export default {
    root: window.translations,

    translate(key, loadRoutes) {
        const routeList = loadRoutes ? loadRoutes : this.root;
        const str = routeList[key];
        return str ? str : key;
    },

    transRoute(route, args) {
        const lang = window.app.env.locale;
        let translatedRoute = this.translate(route, this.root.routes);

        if(route === translatedRoute && route != 'profile') {
            throw new Error('Translation route not found');
        }
        
        for (const key in args) {
            if (Object.hasOwnProperty.call(args, key)) {
                const element = args[key];
                translatedRoute = translatedRoute.replace(`{${key}}`, element);
            }
        }

        return `/${lang}/${translatedRoute}`;
    }
};