import { axiosInstance, isOkResponse } from './http-client'

/**
 * @typedef {Object} Locale
 * @property {number} id - The locale id
 * @property {string} native - The locale name in the native locale language
 * @property {string} name - The locale name in english
 * @property {string} code - The locale ISO code
 */

/**
 * @typedef {Object} LocalesResponse
 * @property {Locale[]} data - The locales array
 */

/**
 * Get the user country locales
 *
 * @returns {Promise<Locale[]>}
 */
export async function getProfileLocales () {
  /** @type {AxiosResponse<LocalesResponse>} */
  const response = await axiosInstance.get('/profile/country/locales')
  return response.data.data
}

/**
 * Set the user preferred locale
 *
 * @param {string} localeCode
 *
 * @returns {Promise<void>}
 */
export async function setProfilePreferredLocale (localeCode) {
  const response = await axiosInstance.post(`/profile/locale/${localeCode}`)

  if (!isOkResponse(response)) {
    throw new Error(`The request for update the user preferred locale failed with status ${response.status} and body ${JSON.stringify(response.data)}`)
  }
}

/**
 * Get the browser locale code in ISO format
 *
 * @returns {string}
 */
export function getBrowserLocaleISOCode () {
  return navigator.language.replace(/_.+/, '')
}
