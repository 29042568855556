<template>
<div class="mmp-posts-tec">
    <h2>{{t("live.live_voting")}}</h2>
    <ul class="voting-scroll">
        <div class="two-votings" v-if="status">
            <ul>
            <li><div class="radio"><input id="radio-vot-2" name="radio" type="radio" v-model="filterType" value="1" v-on:change="filterVoting"> <label  for="radio-vot-2" class="radio-label">Pending vote/Answer</label></div></li>
            <li><div class="radio"><input id="radio-vot-1" name="radio" type="radio" v-model="filterType" value="0" checked v-on:change="filterVoting"> <label for="radio-vot-1" class="radio-label">All</label></div></li>
            </ul>
        </div>
        <li v-for="(item , i) in livepollsdata" :key="i" @click="openPending(item)">
            <label>{{item.created_time}}</label>
            <p>{{item.question}}?</p>
            <div v-if="item.type == 3">
                <div v-for="(curitem,j,k) in item.wordcloud_format" :key="j">
                    <div :id="`'wordcloud-${k}'`" :class="`'wordcloud-ponderation wordcloud-ponderation-${curitem}'`">
                        {{j}}
                    </div>
                </div>
            </div>
            <div v-if="item.type == 2 || item.type == 5">
                <div v-for="(curitem,j) in item.answers.answers" :key="j">
                <div class="answer-first">{{curitem.name}}</div>
                </div>
            </div>
            <div v-if="item.type == 4 || item.type == 1">
                <div v-for="(curitem,j) in item.answers.answers" :key="j">
                    <div class="answer-small">ANSWER {{curitem.name}}
                    </div>
                    <div class="total-progress clearfix">
            <div class="left-side-porgress">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" :style="{width:curitem.percentage+'%'}" :aria-valuenow="`${curitem.percentage}`" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div class="value-porogress">{{curitem.percentage}}%</div>
                    </div>
                </div>
            </div>
            <h4 v-if="item.status == 0">{{t("live.poll_created")}}</h4>
            <h4 v-if="item.status == 1">{{t("live.poll_published")}}</h4>
            <h4 v-if="item.status == 2">{{t("live.poll_finished")}}</h4>
            <h4 v-if="item.status == 3">{{t("live.answers_published")}}</h4>
            <div class="total-answer">{{item.answers.total}} {{t("general.answers")}}</div>
        </li>
     <li class="no-data-list" v-if="livepollsdata.length==0 && !retrievingPolls">No records found</li>
    </ul>
</div>
</template>

<script>
import MessageFactory from "./factories/Message.factory";
import TranslateService from "./../../core/services/translate.service";
import { WSHelper } from '../../../../libs/ws/ws-helper';
import { useLivePollStore } from '../../store/live.store';
import { ref, watch, getCurrentInstance, toRefs } from 'vue';
import axios from 'axios';
import Utils from '../../Utils';

export default {
  props:{
        status: Boolean,
        livepolls: Array,
    },
  setup(props) {
    const { status, livepolls } = toRefs(props);
    const livePollStore = useLivePollStore();
    const livepollsdata = ref([]);
    const translator = ref(TranslateService.create());
    const k = ref(0);
    const filterType = ref(status.value ? 1 : 0);
    const fullData = ref([]);
    const pendingList = ref([]);
    const retrievingPolls = ref(false);
    const instance = getCurrentInstance();

    watch(() => livePollStore.pendings, (newVal) => {
      instance.proxy.resetPendings();
      pendingList.value  = livePollStore.pendings;
      //  pendings or currentPoll review
      if (pendingList.value) {
        Object.keys(pendingList.value).forEach(key => {
          fullData.value.push(MessageFactory.getLivePoll(pendingList.value[key]));
        });
      }
      fullData.value = fullData.value.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.id === item.id
        ))
      );
      if (filterType.value == 0) {
        livepollsdata.value = fullData.value;
      } else {
        livepollsdata.value = fullData.value.filter(function (obj) {
          return obj.answered == 0;
        });
      }
    });

    return {
      livePollStore,
      livepollsdata,
      translator,
      k,
      filterType,
      fullData,
      pendingList,
      retrievingPolls,
    };
  },
    mounted(){
        this.getLivePollList();
        this.filterVoting();

        let that = this;
        this.ws = new WSHelper(true);
        this.ws.registerCallback((evt) => {
            switch (evt.operation) {
                case 'conn_retry_failure':
                  // do something like displaying a loading circle?
                  break;
                case 'conn_down':
                  console.debug('Connection down...');
                  break;
                case 'msg_received':
                  // A message wa s received:
                  that.onWSMessageResults(evt);
                  break;
                case 'conn_started':
                case 'conn_restarted':
                  // Ask current status:
                    const agendaSessionId = Utils.getAgendaSessionId();
                    that.ws.sendObject({ "agenda_id": agendaSessionId, operation: "register_ws", "uid": window.user.uid});
                  break;
              }
        });
        this.ws.start().then(res => {
          console.debug(res);
        }).catch(err => {
          console.debug("ERR", err);
        });
        document.addEventListener("submitLiveSingle", () => {
            this.filterVoting();
        });
        /* this.$root.$on('submitLiveSingle', () => {
            this.filterVoting();
        })*/
    },
    methods:{
        onWSMessageResults(evt){
            let that = this;
            if (evt.json && evt.json.data) {
                const data = evt.json.data;
                switch (data.action) {
                  case "poll_results":
                  case "sp_mod_poll_results":
                    if (data.statusId == 3){
                        this.filterType = 0;
                    }
                    var response = [];
                    Object.keys(data.polls).forEach(key => {
                      response.push(MessageFactory.getLivePoll(data.polls[key].poll_data));
                    });
                    this.fullData = response;
                    if (this.filterType == 0 ) {
                        this.livepollsdata = this.fullData
                    } else {
                        this.livepollsdata = this.fullData.filter(function(obj){
                            return obj.answered == 0;
                        });
                    }
                  break;
                  case "poll_ended":
                      this.resetPendings();
                      if ( this.filterType == 0 ) {
                          this.livepollsdata = this.fullData;
                      } else {
                          this.livepollsdata = []
                      }
                  break
                }
            }
        },
        t(tag) {
            return this.translator.translate(tag)
        },
        async getLivePollList() {
            let response = await this.getLiveVoteDetails();
            if(this.filterType==1){
                this.livepollsdata = response.data.dataPending.map(k=>MessageFactory.getLivePoll(k));
                this.livePollStore.pendings = response.data.dataPending;
                // this.$store.commit('setPendings', response.data.dataPending);
            }else{
                this.livepollsdata = response.data.data.map(k=>MessageFactory.getLivePoll(k))
            }
            this.fullData = this.livepollsdata;
        },
        getLiveVoteDetails(){
            return axios.get("/agenda-session/attendee-live-voting" , {params:{
                id : Utils.getAgendaSessionId()
            }})
        },
        async filterVoting(){
            this.retrievingPolls = true;
            this.livepollsdata = [];
            if ( this.filterType == 0 ) {
                let response = await this.getLiveVoteDetails();
                this.livepollsdata = response.data.data.map(k=>MessageFactory.getLivePoll(k));
            } else {
                let response = await this.getLiveVoteDetails();
                let result = response.data.dataPending.map(k=>MessageFactory.getLivePoll(k));
                this.fullData =  result ;
                this.livepollsdata = this.fullData.filter(function(obj){
                    return obj.answered == 0;
                    // obj.status == 2
                });
                this.livePollStore.pendings = response.data.dataPending;
                // this.$store.commit('setPendings', response.data.dataPending);
            }
            this.retrievingPolls = false;
        },
        resetPendings() {
          if(this.status){
            var newList = []
            for (let index = 0; index < this.fullData.length; index++) {
                const element = this.fullData[index];
                if(element.answered !== 0) {
                  newList.push(element)
                }
            }
            this.fullData = newList;
          }
      },
      openPending(item) {
        if (this.filterType === 0) {
          return
        }
        for (let index = 0; index < this.pendingList.length; index++) {
          if (this.pendingList[index].poll.id === item.id) {
            this.livePollStore.currentPoll = this.pendingList[index];
            break;
          }
        }
      }
    },

}
</script>
