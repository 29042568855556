<template>
  <span>{{ configuration.section_wording }}</span>
</template>

<script>
import TranslateService from '../../core/services/translate.service';

export default {
  name: 'AsynchronusDiscussionSection',
  data() {
    return {
      translator: TranslateService.create(),
    };
  },
  components: {  },
  props: {
    configuration: Object
  },
  mounted() {

  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
