<template>
  <div class="row">
    <div class="clinical-cases__description col">
      <VideoPlayer
        v-if="cc.intro_video_link"
        :id="cc.id"
        :videoid="cc.presentationVideoId"
        :videotype="1"
        class="multimedia-video-player"
        :videouri="cc.intro_video_link"
        :accountid="cc.presentation_accountId"
        :playerid="cc.presentation_playerId"
        :embedid="cc.presentation_embedId"
        :datasourcetype="0"
        :datatype="0"
      ></VideoPlayer>
      <div class="clinical-cases__description__container" v-html="cc.description"></div>
      <div class="clinical-cases__description__tools">
        <button class="v4-btn-primary" @click="goToContentTab()">
          <span>{{ t('clinical-case.card.view-case') }}</span>
        </button>
        <button
          data-action="more"
          @click="readMoreDescription($event)"
          class="clinical-cases__description__tools__read-more d-none"
        >
          {{ t('congress.read-more') }}
        </button>
      </div>
    </div>
    <div class="clinical-cases__right-panel__panel col">
      <ul>
        <li>
          <i class="bi bi-clock"></i> <span>{{ minutes }} {{ t('clinical-case.presentation.minutes') }}</span>
        </li>
        <li>
          <i class="bi bi-globe"></i> <span>{{ language }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import VideoPlayer from '../../modules/multimedia/VideoPlayer.vue';
import $ from 'jquery';

export default {
  name: 'ClinicalCasesPresentation',
  components: {
    VideoPlayer
  },
  props: {
    cc: Object
  },

  data() {
    return {
      minutes: 0,
      language: '',
      studentsNumber: 0,
      chaptersNumber: 0,
      translator: TranslateService.create()
    };
  },

  mounted() {
    this.parseInfo();
    this.calcPresentationHeight();
  },

  methods: {
    parseInfo() {
      this.minutes = this.cc.totalMinutes;
      this.language = this.cc.language.name;
      this.chaptersNumber = this.cc.chapters.length;
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    calcPresentationHeight() {
      if ($('.clinical-cases__description__container').outerHeight() > 250) {
        $('.clinical-cases__description__container').addClass('clinical-cases__description__container--larger');
        $('.clinical-cases__description__tools__read-more').removeClass('d-none');
      }
    },
    readMoreDescription(e) {
      if ($(e.currentTarget).data('action') == 'more') {
        $('.clinical-cases__description__container').removeClass('clinical-cases__description__container--larger');
        $('.clinical-cases__description__tools__read-more').text(this.t('congress.read-less'));
        $(e.currentTarget).data('action', 'less');
      } else {
        $('.clinical-cases__description__container').addClass('clinical-cases__description__container--larger');
        $('.clinical-cases__description__tools__read-more').text(this.t('congress.read-more'));
        $(e.currentTarget).data('action', 'more');
      }
    },
    goToContentTab() {
      document.dispatchEvent(new CustomEvent("goToContentTab"));                    
      // this.$root.$emit('goToContentTab');
    }
  }
};
</script>
