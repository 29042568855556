<template>
  <div class="row">
    <div class="col clinical-cases__tabsheet">
      <ul class="clinical-cases__tabsheet__list">
        <li @click="changeTab('presentation')" :class="{ 'clinical-cases__tabsheet__button--active': currentTab === 'presentation' }" class="clinical-cases__tabsheet__button">{{ t('knowledge-journey.tabs.presentation') }}</li>
        <li @click="changeTab('content')" :class="{ 'clinical-cases__tabsheet__button--active': currentTab === 'content' }" class="clinical-cases__tabsheet__button">{{ t('knowledge-journey.tabs.content') }}</li>
        <li @click="changeTab('supporting information')" :class="{ 'clinical-cases__tabsheet__button--active': currentTab === 'supporting information' }" class="clinical-cases__tabsheet__button">{{ t('knowledge-journey.tabs.support') }}</li>
      </ul>
      <KnowledgeJourneyPresentation v-show="currentTab === 'presentation'" :kj="kj" :progress="currentProgress" :display="currentDisplay" :diploma="showDiploma" :infocard="infocard" class="clinical-cases__tabsheet__container"></KnowledgeJourneyPresentation>
      <KnowledgeJourneyContent v-show="currentTab === 'content'" :kj="kj" :progress="currentProgress" :display="currentDisplay" :diploma="showDiploma" class="clinical-cases__tabsheet__container"></KnowledgeJourneyContent>
      <KnowledgeJourneySupporting v-show="currentTab === 'supporting information'" :kj="kj" :progress="currentProgress" :display="currentDisplay" :si="si" :diploma="showDiploma" class="clinical-cases__tabsheet__container"></KnowledgeJourneySupporting>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import KnowledgeJourneyPresentation from './KnowledgeJourneyPresentation.vue';
import KnowledgeJourneyContent from './KnowledgeJourneyContent.vue';
import KnowledgeJourneySupporting from './KnowledgeJourneySupporting.vue';

export default {
  name: 'KnowledgeJourneyTabsheet',
  components: {
    KnowledgeJourneyPresentation,
    KnowledgeJourneyContent,
    KnowledgeJourneySupporting
  },
  props: {
    kj: Object,
    si: Array,
    infocard: Object,
    progress: Number,
    display: Boolean,
    diploma: Boolean
  },
  data() {
    return {
      currentTab: 'presentation',
      currentDisplay: this.display,
      currentProgress: this.progress,
      showDiploma: this.diploma ?? false,
      translator: TranslateService.create(),
    }
  },
  mounted() {
    document.addEventListener("goToContentTab", () => {
      this.currentTab = 'content';
    });
    /* this.$root.$on('goToContentTab', (percentage) => {
        this.changeTabsheet(null, '#tabsheet-2-tab');
      }); */
    document.addEventListener("updateKJPercentage", (event) => {
      if (event.detail > this.progress) {
        this.currentProgress = event.detail;
      }
    });
    /* this.$root.$on('updateKJPercentage', (percentage) => {
    if(percentage > this.progress) {
      this.currentProgress = percentage;
    } */
  },
  methods: {
    changeTab(tabName) {
      this.currentTab = tabName;
      GoogleAnalytics.sendMenuNavEvent(tabName, 'menu knowledge journey');
    },
    t(tag) {
      return this.translator.translate(tag)
    },
  }
}
</script>
