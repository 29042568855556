import headerComponents from './layout/header'
import preHomeComponents from './pre-home'
import homeComponenets from './home'
import loginComponents from './login'
import regularComponents from './regularComponents'
import internalCongress from './internalCongress'
import eventsComponents from './events'
import agendaSessionComponents from './agendaSession'
import multimediaComponents from './multimedia'
import registerComponents from './register'
import customCongress from './customCongress'
import clinicalCases from './clinicalCases'
import knowledgeJoruney from './knowledgeJourney'
import externalCongress from './externalCongress'
import profile from './profile'
import connectMiComponents from './connectMi'
import webinarCongress from './webinarCongress'
import krasCongress from './krasCongress'
import liveComponents from './live'

export const vueComponents = [
    ...headerComponents,
    ...preHomeComponents,
    ...homeComponenets,
    ...loginComponents,
    ...internalCongress,
    ...eventsComponents,
    ...agendaSessionComponents,
    ...multimediaComponents,
    ...regularComponents,
    ...registerComponents,
    ...customCongress,
    ...clinicalCases,
    ...knowledgeJoruney,
    ...externalCongress,
    ...profile,
    ...connectMiComponents,
    ...webinarCongress,
    ...krasCongress,
    ...liveComponents,
]
