<template>
<div class="row">
    <div class="col">
        <ul class="clinical-cases__tabsheet__supporting">
            <div v-for="(document, i) in supportingDocuments" v-bind:key="i">
                <li>
                    <i :class="getIcon(document)"></i>
                    <div class="col">
                        <a @click="getMultimediaURL(document)">{{document.title}}</a>
                    </div>
                </li>
            </div>
        </ul>
    </div>
</div>
</template>

<script>
import TranslationService from '../../core/services/translation.service';
export default {
    name: 'ClinicalCasesSupporting',
    props: {
        cc: Object,
        si: Array
    },

    data() {
        return {
            supportingDocuments: []
        }

    },

    mounted() {
        this.parseDocuments();
    },

    methods: {
        parseDocuments() {
            this.supportingDocuments = this.si;
        },

        getIcon(document) {
            if (document.multimedia_type_id == 5 || document.multimedia_type_id == 4) {
                return 'bi bi-file-play';
            } else if (document.external_link) {
                return 'bi bi-link';
            } else {
                return 'bi bi-file-earmark';
            }
        },
        getDocument(document) {
            let fileExt = document.file.split('.').pop();
            return document.title + '.' + fileExt;
        },
        parseUrl(url) {
            if (url && url.substr(0,1) != '/') {
                url = '/' + url;
            }
            return url;
        }, 
        getMultimediaURL(documentMultimedia) {
            let url = TranslationService.transRoute('detail-multimedia', {multimediaId: documentMultimedia.id});
            if(documentMultimedia.external_link && documentMultimedia.external_link != "" && documentMultimedia.multimedia_type_id != 4 && documentMultimedia.multimedia_type_id != 5 && documentMultimedia.multimedia_type_id != 12) {
                document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: documentMultimedia.external_link }));                    
                // this.$root.$emit('leavingMMPModal', documentMultimedia.external_link);
            } else {
                window.open(url, '_blank');
            }
        }
    },
}
</script>
