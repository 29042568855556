import axios from "axios";

export default {
    async getClinicalCases(fromRelatedId = null, slug = null, onlyWithProgress = false) {
        let data = {
            id: fromRelatedId ?? null,
            slug: slug ?? null,
            onlyWithProgress: onlyWithProgress ?? false
        };
        const response = await axios.post('/cc/get-cc', data);

        return response.data;
    },
    async saveInterval(progress){
        await axios.post('/cc/save-interval',progress);
    },
    async getLastIntervalCompleted(id) {
        const response = await axios.post('/cc/get-last-interval',id);
        return response;
    },

};