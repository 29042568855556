<template>
    <div class="knowledge-journey__content row">
        <KnowledgeJourneyUnit :key="unitRender.componentKey" :un="unitRender.un"></KnowledgeJourneyUnit>
        <ConfirmDialogue ref="kjResultModal"></ConfirmDialogue>
        <div class="knowledge-journey__content__helper col" :key="treeRender.componentKey">
            <div class="knowledge-journey__content__chapters col">
               <template v-if="currentDisplay">
                    <KnowledgeJourneyProgress class="clinical-cases__tabsheet__progress" 
                      :progress="currentProgress" 
                      :diploma="showDiploma"
                      :kjId="kj.id"
                      :kjTitle="kj.title">
                    </KnowledgeJourneyProgress>
                </template>
                <ul v-if="!treeRender.test" class="knowledge-journey__content__chapters__helper">
                    <li v-for="(ch, i) in kjObj.chapters" :data-id="ch.id" :key="ch.id" @click="openChapterUnits($event, null)" class="knowledge-journey__content__chapters__container kj-chapter-item">
                        <div class="knowledge-journey__content__chapters__container__info col" :style="{'display': kjObj.chapters.length > 1 ? 'block' : 'none'}">
                            <div class="d-flex">
                                <span class="kj-badge">chapter {{ i + 1 }}</span>
                                <span class="kj-badge kj-badge--outlined">{{ ch.duration }} min</span>
                                <span v-if="ch.is_completed" class="kj-badge kj-badge--slim"><i class="bi bi-check"></i></span>
                                <span class="knowledge-journey__content__chapters__container__close" @click="closeChaptersTree()"><i class="bi bi-chevron-down"></i></span>
                            </div>
                            <p class="knowledge-journey__content__chapters__text">{{ ch.title }} <span>({{ ch.units.length }} units)</span></p>
                        </div>
                        <ul class="knowledge-journey__content__chapters__container__list">
                            <li v-for="(un, j) in ch.units" :key="un.id" :data-id="un.id" :class="{'unit-selected': (i === 0 && j === 0), 'cannot-access': !un.can_access}" class="flex-column kj-unit-item" @click="getUnitData(kjObj.id, un.id, un.can_access, un.title, (i+1)+'.'+(j+1), null, $event)" :data-done="un.is_completed">
                                <div class="d-flex">
                                    <span :class="(i === 0 && j === 0) ? 'kj-badge kj-badge--selected' : 'kj-badge'">
                                        <span v-if="kjObj.chapters.length > 1">{{ i + 1 }}.</span>{{ j + 1 }}
                                    </span>
                                    <span v-if="un.is_completed" :class="(i === 0 && j === 0) ? 'kj-badge kj-badge--slim kj-badge--selected' : 'kj-badge kj-badge--slim'"><i class="bi bi-check"></i></span>
                                </div>
                                <p class="knowledge-journey__content__chapters__text">{{ un.title }} ({{ un.duration_minutes }}min)</p>
                                <p v-if="un.has_test" :style="{color: !kjObj.lineal || un.is_completed ? 'white' : 'cadetblue'}" @click.stop="getTestQuestions(un.id, 'unit', false)" class="unit-test">{{t('knowledge-journey.buttons.unit-test')}}</p>
                            </li>
                            <li v-if="ch.has_test" class="flex-column chapter-test-button" @click="getTestQuestions(ch.id, 'chapter', false)" :data-done="ch.is_completed">
                                <p class="knowledge-journey__content__chapters__title" :style="{color: !kjObj.lineal || ch.is_completed ? 'white' : 'grey'}">{{t('knowledge-journey.buttons.chapter-test')}}</p>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul v-else class="knowledge-journey__content__chapters__helper">
                    <li class="knowledge-journey__content__chapters__container knowledge-journey__content__chapters__container--unselect knowledge-journey__content__chapters__container--separator d-flex px-2 mx-4">
                        <span class="text-muted mr-3"><em class="bi bi-clipboard"></em> {{ unitRender.un.questions.length }} {{t('knowledge-journey.test.answers')}}</span>
                        <span :key="answeredRender.componentKey"><em class="bi text-white bi-clipboard-check"></em> {{ answeredRender.answers }} {{t('knowledge-journey.test.answered')}}</span>
                    </li>
                    <li class="knowledge-journey__content__chapters__container knowledge-journey__content__chapters__container--unselect knowledge-journey__content__chapters__container--separator d-flex px-2 mx-4">
                        <span><em class="bi text-white bi-arrow-repeat"></em> {{ unitRender.un.attempt }} {{t('knowledge-journey.test.times-done')}}</span>
                    </li>
                    <li class="knowledge-journey__content__chapters__container knowledge-journey__content__chapters__container--unselect px-4 mt-">
                        <p>{{t('knowledge-journey.test.can-repeat')}}</p>
                    </li>
                </ul>
            </div>
            <div v-if="kjObj.has_test && enabledTest" class="knowledge-journey__content__helper">
                <button v-if="kjObj.test_done" @click="getTestQuestions(kjObj.id)" class="v4-btn-primary">
                    <p class="d-flex">{{t('knowledge-journey.unit_list.final_test')}}</p>
                    <span class="kj-badge kj-badge--slim"><i class="bi bi-check"></i></span>
                </button>
                <button v-else @click="getTestQuestions(kjObj.id)" class="v4-btn-primary v4-btn-primary--outlined">
                    <p>{{t('knowledge-journey.unit_list.final_test')}}</p>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import TranslateService from '../../core/services/translate.service';
  import TranslationService from '../../core/services/translation.service';
  import GoogleAnalytics from '../../GoogleAnalytics.js';
  import KnowledgeJourneyProgress from './KnowledgeJourneyProgress.vue';
  import KnowledgeJourneyUnit from './KnowledgeJourneyUnit.vue';
  import ConfirmDialogue from '../../modules/agenda/ConfirmDialogue.vue';
  import $ from 'jquery';
  import axios from 'axios';
  
  export default {
    name: 'KnowledgeJourneyContent',
    components: {
      KnowledgeJourneyProgress,
      KnowledgeJourneyUnit,
      ConfirmDialogue
    },
    data() {
      return {
        host: window.location.protocol + '//' + window.location.host,
        revisionNumber: 0,
        currentTime: 0,
        videoJS: null,
        lastInterval: null,
        executed: false,
        quizName: '',
        quizStep: '',
        answeredQuestions: [],
        intervalDuration: 0,
        showDiploma: this.diploma ?? false,
        answeredRender: {
            componentKey: 0,
            answers: 0
        },
        unitRender: {
            componentKey: 10,
            un: {}
        },
        treeRender: {
            componentKey: 20,
            test: false
        },
        selectors: {
          chapter: 'knowledge-journey__content__chapters__container'
        },
        translator: TranslateService.create(),
        kjObj: {},
        enabledTest: false,
        currentDisplay: this.display,
        currentProgress: this.progress,
        completedQuiz: [],
      }
    },

    props: {
      kj: Object,
      progress: Number,
      display: Boolean,
      diploma: Boolean
    },

    methods: {
      rightPanelRerender(tryagain = false) {
          this.unitRender.componentKey++;
          let testPassed = this.unitRender && this.unitRender.un && this.unitRender.un.isPassed;
          if (this.treeRender.test || testPassed) {
              this.treeRender.componentKey++;
              if (tryagain) {
                this.answeredRender.answers = 0;
                this.answeredRender.componentKey++;
              }
          }
          if(this.kjObj && this.kjObj.can_give_diploma && this.kjObj.progress == 100) {
            this.showDiploma = true; 
          }
      },

      closeChaptersTree() {
        $('.' + this.selectors.chapter).removeClass(this.selectors.chapter + '--selected').find('ul').hide('fast');
      },

      openChapterUnits(e, chapterId = null) {
        if (e && !$(e.currentTarget).find('ul').is(':visible')) {
          this.closeChaptersTree();
          $(e.currentTarget).addClass(this.selectors.chapter + '--selected').find('ul').show('fast');
        } else if(chapterId) {
            this.closeChaptersTree();
            $(`.kj-chapter-item[data-id=${chapterId}]`).addClass(this.selectors.chapter + '--selected').find('ul').show('fast');
        }
      },

      mountChaptersTree() {
          let chapters = this.kj.chapters;

          for (let i in chapters) {
              chapters[i]['duration'] = 0;
              for (let j in chapters[i].units) {
                  chapters[i]['duration'] = chapters[i]['duration'] + chapters[i].units[j].duration_minutes;
              }
          }

          this.kj.chapters = chapters;
      },

      getUnitData(kjId, kjUnit, canAccess, quizTitle, quizStepActual, chapterId = null, e) {
        if(!canAccess) return;
        const path = '/kjunit/' + kjId + '/' + kjUnit;
        const prms = {
            kjId: kjId,
            kjUnit: kjUnit
        };

        if (!!e) {
          $('.unit-selected').removeClass('unit-selected').find('.kj-badge--selected').removeClass('kj-badge--selected');
          $(e.currentTarget).addClass('unit-selected').find('.kj-badge').addClass('kj-badge--selected');
        } else if(kjUnit) {
          $('.unit-selected').removeClass('unit-selected').find('.kj-badge--selected').removeClass('kj-badge--selected');
          $(`.kj-unit-item[data-id=${kjUnit}]`).addClass('unit-selected').find('.kj-badge').addClass('kj-badge--selected');
          if(chapterId) {
              this.openChapterUnits(null, chapterId);
          }
        }
        $('.chapter-test-button').css('background-color', 'transparent');

        axios.post(path, prms).then(res => {
          if (res.data.status == 200) {
            // Check if the quiz has been started and he is abandoning it
            if (this.unitRender.un && this.unitRender.un.questions && this.unitRender.un.questions.length > 0) {
              if (this.quizName && this.quizStep && this.quizName != quizTitle && !this.completedQuiz.includes(this.quizName)) {
                GoogleAnalytics.sendQuizAbandonEvent(this.quizStep, this.quizName);
              }
            }
            this.unitRender.un = res.data.kju;
            this.rightPanelRerender();
            if (this.unitRender.un.has_test) {
              this.quizStep = quizStepActual;
              this.quizName = quizTitle;
              if (!this.answeredQuestions.includes(this.quizName)) {
                GoogleAnalytics.sendStartQuizEvent(this.quizName);
                this.answeredQuestions.push(this.quizName);
              }
            }
          }
        }).catch(err => {
          console.error(err);
        });
      },

      getQuizStep() {
        return this.quizStep;
      },

      getTestQuestions(kjId, model = '', finalTest = true) {
        const path = '/knowledge-journey/test';
        let currentTarget = event.currentTarget;
        $('.chapter-test-button').css('background-color', 'transparent');
        axios.get(path, {params: {
            id: kjId,
            model: model
        }}).then(res => {
            if(res.status == 200) {
                if (finalTest) this.treeRender.test = true;
                // Check if the quiz has been started and he is abandoning it
                if (this.unitRender.un && this.unitRender.un.questions && this.unitRender.un.questions.length > 0) {
                  if (this.quizName && this.quizStep && !this.completedQuiz.includes(this.quizName)) {
                    GoogleAnalytics.sendQuizAbandonEvent(this.quizStep, this.quizName);
                  }
                }
                this.unitRender.un = res.data.data;
                this.rightPanelRerender();
                // Check if the quiz is starting
                if (this.unitRender.un.has_test) {
                  this.quizStep = quizStepActual;
                  this.quizName = quizTitle;
                  if (!this.answeredQuestions.includes(this.quizName)) {
                    GoogleAnalytics.sendStartQuizEvent(this.quizName);
                    this.answeredQuestions.push(this.quizName);
                  }
                }
                if(model == 'chapter') {
                    $('.unit-selected').removeClass('unit-selected').find('.kj-badge--selected').removeClass('kj-badge--selected');
                    if(currentTarget) {
                        currentTarget.style.backgroundColor = '#2DBCB6';
                    }
                }
            }
        }).catch(err => {
            console.error(err);
        });
      },

      showResults(results) {
          this.unitRender.un = results;
          if(results.isPassed) {
              this.kjObj = results.kj;
          }
          this.rightPanelRerender();
          this.showResultsModal(results);
      },

      async showResultsModal(data) {
        //Type
        let type = data.type != '' ? data.type : 'kj';
        let typeId = data.type_id;
        //Title message
        let title = data.isPassed ? 
            `<img src="../../../images/v4/check.gif" alt="" class="kj-gif" /> ${this.t('knowledge-journey.test.passed')}` : 
            `<img src="../../../images/v4/error.gif" alt="" class="kj-gif" /> ${this.t('knowledge-journey.test.failed')}`;
        //Content message
        let message = data.isPassed ? 
            this.getApprovedMessage(data.currentTitle, data.kj.progress) :
            this.getFailedMessage(data.currentTitle);
        //Show certificate buttons
        let downloadButton = data.kj && data.kj.can_give_diploma && data.kj.progress == 100;
        if(downloadButton) {
            document.dispatchEvent(new CustomEvent("showDiplomaButton"));                    
            // this.$root.$emit('showDiplomaButton');
            if(data.isPassed) {
              message += this.isImlygic() ? `<br>${this.t('knowledge-journey.test.get-certificate-imlygic')}` : `<br>${this.t('knowledge-journey.test.get-certificate')}`;
            } else {
              message += `<br>${this.t('knowledge-journey.test.get-certificate-failed')}`;
            }
        }
        if (data.isPassed) {
          this.completedQuiz.push(this.quizName);
          GoogleAnalytics.sendQuizCompleteEvent(this.quizName);
        }
        //Initialize buttons
        let cancelButton = null;
        let okButton = this.t('knowledge-journey.test.close');
        //KJ id for diploma needed
        let kjid = this.kj.id;
        
        if (data.isPassed && data.nextItem) {
          let alreadyCompleted = data.kj && data.kj.progress == 100;
          if(type != 'kj' && !alreadyCompleted) {
              message += this.getNextMessage(data.nextItem.title);
              okButton = type == 'kj' ? this.t('knowledge-journey.card.keep_learning') : this.t('knowledge-journey.test.next-section');
          } else {
            okButton = null;
          }
          cancelButton = this.t('knowledge-journey.test.review-answers');
        }

        const accepted = await this.$refs.kjResultModal.show({
            kjid: kjid,
            title: title,
            message: message,
            okButton: okButton,
            cancelButton: cancelButton,
            downloadButton: downloadButton
        });

        if(accepted && data.isPassed && data.nextItem){
            if(data.type == 'unit' || data.type == 'chapter') {
                let chapterId = data.nextItem.chapter ? data.nextItem.chapter.id : null;
                this.getUnitData(this.kj.id, data.nextItem.id, data.nextItem.can_access, chapterId);
            } else {
                window.location = TranslationService.transRoute('knowledge-journeys-home');
            }
        } else if(!accepted) {
            $('.knowledge-journey__content__chapters__container__list').css('display', 'none');
            if(type == 'chapter') {
              $('.unit-selected').removeClass('unit-selected').find('.kj-badge--selected').removeClass('kj-badge--selected');
              $(`.kj-chapter-item[data-id=${typeId}]`).addClass(this.selectors.chapter + '--selected').find('ul').show('fast');
              let btn = $(`.kj-chapter-item[data-id=${typeId}]`).find('.chapter-test-button');
              if(btn) {
                btn.css('background-color', '#2DBCB6');
              }
            } else if(type == 'unit') {
              $('.knowledge-journey__content__chapters__container__list').css('display', 'block');
            }
        }

      },

      t(tag) {
        return this.translator.translate(tag);
      },
      getFailedMessage(currentTitle) {
        if(this.isImlygic()) {
          return this.t('knowledge-journey.test.failed-msg-imlygic');
        } else {
          return this.t('knowledge-journey.test.failed-msg') + currentTitle + '.';
        }
      },

      getApprovedMessage(currentTitle, process) {
        if(this.isImlygic()) {
          return process == 100 ? '' : this.t('knowledge-journey.test.passed-msg-imlygic');
        } else {
          return this.t('knowledge-journey.test.passed-msg') + currentTitle + '.';
        }
      },

      getNextMessage(title) {
        if(this.isImlygic()) {
          return '';
        } else {
          return this.t('knowledge-journey.test.continue-learning') + title + '.';
        }
      },
      isImlygic() {
        return window.app.event && window.app.event.slug == 'imlygicknowledge';
      }
    },

    created() {
        this.kjObj = this.kj;
        this.enabledTest = this.kjObj.progress == 100;
        this.mountChaptersTree();
        this.getUnitData(this.kj.id, this.kj.chapters[0].units[0].id, this.kj.chapters[0].units[0].can_access);
    },

    mounted() {

      document.addEventListener("documentReload", () => {
        this.unitRender.componentKey++;
      });
      /* this.$root.$on('documentReload', () => {
        this.unitRender.componentKey++;
      }); */

      if(this.kjObj && this.kjObj.chapters && this.kjObj.chapters.length > 1) {
          this.closeChaptersTree();
      }

      document.addEventListener("changeAnsweredQuestions", (event) => {
        this.answeredRender.answers = event.detail;
        this.answeredRender.componentKey++;
      });
      /* this.$root.$on('changeAnsweredQuestions', (answeredQuestions) => {
          this.answeredRender.answers = answeredQuestions;
          this.answeredRender.componentKey++;
      }); */
      document.addEventListener("updateKJPercentage", (event) => {
        if(event.detail > this.progress) {
              this.currentProgress = event.detail;
        }
        if(event.detail >= 95){
          this.enabledTest = true;
        }
      });
      /* this.$root.$on('updateKJPercentage', (percentage) => {
          if(percentage > this.progress) {
              this.currentProgress = percentage;
          }
          if(percentage >= 95){
            this.enabledTest = true;
          }
      }); */
    },
    beforeDestroy() {
      GoogleAnalytics.sendQuizAbandonEvent(this.quizStep, this.quizName);
    }
  }
</script>
