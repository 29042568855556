<template>

    <div class="mmp-login-content">
        <div class="mmp-login-content__title">
            {{ t('mmp-login.header') }}
        </div>
        <form class="mmp-login-content__form">
            <div>
                <label for="email" class="text-uppercase mmp-login-content__form__label">{{ t('mmp-login.email')
                    }}</label>
                <input id="user-email" data-testid=”form-mail” class="mmp-login-content__form__input"
                    v-model="form.email" type="email" name="email" :placeholder="t('mmp-login.placeholder.email')"
                    required @keyup="emailChange" />
                <div v-show="isAmgenEmail" class="container-create-password__email-error-check mt-3 mb-3">
                    <div class="error-email-step">
                        <span class="error-item">
                            <img v-bind:src="passwordImage.ko"
                                alt="Email Status" />
                        </span>
                        <span class="error-text">{{ t('pre-home.errors.amgen_email_login_text') }}</span>
                        <span class="error-text oktaLink" @click="oktaLogin()"><strong>{{ t('pre-home.errors.amgen_email_okta_link') }}</strong></span>
                    </div>
                </div>
            </div>
            <div class="mt-3 mmp-login-content__form__password">
                <label for="password" class="text-uppercase mmp-login-content__form__password">{{ t('mmp-login.p-label') }}</label>
                <input 
                    @keyup="passwordChange" 
                    v-model="form.password" 
                    id="password-user" 
                    data-testid=”form-password”
                    :type="fieldType.pwdType" class="mmp-login-content__form__input" name="password"
                    :placeholder="t('mmp-login.placeholder.p-label')" autocomplete="off" />
                <i id="toggle-password" @click="togglePasswordItem('pwdType')" class="bi bi-eye-fill mmp-login-content__form__psw-icon"></i>
            </div>
            <div class="row">

                <div class="col-md-6 text-left bottom-helpers-links mmp-login-content__form__reset-psw">
                    <a id="forgot-password" data-testid=”login-forgot-password” @click="showRecoveryPasswordModal()">
                        {{ t('pre-home.create-pwd-label.title') }}
                    </a>
                </div>
            </div>

            <div class="mmp-login-content__form__error" v-if="loginWarning.show" v-html="loginWarning.message">
            </div>
            <div class="text-right mt-5 satellital-separator-xl">
                <a :href="backUrl" data-testid=”login-back” class="mmp-login-content__form__back">{{
                    t('satellital.back') }}</a>
                <button type="submit" class="v4-btn-primary mmp-login-content__form__button" @click="attemptLogin"
                    :disabled="checkLoginConditions" data-testid=”form-login-button”>
                    <span>{{ t('mmp-login.log-in') }}</span>
                </button>
            </div>
        </form>
    </div>

</template>

<script>
import TranslateService from '../../core/services/translate.service.js';
import LoginUtils from './utils/LoginUtils.js';
import PageStayController from '../../page-stay/PageStayController.js';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import Utils from '../../../v4/Utils';
import axios from 'axios';
import { useCreatePwd } from '../../store/modal.store.js';
import { useAppStore } from '../../store/app.store';
import SatellitalUtils from '../satellital/utils/utils';

export default {
    setup() {
        const createPwdStore = useCreatePwd();
        const loaderStore = useAppStore();

        return {
            loaderStore,
            createPwdStore,
        }
    },

    props: {
        slug: String,
        backUrl: String,
        country: String,
    },
    data() {
        return {
            translator: TranslateService.create(),
            checkLoginConditions: false,
            form: {
                email: '',
                password: '',
            },
            fieldType: {
                pwdType: 'password',
            },
            passwordWarning: {
                show: false,
                message: ''
            },
            loginWarning: {
                show: false,
                message: ''
            },
            passwordImage: {
                ko: '/images/v4/assets/x-circle-fill.svg',
                ok: '/images/v4/assets/check-circle-fill.svg'
            },
            isAmgenEmail: false,
        };
    },
    mounted() {
        PageStayController.startStayPageTime('login', this.congressId ?? '0', false);
    },
    methods: {
        t(tag) {
            return this.translator.translate(tag);
        },
        passwordChange() {
            const { password, repeatPassword } = this.form;
            const validation = LoginUtils.doBasicPasswordCheck(this.t, password, repeatPassword);
            this.passwordWarning.show = validation.showPasswordErrorWarning;
            this.passwordWarning.message = validation.passwordMessage;
            this.passwordCheck = LoginUtils.doAdvancedPasswordCheck(password, repeatPassword);
            this.checkForLoginConditions();
        },
        emailChange() {
            this.isAmgenEmail = SatellitalUtils.checkIsAmgenEmail(this.form.email);
        },
        checkForLoginConditions() {
            this.isAmgenEmail = SatellitalUtils.checkIsAmgenEmail(this.form.email);
            const emailCheck = !!this.form && !!this.form.email && this.form.email.length > 0 && !this.isAmgenEmail;
            const passwordCheck = !!this.form && !!this.form.password && this.form.password.length > 0;
            let avoidSubmitForm = !(emailCheck && passwordCheck);
            this.checkLoginConditions = avoidSubmitForm;
        },
        existErrorInPassword() {
            return this.passwordWarning.show;
        },
        togglePasswordItem(item) {
            if (this.fieldType[item] === 'password') {
                this.fieldType[item] = 'text';
            } else {
                this.fieldType[item] = 'password';
            }
        },
        attemptLogin(e) {
            e.preventDefault();
            this.loginWarning.show = false;
            this.loginWarning.message = '';
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
            if (!this.checkLoginConditions) {
                PageStayController.setAdditionalData(this.form.email, this.country, null);
                PageStayController.stopStayPageTime();
            }
            axios.post('/login', this.getFormData())
                .then(response => {
                    document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
                    GoogleAnalytics.sendLoginEvent();
                    if (Utils.isValidUrl(response.data.redirectTo)) {
                        document.location.href = Utils.escapeHtml(response.data.redirectTo);
                    }
                }).catch(error => {
                    document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
                    const resp = error.response.data;
                    this.manageErrorResponse(resp);
                });
        },
        getFormData() {
            const fd = new FormData();
            fd.set("email", this.form.email);
            fd.set("password", this.form.password);
            fd.set("country", this.country);
            return fd;
        },
        manageErrorResponse(resp) {
            this.loginWarning.show = true;
            if (!!resp.errorMessage) {
                this.loginWarning.message = resp.errorMessage;
            } else if (!resp.exist || !resp.published) {
                this.loginWarning.message = this.t('auth.failed');
            } else if (!resp.emailConfirm) {
                this.loginWarning.message = this.t('mmp-login.error.not-validated');
            } else if (!resp.validated) {
                this.loginWarning.message = this.t('mmp-login.error.healthcare-not-validated');
            } else if (!resp.healthcare_verified) {
                this.loginWarning.message = this.t('mmp-login.error.healthcare-not-validated');
            } else {
                this.loginWarning.message = this.t('mmp-login.error.unknown-error');
            }
        },
        showRecoveryPasswordModal() {
            this.createPwdStore.toggleCreatePwd(this.slug);
        },

        openLink(url) {
            window.open(url, '_blank');
        },
        oktaLogin() {
            axios.get(
                '/login/okta'
            ).then(response => {
                document.location.href = response.data.oktaRedirect;
            });
        },
    }
};
</script>
