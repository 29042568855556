<template>
    <div class="custom-template-agenda-event-popup" :style="{top: clientY + 'px', left: clientX + 'px'}">
        <div :class="extendedProps.image && !congress.minimalisticAgendaPopUp ? 'custom-template-agenda-event-popup__header has-image' : 'custom-template-agenda-event-popup__header'" :style="extendedProps.image ? {background: 'url(/' + extendedProps.image + ')'} : null">
            <i class="bi bi-x-lg" @click="$emit('close')"></i>
        </div>
        <div class="custom-template-agenda-event-popup__body">
          <div class="custom-template-agenda-event-popup__body-wrapper">
              <i v-if="!congress.minimalisticAgendaPopUp" class="bi bi-circle-fill" :style="{ color: extendedProps.categoryColor }"></i>
              <p v-bind:class="{ 'custom-template-agenda-event-popup__title': !congress.minimalisticAgendaPopUp, 'custom-template-agenda-event-popup__title_minimal': congress.minimalisticAgendaPopUp }" >{{ extendedProps.eventTitle }}</p>
              <p class="custom-template-agenda-event-popup__date">{{ getFormatData() }}</p>
              <p v-if="!congress.minimalisticAgendaPopUp" class="custom-template-agenda-event-popup__description" v-html="extendedProps.eventDescription"></p>
              <button class="custom-template-agenda-event-popup__view" v-if="!extendedProps.isLive" @click="cardRedirect(extendedProps.link)">
                  <span>View Full Session Details</span>
              </button>
              <button class="custom-template-agenda-event-popup__join" v-if="extendedProps.isLive"  @click="cardRedirect(extendedProps.link)">
                  <span>Join</span>
              </button>
              <p class="custom-template-agenda-event-popup__subtitle" v-if="extendedProps.speakers.length > 0">
                  <span>Speakers</span>
                  <i class="bi bi-chevron-down" v-if="extendedProps.speakers.length > 2" @click="toggleDropDown($event)"></i>
              </p>
              <div class="custom-template-agenda-event-popup__speakers-container" :class="{ short: extendedProps.speakers.length > 2 }" v-if="extendedProps.speakers.length > 0">
                  <div class="custom-template-agenda-event-popup__speaker" v-for="(speaker, index) in extendedProps.speakers" :key="index">
                      <img alt="speaker-image" v-if="speaker.photo" :src="'/' + speaker.photo" />
                      <div>
                          <span class="custom-template-agenda-event-popup__speaker-name">{{ speaker.name }} {{ speaker.surname }}</span>
                          <span class="custom-template-agenda-event-popup__speaker-job">{{ speaker.position }}</span>
                      </div>
                  </div>
              </div>
              <p class="custom-template-agenda-event-popup__subtitle" v-if="extendedProps.onDemandContent.length > 0 && !congress.minimalisticAgendaPopUp">
                  <span>On-demand content</span>
                  <i class="bi bi-chevron-down" v-if="extendedProps.onDemandContent.length > 2" @click="toggleDropDown($event)"></i>
              </p>
              <div class="custom-template-agenda-event-popup__demand-container" :class="{ short: extendedProps.onDemandContent.length > 2 }" v-if="extendedProps.onDemandContent.length > 0 && !congress.minimalisticAgendaPopUp">
                  <div class="custom-template-agenda-event-popup__content" v-for="(content, index) in extendedProps.onDemandContent" :key="index">
                      <a>
                          <i :class="getIcon(content)"></i>
                          <span>{{ content.title }}</span>
                      </a>
                  </div>
              </div>
          </div>
        </div>
        <div class="custom-template-agenda-event-popup__footer">
            <button class="custom-template-agenda-event-popup__on-demand" v-if="extendedProps.isOndemand">
                <span>On Demand</span>
            </button>
            <div class="custom-template-agenda-event-popup__add-calendar" v-if="!extendedProps.isLive && !extendedProps.isOndemand && (!extendedProps.isBookASeat|| (extendedProps.isBookASeat && isRegisteredData))">
                <!-- Button code -->
                <div title="Add to Calendar" class="addeventatc" @click="registerLogAddToCalendar">
                    <span>Add to Calendar</span>
                    <i class="bi bi-chevron-down"></i>
                    <span class="start">{{ extendedProps.eventStart }}</span>
                    <span class="end">{{ extendedProps.eventEnd }}</span>
                    <span class="timezone">{{timezone}}</span>
                    <span class="title">{{extendedProps.eventTitle}}</span>
                    <span class="description">{{plainTextDescription}}</span>
                    <span class="location">{{ this.getLocation(extendedProps.slug, extendedProps.eventId) }}</span>
                </div>
            </div>
            <div class="custom-template-agenda-event-popup__live" v-if="extendedProps.isLive && !extendedProps.isOndemand">
                <i class="bi bi-circle-fill"></i>
                <span>Live now</span>
            </div>
            <button class="custom-template-agenda-event-popup__register" v-if="extendedProps.isBookASeat && !isRegisteredData && !extendedProps.isOndemand && isInRegisterTime" @click="bookASeat($event, extendedProps.eventId)">
                <span>Register</span>
            </button>
            <button class="custom-template-agenda-event-popup__unregister" v-if="isRegisteredData && !extendedProps.isOndemand">
                <i class="bi bi-calendar-check"></i>
            </button>
        </div>
        <EventModalComponent v-show="showModal" @close="closeModal()" :start="extendedProps.eventStart" :end="extendedProps.eventEnd" :timezone="timezone" :title="extendedProps.eventTitle" :description="extendedProps.eventDescription" :plainTextDescription="plainTextDescription" :location="this.getLocation(extendedProps.slug, extendedProps.eventId)" :agendaSessionId="extendedProps.eventId"></EventModalComponent>
        <div class="custom-template-agenda-event-popup__backdrop" @click="$emit('close')"></div>

    </div>
</template>

<script>
    import moment from 'moment';
    import ActivityService from '../../../core/services/activity.service';
    import TranslationService from '../../../core/services/translation.service';
    import EventModalComponent from '../../modals/eventRegisterModal.vue';

    export default {

        props: [
          'clientX', 'clientY', 'popupEvent', 'timezone', 'extendedProps', 'congress'
        ],

        components: {
          EventModalComponent
        },

        data() {
          return {
            activitySvc: ActivityService.create(),
            isInRegisterTime: false,
            showModal: false,
            isRegisteredData: this.extendedProps.isRegistered,
            activitySvc: ActivityService.create(),
            plainTextDescription: this.getPlainTextDescription(this.extendedProps.eventDescription),
          };
        },

        created() {
          let finalTime = moment(this.extendedProps.eventStart).subtract(this.congress.minutesToOpenLiveSession, 'm').format("yyyy-MM-DD hh:mm:ss");

          if(finalTime > moment().format("yyyy-MM-DD hh:mm:ss")) {
            this.isInRegisterTime = true;
          }
        },

        mounted() {
          let a = document.querySelector('.custom-template-agenda-event-popup').style.top;
          let oldTop = parseInt(a.substring(0, a.length - 2));

          let eleHeight = document.querySelector('.custom-template-agenda-event-popup').offsetHeight;
          let scrHeight = window.innerHeight;
          let posHeight = this.popupEvent.y;

          if ((eleHeight + posHeight) > scrHeight) {
            // Popup shows out of screen
            let calc = (eleHeight + posHeight) - scrHeight;
            document.querySelector('.custom-template-agenda-event-popup').style.top = 'calc(' + (oldTop - calc) + 'px - 1.5rem)';
          }
        },

        methods: {
            async bookASeat(e, agendaId) {
                this.saveActivity('agenda-book-seat');
                const response = await axios.post(
                    '/agenda-session/attendee-registration',
                    {
                        userId: window.user.id,
                        agendaSessionId: agendaId,
                    }
                );

                if (response.status == 200) {
                    this.isRegisteredData = true;
                    this.$emit('changeIsRegisteredData', {isRegisteredData: this.isRegisteredData, agendaSessionId:this.extendedProps.eventId});
                    this.openModal();
                };
            },

            toggleDropDown(e) {
                e.target.classList.toggle("open");
                e.target.parentElement.nextElementSibling.classList.toggle("open");
            },

            cardRedirect(url) {
              if (this.extendedProps.preventOpen) {
                return;
              }

              if (this.extendedProps.type !== 'agenda_session' && this.extendedProps.type !== 'agenda') {
                this.onMultimediaClick();
                if (this.extendedProps.cards_behaviour == 'disable modal') {
                  return;
                }
                if (this.extendedProps.external_redirect) {
                  document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.extendedProps.external_url }));
                  // this.$root.$emit('leavingMMPModal', this.extendedProps.external_url);
                } else {
                  this.saveActivity(this.getActivityType());
                  return window.open(url, '_blank').focus();
                }
              } else {
                if (this.extendedProps.cards_behaviour == 'external link') {
                  document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.$attrs.external_link }));
                  // this.$root.$emit('leavingMMPModal', this.$attrs.external_link);
                } else if (this.extendedProps.cards_behaviour == 'open modal') {
                  let agendaURL = '';
                  if ((this.extendedProps.isLive && this.extendedProps.is_accessible && !this.extendedProps.external_congress)
                    || (this.extendedProps.isOndemand  && this.extendedProps.is_accessible && !this.extendedProps.external_congress && this.extendedProps.onDemandContent.length <= 0)) {
                    if(this.extendedProps.meeting_platform == 'ZOOM' && window.app.env.ENABLE_LIVE){
                      agendaURL = window.app.env.LIVE_URL+'/zoom/'+this.extendedProps.slug+'/'+this.extendedProps.eventId+'?encryptsKey=live-encrypts'+window.user.id;
                    }else if(this.extendedProps.meeting_platform == 'WEBEX' && window.app.env.ENABLE_LIVE){
                      agendaURL = window.app.env.LIVE_URL+'/webex/'+this.extendedProps.slug+'/'+this.extendedProps.eventId+'?encryptsKey=live-encrypts'+window.user.id;
                    }else{
                      agendaURL = TranslationService.transRoute('event-detail-live', {
                        slug: this.extendedProps.slug,
                        agendaSessionId: this.extendedProps.eventId
                      });
                    }
                    this.saveActivity('live');
                  } else {
                    agendaURL = TranslationService.transRoute('event-agenda-detail', {
                      slug: this.extendedProps.slug,
                      agendaSessionId: this.extendedProps.eventId
                    });
                    this.saveActivity('agenda');
                  }
                  return window.open(agendaURL, '_blank').focus();
                }
              }
            },

            onMultimediaClick() {
              const dataTracks = this.$attrs.dataTracks || [];
              // Save item to localStorage:
              localStorage.setItem(`datatracks-m-${this.extendedProps.eventId}`, JSON.stringify(dataTracks));
              return true;
            },

            saveActivity(event) {
              let params = {
                title: this.extendedProps.eventTitle,
                model_type: this.extendedProps.type,
                model_id: this.extendedProps.eventId,
                congress_id: window.app.event ? window.app.event.id : null
              };
              this.activitySvc.saveActivity(event, params);
            },

            getActivityType() {
              switch (this.extendedProps.type) {
                case 'multimedia':
                  return 'open-multimedia';
                case 'congress':
                  return 'enter-congress';
                case 'journey':
                  return 'enter-kj';
              }
            },

            getFormatData() {
              let formattedStartDate = moment(this.extendedProps.eventStart).format('Do, MMMM YYYY · h:mm');
              let formattedEndDate = moment(this.extendedProps.eventEnd).format('h:mm a');
              return formattedStartDate + ' - ' + formattedEndDate;
            },

            openModal() {
              this.showModal = true;
            },

            closeModal() {
              this.showModal = false;
            },

            registerLogAddToCalendar() {
              this.saveActivity('agenda-add-to-calendar');
            },

            getIcon(document) {
                if (document.multimedia_type_id == 5) {
                    return 'bi bi-broadcast-pin';
                } else if (document.multimedia_type_id == 4) {
                    return 'bi bi-file-play';
                } else if (document.external_link) {
                    return 'bi bi-link';
                } else {
                    return 'bi bi-file-earmark-text';
                }
            },

            getPlainTextDescription(description) {
              let plainTextDescription = '';
              if (description) {
                plainTextDescription = description.replace(/<[^>]+>/g, '')
                                                  .replace(/&nbsp;/g, ' ')
                                                  .replace(/&amp;/g, '&');
              }
              return plainTextDescription;
            },

            getLocation(slug, id){
              let agendaURL = '';
              agendaURL = TranslationService.transRoute('event-detail-live', {
                slug: slug,
                agendaSessionId: id
              });
              return window.app.env.APP_URL+agendaURL;
            },
        }
    }
</script>
