import ConnectmiEventHeader from "../objects/v4/modules/connectmi2022/ConnectmiEventHeader.vue"
import AuroraEffect from "../objects/v4/modules/connectmi2022/components/AuroraEffect.vue"
import ConnectmiEventWelcome from "../objects/v4/modules/connectmi2022/ConnectmiEventWelcome.vue"
import ConnectmiEventAgenda from "../objects/v4/modules/connectmi2022/ConnectmiEventAgenda.vue"
import ConnectmiEventCommittee from "../objects/v4/modules/connectmi2022/ConnectmiEventCommittee.vue"
import ConnectmiEventSpeakers from "../objects/v4/modules/connectmi2022/ConnectmiEventSpeakers.vue"
import ConnectmiEventContent from "../objects/v4/modules/connectmi2022/ConnectmiEventContent.vue"
import ConnectmiEventFooter from "../objects/v4/modules/connectmi2022/ConnectmiEventFooter.vue"

const vueComponents = [
    ['ConnectmiEventHeader', ConnectmiEventHeader],
    ['AuroraEffect', AuroraEffect],
    ['ConnectmiEventWelcome', ConnectmiEventWelcome],
    ['ConnectmiEventAgenda', ConnectmiEventAgenda],
    ['ConnectmiEventCommittee', ConnectmiEventCommittee],
    ['ConnectmiEventSpeakers', ConnectmiEventSpeakers],
    ['ConnectmiEventContent', ConnectmiEventContent],
    ['ConnectmiEventFooter', ConnectmiEventFooter],
]

export default vueComponents