<template>
    <ModalComponent v-show="showModal" @close="closeWithoutAccept" id="tc-modal">
        <template v-slot:header>&nbsp;</template>
        <template v-slot:body>
            <div class="tc-container">

                <TermConditionsQuestions ref="termsAndQuestionsComponent" :loggedUser="true" :country="country" :slug="slug" :newsletter-accepted="newsletterAccepted ?? false"></TermConditionsQuestions>

                <div class="tc-accept">
                    <button :disabled="!checkedTC" class="v4-btn-primary tc-button" @click="submit">
                        {{ t('general.accept') }}
                    </button>
                </div>
            </div>
            
        </template>
    </ModalComponent>
</template>

<script>
import ModalComponent from '../internal-congress/ModalComponent.vue';
import TranslateService from '../../core/services/translate.service.js';
import TermConditionsQuestions from '../register/components/TermConditionsQuestions.vue';
import axios from 'axios';

export default {
    components: {
      TermConditionsQuestions, ModalComponent
    },
    props: {
        country: Number,
        slug: String,
        newsletterAccepted: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            translator: TranslateService.create(),
            showModal: false,
            extraQuestions: null,
            checkedTC: false,
            tcArray: [],
            newsletter: false,
        }
    },
    mounted() {
        document.addEventListener("termConditionsAndQuestions", (termAndQuestionsForm) => {
            const detail = termAndQuestionsForm.detail;
            if (detail) {
                const { form: { validStatus, termsChecked, questionResponses, newsletter }, termsAndConditions } = detail;
                this.checkedTC = validStatus;

                if (termsAndConditions) {
                    this.tcArray = termsAndConditions.map(item => ({
                        'id': item.id,
                        'scope': item.scope
                    }));
                }
                
                this.extraQuestions = questionResponses ?? null;
                this.newsletter = newsletter ?? false;
                this.showModal = !this.objectEmpty(this.extraQuestions) || this.tcArray.length > 0;
            }
        });
    },
    methods: {
        close() {
            this.showModal = false;
        },
        objectEmpty(obj) {
            return obj === undefined || obj === null || Object.keys(obj).length === 0;
        },
        closeWithoutAccept() {
            document.location.href = document.location.origin;
        },
        t(tag) {
            return this.translator.translate(tag);
        },
        submit(){
            this.checkedTC = false;
            const data = {
                slug: this.slug,
                terms: this.tcArray,
                questionResponses: this.extraQuestions,
                newsletter: this.newsletter,
            };

            axios.post('/termCondition/save-term-condition', data)
            .then(
                res => {
                    console.log(res);
                    this.close();
                }
            ).catch(e => {
                this.checkedTC = true;
                console.error(e);
            });
        }
    }
}
</script>
