<template>
    <div class="slider-pagination-row">
        <div class="v4-loading" :class="{hidden: isLoading }">
            <div class="v4-carousel" :class="{[carouselClassname]: true}">
                <slot name="items"></slot>
            </div>
        </div>

        <!-- Dots carousel -->
        <div v-if="!isNumberCarousel && !customArrows" class="v4-carousel-controls d-flex v4-loading"
            :class="{hidden: isLoading || !navigationEnabled}">
            <a href="javascript:void()" ref="prev" class="v4-carousel-controls--item prev-arrow" style="margin-top: -4px !important;"
                @click="handleContentClick('left arrow click', 'left arrow', 'slider', 'web')">
                <!-- <i class="bi bi-arrow-left"></i> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
                </svg>
            </a>
            <div :class="'slick_dots slick_dots_' + random" ref="slickContainer"></div>
            <a href="javascript:void()" ref="next" class="v4-carousel-controls--item next-arrow ml-auto" style="margin-top: -4px !important;"
                @click="handleContentClick('right arrow click', 'right arrow', 'slider', 'web')">
                <!-- <i class="bi bi-arrow-left-circle"></i> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                </svg>
            </a>
        </div>
        <!-- Number carousel -->
        <div v-else-if="!customArrows" class="v4-carousel-controls d-flex v4-loading custom-number-carousel"
            :class="{hidden: isLoading || !navigationEnabled}">
            <a href="javascript:void()" ref="prev" class="v4-carousel-controls--item prev-arrow"
                @click="handleContentClick('left arrow click', 'left arrow', 'slider', 'web')">&lt;</a>
            <div :class="'slick_dots slick_dots_' + random" ref="slickContainer"></div>
            <a href="javascript:void()" ref="next" class="v4-carousel-controls--item next-arrow"
                @click="handleContentClick('right arrow click', 'right arrow', 'slider', 'web')">&gt;</a>
        </div>

    </div>
</template>

<script>
import GoogleAnalytics from '../../GoogleAnalytics.js';
import $ from 'jquery';

export default {
    props: {
        name: String,
        slidesToShow: Number,
        rowsToShow: Number,
        slidesToScroll: Number,
        isLoading: Boolean,
        isNavigationEnabled: Boolean,
        numberCarousel: Boolean,
        isResponsive: Boolean,
        autoplaySpeed: Number,
        customArrows: {
            type: Object,
            required: false,
        },
        infinite : {
            type: Boolean,
            default: true,
            required: false,
        },
        autoplay : {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        var displayCount = 2;
        if(this.isResponsive){
            displayCount = 1;
        }
        return {
            slidesShow: this.slidesToShow ? this.slidesToShow : 1,
            slidesScroll: this.slidesToScroll ? this.slidesToScroll : 1,
            rowsShow: this.rowsToShow ? this.rowsToShow : 1,
            navigationEnabled: !!this.isNavigationEnabled ? this.isNavigationEnabled : true,
            isNumberCarousel: !!this.numberCarousel ? this.numberCarousel : false,
            random: this.randomNumber(),
            responsiveCount: displayCount
        }
    },

    computed: {
        carouselClassname() {
            return `v4-carousel-${this.name}`;
        }
    },

    mounted() {
        this.$nextTick(function () {
            $(`.${this.carouselClassname}`).not('.slick-initialized').slick(this.getSlickConfig());
        });
        if (!this.customArrows) {
            this.$refs.slickContainer.addEventListener('click', this.onSlickDotClick);
        }
    },
    beforeDestroy() {
        if (!this.customArrows) {
            this.$refs.slickContainer.removeEventListener('click', this.onSlickDotClick);
        }
    },
    methods: {
        prevArrowEl() {
            return this.$refs['prev']
        },
        nextArrowEl() {
            return this.$refs['next']
        },
        getSlickConfig() {
            return {
                dots: true,
                slidesToShow: this.slidesShow,
                slidesToScroll: this.slidesScroll,
                autoplay: this.autoplay,
                autoplaySpeed: this.autoplaySpeed * 1000,
                rows: this.rowsShow,
                prevArrow: this.customArrows ? this.customArrows.prev : this.prevArrowEl(),
                nextArrow: this.customArrows ? this.customArrows.next : this.nextArrowEl(),
                appendDots: $(".slick_dots_" + this.random),
                speed: 500,
                infinite: this.infinite,
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: this.responsiveCount,
                        slidesToScroll: this.responsiveCount,
                        }
                    }, {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }]
            }
        },
        busAvailable() {
            //this.bus.$on('remove', this.remove);
            //this.bus.$on('reload', this.reload);
        },
        remove() {
            $(`.${this.carouselClassname}`).slick('unslick');
        },
        reload() {
            $(`.${this.carouselClassname}`).slick(this.getSlickConfig());
            $(`.${this.carouselClassname}`).slick('setPosition')
        },
        dataFilter(arraydata) {
            $(".slider").not('.slick-initialized').slick();
            $(`.${this.carouselClassname}`).slick('slickFilter', arraydata);
        },
        refreshSlick() {
            this.remove();
            this.reload();
        },
        resetForm() {
            this.form.name = '';
        },
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        randomNumber: function () {
            return Math.floor(Math.random()*(9999-1000+1)+1000);
        },
        onSlickDotClick(event) {
            const liElement = event.target.closest('li');
            if (liElement && liElement.parentElement.classList.contains('slick-dots')) {
                const index = Array.from(liElement.parentElement.children).indexOf(liElement);
                this.handleContentClick('dot click', 'dot', 'slider', 'web', index + 1);
            }
        },
        handleContentClick(clickAction, contentName, componentType, contentCategory, contentPosition = '') {
            if (typeof GoogleAnalytics !== 'undefined' && typeof GoogleAnalytics.sendPromoClickEvent === 'function') {
                GoogleAnalytics.sendContentClickEvent(clickAction, contentName, componentType, contentCategory, contentPosition);
            } else {
                console.error('GoogleAnalytics.sendContentClickEvent is not defined');
            }
        },
    }
}
</script>

<style scoped>
.v4-loading {
    transition: 200ms linear all;
}
.v4-loading.hidden {
    opacity: 0;
}
</style>
