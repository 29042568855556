<template>
  <section class="speakers-related-content mt-3">
    <h3>{{t('speakers.related-content')}}</h3>
    <V4Carousel
      :slidesToShow="2"
      :slidesToScroll="2"
      :name="'agenda'"
      :isLoading="isLoading"
      :numberCarousel="false"
      v-if="!isLoading"
    >
      <template v-slot:items>
        <div v-for="(item, i) in formattedRelatedContent" v-bind:key="i">
            <V4Card v-bind="item"></V4Card>
        </div>
      </template>
    </V4Carousel>
</section>
</template>

<script>
import TranslateService from "../../../core/services/translate.service";
import AgendaFactory from "../../agenda/factories/Agenda.factory";
import V4Carousel from "../../carousel/V4Carousel.vue";
import ContentFactory from "../../content/factories/Content.factory";
export default {
  name: "SpeakersRelatedContent",
  components: { V4Carousel },
  props: {
    relatedContent: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    let isLoading = true;

    const formattedRelatedContent = props.relatedContent.map(ev => {
        if (ev.type == "multimedia") {
            return ContentFactory.get(ev);
        } else {
            return AgendaFactory.get(ev);
        }
    });

    isLoading = false;
    const translator = TranslateService.create();
    const t = (tag) => translator.translate(tag);
    const isMobile = () =>
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    return {
      isLoading,
      t,
      isMobile,
      formattedRelatedContent,
    };
  },
};
</script>
  