<template>
    <div v-if="this.list.length > 0">
        <div v-if="isLoading">{{ t("congress.loading_text")}}</div>
        <div v-if="!isLoading">
            <h4 class="v4-subtitle m-0">{{ t("congress.media_content") }}</h4>
            <div v-if="!isMobile()">
                <!-- DESKTOP VERSION -->
                <V4Carousel  :slidesToShow="3" :slidesToScroll="3" :name="'agenda'" :isLoading="isLoading" v-if="!isLoading">
                    <template v-slot:items>
                        <div v-for="(item, i) in list" v-bind:key="i">
                            <AgendaMediaContentItem v-bind="item" />
                        </div>
                    </template>
                </V4Carousel>
            </div>
            <div v-else>
                <!-- MOBILE VERSION -->
                <V4Carousel  :slidesToShow="1" :slidesToScroll="1" :name="'clinical-cases__carousel'" :isLoading="isLoading" v-if="!isLoading">
                    <template v-slot:items>
                        <div v-for="(item, i) in list" v-bind:key="i">
                            <AgendaMediaContentItem v-bind="item" />
                        </div>
                    </template>
                </V4Carousel>
            </div>
        </div>
    </div>
</template>

<script>
import AgendaMediaContentItem from './AgendaMediaContentItem.vue';
import TranslateService from '../../core/services/translate.service.js';
import V4Carousel from '../carousel/V4Carousel.vue';
import axios from 'axios';

    export default {
        name: 'AgendaMediaContent',
        props: {
            agendasessionid: String,
           
        },
        components: {
            AgendaMediaContentItem, V4Carousel
        },
        created:function(){
           axios
                .get(`/agenda-session/multimedia`, {params:{
                    id: this.agendasessionid, 
                    type : "media",     
                }})
                .then(res => {
                   var result = JSON.parse(JSON.stringify(res));
                   this.list = result.data.data;  
                   this.status = true;  
                   this.isLoading = false;
                   if(this.list && this.list.length == 0) {
                        document.dispatchEvent(new CustomEvent("showAgendaImage"));
                    //    this.$root.$emit('showAgendaImage');
                   }
                })
                .catch(err => {
                    console.warn(err)
                    this.status = false;
                });
        },
        methods: {
             isMobile() {
                return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
            },
            t(tag){
                return this.translator.translate(tag)
            }
        },
        data:function(){
            return{
                list : Array,
                // bus: new Vue(),
                isLoading: true,
                translator : TranslateService.create()
            }
        }        
    }
</script>
