import ConnectmiContentHeader from '../objects/v4/modules/connectmi2022/ConnectmiContentHeader.vue';
import MultimediaHeader from '../objects/v4/modules/multimedia/MultimediaHeader.vue';
import VideoPlayer from '../objects/v4/modules/multimedia/VideoPlayer.vue';
import DocumentViewer from '../objects/v4/modules/multimedia/DocumentViewer.vue';
import SpeakersSection from '../objects/v4/modules/multimedia/SpeakersSection.vue';
import RelatedContent from '../objects/v4/modules/multimedia/RelatedContent.vue';
import ContentTestModal from '../objects/v4/modules/modals/ContentTestModal.vue';
import ContentSearch from '../objects/v4/modules/content/content-search/ContentSearch.vue';
import AskQuestionModal from '../objects/v4/modules/congresslive/AskQuestionModal.vue';

const vueComponents = [
    ['ConnectmiContentHeader', ConnectmiContentHeader],
    ['MultimediaHeader', MultimediaHeader],
    ['VideoPlayer', VideoPlayer],
    ['DocumentViewer', DocumentViewer],
    ['SpeakersSection', SpeakersSection],
    ['RelatedContent', RelatedContent],
    ['ContentTestModal', ContentTestModal],
    ['ContentSearch', ContentSearch],
    ['AskQuestionModal', AskQuestionModal],
]

export default vueComponents