<template>
  <div class="slider-pagination-row">
    <div v-if="isLoading">
      {{ t('congresses.loading') }}
    </div>
    <template v-if="!isLoading && list.length">
      <V4Carousel
        v-bind="{ slidesToShow: 1, slidesToScroll: 1, name: 'oursymposia', isLoading: false, isResponsive: true }"
        class="custom-congress-carousel"
      >
        <template v-slot:items>
          <div v-for="(item, i) in list" :key="i" class="slider-content-row">
           <span v-title="item.title" class="title-text secondary-color text-bold symposia-main-slider">    
              <div style="overflow:hidden; text-overflow:ellipsis;">{{ item.title }}</div>
            </span>
            <PresentationTabs
              :symposiaid="item.id"
              :symposianame="item.title"
              :symposiadesc="item.description"
              :thumbimg="item.image"
              :formattedDate="item.date_formatted"
            ></PresentationTabs>
          </div>
        </template>
      </V4Carousel>
    </template>
    <div v-if="!isLoading && !list.length">
      <!-- <center> -->
        <p>{{ t('congresses.no_symposias') }}</p>
      <!-- </center> -->
    </div>
  </div>
</template>

<script>
import TranslateService from '../../../core/services/translate.service.js';
import AgendaFactory from '../../agenda/factories/Agenda.factory';
// import vTitle from 'vuejs-title'
import V4Carousel from '../../carousel/V4Carousel.vue';
import PresentationTabs from './PresentationTabs.vue';
import $ from 'jquery';

/* Vue.use(vTitle,{
  bgColor: "#b2b2b2",
  textColor:"#001c37",
  maxWidth: '100%'
}) */

export default {
  name: 'SymposiaList',
  components: {
    V4Carousel,
    PresentationTabs
  },
  props: {
    link: String
  },
  data() {
    return {
      isLoading: true,
      list: [],
      translator: TranslateService.create()
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const results = await fetch(`/congresses/agenda?simposium=1&slug=${this.link}`).then(res => res.json());
      this.list = results.data.map(ev => {
        return AgendaFactory.get(ev);
      });
      this.list = results.data;
      this.isLoading = false;
      if (this.list.length == 1) {
        $('#symposiatitle').html(`${this.t('general.our_symposium')}`);
      } else if (this.list.length > 1) {
        $('#symposiatitle').html(`${this.t('general.our_symposia')} (${this.list.length})`);
      } else if (!this.list.length) {
        $('#symposia').css('display', 'none');
        $('#symposia_menu').css('display', 'none');
      }
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    t(tag) {
      return this.translator.translate(tag);
    }
  }
};
</script>
