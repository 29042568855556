export default {
    get(steering_committee) {
        return {
            id: steering_committee?.id ?? '',
            name: steering_committee?.name ?? '',
            surname: steering_committee?.surname ?? '',
            photo: steering_committee?.photo ?? '',
            position: steering_committee?.position ?? '',
            bio: steering_committee?.bio ?? false,
            short_bio: steering_committee?.short_bio ?? '',
            institution: steering_committee?.institution ?? '',
            video: steering_committee?.video ?? ''
        };
    },
    
};