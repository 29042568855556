<template>
  <section class="gratitude-board pt-4" v-if="isDisplay" id="gratitudeboard">
    <div class="gratitude-head">
     <h2 class="text-center">{{t("congress.gratitude")}} {{t("congress.board")}}</h2>
   </div>
   <div class="gratitude-list" v-if="this.gratitude_board != null">  
    <iframe :src="this.url" frameborder="0" allow="camera;microphone;geolocation" style="width:100%;height:608px;display:block;padding:0;margin:0">
    </iframe>
  </div>  
</section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import $ from 'jquery'; 
export default {
  name: 'gratitude',
  data() {
    return { 
     isDisplay: true,
     translator: TranslateService.create(),
     url : ""
   }
 },
 props: {
    gratitude_board : String,
 },
  mounted() {
    this.getGratitudeBoard();
  },

 methods:{
    getGratitudeBoard(){
      if ( this.gratitude_board != null && typeof this.gratitude_board != "undefined") {
         this.url = "https://padlet.com/embed/" + this.gratitude_board
      }else{
        this.isDisplay = false;
        $('#gratitudeboard_menu').css('display','none');
      }
    },    
    t(tag) {
      return this.translator.translate(tag);
    }
}
}
</script>
