<template>
<div>
    <V4Carousel v-if="!isMobile()" :slidesToShow="4" :slidesToScroll="4" :name="'steering-commitee'" :isLoading="false">
        <template v-slot:items>
            <div v-for="(item, i) in items" v-bind:key="i">
                <SteeringComiteeCard v-bind:speaker="item" />
            </div>
        </template>
    </V4Carousel>
    <V4Carousel v-else :slidesToShow="1" :slidesToScroll="1" :name="'steering-commitee'" :isLoading="false">
        <template v-slot:items>
            <div v-for="(item, i) in items" v-bind:key="i">
                <SteeringComiteeCard v-bind:speaker="item"/>
            </div>
        </template>
    </V4Carousel>
    <SpeakerModel v-show="isModalVisible" @close="closeModal">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body><div class="col-sm-12">
        <div class="speak-user">
          <div class="speaker-img"><img :src="display_pic" @error="imageUrlAlt"></div>
          <div class="user-name-speaker">
           <h4>{{full_name}}</h4>
           <p class="new-sec_info">{{institution_info}}</p>
           <p class="infodesc" v-html="bio_info"></p>
         </div>
       </div>
     </div></template>
     <template v-slot:footer>&nbsp;</template>
   </SpeakerModel>
</div>
</template>

<script>
import V4Carousel from "../carousel/V4Carousel.vue";
import SpeakerModel from "../internal-congress/ModalComponent.vue";
import SteeringComiteeCard from "./SteeringComiteeCard.vue";

export default {
    name: 'SteeringComitee',
    components: {
        SpeakerModel,
        SteeringComiteeCard,
        V4Carousel
    },
    props: {
        items: Array
    },
    mounted() {
        document.addEventListener("showSpeakerModal", (speaker) => {
            this.showModal(speaker.detail);
        });
    },
    data() {
        return {
            // bus: new Vue(),
            isModalVisible: false,
            full_name: '',
            display_pic: '',
            bio_info: '',
            position_info: '',
            institution_info: '',
        }
    },

    methods: {
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        imageUrlAlt(event) {
            event.target.src = "/images/v4/assets/avatar.svg";
        },
        getSpeakerItemInfo(item) {
            let info = '';
            if(item.position) info = item.position;
            if(item.position && item.institution) info = info + ' - ';
            if(item.institution) info = info + item.institution;
            return info;
        },
        parseImage(img) {
            if (img && img.charAt(0) != '/') {
                    img = '/' + img;
            }
            return img;
        },
        showModal(i) {
            this.full_name = i.name+' '+i.surname;
            this.bio_info = i.bio;
            this.institution_info = i.institution;
            this.display_pic = this.parseImage(i.photo);
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        }
    }
}
</script>
