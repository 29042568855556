<template>
  <div>
    <div v-if="isLoading">{{ t("general.loading") }}...</div>

    <div v-if="results?.length && !isLoading" class="row sort-search">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 m-0 p-0" v-for="(content,index) in results" :key="content.id">
        <div class="v4-card-search-result" v-if="cardType === 'default'"><V4Card v-bind="content" @click.native="sendResults(content, index)"></V4Card></div>
        <div v-else><CardThumbnail v-bind="content"></CardThumbnail></div>
      </div>
    </div>

    <div class="my-4">
      <V4Pagination
        @ChangePageWasClicked="pageClicked"
        v-if="resultsCount > size"
        v-bind:current="1"
        v-bind:totalItems="resultsCount"
        v-bind:maxResultsPerPage="size"
      ></V4Pagination>
    </div>

    <ResultsNotFound v-if="!results?.length && !isLoading" :showRelated="true"></ResultsNotFound>
  </div>
</template>

<script>
import EventFactory from "../events/factories/Event.factory";
import ContentFactory from "../content/factories/Content.factory";
import TranslateService from "../../core/services/translate.service";
import V4Pagination from "../pagination/V4Pagination.vue";
import CardThumbnail from "../custom-template/components/CardThumbnail.vue";
import V4Card from "../card/V4Card.vue";
import ResultsNotFound from "../results-not-found/ResultsNotFound.vue";
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
  name: "SearchFormResults",
  components: {
    V4Pagination, CardThumbnail, V4Card, ResultsNotFound
  },
  props: {
    size: Number,
    card_type: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      cardType: this.card_type,
      isLoading: true,
      results: [],
      resultsCount: 0,
      entity: [],
      translator: TranslateService.create(),
    };
  },

  mounted() {
    document.addEventListener("SearchHomeLoading", () => {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }))
      this.isLoading = true;
    });

    document.addEventListener("searchHomeLoaded", (event) => {
      this.resultsLoaded(event.detail);
    });
  },

  methods: {
    resultsLoaded(data) {
      this.isLoading = false;
      const results = data[0];
      const resultsCount = data[1];

      this.results = results.map((ev) => {
          if (ev.type == "multimedia") {
            return ContentFactory.get(ev);
          } else {
            return EventFactory.get(ev);
          }
      });
      this.resultsCount = resultsCount;
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }))
      document.dispatchEvent(new CustomEvent("SearchFormResultsCount", { detail: this.resultsCount }))
    },

    pageClicked(paginationData) {
      document.dispatchEvent(new CustomEvent("SearchFormResultsPageChanged", { detail: paginationData }));
    },
    t(tag) {
      return this.translator.translate(tag);
    },
        sendResults(content, index) {
            let searchTerm = '';
            let searchCategory = '';
            document.addEventListener("searchTerm", (event) => {
              searchTerm = event.detail;
            });
            document.addEventListener("searchCategory", (event) => {
              searchCategory = event.detail;
            });

            GoogleAnalytics.sendOpenContentEvent(content.title, 'card');
            GoogleAnalytics.sendSearchResultsEvent(content.title, searchTerm, index.toString(), searchCategory);
        }
  },
};
</script>
