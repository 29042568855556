<template>
    <section class="assistance-section">
        <AssistanceMenu v-if="showAssistanceMenu" :activeUser="activeUser"></AssistanceMenu>
        <assistanceForm v-if="showAssistanceForm" :selectedForm="selectedForm" :user="user"></assistanceForm>
        <button class="assistance-section__btn" @click="displayAssistanceMenu()">
            <svg v-if="!showAssistanceMenu && !showAssistanceForm" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="url(#paint0_linear_2320_65)"/>
                <mask id="mask0_2320_65" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="40" height="40">
                <rect x="10" y="10" width="40" height="40" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_2320_65)">
                    <path d="M28.2758 36.2821C28.2758 34.3955 28.4932 32.951 28.9279 31.9488C29.3626 30.9466 30.2703 29.8355 31.6508 28.6155C32.7256 27.6583 33.5402 26.7902 34.0946 26.0113C34.649 25.2324 34.9263 24.3494 34.9263 23.3621C34.9263 22.0524 34.4803 20.9638 33.5883 20.0963C32.6961 19.2288 31.4679 18.7951 29.9038 18.7951C28.4765 18.7951 27.3654 19.1774 26.5704 19.9421C25.7757 20.7071 25.1721 21.5609 24.7596 22.5034L21.7179 21.1667C22.3654 19.5706 23.3761 18.2399 24.75 17.1746C26.1239 16.1096 27.8418 15.5771 29.9038 15.5771C32.596 15.5771 34.6681 16.3362 36.12 17.8542C37.572 19.3723 38.2979 21.1741 38.2979 23.2596C38.2979 24.563 38.0154 25.7131 37.4504 26.7101C36.8851 27.7067 36.0278 28.7446 34.8783 29.8238C33.4317 31.1508 32.5358 32.1971 32.1908 32.963C31.8456 33.7291 31.6729 34.8355 31.6729 36.2821H28.2758ZM29.9038 45.8334C29.2221 45.8334 28.635 45.5871 28.1425 45.0946C27.65 44.6021 27.4038 44.0151 27.4038 43.3334C27.4038 42.6517 27.65 42.0646 28.1425 41.5721C28.635 41.0796 29.2221 40.8334 29.9038 40.8334C30.5854 40.8334 31.1725 41.0796 31.665 41.5721C32.1575 42.0646 32.4038 42.6517 32.4038 43.3334C32.4038 44.0151 32.1575 44.6021 31.665 45.0946C31.1725 45.5871 30.5854 45.8334 29.9038 45.8334Z" fill="#222222"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_2320_65" x1="60" y1="7.15256e-06" x2="7.15256e-06" y2="60" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00BCE4"/>
                        <stop offset="1" stop-color="#ECBD0B"/>
                    </linearGradient>
                </defs>
            </svg>
            <svg v-if="showAssistanceMenu || showAssistanceForm" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="30" fill="url(#paint0_linear_2320_173)"/>
                <mask id="mask0_2320_173" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="40" height="40">
                    <rect x="10" y="10" width="40" height="40" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_2320_173)">
                    <path d="M20.6667 41.0896L18.9104 39.3333L28.2438 30L18.9104 20.6667L20.6667 18.9104L30 28.2437L39.3333 18.9104L41.0896 20.6667L31.7563 30L41.0896 39.3333L39.3333 41.0896L30 31.7562L20.6667 41.0896Z" fill="#1C1B1F"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_2320_173" x1="60" y1="7.15256e-06" x2="7.15256e-06" y2="60" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#00BCE4"/>
                        <stop offset="1" stop-color="#ECBD0B"/>
                    </linearGradient>
                </defs>
            </svg>
        </button>
    </section>
</template>

<script>
import { ref, onMounted, toRef } from 'vue';
import TranslateService from '../../../core/services/translate.service.js';
import AssistanceMenu from './AssistanceMenu.vue';
import AssistanceForm from './AssistanceForm.vue';

export default {
    name: 'AssistanceButton',
    components: { AssistanceMenu, AssistanceForm },
    props: {
        activeUser: Boolean,
        user: Object,
    },
    setup(props) {
        //Computed for static values already calculated.
        //Ref for variables that u can modify during execution.
        const translator = TranslateService.create();
        const t = (tag) => translator.translate(tag);
        const showAssistanceMenu = ref(false);
        const showAssistanceForm = ref(false);
        const activeUser = props.activeUser;
        const user = props.user;
        const selectedForm = ref('');

        onMounted(() => {
            document.addEventListener("displayAssistanceForm", (event) => {
                if (event.detail.show === true) {
                    showAssistanceMenu.value = false;
                    selectedForm.value = event.detail.type;
                }
                
                showAssistanceForm.value = event.detail.show;
            });
        });

        const displayAssistanceMenu = () => {
            if (showAssistanceForm.value) {
                showAssistanceMenu.value = showAssistanceForm.value = false;
            } else {
                showAssistanceMenu.value = !showAssistanceMenu.value;
            }
        };

        const isMobile = () => {
            try {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            } catch (error) {
                console.error('Error determining if the device is mobile:', error);
                return false;
            }
        };

        return {
            t,
            isMobile,
            showAssistanceMenu,
            displayAssistanceMenu,
            activeUser,
            user,
            showAssistanceForm,
            selectedForm,
        };
    },
}
</script>