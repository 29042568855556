<template>
  <div>
    <section class="webinar-congress container">
      <div class="container timesche">
        <div class="my-4 d-flex justify-content-center">
          <span class="live_now" v-if="webinar.is_live">{{ t('congress.live-now') }}!</span>
        </div>
        <p v-if="!webinar.is_live" class="time_details">
          {{ this.escapeHtml(getDay()) }} | {{ momentStart }} {{ momentEnd }}
        </p>
        <h3 class="main_title" v-html="basicInfo.name"></h3>
        <div class="multimedia-header__keywords">
          <a v-for="(kw, index) in keywordList" :key="index" class="keyword" href="javascript:void(0)"  @click.stop="filterKeywords(kw.id, kw.name)">#{{ kw.name }}</a>
        </div>
        <p v-if="!readmore" class="description_details" v-html="basicInfo.substring"></p>
        <p v-if="readmore" class="description_details" v-html="basicInfo.description"></p>
        <template v-if="basicInfo.showread">
          <a
            v-if="!readmore"
            href="javascript:void(0);"
            class="webiner-atag v4-action-link"
            v-on:click="hideShortdesc"
            >{{ t('congress.read-more') }}</a
          >
        </template>
        <a v-if="readmore" href="javascript:void(0);" class="webiner-atag v4-action-link" v-on:click="showShortdesc">{{
          t('congress.read-less')
        }}</a>
        <div class="current-event-register" v-if="basicInfo.pre_register">
          <a v-on:click="registerLink" class="current-event-register-text"
            >{{ t('congress.register-now') }} <span><em class="bi bi-box-arrow-up-right"></em></span
          ></a>
        </div>
        <div class="current-event-register" v-if="webinar.is_live">
          <a v-on:click="openLive" class="current-event-register-text">{{ t('pre-home.join') }}</a>
        </div>
        <div class="current-event-register" v-if="webinar.is_past && webinar.keep_opened_session">
          <a v-on:click="openLive" class="current-event-register-text">{{ t('general.on-demand') }}</a>
        </div>
      </div>
    </section>
    <section class="container">
      <div
        class="row webinar-cases__tabsheet__container webinar-cases__tabsheet__container speakers-right-box"
        id="tabsheet-1"
        style=""
      >
        <div class="webinar-cases__description col-sm-12 col-md-12 col-lg-8">
          <template v-if="webinar.is_live || (webinar.is_past && webinar.keep_opened_session)">
            <img :src="parseImage(webinar.thumbnail_image)" alt="live_thumbnail" @click="openLive" />
          </template>
          <template v-else>
            <img :src="parseImage(webinar.thumbnail_image)" alt="live_thumbnail" @click="openAgenda" />
          </template>
        </div>
        <div
          v-if="!hideSpeakers"
          class="left-top-speaker speaker_block webinar-cases__right-panel__panel col-sm-12 col-md-12 col-lg-4"
        >
          <h1>{{ t('congress.speakers') }}</h1>
          <div class="preset-scroll">
            <ul class="speakers_lister">
              <li class="row" v-for="(speaker, j) in speakers" v-bind:key="j">
                <a @click="showModal(speaker)">
                  <img :src="parseImage(speaker.photo)" alt="speaker" @error="imageUrlAlt" />
                  <h2>{{ speaker.name }} {{ speaker.surname }}</h2>
                  <p v-html="speaker.position"></p>
                </a>
              </li>
            </ul>
            <div class="speaker_file_download" v-if="showMultimedia">
              <a @click="getMultimediaURL(multimedia)" v-for="(multimedia, k) in multimediaList" v-bind:key="k">
                <span>
                  <em :class="getIcon(multimedia)"></em>
                </span>
                <span v-html="multimedia.title"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="agenda_block container mt-3">
      <div class="webinar">
        <h1>{{ t('congress.agenda') }}</h1>
        <ul>
          <li>
            <p>{{ momentStart }} {{ momentEnd }}</p>
            <h2 v-html="webinar.title"></h2>
            <h3 v-html="webinar.description"></h3>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import moment from 'moment';
import Utils from '../../Utils';
import CongressBasicFactory from './factories/CongressBasic.factory';
import PageStayController from '../../page-stay/PageStayController';
import TranslationService from '../../core/services/translation.service';
import DateService from '../../core/services/date.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import axios from 'axios';

export default {
  name: 'WebinarCasesItem',
  data() {
    return {
      hideSpeakers: false,
      hideVideo: false,
      readmore: false,
      showMultimedia: false,
      basicInfo: [],
      speakers: [],
      translator: TranslateService.create(),
      keywordList: this.keywords,
    };
  },
  props: {
    id: Number,
    slug: String,
    webinar: Object,
    multimediaList: Array,
    congress: Object,
    keywords: Array,
  },
  mounted() {
    //Speakers
    this.speakers = this.congress.speakers;
    if (this.speakers && this.speakers.length == 0) {
      this.hideSpeakers = true;
    }
    //Multimedia
    if (this.multimediaList && this.multimediaList.length > 0) {
      this.showMultimedia = true;
    }
    //Video
    if (this.webinar.videoUri == null || this.webinar.videoUri == '') {
      this.hideVideo = true;
    }

    this.fetch(this.slug);
    this.initStayPage();
  },
  methods: {
    registerLink() {
      window.location.href = TranslationService.transRoute('event-register', { slug: this.slug, 'token?': '' });
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    async fetch() {
      const response = await this.getData();
      const results = CongressBasicFactory.get(response.data.data);
      this.basicInfo = results;
    },
    getData() {
      return axios.get('/congresses/info?slug=' + this.slug);
    },
    hideShortdesc: function(event) {
      this.readmore = true;
    },
    showShortdesc: function(event) {
      this.readmore = false;
    },
    escapeHtml(item) {
      return Utils.escapeHtml(item);
    },
    moment: function() {
      return moment();
    },
    imageUrlAlt(event) {
      event.target.src = '/images/v4/assets/avatar.svg';
    },
    openLive() {
      let liveURL = TranslationService.transRoute('event-detail-live', {
        slug: this.slug,
        agendaSessionId: this.webinar.id
      });

      return window.open(liveURL, '_blank').focus();
    },
    openAgenda() {
      let agendaURL = TranslationService.transRoute('event-agenda-detail', {
        slug: this.slug,
        agendaSessionId: this.webinar.id
      });
      
      return window.open(agendaURL, '_blank').focus();
    },
    parseImage(img) {
      if (img && img.substr(0, 1) != '/') {
        img = '/' + img;
      }
      return img;
    },
    getIcon(document) {
      if (document.multimedia_type_id == 5 || document.multimedia_type_id == 4) {
        return 'bi bi-file-play';
      } else if (document.external_link) {
        return 'bi bi-link';
      } else {
        return 'bi bi-file-earmark';
      }
    },
    getMultimediaURL(documentMultimedia) {
      let url = TranslationService.transRoute('detail-multimedia', { multimediaId: documentMultimedia.id });
      if (
        documentMultimedia.external_link &&
        documentMultimedia.external_link != '' &&
        documentMultimedia.multimedia_type_id != 4 &&
        documentMultimedia.multimedia_type_id != 5 &&
        documentMultimedia.multimedia_type_id != 12
      ) {
        document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: documentMultimedia.external_link }));                    
        // this.$root.$emit('leavingMMPModal', documentMultimedia.external_link);
      } else {
        window.open(url, '_blank');
      }
    },
    initStayPage() {
      PageStayController.startStayPageTime('congress', this.id);
    },
    getDay() {
      return DateService.cardDate(this.webinar.startDate, this.webinar.endDate);
    },
    showModal(j) {
      document.dispatchEvent(new CustomEvent("showSpeakerModal", { detail: {
          name: j.name,
          surname: j.surname,
          institution: j.institution,
          bio: j.bio,
          photo: this.parseImage(j.photo),
        }})
      );
    },
    filterKeywords(id, keyword) {
      let url = TranslationService.transRoute('search-page');
      if (this.from_type == 'profilesub' || this.from_type == 'content') {
        this.$emit('kwchange', id);
      } else if (this.from_type == 'bookmark') {
        var obj = { key_id: id, key_name: keyword };
        this.$emit('words', obj);
      }
      //pending to send ecripyed keyword to results page
      GoogleAnalytics.sendPromoClickEvent(keyword);
      GoogleAnalytics.sendFilterEvent('keyword', this.from_type ?? 'content', keyword);
      return window.open(url, '_blank').focus();
    },
  },
  computed: {
    momentStart() {
      return moment(this.webinar.startDate).format('hh:mm A');
    },
    momentEnd() {
      return moment(this.webinar.endDate).format('hh:mm A');
    }
  }
/*   filters: {
    moment: function(date) {
      return moment(date).format('hh:mm');
    },
    momentEnd: function(date) {
      return moment(date).format('hh:mm A');
    }
  } */
};
</script>
