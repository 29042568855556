<template>
  <div v-if="dataReady">
    <div class="agenda-list custom-congress-date">
      <ul class="text-center">
        <li v-for="(item, k) in tabInfo" v-bind:key="k" @click="openTab(k)">
          <a href="javascript:void(0);" :class="{ active: k == selected_tab_id }">{{ item }}</a>
        </li>
      </ul>
    </div>
    <div v-if="!isLoading" class="agenda-promo custom-congress-carousel" v-for="(item, i) in tabInfo" v-bind:key="i">
      <div v-if="!isMobile()">
        <div class="hidden" :id="'agenda_common_' + i" v-if="agendaInfo[i].length > 0">
          <v4-carousel :slidesToShow="3" :slidesToScroll="3" :name="'agenda' + i">
            <template v-slot:items>
              <div v-for="(itemAgent, j) in agendaInfo[i]" v-bind:key="j">
                <v4-card v-bind="itemAgent"></v4-card>
              </div>
            </template>
          </v4-carousel>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="hidden custom-congress-carousel" :id="'agenda_common_' + i" v-if="agendaInfo[i].length > 0">
        <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'agenda' + i">
          <template v-slot:items>
            <div v-for="(itemAgent, j) in agendaInfo[i]" v-bind:key="j">
              <V4Card v-bind="itemAgent"></V4Card>
            </div>
          </template>
        </V4Carousel>
      </div>
    </div>
    <div v-if="isDisplay" class="d-flex  justify-content-center my-5">
      <div class="my-5">{{ t('congress.no_result_found') }}</div>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import AgendaFactory from '../agenda/factories/Agenda.factory';
import V4Card from '../card/V4Card.vue';
import V4Carousel from '../carousel/V4Carousel.vue';
import $ from 'jquery';

export default {
  data() {
    return {
      isLoading: true,
      selected_tab_id: 0,
      isDisplay: false,
      translator: TranslateService.create(),
      dataReady : false,
      agendaInfo: [],
      tabInfo: [],
    };
  },
  components: { V4Card, V4Carousel},
  props: {
    agendaSessions: Object,
  },
  mounted() {
    this.getTabEvents();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    getTabEvents(slug) {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      // this.$root.$emit('showCustomLoader', true);
      this.isDisplay = false;
      const response = this.agendaSessions;
      var agenda = [];
      var tabs = [];
      $.each(response.data.data, function(key, value) {
        const results = value.map(k => AgendaFactory.get(k, slug));
        agenda.push(results);
        tabs.push(key);
      });
      this.agendaInfo = agenda;
      this.tabInfo = tabs;
      this.isLoading = false;
      setTimeout(() => {
        this.openTab(0);
      }, 800);
      if (this.agendaInfo.length == 0) {
        this.isDisplay = true;
      }
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
      // this.$root.$emit('showCustomLoader', false);
      this.dataReady = true;
    },
    openTab(tabId) {
      $('.hidden').hide();
      $('#agenda_common_' + tabId).css('display', 'block');
      this.selected_tab_id = tabId;
      $('.slick-slider').slick('refresh');
    },
  }
};
</script>
