<template>
  <div class="connectmi-event__agenda" :key="componentKey" >
    <div class="connectmi-event__agenda__helper" v-if="!isLoading">
      <h2 class="connectmi-event__agenda__title title title--xl">Agenda</h2>
      <div class="connectmi-event__agenda__day__container">
        <AgendaDay
          v-for="(tab, key) in tabInfo"
          :key="key"
          :tabNumber="key"
          :day="tab.day"
          :desc="tab.desc"
          :selected="componentKey"
          @click.native="forceRerender(key)"
        ></AgendaDay>
      </div>
      <div v-for="(day, i) in agendaInfo" :key="i">
        <div v-if="componentKey === i" class="connectmi-event__agenda__event__container">
          <AgendaEvent
            v-for="(agenda, j) in day"
            :key="j"
            :gradient="i.toString()"
            :agenda="agenda"
            :imagePath="imagePath"
          ></AgendaEvent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import AgendaEvent from './components/AgendaEvent.vue';
import AgendaDay from './components/AgendaDay.vue';
import AgendaFactory from '../agenda/factories/Agenda.factory';
import axios from 'axios';
import $ from 'jquery';

export default {
  props: {
    slug: String,
    countryId: Number,
    imagePath: String
  },

  components: {
    AgendaEvent,
    AgendaDay
  },

  data() {
    return {
      isDisplay: true,
      isLoading: true,
      agendaInfo: [],
      tabInfo: [],
      hidden: false,
      first: true,
      translator: TranslateService.create(),
      componentKey: 0,
      topics: [
        'Redefining MI: from MINOCA to cardiogenic shock',
        'Acute atheromatous myocardial infarction',
        'Treating residual risk in patients with myocardial infarction',
        'Role of intracoronary imaging and coronary physiology',
        'New "drugs" and "tools" for myocardial infarction  management'
      ],
      formData: {
        slug: this.slug,
        countryId: this.countryId ?? null,
        timezone: window.user.time_zone ?? 'UTC'
      }
    };
  },

  mounted() {
    this.agenda(this.slug, this.countryId);
  },

  methods: {
    async agenda(slug) {
      const response = await this.getAgendaData();
      var agenda = [];
      var tabs = [];
      const dayTopics = this.topics;
      let index = 0;
      $.each(response.data.data, function(key, value) {
        const results = value.map(k => AgendaFactory.get(k, slug));
        agenda.push(results);
        const desc = dayTopics[index] ? dayTopics[index] : '';
        const tabDay = {
          day : key,
          desc : desc
        };
        tabs.push(tabDay);
        index = index + 1;
      });
      this.agendaInfo = agenda;
      this.tabInfo = tabs;
      this.isLoading = false;
    },

    getAgendaData() {
      return axios.get(
        `/congresses/grouped-agenda`,
        { params: { 
            slug: this.formData.slug,
            countryId: this.formData.countryId,
            timezone: this.formData.timezone
          } 
        });
    },

    createImagePath(image) {
      return this.imagePath + image;
    },

    t(tag) {
      return this.translator.translate(tag);
    },

    forceRerender(tab) {
      this.componentKey = tab;
    }
  }
};
</script>
