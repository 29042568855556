import { defineStore } from 'pinia'
import { getProfileLocales, setProfilePreferredLocale, getBrowserLocaleISOCode } from '../../../locales'

export const useLocaleStore = defineStore('locale', {
  state: () => ({
    userLocales: [],
    currentUserLocale: null
  }),
  actions: {
    toggleShowParticipants () {
      this.showParticipants = !this.showParticipants
    },
    fetchUserLocales () {
      getProfileLocales().then(locales => {
        this.userLocales = locales;
      })
    },
    fetchCurrentUserLocale () {
      this.currentUserLocale = window.user?.locale || getBrowserLocaleISOCode();
    },
    saveUserLocale (locale) {
      this.currentUserLocale = locale;
      setProfilePreferredLocale(locale).then(() => window.location.reload())
    }
  }
})
