<template>
    <div v-if="!enabledTa">
        <div v-if="!isMobile()">
            <!-- DESKTOP -->
            <HomeForm :therapeutics="therapeutics" :contentType="contentType"
                :user-therapeutic-areas="usertherapeuticareas" v-bind="{redirectMode: true}"></HomeForm>
        </div>
        <div v-else class="button-row">
            <!-- MOBILE -->
            <form :action="getSearchRoute()" @submit.prevent="onFormSubmit">
                <div class="pt-2 d-flex justify-content-center search-bar">
                    <input type="text" name="q" class="v4-search-query" :placeholder="t('new-home.search.write-in')">
                    <button class="ml-3 v4-btn-primary">
                        <i class="bi bi-search"></i>
                        <p>{{t("general.search")}}</p>
                    </button>
                </div>
            </form>
            <button type="button" data-toggle="modal" data-target="#content-modal" class="button-open-content">
                <i class="bi bi-filter"></i> {{ t("new-home.search.show-more-filters") }}
            </button>
            <!-- Modal -->
            <div class="modal fade" id="content-modal" tabindex="-1" role="dialog" aria-labelledby="content-modal"
                aria-hidden="true">
                <div class="modal-dialog modal-lg theme-dark-modal" role="document">
                    <div class="modal-content p-md-5">
                        <div class="modal-body px-3">
                            <div class="modal-close-row">
                                <i class="bi bi-x-lg" data-dismiss="modal" aria-label="Close"></i>
                            </div>
                            <div class="content-modal-body modal-body-content">
                                <HomeFormMobile :therapeutics="therapeutics" :contentType="contentType"
                                    :user-therapeutic-areas="usertherapeuticareas" v-bind="{redirectMode: true}">
                                </HomeFormMobile>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <HomeTaForm v-if="enabledTa" @input="onSelectChange" id="TherapeuticAreas" :therapeutics="this.therapeuticsTa"
        v-bind="{redirectMode: true}"></HomeTaForm>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import TranslationService from '../../core/services/translation.service.js';
import ContentRepository from './repositories/Content.repository';
import HomeForm from './HomeForm.vue';
import HomeFormMobile from './HomeFormMobile.vue';
import HomeTaForm from "./HomeTaForm.vue";

export default {
    props: {
        therapeutics : Object,
        contentType: Object,
        usertherapeuticareas : Array,
        enabledTa: Boolean,
        therapeuticsTa: Array,
    },
    mounted() {
        document.addEventListener("SearchFormResultsPageChanged", (paginationData) => {
            this.form["page"] = paginationData.currentPage;
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
            this.search();
        });
    },
    methods: {
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        t(tag){
            return this.translator.translate(tag)
        },
        getSearchRoute() {
            return TranslationService.transRoute('search-page');
        },
        onFormSubmit(ev) {
            const formString = JSON.stringify(this.form);
            const base = btoa(formString);
            const url = ev.target.action !== undefined ? new URL(ev.target.action) : new URL(window.location.origin + ev.target.dataset.action);
            url.searchParams.append('q', base);
            window.location.href = url;
        },
        async search() {
            const form = this.prepareSearchRequest(form);
            document.dispatchEvent(new CustomEvent("SearchHomeLoading", { detail: true }));
            let data = await ContentRepository.search(form);
            // data[0] = data[0].slice(0, 12);
            document.dispatchEvent(new CustomEvent("searchHomeLoaded", { detail: data }));
        },
        prepareSearchRequest() {
            const form = this.removeEmpty(this.form);
            const contents = ['events', 'content', 'knowledge-journey', 'all'];
            this.form.content_type = [];

            contents.forEach(element => {
                const existElement = form[element] ?? null;

                if(existElement) {
                    form.content_type.push(element);
                    delete form[element];
                }
            });

            return form;
        },
        removeEmpty(obj) {
            return Object.entries(obj)
                .filter(([_, v]) => v != null)
                .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
        },
    },
    data() {
        return {
            translator : TranslateService.create(),
            form: {},
        }
    },
    components: {
        HomeForm,
        HomeFormMobile,
        HomeTaForm,
    }
}
</script>
