<template>
    <div v-if="dataReady && !isLoading" class="custom-template-speakers__carousel row" :class="alignment === 'left' ? 'd-flex flex-row' : (alignment === 'right' ? 'd-flex flex-row-reverse' : 'd-flex flex-row')">
        <div :class="[alignment !== 'center' ? 'col-6' : 'col']">
            <div v-if="!isMobile()">
                <div class="speaker_hidden" :id="'speaker_common_1'">
                    <V4Carousel :key="refreshCarousel" :slidesToShow="alignment == 'center' ? 4 : 2" :slidesToScroll="alignment == 'center' ? 4 : 2" :rowsToShow="alignment == 'center' ? 1 : 2" :name="'speakers1'">
                        <template v-slot:items>
                            <div v-for="(itemInfo, j) in speakerInfo" v-bind:key="j" class="col-sm-12">
                                <div :class="(selectedCard === j && alignment !='center') ? 'selected-card' : ''" style="border: 5px solid rgba(255, 0, 0, 0);">
                                    <div class="custom-template-speakers__carousel__speaker" :style="isStandardOrStandardEllipse() ? 'border-radius:14px;' : 'border-radius: 20px;'"
                                    :class="[alignment === 'center' ? 'custom-template-speakers__carousel__speaker__centered' : '']"
                                    @click="alignment == 'center' ? showModal(itemInfo) : changeSelectedCard(j, itemInfo)">
                                        <SpeakersCardStandard v-if="isStandardOrStandardEllipse()"
                                        :cardType="cardType"
                                        :itemInfo="itemInfo"
                                        >
                                        </SpeakersCardStandard>
                                        <SpeakersCardImage v-if="isFullImage()"
                                        :cardType="cardType"
                                        :itemInfo="itemInfo"
                                        >
                                        </SpeakersCardImage>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </V4Carousel>
                </div>
            </div>
            <div v-else>
                <div class="speaker_hidden" :id="'speaker_common_1'">
                    <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'speakers1'" :key="refreshCarousel">
                        <template v-slot:items>
                            <div v-for="(itemInfo, j) in speakerInfo" v-bind:key="j">
                                <div class="custom-template-speakers__carousel__speaker" @click="alignment == 'center' ? showModal(itemInfo) : null">
                                    <SpeakersCardStandard v-if="isStandardOrStandardEllipse()"
                                        :cardType="cardType"
                                        :itemInfo="itemInfo">
                                    </SpeakersCardStandard>
                                    <SpeakersCardImage v-if="isFullImage()"
                                        :cardType="cardType"
                                        :itemInfo="itemInfo">
                                    </SpeakersCardImage>
                                </div>
                            </div>
                        </template>
                    </V4Carousel>
                </div>
            </div>
        </div>
        <div class="col-6" v-if="alignment !== 'center' && singleSpeakerInfo" :style="!singleSpeakerInfo.video ? 'padding-top:26px;' : ''">
            <SpeakersDescriptionBox
                :key="refreshSpeakerBox"
                :speakerInfo="singleSpeakerInfo"
                :source="'speaker'"
                :hideRelatedContent="hideRelatedContent">
            </SpeakersDescriptionBox>
        </div>
    </div>
    <div v-if="isDisplay" class="d-flex justify-content-center my-5">
      <div class="my-5">{{ t('congress.no_result_found') }}</div>
    </div>
</template>

<script>
import TranslateService from '../../../core/services/translate.service';
import SpeakersCardImage from './SpeakersCardImage.vue';
import SpeakersCardStandard from './SpeakersCardStandard.vue';
import SpeakersDescriptionBox from './SpeakersDescriptionBox.vue';
import V4Carousel from '../../carousel/V4Carousel.vue';
import axios from 'axios';

export default {
  name: 'SpeakersCarrousel',
  components: { SpeakersCardImage, SpeakersCardStandard, SpeakersDescriptionBox, V4Carousel },
  props: {
    configuration: Object,
  },
  data() {
    return {
      translator: TranslateService.create(),
      isLoading: true,
      alignment: this.configuration.configuration.custom_speakers_alignment,
      cardType: this.configuration.configuration.custom_speakers_card_type,
      hideRelatedContent: true,
      speakerInfo: [],
      isMobileDevice: false,
      singleSpeakerInfo: {},
      refreshSpeakerBox: 1,
      refreshCarousel: 1,
      selectedCard: 0,
      dataReady: false,
      isDisplay: false,
    };
  },
  mounted() {
    this.getSpeakers();
    window.addEventListener('resize', this.handleResize);
    this.isMobileDevice = this.isMobile();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    showModal(speaker) {
      document.dispatchEvent(new CustomEvent("showSpeakerModal", { detail: {
        id: speaker.id,
        name: speaker.name,
        surname: speaker.surname,
        institution: speaker.institution,
        bio: speaker.bio,
        photo: this.parseImage(speaker.photo),
        video: speaker.video,
        relatedContent: speaker.relatedContent,
        hideRelatedContent: this.hideRelatedContent,
      }}));
    },
    changeSelectedCard(cardId, speakerInfo) {
      this.selectedCard = cardId;
      this.refreshSpeaker(speakerInfo);
    },
    isStandardOrStandardEllipse(){
      return this.cardType == 'standard' || this.cardType == 'standard_ellipse';
    },
    isFullImage(){
      return this.cardType == 'image' || this.cardType== 'image_black_and_white';
    },
    async getSpeakers() {
      await axios
        .get('/congresses/speakers/get-grouped-speakers',
        { params:
            {
              congressId: this.configuration.congress_id
            }
        })
        .then(response => {
          if(response?.data?.groupedSpeakers) {
            this.fillSpeaker(response.data.groupedSpeakers);
          } else {
            this.isDisplay = true;
          }
          this.hideRelatedContent = this.configuration.configuration.custom_speakers_related_content == 1;
          this.isLoading = false;
          this.dataReady = true;
        })
        .catch(error => {
            this.isDisplay = true;
            console.error(error);
        });
    },
    fillSpeaker(groupedSpeakers){
      this.speakerInfo = Object.values(groupedSpeakers).map(speaker => this.mapSpeaker(speaker));
      this.singleSpeakerInfo = this.speakerInfo[0];
    },
    mapSpeaker(speaker) {
      return {
            id: speaker?.id ?? '',
            name: speaker?.name ?? '',
            surname: speaker?.surname ?? '',
            photo: speaker?.photo ?? '',
            position: speaker?.position ?? '',
            bio: speaker?.bio ?? false,
            short_bio: speaker?.short_bio ?? '',
            institution: speaker?.institution ?? '',
            video: speaker?.video ?? '',
            relatedContent: speaker?.relatedContent ?? [],
      };
    },
    imageUrlAlt(event) {
      event.target.src = "/images/v4/assets/avatar.svg";
    },
    parseImage(img) {
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    handleResize() {
      this.isMobileDevice = this.isMobile();
    },
    refreshSpeaker(speaker) {
      this.singleSpeakerInfo = speaker;
      this.refreshSpeakerBox++;
    },
  }
};
</script>