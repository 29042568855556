export default {
    get(activity) {
        return {
            event: activity?.event ?? '',
            date: activity?.date ?? '',
            url: activity?.url ?? '',
            type: activity?.type ?? '',
        };
    },  
    getNotes(notes) {
        return {
            agenda_session_title: notes?.agenda_session != null ? notes?.agenda_session?.title : "",
            date: notes?.created_at ?? '',
            congress : notes?.congress ?? null,
            note_text : notes?.text ?? '',
            id : notes.id
        };
    },
};