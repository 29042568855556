<template>
    <div class="satellital-final-message">
      <section class="v4-satellital-content">
        <div class="text-center">
          <div class="v4-satellital-success-icon">
            <img v-if="successMotiveType === 'P1'" src="/images/v4/animations/email.svg">
            <img v-if="successMotiveType === 'P2'" src="/images/v4/animations/double_check.svg">
            <img v-if="successMotiveType === 'P3'" src="/images/v4/animations/clocksand.svg">
          </div>
        </div>
        <div class="text-center v4-satellital-error-title">
            <span v-if="successMotiveType === 'P1'">
                {{t("satellital.check-your-email")}}
            </span>
            <span v-if="successMotiveType === 'P2'">
            {{t("satellital.registration-successful")}}
            </span>
            <span v-if="successMotiveType === 'P3'">
                {{t("satellital.registration-waiting-validation-1")}}<br/>
                {{t("satellital.registration-waiting-validation-2")}}
            </span>
        </div>
        <div class="text-center v4-satellital-success-info">
          {{t("satellital.success." + successMotiveType)}}
        </div>
      </section>
    </div>
  </template>
  
<script>
import TranslateService from '../../core/services/translate.service.js';
export default {
props: {
    email: {
        type: String,
        default: null,
    },
    isOnBehalf: Boolean,
    slug: {
        type: String,
        default: null,
    },
},
data() {
    return {
        translator: TranslateService.create(),
        successMotiveType: this.getMotiveType(),
    };
},
methods: {
    t(tag){
        return this.translator.translate(tag)
    },
    getMotiveType(){
        if (this.isOnBehalf) {
            return 'P2';
        }
        if (this.email === null && this.slug === null) {
            return 'P1';
        } else if (this.email && this.slug) {
            return 'P3';
        } else if (this.email || this.slug) {
            return 'P1';
        }
    }
}
}
</script>
  