import VirtualBooth from '../objects/v4/modules/events/event-internal/VirtualBooth.vue'
import SymposiaList from '../objects/v4/modules/events/event-internal/SymposiaList.vue'
import AgendaRecommend from '../objects/v4/modules/events/event-internal/AgendaRecommend.vue'
import CustomContentSearch from '../objects/v4/modules/events/event-internal/CustomContentSearch.vue'
import SearchFormResults from '../objects/v4/modules/search-form-results/SearchFormResults.vue'

const vueComponents = [
    ['VirtualBooth', VirtualBooth],
    ['SymposiaList', SymposiaList],
    ['AgendaRecommend', AgendaRecommend],
    ['CustomContentSearch', CustomContentSearch],
    ['SearchFormResults', SearchFormResults],
]

export default vueComponents