import axios from 'axios'

/**
 * The default axios instance
 *
 * @type {AxiosInstance}
 */
export const axiosInstance = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
})

/**
 * Returns true if the response status is successful or false otherwise
 *
 * @param {AxiosResponse<unknown>} response
 */
export function isOkResponse (response) {
  return response.status >= 200 && response.status <= 299
}
