<template>
<div>
    <div v-if="isLoading">
        {{t('general.loading')}}...
    </div>
    <div v-if="!isLoading">
        <!-- LIST AGENDAS -->
        <div v-if="!isMobile()">
            <V4Carousel :slidesToShow="3" :slidesToScroll="3" :rowsToShow="this.setNumberOfRows()" :name="'past-events'" ref="pastEvents"
                :isLoading="isLoading" v-if="!isLoading" :key="componentKey" :numberCarousel="true">
                <template v-slot:items>
                    <div v-for="(item, i) in agendas" v-bind:key="i">
                        <V4Card :key="item.id" v-bind="item"></V4Card>
                    </div>
                </template>
            </V4Carousel>
        </div>
        <div v-else>
            <V4Carousel :slidesToShow="1" :slidesToScroll="1" :rowsToShow="this.setNumberOfRows()" :name="'past-events'" ref="pastEvents" 
                :isLoading="isLoading" v-if="!isLoading" :key="componentKey" :numberCarousel="true">
                <template v-slot:items>
                    <div v-for="(item, i) in agendas" v-bind:key="i">
                        <V4Card :key="item.id" v-bind="item"></V4Card>
                    </div>
                </template>
            </V4Carousel>
        </div>

        <!-- LIST CATEGORIZED AGENDAS -->
        <div v-for="(category, index) in categories" :key="index">
            <p class="text-bold">
                {{category.name}}
            </p>
            <div v-if="!isMobile()">
                <V4Carousel v-bind="{slidesToShow:3, slidesToScroll:3, name: `past-events-${index}`, isLoading: false, numberCarousel: false}" 
                  ref="pastEvents" :key="componentKey">
                    <template v-slot:items>
                        <V4Card v-for="ev in category.agenda" v-bind="ev" :key="ev.id"></V4Card>
                    </template>
                </V4Carousel>
            </div>
            <div v-else>
                <V4Carousel v-bind="{slidesToShow:1, slidesToScroll:1, name: `past-events-${index}`, isLoading: false, numberCarousel: false}" 
                  ref="pastEvents" :key="componentKey">
                    <template v-slot:items>
                        <V4Card v-for="ev in category.agenda" v-bind="ev" :key="ev.id"></V4Card>
                    </template>
                </V4Carousel>
            </div>
        </div>
    </div>
    <div v-if="!categories.length && !agendas.length && !isLoading">
        No results
    </div>
</div>
</template>

<script>
import AgendaRepository from '../agenda/repositories/Agenda.repository';
import AgendaFactory from '../agenda/factories/Agenda.factory';
import TranslateService from '../../core/services/translate.service';
import V4Carousel from '../carousel/V4Carousel.vue';
import V4Card from '../card/V4Card.vue';
import $ from 'jquery';

export default {
    name: 'PastEvents',
    components: {
        V4Carousel,
        V4Card
    },
    props:{
        slug: String
    },
    data() {
        return {
            isLoading: true,
            categories: [],
            agendas: [],
            error: '',
            // bus: new Vue(),
            componentKey: 0,
            translator : TranslateService.create()
        }
    },

    mounted() {
        this.get();
    },

    methods: {
        async get() {
            try {
                this.isLoading = true;
                this.error = '';
                let results = await AgendaRepository.getPastEvents(this.slug);

                //Categories
                let listCategories = [];
                if(results && results.data) {
                    results.data.categories.forEach(function callback(category, i){
                        listCategories.push(category);
                        listCategories[i].agenda = listCategories[i].agenda.map(item => AgendaFactory.get(item));
                    });
                }

                //Agendas
                const listAgendas = results && results.data ? results.data.agendas.map(k => AgendaFactory.get(k)) : [];

                this.categories = listCategories;
                this.agendas = listAgendas;

                if(this.categories.length == 0 && this.agendas.length == 0)  {
                    $('#past-events').css('display', 'none');
                    $('#past-events_menu').css('display', 'none');
                    $("a[href='#past-events']").css('display', 'none');
                }
                
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);

            } catch (error) {
                console.log(error);
                this.categories = [];
                this.agendas = [];
                this.error = "something happened";
                this.isLoading = false;
            }
        },

        setNumberOfRows() {
            return this.agendas.length > 3 ? 2 : 1;
        },
        forceRerender() {
            this.componentKey += 1
        },
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        },
        t(tag) {
            return this.translator.translate(tag);
        },
    }
}
</script>
