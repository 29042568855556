<template>
  <div class="particle-container">
    <div class="main-title-container">
      <h1>
        {{ t('pre-home.mmp-universe-1') }} <br /><span class="text-bold">{{ t('pre-home.mmp-universe-2') }}</span>
      </h1>
    </div>
    <div class="v-particle-ball" id="small2-particle-ball">
      <div class="particle-info">
        <p>
          <strong>{{ t('pre-home.circle-3') }}</strong> {{ t('pre-home.circle-3-2') }}
        </p>
      </div>
    </div>
    <div class="v-particle-ball large-ball" id="large-particle-ball"></div>
    <div class="v-particle-ball" id="small1-particle-ball">
      <div class="particle-info">
        <p>
          <strong>{{ t('pre-home.circle-2') }}</strong> {{ t('pre-home.circle-2-2') }}
        </p>
      </div>
    </div>
    <div class="v-particle-ball" id="small3-particle-ball">
      <div class="particle-info">
        <p>
          <strong>{{ t('pre-home.circle-4') }}</strong> {{ t('pre-home.circle-4-2') }}
        </p>
      </div>
    </div>
    <div class="v-particle-ball" id="small4-particle-ball">
      <div class="particle-info">
        <p>
          <strong>{{ t('pre-home.circle-5') }}</strong> {{ t('pre-home.circle-5-2') }}
        </p>
      </div>
    </div>
    <div class="v-particle-ball" id="small5-particle-ball">
      <div class="particle-info">
        <p>
          <strong>{{ t('pre-home.circle-1') }} </strong> {{ t('pre-home.circle-1-2') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
export default {
  props: {},
  data() {
    return {
      translator: TranslateService.create(),
      config: {
        large: {
          particles: {
            number: {
              value: 1800,
              density: {
                enable: true,
                value_area: 300
              }
            },
            color: {
              value: '#2DBCB6'
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 1,
                color: '#000000'
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 1,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 2,
              random: true,
              anim: {
                enable: false,
                speed: 0,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 0,
              color: '#2DBCB6',
              opacity: 0.4,
              width: 1
            },
            move: {
              enable: true,
              speed: 2,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: false,
                mode: 'grab'
              },
              onclick: {
                enable: false,
                mode: 'push'
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 150,
                line_linked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3
              },
              repulse: {
                distance: 200,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          retina_detect: true
        },
        small: {
          particles: {
            number: {
              value: 800,
              density: {
                enable: true,
                value_area: 100
              }
            },
            color: {
              value: '#2DBCB6'
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 1,
                color: '#000000'
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 0.7,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 2,
              random: true,
              anim: {
                enable: false,
                speed: 0,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 30,
              color: '#2DBCB6',
              opacity: 0.8,
              width: 1
            },
            move: {
              enable: true,
              speed: 2,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: false,
                mode: 'grab'
              },
              onclick: {
                enable: false,
                mode: 'push'
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 150,
                line_linked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3
              },
              repulse: {
                distance: 200,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          retina_detect: true
        }
      }
    };
  },

  mounted() {
    this.$nextTick(() => {
      particlesJS('large-particle-ball', this.config.large);
      particlesJS('small1-particle-ball', this.config.small);
      particlesJS('small2-particle-ball', this.config.small);
      particlesJS('small3-particle-ball', this.config.small);
      particlesJS('small4-particle-ball', this.config.small);
      particlesJS('small5-particle-ball', this.config.small);
    });
  },

  methods: {
    t(tag) {
      return this.translator.translate(tag);
    }
  }
};
</script>
