<template>
    <section id="people-choice-ranking-section" class="py-3">
        <table>
            <thead class="ranking-header">
                <tr>
                    <th>Ranking</th>
                    <th>Title</th>
                    <th>Author/s</th>
                    <th>Voting</th>
                </tr>
            </thead>
            <tbody class="ranking-body">
                <tr v-for="(multimedia, index) in sortedMultimedias" :key="multimedia.id" class="ranking-row">
                    <td class="ranking-body_number text-center" @click.stop="goToMultimedia(multimedia)">
                        <trophyIconSvg v-if="index < 3"/>
                        {{ index + 1 }}
                    </td>
                    <td class="ranking-body_title" @click.stop="goToMultimedia(multimedia)">{{ multimedia.title }}</td>
                    <td class="ranking-body_author">
                        <img v-for="author in multimedia.authorsData" :key="author.id" :src="parseImage(author.photo)" @error="imageUrlAlt" alt="" width="25">
                        <div class="authors-container">
                            <span v-for="(author, authorIndex) in multimedia.authorsData" :key="author.id">
                                {{ author.full_name }}<span v-if="authorIndex < multimedia.authorsData.length - 1">, </span>
                            </span>
                        </div>
                    </td>
                    <td class="ranking-body-voting">
                        {{ Number(multimedia.people_choice_score.toFixed(1)) + '%'  }}
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
import { ref, computed } from "vue";
import trophyIconSvg from "./trophyIconSvg.vue";
import GoogleAnalytics from "../../../GoogleAnalytics";

export default {
    name: "PeopleChoiceRankingSection",
    components: { trophyIconSvg },
    props: {
        configuration: Object,
        multimedias: Array,
    },
    setup(props) {
        const sortedMultimedias = computed(() => {
            return props.multimedias.slice().sort((a, b) => b.people_choice_score - a.people_choice_score);
        });

        const imageUrlAlt = (img) => {
            img.target.src = "/images/v4/assets/avatar.svg";
        };

        const parseImage = (img) => {
            if (img && img.charAt(0) != '/') {
                img = '/' + img;
            }
            return img;
        };

        const isStandardEllipse = () => {
            return props.cardType == 'standard_ellipse';
        };

        const goToMultimedia = (multimedia) => {
            GoogleAnalytics.sendOpenContentEvent(multimedia.title, multimedia.type);
            if (multimedia.type !== 'agenda_session' && multimedia.type !== 'agenda') {
                if (multimedia.external_redirect) {
                    document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: multimedia.external_url }));       
                } else {
                    return window.open(multimedia.url, '_blank').focus();
                }
            } else {
                if (multimedia.cards_behaviour == 'external link') {
                    document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: multimedia.external_link }));   
                } else if (multimedia.cards_behaviour == 'open modal') {
                    let agendaURL = '';
                if ((multimedia.is_live && multimedia.is_accessible && !multimedia.external_congress)
                    || (multimedia.is_on_demand  && multimedia.is_accessible && !multimedia.external_congress && multimedia.external_link != '')) {
                    if(multimedia.meeting_platform == 'ZOOM' && window.app.env.ENABLE_LIVE){
                        agendaURL = window.app.env.LIVE_URL+'/zoom/'+multimedia.slug+'/'+multimedia.id+'?encryptsKey=live-encrypts'+window.user.id;
                    }else if(multimedia.meeting_platform == 'WEBEX' && window.app.env.ENABLE_LIVE){
                            agendaURL = window.app.env.LIVE_URL+'/webex/'+multimedia.slug+'/'+multimedia.id+'?encryptsKey=live-encrypts'+window.user.id;
                    }else{
                        agendaURL = TranslationService.transRoute('event-detail-live', {
                            slug: multimedia.slug,
                            agendaSessionId: multimedia.id
                        });
                    }
                } else {
                    agendaURL = TranslationService.transRoute('event-agenda-detail', {
                        slug: multimedia.slug,
                        agendaSessionId: multimedia.id
                    });
                }
                    return window.open(agendaURL, '_blank').focus();
                }
            }
        }

        return {
            sortedMultimedias,
            imageUrlAlt,
            parseImage,
            isStandardEllipse,
            goToMultimedia
        };
    },
};
</script>