<template>
    <nav class="floating-menu"  v-scroll-spy-active v-scroll-spy-link>
        <a v-for="(item, index) in list" :key="index" :href="item.link" :id="changetext(item.link)" class="floating-item anchor-text" v-on:click="sendMenuNavEvent(getMenuName(item.text), 'menu event')">
            {{ item.text }}
        </a>
    </nav>
</template>

<script>
// import Scrollspy from '../../../scrollspy.js';
import GoogleAnalytics from '../../../GoogleAnalytics.js';
    // Vue.use(Scrollspy, {
    //   offset: 65,
    //   allowNoActive: true
    // });
    export default {
        props: {
            list: Array
        },
        methods:{
            changetext(text) {
                let string = text;
                let changedstring = string.replace('#','');
                return changedstring+'_menu';
            },
            getMenuName(originalName) {
                return originalName.replace(/_/g, ' ').replace('general.', '');
            },
            sendMenuNavEvent(menuName, menuType) {
                GoogleAnalytics.sendMenuNavEvent(menuName, menuType);
            },
        },
        mounted() {
          if(window.app.event && window.app.event.dynamicMenu) {
            window.addEventListener('scroll', () => {
                const floatingMenu = document.querySelector('.floating-menu');
                if(floatingMenu) {
                    const sectionHeight = 440;
                    if (pageYOffset >= (sectionHeight)) {
                        floatingMenu.style.left = 0;
                    } else {
                        floatingMenu.style.left = '-150px';
                    }
                }
            });
          }
        }
    }
</script>
