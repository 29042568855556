import KnowledgeJourneySearch from '../objects/v4/modules/knowledge-journey/knowledge-journey-search/KnowledgeJourneySearch.vue';
import KnowledgeJourneyHeader from '../objects/v4/modules/knowledge-journey/KnowledgeJourneyHeader.vue';
import KnowledgeJourneyTabsheet from '../objects/v4/modules/knowledge-journey/KnowledgeJourneyTabsheet.vue';
import KnowledgeJourneyContent from '../objects/v4/modules/knowledge-journey/KnowledgeJourneyContent.vue';
import KnowledgeJourneyPresentation from '../objects/v4/modules/knowledge-journey/KnowledgeJourneyPresentation.vue';
import KnowledgeJourneyUnit from '../objects/v4/modules/knowledge-journey/KnowledgeJourneyUnit.vue';
import KnowledgeJourneySupporting from '../objects/v4/modules/knowledge-journey/KnowledgeJourneySupporting.vue';

const vueComponents = [

    ['KnowledgeJourneySearch', KnowledgeJourneySearch],
    ['KnowledgeJourneyHeader', KnowledgeJourneyHeader],
    ['KnowledgeJourneyTabsheet', KnowledgeJourneyTabsheet],
    ['KnowledgeJourneyContent', KnowledgeJourneyContent],
    ['KnowledgeJourneyPresentation', KnowledgeJourneyPresentation],
    ['KnowledgeJourneyUnit', KnowledgeJourneyUnit],
    ['KnowledgeJourneySupporting', KnowledgeJourneySupporting],
]

export default vueComponents