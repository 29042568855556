import Translations from '../../../v4/Translations';

export default class TranslateService {
  translator = null;

  static create() {
    return new TranslateService();
  }

  constructor() {
    this.translator = new Translations();
  }
  translate(tag) {
    const [file, name, subname] = tag.split(".");
    return this.translator.translate(file, name, subname);
  }

  translateField(field) {
    return this.translator.translateField(field);
  }
}
