<template>
<div>
    <div class="card-container card-container mt-4 mb-4" @click="showModal()">
        <div class="inner-card-container">
            <div class="card-top">
                <div class="speaker-image" :style="'background-image: url('+ this.parseImage(this.speaker.photo) + ' )'">
                </div>
            </div>
            <div class="card-bottom">
                <div class="speaker-name">
                    <p class="m-0" data-toggle="modal">
                        {{ this.speaker.name + ' ' + this.speaker.surname }}
                    </p>
                </div>
                <div class="speaker-description">
                    <p class="m-0" v-if="this.speaker.position !== null || this.speaker.position != ''">{{this.speaker.position}}</p>
                    <p class="m-0" v-if="this.speaker.institution !== null || this.speaker.institution != '' ">{{this.speaker.institution}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'SteeringComiteeCard',
    props: {
        speaker: Object
    },

    data() {
        return {

        }
    },
    methods: {
        showModal() {
            document.dispatchEvent(new CustomEvent("showSpeakerModal", { detail: this.speaker}));
        },
        parseImage(img) {
            if (img && img.charAt(0) != '/') {
                    img = '/' + img;
            }
            return img;
        },
    }
}
</script>
