export default {
    get(kj) {
        return {
            id: kj?.id ?? '',
            title: kj?.title ?? '',
            info: kj?.infocard ?? {},
            keywords: kj?.keywords ?? [],
            therapeuticAreas: kj?.therapeuticAreas ?? [],
            link: `/kj/${kj?.id}`
        };
    },  
};