import RoutesHelper from '../../../v4/routesHelper';
import Utils from '../../Utils';
import axios from 'axios';
import $ from 'jquery';

const DisclaimerModal = {
  disclaimer: new Object(),

  modal: {
    selector: '#disclaimer-modal',
    close: '.btn-close-disclaimer-modal',
    body: '.disclaimer-body-modal'
  },
  routes: {
    disclaimerMessage: 'get-disclaimer-message'
  },

  init() {
    this.retrieveDiscalimerMessageData();
  },

  showModal() {
    $(this.modal.selector).show();
  },

  hideModal() {
    $(this.modal.selector).hide();
  },

  retrieveDiscalimerMessageData() {
    if (window.user) {
      axios.get(this.getUrl(this.routes.disclaimerMessage)).then(response => {
        let disclaimerData = response.data.disclaimer;
        if (disclaimerData.length > 0 && (localStorage.getItem('show_disclaimer_modal') === 'false' || localStorage.getItem('show_disclaimer_modal') === null || typeof localStorage.getItem('show_disclaimer_modal') === 'undefined')) {
          const lastDisclaimer = disclaimerData[disclaimerData.length - 1];
          if (Object.keys(lastDisclaimer).length > 0) {
            this.showModal();
            if (typeof document.querySelector('.v-disclaimer-title') !== 'undefined' && document.querySelector('.v-disclaimer-title') !== null) {
              document.querySelector('.v-disclaimer-title').textContent = Utils.escapeHtml(lastDisclaimer.title);
            }
            const txt = document.createElement('textarea');
            txt.textContent = Utils.escapeHtml(lastDisclaimer.disclaimer);
            if(typeof document.querySelector('.v-disclaimer-text') !== 'undefined' && document.querySelector('.v-disclaimer-text') !== null) {
              document.querySelector('.v-disclaimer-text').textContent = txt.value;
            }
            localStorage.setItem('show_disclaimer_modal', true);
          }
        }
        this.initListeners();
      });
    }
  },

  initListeners() {
    $(this.modal.close).click(() => this.closeModal());
  },

  closeModal() {
    event.stopPropagation();
    this.hideModal();
  },

  getUrl: function(route) {
    let routesHelper = new RoutesHelper();
    return routesHelper.getRoute(route);
  }
};

export default DisclaimerModal;
