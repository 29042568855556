<template>
  <form :action="getSearchRoute()" @submit.prevent="onFormSubmit">
    <div class="row">
      <div class="col-12" v-if="dataIsReady" :key="formSectionKey">
        <div id="main-filters" class="row">
          <div class="col-6 pl-2 d-flex flex-row">
            <FormCheckbox
              @input="onInputChecked"
              id="events"
              :defaultLabel="t('new-home.title.events')"
              value="events"
              :checked="shouldBeChecked('events')"
            ></FormCheckbox>
            <FormCheckbox
              @input="onInputChecked"
              id='content'
              :defaultLabel="t('general.content')"
              value='content'
              :checked="shouldBeChecked('content')"
            ></FormCheckbox>
            <FormCheckbox
              @input="onInputChecked"
              id="knowledge-journey"
              :defaultLabel="t('knowledge-journey.title')"
              value="knowledge-journey"
              :checked="shouldBeChecked('knowledge-journey')"
            ></FormCheckbox>
            <FormCheckbox
              @input="onInputChecked"
              id="all"
              :defaultLabel="t('general.all')"
              value="all"
              :checked="shouldBeChecked('all')"
            ></FormCheckbox>
          </div>

          <div class="col-2 pl-2">
            <div class="v4-form-switch--bookmark" style="width: 250px">
              <FormSwitch
                ref="bookmarksRef"
                @switch="onSwitchToggle"
                id='bookmarks'
                :value="shouldBeChecked('bookmarks')"
                :label="t('new-home.search.only-bookmarks')"
              ></FormSwitch>
            </div>
          </div>

          <div class="col-4 pl-2 text-right">
            <a href="#" class="v4-action-link" @click="toggleSecondRow()">
              <i class="bi bi-filter"></i>
              {{ t("new-home.search.show-more-filters") }}</a
            >
          </div>
        </div>

        <div class="mt-2 mb-5 form-second-row" :class="{ 'form-second-row-active': secondRowActive }">
          <div class="row">
            <div
              class="col-2 v4-select-container v4-form-name-therapeutic-areas"
              v-if="therapeuticsOptions.length && showSelects"
            >
              <FormSelect
                @input="onSelectChange"
                id="qTherapeuticAreas"
                :title="t('form.therapeutic_areas')"
                :defaultOptions="therapeuticsOptions"
                :multiple="true"
                :preselectedvalues="form.qTherapeuticAreas"
              ></FormSelect>
            </div>
            <div
              class="col-2 v4-select-container v4-form-name-diseases"
              v-if="pathologiesOptions.length && showSelects"
            >
              <FormSelect
                @input="onSelectChange"
                id="qPathologies"
                :title="t('form.diseases')"
                :defaultOptions="pathologiesOptions"
                :multiple="true"
                :preselectedvalues="form.qPathologies"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container v4-form-name-format" v-if="formatsOptions.length && showSelects">
              <FormSelect
                @input="onSelectChange"
                id= 'format'
                :title="t('form.format')"
                :defaultOptions="formatsOptions"
                :multiple="true"
                :preselectedvalues="form.format"
              ></FormSelect>
            </div>
            <div
              class="col-2 v4-select-container v4-form-name-content-type"
              v-if="multimediaOptions.length && showSelects"
            >
              <FormSelect
                @input="onSelectChange"
                id="qContentType"
                :title="t('form.content-type')"
                :defaultOptions="multimediaOptions"
                :multiple="true"
                :preselectedvalues="form.qContentType"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container v4-form-name-keywords" v-if="keywordsOptions.length && showSelects">
              <FormSelect
                @input="onSelectChange"
                id="qKeywords"
                :title="t('home.search.keywords')"
                :defaultOptions="keywordsOptions"
                :multiple="true"
                :preselectedvalues="form.qKeywords"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container">
              <DatePicker
                ref="homeDatepicker"
                :preselectedStartDate="form.qStartDate"
                :preselectedEndDate="form.qEndDate"
              ></DatePicker>
            </div>
          </div>
          <div class="pt-3 text-right">
            <i class="bi bi-trash" style="color: #2dbcb6"></i>
            <a href="javascript:void(0)" class="v4-action-link" @click="resetFilters()">{{
              t("new-home.search.delete-filters")
            }}</a>
          </div>
        </div>

        <div class="pt-2 d-flex justify-content-center search-bar">
          <input
            v-model="form.q"
            type="text"
            name="q"
            class="v4-search-query"
            :placeholder="t('new-home.search.write-in')"
          />
          <button class="ml-3 v4-btn-primary" @click="searchList">
            <i class="bi bi-search"></i>
            <p>{{ t("general.search") }}</p>
          </button>
        </div>
        <div id="responsive-showfilters" class="col-2 text-right d-none">
          <a href="#" class="v4-action-link" @click="toggleSecondRow()">
            <i class="bi bi-filter"></i>
            {{ 't("new-home.search.show-more-filters")' }}</a
          >
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ContentRepository from "./repositories/Content.repository";
import DatePicker from "../form/datepicker/DatePicker.vue";
import TranslateService from "../../core/services/translate.service.js";
import PageStayController from "../../page-stay/PageStayController";
import moment from "moment";
import $ from "jquery";
import axios from "axios";
import FormCheckbox from "../form/checkbox/FormCheckbox.vue";
import FormSelect from "../form/select/FormSelect.vue";
import FormSwitch from "../form/switch/FormSwitch.vue";
import TranslationService from "../../core/services/translation.service";
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
  components: {
    DatePicker,
    FormCheckbox,
    FormSelect,
    FormSwitch,
  },
  props: {
    redirectMode: Boolean,
    allSelected: Boolean,
    selectLists: Object,
    initialSelect: Boolean,
    userTherapeuticAreas: Array,
  },
  data() {
    return {
      translator: TranslateService.create(),
      defaultSelectList: this.selectLists ?? [],
      therapeuticsOptions: [],
      pathologiesOptions: [],
      multimediaOptions: [],
      keywordsOptions: [],
      specialitiesOptions: [],
      formatsOptions: [],
      shouldRedirect: this.redirectMode ?? true,
      secondRowActive: false,
      dataIsReady: false,
      formSectionKey: 0,
      showSelects: false,
      hasSelectedValue: false,
      resultsCount: 0,
      defaultForm: {
        events: this.allSelected ? true : null,
        content: this.allSelected ? true : null,
        all: this.allSelected ? true : null,
        "knowledge-journey": this.allSelected ? true : null,
        bookmarks: false,
        q: "",
        qTherapeuticAreas: [],
        qPathologies: [],
        format: [],
        content_type: [],
        qKeywords: [],
        qContentType: [],
        qStartDate: null,
        qEndDate: null,
        date: [],
        page: 0,
        size: 12,
      },
      form: {},
      formAux: {},
      selectCheckbox:[],
    };
  },

  mounted() {
    this.shouldMakeSearchRequest();
    this.prepareSelects();
    document.addEventListener("SearchFormResultsPageChanged", (event) => {
      this.page = event.detail.currentPage;
      this.form["page"] = event.detail.currentPage;
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.search();
    });

    document.addEventListener("KeywordSearch", (event) => {
      const { data, $event } = event.detail;
      GoogleAnalytics.sendPromoClickEvent(event.detail.keywordText);
      this.form = data;
      this.search();
      this.onFormSubmit($event);
    });

    document.addEventListener("changeselectpicker", (event) => {
      this.setFormSelects(event.detail);
    });

    document.addEventListener("changecheckbox", (event) => {
      this.setCheckBox(event.detail);
    });

    document.addEventListener("SearchFormResultsCount", (event) => {
      this.resultsCount = event.detail;
    });
    this.initStayPage();
    this.addSelectorsListeners();
    this.dataIsReady = true;
  },

  methods: {
    resetFilters() {
      this.form = this.defaultForm;
      $(".selectpicker").val("default");
      $(".selectpicker").selectpicker("refresh");
      this.$refs.bookmarksRef.checked = false;
      this.$refs.homeDatepicker.dateRange = {
        startDate: null,
        endDate: null,
      };
    },

    async prepareSelects() {
      let filters = await axios.get("/webapi/home/models-filters");

      filters = filters.data.data;
      const keywords = filters.keywords;
      const multimedia = filters.multimediaTypes;
      const therapeutics = filters.therapeuticAreas;
      const specialities = filters.specialities;
      const pathologies = filters.pathologies;
      const formats = filters.formats;

      this.keywordsOptions = this.mapSelect(Object.values(keywords));
      this.multimediaOptions = this.mapSelect(Object.values(multimedia));
      this.therapeuticsOptions = this.mapSelect(Object.values(therapeutics));
      this.specialitiesOptions = this.mapSelect(Object.values(specialities));
      this.pathologiesOptions = this.mapSelect(Object.values(pathologies));
      this.formatsOptions = this.mapSelect(Object.values(formats));
    },
    mapSelect(collection) {
      return collection.map((i) => {
        return {
          id: i.id,
          name: i.name,
          therapeutic_area_id: i.therapeutic_area_id,
        };
      });
    },

    shouldBeChecked(formField) {
      if (this.allSelected && formField != "bookmarks") {
        return true;
      } else {
        return Boolean(this.form[formField]);
      }
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    onInputChecked(checkbox) {
      this.setFormInputFromDTO(checkbox, true);
    },

    onSwitchToggle(toggleData) {
      this.setFormInputFromDTO(toggleData, true);
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.search();
    },

    onSelectChange(selected) {
      this.setFormInputFromDTO(selected);
    },

    searchList() {
      GoogleAnalytics.sendSearchEvent(this.form.q, this.resultsCount, '');
      const filterCategory = Object.keys(this.formAux).join(', ');
      const filterValue = Object.values(this.formAux).flat().join(', ');
      const clickAction = Array(this.selectCheckbox).join(', ');
      GoogleAnalytics.sendFilterEvent(filterValue, filterCategory, filterValue, clickAction);
      document.dispatchEvent(new CustomEvent("showSearchTerm", { detail: this.form.q }));
    },


    setFormInputFromDTO(inputData, isBoolOrNull) {
      let value = "";
      
      if (inputData.key == "all") 
      {
        value = inputData.value ? null : true;

        this.form["content"] = value;
        this.form["knowledge-journey"] = value;
        this.form["events"] = value;
        this.form["all"] = value;

        let checkboxes = $("#content,#knowledge-journey,#events").toArray();

        checkboxes.forEach((checkbox) => {
          checkbox.checked = value;
        });

      } else if (inputData.key == "content" || inputData.key == "knowledge-journey" || inputData.key == "events") 
      {

        if (isBoolOrNull) {
          value = inputData.value ? null : true;
        } else {
          value = inputData.value;
        }

        this.form[inputData.key] = value;
        var allChecked = (this.form["content"] && this.form["knowledge-journey"] && this.form["events"]) ?? false;
        this.form["all"] = allChecked;
        $("#all")[0].checked = allChecked;
      }
    },

    setCheckBox(data) {
      if (data.value){
          this.selectCheckbox.push(data.key);
      }else{
          this.selectCheckbox = this.selectCheckbox.filter(item => item !== data.key);
      }
    },

    removeEmpty(obj) {
      return Object.entries(obj)
        .filter(([_, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    },

    toggleSecondRow() {
      this.secondRowActive = !this.secondRowActive;
      $(".form-second-row").removeClass("d-none");
        setTimeout(() => {
        this.formSectionKey++;
        }, 10000);
      this.secondRowActive ? GoogleAnalytics.sendExpandContentEvent('Show more filters') : GoogleAnalytics.sendCollapseContentEvent('Show more filters');
    },

    shouldMakeSearchRequest() {
      const data = this.parseURLFormData();
      if (Boolean(data)) {
        data.q = decodeURIComponent(escape(data.q));
        this.form = data;
        this.formSectionKey++;
        document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
        this.search();
      } else {
        this.form = JSON.parse(JSON.stringify(this.defaultForm));
      }
      this.showSelects = true;
    },

    parseURLFormData() {
      const url = new URLSearchParams(window.location.search);
      const q = url.get("q");
      if (q) {
        return JSON.parse(atob(q));
      }
      return "";
    },

    onFormSubmit(ev) {
      if (
        this.$refs.homeDatepicker &&
        this.$refs.homeDatepicker.dateRange &&
        this.$refs.homeDatepicker.dateRange.startDate &&
        this.$refs.homeDatepicker.dateRange.endDate
      ) {
        this.form.qStartDate = moment(this.$refs.homeDatepicker.dateRange.startDate).format("DD/MM/YYYY");
        this.form.qEndDate = moment(this.$refs.homeDatepicker.dateRange.endDate).format("DD/MM/YYYY");
      }
      const formString = JSON.stringify(this.form);
      const base = btoa(unescape(encodeURIComponent(formString)));
      const url =
        ev.target.action !== undefined
          ? new URL(ev.target.action)
          : new URL(window.location.origin + ev.target.dataset.action);
      url.searchParams.append("q", base);

      if (this.redirectMode) {
        window.location.href = url;
      } else {
        this.search();
      }
    },

    prepareSearchRequest() {
      const form = this.removeEmpty(this.form);
      const contents = ["events", "content", "knowledge-journey", "all"];
      this.form.content_type = [];
      contents.forEach((element) => {
        const existElement = form[element] ?? null;

        if (existElement) {
          form.content_type.push(element);
          delete form[element];
        }
      });
      return form;
    },

    async search() {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      const form = this.prepareSearchRequest();
      document.dispatchEvent(new CustomEvent("SearchHomeLoading", { detail: true }));
      let data = await ContentRepository.search(form);
      document.dispatchEvent(new CustomEvent("searchHomeLoaded", { detail: data }));
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
    },

    setFormSelects(selector) {
      this.form[selector.key] = selector.value;
      this.formAux[selector.key] = selector.name;
    },
    getSearchRoute() {
      return TranslationService.transRoute("search-page");
    },
    initStayPage() {
      PageStayController.startStayPageTime("home", 0);
    },
    addSelectorsListeners() {
      $("body").on("hidden.bs.select", ".selectpicker", (ev) => {
        //Only if changed
        if (this.hasSelectedValue) {
          setTimeout(() => {
            // this.$root.$emit("showCustomLoader", true);
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
            this.search();
            this.hasSelectedValue = false;
          }, 75);
        }
      });

                //Set selected value to false when opening selector
                $('body').on("shown.bs.select", '.selectpicker', ev => {
                    this.hasSelectedValue = false;
                    const category = $(ev.target).attr('title');
                    GoogleAnalytics.sendExpandContentEvent(category);
                });
                //Capture when dropdown is closed
                $('body').on("hidden.bs.select", '.selectpicker', ev => {
                    const category = $(ev.target).attr('title');
                    GoogleAnalytics.sendCollapseContentEvent(category);
                });
                //Set selected value to false when selected value
                $('body').on("changed.bs.select", '.selectpicker', ev => {
                    this.hasSelectedValue = true;
                    const targetSelector = $(ev.target);
                    const category = $(ev.target).attr('title');
                    const selectedOptionText = targetSelector.find('option:selected').text();
                    GoogleAnalytics.sendFilterEvent(selectedOptionText, category, selectedOptionText);
                });
            },
        },
        beforeDestroy() {
            $(document).off('click', '.dropdown-item');
         }
    }
</script>
