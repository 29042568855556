export default {
    get(content) {
         return {
            id: content?.hf_participant.id ?? '',
            full_name: content?.hf_participant.full_name ?? '',
            image: content?.hf_participant.image ?? '',
            bio: content?.hf_participant.bio ?? '',
            speciality: content?.hf_participant.speciality ?? '',
            number_likes: content?.number_likes ?? '',
            comment_count: content?.comments.length ?? '',
            category_id: content?.category_id ?? '',
            list_comments: content?.comments ?? '',
            hf_item_id: content?.id ?? '',
            liked_by_user: content?.liked_by_user ?? ''
        };
    },
    
};