import MmpLogin from '../objects/v4/modules/login/MmpLogin.vue';
import RecoveryPasswordModal from '../objects/v4/modules/modals/RecoveryPasswordModal.vue';
import MmpResetPassword from '../objects/v4/modules/password/MmpResetPassword.vue'
import CreatePassword from '../objects/v4/modules/register/components/CreatePassword.vue';

const vueComponents = [
    ['MmpLogin', MmpLogin],
    ['CreatePassword', CreatePassword],
    ['RecoveryPasswordModal', RecoveryPasswordModal],
    ['MmpResetPassword', MmpResetPassword],
]

export default vueComponents