<template>
    <div class="box-common-size">
        <div class="new-voting-padd">
            <div class="left-timer" v-if="expiration_time > 0" :data-poll-id="id"><i class="bi bi-hourglass-split"></i>
                <span>{{timePoll}}</span></div>
            <div class="live-title">
                <h4>Live Voting</h4>
            </div>
            <div class="second-title">
                <h2 class="text-center">{{ question }}</h2>
            </div>
            <div class="third-content">
                <p class="text-center">Match!</p>
            </div>
        </div>
        <div class="box-padd-new">
            <div class="line-next-padd-even slider-arrow-hided">
                <div id="media-question-list">
                    <form action="" ref="quesForm" @submit="showSubmit" method="post" class="modal-questions-form"
                        :data-poll-id="id" :data-poll-type="type" :data-poll-expiration="expiration_time"
                        :id="'quesform_' + id">
                        <input type="hidden" name="pollId" class="questiontextid" ref="answer" :value="id">
                        <input type="hidden" name="answer[]" class="questiontextid" :value="item" v-for="item in answer"
                            :key="item">
                        <div class="slide-now">
                            <div class="four-images-match">
                                <div class="box-match-even" v-for="(imglist, index) in list" :key="index">
                                    <div class="left-new-match">
                                        <img :src="imglist.image" @error="showDefaultImage($event)">
                                        <input id="newradio" name="radio[]" type="radio" checked="checked"> <label
                                            for="newradio" class="radio-label"></label>
                                    </div>
                                    <div class="right-new-match">
                                        <img :src="imglist.match_option.image" @error="showDefaultImage($event)">
                                        <input id="newfree" name="radio[]" type="radio" checked="checked"> <label
                                            for="newfree" class="radio-label"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import LivePollsController from "../../../v4/modules/live/LivePollsController"
import $ from 'jquery';

export default {
    props: {
        id : Number,
        type: Number,
        question : String,
        expiration_time : Number,
        list: Array
    },
    data:function(){
        return{
            answer: [],
            timePoll: 0
        }
    },
    mounted() {
        let id = '#quesform_' + this.id;
        $(document).on('submit', id.toString() , ev => this.showSubmit(ev));
        this.timePoll = this.expiration_time;
    },
    watch: {
        timePoll: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timePoll--;
                    }, 1000);
                }else{
                    this.popupQuesType();
                }
            },
        }
    },
    methods: {

        async showSubmit(event){
            event.preventDefault();
            event.stopPropagation();
            if ( this.answer.length == 0 ) {
                LivePollsController.displayToastifyMessage(2 , "Error" , "Answer is required")
            } else {
                var response = await LivePollsController.setAnswerPollFunc(event);
                if ( response.data.status == 201 ) {
                    LivePollsController.displayToastifyMessage(2 , "Error" , response.data.message)
                } else if ( response.data.status == 200 ) {
                    this.popupQuesType();
                    document.dispatchEvent(new CustomEvent("submitLiveSingle"));
                    // this.$root.$emit('submitLiveSingle', {});
                }
            }
        },
        popupQuesType(){
            this.$parent.$parent.closePopup(this.id);
        },
        onSelectImage(event){
            this.answer=[];
            for(let i = 0; i<event.length; i++) {
                this.answer.push(event[i].id);
            }
            console.log(this.answer);
        },
        showDefaultImage(event){
            event.target.src = "/images/v4/picture.png"
        }
    }
}
</script>
