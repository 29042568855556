<template>
  <section class="section-box webex__participants">
    <button type="button" class="btn-close" @click="store.toggleShowParticipants()" aria-label="Close modal">
      x
    </button>
    <h2 class="collapsible">
      Participants
    </h2>
    <div class="section-content collapsed">
      <!-- meetings / participants -->
      <div>
        <fieldset>
          <legend>Participants</legend>
          <div id="participant-list">
            <div v-for="(participant, index) in participants" :key="index" class="webex__participants__participant">
              <div>
                <span class="webex__participants__participant--avatar">{{ avatar(participant.name) }}</span>
                <div class="webex__participants__participant--info">
                  <span>{{ participant.name ?? 'Unknown' }}</span>
                </div>
              </div>
              <div class="webex__participants__participant--actions">
                <i v-if="participant.isHandRaised ?? false"
                  class="webex__participants__participant--hand-up bi bi-hand-index-fill"></i>
                <!-- Mic controls -->
                <i v-if="participant.isAudioMuted ?? false"
                  class="webex__participants__participant--muted bi bi-mic-mute-fill"></i>
                <i v-if="!(participant.isAudioMuted ?? false)"
                  class="webex__participants__participant--not-muted bi bi-mic-fill"
                  :class="{ 'clickable': !participant.isAudioMuted }" @click.prevent="toggleMute(participant)"></i>
                <!-- End Mic controls -->
                <!-- Webcam controls -->
                <i v-if="participant.isVideoMuted ?? false"
                  class="webex__participants__participant--muted bi bi-camera-video-off-fill"></i>
                <i v-if="!(participant.isVideoMuted ?? false)"
                  class="webex__participants__participant--not-muted bi bi-camera-video-fill"></i>
                <!-- End Webcam controls -->
                <div v-if="isModerator" class="webex__participants__participant--actions--menu dropdown">
                  <i class="bi bi-three-dots-vertical" data-toggle="dropdown"></i>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#" @click.prevent="remove(participant)">Remove</a>
                  </div>
                </div>
              </div>
            </div>
            <div id="participant-table"></div>
            <div id="participant-btn"></div>
          </div>
          <p class="note multistream" id="remote-sources-info"></p>
        </fieldset>
      </div>
    </div>
  </section>
</template>

<script>
import { useWebexStore } from '../../store/webex.store'

export default {
  props: ['members', 'meeting', 'isModerator'],
  setup() {
    const store = useWebexStore()
    return { store }
  },
  data() {
    return {
      participants: [],
      me: null,
      eventsBinded: false
    }
  },
  mounted () {
    this.bindMeetingEvents()
    this.refreshParticipantsList()
  },
  methods: {
    bindMeetingEvents () {
      if (this.meeting && !this.eventsBinded) {
        this.meeting.getMembers()
          .on('members:update', () => {
            this.refreshParticipantsList()
          })
          .on('members:self:update', () => {
            this.refreshParticipantsList()
          })

        this.eventsBinded = true
      }
    },
    refreshParticipantsList () {
      const members = this.members?.membersCollection?.members

      if (members != null) {
        this.participants = Object.entries(members)
          .filter(([_, member]) => member.status === 'IN_MEETING')
          .map(([_, member]) => member)

        this.me = this.participants.filter(participant => participant.isSelf ?? false).shift()

        // if I'm removed from the meeting clear the participants list
        if (this.me == null) {
          this.participants = []
          this.eventsBinded = false
        }
      }
    },
    avatar (name) {
      return (name ?? 'Unknown')
        .split(' ')
        .filter(value => value !== '' && /^[a-zA-Z]+$/.test(value))
        .slice(0, 2)
        .map(value => value.charAt(0).toUpperCase())
        .join('')
    },
    toggleMute (participant) {
      this.meeting?.mute(participant.id, !participant.isAudioMuted)?.then()
    },
    makeHost (participant) {
      if (!this.isModerator) {
        return
      }

      this.meeting?.transfer(participant.id)?.then()
    },
    remove (participant) {
      if (!this.isModerator) {
        return
      }

      this.members?.removeMember(participant.id)
    }
  },
  watch: {
    meeting () {
      this.bindMeetingEvents()
    }
  }
}
</script>
