<template>
<div>
    <div v-if="isLoading">{{t('general.loading')}}...</div>
    <div v-if="!isLoading">
        <div v-for="(category, index ,key) in list" v-bind:key="key">
            <p class="text-bold">
                {{category.name}}
            </p>
            <div v-if="!isMobile()">
                <V4Carousel v-bind="{slidesToShow:3, slidesToScroll:3, name: `trending-content-${index}`, isLoading: false, numberCarousel: false}">
                    <template v-slot:items>
                        <V4Card v-for="ev in category.multimedia" v-bind="ev" :key="ev.id"></V4Card>
                    </template>
                </V4Carousel>
            </div>
            <div v-else>
                <V4Carousel v-bind="{slidesToShow:1, slidesToScroll:1, name: `trending-content-${index}`, isLoading: false, numberCarousel: false}">
                    <template v-slot:items>
                        <V4Card v-for="ev in category.multimedia" v-bind="ev" :key="ev.id"></V4Card>
                    </template>
                </V4Carousel>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import V4Carousel from '../carousel/V4Carousel.vue';
import V4Card from '../card/V4Card.vue';
import EventFactory from '../events/factories/Event.factory';
import $ from 'jquery';

export default {
    name: 'PodcastSection',
    components: {
        V4Carousel,
        V4Card
    },
    props: {
        podcastsByCategory: Object
    },

    data() {
        return {
            isLoading: true,
            list: [],
            error: '',
            translator : TranslateService.create()
        }
    },

    mounted() {
        this.get();
    },

    methods: {
        format(ev) {
            return EventFactory.get(ev)
        },

        async get() {
            try {
                this.isLoading = true;
                this.error = '';
                let list = [];

                this.podcastsByCategory.multimedia.forEach(function callback(category,i){
                    list.push(category);
                    list[i].multimedia = list[i].multimedia.map(item => EventFactory.get(item));
                });
                this.list = list;

                this.isLoading = false;
                
                if(this.list.length == 0){
                    $('#podcasts').css('display', 'none');
                    $('#podcasts_menu').css('display', 'none');
                    $("a[href='#podcasts']").css('display', 'none');
                }
            } catch (error) {
                this.list = [];
                this.error = "something happened";
                this.isLoading = false;
            }
        },

        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        t(tag){
            return this.translator.translate(tag)
        },
    }
}
</script>
