<template>
    <div class="livevoting-popup">
        <SurveyModalComponent v-bind:type="'textareaanswer'" v-show="isVotingpopupVisible" @close="closeModal" :key="k">
            <template v-slot:body>
                <div id="media-question-list" v-if="!isLoading && formdetails.length > 0" :key="totalcount">
                    <div class="voting-list-items" v-for="(formcontent, i) in formdetails" :id="formcontent.poll.id"
                        :key="i">
                        <CongressLiveQuestion v-if="formcontent.poll.type == 2" :type="formcontent.poll.type"
                            :expiration_time="formcontent.poll.expiration_timeout" :id="formcontent.poll.id"
                            :question="formcontent.poll.question" @popupQuesClose="closePopup(3)"
                            :key="formcontent.poll.id"></CongressLiveQuestion>

                        <CongressLiveSingle v-if="formcontent.poll.type == 1" :type="formcontent.poll.type"
                            :expiration_time="formcontent.poll.expiration_timeout" :id="formcontent.poll.id"
                            :question="formcontent.poll.question" @popupQuesClose="closePopup(1)"
                            :options="formcontent.poll.options" :key="formcontent.poll.id"></CongressLiveSingle>

                        <CongressLiveMultiple v-if="formcontent.poll.type == 4" :type="formcontent.poll.type"
                            :expiration_time="formcontent.poll.expiration_timeout" @popupQuesClose="closePopup(4)"
                            :id="formcontent.poll.id" :question="formcontent.poll.question"
                            :options="formcontent.poll.options" :key="formcontent.poll.id"></CongressLiveMultiple>

                        <CongressLiveRadio :id="formcontent.poll.id" v-if="formcontent.poll.type == 6"
                            :expiration_time="formcontent.poll.expiration_timeout" :question="formcontent.poll.question"
                            :list="formcontent.poll.options" @popupQuesClose="closePopup(4)"
                            :type="formcontent.poll.type" :key="formcontent.poll.id"></CongressLiveRadio>

                        <CongressLiveMatch :id="formcontent.poll.id" v-if="formcontent.poll.type == 7"
                            :list="formcontent.poll.options" :expiration_timeout="formcontent.poll.expiration_time"
                            :question="formcontent.poll.question" @popupQuesClose="closePopup(5)"
                            :type="formcontent.poll.type" :key="formcontent.poll.id"></CongressLiveMatch>

                        <CongressLiveFillgaps :id="formcontent.poll.id" v-if="formcontent.poll.type == 8"
                            :expiration_time="formcontent.poll.expiration_timeout" :question="formcontent.poll.question"
                            @popupQuesClose="closePopup(6)" :type="formcontent.poll.type" :key="formcontent.poll.id"
                            :filldetails="formcontent.poll"></CongressLiveFillgaps>
                    </div>
                </div>
                <div class="box-padd-new box-padd-full">
                    <div class="footer-box-cm clearfix popup-slider-icon">
                        <div class="one-five pull-left">
                            <a href="javascript:void(0);"><i class="bi bi-chevron-left" v-on:click="slickPrev"></i></a>
                            <label>
                                <span class="active-highlight pagingInfo">{{ prevcount }}</span> / {{ totalcount }}
                            </label>
                            <a href="javascript:void(0);"><i class="bi bi-chevron-right" v-on:click="slickNext"></i></a>
                        </div>
                        <div class="send-now-btn pull-right">
                            <button class="btn btn-primary v4-btn-primary" type="button"
                                v-on:click="setAnswerPoll">Send</button>
                        </div>
                    </div>
                </div>
            </template>
        </SurveyModalComponent>
    </div>
</template>
<script>
import { WSHelper } from '../../../../libs/ws/ws-helper';
import Utils from '../../Utils';
import SurveyModalComponent from './SurveyModalComponent.vue';
import CongressLiveQuestion from './CongressLiveQuestion.vue';
import CongressLiveSingle from './CongressLiveSingle.vue';
import CongressLiveMultiple from './CongressLiveMultiple.vue';
import CongressLiveRadio from './CongressLiveRadio.vue';
import CongressLiveMatch from './CongressLiveMatch.vue';
import CongressLiveFillgaps from './CongressLiveFillgaps.vue';
import { useLivePollStore } from '../../store/live.store.js';
import { ref, watch, onMounted, getCurrentInstance, nextTick } from 'vue';
import $ from 'jquery';

export default {
    setup() {
        const livePollStore = useLivePollStore();
        const isVotingpopupVisible = ref(false);
        const formdetails = ref([]);
        const totalcount = ref(0);
        const instance = getCurrentInstance();

        watch(() => livePollStore.currentPoll, (newPoll) => {
            formdetails.value = [newPoll];
            totalcount.value = Object.keys(formdetails.value).length;
            if (totalcount.value >= 1) {
                isVotingpopupVisible.value = true;
                nextTick(() => {
                    instance.proxy.loadslider();
                });
            }
        });

        return {
            livePollStore,
            isVotingpopupVisible,
            formdetails,
            totalcount,
        };
    },

    components: {
        SurveyModalComponent,
        CongressLiveQuestion,
        CongressLiveSingle,
        CongressLiveMultiple,
        CongressLiveRadio,
        CongressLiveMatch,
        CongressLiveFillgaps,
    },
    data() {
        return {
            isLoading: false,
            isTextAreaAnswerVisible: false,
            isRadioAnswerVisible: false,
            isCheckboxVisible: false,
            isMatchTextVisible: false,
            isMatchImageVisible: false,
            isFillGapVisible: false,
            prevcount: 0,
            expirationTime: 0,
            k: 0
        }
    },
    // watch: {
    //     '$store.getters.getCurrentPoll': function () {
    //         this.formdetails = this.$store.getters.getCurrentPoll
    //         this.totalcount = this.formdetails.length;
    //         if (this.totalcount >= 1) {
    //             this.isVotingpopupVisible = true;
    //             let that = this;
    //             this.$nextTick(function () {
    //                 that.loadslider();
    //             });
    //             // setTimeout(function() {
    //             //     that.changetimequestion();
    //             // }, 60000);
    //         }
    //     },
        // timerCountDown() {
        //     if (this.expirationTime > 0) {
        //         setTimeout(() => {
        //             this.expirationTime--;
        //             console.log('tiempo restante', this.expirationTime);
        //         }, 1000)
        //     }else{
        //         console.log('acabadooo');
        //     }
        // }
    // },
    methods: {
        closeModal() {
            if ($('.slick-current .voting-list-items').length) {
                let id = $('.slick-current .voting-list-items').attr('id');
                this.closePopup(id);
            } else {
                this.isVotingpopupVisible = false;
            }
        },
        closePopup(id) {
            this.formdetails = this.formdetails.filter(function (obj) {
                return obj.poll.id.toString() != id.toString();
            });
            this.totalcount = this.formdetails.length;
            let that = this;
            if (this.totalcount >= 1) {
                this.isVotingpopupVisible = true;
                this.$nextTick(function () {
                    that.loadslider();
                });
            } else {
                this.isVotingpopupVisible = false;
            }
            //Remove button listener
            let formId = '#quesform_' + id;
            $(document).off('submit', formId.toString());
        },
        removeslider() {
            if ($('#media-question-list').hasClass('slick-initialized')) {
                $('#media-question-list').slick('unslick');
            }
        },
        loadslider() {
            if ($('#media-question-list').hasClass('slick-initialized')) {
                $('#media-question-list').slick('setPosition');
            } else {
                this.removeslider();
                $('#media-question-list').not('.slick-initialized').slick({
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    arrows: false
                });
                this.addSliderListener();
                $('#media-question-list').slick('refresh');
            }
        },
        addSliderListener() {
            $('#media-question-list').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                this.prevcount = (currentSlide ? currentSlide : 0) + 1;
                $('.pagingInfo').html(this.prevcount);
            });
        },
        slickNext() {
            $('#media-question-list').slick('slickNext');
        },
        slickPrev() {
            $('#media-question-list').slick('slickPrev');
        },
        setAnswerPoll() {
            $('.slick-current .modal-questions-form').trigger("submit")
        },
        onWSMessageShowpoll(evt) {
            let that = this;
            // that.removeslider();
            if (evt.json && evt.json.data) {
                const data = evt.json.data;
                if (data.action == "show_poll") {
                    const response = { data, status: 200 };
                    this.formdetails = [];
                    try {
                        //Remove already answered
                        if (response.data.livePollsData) {
                            let answeredPollsIds = localStorage.answeredPollsIds ? JSON.parse(localStorage.answeredPollsIds) : [];
                            for (let i = response.data.livePollsData.length - 1; i >= 0; i--) {
                                let objId = response.data.livePollsData[i].poll.id;
                                if (answeredPollsIds.includes(objId.toString())) {
                                    response.data.livePollsData.splice(i, 1);
                                }
                            }
                        }

                        this.livePollStore.pendings = response.data.livePollsData;
                        //this.$store.commit('setPendings', response.data.livePollsData);
                        this.formdetails = response.data.livePollsData;
                        this.totalcount = this.formdetails.length;
                        if (this.totalcount >= 1) {
                            this.isVotingpopupVisible = true;
                            this.$nextTick(function () {
                                that.loadslider();
                            });
                            // setTimeout(function() {
                            //     that.changetimequestion();
                            // }, 60000);
                        } else {
                            this.closeModal();
                        }
                    } catch (e) {
                        console.debug("ERR", e);
                    }

                }
            }
        },
        changetimequestion() {
            let that = this;
            let presenttime = Date.now()
            that.removeslider();
            $(".left-timer").each(function () {
                let questiontime = $(this).data('totaltime');
                if (questiontime != 0) {
                    if (questiontime < presenttime) {
                        let pollid = $(this).data("poll-id");
                        if (this.isVotingpopupVisible) {
                            that.closePopup(pollid);
                        }
                    }
                }
            });
        }
    },
    mounted() {
        let that = this;
        this.ws = new WSHelper(true);
        this.ws.registerCallback((evt) => {
            switch (evt.operation) {
                case 'conn_retry_failure':
                    // do something like displaying a loading circle?
                    break;
                case 'conn_down':
                    console.debug('Connection down...');
                    break;
                case 'msg_received':
                    this.onWSMessageShowpoll(evt);
                    break;
                case 'conn_started':
                case 'conn_restarted':
                    // Ask current status:
                    const agendaSessionId = Utils.getAgendaSessionId();
                    that.ws.sendObject({ "agenda_id": agendaSessionId, operation: "register_ws", "uid": window.user.uid });
                    break;
            }
        });
        this.ws.start().then(res => {
            // alert(res);
        }).catch(err => {
            // alert("ERR", err);
        });

        this.addSliderListener();
    }
}
</script>
