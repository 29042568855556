<template>
  <div class="carousel_block">
    <div v-if="isLoading">{{ t('general.loading') }}...</div>

    <div v-if="!isLoading">
      <div v-if="!isMobile()">
        <!-- DESKTOP VERSION -->
        <V4Carousel :slidesToShow="3" :slidesToScroll="3" :name="'related'">
          <template v-slot:items>
            <div v-for="(item, i) in list" v-bind:key="i">
              <V4Card v-bind="item"></V4Card>
            </div>
          </template>
        </V4Carousel>
      </div>
      <div v-else>
        <!-- MOBILE VERSION -->
        <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'related'">
          <template v-slot:items>
            <div v-for="(item, i) in list" v-bind:key="i">
              <V4Card v-bind="item"></V4Card>
            </div>
          </template>
        </V4Carousel>
      </div>
      <p class="no_records" v-if="isRecord">{{ t('general.no-results') }}</p>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import RelatedFactory from './factories/Related.factory';
import V4Carousel from '../carousel/V4Carousel.vue';
import V4Card from '../card/V4Card.vue';
import $ from 'jquery';
import axios from 'axios';

export default {
  name: 'RelatedlistWeb',
  components: {
    V4Carousel,
    V4Card
  },
  props: ['slug'],
  data() {
    return {
      isRecord: false,
      isLoading: true,
      list: [],
      translator: TranslateService.create()
    };
  },
  mounted() {
    this.fetch(this.slug);
  },

  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async fetch() {
      const response = await this.getData();
      const results = response.data.data.map(k => RelatedFactory.get(k));
      this.list = results;
      this.isLoading = false;
      if (this.list.length == 0) {
        this.isRecord = true;
        $('#related').hide();
        $('#realted-contents').hide();
      }
    },
    getData() {
      return axios.get('/congresses/related-content?slug=' + this.slug);
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
};
</script>
