<template>
  <div class="connectmi-event__aurora">
    <div class="connectmi-event__aurora__helper">
      <div class="connectmi-event__aurora--1"></div>
      <div class="connectmi-event__aurora--2"></div>
      <div class="connectmi-event__aurora--3"></div>
      <div class="connectmi-event__aurora--filter"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},

  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>
