<template>
  <label class="switch-holder">
    <input class="input" type="checkbox" :checked="checked" @change="toggleSwitch" :id="id" />
    <span class="switch"></span>
    <span class="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: {
    value: Boolean,
    id: String,
    label: String,
  },
  data() {
    return {
      elementId: this.id ?? "",
      checked: this.value ?? false,
    };
  },
  methods: {
    toggleSwitch() {
      this.checked = !this.checked;
      this.$emit("switch", {
        value: this.checked,
        key: this.elementId,
      });
    },
  },
};
</script>

<style>
.switch-holder {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 200px;
}

/* Visually hide the checkbox input from the DOM, we only need it semantically */
.input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
/* color background */
.switch {
  --switch-container-width: 35px;
  --switch-size: calc(var(--switch-container-width) / 2);
  display: flex;
  align-items: center;
  position: relative;
  height: var(--switch-size);
  flex-basis: var(--switch-container-width);
  border-radius: var(--switch-size);
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
  border: 1px solid grey;
}
/* circle inside the switch */
.switch::before {
  content: "";
  position: absolute;
  left: 2px;
  height: calc(var(--switch-size) - 6px);
  width: calc(var(--switch-size) - 6px);
  border-radius: 9999px;
  background-color: grey;
  transition: all 0.375s ease-in-out;
}
/* style for ON state */
.input:checked + .switch {
  background-color: #4fd1c5;
}
.input:checked + .switch::before {
  background-color: white;
}

.input:checked + .switch::before {
  border-color: #4fd1c5;
  transform: translateX(calc(var(--switch-container-width) - var(--switch-size)));
}
/* text */
.label {
  margin-left: 10px;
  font-size: 1rem;
  color: white;
  display: block;
}
</style>
