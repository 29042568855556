<template>

    <div class="mmp-login-content" v-if="isValidToken">
        <div class="mmp-login-content__title" v-if="!isCreatePwd">
            {{ t('mmp-reset-password.header') }}
        </div>
        <div class="mmp-login-content__title" v-else>
            {{ t('mmp-reset-password.header_create') }}
        </div>
        <form class="mmp-login-content__form">

            <CreatePassword :email="email" :isCreatePwd="isCreatePwd"></CreatePassword>

            <div class="error-recovery-message" v-if="errorMessage != ''">
                <p class="error-recovery-message-response validate-error" style="color:red;" v-html="errorMessage"></p>
            </div>

            <TermConditionsQuestions ref="termsAndQuestionsComponent" :loggedUser="false" :country="country"
                :slug="slug" :user-id="userId" :tc-already-accepted="newsletterAccepted"></TermConditionsQuestions>

            <div class="text-right mt-5 satellital-separator-xl">
                <button type="submit" class="v4-btn-primary mmp-login-content__form__button"
                    @click="submitResetPassword" :disabled="!validPasswordForm"
                    data-testid=”reset-password-submit-button”>
                    <span>{{ t('mmp-reset-password.reset-button') }}</span>
                </button>
            </div>
        </form>
    </div>
    <div class="mmp-login-content" v-else>
        <div class="mmp-login-content__title">
            {{ t('mmp-reset-password.header_invalid_token') }}
        </div>
        <form class="mmp-login-content__form">
            <div class="mmp-login-content__text">
                {{ t('mmp-reset-password.text_invalid_token') }}
            </div>
            <div class="mt-5 satellital-separator-xl">
                <button type="button" class="v4-btn-primary mmp-login-content__form__button" @click="resendLink()"
                    data-testid=”reset-password-resend-button”>
                    <span>{{ t('mmp-reset-password.resend-button') }}</span>
                </button>
            </div>
        </form>
    </div>

</template>

<script>
import TranslateService from '../../core/services/translate.service.js';
import CreatePassword from '../register/components/CreatePassword.vue';
import TermConditionsQuestions from '../register/components/TermConditionsQuestions.vue';
import { useCreatePwd } from '../../store/modal.store.js';
import { useEmailPasswordStore } from '../../store/form.store.js';
import { create } from 'lodash';
import { reactive, watch, computed, onMounted, toRefs } from 'vue';
import axios from 'axios';

export default {
    setup() {
        const createPwdStore = useCreatePwd();
        const emailPasswordStore = useEmailPasswordStore();

        let validPasswordForm = computed(() => emailPasswordStore.validPasswordForm);
        let validEmailForm = computed(() => emailPasswordStore.email);

        const state = reactive({
            translator: TranslateService.create(),
            passwordForm: {
                email: '',
                password: '',
                repeatPassword: '',
                validPassword: '',
                
            },
            errorMessage: '',
            showTermsAndConditions: false,
            questionResponses: null,
            checkedTC: false,
            tcArray: [],
            newsletter: false,
        });

        const handleEvent = (event) => {
            const detail = event.detail;
            if (detail) {
                const { form: { validStatus, termsChecked, questionResponses, newsletter }, termsAndConditions } = detail;
                state.checkedTC = validStatus ?? false;
                state.tcArray = termsAndConditions.map(({ id, scope }) => ({ id, scope }));
                state.questionResponses = questionResponses ?? null;
                state.newsletter = newsletter ?? false;
            }
        };
        watch([validPasswordForm, validEmailForm], () => {
            state.passwordForm.email = validEmailForm.value;
            state.passwordForm.validPassword = validPasswordForm.value;
            state.passwordForm.password = emailPasswordStore.password;
            state.passwordForm.repeatPassword = emailPasswordStore.repeatPassword;
        });

        onMounted(() => {
        document.addEventListener('termConditionsAndQuestions', handleEvent);
        });

        return {
            validPasswordForm,
            createPwdStore,
            ...toRefs(state),
        }
    },
    components: {
        CreatePassword,
        TermConditionsQuestions,
    },
    props: {
        isValidToken: false,
        isCreatePwd: false,
        token: String,
        email: String,
        slug: String,
        country: Number,
        userId: Number,
        newsletterAccepted: Boolean,
    },
    methods: {
        t(tag) {
            return this.translator.translate(tag);
        },
        submitResetPassword(e) {
            e.preventDefault();
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
            axios.post('/reset-password', this.getFormData())
                .then(response => {
                    document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
                    window.location.href = response.data.redirect_uri;
                }).catch(error => {
                    document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
                    this.errorMessage = error.response.data.error;
                });
        },
        getFormData() {
            return {
                token: this.token,
                email: this.passwordForm.email,
                password: this.passwordForm.password,
                password_confirmation: this.passwordForm.validPassword ? this.passwordForm.repeatPassword : '',
                terms: this.tcArray,
                questionResponses: this.questionResponses,
                slug: this.slug ?? null,
                userId: this.userId,
                newsletter: this.newsletter ?? false,
            }
        },
        openLink(url) {
            window.open(url, '_blank');
        },
        resendLink() {
            this.createPwdStore.toggleRecoverySubmit(this.slug, this.email);
        },
    }
};
</script>
