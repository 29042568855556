/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import RoutesHelper from './objects/v4/routesHelper';
import MultiEventHeaderController from './objects/v4/MultiEventHeaderController';
import HospitalitySuiteController from './objects/v4/HospitalitySuiteController';
import daterangepicker from 'daterangepicker';
import CompleteUserFieldsModal from './objects/v4/CompleteUserFieldsModal';
import Utils from './objects/v4/Utils';
import { axiosInstance } from './http-client';
import $ from 'jquery';

const ViewHandler = {
  daterangepicker: null,

  routes: {
    acceptCookies: 'set-accept-cookies'
  },

  cookiesContainerEl: {
    selector: '#fake-cookiebot',
    oldSelector: '#gdpr'
  },

  acceptCookiesEl: {
    selector: '#FakeBotDialogBodyLevelButtonLevelOptinAllowAll',
    oldSelector: '#accept-cookies',
    el: null
  },

  appLoaderEl: {
    selector: '#loader',
    el: null
  },

  welcome: {
    selector: '#hospitality-welcome'
  },

  hotSpot: {
    selector: '.custom-hotspot, .menu-option'
  },

  amgenSession: {
    selector: '.card-session'
  },

  getAcceptCookiesUrl: function() {
    let routesHelper = new RoutesHelper();
    let url = routesHelper.getRoute(this.routes.acceptCookies);

    return url;
  },

  onAcceptCookies: function() {
    let cookiesContainer = document.querySelector(this.cookiesContainerEl.selector);

    const url = this.getAcceptCookiesUrl();
    axios.post(url, {}).then(() => {
      cookiesContainer.classList.add('hide');
      window.localStorage.setItem('fakeBotOk', 'true');
    });
  },

  navigatorChrome: function() {
    return navigator.userAgent.toLowerCase().indexOf('chrome') >= 0;
  },

  init: function(data) {
    this.daterangepicker = daterangepicker;
    document.addEventListener('DOMContentLoaded', () => {
      this.onDocumentReady();
    });
  },

  backToTop: function() {
    var progressPath = document.querySelector('.progress-wrap path');
    if(progressPath) {
      var pathLength = progressPath.getTotalLength();
      progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
      progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
      progressPath.style.strokeDashoffset = pathLength;
      progressPath.getBoundingClientRect();
      progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
      var updateProgress = function() {
        var scroll = $(window).scrollTop();
        var height = $(document).height() - $(window).height();
        var progress = pathLength - (scroll * pathLength) / height;
        progressPath.style.strokeDashoffset = progress;
      };
      updateProgress();
      $(window).scroll(updateProgress);
      var offset = 500;
      var duration = 550;
      jQuery(window).on('scroll', function() {
        if (jQuery(this).scrollTop() > offset) {
          jQuery('.progress-wrap').addClass('active-progress');
        } else {
          jQuery('.progress-wrap').removeClass('active-progress');
        }
      });
      jQuery('.progress-wrap').on('click', function(event) {
        event.preventDefault();
        jQuery('html, body').animate({ scrollTop: 0 }, duration);
        return false;
      });
    }
  },
  /**
   * BUGFIXES CHROME
   */
  onModalShow: function() {
    const isChrome = this.navigatorChrome();
    if (isChrome) {
      const self = this;
      $('.modal').on('show.bs.modal', function() {
        let inputs = $(this).find('input');

        for (let i = 0; i < inputs.length; i++) {
          let element = inputs[i];
          self.activeInputFocus(element);
        }
      });
    }
  },

  generalEvents: function() {
    let loader = document.querySelector('#loader');
    setTimeout(function() {
      loader.classList.add('loaded');
      document.querySelector('#app').classList.add('loaded');

      setTimeout(function() {
        //    loader.parentNode.removeChild(loader);
        loader.classList.add('d-none');
      }, 50);
    }, 50);
  },

  setListeners: function() {
    let self = this;
    this.acceptCookiesEl.el = document.querySelector(this.acceptCookiesEl.selector);
    this.appLoaderEl.el = document.querySelector(this.appLoaderEl.selector);
    this.hotSpots = document.querySelectorAll(this.hotSpot.selector);
    this.welcome = document.querySelector(this.welcome.selector);
    this.sessions = document.querySelectorAll(this.amgenSession.selector);

    if(window.localStorage.getItem('welcome-passed')) {
      this.welcomeFadeOut();
    }
    if(this.welcome) {
      $('body').on("click", this.welcome, ev => {
        this.welcomeFadeOut();
      });
    }

    this.openMeetingUsers();

    $('body').on("click", this.hotSpot.selector, ev => {
        $('.custom-hotspot').hide();
        let title = ev.currentTarget.getAttribute('data-title');
        $('#videoModal .modal-title').html(
          title + '<button type="button" class="close" data-dismiss="modal">×</button>'
        );
        $('.menu-option').removeClass('active');
        $('#videoModal .modal-body').empty();
        $(".hs-select").selectpicker("destroy");
        switch (ev.currentTarget.getAttribute('data-modal')) {
          case 'faqs':
            $('#videoModal .modal-body').html($('#content-faqs').html());
            $('.hotspot1-option').addClass('active');
            break;
          case 'diary':
            let diaryHTML = $('#content-diary').clone();
            diaryHTML.find('select').attr('id', 'hs-timezone');
            $('#videoModal .modal-body').html(diaryHTML.html());
            $('.hotspot2-option').addClass('active');
            $('.hs-select').selectpicker('refresh');
            $('#hs-timezone').on("changed.bs.select", (ev) => {
              this.changeTimezone(ev);
            });
            break;
          case 'content':
            $('#videoModal .modal-body').html($('#content-cards').html());
            $('.hotspot3-option').addClass('active');
            break;
          case 'sessions':
            $('#videoModal .modal-body').html($('#content-sessions').html());
            $('.hotspot4-option').addClass('active');
            break;
        }
        $('#videoModal .modal-body').prepend($('.modal-menu').html());
        $('#videoModal .modal-footer').html('');
    });

    $('#videoModal').on('click', '.card-session-container', function() {
      var eventSlug = this.getAttribute('data-target');
      $('#videoModal .modal-title').html(
        '<div class="back"></div> Explore Amgen\'s sessions <button type="button" class="close" data-dismiss="modal">×</button>'
      );
      $('#videoModal .modal-body').html($('#' + eventSlug).html());
      $('#videoModal .modal-body').prepend($('.modal-menu').html());
    });

    $('#videoModal').on('click', '.back', function() {
      $('#videoModal .modal-title').html(
        'Explore Amgen\'s sessions <button type="button" class="close" data-dismiss="modal">×</button>'
      );
      $('#videoModal .modal-body').html($('#content-sessions').html());
      $('#videoModal .modal-body').prepend($('.modal-menu').html());
    });

    $('#videoModal').on('click', '.join-meeting', function() {
      var meetingUrl = this.getAttribute('data-meeting');
      window.location.replace(meetingUrl);
    });

    $('body').on('click', '.meeting-lobby-button', function() {
      var meetingUrl = this.getAttribute('data-meeting');
      if($('.hs-not-logged-form').length) {
        meetingUrl = meetingUrl + `?name=${$('#hs-not-logged-name').val()}&surname=${$('#hs-not-logged-surname').val()}&country=${$('#hs-not-logged-select').val()}`;
      }
      window.location.replace(meetingUrl);
    });

    if($('.hs-not-logged-form').length) {
      getExtraInputs($('#hs-not-logged-select').val(), $('#hs-not-logged-select').attr('data-slug'));
      $('body').on('input, change', '.hs-not-logged-form input, .hs-not-logged-form select', ev => {
        checkNotLoggedInputs();
        if(event.currentTarget.id == 'hs-not-logged-select') {
          getExtraInputs($('#hs-not-logged-select').val(), $('#hs-not-logged-select').attr('data-slug'));
        }
      });
    }

    $('#videoModal').on('click', '.congress-content', function() {
      const isExternal = this.hasAttribute("data-external");
      if(isExternal){
        return;
      }
      let url = this.getAttribute('data-url');
      window.open(url, '_blank').focus();
    });

    $('body').on("click", ".agenda-box-go-to-session", ev => { this.saveActivity(ev); });

    function checkNotLoggedInputs() {
      if ($('#hs-not-logged-select').val() !== '' && $('#hs-not-logged-name').val() !== '' && $('#hs-not-logged-surname').val() !== ''
        && $('#hs-not-logged-check').prop('checked') == true && $('.extra input:checked').length == $('.extra input').length){
        $('.hs-not-logged-button').prop('disabled', false);
      } else {
        $('.hs-not-logged-button').prop('disabled', true);
      }
    }
    function toggleModalMenu(action) {
      if (action == 'show') {
        $('.main-menu').removeClass('d-none');
      } else {
        $('.main-menu').addClass('d-none');
      }
    }

    function toggleMeetingMenu(action) {
      if (action == 'show') {
        $('.meeting-menu-div').removeClass('d-none');
      } else {
        $('.meeting-menu-div').addClass('d-none');
      }
    }

    function getExtraInputs(countryId = '', slug = '') {
      const data = {
        country: countryId
      };
      axiosInstance.post(getSlugUrl('hs-get-extras', slug), data)
        .then(response => {
          addExtrasToForm(response.data);
        })
        .catch(error => console.log(error));
    }
    function getSlugUrl(route, slug = '') {
      let routesHelper = new RoutesHelper();
      return routesHelper.getRoute(route, slug, '%slug%');
    }
    function addExtrasToForm(extras) {
      const { htmlLegal } = extras;
      $('.extra-legals:visible').empty();
      $(`.extra-legals`).append(htmlLegal);
      checkNotLoggedInputs();
    }

    $('#videoModal').on('click', '.back-meeting', function() {
      var eventSlug = this.getAttribute('data-target');
      $('.mev-container').removeClass('meeting');
      $('.meeting-div').remove();
      $('#videoModal .modal-title').html(
        '<div class="back"></div> Explore Amgen\'s sessions <button type="button" class="close" data-dismiss="modal">×</button>'
      );
      $('#videoModal .modal-body').html($('#' + eventSlug).html());
      $('#videoModal .modal-body').prepend($('.modal-menu').html());
    });

    $('#videoModal').on('click', '.carousel-control', function(e) {
      e.preventDefault();
      $('.carousel').carousel($(this).data());
    });

    $('#videoModal').on('click', '.carousel-indicators li', function(e) {
      e.preventDefault();
      $('.carousel').carousel($(this).data('slide-to'));
    });

    $('#videoModal').on('hidden.bs.modal', function() {
      if (!$('.mev-container').hasClass('meeting')) {
        $('.custom-hotspot').show();
      } else {
        toggleMeetingMenu('show');
      }
    });

    if (this.acceptCookiesEl.el) {
      const state =
        !!localStorage.getItem('fakeBotOk') ||
        !!localStorage.getItem('fakeBotDecline') ||
        !!localStorage.getItem('fakeBotPartial');
      if (!state) {
        document.querySelector("section[role='fake-cookiebot']").classList.remove('hide');
      }
      this.acceptCookiesEl.el.addEventListener('click', () => {
        this.onAcceptCookies();
      });
      document
        .querySelector('#FakeBotDialogBodyLevelButtonLevelOptinAllowallSelection')
        .addEventListener('click', ev => {
          const isChecked = el => document.querySelector(el).checked;
          const data = {
            necessary: isChecked('#FakeBotDialogBodyLevelButtonNecessary'),
            functional: isChecked('#FakeBotDialogBodyLevelButtonPreferences'),
            performance: isChecked('#FakeBotDialogBodyLevelButtonStatistics'),
            social: isChecked('#FakeBotDialogBodyLevelButtonMarketing')
          };
          window.localStorage.setItem('fakeBotPartial', JSON.stringify(data));
          document.querySelector("section[role='fake-cookiebot']").classList.add('hide');
        });
      document.querySelector('#FakeBotDialogBodyButtonDecline').addEventListener('click', ev => {
        window.localStorage.setItem('fakeBotDecline', 'true');
        document.querySelector("section[role='fake-cookiebot']").classList.add('hide');
      });
      document.querySelector('#FakeBotDialogBodyLevelDetailsButton').addEventListener('click', ev => {
        document.getElementById('fake-cookiebot').classList.toggle('settings-open');
        document.getElementById('FakeBotDialogDetail').classList.toggle('hidden');
        const el = document.getElementById('FakeBotDialogBodyLevelDetailsButton');
        if (el.innerText === 'Hide Settings') {
          el.innerText = 'Settings';
        } else {
          el.innerText = 'Hide Settings';
        }
      });
      [
        '#FakeBotDialogDetailBodyContentCookieContainerNecessary',
        '#FakeBotDialogDetailBodyContentCookieContainerPreference',
        '#FakeBotDialogDetailBodyContentCookieContainerStatistics',
        '#FakeBotDialogDetailBodyContentCookieContainerAdvertising',
        '#FakeBotDialogDetailBodyContentCookieContainerUnclassified'
      ].forEach(item => {
        document.querySelector(item).addEventListener('click', ev => {
          $('.FakeBotTabs').addClass('hidden');
          $('.FakeBotSelectorTab').removeClass('active');
          const theId = document.querySelector(item).id;
          $(`.${theId}Tab`).removeClass('hidden');
          document.querySelector(item).classList.add('active');
        });
      });
    }

    document.addEventListener('mmp:onLogin:step1success', e => {
      this.onLogin1stepSuccess(e);
    });

    document.addEventListener('mmp:onLogin:step2success', e => {
      this.onLogin2stepSuccess(e);
    });

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.document.addEventListener(
        'touchmove',
        e => {
          if (e.scale !== 1) {
            e.preventDefault();
          }
        },
        { passive: false }
      );
    }
  },
  loadIframeTries: 0,
  passiveLoadIframe: function() {
    console.debug('Started passive load...');
    const iframe = document.querySelector('.multimedia-embed-item');
    if (this.loadIframeTries < 5) {
      if (!iframe) {
        this.loadIframeTries++;
        setTimeout(() => {
          this.passiveLoadIframe();
        }, 5000);
      } else {
        let url = iframe.getAttribute('data-url');
        let realId = iframe.getAttribute('data-real-id');
        let data = window.localStorage.getItem(`multimedia_${realId}`);
        console.debug(realId, data);
        if (!!data) {
          try {
            const parsedData = JSON.parse(data);
            const tracks = parsedData.availableTags || [];
            if (tracks.length > 0) {
              url += `&search=${tracks[0]}#toolbar=0`;
            } else {
              url += `#toolbar=0`;
            }
          } catch (e) {
            console.debug('ERR', e);
          }
        } else {
          url += `#toolbar=0`;
        }
        iframe.setAttribute('data-url', url);
        iframe.setAttribute('src', url);
      }
    }
  },

  saveActivity(ev){
    const url = Utils.getUrl(false, 'save-activity');
    const fd = new FormData();

    var params = '';
    if(ev.currentTarget.dataset.type == 'live') {
      fd.append("event", 'live');
      params = {live: ev.currentTarget.dataset.title , model_type: 'agenda_session',model_id: ev.currentTarget.dataset.id, source: ev.currentTarget.dataset.source,
      congress_id: ev.currentTarget.dataset.congress_id};

      fd.append("parameters",JSON.stringify(params));
        axiosInstance.post(url,fd).then(response => {
          this.data = response.data;
        }).catch(e => {
          console.debug("ERR", e);
        })
    }
  },

  welcomeFadeOut() {
    $('#hospitality-welcome').fadeOut();
    $('.mev-header').removeClass('hidden');
    $('.custom-hotspot').show().removeClass('hidden');
    window.localStorage.setItem('welcome-passed', true);
  },

  openMeetingUsers() {
    $(document).on('click', '.openMeetingUsers', ev => {
      $(".hsMeetingModal").addClass("d-none");
      $(".hsMeetingModal[data-id='"+ ev.currentTarget.dataset.id+"']").removeClass("d-none");
    });
    $(document).on('click', '.closeMeetingUsers', ev => {
      $(".hsMeetingModal").addClass("d-none");
    });
  },

  changeTimezone(ev) {
    let url = this.getUrl('profile-upload');
    let data = new FormData();
    data.append('time_zone', $('#hs-timezone').val());

    axios.post(url, data)
      .then(res => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        urlParams.set('section', 'diary');
        window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
        window.location.reload();
      }).catch(err => {
        console.debug("ERR", 'Problem updating profile timezone');
      })
  },

  getUrl(route) {
    let routesHelper = new RoutesHelper();
    return routesHelper.getRoute(route);
  },

  checkParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const section = urlParams.get('section');
    if(section == 'diary') {
      $('#example-2-modal').trigger('click');
      const urlParams = new URLSearchParams(queryString);
      urlParams.delete('section');
      window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
    }
  },

  onDocumentReady: function() {
    // AnalyticsController.init();
    MultiEventHeaderController.init();
    CompleteUserFieldsModal.init();
    HospitalitySuiteController.init();

    this.generalEvents();
    this.backToTop();
    // this.onModalShow();
    this.setListeners();
    // this.passiveLoadIframe();
    this.checkParams();

    setTimeout(() => {
      this.welcomeFadeOut();
    }, 4000);
  }
};

ViewHandler.init({});
