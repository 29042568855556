export default {
    mapObjectToArray(obj) {
        const arr = [];

        if(Array.isArray(obj)) {
            return obj;
        }

        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                const element = obj[key];
                
                arr.push({
                    id: key,
                    name: element
                });
            }
        }

        return arr;
    }
};