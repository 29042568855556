<template>
    <!-- Main component structure -->
    <div class="webex" id="webex-component">
        <!-- Join button -->
        <button id="webex-join" aria-label="join" ref="join" title="join" type="button" class="button webex__button--join" @click="register"
            v-bind:disabled="isRegistering">
            <em class="bi bi-camera-video-fill"></em>
        </button>

        <!-- Waiting state component -->
        <div id="waiting" ref="waiting" :class="[
            'button webex__waiting',
            webexData.waiting_room_image ? 'waiting-image' : 'waiting-spin',
            'hide'
        ]">
            <!-- Display the waiting room image if it's available, else display a spinning icon -->
            <img v-if="webexData.waiting_room_image" style="width:100%" :src="webexData.waiting_room_image"
                alt="Waiting room" />
            <em v-else class="fas fa-circle-notch fa-spin"></em>
        </div>

        <!-- Main video and control panel section -->
        <div class="webex__viewport">
            <div id="webex-screen" ref="webexScreen" class="webex__screen" :class="{ 'allow-participants-sidebar': store.showParticipants }">
                <!-- Main video views -->
                <video ref="remoteViewVideo" class="webex__main-screen" autoplay playsinline></video>
                <video ref="remoteScreenshare" class="webex__main-screen hide" autoplay playsinline></video>
                <video ref="selfView" class="webex__self-screen" muted autoplay playsinline id="selfCam"></video>
                <video ref="localScreenshare" class="webex__self-screen hide" autoplay playsinline></video>
                <div class="mediaOptionsContainer" v-show="toggleAudioSelect || toggleVideoSelect">
                    <!-- Media devices options -->
                    <div class="mediaOptions" id="audioSelect" ref="audioSelect" v-show="toggleAudioSelect">
                        <button v-for="audioElement in this.audioElements" :key="audioElement.id" :id="audioElement.id"
                            @click="updateAudioDevice(audioElement.id)">
                            <em class="bi bi-mic-fill green"></em> <span>{{ audioElement.text }}</span>
                        </button>
                    </div>
                    <div class="mediaOptions" id="videoSelect" ref="videoSelect" v-show="toggleVideoSelect">
                        <button v-for="videoElement in this.videoElements" :key="videoElement.id" :id="videoElement.id"
                            @click="updateVideoDevice(videoElement.id)">
                            <em class="bi bi-camera-video-fill green"></em> <span>{{ videoElement.text }}</span>
                        </button>
                    </div>
                </div>
                <!-- Control panel -->
                <form ref="controls" class="webex__controls hide">
                    <!-- Control buttons -->
                    <button type="button" class="webex__button mediaSelect green" @click="toggleAudioMediaOptions">
                        <em class="bi bi-chevron-up"></em>
                    </button>
                    <button type="button" class="webex__button green toogleMedia" :disabled="isTogglingAudio"
                        @click="toggleAudio" ref="toggleAudio">
                        <em class="bi bi-mic-fill"></em>
                    </button>
                    <button type="button" class="webex__button mediaSelect green" @click="toggleVideoMediaOptions">
                        <em class="bi bi-chevron-up"></em>
                    </button>
                    <button type="button" class="webex__button green toogleMedia" :disabled="isTogglingVideo"
                        @click="toggleVideo" ref="toggleVideo">
                        <em class="bi bi-camera-video-fill"></em>
                    </button>
                    <div class="videoquality">
                        <div class="_qualitybar qualitybar" data-status="hidden">
                            <div class="_qualityinner">
                                <span class="configTitle">Video quality</span>
                                <label class="videoQualityOption active" @click="changeVideoQuality('HIGH')" id="videoQualityHIGH">
                                    High
                                </label>
                                <label class="videoQualityOption" @click="changeVideoQuality('MEDIUM')" id="videoQualityMEDIUM">
                                    Medium
                                </label>
                                <label class="videoQualityOption" @click="changeVideoQuality('LOW')" id="videoQualityLOW">
                                    Low
                                </label>
                            </div>
                        </div>
                        <button title="videoquality" type="button" class="webex__button" ref="videoquality">
                            <em class="bi bi-gear"></em>
                        </button>
                    </div>
                    <button type="button" class="webex__button" @click="toggleHand" ref="toggleHand">
                        <em class="bi bi-hand-index-thumb"></em>
                    </button>
                    <!-- Additional controls for screen sharing -->
                    <button v-if="isModerator" type="button" class="webex__button" @click="toggleScreenShare"
                        ref="screenShare">
                        <em class="bi bi-display"></em>
                    </button>
                    <!-- <button title="fullscreen" type="button" class="webex__button" @click="toggleFullScreen" ref="fullScreen">
                        <em class="bi bi-arrows-fullscreen"></em>
                    </button> -->
                    <!-- <button v-if="isModerator" type="button" class="webex__button" @click="toggleRecording" ref="toggleRecording">
                        <em class="bi bi-record-fill"></em>
                    </button> -->
                    <button v-if="isModerator" v-show="isRecording" type="button" class="webex__button"
                        @click="togglePauseRecording" ref="togglePauseRecording">
                        <em class="bi bi-pause-btn"></em>
                    </button>
                    <div class="videoLayout">
                        <div class="_layoutbar layoutbar" data-status="hidden">
                            <div class="_layoutinner">
                                <em class="bi bi-fullscreen layoutOptions layoutActivePresence active"
                                    :class="{ 'is-moderator' : isModerator}"
                                    @click="toggleVideoLayout('ActivePresence')"></em>
                                <em class="bi bi-grid layoutOptions layoutEqual"
                                    :class="{ 'is-moderator' : isModerator}" @click="toggleVideoLayout('Equal')"></em>
                                <em class="bi bi-layout-sidebar-reverse layoutOptions layoutProminent"
                                    :class="{ 'is-moderator' : isModerator}"
                                    @click="toggleVideoLayout('Prominent')"></em>
                                <em class="bi bi-arrows-fullscreen fullscreen" :class="{ 'is-moderator' : isModerator}"
                                    @click="toggleFullScreen" ref="fullScreen"></em>
                            </div>
                        </div>
                        <button title="videoLayout" type="button" class="webex__button" ref="videoLayout">
                            <em class="bi bi-grid"></em>
                        </button>
                    </div>
                    <button title="hangup" type="button" class="webex__button leave-button" @click="hangup">
                        <em class="bi bi-x-lg"></em>
                    </button>
                </form>
            </div>
            <WebexParticipants
              v-if="store.showParticipants"
              :members="members"
              :meeting="meeting"
              :is-moderator="isModerator"
            />
            <audio ref="remoteViewAudio" autoplay playsinline id="selfAudio"></audio>
        </div>
    </div>
</template>

<script>
/* import Webex from "webex"; */
import { useWebexStore } from '../../store/webex.store'
import WebexParticipants from './WebexParticipants.vue'

export default {
    components: { WebexParticipants },
    setup() {
      const store = useWebexStore()

      return { store }
    },
    data() {
        return {
            isRegistering: false,
            isTogglingAudio: false,
            isTogglingVideo: false,
            isSharingScreen: false,
            isRecording: false,
            isPausedRecording: false,
            isLeaving: false,
            mediaSettings: {
                receiveVideo: true,
                receiveAudio: true,
                receiveShare: true,
                sendVideo: true,
                sendAudio: true,
                sendShare: false,
            },
            webex: null,
            meeting: null,
            members: null,
            videoLayout: 'ActivePresence',
            localMedia: {
                microphoneStream: undefined,
                cameraStream: undefined,
            },
            toggleAudioSelect: false,
            toggleVideoSelect: false,
            audioElements: [],
            videoElements: [],
        };
    },
    props: {
        jwt: {
            type: String,
            required: true,
        },
        accessToken: {
            type: String,
            required: false,
        },
        webexData: {
            type: Object,
            required: true
        },
        isModerator: {
            type: Boolean,
            required: true
        },
        eventUrl: {
            type: String,
            required: true
        }
    },
    methods: {
        // Register user in meeting
        register() {
            if (!this.isRegistering) {
                this.isRegistering = true;
                this.webex.meetings.register().then(() => {
                    return this.create();
                }).catch(console.debug)
                    .finally(() => { this.isRegistering = false; });
            }
        },
        // Create the meeting
        create() {
            const destination = this.webexData.webex_code;
            this.webex.meetings.create(destination).then((meeting) => {
                this.meeting = meeting;
                this.bindMeetingEvents(this.meeting);
                return this.joinMeeting(this.meeting);
            }).catch(console.debug);
        },
        // Joins the meeting and displays the controls if the status is 'JOINED'
        joinMeeting(meeting) {
            this.isLeaving = false
            let joinOptions = {
                meetingQuality: {
                    local: '1080p',
                    remote: 'HIGH'
                }
            }
            if(this.isModerator && this.webexData.moderatorPin){
                joinOptions.moderator = this.isModerator
                joinOptions.pin = this.webexData.moderatorPin
            }
            meeting.join(joinOptions).then(() => {
                this.hideElement(this.$refs.join);
                if (meeting.state === 'JOINED') {
                    this.showElement(this.$refs.controls);
                    //Get current member
                    let member = this.getCurrentMember();
                    if (this.isModerator && member) {
                        meeting.getMembers().assignRoles(member.id, [{type: 'COHOST', hasRole: true, hostKey: this.webexData.moderatorPin}]);
                    }
                    return this.addMedia(meeting);
                }
            }).catch(console.debug).finally(() => {
                this.refreshParticipantsList();
            });
        },
        // Add media to the meeting
        addMedia(meeting) {
            meeting.getMediaStreams(this.mediaSettings).then(([localStream, localShare]) => {
                return meeting.addMedia({ localShare, localStream, mediaSettings: this.mediaSettings });
            }).catch(console.debug);
        },
        toggleAudioMediaOptions(){
            this.toggleAudioSelect = !this.toggleAudioSelect
            this.toggleVideoSelect = false
        },
        toggleVideoMediaOptions(){
            this.toggleVideoSelect = !this.toggleVideoSelect
            this.toggleAudioSelect = false
        },
        getDevices() {
            let audioSelect = document.getElementById('audioSelect');
            while (audioSelect.firstChild) {
              audioSelect.remove(audioSelect.firstChild);
            }
            let videoSelect = document.getElementById('videoSelect');
            while (videoSelect.firstChild) {
              videoSelect.remove(videoSelect.firstChild);
            }
            navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
                for (let i = 0; i !== devices.length; ++i) {
                    let deviceInfo = devices[i];
                    if (deviceInfo.kind === 'audioinput') {
                        this.audioElements.push({
                            id: deviceInfo.deviceId,
                            text: deviceInfo.label || `microphone ${this.audioElements.length + 1}`
                        })
                    } else if (deviceInfo.kind === 'videoinput') {
                        this.videoElements.push({
                            id: deviceInfo.deviceId,
                            text: deviceInfo.label || `camera ${this.videoElements.length + 1}`
                        })
                    }
                }
            })
        },
        getAudioVideoConstraints(audio = null, video = null){
            let constraints = {
                audio: {deviceId: audio ? {exact: audio} : undefined},
                video: {deviceId: video ? {exact: video} : undefined}
            };
            return constraints
        },
        getVideoConstraints(deviceId){
            let constraints = {
                video: {deviceId:  {exact: deviceId}}
            };
            return constraints
        },
        getAudioConstraints(deviceId){
            let constraints = {
                audio: {deviceId: {exact: deviceId}}
            };
            return constraints
        },
        updateVideoDevice(deviceId){
            let constraints = this.getAudioVideoConstraints(null,deviceId)
            navigator.mediaDevices.getUserMedia(constraints)
                .then(this.setVideoInputDevice(deviceId))
                .catch(function (error){
                    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
                })
        },
        setVideoInputDevice(deviceId) {
            return this.getUserVideoMedia(deviceId).then(() => {
                this.meeting.updateVideo({
                    sendVideo: true,
                    receiveVideo: true,
                    stream: this.localMedia.cameraStream,
                }).catch(function(error){
                    console.log(error)
                });
            });
        },
        async getUserVideoMedia(deviceId) {
            await this.loadCamera(this.getVideoConstraints(deviceId));
        },
        loadCamera(constraints) {
            return new Promise((resolve, reject) => {
                try {
                    navigator.webkitGetUserMedia(
                        constraints,
                        (stream) => {
                            var mediaElement = document.querySelector('#selfCam');
                            mediaElement.srcObject = stream;
                            this.localMedia.cameraStream = stream;
                            mediaElement.load();
                            resolve()
                        },
                        (e) => {
                           console.log(e)
                           reject(e)
                        }
                    );
                }
                catch (e) {
                    console.log('MeetingControls#loadCamera() :: Error getting camera stream!');
                    reject(e);
                }
            })
        },
        updateAudioDevice(deviceId){
            let constraints = this.getAudioVideoConstraints(deviceId,null)
            navigator.mediaDevices.getUserMedia(constraints)
                .then(this.setAudioInputDevice(deviceId))
                .catch(function (error){
                    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
                })
        },
        setAudioInputDevice(deviceId) {
            return this.getUserAudioMedia(deviceId).then(() => {
                this.meeting.updateAudio({
                    sendAudio: true,
                    receiveAudio: true,
                    stream: this.localMedia.microphoneStream,
                }).catch(function(error){
                    console.log(error)
                });
            });
        },
        async getUserAudioMedia(deviceId) {
            await this.loadMicrophone(this.getAudioConstraints(deviceId));
        },
        loadMicrophone(constraints) {
          return new Promise((resolve, reject) => {
                try {
                    navigator.webkitGetUserMedia(
                        constraints,
                        (stream) => {
                            this.localMedia.microphoneStream = stream;
                            resolve()
                        },
                        (e) => {
                           console.log(e)
                           reject(e)
                        }
                    );
                }
                catch (e) {
                    console.log('MeetingControls#loadCamera() :: Error getting camera stream!');
                    reject(e);
                }
            })
        },
        toggleAudio() {
            if (!this.isMeetingJoined() || this.isTogglingAudio) {
                console.debug('MeetingControls#toggleSendAudio() :: no valid meeting object!');
                return;
            }

            this.isTogglingAudio = true;

            const action = this.meeting.isAudioMuted() ? 'unmuteAudio' : 'muteAudio';
            this.meeting[action]().then(() => {
                this.setButtonState(this.$refs.toggleAudio, !this.meeting.isAudioMuted(), 'bi bi-mic-fill', 'bi bi-mic-mute-fill');
            }).catch(console.debug).finally(() => {
                this.isTogglingAudio = false;
            }).finally(() => {
              this.refreshParticipantsList();
            });
        },
        toggleVideo() {
            if (!this.isMeetingJoined() || this.isTogglingVideo) {
                console.debug('MeetingControls#toggleSendVideo() :: no valid meeting object!');
                return;
            }

            this.isTogglingVideo = true;

            const action = this.meeting.isVideoMuted() ? 'unmuteVideo' : 'muteVideo';
            this.meeting[action]().then(() => {
                this.setButtonState(this.$refs.toggleVideo, !this.meeting.isVideoMuted(), 'bi bi-camera-video-fill', 'bi bi-camera-video-off-fill');
            }).catch(console.debug).finally(() => {
                this.isTogglingVideo = false;
            }).finally(() => {
              this.refreshParticipantsList();
            });
        },
        toggleHand() {
            if (!this.isMeetingJoined()) {
                console.debug('MeetingControls#toggleHand() :: no valid meeting object!');
                return;
            }
            //Get current member
            let member = this.getCurrentMember()
            //Get current hand status
            let handRaisedStatus = !member.isHandRaised;
            //Change hand status
            this.meeting.getMembers().raiseOrLowerHand(member.id,!member.isHandRaised).finally(() => {
                this.refreshParticipantsList();
            });
            //Change button state
            this.setButtonState(this.$refs.toggleHand, !handRaisedStatus, 'bi bi-hand-index-thumb', 'bi bi-hand-index-thumb-fill');

        },
        toggleScreenShare() {
            if (!this.isMeetingJoined()) {
                console.debug('MeetingControls#startScreenShare() :: no valid meeting object or action is in progress!');
                return;
            }

            if (!this.isSharingScreen) {
                this.isSharingScreen = true;
                this.meeting.shareScreen().catch(console.debug).finally(() => {
                    this.refreshParticipantsList();
                });
            } else {
                this.isSharingScreen = false;
                this.meeting.stopShare().catch(console.debug).finally(() => {
                    this.refreshParticipantsList();
                });
            }

            //Change button state
            this.setButtonState(this.$refs.screenShare, !this.isSharingScreen, 'bi bi-display', 'bi bi-display-fill');
        },
        toggleFullScreen() {
            if (!this.isFullscreen()) {
                $('.fullscreen').addClass('active');
                this.$refs.webexScreen.requestFullscreen().catch(console.debug);
            } else {
                $('.fullscreen').removeClass('active');
                document.exitFullscreen();
            }
            //Change button state
            // this.setButtonState(this.$refs.fullScreen, this.isFullscreen(), 'bi bi-arrows-fullscreen', 'bi bi-fullscreen-exit');
        },
        toggleRecording() {
            if (!this.isMeetingJoined()) {
                console.debug('MeetingControls#toggleRecording() :: no valid meeting object or action is in progress!');
                return;
            }

            if (!this.isRecording) {
                this.isRecording = true;
                this.meeting.startRecording().catch(console.debug).finally(() => {
                });
            } else {
                this.isRecording = false;
                this.meeting.stopRecording().catch(console.debug).finally(() => {
                });
            }

            //Change button state
            this.setButtonState(this.$refs.toggleRecording, !this.isRecording, 'bi bi-record-fill', 'bi bi-stop-fill');
        },
        togglePauseRecording() {
            if (!this.isMeetingJoined() || !this.isRecording) {
                console.debug('MeetingControls#toggleRecording() :: no valid meeting object or action is in progress!');
                return;
            }

            if (!this.isPausedRecording) {
                this.isPausedRecording = true;
                this.meeting.pauseRecording().catch(console.debug).finally(() => {
                });
            } else {
                this.isPausedRecording = false;
                this.meeting.resumeRecording().catch(console.debug).finally(() => {
                });
            }

            //Change button state
            this.setButtonState(this.$refs.togglePauseRecording, !this.isPausedRecording, 'bi bi-pause-btn', 'bi bi-play-btn');
        },
        toggleVideoLayout(type, manual = false) {
            if (!this.isMeetingJoined()) {
                console.debug('MeetingControls#toggleRecording() :: no valid meeting object or action is in progress!');
                return;
            }

            this.meeting.changeVideoLayout(type).catch(console.debug).finally(() => {
                $('.layoutOptions').removeClass('active');
                $('.layout'+type).addClass('active');
            });

            if (!manual) {
                this.videoLayout = type;
            }
        },
        // Close the meet
        hangup() {
            this.isLeaving = true
            if (this.isFullscreen()) {
                document.exitFullscreen();
            }

            this.meeting.leave();
            this.webex.meetings.unregister();

            this.showElement(this.$refs.join);
            this.hideElement(this.$refs.controls);
            this.setButtonState(this.$refs.toggleAudio, true, 'microphone', 'microphone');
            this.setButtonState(this.$refs.toggleVideo, true, 'video', 'video');
            this.refreshParticipantsList();
        },
        // Associate meeting events
        bindMeetingEvents(meeting) {
            const mediaTypes = {
                'local': 'selfView',
                'remoteVideo': 'remoteViewVideo',
                'remoteAudio': 'remoteViewAudio',
                'remoteShare': 'remoteScreenshare',
                'localShare': 'localScreenshare',
            };
            // We add listens to the meeting states
            meeting
                .on('error', (err) => {
                    console.debug("ERROR:", err);
                })
                .on('media:ready', (media) => {
                    if (!media || !(media.type in mediaTypes)) return;
                    this.$refs[mediaTypes[media.type]].srcObject = media.stream;
                    this.refreshParticipantsList();
                })
                .on('media:stopped', (media) => {
                    if (!media || !(media.type in mediaTypes)) return;
                    this.$refs[mediaTypes[media.type]].srcObject = null;
                    this.refreshParticipantsList();
                })
                .on('meeting:self:lobbyWaiting', () => {
                    this.showElement(this.$refs.waiting);
                    this.refreshParticipantsList();
                })
                .on('meeting:self:guestAdmitted', () => {
                    this.hideElement(this.$refs.waiting);
                    this.refreshParticipantsList();
                })
                .on('meeting:stateChange', (obj) => {
                    let currentState = obj.payload.currentState;
                    if (currentState === 'INACTIVE') {
                        this.webex.meetings.unregister();
                        this.showElement(this.$refs.join);
                        this.hideElement(this.$refs.waiting);
                    }
                    this.refreshParticipantsList();
                })
                .on('meeting:startedSharingRemote', () => {
                    this.showElement(this.$refs.remoteScreenshare);
                    if (this.videoLayout !== 'ActivePresence') {
                        this.toggleVideoLayout('ActivePresence');
                    }
                    $(this.$refs.remoteViewVideo).addClass('remoteScreenShare');
                    this.refreshParticipantsList();
                })
                .on('meeting:stoppedSharingRemote', () => {
                    this.hideElement(this.$refs.remoteScreenshare);
                    this.toggleVideoLayout(this.videoLayout);
                    $(this.$refs.remoteViewVideo).removeClass('remoteScreenShare');
                    this.refreshParticipantsList();
                })
                .on('meeting:startedSharingLocal', () => {
                    this.showElement(this.$refs.localScreenshare);
                    this.hideElement(this.$refs.selfView);
                    this.refreshParticipantsList();
                })
                .on('meeting:stoppedSharingLocal', () => {
                    this.hideElement(this.$refs.localScreenshare);
                    this.showElement(this.$refs.selfView);
                    this.refreshParticipantsList();
                })
                .on('meeting:self:unmutedByOthers', () => {
                  this.refreshParticipantsList();
                })
                .on('meeting:self:mutedByOthers', () => {
                  this.refreshParticipantsList();
                })
                .on('meeting:self:requestedToUnmute', () => {
                  this.refreshParticipantsList();
                })
                .on('media:stopped', () => {
                  this.leaveToEventPage()
                })
            meeting.getMembers()
                .on('members:update', () => this.refreshSelfState())
                .on('members:self:update', () => this.refreshSelfState())
        },
        // Check the fullScreen status
        isFullscreen() {
            return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
        },
        // Check if the meet is in Joined state
        isMeetingJoined() {
            return this.meeting && this.meeting.state === 'JOINED';
        },
        // Display an HTML element
        showElement(element) {
            element.classList.remove('hide');
        },
        // Hide an HTML element
        hideElement(element) {
            element.classList.add('hide');
        },
        // Update the state of a button
        setButtonState(button, enabled, enableIcon, disableIcon) {
            button.innerHTML = enabled
                ? `<em class="${enableIcon}"></em>`
                : `<em class="${disableIcon}"></em>`;
            button.classList[enabled ? 'remove' : 'add']("red");
        },
        refreshParticipantsList() {
          this.members = this.meeting?.members
        },
        // Get current member
        getCurrentMember(){
            let members = this.meeting.getMembers().membersCollection.members;
            return members[this.meeting.selfId] !== 'undefined' ? members[this.meeting.selfId] : null;
        },
        refreshSelfState() {
            const members = this.meeting?.members?.membersCollection?.members

            if (members != null) {
                const me = Object.entries(members)
                    .filter(([_, member]) => member.status === 'IN_MEETING' && member.isSelf === true)
                    .map(([_, member]) => member)
                    .shift()

                if (me != null && !this.isTogglingAudio) {
                    this.setButtonState(this.$refs.toggleAudio, !me.isAudioMuted, 'bi bi-mic-fill', 'bi bi-mic-mute-fill');
                }
            }
        },
        leaveToEventPage() {
          // wait 100ms for avoid race conditions while the hangup button is clicked and the media:stopped event is fired.
          // This will ensure get the correct value of isLeaving property
          setTimeout(() => {
            if (!this.isLeaving) {
              window.location.replace(this.eventUrl)
            }
          }, 100)
        },
        changeVideoQuality(newQuality){
            this.meeting.setRemoteQualityLevel(newQuality)
            $('.videoQualityOption').each(function(index){
                $(this).removeClass('active')
            });
            $('#videoQuality'+newQuality).addClass('active')
        }
    },
    mounted() {
        // Webex instance
        this.webex = window.webex = Webex.init();

        if (this.additionalOptions) {
            // We request access owner to Webex
            this.webex.once('ready', () => {
                this.webex.authorization.authorize({ access_token: this.accessToken })
                    .then(() => {
                        if (this.webex.canAuthorize) {
                            this.$refs.join.addEventListener('click', this.register.bind(this));
                            this.getDevices();
                            this.refreshParticipantsList();
                        }
                    }).catch(console.debug);
            });
        } else {
            // We request access guest to Webex
            this.webex.once('ready', () => {
                this.webex.authorization.requestAccessTokenFromJwt({ jwt: this.jwt })
                    .then(() => {
                        if (this.webex.canAuthorize) {
                            this.$refs.join.addEventListener('click', this.register.bind(this));
                            this.getDevices();
                            this.refreshParticipantsList();
                        }
                    }).catch(console.debug);
            });
        }

        this.refreshParticipantsList()
    },
    // When closing the component
    beforeDestroy() {
        if (this.webex && this.webex.canAuthorize) {
            // If the user leaves while the meeting is still in progress, it makes sure to hang up.
            if (this.meeting) {
                this.meeting.leave();
            }

            this.webex.meetings.unregister();
        }
    }
}
</script>
