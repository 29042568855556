import Utils from '../../Utils';
import LiveUtils from './Utils';
import LiveBackPollsController from './LiveBackPollsController';
import LiveSpeakerPollsController from './LiveSpeakerPollsController';
import moment from 'moment';
import DOMPurify from 'dompurify';
import axios from 'axios';
import $ from 'jquery';

const LiveBackController = {
  activateLiveSessionEl: {
    selector: '.activate-live-session-js'
  },
  liveBackSectionEl: {
    selector: '#page-congress-live-speaker-v5'
  },
  liveModeratorSectionEl: {
    selector: '#page-congress-live-moderator-v5'
  },
  backAskListLi: {
    selector: '.congress-live-back-ask-list li'
  },
  backAskListItem: {
    selector: '.congress-live-back-ask-list-item'
  },
  backAskListItemRecent: {
    selector: 'congress-live-back-ask-list-item-recently'
  },
  backAskListItemButtonsContainer: {
    selector: '.congress-live-back-ask-list-actions-buttons-container'
  },
  backAskListItemResponseContainer: {
    selector: '.congress-live-back-ask-list-actions-response-container'
  },
  backAskListItemLeftTextContainer: {
    selector: '.congress-live-back-left-text'
  },
  backAskListJs: {
    selector: '.congress-live-back-ask-list-js'
  },
  backAskListQuestionJs: {
    selector: '.congress-live-back-ask-list-content'
  },
  backAskListAcceptJs: {
    selector: '.accept-ask-js'
  },
  backAskListRejectJs: {
    selector: '.reject-ask-js'
  },
  backAskListUpdateJs: {
    selector: '.update-ask-js'
  },
  backAskListAnsweredJs: {
    selector: '.answered-ask-js'
  },
  askCountEl: {
    selector: '.congress-live-back-ask-count-js'
  },
  liveSpeakerEl: {
    selector: '.live-activate-speaker-js'
  },
  speakerItemEl: {
    selector: '.congress-live-speakers-speaker-item'
  },
  speakersContainerEl: {
    selector: '.speakers-container-js'
  },
  modalResponse: {
    selector: '#modal-response'
  },
  pollsContainer: {
    selector: '.congress-live-back-moderator-polls-container'
  },
  moderatorControlButtons: {
    selector: '.live-moderator-multimedia-video-controls-left-item'
  },
  moderatorComments: {
    selector: '#moderator-comments-textarea'
  },
  moderatorSendQComments: {
    selector: '#moderator-send-q-to-speaker-textarea'
  },
  moderatorSendQ: {
    selector: '#moderator-send-q-to-speaker-textarea'
  },
  moderatorCommentsJs: {
    selector: '.moderator-comments-textarea-btn-js'
  },
  moderatorSendQJs: {
    selector: '.moderator-send-q-to-speaker-textarea-btn-js'
  },
  finishTheSessionJs: {
    selector: '.finish-the-session-js'
  },
  setSpeakerLiveFlag: true,
  getSpeakerLiveFlag: true,
  liveNowCheckInterval: null,
  routes: {
    activateSession: 'activate-session',
    getSpeakers: 'update-speakers',
    getLiveQuestions: 'get-live-questions',
    validateLiveQuestions: 'validate-live-questions',
    liveSelectSpeaker: 'live-select-speaker'
  },
  zeroPad(num) {
    return num < 10 ? '0' + num : '' + num;
  },

  init() {
    if (!this.checkLiveBackSection() && !this.checkLiveModeratorSection()) return false;
    this.initRequests();
    if (this.checkLiveModeratorSection()) {
      LiveBackPollsController.init();
      this.setListeners();
      this.scrollToSection(this.liveModeratorSectionEl.selector);
    }
    if (this.checkLiveBackSection()) {
      LiveSpeakerPollsController.init();
      this.scrollToSection(this.liveBackSectionEl.selector);
    }
  },

  checkLiveBackSection() {
    const el = document.querySelectorAll(this.liveBackSectionEl.selector);
    return el.length;
  },

  checkLiveModeratorSection() {
    const el = document.querySelectorAll(this.liveModeratorSectionEl.selector);
    return el.length;
  },

  setListeners() {
    $(this.activateLiveSessionEl.selector).click(ev => this.activateLiveSessionHandler(ev));
    $(document).on('click', this.liveSpeakerEl.selector, ev => this.clickSetLiveSpeakerHandler(ev));
    $(document).on(
      'click',
      `${this.backAskListAcceptJs.selector},
                              ${this.backAskListRejectJs.selector},
                              ${this.backAskListUpdateJs.selector},
                              ${this.backAskListAnsweredJs.selector}`,
      ev => this.clickSetAskActionHandler(ev)
    );
    $(this.moderatorControlButtons.selector).click(ev =>
      this.switchModeratorPanelView(ev.currentTarget.dataset.selected)
    );
    $(this.moderatorComments.selector).keyup(this.delay(ev => this.changeButtonFormSendMessage(ev), 500));
    $(this.moderatorSendQ.selector).keyup(this.delay(ev => this.changeButtonFormSendModeratorMessage(ev), 500));
    $(this.moderatorCommentsJs.selector).click(ev => this.sendMessageToAudience());
    $('.moderator-send-q-to-speaker-textarea-btn-js').click(ev => this.sendMessageToModerator());
    $('.send-q-to-speaker').on('click', ev => {
      $('.send-q-fields-modal').modal('show');
    });
    $(this.finishTheSessionJs.selector).click(ev => this.finishTheSession());
    $('.congress-live-card').on('keyup', this.backAskListQuestionJs.selector, ev => {
      const itt = ev.target.closest('.congress-live-back-ask-list-item');
      const id = itt.getAttribute('data-id');
      document.querySelector(`.congress-live-back-ask.update-ask[data-id='${id}']`).style.display = 'flex';
    });

    this.setPadletListeners();
    this.configureTopAgendaRefresh();
    this.configureAgendaChangeModal();
  },

  initRequests() {
    this.getSpeakerQuestions({
      success: this.getSpeakerQuestionsSuccess,
      error: this.getSpeakerQuestionsError
    });

    if (this.setSpeakerLiveFlag && this.getSpeakerLiveFlag) {
      this.getSpeakers({
        success: this.getSpeakersSuccess,
        error: this.getSpeakersError
      });
    }

    // this.initGetQuestionsInterval();
    // this.initGetSpeakersInterval();
    this.initSlickQuestions();
  },

  initGetQuestionsInterval() {
    setInterval(() => {
      this.getSpeakerQuestions({
        success: this.getSpeakerQuestionsSuccess,
        error: this.getSpeakerQuestionsError
      });
    }, 15000);
  },

  initGetSpeakersInterval() {
    setInterval(() => {
      if (this.setSpeakerLiveFlag && this.getSpeakerLiveFlag) {
        this.getSpeakerLiveFlag = false;
        this.getSpeakers({
          success: this.getSpeakersSuccess,
          error: this.getSpeakersError
        });
      }
    }, 10000);
  },

  clickSetLiveSpeakerHandler(ev) {
    const speakerId = ev.currentTarget.dataset.speakerid;
    const currentTarget = ev.currentTarget;
    this.sendSelectedSpeaker(speakerId, {
      success: response => this.sendSelectedSpeakerSuccess(response, currentTarget),
      error: () => this.sendSelectedSpeakerError()
    });
  },

  sendSelectedSpeaker(speakerId, callbacksFn) {
    const url = Utils.getUrl(true, this.routes.liveSelectSpeaker);
    const agendaSessionId = this.getAgendaSessionId();
    const data = { agendaSessionId: agendaSessionId, speaker: speakerId };
    this.setSpeakerLiveFlag = false;
    const $req = axios.post(url, data);
    $req.then(response => {
      let sFn = callbacksFn.success;

      if (typeof sFn == 'function') sFn.bind(this)(response);
    });

    $req.catch(response => {
      let eFn = callbacksFn.error;

      if (typeof eFn == 'function') eFn.bind(this)(response);
    });
    $req.finally(() => {
      this.setSpeakerLiveFlag = true;
    });
  },

  sendSelectedSpeakerSuccess(response, currentTarget) {
    if (response && response.status === 200) {
      const $active = $($(currentTarget).find('span.active'));
      const $notActive = $($(currentTarget).find('span:not(.active)'));
      const action = $active.data('action');
      if (action === 'activate') {
        $(currentTarget)
          .closest(this.speakerItemEl.selector)
          .addClass('live');
        $(currentTarget).addClass('active');
      } else if (action === 'disable') {
        $(currentTarget)
          .closest(this.speakerItemEl.selector)
          .removeClass('live');
        $(currentTarget).removeClass('active');
      }
      this.toggleButtonAction($active, $notActive);
    }
  },

  sendSelectedSpeakerError(error) {
    console.debug("ERR", error);
  },

  toggleButtonAction($active, $notActive) {
    $active.addClass('d-none');
    $active.removeClass('active');
    $notActive.addClass('active');
    $notActive.removeClass('d-none');
  },

  getSpeakers(callbacksFn) {
    const url = Utils.getUrl(true, this.routes.getSpeakers);
    const agendaSessionId = this.getAgendaSessionId();
    const data = { agendaSessionId: agendaSessionId };

    const $req = axios.post(url, data);
    $req.then(response => {
      let sFn = callbacksFn.success;

      if (typeof sFn == 'function') sFn.bind(this)(response);
    });

    $req.catch(response => {
      let eFn = callbacksFn.error;

      if (typeof eFn == 'function') eFn.bind(this)(response);
    });

    $req.finally(() => {
      this.getSpeakerLiveFlag = true;
    });
  },

  getSpeakersSuccess(response) {
    if (response.data && response.data.html && this.setSpeakerLiveFlag)
      var speakersContainer = document.querySelector(this.speakersContainerEl.selector);
      const sanitizedHtml = DOMPurify.sanitize(response.data.html);
      if(sanitizedHtml && speakersContainer){
        speakersContainer.innerHTML = Utils.escapeHtml(sanitizedHtml);
      }
  },

  getSpeakersError(error) {
    console.debug("ERR", error);
  },

  getSpeakerQuestions(callbacksFn) {
    const url = Utils.getUrl(true, this.routes.getLiveQuestions);
    const agendaSessionId = this.getAgendaSessionId();
    const shownIds = this.getShownIds();
    const data = {
      agendaSessionId: agendaSessionId,
      shownIds: shownIds
    };
    this.removeListItemRecentlyClass();
    const $req = axios.post(url, data);
    $req.then(response => {
      let sFn = callbacksFn.success;

      if (typeof sFn == 'function') sFn.bind(this)(response);
    });

    $req.catch(response => {
      let eFn = callbacksFn.error;

      if (typeof eFn == 'function') eFn.bind(this)(response);
    });
  },

  getSpeakerQuestionsSuccess(response) {
    if (response.data && response.data.html) {
      const backAskList = document.querySelector(this.backAskListJs.selector);
      const sanitizedHtml = DOMPurify.sanitize(response.data.html);
      if (backAskList) {
        backAskList.insertAdjacentHTML('afterbegin', Utils.escapeHtml(sanitizedHtml));
        this.setQuestionsNumberInView();
      }
    }
    if (response.data && response.data.discarded && !this.checkLiveModeratorSection()) {
      this.cleanQuestionsDiscarded(response.data.discarded);
      this.markQuestionsAsAnswered(response.data.answeredIds);
      this.setQuestionsNumberInView();
    }
  },

  getSpeakerQuestionsError(response) {
    console.debug("ERR", response);
  },

  getAgendaSessionId() {
    let e = window.app.agenda.agendaSessionId;
    if ('undefined' == typeof e) throw new Error('This config does not exists. EMC01');
    return e;
  },

  getShownIds() {
    var ids = [];
    $(this.backAskListLi.selector).each(function() {
      ids.push($(this).data('id'));
    });
    return ids;
  },

  removeListItemRecentlyClass() {
    $(this.backAskListItem.selector).removeClass(this.backAskListItemRecent.selector);
  },

  cleanQuestionsDiscarded(discardedIds) {
    if (discardedIds.length) {
      $(this.backAskListLi.selector).each(function() {
        const item = $(this);
        const itemId = item.data('id');
        if (!$.inArray(itemId, discardedIds)) {
          item.fadeOut().remove();
        }
      });
    }
  },

  markQuestionsAsAnswered(answeredIds) {
    if (answeredIds.length) {
      $(this.backAskListLi.selector).each(function() {
        const item = $(this);
        const itemId = item.data('id');
        if (!$.inArray(itemId, answeredIds)) {
          item.addClass('grayscale');
          item.addClass('opacity-03');
        }
      });
    }
  },

  setQuestionsNumberInView() {
    const questionsNumber = this.getQuestionsNumber();
    $(this.askCountEl.selector).text(questionsNumber);
  },

  getQuestionsNumber() {
    const $el = document.querySelectorAll(this.backAskListLi.selector);
    return $el.length;
  },

  clickSetAskActionHandler(ev) {
    const action = ev.currentTarget.dataset.action;
    const callbacksFn = {
      success: this.askActionSuccess,
      error: this.askActionError
    };
    this.setAskAction(ev, callbacksFn, action);
  },

  setAskAction(ev, callbacksFn, action) {
    const url = Utils.getUrl(true, this.routes.validateLiveQuestions);
    const current = $(ev.currentTarget).closest('li');
    const liveQuestion = current.data('id');
    const agendaSessionId = this.getAgendaSessionId();
    const questionContainer = current
      .find(this.backAskListQuestionJs.selector)
      .text()
      .trim();
    const data = {
      agendaSessionId: agendaSessionId,
      live_question: liveQuestion,
      question: questionContainer,
      status: action
    };

    const $req = axios.post(url, data);
    $req.then(response => {
      let sFn = callbacksFn.success;

      if (typeof sFn == 'function') {
        sFn.bind(this)(response, action, current);
      }
      document.querySelector(`.congress-live-back-ask.update-ask[data-id='${liveQuestion}']`).style.display = 'none';
      document.querySelector(`.congress-live-back-ask.update-ask[data-id='${liveQuestion}']`).style.display = 'none';
    });

    $req.catch(response => {
      let eFn = callbacksFn.error;

      if (typeof eFn == 'function') eFn.bind(this)(response);
    });
  },

  askActionSuccess(response, action, current) {
    //current.find(this.backAskListItemButtonsContainer.selector).hide();
    let responseContainer = current.find(this.backAskListItemResponseContainer.selector);
    const buttonsContainer = current.find(this.backAskListItemButtonsContainer.selector);
    const liveQuestion = current.data('id');
    responseContainer.fadeIn();
    const liveText = document
      .querySelector(`.congress-live-back-ask-list-item[data-id='${liveQuestion}']`)
      .querySelector('.congress-live-back-left-text');
    if (action === 'validated') {
      responseContainer.find('.discarded').hide();
      responseContainer.find('.validated').fadeIn();
      liveText.classList.remove('discarded');
      liveText.classList.add('validated');
      current.find(this.backAskListAcceptJs.selector).hide();
      current.find(this.backAskListRejectJs.selector).fadeIn();
      current.find(this.backAskListAnsweredJs.selector).removeClass('sr-only');
    } else if (action === 'discarded') {
      responseContainer.find('.validated').hide();
      responseContainer.find('.discarded').fadeIn();
      liveText.classList.add('discarded');
      liveText.classList.remove('validated');
      current.find(this.backAskListRejectJs.selector).hide();
      current.find(this.backAskListAcceptJs.selector).fadeIn();
      current.find(this.backAskListAnsweredJs.selector).addClass('sr-only');
      /*
          setTimeout(function () {
              current.fadeOut();
          }, 1000);
          */
    } else if (action === 'update') {
      responseContainer.find('.update').fadeIn();
      setTimeout(function() {
        responseContainer.find('.update').fadeOut();
      }, 1500);
    } else if (action === 'answered') {
      this.performAnsweredActions(responseContainer, buttonsContainer, current);
    }

    setTimeout(function() {
      //responseContainer.fadeOut();
    }, 1500);
  },

  performAnsweredActions(responseContainer, buttonsContainer, current) {
    const answered = responseContainer.find('.answered');
    const button = buttonsContainer.find(this.backAskListAnsweredJs.selector);

    //If already answered, remove classes
    if (button.length && button[0].classList.contains('grayscale')) {
      button.removeClass('grayscale');
      button.attr('style', '');
      //Opacity of text
      let leftText = current.find(this.backAskListItemLeftTextContainer.selector);
      if (leftText.length) {
        leftText.removeClass('grayscale');
        leftText.removeClass('opacity-03');
      }
    } else {
      button.addClass('grayscale');
      button.attr('style', 'opacity: 0.5');
      answered.fadeIn();
      setTimeout(function() {
        answered.fadeOut();
      }, 1500);
      //Opacity of text
      let leftText = current.find(this.backAskListItemLeftTextContainer.selector);
      if (leftText.length) {
        leftText.addClass('grayscale');
        leftText.addClass('opacity-03');
      }
    }
  },

  askActionError(response) {
    console.debug('ERR', response);
  },

  activateLiveSessionHandler(ev) {
    const currentTarget = ev.currentTarget;
    this.activateLiveSession({
      success: response => this.activateLiveSessionSuccess(response, currentTarget),
      error: this.activateLiveSessionSuccess
    });
  },

  activateLiveSession(callBacksFn) {
    const url = Utils.getUrl(true, this.routes.activateSession);
    const agendaSessionId = Utils.getAgendaSessionId();
    const data = {
      agendaSessionId: agendaSessionId
    };

    this.makeRequest(url, data, callBacksFn);
  },

  activateLiveSessionSuccess(response, currentTarget) {
    const { data, status } = response;
    if (data && data.html && status === 200) {
      const $button = $(currentTarget);
      const span1 = $button.find('[data-action=activate]');
      const span2 = $button.find('[data-action=disable]');

      this.toggleElements(span1, span2);
      LiveUtils.openModal(this.modalResponse.selector, data.html);
    }
  },

  setPadletListeners() {
    $('body').on('click', '.live-moderator-multimedia-video-controls-left-item-mural', ev => {
      if (!$('.floating-menu-item-mural.active').length) {
        $('.floating-menu-item-mural').removeClass('d-none');
        $('.floating-menu-item-mural').addClass('active');
      } else {
        $('.floating-menu-item-mural').addClass('d-none');
        $('.floating-menu-item-mural').removeClass('active');
      }
    });
    //Close menu item modal
    $('body').on('click', '.floating-menu-item-close', ev => {
      let itemModal = $(ev.currentTarget).closest('.floating-menu-item');
      if (itemModal.length) {
        itemModal.addClass('d-none');
        itemModal.removeClass('active');
      }
    });
  },

  toggleElements(el1, el2) {
    el1.toggleClass('d-none');
    el2.toggleClass('d-none');
  },

  activateLiveSessionError(err) {
    console.debug("ERR", err);
  },

  makeRequest(url, data, callbacksFn) {
    const $req = axios.post(url, data);
    $req.then(response => {
      let sFn = callbacksFn.success;

      if (typeof sFn == 'function') sFn.bind(this)(response);
    });

    $req.catch(response => {
      let eFn = callbacksFn.error;

      if (typeof eFn == 'function') eFn.bind(this)(response);
    });
  },

  initSlickQuestions() {
    let slickOptions = {
      infinite: true,
      slidesToShow: 1,
      arrows: true,
      prevArrow: $('.polls-btn-left'),
      nextArrow: $('.polls-btn-right'),
      draggable: true,
      respondTo: 'window',
      centerMode: false
    };

    $('body').on('init reInit afterChange', this.pollsContainer.selector, (event, slick, currentSlide, nextSlide) => {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      i = slick.slideCount == 0 ? 0 : i;
      $('.pollsPagination').text(i + '/' + slick.slideCount);

      //Check button force speaker view
      this.checkForceSpeakerViewButton(slick, currentSlide);
    });

    $(this.pollsContainer.selector).slick(slickOptions);
  },

  checkForceSpeakerViewButton(slick, currentSlide) {
    //Only if moderator and has slides
    if (this.checkLiveModeratorSection() && slick && slick.$slides.length) {
      currentSlide = currentSlide ? currentSlide : 0;
      let currentSlickEl = $(slick.$slides[currentSlide]).find('.congress-live-back-moderator-polls-item');
      let pollId = currentSlickEl.attr('data-pollid');
      let status = currentSlickEl.attr('data-status');

      //If poll status = 0, disable button
      if (status == 0) {
        $('#moderator-polls-force-btn').prop('disabled', true);
      } else {
        $('#moderator-polls-force-btn').removeAttr('disabled');
      }
      //Set poll id dataset to button
      $('#moderator-polls-force-btn').attr('data-pollid', pollId);
    }
  },

  switchModeratorPanelView(selected) {
    //Get current active button
    let currentActive = $(this.moderatorControlButtons.selector + '.active');
    //If selected is already active, no action
    if (currentActive.length && currentActive[0].dataset.selected == selected) {
      return;
    }

    //Unselect all buttons
    $(this.moderatorControlButtons.selector).removeClass('active');
    //Select selected button
    let buttonClass = this.moderatorControlButtons.selector + '.' + selected;
    if ($(buttonClass).length) {
      $(buttonClass).addClass('active');
    }

    //Hide all sections
    $('.live-moderator-section').hide();
    //Show selected section class
    let sectionClass = '.live-moderator-section-' + selected;
    if ($(sectionClass).length) {
      $(sectionClass).show();
    }

    if (selected == 'polls') {
      this.initSlickQuestions();
    } else {
      $(this.pollsContainer.selector).slick('unslick');
    }
  },
  delay(callback, ms) {
    var timer = 0;
    return function() {
      var context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function() {
        callback.apply(context, args);
      }, ms || 0);
    };
  },
  changeButtonFormSendMessage(ev) {
    const textarea = $(this.moderatorComments.selector);
    const button = $(this.moderatorCommentsJs.selector);
    if (textarea.val() === '') {
      button.prop('disabled', true);
    } else {
      button.prop('disabled', false);
    }
  },
  changeButtonFormSendModeratorMessage(ev) {
    const textarea = $(this.moderatorSendQ.selector);
    const button = $(this.moderatorSendQJs.selector);
    if (textarea.val() === '') {
      button.prop('disabled', true);
    } else {
      button.prop('disabled', false);
    }
  },
  scrollToSection(aid) {
    $('html, body').animate(
      {
        scrollTop: $(aid).offset().top
      },
      200
    );
  },

  //Websocket methods section
  /**
   * Send message to the audience
   */
  sendMessageToModerator() {
    let url = Utils.getUrl(false, 'send-message-to-moderator');
    let data = new FormData();
    data.append('agendaSessionId', this.getAgendaSessionId());
    data.append('message', $(this.moderatorSendQComments.selector).val());
    axios
      .post(url, data)
      .then(res => {
        if (res.status === 200) {
          LiveUtils.openModal(this.modalResponse.selector, res.data.html);
          $(this.moderatorSendQComments.selector).val('');
          $('.send-q-fields-modal').modal('hide');
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },

  /**
   * Send message to the audience
   */
  sendMessageToAudience() {
    let url = Utils.getUrl(false, 'send-message-to-audience');
    let data = new FormData();
    data.append('agendaSessionId', this.getAgendaSessionId());
    data.append('message', $(this.moderatorComments.selector).val());
    axios
      .post(url, data)
      .then(res => {
        if (res.status === 200) {
          LiveUtils.openModal(this.modalResponse.selector, res.data.html);
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },

  configureTopAgendaRefresh() {
    $('.agenda-moderator-active-slick-container').slick({
      infinite: false,
      autoplay: false,
      slidesToShow: 6,
      slidesToScroll: 2,
      autoplaySpeed: 5000,
      speed: 1000,
      focusOnSelect: false,
      arrows: true,
      variableWidth: false,
      prevArrow: $('.agmod-prev-btn'),
      nextArrow: $('.agmod-next-btn'),
      responsive: [
        {
          breakpoint: 2560,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
    const agendas = JSON.parse(document.querySelector('.agenda-metadata').innerHTML);
    if (agendas === null) {
      console.debug('Agenda metadata is empty');
      return;
    }
    agendas.forEach(agenda => {
      const previous_time = (agenda.previous_time_live * 60) || 1800;
      agenda.unix_start = (moment(agenda.date_start).unix() - previous_time) * 1000;
      agenda.unix_real_start = moment(agenda.date_start).unix() * 1000;
      agenda.unix_end = moment(agenda.date_end).unix() * 1000;
    });
    const liveCheckFn = () => {
      const time = Date.now();
      const lives = agendas.filter(agenda => time - agenda.unix_real_start >= 0 && !agenda.is_finished);
      const preLives = agendas.filter(agenda => time - agenda.unix_real_start >= 0 && time - agenda.unix_start < 0);
      const notLivesPast = agendas.filter(agenda => time - agenda.unix_start >= 0 && agenda.is_finished);
      const notLivesFuture = agendas.filter(agenda => time - agenda.unix_start < 0);

      const baseLivesLogic = live => {
        const moderatorItem = document.querySelector(`.agenda-moderator-active-item[data-id='${live.id}']`);
        if (!!moderatorItem) {
          moderatorItem.classList.add('item-is-live');
          moderatorItem.classList.remove('item-is-finished');
          moderatorItem.querySelector('.data-col').classList.add('is-live');
          moderatorItem.querySelector('.is-live-col').classList.remove('sr-only');
          const item = document.querySelector(`.agenda-moderator-timeout-info[data-id='${live.id}']`);
          item.innerHTML = '';
          item.style = 'display: none';
          if (live.is_finished === 1) {
            moderatorItem.classList.add('item-is-finished');
          }
        }
        return moderatorItem;
      };
      const baseNoLivesLogic = notLive => {
        const moderatorItem = document.querySelector(`.agenda-moderator-active-item[data-id='${notLive.id}']`);
        if (!!moderatorItem) {
          moderatorItem.classList.remove('item-is-live');
          moderatorItem.querySelector('.data-col').classList.remove('is-live');
          moderatorItem.querySelector('.is-live-col').classList.add('sr-only');
          if(notLive.is_finished === 1) {
            moderatorItem.classList.add('item-is-finished');
          }
        }
        return moderatorItem;
      };
      if (lives.length > 0) {
        lives.forEach(live => {
          baseLivesLogic(live);
        });
      }
      notLivesPast.forEach(notLive => {
        const moderatorItem = baseNoLivesLogic(notLive);
        if (!!moderatorItem) moderatorItem.classList.add('greyed-out');
        const item = document.querySelector(`.agenda-moderator-timeout-info[data-id='${notLive.id}']`);
        if(!!item) {
          item.style = "display: inline-block";
          item.innerHTML = 'Session Finished';
        }
      });
      notLivesFuture.forEach(notLive => {
        baseNoLivesLogic(notLive);
        const time = (notLive.unix_real_start - Date.now()) / 1000;
        const hours = Math.floor(time/3600);
        const timeNoHours = time - (3600 * hours);
        let timeClock = "Session Starts In ";
        if (hours > 0) timeClock += `${hours}h `;
        timeClock += `${this.zeroPad(Math.floor(timeNoHours / 60))}m ${this.zeroPad(Math.floor(timeNoHours%60))}s`;
        const item = document.querySelector(`.agenda-moderator-timeout-info[data-id='${notLive.id}']`);
        if (!!item) {
          item.style = 'display: inline-block';
          item.innerHTML = timeClock;
        }
      });
      preLives.forEach(preLive => {
        baseLivesLogic(preLive);
        const time = (preLive.unix_real_start - Date.now()) / 1000;
        const hours = Math.floor(time/3600);
        const timeNoHours = time - (3600 * hours);
        let timeClock = "Session Starts In ";
        if (hours > 0) timeClock += `${hours}h `;
        timeClock += `${this.zeroPad(Math.floor(timeNoHours / 60))}m ${this.zeroPad(Math.floor(timeNoHours%60))}s`;
        const item = document.querySelector(`.agenda-moderator-timeout-info[data-id='${preLive.id}']`);
        if (!!item) {
          item.style = 'display: inline-block';
          item.innerHTML = timeClock;
        }
      });
    };
    liveCheckFn();
    this.liveNowCheckInterval = setInterval(() => liveCheckFn(), 10000);
  },

  /**
   * Finish the session
   */
  finishTheSession() {
    return new Promise((resolve, reject) => {
      let url = Utils.getUrl(false, 'finish-the-session');
      let data = new FormData();
      data.append('agendaSessionId', this.getAgendaSessionId());
      axios
        .post(url, data)
        .then(res => {
          if (res.status === 200) {
            LiveUtils.openModal(this.modalResponse.selector, res.data.html);
            resolve('OK');
          }
          reject('KO');
        })
        .catch(error => {
          console.debug("ERR", error);
          reject('KO');
        });
    });
  },

  configureAgendaChangeModal() {
    $('body').on('click', '.agenda-moderator-active-item.not-self', ev => {
      ev.preventDefault();
      const uri = ev.currentTarget.href;
      $('#go-to-session-modal').modal('show');
      const theItem = document.querySelector('.agenda-moderator-active-item.is-active');
      if (theItem.classList.contains('item-is-live')) {
        document.querySelector('#finish-and-go-to-the-session-yes-button').style = 'display: block';
      } else {
        document.querySelector('#finish-and-go-to-the-session-yes-button').style = 'display: none';
      }
      if(!!theItem.classList.contains("item-is-finished")) {
        document.querySelector('#finish-and-go-to-the-session-yes-button').style = 'display: none';
        document.querySelector('#go-to-session-yes-button').querySelector('div').innerHTML = 'Yes.';
      } else {
        document.querySelector('#finish-and-go-to-the-session-yes-button').style = 'display: block';
        document.querySelector('#go-to-session-yes-button').querySelector('div').innerHTML =
          'Yes. Keep the current session opened.';

      }
      $('#go-to-session-modal').attr('current-href', uri);
    });
    $('body').on('click', '#finish-and-go-to-the-session-yes-button', ev => {
      this.finishTheSession()
        .then(res => {
          document.location.href = $('#go-to-session-modal').attr('current-href');
        })
        .catch(err => {
          document.location.href = $('#go-to-session-modal').attr('current-href');
        });
    });
    $('body').on('click', '#go-to-session-yes-button', ev => {
      document.location.href = $('#go-to-session-modal').attr('current-href');
    });
  }
};

export default LiveBackController;
