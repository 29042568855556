import {BehaviorSubject} from "rxjs";
import {filter} from "rxjs/operators";

export class AmgenIframe {

    constructor(config) {

        this._config = config;
        this._onIframeReady = new BehaviorSubject(false);
    }

    get onIframeReady() {
        return this._onIframeReady.asObservable()
            .pipe(
                filter(ready => ready)
            );
    }

    init() {

        return new Promise((resolve, reject) => {

            this.createIframe();

            this.iframe.onload = () => {

                this._onIframeReady.next(true);
            };
        });
    }

    createIframe() {
        this.iframe = document.body.querySelector('#amgen-connect-iframe');
        if (!this.iframe) {
            this.iframe = document.createElement('iframe');
            this.iframe.id = 'amgen-connect-iframe';
            this.iframe.src = this._config.src;
            this.iframe.width = '0';
            this.iframe.height = '0';
            this.iframe.style.position = 'absolute';
            this.iframe.style.top = '0';
            this.iframe.style.border = 'none';
            document.body.append(this.iframe);
        }
    }

    postMessage(action, data = {}) {

        if (!this.iframe) throw new Error('Amgen connect iframe is not initialized');
        this.iframe.contentWindow.postMessage(JSON.stringify({action: action, data: data}), '*');
    }

    reload() {

        if (this._config.reload) {

            location.reload();
        }
    }
}
