import {combineLatest} from "rxjs";
import {distinctUntilChanged, map} from "rxjs/operators";
import {insertParam} from "./url";
import {AmgenOwa} from "./amgen-owa";


export class AmgenConnect extends AmgenOwa {

    constructor(config) {

        super(config);

        this._setCallbackListeners();

        this.onSelectedSpeciality.subscribe((speciality) => {
            if (this._config.urlSpeciality && speciality) {
                // insertParam('speciality', speciality.name.es);
            }
        })
    }

    get onReady() {

        return this.onAuthServiceReady;
    }

    get onRequiredData() {
        return combineLatest([
            this.onProfessional,
            this.onSelectedSpeciality
        ]);
    }

    get onHasRequiredData() {
        return this.onRequiredData
            .pipe(
                map(([professional, selectedSpeciality]) => !!professional && !!selectedSpeciality),
                distinctUntilChanged()
            );
    }

    get ready() {

        return this._onAuthServiceReady.getValue();
    }

    get hasRequiredData() {
        return !!this.professional && !!this.selectedSpeciality;
    }

    init() {

        super.init();
    }
}
