<template>
  <div>
    <div class="speaker-content-video" v-if="speakerInfo.video" :style="speakerInfo.video ? 'margin-bottom: -107px;' : ''">
      <video-player
        :id="videoPlayerId"
        :videoid="videoData.videoId"
        :videotype="1"
        class="single-speaker-video-player"
        :videouri="speakerInfo.video"
        :accountid="videoData.accountId"
        :playerid="videoData.playerId"
        :embedid="'default'"
        :datasourcetype="0"
        :datatype="0"
      ></video-player>
    </div>
    <div class="speaker-content-description" :style="!speakerInfo.video ? 'min-height: 734px;' : 'padding-top: 125px;'">
      <div>
        <div class="speaker-content-description__header">
          <img class="speaker-content-description__header__image" v-if="!speakerInfo.video" :src="parseImage(speakerInfo.photo)" @error="imageUrlAlt" alt="Speaker Image">
          <div class="speaker-content-description__header__bio">
            <h3 class="speaker-content-description__header__bio__name" v-html="speakerInfo.name + ' ' + speakerInfo.surname"></h3>
            <p class="speaker-content-description__header__bio__ocupation" v-html="speakerInfo.institution"></p>
          </div>
        </div>
        <div class="speaker-content-description__body" :style="!speakerInfo.video ? '' : 'max-height: 220px;'" v-html="speakerInfo.bio"></div>
      </div>
      <SpeakersRelatedContentVue
        v-if="speakerInfo && speakerInfo.relatedContent && speakerInfo.relatedContent.length > 0 && !hideRelatedContent"
        :relatedContent="!hideRelatedContent ? speakerInfo.relatedContent : []"
      ></SpeakersRelatedContentVue>
    </div>
  </div>
</template>

<script>
import SpeakersRelatedContentVue from './SpeakersRelatedContent.vue';

export default {
  components: { SpeakersRelatedContentVue },
  name: 'SpeakersDescriptionBox',
  props: {
    speakerInfo: Object,
    source: String,
    hideRelatedContent: Boolean,
  },
  data() {
    return {
      videoPlayerId: this.speakerInfo.id + '_' + this.source,
      videoData: {},
    };
  },
  beforeMount() {
    if (this.speakerInfo.video) {
      this.prepareVideoData(this.speakerInfo.video)
    }
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/images/v4/assets/avatar.svg";
    },
    parseImage(img) {
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
    prepareVideoData(videoUri) {
      const congress_video_info = {};
      if (/brightcove/i.test(videoUri)) {
          congress_video_info.videoType = 1;
          congress_video_info.parsedUri = new URL(videoUri);
          congress_video_info.explodedPath = congress_video_info.parsedUri.pathname.split('/');
          const query = Object.fromEntries(congress_video_info.parsedUri.searchParams.entries());
          congress_video_info.accountId = congress_video_info.explodedPath[1];
          congress_video_info.videoId = query.videoId;
          congress_video_info.player = congress_video_info.explodedPath[2].split('_');
          congress_video_info.playerId = congress_video_info.player[0];
          this.videoData = congress_video_info;
      }
    },
  }
};
</script>

