<template>
<div id="multimedia-header">
    <div class="multimedia__info col col-xl-12">
        <span v-if="duration" class="multimedia__info__time">{{duration}}</span>
        <span class="multimedia__info__volt" v-if="isAmgen && voltTitle && !voltLink">Volt: {{voltTitle}}</span>
        <a v-if="isAmgen && voltTitle && voltLink" :href="voltLink" target="_blank">
            <span class="multimedia__info__volt" @click="handleVoltLink">{{voltTitle}}</span>
        </a>
    </div>
    <div class="multimedia-header__title">
        {{defaultTitle}}
    </div>
    <div class="multimedia-header__keywords">
        <a v-for="(kw, index) in keywordList" :key="index" class="keyword" href="">#{{ kw.name }}</a>
    </div>
    <div class="multimedia-header__description mt-3" v-html="description"></div>
    <div class="multimedia-header__actions mt-3">
        <ShareActions :id="this.id" :title="this.title" :slug="''" :type="this.type" :liked="this.liked" :disliked="this.disliked" :saved="this.saved"></ShareActions>
    </div>
    <div class="multimedia-header__actions mt-3 test-modal-button-div" hidden>
        <button class="v4-btn-primary show-test-modal-button" id="showTestModalBefore" @click="showTestModal(1)" disabled hidden><span class="js-before-test-check" hidden><i class="bi bi-check"></i>
    </span><span class="js-before-test-text">Resume Quiz</span></button>
        <button class="v4-btn-primary show-test-modal-button" id="showTestModalAfter" @click="showTestModal(2)" disabled hidden><span class="js-after-test-check" hidden><i class="bi bi-check"></i>
    </span><span class="js-after-test-text">Resume Quiz</span></button>
    </div>
</div>
</template>

<script>
import PageStayController from '../../page-stay/PageStayController';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import ShareActions from '../share-actions/ShareActions.vue';
export default {
    name: 'MultimediaHeader',
    components: {
        ShareActions
    },
    props: {
        id: Number,
        title: String,
        description: String,
        type: String,
        duration: String,
        keywords: Array,
        liked: Boolean,
        disliked: Boolean,
        saved: Boolean,
        voltLink: String,
        voltTitle: String,
        isAmgen: Boolean
    },
    data() {
        return {
            defaultTitle: this.title,
            defaultDescription: this.description,
            keywordList: this.keywords,
        }
    },

    mounted() {
        this.initStayPage();
    },

    methods: {

        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        showShareOptions() {
            let icons = $(`.share-icons-${this.id}`);

            if (icons.is(":visible")) {
                $(icons).hide(200);
                $(icons).fadeOut(200);
            } else {
                $(icons).show(200);
                $(icons).fadeIn(200);
            }            this.$root.$emit('handleVoltLink');

        },
        initStayPage() {
            PageStayController.startStayPageTime('multimedia', this.id);
        },
        showTestModal(type) {
            // this.$root.$emit('showTestModal', type);
            document.dispatchEvent(new CustomEvent("showTestModal", { type: type}));                    
        },
        handleVoltLink() {
            const parts = this.voltLink.split('/');
            const urlDomain = parts[2];
            GoogleAnalytics.sendOutboundClickEvent(this.voltTitle, this.voltLink, urlDomain);
        }
    }
}
</script>

<style lang="scss">
.keyword {
    color: #2DBCB6;
    font-size: .9rem;
    letter-spacing: 0.58px;
    line-height: 18px;
    text-align: left;
    font-weight: bold;
    margin-right: 5px;
}
</style>
