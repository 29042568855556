<template>
  <select
    @change="onChange"
    v-if="true"
    class="selectpicker"
    data-live-search="true"
    data-actions-box="true"
    :data-select-all-text="t('general.all')"
    :data-deselect-all-text="t('general.clear')"
    :multiple="multiple"
    data-size="5"
    :data-live-search-placeholder="t('general.search')"
    data-selected-text-format="count > 1"
    :data-label="theLabel"
    :data-name="name"
    :ref="id"
    :id="id"
    :title="theLabel"
    :name="name"
    :data-count-selected-text="labeled"
  >
    <option
      v-for="opt in options"
      :key="opt.id"
      :value="opt.id ? opt.id : ''"
      :selected="selectOption(opt.id)"
      :therapeutic-area="opt.therapeutic_area_id"
    >
      {{ opt.name }}
    </option>
  </select>
</template>

<script>
import TranslateService from "../../../core/services/translate.service";
import 'bootstrap-select';
import $ from "jquery";

export default {
  props: {
    id: String,
    name: String,
    title: String,
    defaultOptions: Array,
    multiple: Boolean,
    userTA: Array,
    preselectedvalues: Array,
  },
  watch: {
    defaultOptions: function (newValue, oldValue) {
      $(".selectpicker").selectpicker("refresh");
    },
  },
  data() {
    return {
      defaultSelected: {
        name: "TEst121",
        last: "sadad",
      },
      options: this.defaultOptions ?? [],
      userTherapeuticAreas: this.userTA ?? [],
      theLabel: this.title,
      defaultForm: {
        events: null,
        content: null,
        all: null,
        "knowledge-journey": null,
        bookmarks: false,
        q: "",
        qTherapeuticAreas: [],
        qPathologies: [],
        format: [],
        content_type: [],
        qKeywords: [],
        date: [],
        page: 0,
        size: 12,
      },
      form: {},
      translator: TranslateService.create(),
    };
  },
  computed: {
    labeled() {
      if (this.theLabel.length > 13) {
        return `${this.theLabel.substr(0, 13)}... ({0})`;
      } else {
        return `${this.theLabel} ({0})`;
      }
    },
  },
  mounted() {
    if (this.id == "diseases") {
      this.hideOptions($("#therapeuticAreas")[0]);
    }
    $(".selectpicker").selectpicker("refresh");
  },
  methods: {
    onChange() {
      const selectEl = this.$refs[this.id];
      const selectedOptions = Array.from(selectEl.selectedOptions).map(op => op.text);
      const selectedValues = Array.from(selectEl.selectedOptions).map((op) => op.value);
      const first = selectedValues[0] ?? "";
      // useSelectPickerStore
      /* this.selectPickerStore.selectPickerData = {
        key: this.id,
        value: this.multiple ? selectedValues : first,
      } */

      document.dispatchEvent(new CustomEvent("changeselectpicker", { detail: { key : this.id, value: this.multiple ? selectedValues : first, name: selectedOptions } }));
      // this.$root.$emit("changeselectpicker", {
      //   key: this.id,
      //   value: this.multiple ? selectedValues : first,
        // name: selectedOptions,
      // });

      if (selectEl.id == "therapeuticAreas") {
        this.hideOptions(selectEl);
      }
      $(`#${selectEl.id} option:selected`).prependTo(`#${selectEl.id}`);
      $(`#${selectEl.id}`).selectpicker("refresh");
    },
    selectOption(id) {
      return this.preselectedvalues ? this.preselectedvalues.includes(id.toString()) : false;
    },
    hideOptions(selector) {
      $("#diseases").find("option").removeAttr("hidden");
      const selectedValues = Array.from(selector.selectedOptions).map((op) => ({
        value: op.value,
        therapeutic_area: op.therapeutic_area_id,
      }));
      selectedValues.forEach((val) => {
        let filteredDiseases = $("#diseases").find(`option[therapeutic-area='${val.value}']`).toArray();
        filteredDiseases.forEach((disease) => {
          $(disease).attr("hidden", true);
          $(disease).prop("selected", false);
        });
      });
      $("#diseases").selectpicker("refresh");
    },
    t(tag) {
      return this.translator.translate(tag);
    },
  },
};
</script>
