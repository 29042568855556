<template>
  <div class="row" :key="revisionNumber">
    <div class="col col-sm-9 clinical-cases__tabsheet__content__container">
      <div id="video-container" class="clinical-cases__tabsheet__content__video">
        <div id="brightPlayer"></div>
        <DocumentViewer
          v-if="showPDF"
          style="width: 100%; height: 100%;"
          class="video-js-support-multimedia-d"
          :id="multimediaId"
          :fileuri="getDocumentFileURL()"
        >
        </DocumentViewer>
      </div>
      <em class="close-btn d-none bi bi bi-x" @click="toggleSupportMultimedia(openedSupportMultimedia)"></em>
      <div
        class="clinical-cases__tabsheet__content__support"
        :class="{ 'has-content': activeIntervalMultimedias.length > 0 }"
      >
        <p class="clinical-cases__tabsheet__subtitle pb-0">{{t('clinical-case.tabs.scientific-support')}}</p>
        <ul>
          <V4Carousel
            :key="activeIntervalKey"
            :slidesToShow="isMobile() ? 1 : 2"
            :slidesToScroll="isMobile() ? 1 : 2"
            :name="'support'"
            :isLoading="isLoading"
          >
            <template v-slot:items>
              <div
                v-for="(multimedia, i) in activeIntervalMultimedias"
                :key="i"
                @click="toggleSupportMultimedia(multimedia)"
                class="support-multimedia-item"
              >
                <V4Card v-bind="multimedia"></V4Card>
              </div>
            </template>
          </V4Carousel>
        </ul>
      </div>
    </div>
    <div class="col col-sm-3 clinical-cases__tabsheet__consultation__container">
      <div class="clinical-cases__tabsheet__consultation__panel">
        <p class="clinical-cases__tabsheet__subtitle">{{ t('clinical-case.presentation.role-play') }}</p>
        <ul>
          <li
            v-for="(item, index) of cc.intervals"
            :key="item.id"
            :class="getClassForInterval(item)"
            @click="setVideoTimestamp(item.timestamp)"
          >
            <div class="col">
              <!-- viewed, playing-->
              <p>{{ zeroPad(index + 1) }}</p>
            </div>
            <div class="col">
              <p>{{ item.translated_name }}</p>
              <p v-if="!cc.hide_interval">
                {{ splitFrom(item.from, [0]) }}:{{ splitFrom(item.from, [1]) }}:{{ splitFrom(item.from, [2]) }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import brightcovePlayerLoader from '@brightcove/player-loader';
import ClinicalCasesRepository from './repositories/clinical-cases.repository';
import TranslateService from '../../core/services/translate.service';
import DocumentViewer from '../multimedia/DocumentViewer.vue';
import V4Carousel from '../carousel/V4Carousel.vue';
import V4Card from '../card/V4Card.vue';
import $ from 'jquery';

export default {
  name: 'ClinicalCasesContent',
  components: {
    DocumentViewer,
    V4Carousel,
    V4Card
  },
  data() {
    return {
      host: window.location.protocol + '//' + window.location.host,
      revisionNumber: 0,
      currentTime: 0,
      videoJS: null,
      lastInterval: null,
      finalIntervalId: null,
      executed: false,
      intervalDuration: 0,
      showPDF: false,
      translator: TranslateService.create(),
      activeIntervalMultimedias: [],
      openedSupportMultimedia: null,
      isLoading: true,
      // bus: new Vue(),
      activeIntervalKey: '',
      multimediaDownloadable: false,
      multimediaRequestable: false,
      multimediaFile: ''
    };
  },
  props: {
    cc: Object
  },
  methods: {
    setVideoTimestamp(timestamp) {
      //Close opened document
      this.toggleSupportMultimedia(null);
      //Set the last interval after change
      let currentInterval = this.getActiveIntervals();
      if (currentInterval.length > 0) {
        this.lastInterval = currentInterval[0];
      }
      this.videoJS.currentTime(timestamp);
    },
    getActiveIntervals() {
      let self = this;
      return this.cc.intervals.filter(item => {
        return self.currentTime >= item.timestamp && self.currentTime <= item.timestamp + item.duration;
      });
    },
    setActiveIntervalMultimedias() {
      this.activeIntervalMultimedias = [];
      let intervals = this.getActiveIntervals();
      let intervalIds = '';
      intervals.forEach(interval => {
        intervalIds = intervalIds + interval.id + '-';
        interval.multimedia_target.forEach(mult => {
          this.activeIntervalMultimedias.push(mult);
        });
      });
      this.isLoading = false;
      this.activeIntervalKey = intervalIds;
    },
    getClassForInterval(item) {
      let itemFinish = item.timestamp + item.duration;
      if (item.timestamp <= this.currentTime && itemFinish > this.currentTime) {
        return 'row playing';
      } else if (itemFinish < this.currentTime) {
        return 'row viewed';
      }
      return 'row';
    },
    initBrightCove(accountId, videoId, playerId, embedId) {
      let self = this;

      const player = brightcovePlayerLoader({
        refNode: '#brightPlayer',
        accountId,
        playerId: playerId,
        embedId: embedId,
        videoId
      });

      player
        .then(result => {
          self.videoJS = result.ref;
          let videoTotalTime;

          self.videoJS.on('loadedmetadata', () => {
            videoTotalTime = self.videoJS.duration();
          });

          self.videoJS.on('timeupdate', () => {
            let currentTimestamp = self.videoJS.currentTime();
            let totalViewedPercentage = 0;

            if (!Number.isNaN(currentTimestamp)) {
              this.currentTime = currentTimestamp;
              totalViewedPercentage = (this.currentTime * 100) / videoTotalTime;

              let currentInterval = this.getActiveIntervals();
              this.setActiveIntervalMultimedias();

              if (currentInterval[0] && !self.executed) {
                this.executed = true;
                self.intervalDuration = Math.trunc(currentTimestamp) + Math.trunc(currentInterval[0].duration);
              }

              if (currentInterval[0] && Math.trunc(currentTimestamp) >= self.intervalDuration) {
                if (currentInterval[0].id != self.lastInterval.id) {
                  totalViewedPercentage = currentInterval[0].id == this.finalIntervalId ? 100 : totalViewedPercentage;
                  let fd = new FormData();
                  fd.append('cc_id', currentInterval[0].cc_id);
                  fd.append('interval_id', currentInterval[0].id);
                  fd.append('percentage', Math.trunc(totalViewedPercentage));
                  ClinicalCasesRepository.saveInterval(fd);
                  this.lastInterval = currentInterval[0];
                  document.dispatchEvent(new CustomEvent("updateCCPercentage", { percentage: Math.trunc(totalViewedPercentage) }));                    
                  // this.$root.$emit('updateCCPercentage', Math.trunc(totalViewedPercentage));
                }
                self.executed = false;
              }
            }
          });
        })
        .catch(err => {
          console.warn(err);
        });
    },
    zeroPad(num) {
      if (Number.isSafeInteger(num)) {
        if (num < 0) {
          return '00';
        } else if (num < 10) {
          return `0${num}`;
        } else {
          return `${num}`;
        }
      }
    },
    splitFrom(from, position) {
      if (!from) {
        return '00';
      }
      let splitedFrom = from.split(':');
      return splitedFrom[position];
    },
    async getLastInterval() {
      this.getFinalInterval();
      let fd = new FormData();
      fd.append('cc_id', this.cc.id);
      ClinicalCasesRepository.getLastIntervalCompleted(fd).then(response => {
        this.lastInterval = response.data;
      });
    },
    getFinalInterval() {
      if (this.cc && this.cc.intervals > 0) {
        this.finalIntervalId = this.cc.intervals[this.cc.intervals.length - 1].id;
      }
    },
    closeOpenedSupportMultimedia() {
      $('.video-js-support-multimedia').remove();
      $('#video-container script').remove();
      if ($('.support-multimedia-item').length) {
        $('.support-multimedia-item').removeClass('active');
      }
      $('em.close-btn').addClass('d-none');
    },

    toggleSupportMultimedia(item) {
      this.showPDF = false;
      this.openedSupportMultimedia = item;
      //Delete previous child after add a new one
      this.closeOpenedSupportMultimedia();
      //Todo: Comprobar si es un video, qué tipo es, brightcove, youtube.. si es brightcove obtener de manera dinamica el id y demás información relacionada con el player
      if (item && $('#brightPlayer').is(':visible')) {
        this.videoJS.pause();
        if (item && item.file) {
          $('#brightPlayer').hide();
          this.multimediaDownloadable = item.downloadable;
          this.multimediaRequestable = item.requestable;
          this.multimediaFile = item.file;
          this.multimediaId = item.id;
          this.showPDF = true;
          event.currentTarget.classList.add('active');
          $(`em.close-btn`).removeClass('d-none');
        } else if (
          item &&
          item.external_link &&
          item.multimedia_type_id == 4 &&
          item.external_link.includes('players.brightcove')
        ) {
          $('#brightPlayer').hide();
          let videoData = this.getVideoData(item.external_link);
          $('#video-container').append(`
            <video-js data-account="${videoData.accountId}"
            data-player="${videoData.playerId}"
            data-embed="${videoData.embedId}"
            data-type="brightcove"
            id="video-js-player-${item.id}"
            class="video-js-player is-brightcove video-js vjs-big-play-centered video-js-support-multimedia"
            style="height: 100%;"
            data-video-id="${videoData.videoId}"
            controls=""
            data-preload="false"
            data-real-id="${item.id}"
            data-playlist-id=""
            data-application-id="">
            </video-js>
            <script type="application/javascript" src="https://players.brightcove.net/${videoData.accountId}/${videoData.playerId}_${videoData.embedId}/index.min.js"/>`);
          event.currentTarget.classList.add('active');
          $(`em.close-btn`).removeClass('d-none');
        } else {
          window.open(item.external_link, '_blank').focus();
        }
      } else {
        this.videoJS.play();
        $('#brightPlayer').show();
        $('#document-container').remove();
        if (item) {
          $(`#video-js-player-${item.id}`).remove();
        }
      }
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    getMultimediaType(multimedia) {
      return multimedia.file ? this.t('home.search.document') : this.t('home.search.video');
    },
    getVideoData(link) {
      let data = [];
      let parsedUri = new URL(link);
      let explodedPath = parsedUri.pathname.split('/');
      data.accountId = explodedPath[1];
      let player = explodedPath[2].split('_');
      data.playerId = player[0];
      data.embedId = player[1];
      data.videoId = parsedUri.searchParams.get('videoId');
      return data;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    getDocumentFileURL() {
      const downloadableParam = this.multimediaDownloadable ? '&disableDownload=false' : '&disableDownload=true';
      const requestableParam =
        this.multimediaRequestable && !this.multimediaDownloadable ? '&disableRequest=false' : '&disableRequest=true';
      const filePath = document.location.protocol + '//' + document.location.host + '/' + this.multimediaFile;

      let fileUri = `/pdfjs/web/viewer.html?file=${filePath}?search=${downloadableParam}${requestableParam}`;
      if (this.multimediaRequestable) {
        fileUri = `${fileUri}&reqCallback=requestMultimediaAction(${this.multimediaId})`;
      }
      console.log(fileUri);

      return fileUri;
    }
  },
  mounted() {
    if (!!this.cc.external_link_accountId) {
      this.initBrightCove(
        this.cc.external_link_accountId,
        this.cc.external_linkVideoId,
        this.cc.external_link_playerId,
        this.cc.external_link_embedId
      );
    }
    this.getLastInterval();
    this.getFinalInterval();
  }
};
</script>
