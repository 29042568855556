<template>
  <div class="connectmi-event__committee">
    <div class="connectmi-event__committee__helper" v-if="!isLoading">
      <h2 class="connectmi-event__committee__title title title--xl">Steering Committee</h2>
      <div v-if="showMore || key < 8" class="connectmi-event__committee__speakers" :key="this.showMore">
        <EventSpeaker
          v-for="(speaker, key) in committeeMembers"
          :key="key"
          :image="speaker.photo"
          :imagePath="imagePath"
          :fullname="speaker.name + ' ' + speaker.surname"
          :job="speaker.position"
          variant="committee"
          :variantimage="'' + ((key % 3) + 1)"
          :bio="speaker.short_bio"
          :isSteeringCommittee="speaker.steering_committee"
          :institution="speaker.institution"
        ></EventSpeaker>
      </div>
      <button v-if="committeeMembers.length > 7" class="connectmi-event__button connectmi-event__button--shadow my-4 mx-2" @click="showMoreHandler">
        <span v-if="!showMore">Show More</span>
        <span v-else>Show Less</span>
      </button>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import EventSpeaker from './components/EventSpeaker.vue';
import axios from 'axios';

export default {
  props: {
    slug: String,
    imagePath: String
  },

  components: {
    EventSpeaker
  },

  data() {
    return {
      committeeMembers: [],
      translator: TranslateService.create(),
      showMore: false,
      isLoading: true,
    };
  },

  mounted() {
    this.getEventCommittee();
  },

  methods: {
    createImagePath(image) {
      return this.imagePath + image;
    },
    showMoreHandler() {
      this.showMore = !this.showMore;
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    async getEventCommittee() {
      const result = await axios.get(`/congresses/steering-committee-members?slug=${this.slug}`);
      this.committeeMembers = result.data.data;
      this.isLoading = false;
    }
  }
};
</script>
