import {deleteCookie, getCookie, setCookie} from "./cookies";
import {BehaviorSubject} from "rxjs";
import {AmgenIframe} from "./amgen-iframe";
import {distinctUntilChanged, filter} from "rxjs/operators";

export class AmgenAuth extends AmgenIframe {

    constructor(config) {

        super(config);

        this._promises = {};
        this._onAuthServiceReady = new BehaviorSubject(false);
        this._onUser = new BehaviorSubject(null);
        this._onAccessToken = new BehaviorSubject(getCookie('access_token'));
        this._onRefreshToken = new BehaviorSubject(getCookie('refresh_token'));
        this._onProfessional = new BehaviorSubject(!!getCookie('professional'));
        this._onAuthChange = new BehaviorSubject(this.isAuth);
        this._onAuthWorking = new BehaviorSubject(false);
    }

    get onAuthServiceReady() {
        return this._onAuthServiceReady.asObservable()
            .pipe(
                filter(ready => ready)
            );
    }

    get onUser() {
        return this._onUser.asObservable();
    }

    get onAccessToken() {
        return this._onAccessToken.asObservable();
    }

    get onRefreshToken() {
        return this._onRefreshToken.asObservable();
    }

    get onProfessional() {
        return this._onProfessional.asObservable();
    }

    get onAuthChange() {
        return this._onAuthChange.asObservable()
            .pipe(
                distinctUntilChanged()
            );
    }

    get onAuthWorking() {
        return this._onAuthWorking.asObservable()
            .pipe(
                distinctUntilChanged()
            );
    }

    get user() {
        return this._onUser.getValue();
    }

    set user(value) {

        if (value) this.professional = true;

        this._onUser.next(value);
    }

    get accessToken() {
        return this._onAccessToken.getValue();
    }

    get refreshToken() {
        return this._onRefreshToken.getValue();
    }

    get professional() {
        return this._onProfessional.getValue();
    }

    get someAuthCookie() {

        return !!getCookie('access_token') || !!getCookie('refresh_token');
    }

    set professional(value) {

        if (value) setCookie('professional', true, 1);
        else deleteCookie('professional');

        this._onProfessional.next(!!value);
    }

    get isAuth() {
        return !!this.accessToken;
    }

    _setCallbackListeners() {

        window.addEventListener('message', (event) => {

          if(event.data && typeof event.data === 'string') {
            try {
              JSON.parse(event.data);
            } catch(e) {
              console.debug("ERR", e);
              return;
            }
          }

            let eventData = event.data && typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

            if (eventData) {

                let data = eventData.data;
                let error = eventData.error;

                switch (eventData.action) {

                    case 'checkEmail':
                        if (this._promises.checkEmail) {
                            if (error) this._promises.checkEmail.reject(error);
                            else this._promises.checkEmail.resolve(data);

                            delete this._promises.checkEmail;
                        }
                        this._onAuthWorking.next(false);
                        break;
                    case 'login':
                    case 'signUp':
                    case 'loginV2':
                    case 'loginMMP':
                    case 'signUpV2':


                        if (this._promises.login) {
                            if (error) this._promises.login.reject(error);
                            else this._promises.login.resolve(data);

                            delete this._promises.login;
                        }

                        if (data) {
                            this.user = data.user;
                            this.setAuthCookies(data.tokens);
                        } else {
                            this.setAuthCookies(undefined);
                        }

                        this._onAuthWorking.next(false);
                        break;
                    case 'logout':


                        if (this._promises.logout) {
                            if (error) this._promises.logout.reject(error);
                            else this._promises.logout.resolve(data);

                            delete this._promises.logout;
                        }

                        this.user = null;
                        this.professional = undefined;
                        this.setAuthCookies(undefined);
                        this._onAuthWorking.next(false);
                        break;
                    case 'tokens':

                        if (this._promises.tokens) {
                            if (error) this._promises.tokens.reject(error);
                            else this._promises.tokens.resolve(data);

                            delete this._promises.tokens;
                        }

                        this.setAuthCookies(data);
                        this._onAuthWorking.next(false);
                        break;
                    case 'refreshTokens':

                        if (this._promises.refreshTokens) {
                            if (error) this._promises.refreshTokens.reject(error);
                            else this._promises.refreshTokens.resolve(data);

                            delete this._promises.refreshTokens;
                        }

                        this.setAuthCookies(data);
                        this._onAuthWorking.next(false);
                        break;
                    case 'user':

                        if (this._promises.user) {
                            if (error) this._promises.user.reject(error);
                            else this._promises.user.resolve(data);

                            delete this._promises.user;
                        }

                        if (error) this.user = null;
                        else this.user = data;
                        this._onAuthWorking.next(false);
                        break;
                    case 'resetPassword':
                        if (this._promises.resetPassword) {
                            if (error) this._promises.resetPassword.reject(error);
                            else this._promises.resetPassword.resolve(data);

                            delete this._promises.resetPassword;
                        }
                        this._onAuthWorking.next(false);
                        break;
                }
            }
        });
    }

    init() {

        super.init();


        this.onIframeReady.subscribe((data) => {

            let promises = [];

            this._onAuthWorking.next(true);
            promises.push(this.tokens());
            promises.push(this.getUser());

            Promise.all(promises)
                .catch((error) => {
                    this.setAuthCookies(undefined);
                })
                .finally(() => {

                    this._onAuthWorking.next(false);
                    this._onAuthServiceReady.next(true);
                });
        });
    }

    checkEmail(email) {

        return new Promise((resolve, reject) => {

            this._promises.checkEmail = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('checkEmail', {email: email});
        });
    }

    login(credentials) {

        return new Promise((resolve, reject) => {

            this._promises.login = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('login', credentials);
        });
    }

    loginV2(credentials) {

        return new Promise((resolve, reject) => {

            this._promises.login = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('loginV2', credentials);
        });
    }

    loginMMP(credentials) {

        return new Promise((resolve, reject) => {

            this._promises.login = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('loginMMP', credentials);
        });
    }

    signUp(data) {

        return new Promise((resolve, reject) => {

            this._promises.login = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('signUp', data);
        });
    }

    signUpV2(data) {

        return new Promise((resolve, reject) => {

            this._promises.login = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('signUpV2', data);
        });
    }

    logout() {

        return new Promise((resolve, reject) => {

            this._promises.logout = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('logout');
        });
    }

    tokens() {

        return new Promise((resolve, reject) => {

            this._promises.tokens = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('tokens');
        });
    }

    refreshTokens() {

        return new Promise((resolve, reject) => {

            this._promises.refreshTokens = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('refreshTokens');
        });
    }

    getUser() {

        return new Promise((resolve, reject) => {

            this._promises.user = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('user');
        });
    }

    resetPassword(email) {

        return new Promise((resolve, reject) => {

            this._promises.resetPassword = {resolve, reject};
            this._onAuthWorking.next(true);

            this.postMessage('resetPassword', {email: email});
        });
    }

    setAuthCookies(data) {

        if (data && (data.access !== this.accessToken || data.refresh !== this.refreshToken)) {

            setCookie('access_token', data.access, 360);
            setCookie('refresh_token', data.refresh, 360);

            this._onAccessToken.next(data.access);
            this._onRefreshToken.next(data.refresh);

            this.reload();
        } else if (!data && this.someAuthCookie) {

            deleteCookie('access_token');
            deleteCookie('refresh_token');

            this._onAccessToken.next(null);
            this._onRefreshToken.next(null);

            this.reload();
        }

        this._onAuthChange.next(this.isAuth);
    }
}
