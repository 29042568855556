import RoutesHelper from './routesHelper';
import $ from 'jquery';
import axios from 'axios';
import { onMounted } from 'vue';

const CompleteUserFieldsModal = {
  hasEmptyData: false,
  emptyFields: new Array(),

  modal: {
    selector: '#user-fields-modal',
    close: '.close-button',
    content: '.content-modal',
    body: '.body-modal-part',
    success: '.body-success-part'
  },
  dataGroups: {
    email: '.email-group',
    name: '.name-group',
    surname: '.surname-group',
    country: '.country-group',
    department: '.department-group',
    specialities: '.speciality-group',
    institution: '.institution-group',
    therapeuticAreas: '.therapeutic-group',
    hcpRestricted: '.hcprestricted-group',
    filterTimezone: '.filter-timezone-group',
    filterTimezoneCheckbox: '#filter_country_time_zones',
    countryTimezone: '.country-timezone-group',
    countryTimezoneSelect: '#country_time_zone',
    timezone: '.timezone-group',
    timezoneSelect: '#time_zone',
    confirmTimezone: '.confirmtimezone-group'
  },
  form: {
    name: '.missing-user-fields-form',
    submit: '.update-user-button'
  },
  routes: {
    updateUser: 'update-profile'
  },

  init() {
    if (document.body.classList.contains('hospitality-suite')) {
      const userFieldsModal = document.getElementById('user-fields-modal');
      if (userFieldsModal) {
        userFieldsModal.classList.add('d-none');
      }
      return;
    }
    
    this.retrieveEmptyUserData();
    if (this.hasEmptyData) {
      this.showModal();
    }
    this.initListeners();
  },

  showModal() {
    this.showEmptyFields();
    $(this.modal.selector).show();
  },

  hideModal() {
    $(this.modal.selector).hide();
  },

  retrieveEmptyUserData() {
    const fields = localStorage.getItem('fields');
    const healthcare = window.user ? window.user.is_healthcare_verified : false;
    const answeredHCP = window.user && window.user.hcp_restricted_answered == '1' ? true : false;
    const confirmedTimezone = window.user && window.user.time_zone_confirmed == '1' ? true : false;
    try {
      if (fields && fields != null && fields != 'undefined') {
        this.emptyFields = JSON.parse(fields);
      }
      if (!answeredHCP && (healthcare === 3 || healthcare === '3')) {
        this.emptyFields.push('hcpRestricted');
      }
      if (!confirmedTimezone) {
        this.emptyFields.push('filterTimezone');
        //this.emptyFields.push('timezone');
        //this.emptyFields.push('countryTimezone');
        this.updateFilterTimezone();
        this.emptyFields.push('confirmTimezone');
      }
    } catch (e) {
      console.debug("ERR", e);
    }
    this.hasEmptyData = this.emptyFields && this.emptyFields.length;
  },

  showEmptyFields() {
    this.emptyFields.forEach(field => {
      $(this.dataGroups[field]).show();
    });
  },

  initListeners() {
    $(this.form.submit).click(() => this.updateUserAction());
    // $(this.modal.close).click(() => this.hideModal());

    if (document.querySelector(this.dataGroups.filterTimezoneCheckbox)) {
      document.querySelector(this.dataGroups.filterTimezoneCheckbox).addEventListener('click', () => this.updateFilterTimezone());
    }
  },

  retrieveFormData(selector) {
    const arrays = ['therapeuticAreas', 'speciality', 'newsletterPathologies'];
    let data = {};
    const formData = $(selector).serializeArray();
    formData.forEach(input => {
      const { name, value } = input;
      const isArray = arrays.includes(name);
      if (name === 'time_zone') {
        let filterTimezone = document.querySelector(this.dataGroups.filterTimezoneCheckbox);
        data[name] = document.querySelector(this.dataGroups.timezoneSelect).value;
        if (filterTimezone && !filterTimezone.checked) {
          data[name] = document.querySelector(this.dataGroups.countryTimezoneSelect).value;
        }
      } else if (data[name]) {
        data[name] = [...data[name], value];
      } else {
        if (isArray) data[name] = [value];
        else {
          data[name] = value;
        }
      }
    });
    // In Profile we need to send the array as JSON...
    Object.keys(data).forEach(d => {
      const isArray = arrays.includes(d);
      if (isArray) {
        data = { ...data, [d]: JSON.stringify(data[d]) };
      }
    });
    return data;
  },

  updateUserAction() {
    event.stopPropagation();
    const data = this.retrieveFormData(this.form.name);
    const totalFields = Object.keys(this.emptyFields).length;
    const missingField = Object.values(data).filter(d => !!d);
    if (totalFields !== missingField.length) return;
    axios
      .post(this.getUrl(this.routes.updateUser), data)
      .then(response => {
        this.onUpdateUserSuccess();
      })
      .catch(error => {
        console.debug("ERR", error.message);
        // this.hideModal();
      });
  },

  onUpdateUserSuccess() {
    $(this.modal.body).hide();
    setTimeout(() => this.hideModal(), 3000);
    $(this.modal.success).show();
    localStorage.removeItem('fields');
    //If time zone is confirmed in this action, reload page
    if(window.user.time_zone_confirmed == '') {
      window.location.reload();
    }
  },

  updateFilterTimezone() {
    let filterTimezone = document.querySelector(this.dataGroups.filterTimezoneCheckbox);
    if (filterTimezone.checked) {
      $(this.dataGroups.countryTimezone).hide();
      $(this.dataGroups.timezone).show();
    } else {
      $(this.dataGroups.timezone).hide();
      $(this.dataGroups.countryTimezone).show();
    }
  },

  getUrl: function(route) {
    let routesHelper = new RoutesHelper();
    return routesHelper.getRoute(route);
  }
};

export default CompleteUserFieldsModal;
