<template>
  <form :action="getSearchRoute()" @submit.prevent="onFormSubmit">
    <div class="fliter-therapeutics">
      <div v-for="(ta, index) in taOptions" :key="index" class="filter-therapeutics_card">
        <label class="item" :class="therapeuticsSelected && therapeuticsSelected.includes(ta.id) ? 'active' : ''">
          <input :id="ta.id" :checked="therapeuticsSelected && therapeuticsSelected.includes(ta.id)" type="checkbox"
            @change="onChange(ta.id)" />
          <img :src="'/'+ ta.icon"></img>
          <span class="checkmark">{{ ta.name }}</span>
        </label>
      </div>
    </div>
    <div class="fliter-therapeutics-button">
      <button class="v4-btn-primary" @click="searchList">
        <i class="bi bi-search"></i>
        <p>{{ t("general.search") }}</p>
      </button>
    </div>
  </form>
</template>

<script>
import ContentRepository from "./repositories/Content.repository";
import TranslateService from '../../core/services/translate.service.js';
import TranslationService from '../../core/services/translation.service.js';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import PageStayController from "../../page-stay/PageStayController";

export default {
  props: {
    therapeutics: Array,
    redirectMode: Boolean,
  },
  data() {
    return {
      taOptions: this.therapeutics ?? [],
      translator : TranslateService.create(),
      defaultForm: {
        events: null,
        content: null,
        all: null,
        "knowledge-journey": null,
        bookmarks: false,
        q: "",
        qTherapeuticAreas: [],
        qPathologies: [],
        format: [],
        content_type: [],
        qKeywords: [],
        qContentType: [],
        qStartDate: null,
        qEndDate: null,
        date: [],
        page: 0,
        size: 12,
      },
      form: {},
      therapeuticsSelected : [],
    };
  },
  mounted() {
    this.therapeuticsSelected = this.getTherapeutics();
    this.form = { ...this.defaultForm };
    this.shouldMakeSearchRequest();
    document.addEventListener("SearchFormResultsPageChanged", (event) => {
      this.page = event.detail.currentPage;
      this.form["page"] = event.detail.currentPage;
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.search(event.detail.currentPage);
    });
    this.initStayPage();
  },
  methods: {
    onChange(itemId) {
      if (document.getElementById(itemId).checked) {
        document.getElementById(itemId).parentElement.classList.add('active');
        document.getElementById(itemId).parentElement.classList.remove('inactive');
      } else {
        document.getElementById(itemId).parentElement.classList.add('inactive');
        document.getElementById(itemId).parentElement.classList.remove('active');
      }
      const activeIds = Array.from(document.querySelectorAll('.filter-therapeutics_card label.active')).map(label => label.querySelector('input').id);
      if (activeIds.length === 0) {
        this.form['qTherapeuticAreas'] = [];
      } else {
        this.form['qTherapeuticAreas'] = activeIds;
      }

      document.dispatchEvent(new CustomEvent("changecheckbox", { detail: {
        key: this.elementId,
        value: this.checked
      } }));
      this.$emit('input', {
        value: this.checked,
        key: this.elementId
      });
      this.search();
    },
    getSearchRoute() {
      return TranslationService.transRoute("search-page");
    },
    t(tag) {
      return this.translator.translate(tag)
    },
    searchList() {
      const filterCategory = this.t('form.therapeutic_areas');
      const activeLabels = Array.from(document.querySelectorAll('.filter-therapeutics_card label.active'));
      const activeTexts = activeLabels.map(label => label.querySelector('.checkmark').textContent);
      const filterValue = activeTexts.join(', ');
      GoogleAnalytics.sendFilterEvent(filterValue, filterCategory, filterValue);
    },
    onFormSubmit(ev) {
      const formString = JSON.stringify(this.form);
      const base = btoa(unescape(encodeURIComponent(formString)));
      const url =
        ev.target.action !== undefined
          ? new URL(ev.target.action)
          : new URL(window.location.origin + ev.target.dataset.action);
      url.searchParams.append("q", base);

      this.saveTherapeutics();

      if (this.redirectMode) {
        window.location.href = url;
      } else {
        this.search();
      }
    },
    async search() {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      document.dispatchEvent(new CustomEvent("SearchHomeLoading", { detail: true }));
      let data = await ContentRepository.search(this.form);
      document.dispatchEvent(new CustomEvent("searchHomeLoaded", { detail: data }));
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
    },
    shouldMakeSearchRequest() {
      const data = this.parseURLFormData();
      if (Boolean(data)) {
        data.q = decodeURIComponent(escape(data.q));
        this.form = data;
        this.formSectionKey++;
        document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
        this.search();
      } else {
        this.form = JSON.parse(JSON.stringify(this.defaultForm));
      }
    },
    parseURLFormData() {
      const url = new URLSearchParams(window.location.search);
      const q = url.get("q");
      if (q) {
        return JSON.parse(atob(q));
      }
      return "";
    },
    initStayPage() {
      PageStayController.startStayPageTime("home", 0);
    },
    saveTherapeutics() {
      window.localStorage.setItem('therapeuticsSelected', this.form['qTherapeuticAreas']);
    },
    getTherapeutics() {
      return window.localStorage.getItem('therapeuticsSelected');
    },
  },
};
</script>
