<template>
    <div class="custom-template-agenda-full-calendar">
        <FullCalendar
            ref='fullCalendar'
            :options='watchInfo(calendarOptions)'
            >
            <template v-slot:eventContent='arg'>
                <div class="fc-event-wrapper">
                    <i class="bi bi-circle-fill" :style="{ color: arg.event.extendedProps.categoryColor + ' !important' }"></i>
                    <b>{{ arg.timeText }}</b>
                    <span class="fc-event-category">{{ arg.event.extendedProps.category }}</span>
                    <i v-html="arg.event.title"></i>
                    <span class="fc-event-speaker">{{ arg.event.extendedProps.speakersList}}</span>
                </div>
            </template>
        </FullCalendar>
        <AgendaPopup
            v-if="popupState"
            :clientY="clientY"
            :clientX="clientX"
            :popupEvent="popupEvent"
            :key="popupKey"
            :timezone="timezone"
            :congress="congress"
            :extendedProps = "extendedProps"
            @close="closePopup()"
            @changeIsRegisteredData="changeIsRegisteredData"
        />
    </div>
</template>

<script>
    import FullCalendar from '@fullcalendar/vue3';
    import listPlugin from '@fullcalendar/list';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import AgendaPopup from './AgendaPopup.vue';
    import AgendaFactory from '../../agenda/factories/Agenda.factory';
    import moment from 'moment';
    import $ from 'jquery';
    import GoogleAnalytics from '../../../GoogleAnalytics.js';
    import axios from 'axios';

    export default {

        components: {
            FullCalendar,
            AgendaPopup
        },

        data: function() {
            const titleCustomButtonView = this.getTitleForButtonCustomView();
            return {
                popupEvent: null,
                calendarApi: '',
                viewType: '',
                clientX: 0,
                clientY: 0,
                popupKey: 0,
                popupState: false,
                extendedProps: null,
                calendarOptions: {
                    stickyHeaderDates: false,
                    eventClick: this.handleEventClick,
                    select: this.handleDateSelect,
                    eventsSet: this.handleEvents,
                    initialView: this.activeViewType,
                    slotEventOverlap: false,
                    selectMirror: true,
                    dayMaxEvents: true,
                    allDaySlot: false,
                    slotDuration: '00:30:00',
                    selectable: false,
                    editable: false,
                    weekends: true,
                    validRange: {
                        start: '',
                        end: '',
                    },
                    headerToolbar: {
                        left: 'prev title next',
                        right: this.formatViews(),
                    },
                    listDayFormat: {
                        day: 'numeric',
                        month: 'long',
                        weekday: 'long',
                    },
                    dayPopoverFormat: {
                        weekday: 'short',
                        day: '2-digit',
                    },
                    eventTimeFormat: {
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false,
                        hour12: false
                    },
                    plugins: [
                        listPlugin,
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                    ],
                    views: {
                        timeGridCustomDay: {
                            type: 'timeGrid',
                            duration: { days: this.congress.fullCalendarCustom },
                            buttonText: titleCustomButtonView,
                            displayEventEnd: true
                        },
                        dayGrid: {
                            displayEventEnd: true
                        },
                        timeGrid: {
                            displayEventEnd: true
                        },
                        week: {
                            displayEventEnd: true
                        },
                        day: {
                            displayEventEnd: true
                        },
                        
                    },
                    events: [],
                },
                currentEvents: []
            }
        },

        props : {
          agendaSessions: Object,
          timezone: String,
          activeViewType: String,
          congress: Object
        },

        beforeMount() {
            this.getFullCalendarEvents();
            if(this.dataReady){
                this.calendarOptions.events = this.events;
                if (this.events.length > 0){
                    let startDate = moment(this.calendarOptions.events[0].start).format('YYYY-MM-DD');
                    let endDate = moment(this.calendarOptions.events[this.calendarOptions.events.length-1].end).add(1, 'days').format('YYYY-MM-DD');
                    this.calendarOptions.validRange.start = startDate;
                    this.calendarOptions.validRange.end = endDate;
                    this.calendarOptions.initialDate = startDate;
                }
                this.calendarOptions.initialView = this.activeViewType;
                this.calendarOptions.slotDuration = this.getSlotDuration();
            }

        },

        mounted() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            document.addEventListener("giveMeType", () => {
                if (calendarApi) {
                    localStorage.setItem('fullCalendarActiveViewType', calendarApi.view.type);
                }
            });
            this.hideSingleButton();
        },

        methods: {
            handleWeekendsToggle() {
                this.calendarOptions.weekends = !this.calendarOptions.weekends;
            },

            handleEvents(events) {
                this.currentEvents = events;
            },

            handleEventClick(eventClickInfo){
                this.openPopup(eventClickInfo, eventClickInfo.event.extendedProps);
            },

            async getAgendaData(eventClickInfo) {
                try {
                    const response = await axios.get(
                        '/congresses/agenda/get-live-agenda',
                        { params: { agendaId: eventClickInfo.event.id } }
                    );
                    return response.data.isLive;
                } catch (error) {
                    return false;
                }
            },

            async openPopup(eventClickInfo, props) {
                const checkIsLive = await this.getAgendaData(eventClickInfo);

                this.extendedProps = { ...props, isLive: checkIsLive };
                this.popupState = true;
                this.clientX = eventClickInfo.jsEvent.pageX;
                this.clientY = eventClickInfo.jsEvent.pageY;
                this.popupEvent = eventClickInfo.event;
                this.popupKey++;
                setTimeout(function(){
                  addeventatc.settings({
                      license: window.app.env.USER_CLIENT_ID,
                  });
                  addeventatc.refresh();
                }, 600);
                GoogleAnalytics.sendOpenContentEvent(this.extendedProps.eventTitle, 'Calendar');
            },

            closePopup() {
                this.popupState = false;
                GoogleAnalytics.sendCloseContentEvent(this.extendedProps.eventTitle, 'Calendar');
            },

            watchInfo(calendarOptions) {
                return calendarOptions;
            },

            getFullCalendarEvents(slug) {
              const response = this.agendaSessions;

              let agenda = [];
              let formattedAgenda = [];
              $.each(response.data.data, function(key, value) {
                  const results = value.map(k => AgendaFactory.getFullCalendar(k, slug));
                  agenda.push(results);
              });

              $.each(agenda, function(key, value) {
                  $.each(value, function(key, value2) {
                      formattedAgenda.push(value2);
                  });
              });

              this.dataReady = true;
              this.events = formattedAgenda;
              this.calendarOptions.initialView = this.viewType;
            },

            formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            },
            getTitleForButtonCustomView(){
                let titleCustomButtonView = '4 day';
                switch (this.congress.fullCalendarCustom) {
                    case 2:
                        titleCustomButtonView = '2 day';
                        break;
                    case 3:
                        titleCustomButtonView = '3 day';
                        break;
                    case 5:
                        titleCustomButtonView = '5 day';
                        break;
                    case 6:
                        titleCustomButtonView = '6 day';
                        break;
                }
                return titleCustomButtonView;
            },
            changeIsRegisteredData({isRegisteredData, agendaSessionId}){
              if(this.dataReady && this.events.length > 0){
                for(let i in this.events){
                  let eventToUpdate = this.events[i];
                  if(eventToUpdate.id == agendaSessionId){
                    eventToUpdate.isRegistered = isRegisteredData;
                  }
                }
              }
            },
            getSlotDuration(){
                const slotDurationFromObject = this.congress.congressTemplateConfiguration?.calendar_slot_duration? this.congress.congressTemplateConfiguration.calendar_slot_duration : 30;
                const managedSlotDuration = slotDurationFromObject < 10 ? '0' + slotDurationFromObject : slotDurationFromObject;
                return '00:' + managedSlotDuration + ':00';
            },
            formatViews() {
                const mapping = {
                    "List": "listYear",
                    "Week": "timeGridWeek",
                    "Day": "timeGridDay",
                    "Month": "dayGridMonth",
                    "Custom value": "timeGridCustomDay"
                };

                const data = JSON.parse(this.congress.fullCalendarAvailableViews);

                const formatedViews = data
                    .map(item => mapping[item.name])
                    .filter(Boolean)
                    .join(' ');

                return formatedViews;
            },
            hideSingleButton() {
                let data = JSON.parse(this.congress.fullCalendarAvailableViews);
                if (data.length == 1){
                    $('.fc-' + data[0].id + '-button').hide();
                } 
            }
        }
    }
</script>
