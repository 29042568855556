<template>
<div>
    <iframe :id="'iframe_data-wb-'+id"
    :data-real-id="id" class="embed-responsive-item multimedia-embed-item multimedia-pdf-embed-item document-viewer-iframe"
    :data-url="localizedURL()"
    :src="localizedURL()"
    frameborder="0"
    style="height: 70vh; width: 100%;"
    allowfullscreen
    webkitallowfullscreen
    title=""
    @load="setupAnalytics">
    </iframe>
</div>
</template>

<script>
import GoogleAnalytics from '../../GoogleAnalytics.js';
import axios from 'axios';

export default {
    name: 'DocumentViewer',
    data() {
        return {
            fileurl: '',
            itemId: '',
            pdfsearch:'',
        }
    },
    props: ['id', 'fileuri','search'],
    mounted() {
        this.itemId = this.id;
        this.fileurl = this.fileuri;
        this.pdfsearch = this.search;
        this.initRequestMultimedia();
    },
    methods: {
        localizedURL() {
            return `${this.fileurl}&locale=${window.app.env.locale}`;
        },
        initRequestMultimedia() {
          window['requestMultimediaAction'] = (id) => {
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
            // this.$root.$emit('showCustomLoader', true);
            this.requestMultimedia(id);
          };
        },
        requestMultimedia(id) {

          const url = '/webapi/request-multimedia';
          const fd = new FormData();
          fd.append('multimedia', id);
          axios
            .post(url, fd)
            .then((response) => {
              document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
              // this.$root.$emit('showCustomLoader', false);
              document.dispatchEvent(new CustomEvent("requestedMultimediaDialogue"));
              // this.$root.$emit('requestedMultimediaDialogue');
            })
            .catch((e) => {
              document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
              // this.$root.$emit('showCustomLoader', false);
              console.debug('ERR', e);
            });
        },
        setupAnalytics(){
          const iframe = document.getElementById(`iframe_data-wb-${this.itemId}`);
          if (iframe) {
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            const downloadButton = iframeDocument.getElementById('download');
            const parentUrl = window.location.href;
            if (downloadButton) {
                downloadButton.addEventListener('click', () => {
                  var postTile = 'Document';
                  if (typeof document.getElementsByClassName('multimedia-header__title')[0] !== 'undefined') {
                    postTile = document.getElementsByClassName('multimedia-header__title')[0].innerText;
                  } else if (typeof document.getElementsByClassName('knowledge-journey__content__info__title__unit')[0] !== 'undefined') {
                    postTile = document.getElementsByClassName('knowledge-journey__content__info__title__unit')[0].innerText;
                  }
                  GoogleAnalytics.sendDownloadContentEvent(postTile, parentUrl);
              });
            }
          }
        }
    }
}
</script>
