<template>
    <div class="box-common-size" id="isTextAreaAnswerVisible">
    <div class="left-timer" v-if="expiration_time > 0" :data-poll-id="id"><i class="bi bi-hourglass-split"></i> <span>{{timePoll}}</span></div>
    <div class="live-title"><h4>Live Voting</h4></div>
    <div class="second-title"><h2 class="text-center">{{ question }}</h2></div>
    <div class="third-content">
        <p class="text-center questiontext">Open question</p>
    </div>
    <div class="box-padd-new">
    <div class="text-answer">
        <form action="javascript:void(0);" ref="quesForm" @submit="showSubmit" method="post" class="modal-questions-form" :data-poll-id="id" :data-poll-type="type" :data-poll-expiration="expiration_time" :id="'quesform_' + id">
            <input type="hidden" name="pollId" class="questiontextid" ref="answer" :value="id">
            <input type="hidden" name="type" id="questiontype" ref="answertype" :value="type">
            <textarea placeholder="Enter your answer..." name="answer" id="answer" v-model="answer"></textarea>

        </form>
    </div>
    </div>
    </div>
</template>
<script>
import LivePollsController from "../../../v4/modules/live/LivePollsController"
import $ from 'jquery';

export default {
    props: {
        id : Number,
        type: Number,
        question : String,
        expiration_time : Number
    },
    data:function(){
        return{
            answer: "",
            timePoll: 0
        }
    },
    mounted() {
        let id = '#quesform_' + this.id;
        $(document).on('submit', id.toString() , ev => this.showSubmit(ev));
        this.timePoll = this.expiration_time;
    },
    watch: {
        timePoll: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timePoll--;
                    }, 1000);
                }else{
                    this.popupQuesType();
                }
            },
        }
    },
    methods: {
        async showSubmit(event){
            console.log(900)
            event.preventDefault();
            event.stopPropagation();
            if ( this.answer == '' ) {
                LivePollsController.displayToastifyMessage(2 , "Error" , "Answer is required")
            } else {
                var response = await LivePollsController.setAnswerPollFunc(event);
                if ( response.data.status == 201 ) {
                    LivePollsController.displayToastifyMessage(2 , "Error" , response.data.message)
                } else if ( response.data.status == 200 ) {
                    this.popupQuesType();
                    document.dispatchEvent(new CustomEvent("submitLiveSingle"));
                    // this.$root.$emit('submitLiveSingle', {});
                }
            }

        },
        popupQuesType(){
            this.$parent.$parent.closePopup(this.id);
        }
    }
}
</script>
