<template>
  <section class="hallfame custom-congress-hof" id="halfoffame">
    <div v-if="isLoading">
      {{ t("congresses.loading") }}
    </div>
    <template v-if="!isLoading">
      <div class="container" v-if="hallInfo.length">
        <div class="hallfame-head">
          <h2 class="text-center custom-congress-title">{{t("congress.hall-of-fame")}}</h2>
        </div>

        <SpeakerModel v-show="isModalHallVisible" @close="closeModal">
          <template v-slot:header>&nbsp;</template>
          <template v-slot:body>
            <div class="col-sm-12">
              <div class="speak-user">
                <div class="speaker-img"><img :src="display_pic" @error="imageUrlAlt"></div>
                <div class="user-name-speaker">
                  <h4>{{full_name}}</h4>
                  <p class="infodesc" v-html="bio_info"></p>
                  <div class="like-congress">
                    <ul>
                      <li v-bind:class="(liked_by_user) ? 'active' : ''"><a href="javascript:void(0);" :disabled='isDisabledlike' @click="likeProfile();"><i class="bi bi-hand-thumbs-up"></i> {{likesCount}}</a></li>
                      <li><a href="javascript:void(0);"><i class="bi bi-chat-left"></i> {{commentCount}}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="comment-frame">
                <div class="comment-frame-scroll">
                  <div class="user-frame"  v-for="(itemComment, l) in commentListing" v-bind:key="l" >
                    <h5>{{itemComment.user.name+' '+itemComment.user.surname}}</h5>
                    <label>{{ momentDate(itemComment.created_at) }}</label>
                    <p>{{itemComment.comment}}</p>
                  </div>
                </div>
                <div class="comment-frame-area">
                  <textarea v-model="message" placeholder="Your comment..."></textarea>
                  <button :disabled='isDisabled' @click="sendMsg();" ref="btnSend">Send</button>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>&nbsp;</template>
        </SpeakerModel>

        <div class="hallfame-lister">
          <ul class="text-center custom-congress-categories" id="project-terms">
            <li v-for="(item, k) in tabInfo" v-bind:key="k">
              <div class="checkbox-form">
                <div class="answers">
                  <label class="item">
                    <span>{{item.name}}</span> 
                    <input type="checkbox" class="checkbox_cls dynamic_cls" :id="'checkbox_id_'+item.id" :value="item.id"> 
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </li>
            <li>
              <div class="checkbox-form">
                <div class="answers">
                  <label class="item">
                    <span>All</span> 
                    <input type="checkbox" id="all" value="all" class="checkbox_cls" checked="checked"> 
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <!--main carousel element-->
        <div id="projects-carousel" class="owl-carousel custom-congress-carousel">
          <div class="project" v-bind:class="'by_category_'+itemInfo.category_id" v-for="(itemInfo, j) in hallInfo" v-bind:key="j" @click="showModal(itemInfo);">
            <div class="col-sm-12">
              <div class="speak-user text-center">
                <div class="innavteyear">
                  <p v-if="itemInfo.category_id==2"><i class="bi bi-star"></i> Innovator of the year</p>
                </div>
                <div class="speaker-img"><img :src="parseImage(itemInfo.image)" @error="imageUrlAlt"></div>
                <div class="user-name-speaker">
                  <h4>{{itemInfo.full_name}}</h4>
                  <p>{{itemInfo.speciality}}</p>
                </div>
                <div class="bottom-like-show clearfix">
                  <div class="pull-left"><a href="javascript:void(0);"><i class="bi bi-hand-thumbs-up"></i> <span :id="'item_like_'+itemInfo.id">{{itemInfo.number_likes}}</span></a></div>
                  <div class="pull-right"><a href="javascript:void(0);"><i class="bi bi-chat-square"></i> <span :id="'item_comment_'+itemInfo.id">{{itemInfo.comment_count}}</span></a></div>
                </div>
              </div>
            </div> 
          </div> 
        </div>
        <!--element to hold filtered out items-->
        <div id="projects-hidden" class="hide"></div>
      </div> 
    </template>
  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import HalloffameFactory from './factories/Halloffame.factory'; 
import SpeakerModel from "./ModalComponent.vue";
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
/* import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel'; */
export default {
  name: 'fame',
  components: {
    SpeakerModel
  },
  data() {
    return {
      isDisplay: true,
      isModalHallVisible: false,
      isLoading: true,
      full_name: '',
      display_pic: '',
      bio_info: '',
      institution_info: '',
      hallInfo: [],
      likesCount: 0,
      commentCount: 0,
      tabInfo: [],
      commentListing: [],
      message: '',
      isDisabled: false,
      isDisabledlike: false,
      item_id: 0,
      liked_by_user: false,
      translator: TranslateService.create(),
    }
  },
  setup() {
    const momentDate = (date) => {
      moment.locale('en');
      const m1 = moment(new Date(date));
      return moment.duration(moment().diff(m1)).humanize(true);
    }
    return { momentDate };
  },
  props: ['slug'],
  mounted() {
    this.hallOffame();
  },
  methods:{
    t(tag) {
      return this.translator.translate(tag);
    },
    async hallOffame() {
      const response = await this.getHalloffameData();
      var users = [];
      var tabs = [];
      $.each(response.data.data, function(key, value) {
        const results = value.hf_items.map(k => HalloffameFactory.get(k));
        var obj = {id:value.id,name:value.name};
        users.push(results);
        tabs.push(obj);
      });
      const newArr = Array.prototype.concat(...users);
      this.hallInfo = newArr;
      this.tabInfo = tabs;
      this.isLoading = false;
      if(newArr.length < 1) {
        $('#halfoffame').remove();
        $('#halfoffame_menu').remove();
      }
    },
    getHalloffameData() {
      return axios.get('/congresses/hall-of-fame?slug='+this.slug);
    },
    imageUrlAlt(event) {
      event.target.src = "/images/v4/assets/avatar.svg";
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    showModal(i) {
      this.commentListing = i.list_comments;
      this.full_name = i.full_name;
      this.bio_info = i.bio;
      this.display_pic = i.image;
      this.likesCount = i.number_likes;
      this.commentCount = i.comment_count;
      this.hr_item_id = i.hf_item_id;
      this.item_id = i.id;
      this.isModalHallVisible = true;
      this.liked_by_user = i.liked_by_user;
    },
    closeModal() {
      this.isModalHallVisible = false;
    },
    async sendMsg(){
      if(this.message.length>0 && this.message!='' && this.message.match(/^\s+$/) === null) {
        this.$refs.btnSend.innerText = 'Loading';
        this.isDisabled = true;
        const response = await this.getHallcomment();
        if(response.data.status==200) {
          this.commentCount = (this.commentCount+parseInt(1));
          this.message = '';
          this.hallOffame();
          this.commentListing.push(response.data.comment);
          $('#item_comment_'+this.item_id).text(this.commentCount);
        } else {
          console.log(response.data.message);  
        }
        this.$refs.btnSend.innerText = 'Send';
        this.isDisabled = false;
      }
    },
    getHallcomment() {
      return axios.post('/webapi/events/'+this.slug+'/send-hall-fame-comment', {hf_item_id: this.hr_item_id, comment:this.message});
    },
    moment: function () {
      return moment();
    },
    async likeProfile(id = null) {
      if(!this.liked_by_user) {
        const response = await this.getHalllike(id);
        if(response.data.status==200) {
          if(!this.liked_by_user) {
            this.likesCount = (this.likesCount+parseInt(1)); 
            $('#item_like_'+this.item_id).text(this.likesCount); 
          }
          this.hallOffame();
          this.liked_by_user = true;
        }
      }
    },
    getHalllike(id = null) {
      return axios.post('/webapi/events/'+this.slug+'/send-hall-fame-like', {hf_item_id: id ? id : this.hr_item_id});
    },
    parseImage(img) {
      if (img && img.substr(0,1) != '/') {
        img = '/' + img;
      }
      return img;
    },
  },
/*   filters: {
    moment: function (date) {
      moment.locale('en');
      const m1 = moment(new Date(date));
      return moment.duration(moment().diff(m1)).humanize(true);
    }
  }, */
  updated: function () {
    /* var owl = $('.owl-carousel').owlCarousel({
      dots: true,
      dotsEach: 4,
      nav: true,
      navText: [
      "<i class='fa fa-caret-left'></i>",
      "<i class='fa fa-caret-right'></i>"
      ],
      responsive:{
        0:{
          items:1,
          slideBy:1
        },
        600:{
          items:2,
          slideBy:2
        },
        1000:{
          items:4,
          slideBy:4
        }
      }
    }); 

    $('#project-terms .checkbox_cls').click(function(e) {
        var value = $(this).val();
        if($('.checkbox_cls:checkbox:checked').length==0) {
          $("#all").prop("checked", true);
          value = 'all';
        }
        if (value == 'all') {
          $(".dynamic_cls").prop("checked", false);
          $('#projects-hidden .project').each(function() {
            var owl = $(".owl-carousel").data('owlCarousel');
            var elem = $(this).parent().html(); 
            $('.owl-carousel').owlCarousel('add', elem).owlCarousel('update');
            $(this).parent().remove();
          });
        } else {
          $("#all").prop("checked", false);
          $('#projects-hidden .project.by_category_'+ value).each(function() {
            var owl = $(".owl-carousel").data('owlCarousel');
            var elem = $(this).parent().html();
            $('.owl-carousel').owlCarousel('add', elem).owlCarousel('update');
            $(this).parent().remove();
          });

          $('.dynamic_cls:checkbox:not(:checked)').each(function() {
            var setValue = $(this).val();
            $('#projects-carousel .project.by_category_'+ setValue).each(function() {
              var owl = $(".owl-carousel").data('owlCarousel');
              var targetPos = $(this).parent().index();
              var elem = $(this).parent();
              $(elem).clone().appendTo( $('#projects-hidden') );
              $('.owl-carousel').owlCarousel('remove', targetPos).owlCarousel('update');
            });
          });         
        }

        $('.owl-carousel').owlCarousel().trigger('refresh.owl.carousel'); 
    }); */
  }
}
</script>
