<template>
  <div>
    <div v-if="isLoading">{{t('general.loading')}}...</div>
    <template v-if="!isLoading">
      <ProfileSearch @searchFullTitle="searchFullTitle" ref="profilesearch" :from_type="'bookmark'"></ProfileSearch>
      <div>
        <div v-if="!isMobile()">
          <V4Carousel :slidesToShow="3" :slidesToScroll="3" :name="'profile-bookmarks'" :rowsToShow= this.numRows :isLoading="isLoading" :key="updateDom" v-if="!isLoading">
            <template v-slot:items>
              <V4Card v-for="(item,i) in list" v-bind="item" :key="i" @words="searchKW"></V4Card>
            </template>
          </V4Carousel>
        </div>
        <div v-else>
          <V4Carousel v-bind="{slidesToShow:1, slidesToScroll:1, name: 'profile-bookmarks', isLoading: this.isLoading, rowsToShow:3}"
            :key="updateDom" v-if="!isLoading">
            <template v-slot:items>
              <V4Card v-for="(item,i) in list" v-bind="item" :key="i" @words="searchKW"></V4Card>
            </template>
          </V4Carousel>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DatePicker from '../form/datepicker/DatePicker.vue';
import TranslateService from '../../core/services/translate.service.js';
import BookmarkFactory from './factories/Bookmark.factory';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import V4Carousel from '../carousel/V4Carousel.vue';
import V4Card from '../card/V4Card.vue';
import ProfileSearch from './ProfileSearch.vue';
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'bookmarks',
  props: {
    therapeutics: Object,
    eventTypes: Array,
    redirectMode: Boolean,
    userTherapeuticAreas: Array
  },
  data() {
    return {
      translator : TranslateService.create(),
      start_date: '',
      end_date: '',
      // bus: new Vue(),
      search_title: '',
      updateDom: 0,
      show_more: false,
      show_less: false,
      isLoading: true,
      list: [],
      fulllist: [],
      numRows : 3,
    }
  },
  components: {
    DatePicker,
    V4Carousel,
    V4Card,
    ProfileSearch
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const response = await this.getData();
      const results = response.data.bookmarks.map(k => BookmarkFactory.get(k));
      this.fulllist = results;
      this.list = this.fulllist;
      this.isLoading = false;
      GoogleAnalytics.sendBookmarkCount(this.list.length.toString());
      
      if(this.list.length==0) {
        $("#my_bookmarks").hide();
        $('#my_bookmarks_menu').css('display', 'none');
      }else{
        this.setRows(this.list.length);
      }

      if(this.list.length>9) {
        this.show_more = true;
        this.show_less = false;
      }
    },
    getData() {
      return axios.get('/profile/bookmarks');
    },
    setRows(len){
      this.numRows = len <= 3 ? 1 : len<= 6 ? 2 : 3;
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    action(bool) {
      if(bool) {
        this.show_more = false;
        this.show_less = true;
        $('.hide_block').show();
      } else {
        this.show_more = true;
        this.show_less = false;
        $('.hide_block').hide();
      }
    },
    searchFullTitle(filter_data) {
      this.isLoading = true;
      this.list = this.fulllist;
      if ( filter_data.q != '' ) {
        let that = this;
        this.list =  this.list.filter(function(obj){
          return obj.title.toLowerCase().indexOf(filter_data.q.toLowerCase()) !== -1;
        })
        this.updateDom++;
      }
      if ( filter_data.keywords.length > 0 ) {
        this.list = this.list.filter(function(obj){
          let keywords_list = [];
          keywords_list = obj.shortened_keywords.map(function(key_obj) {
            return key_obj.id.toString();
          });
          let checkKeywordExist = (element) => keywords_list.includes(element);
          return obj.shortened_keywords.length > 0 && filter_data.keywords.every(checkKeywordExist);
        })
        this.updateDom++;
      }
      if ( filter_data.therea.length > 0 ) {
        this.list = this.list.filter(function(obj){
          let therea_list = [];
          therea_list = obj.therapeuticAreas.map(function(key_obj) {
            return key_obj.id.toString();
          });
          let checkThereaExist = (element) => therea_list.includes(element);
          return obj.therapeuticAreas.length > 0 && filter_data.therea.every(checkThereaExist);
        })
        this.updateDom++;
      }
      if ( filter_data.diseases.length > 0 ) {
        this.list = this.list.filter(function(obj){
          let diseases_list = [];
          diseases_list = obj.pathologies.map(function(key_obj) {
            return key_obj.id.toString();
          });
          let checkDiseasesExist = (element) => diseases_list.includes(element);
          return obj.pathologies.length > 0 && filter_data.diseases.every(checkDiseasesExist);
        })
        this.updateDom++;
      }
      if ( filter_data.event_type != ''  ) {
        if ( filter_data.event_type != 'both' ) {
          this.list = this.list.filter(function(obj){
            return obj.congress_type == filter_data.event_type;
          })
          this.updateDom++;
        } else {
          this.updateDom++;
        }
      }
      if ( filter_data.from_date != '' && filter_data.to_date != '' ) {
        if(filter_data.from_date!='Invalid date' && filter_data.to_date!='Invalid date'){
          this.list = this.list.filter(function(obj){
            let startDate = moment(obj.date_start).format("YYYY-MM-DD").toString();
            let endDate = moment(obj.date_end).format("YYYY-MM-DD").toString();
            let startExist = moment(startDate).isBetween(filter_data.from_date.toString(),filter_data.to_date.toString()) || moment(startDate).isSame(filter_data.from_date) || moment(startDate).isSame(filter_data.to_date);
            let endExist = moment(endDate).isBetween(filter_data.from_date.toString(),filter_data.to_date.toString()) || moment(endDate).isSame(filter_data.from_date) || moment(endDate).isSame(filter_data.to_date)
            return (startExist == true)  && (endExist == true);
          });
        }
      }
      document.dispatchEvent(new CustomEvent("SearchCount", { detail: this.list.length }))
      // this.$root.$emit('SearchCount', this.list.length);
      this.updateDom++;
      this.isLoading = false;
      $('#bookmark_block').text(this.t('profile.my-bookmarks')+' ('+this.list.length+')');
    },
    searchKW(wordobj) {
        this.$refs.profilesearch.updateBookmarkkey(wordobj);
    },
    isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }
  }
}
</script>
<style scoped>
    @media only screen and (max-width: 600px) {
        .search{
            height: 120px;
            display: grid !important;
        }
    }
</style>
