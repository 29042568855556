import {BehaviorSubject} from "rxjs";
import {filter} from "rxjs/operators";
import {AmgenSpecialities} from "./amgen-specialities";

export class AmgenOwa extends AmgenSpecialities {

    constructor(config) {

        super(config);

        this._promises = {};
        this._onOwaServiceReady = new BehaviorSubject(false);
        this._onOwaCountries = new BehaviorSubject([]);
        this._onOwaProfessions = new BehaviorSubject([]);
        this._onOwaSpecialities = new BehaviorSubject([]);
    }

    get onOwaServiceReady() {
        return this._onOwaServiceReady.asObservable()
            .pipe(
                filter(ready => ready)
            );
    }

    get onOwaCountries() {
        return this._onOwaCountries.asObservable();
    }

    get onOwaProfessions() {
        return this._onOwaProfessions.asObservable();
    }

    get onOwaSpecialities() {
        return this._onOwaSpecialities.asObservable();
    }

    get owaCountries() {
        return this._onOwaCountries.getValue();
    }

    set owaCountries(value) {
        this._onOwaCountries.next(value || []);
    }

    get owaProfessions() {
        return this._onOwaProfessions.getValue();
    }

    set owaProfessions(value) {
        this._onOwaProfessions.next(value || []);
    }

    get owaSpecialities() {
        return this._onOwaSpecialities.getValue();
    }

    set owaSpecialities(value) {
        this._onOwaSpecialities.next(value || []);
    }

    _setCallbackListeners() {

        super._setCallbackListeners();

        window.addEventListener('message', (event) => {

            if (event.data && typeof event.data === 'string') {
              try {
                JSON.parse(event.data);
              } catch (e) {
                console.debug('ERR', e);
                return;
              }
            }

            let eventData = event.data && typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

            if (eventData) {

                let data = eventData.data;
                let error = eventData.error;

                switch (eventData.action) {

                    case 'owaCountries':

                        if (this._promises.owaCountries) {
                            if (error) this._promises.owaCountries.reject(error);
                            else this._promises.owaCountries.resolve(data);

                            delete this._promises.owaCountries;
                        }

                        if (error) this.owaCountries = [];
                        else this.owaCountries = data;

                        break;
                    case 'owaProfessions':

                        if (this._promises.owaProfessions) {
                            if (error) this._promises.owaProfessions.reject(error);
                            else this._promises.owaProfessions.resolve(data);

                            delete this._promises.owaProfessions;
                        }

                        if (error) this.owaProfessions = [];
                        else this.owaProfessions = data;

                        break;
                    case 'owaSpecialities':

                        if (this._promises.owaSpecialities) {
                            if (error) this._promises.owaSpecialities.reject(error);
                            else this._promises.owaSpecialities.resolve(data);

                            delete this._promises.owaSpecialities;
                        }

                        if (error) this.owaSpecialities = [];
                        else this.owaSpecialities = data;

                        break;
                }
            }
        });
    }

    init() {

        super.init();

        this.onAuthServiceReady.subscribe(() => {

            Promise.all([
                this.getOwaCountries(),
                this.getOwaProfessions(),
                this.getOwaSpecialities()
            ])
                .finally(() => {
                    this._onOwaServiceReady.next(true);
                });
        });
    }

    getOwaCountries() {

        return new Promise((resolve, reject) => {

            this._promises.owaCountries = {resolve, reject};

            this.postMessage('owaCountries');
        });
    }

    getOwaProfessions() {

        return new Promise((resolve, reject) => {

            this._promises.owaProfessions = {resolve, reject};

            this.postMessage('owaProfessions');
        });
    }

    getOwaSpecialities() {

        return new Promise((resolve, reject) => {

            this._promises.owaSpecialities = {resolve, reject};

            this.postMessage('owaSpecialities');
        });
    }
}
