
<template>
    <div class="full-calendar">
        <FullCalendar
            ref='fullCalendar'
            class='demo-app-calendar'
            :options='watchInfo(calendarOptions)'
            >
            <template v-slot:eventContent='arg'>
                <div class="fc-event-wrapper">
                    <i class="bi bi-circle-fill" :style="{ color: arg.event.extendedProps.categoryColor + ' !important' }"></i>
                    <b>{{ arg.timeText }}</b>
                    <span class="fc-event-category">{{ arg.event.extendedProps.category }}</span>
                    <i>{{ arg.event.title }}</i>
                    <span class="fc-event-speaker">{{ arg.event.extendedProps.speaker }}</span>
                </div>
            </template>
        </FullCalendar>
        <AgendaPopup
            v-if="popupState"
            @close="closePopup()"
            :clientY="clientY"
            :clientX="clientX"
            :popupEvent="popupEvent"
            :image="extendedProps.image"
            :categoryColor="extendedProps.categoryColor"
            :title="extendedProps.eventTitle"
            :description="extendedProps.eventDescription"
            :key="popupKey"
            :speakers="extendedProps.speakers"
            :contents="extendedProps.onDemandContent"
            :isLive="extendedProps.isLive"
            :onDemand="extendedProps.isOndemand"
            :isRegistered="extendedProps.isRegistered"
            :timezone="timezone"
            :startDate="extendedProps.eventStart"
            :endDate="extendedProps.eventEnd"
            :id="extendedProps.eventId"
            :minutesToOpenLiveSession="minutesToOpenLiveSession"
            :isBookASeat="extendedProps.isBookASeat"
            :slug="extendedProps.slug"
            :link="extendedProps.link"
            :type="extendedProps.type"
            :cards_behaviour="extendedProps.cards_behaviour"
            :external_redirect="extendedProps.external_redirect"
            :external_url="extendedProps.external_url"
            :is_accessible="extendedProps.is_accessible"
            :external_congress="extendedProps.external_congress"
            :preventOpen="extendedProps.preventOpen"
            :location="this.getLocation(extendedProps.slug, extendedProps.eventId)"
            :minimalisticAgendaPopUp="minimalisticAgendaPopUp"
            :meeting_platform="extendedProps.meeting_platform"
            @changeIsRegisteredData="changeIsRegisteredData"
        />
    </div>
</template>

<script>
    import FullCalendar from '@fullcalendar/vue3'
    import listPlugin from '@fullcalendar/list'
    import dayGridPlugin from '@fullcalendar/daygrid'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import interactionPlugin from '@fullcalendar/interaction'
    import AgendaPopup from './InternalCongressAgendaPopup.vue'
    import AgendaFactory from '../agenda/factories/Agenda.factory';
    import moment from 'moment'
    import TranslationService from '../../core/services/translation.service';
    import $ from 'jquery';

    export default {

        components: {
            FullCalendar,
            AgendaPopup
        },

        data: function() {
            const titleCustomButtonView = this.getTitleForButtonCustomView();
            return {
                popupEvent: null,
                calendarApi: '',
                viewType: '',
                clientX: 0,
                clientY: 0,
                popupKey: 0,
                popupState: false,
                extendedProps: null,
                calendarOptions: {
                    stickyHeaderDates: false,
                    eventClick: this.handleEventClick,
                    select: this.handleDateSelect,
                    eventsSet: this.handleEvents,
                    initialView: this.activeViewType,
                    slotEventOverlap: false,
                    selectMirror: true,
                    dayMaxEvents: true,
                    allDaySlot: false,
                    slotDuration: '00:30:00',
                    selectable: false,
                    editable: false,
                    weekends: true,
                    validRange: {
                        start: '',
                        end: '',
                    },
                    headerToolbar: {
                        left: 'prev title next',
                        right: 'today '+this.availableViews.replace(/['"]+/g, '').slice(1).slice(0,-1)
                    },
                    listDayFormat: {
                        day: 'numeric',
                        weekday: 'long',
                    },
                    dayPopoverFormat: {
                        weekday: 'short',
                        day: '2-digit',
                    },
                    eventTimeFormat: {
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false,
                        hour12: false
                    },
                    plugins: [
                        listPlugin,
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                    ],
                    views: {
                        timeGridCustomDay: {
                            type: 'timeGrid',
                            duration: { days: this.fullCalendarCustom },
                            buttonText: titleCustomButtonView,
                            displayEventEnd: true
                        },
                        dayGrid: {
                            displayEventEnd: true
                        },
                        timeGrid: {
                            displayEventEnd: true
                        },
                        week: {
                            displayEventEnd: true
                        },
                        day: {
                            displayEventEnd: true
                        }
                    },
                    events: [],
                },
                currentEvents: []
            }
        },

        props : {
          agendaSessions: Object,
          timezone: String,
          minutesToOpenLiveSession : Number,
          activeViewType: String,
          fullCalendarCustom: Number,
          availableViews: String,
          congressTemplateConfiguration: Object,
          minimalisticAgendaPopUp: Boolean,
        },

        beforeMount() {
            this.getFullCalendarEvents();
            if(this.dataReady){
                this.calendarOptions.events = this.events;
                if (this.events.length > 0){
                    let startDate = moment(this.calendarOptions.events[0].start).format('YYYY-MM-DD');
                    let endDate = moment(this.calendarOptions.events[this.calendarOptions.events.length-1].end).add(1, 'days').format('YYYY-MM-DD');
                    this.calendarOptions.validRange.start = startDate;
                    this.calendarOptions.validRange.end = endDate;
                    this.calendarOptions.initialDate = startDate;
                }
                this.calendarOptions.initialView = this.activeViewType;
                this.calendarOptions.slotDuration = this.getSlotDuration();
            }

        },

        mounted() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            document.addEventListener("giveMeType", () => {
                if (calendarApi) {
                    localStorage.setItem('fullCalendarActiveViewType', calendarApi.view.type);
                }
            });
/*             this.$root.$on('giveMeType', () => {
                if (calendarApi) {
                    localStorage.setItem('fullCalendarActiveViewType', calendarApi.view.type);
                }
            }) */
        },

        methods: {
            handleWeekendsToggle() {
                this.calendarOptions.weekends = !this.calendarOptions.weekends;
            },

            handleEvents(events) {
                this.currentEvents = events;
            },

            handleEventClick(eventClickInfo){
              this.openPopup(eventClickInfo, eventClickInfo.event.extendedProps);
            },

            openPopup(eventClickInfo, props) {
                this.extendedProps = props;
                this.popupState = true;
                this.clientX = eventClickInfo.jsEvent.pageX;
                this.clientY = eventClickInfo.jsEvent.pageY;
                this.popupEvent = eventClickInfo.event;
                this.popupKey++;
                setTimeout(function(){
                  addeventatc.settings({
                      license: window.app.env.USER_CLIENT_ID,
                  });
                  addeventatc.refresh();
                }, 600);
            },

            closePopup() {
                this.popupState = false;
            },

            watchInfo(calendarOptions) {
                return calendarOptions;
            },

            getFullCalendarEvents(slug) {
              const response = this.agendaSessions;

              let agenda = [];
              let formattedAgenda = [];
              $.each(response.data.data, function(key, value) {
                  const results = value.map(k => AgendaFactory.getFullCalendar(k, slug));
                  agenda.push(results);
              });
            
              $.each(agenda, function(key, value) {
                  $.each(value, function(key, value2) {
                      formattedAgenda.push(value2);
                  });
              });

              this.dataReady = true;
              this.events = formattedAgenda;
              this.calendarOptions.initialView = this.viewType;
            },

            getLocation(slug, id){
              let agendaURL = '';
              agendaURL = TranslationService.transRoute('event-detail-live', {
                slug: slug,
                agendaSessionId: id
              });
              return window.app.env.APP_URL+agendaURL;
            },

            formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            },
            getTitleForButtonCustomView(){
                let titleCustomButtonView = '4 day';
                switch (this.fullCalendarCustom) {
                    case 2:
                        titleCustomButtonView = '2 day';
                        break;
                    case 3:
                        titleCustomButtonView = '3 day';
                        break;
                    case 5:
                        titleCustomButtonView = '5 day';
                        break;
                    case 6:
                        titleCustomButtonView = '6 day';
                        break;
                }
                return titleCustomButtonView;
            },
            changeIsRegisteredData({isRegisteredData, agendaSessionId}){
              if(this.dataReady && this.events.length > 0){
                for(let i in this.events){
                  let eventToUpdate = this.events[i];
                  if(eventToUpdate.id == agendaSessionId){
                    eventToUpdate.isRegistered = isRegisteredData;
                  }
                }
              }
            },
            getSlotDuration(){
                const slotDurationFromObject = this.congressTemplateConfiguration?.calendar_slot_duration? this.congressTemplateConfiguration.calendar_slot_duration : 30;
                const managedSlotDuration = slotDurationFromObject < 10 ? '0' + slotDurationFromObject : slotDurationFromObject;
                return '00:' + managedSlotDuration + ':00';
            }
        }

    }
</script>
