<template>
    <ModalComponent v-show="showModal" @close="closeModal" id="content-test-modal">
        <template v-slot:header>&nbsp;</template>
        <template v-slot:body v-if="test.questions">
            <div v-if="Object.keys(test).length > 0" class="test__content__info col">
                <form class="test__content__test__container test-test-form-js" id="tests-form">
                    <div id="testCarousel" class="test__content__test__container__carousel carousel slide" data-interval="false">
                        <ol class="carousel-indicators">
                            <li v-for="(q, i) in test.questions"
                                :key="i"
                                :data-type="q.type"
                                data-target="#testCarousel" :data-slide-to="[i]" :class="[i == 0 ? 'active' : '']" style="background-color: #2DBCB6;"></li>
                        </ol>
                        <div class="carousel-inner" style="margin-left: 3%">
                            <div class="carousel-item"
                                v-for="(q, i) in test.questions"
                                :key="i"
                                :data-type="q.type"
                                :class="[i == 0 ? 'active' : '']">
                                <div class="test__content__test__container__question test-test-question-js test-question">
                                    <template v-if="q.type == 1">
                                        <div class="test__content__test__container__question__header">
                                            <span class="test-badge test-badge--lower test-badge--outlined">Single question answer</span>
                                        </div>
                                        <div class="test__content__test__container__question__container">
                                            <p>{{ q.title }}</p>
                                            <div v-for="(a, j) in q.answers" :key="j" class="form-check">
                                                <input required class="form-check-input answer" :name="q.id" type="radio" :value="a.id" :id="`test-input-${a.id}`" />
                                                <label class="form-check-label" :for="`test-input-${a.id}`">
                                                    {{ a.title }}
                                                </label>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="q.type == 2">
                                        <div class="test__content__test__container__question__header">
                                            <span class="test-badge test-badge--lower test-badge--outlined">Multi-answer question</span>
                                        </div>
                                        <div class="test__content__test__container__question__container">
                                            <p>{{ q.title }}</p>
                                            <div v-for="(a, j) in q.answers" :key="j" class="form-check">
                                                <input required class="form-check-input answer" :name="q.id" type="checkbox" :value="a.id" :id="`test-input-${a.id}`"/>
                                                <label class="form-check-label" :for="`test-input-${a.id}`">
                                                    {{ a.title }}
                                                </label>    
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-indicators" style="right: -15%; bottom: 15%;">
                            <button class="v4-btn-primary test__test__send ml-auto test-test-send-js" form="tests-form" disabled style="margin-right: 8%">
                                <p>{{ t('general.submit') }}</p>
                            </button>
                        </div>
                        <a class="carousel-control-prev" href="#testCarousel" role="button" data-slide="prev" style="width: 6%;">
                            <div class="knowledge-journey__content__test__container__question__arrows">
                                <i class="bi bi-chevron-left"></i>
                            </div>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#testCarousel" role="button" data-slide="next" style="width: 10%;">
                            <div class="knowledge-journey__content__test__container__question__arrows">
                                <i class="bi bi-chevron-right"></i>
                            </div>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </form>
            </div>
        </template>
        <template v-slot:footer>&nbsp;</template>
        <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
    </ModalComponent>
</template>

<script>
import formUtils from '../../core/services/formUtils.service';
import TranslateService from '../../core/services/translate.service';
import ConfirmDialogue from '../agenda/ConfirmDialogue.vue';
import ModalComponent from '../internal-congress/ModalComponent.vue';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'ContentTestModal',
  props: {
    test: Object,
    show: Boolean,
    showTestBefore: Boolean,
    showTestAfter: Boolean,
    initModalType: Number,
    modelType: String,
    modelId: Number,
    beforeTestAlreadyAnswered: Boolean,
    afterTestAlreadyAnswered: Boolean
  },
  components: {
    ModalComponent,
    ConfirmDialogue
  },

  data() {
    return {
      selectors: {
        test: 'test__content__test__container__carousel',
        testForm: 'test-test-form-js',
        testSend: 'test-test-send-js',
        answer: '.answer',
        question: '.test-question'
      },
      translator: TranslateService.create(),
      showModal: this.show ?? false,
      modalType: this.initModalType ?? 1,
    };
  },

  created() {
  },

  mounted() {
    if (Object.keys(this.test).length) {
      if (this.test.questions) {
        this.initTestContent();
      }
    }

    this._showTestBefore = this.showTestBefore;
    this._showTestAfter = this.showTestAfter;
    this._beforeTestAlreadyAnswered = this.beforeTestAlreadyAnswered;
    this._afterTestAlreadyAnswered = this.afterTestAlreadyAnswered;

    $('.test-modal-button-div').prop('hidden', false);
    if (this._showTestBefore) {
        this.showModal = true;
        this.modalType = 1;
        $('#showTestModalBefore').prop('hidden', false);
        GoogleAnalytics.sendOpenContentEvent('Test', 'Modal');
    } else if (this._beforeTestAlreadyAnswered && !this._afterTestAlreadyAnswered) {
        $('#showTestModalBefore').prop('hidden', false);
        $('.js-before-test-edit').prop('hidden', true);
        $('.js-before-test-check').prop('hidden', false);
        $('.js-before-test-text').html('Quiz Completed');
    }
    if (this._showTestAfter && !this._showTestBefore) {
        this.modalType = 2;
        if (!this._beforeTestAlreadyAnswered) {
            $('#showTestModalAfter').prop('hidden', false);
        }
    } else if (this._afterTestAlreadyAnswered) {
        $('#showTestModalAfter').prop('hidden', false);
        $('.js-after-test-edit').prop('hidden', true);
        $('.js-after-test-check').prop('hidden', false);
        $('.js-after-test-text').html('Quiz Completed');
    }

    document.addEventListener("showTestModal", (event) => {
        if (event.type == 1 && typeof $('#showTestModalBefore').attr("disabled") === 'undefined') {
            this.showModal = true;
            this.modalType = event.type;
        } else if (event.type == 2 && typeof $('#showTestModalAfter').attr("disabled") == 'undefined') {
            this.showModal = true;
            this.modalType = event.type;
        }
    });

/*     this.$root.$on('showTestModal', (type) => {
        if (type == 1 && typeof $('#showTestModalBefore').attr("disabled") === 'undefined') {
            this.showModal = true;
            this.modalType = type;
            GoogleAnalytics.sendOpenContentEvent('Test', 'Modal');
        } else if (type == 2 && typeof $('#showTestModalAfter').attr("disabled") == 'undefined') {
            this.showModal = true;
            this.modalType = type;
            GoogleAnalytics.sendOpenContentEvent('Test', 'Modal');
        }
    }); */

    this.initOnDocumentEnd();
    this.initOnVideoEnd();
  },

  methods: {
    initTestContent() {
        let self = this;

        $('.' + this.selectors.testSend).click(function (e) {
            e.preventDefault();
            const url = '/test/sendtest';
            // we get the formData form the FormUtils
            const formData = formUtils.getFormData($('.' + self.selectors.testForm));

            // we append specific data for this form
            formData.append('testId', self.test.id);
            formData.append('model_type', self.modelType);
            formData.append('model_id', self.modelId);
            formData.append('type', self.modalType);
            axios
                .post(url, formData)
                .then((response) => {
                    self.showModal = false;
                    GoogleAnalytics.sendCloseContentEvent('Test', 'Modal');
                })
                .catch((e) => {
                    console.debug('ERR', e);
                });

            $('.show-test-modal-button').prop('disabled', true);
            $('.show-test-modal-button').attr('disabled', true);
            if (self.modalType == 1) {
                self._beforeTestAlreadyAnswered = 1;
                $('.js-before-test-edit').prop('hidden', true);
                $('.js-before-test-check').prop('hidden', false);
                $('.js-before-test-text').html('Quiz Completed');
                self._showTestBefore = false;
            } else if (self.modalType == 2) {
                self._afterTestAlreadyAnswered = 1;
                $('.js-after-test-edit').prop('hidden', true);
                $('.js-after-test-check').prop('hidden', false);
                $('.js-after-test-text').html('Quiz Completed');
                self._showTestAfter = false;
            }
        });

        $('.selectpicker').selectpicker();

        $('body').on('change keyup', this.selectors.answer, (e) => this.refreshAnsweredQuestions());
    },

    t(tag) {
      return this.translator.translate(tag);
    },

    refreshAnsweredQuestions(e) {
        let answeredQuestions = 0;
        let questions = $(this.selectors.question).toArray();

        questions.forEach((question) => {
            let isAnswered;
            let answers = $(question).find(this.selectors.answer).toArray();
            isAnswered = answers.some((answer) => {
                return answer.checked;
            });

            if (isAnswered) {
                answeredQuestions++;
            }
        });

        if (answeredQuestions == questions.length) {
            $('.' + this.selectors.testSend).removeAttr('disabled');
        } else {
            $('.' + this.selectors.testSend).attr('disabled', true);
        }

        document.dispatchEvent(new CustomEvent("changeAnsweredQuestions", { detail: answeredQuestions }));
        // this.$root.$emit('changeAnsweredQuestions', answeredQuestions);
    },

    resetQuestionsTest(e) {
        $('.' + this.selectors.testSend).attr('disabled', true);
        $(this.selectors.answer).prop('checked', false);
        $('#testCarousel').children('.carousel-inner').children('.carousel-item.active').removeClass('active');
        $('#testCarousel').children('.carousel-inner').children('.carousel-item:first').addClass('active');
        $('.carousel-indicators').children().removeClass('active');
        $('.carousel-indicators').children(':first').addClass('active');
    },

    initOnDocumentEnd() {
      window['testDocEnd'] = (id) => {
        this.finishUnit();
      };
    },

    initOnVideoEnd() {
        document.addEventListener("testVideoEnd", () => {
            this.finishUnit();
        });
      /* this.$root.$on('testVideoEnd', (data) => {
        this.finishUnit();
      }); */
    },

    finishUnit() {
        if (this.showTestAfter) {
            this.resetQuestionsTest();
            this.showModal = true;
            this.modalType = 2;
            GoogleAnalytics.sendOpenContentEvent('Test', 'Modal');
        }
    },

    closeModal() {
        this.showModal = false;
        if (this.modalType == 1 && this._showTestBefore) {
            $('.test-modal-button-div').prop('hidden', false);
            $('#showTestModalAfter').prop('hidden', true);
            $('#showTestModalBefore').prop('hidden', false);
            $('#showTestModalBefore').prop('disabled', false);
            $('#showTestModalBefore').removeAttr('disabled');
        } else if (this.modalType == 2 && this._showTestAfter) {
            $('.test-modal-button-div').prop('hidden', false);
            $('#showTestModalBefore').prop('hidden', true);
            $('#showTestModalAfter').prop('hidden', false);
            $('#showTestModalAfter').prop('disabled', false);
            $('#showTestModalAfter').removeAttr('disabled');
        }
        GoogleAnalytics.sendCloseContentEvent('Test', 'Modal');
    },
  }
};
</script>
