<template>
    <section v-if="dataLoaded" class="term-conditions-container">
        <div class="register-separator-xl mb-4 mt-4" v-for="(question, index) of questions"
            :key="'question-' + question.id">
            <div class="register-input-group" v-if="question.type === 1"
                :hidden="questionResponseHiddenGrid[question.id]">
                <label :for="'question-' + index" v-html="question.field"></label>
                <select @change="checkTermAndConditions" :placeholder="question.field"
                    v-model="form.questionResponses[question.id]" :id="'question' + index" :name="'question' + index"
                    class="selectpicker" :title="t('satellital.nothing-selected')">
                    <option v-for="option of question.options" :key="option.id" :value="option.id">
                        <span v-html="option.option"></span>
                    </option>
                </select>
            </div>
            <div class="register-input-group" v-if="question.type === 2"
                :hidden="questionResponseHiddenGrid[question.id]">
                <label :for="'question-' + index">{{ question.field }}</label>
                <textarea @change="checkTermAndConditions" :placeholder="question.field"
                    v-model="form.questionResponses[question.id]" :id="'question' + index"
                    class="satellital-textarea text-area" :name="'question' + index">
            </textarea>
            </div>
        </div>

        <div class="register-checkbox-group" v-for="(term, index) of termsAndConditions" :key="'terms-' + term.id">
            <CheckboxHtml @change="checkTermAndConditions" v-model="form.termsChecked[term.id]" :disabled="false"
                :item-id="'term' + index" :using-popup="term.show_using_popup" :title="term.title" :text="term.terms">
                <div v-html="term.show_using_popup ? term.title : term.terms"></div>
            </CheckboxHtml>
        </div>

        <div v-if="!loggedUser || !newsletterAccepted">
            <div class="register-checkbox-group">
                <CheckboxHtml @change="checkTermAndConditions" :item-id="'newsletter'" :disabled="false"
                    v-model="form.newsletter">
                    {{ t('pre-home.newsletter') }}
                </CheckboxHtml>
            </div>
        </div>
    </section>
</template>
<script>
import TranslateService from '../../../core/services/translate.service.js';
import CheckboxHtml from '../../form/checkbox/CheckboxHtml.vue';
import { ref, reactive } from 'vue';
import $ from 'jquery';
import axios from 'axios';

export default {
    components: {
        CheckboxHtml
    },
    setup() {
        const questions = ref([]);
        const questionResponseHiddenGrid = reactive({});
        const termsAndConditions = ref([]);

        const form = reactive({
            questionResponses: {},
            termsChecked: {},
            newsletter: false,
            validStatus: false,
        });
        function initQuestions() {
            questions.value.forEach(question => {
                questionResponseHiddenGrid[question.id] = !!questions.value[question.id];
                form.questionResponses[question.id] = '';
            });
        }
        function initTermAndConditions() {
            termsAndConditions.value.forEach(term => {
                form.termsChecked[term.id] = !!termsAndConditions.value[term.id];
            });
        }

        initQuestions();
        initTermAndConditions();

        return {
            questions,
            termsAndConditions,
            questionResponseHiddenGrid,
            form,
            initQuestions,
            initTermAndConditions,
        };
    },
    props: {
        loggedUser: Boolean,
        country: Number,
        slug: {
            type: String,
            required: false
        },
        userId: {
            type: Number,
            required: false
        },
        newsletterAccepted: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            translator: TranslateService.create(),
            emptyTermsAndQuestions: false,
            dataLoaded: false,
        }
    },
    beforeMount() {
        this.getTermsAndConditions();
    },
    watch: {
        dataLoaded(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    $('.selectpicker').selectpicker('refresh');
                });
            }
        },
    },
    methods: {
        t(tag) {
            return this.translator.translate(tag);
        },
        getTermsAndConditions() {
            axios.get('/get-tcs-and-questions',
                {
                    params: {
                        country: this.country,
                        slug: this.slug ?? null,
                        loggedUser: this.loggedUser,
                        userId: this.userId ?? null,
                    }
                })
                .then(response => {
                    this.termsAndConditions = response.data.termConditions;
                    this.initTermAndConditions();
                    this.questions = response.data.extraQuestions;
                    this.initQuestions();
                    this.checkIfEmptyTermsAndQuestions();
                    this.checkTermAndConditions();
                    this.dataLoaded = true;
                }).catch(error => {
                    console.error(error);
                });
        },
        openLink(url) {
            window.open(url, '_blank');
        },
        checkIfEmptyTermsAndQuestions() {
            this.emptyTermsAndQuestions = this.termsAndConditions.length === 0 && this.questions.length === 0;
        },
        checkTermAndConditions() {

            this.form.validStatus = false;
            
            if (this.emptyTermsAndQuestions) {
                this.form.validStatus = true;
            }
            
            if(Object.values(this.form.questionResponses).every(value => value.length !== 0) && Object.values(this.form.termsChecked).every(state => state)){
                this.form.validStatus = true;
            }else {
                this.form.validStatus = false;
            }

            document.dispatchEvent(new CustomEvent("termConditionsAndQuestions", {
                detail: {
                    form: this.form,
                    termsAndConditions: this.termsAndConditions
                }
            }));
        },
        initializeSelectElements() {
            const selects = this.$el.querySelectorAll('select');
            selects.forEach(select => {
                if (!select.value) {
                    select.selectedIndex = -1;
                }
            });
        },
    }
}
</script>
