export default class LoginUtils {

    static doBasicPasswordCheck(t, password, repeatPassword) {
        if (password.length < 8) {
          return {
            showPasswordErrorWarning: true,
            passwordMessage: t('pre-home.errors.min_char'),
          };
        }
        if(!password.match(/(?=.*[a-z])/)) {
          return {
            showPasswordErrorWarning: true,
            passwordMessage: t('pre-home.errors.lowercase'),
          };
        }
        if(!password.match(/(?=.*[A-Z])/)) {
          return {
            showPasswordErrorWarning: true,
            passwordMessage: t('pre-home.errors.uppercase'),
          };
        }
        if(!password.match(/(?=.*[0-9])/)) {
          return {
            showPasswordErrorWarning: true,
            passwordMessage: t('pre-home.errors.number'),
          };
        }
        if (password !== repeatPassword) {
          return {
            showPasswordErrorWarning: true,
            passwordMessage: t('pre-home.errors.match'),
          };
        }
        return {
          showPasswordErrorWarning: false,
          passwordMessage: ""
        };
    }

    static doAdvancedPasswordCheck(password, repeatPassword) {
        return {
          min_char: password.length >= 8,
          lowercase: !!password.match(/(?=.*[a-z])/),
          uppercase: !!password.match(/(?=.*[A-Z])/),
          number: !!password.match(/(?=.*[0-9])/),
          match: password === repeatPassword,
        }
    }

}