<template>
  <div class="carousel_block">
    <div v-if="isLoading">
      {{t('general.loading')}}...
    </div>

    <div v-if="!isLoading">
      <div v-if="!isMobile()">
        <!-- DESKTOP VERSION -->
        <V4Carousel  :slidesToShow="3" :slidesToScroll="3" :name="'material'">
          <template v-slot:items>
            <div v-for="(item, i) in list" v-bind:key="i">
              <V4Card v-bind="item"></V4Card>
            </div>
          </template>
        </V4Carousel>
      </div>
      <div v-else>
        <!-- MOBILE VERSION -->
        <V4Carousel  :slidesToShow="1" :slidesToScroll="1" :name="'material'">
          <template v-slot:items>
            <div v-for="(item, i) in list" v-bind:key="i">
              <V4Card v-bind="item"></V4Card>
            </div>
          </template>
        </V4Carousel>
      </div>
      <p class="no_records" v-if="isRecord">{{t("general.no-results")}}</p>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import ContentFactory from '../../modules/content/factories/Content.factory';
import V4Carousel from '../carousel/V4Carousel.vue';
import V4Card from '../card/V4Card.vue';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'WebinarCasesCarousel',
  components: {
    V4Carousel,
    V4Card
  },
  props: {
    slug: String
  },
  data() {
    return { 
      isRecord: false,
      isLoading: true,
      list: [],
      translator: TranslateService.create()
    }
  },
  mounted() {
    this.fetch(this.slug);  
  },

  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async fetch() {
      const response = await this.getData();
      const results = response.data.data.map(k => ContentFactory.get(k));
      this.list = results;
      this.isLoading = false; 
      if(this.list.length==0){
        this.isRecord = true;
        $("#materials").hide();
      }
    },
    getData() {
      return axios.get(`/congresses/multimedia?slug=${this.slug}&exclude-agenda=true`);
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    } 
  }
}
</script>
