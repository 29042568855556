<template>
<section>    
    <div class="welcome-container external-welcome-container">
        <div class="carousel-container container section-container v4-event__container">   
            <div class="row summit-home-carousel" id="welcome">  
                <div class="home-header-current-event col-12 col-md welcome-full-text">
                    <img v-if="checkHeaderLogo() && logoSize == 'small'" :src="parseImage(this.image)" class="text-center v4-event__internal__logo small" alt="event-logo"/>
                    <img v-if="checkHeaderLogo() && logoSize == 'medium'" :src="parseImage(this.image)" class="text-center v4-event__internal__logo medium" alt="event-logo"/>
                    <img v-if="checkHeaderLogo() && logoSize == 'large'" :src="parseImage(this.image)" class="text-center v4-event__internal__logo large" alt="event-logo"/>
                    <div class="current-event-text" v-if="welcome">{{welcome}}</div>
                    <div class="current-event-banner" :data-title="title" v-if="title && !hideEventTitle" v-html="title"></div>
                    <span class="current-event-date" v-if="!readmore" v-html="substring"></span>
                    <span class="current-event-date" v-if="readmore" v-html="description"></span>
                    
                    <div>
                        <template v-if="showread">
                            <a v-if="!readmore" href="javascript:void(0);" class="congress-atag v4-action-link" v-on:click="hideShortdesc">{{t("congress.read-more")}}</a>
                        </template>
                        <a v-if="readmore" href="javascript:void(0);" class="webiner-atag v4-action-link" v-on:click="showShortdesc">{{t("congress.read-less")}}</a>
                    </div>
                    <div>
                        <a @click="checkAccessCode()" target="_blank" class="v4-btn-primary" v-if="btn_link">{{btn_text}}</a>
                        <a @click="showLeavingModal(btn_a_link)" target="_blank" class="v4-btn-primary d-none" id="agenda-external-btn-header" v-if="btn_a_link">{{btn_a_text}}</a>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</section>

</template>

<script>
import TranslateService from '../../../core/services/translate.service';
import PageStayController from '../../../page-stay/PageStayController';
    export default {
        props: {
            id: Number,
            welcome: String,
            title: String,
            image: String,
            description: String,
            shortdesc:String,
            btn_text: String,
            btn_link: String,
            btn_a_text: String,
            btn_a_link: String,
            hasAccessCode: Boolean,
            accessCode: String,
            hideEventTitle : Boolean,
            logoSize : String
        },
        data() {
            return { 
                readmore: false,
                substring: '',
                showread: '',
                translator: TranslateService.create(),
                accessCodeActive: this.hasAccessCode ?? false,
                userCode: this.accessCode ?? null
            }
        },
        mounted() {
            var dots = (this.description.length>300) ? ' ...' : '';
            var congressRead = false;
            if(this.description.length>300){
                congressRead = true;
            }
            this.substring = this.description.substring(0, 300)+dots,
            this.showread = congressRead;
            this.initStayPage();
        },    
        methods:{
            t(tag) {
              return this.translator.translate(tag);
            },
            hideShortdesc: function (event) {
                 this.readmore = true;
            },
            showShortdesc: function (event) {
                 this.readmore = false;
            },
            checkHeaderLogo() {
                return this.image && this.image != 'unset' && this.image != '';
            },
            parseImage(img) {
                if (img && img.substr(0,1) != '/') {
                    img = '/' + img;
                }
                return img;
            },
            checkAccessCode() {
                if(this.accessCodeActive) {
                    this.showAccessCodeModal();
                } else {
                    this.showLeavingModal(this.btn_link);        
                }
            },
            showLeavingModal(url) {
                document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: url }));
                // this.$root.$emit('leavingMMPModal', url);
            },
            showAccessCodeModal() {
                let params = {
                    url: this.btn_link,
                    code: this.userCode,
                    title: this.title
                }
                document.dispatchEvent(new CustomEvent("accessCodeModal", { detail: params }));
                // this.$root.$emit('accessCodeModal', params);
            },
            initStayPage() {
                PageStayController.startStayPageTime('congress', this.id);
            },
        }
    }
</script>
