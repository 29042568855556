import UtilsHelp from '../../Utils';
const Utils = {
  modalTypes: {
    modalResponse: {
      selector: '#modal-response'
    },
    modalAnswers: {
      selector: '#modal-answers'
    },
    modalQuestions: {
      selector: '#modal-questions'
    },
  },
  modalContentRenderEl: {
    selector: '.content-render'
  },

  openModal(modalId, html = '') {
    let modal = $(modalId);
    modal.modal('show');
    modal.find('.spinner-render').hide();
    var modalContentRenderElSelector = document.getElementsByClassName(this.modalContentRenderEl.selector);
    modalContentRenderElSelector.innerHTML(Utils.escapeHtml(html));
    modal.find(this.modalContentRenderEl.selector).show();
  },

  hideModal(modalId) {
    let modal = $(modalId);
    modal.modal('hide');
    modal.find('.spinner-render').show();
    modal.find(this.modalContentRenderEl.selector).hide();
    modal.find(this.modalContentRenderEl.selector).html('');
  },
};
export default Utils;
