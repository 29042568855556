import EventSubscriptionCarousel from '../objects/v4/modules/events/event-subscriptions-carousel/EventSubscriptionCarousel.vue';
import EventSearch from '../objects/v4/modules/events/event-search/EventSearch.vue';
import TrendingKeywordContent from '../objects/v4/modules/trending-keyword-content/TrendingKeywordContent.vue';

const vueComponents = [
    ['EventSubscriptionCarousel', EventSubscriptionCarousel],
    ['EventSearch', EventSearch],
    ['TrendingKeywordContent', TrendingKeywordContent],
]

export default vueComponents