<template>
  <section class="container symposia-container new-slider-arrow">
    <div v-if="isLoading">
      {{ t("congresses.loading") }}
    </div>
    <template v-if="!isLoading">
      <div class="webinar-cases__tabsheet__container webinar-cases__tabsheet__container">
        <div class="low-row">
          <div v-if="list && list.length">
            <V4Carousel
              v-bind="{
                slidesToShow: 1,
                slidesToScroll: 1,
                name: `imageslides-${symposiaid}`,
                isLoading: false,
                isResponsive: true,
              }"
            >
              <template v-slot:items>
                <div v-for="(imageitem, i) in list" :key="i">
                  <div>
                    <p class="date-text text-left">{{ formattedDate }}</p>
                    <h2 class="slider-video-title text-left one">{{ imageitem.title }}</h2>
                  </div>
                  <div class="webinar-cases__description col-md-7 col-sm-12">
                    <div class="videolists">
                      <div class="videolists-cont">
                        <a @click="redirectMultimedia(imageitem.url, i, true)">
                          <div class="symposia-play-btn">
                            <em class="bi bi-play-circle"></em>
                          </div>
                          <video :poster="parseImage(imageitem.image)" @error="showDefaultImage($event)">
                            <source v-if="imageitem.url" :src="parseImage(imageitem.url)" type="video/mp4" />
                          </video>
                        </a>
                      </div>
                    </div>
                    <div class="bottom-descs text-left d-none" v-html="imageitem.description"></div>
                  </div>
                  <div class="speaker_block webinar-cases__right-panel__panel col-md-5 col-sm-12">
                    <div class="col virtual-cases__tabsheet">
                      <ul class="virtual-cases__tabsheet__list text-left">
                        <li
                          v-if="imageitem.speakers.length > 0"
                          @click="changeTabsheet"
                          :data-tabid="`${symposiaid}-${i}`"
                          :data-tabsheet="`tabsheet-1-${symposiaid}-${i}`"
                          :class="
                            `virtual-cases__tabsheet__button-${symposiaid}-${i}` +
                            (imageitem.speakers.length > 0 ? ' virtual-cases__tabsheet__button--active' : '')
                          "
                        >
                          {{ t("congress.speakers") }}
                        </li>
                        <li
                          v-if="presentations.length"
                          @click="changeTabsheet"
                          :data-tabid="`${symposiaid}-${i}`"
                          :data-tabsheet="`tabsheet-2-${symposiaid}-${i}`"
                          :class="
                            `virtual-cases__tabsheet__button-${symposiaid}-${i}` +
                            (imageitem.speakers.length <= 0 ? ' virtual-cases__tabsheet__button--active' : '')
                          "
                        >
                          {{ t("congresses.presentation") }}
                        </li>
                      </ul>
                      <div
                        v-if="presentations.length"
                        :id="`tabsheet-2-${symposiaid}-${i}`"
                        :class="`virtual-cases__tabsheet__container-${symposiaid}-${i}`"
                        :style="{ display: imageitem.speakers.length <= 0 ? 'block' : 'none' }"
                      >
                        <ul class="speakers_lists presentation_lists" v-if="presentations.length">
                          <li class="viewed" v-for="(presentitem, prei) in presentations" :key="prei">
                            <a @click="redirectMultimedia(presentitem.url, prei, false)">
                              <span class="title-text secondary-color text-bold"
                                ><span><em class="bi bi-file-earmark-slides"></em></span
                                ><span class="list-text">{{ presentitem.title }}</span></span
                              ></a
                            >
                          </li>
                        </ul>
                      </div>
                      <div
                        v-if="imageitem.speakers.length > 0"
                        :id="`tabsheet-1-${symposiaid}-${i}`"
                        :class="`virtual-cases__tabsheet__container-${symposiaid}-${i} virtual-cases__tabsheet__container agendaSpeakers`"
                        :style="{ display: imageitem.speakers.length > 0 ? 'block' : 'none' }"
                      >
                        <ul v-if="imageitem.speakers.length > 0" class="tab-content-scroll">
                          <li v-for="(item, i) in imageitem.speakers" :key="i">
                            <AgendaSpeakersItem v-bind="item" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </V4Carousel>
          </div>
          <div v-else>
            <div>
              <p class="date-text text-left">{{ formattedDate }}</p>
              <h2 class="slider-video-title text-left">{{ symposianame }}</h2>
            </div>
            <div class="webinar-cases__description col-md-7 col-sm-12">
              <div class="videolists">
                <div class="videolists-cont">
                  <img :src="parseImage(thumbimg)" @error="showDefaultImage($event)" alt="" />
                </div>
              </div>
              <div class="bottom-descs text-left d-none" v-html="symposiadesc"></div>
            </div>
            <div class="speaker_block webinar-cases__right-panel__panel col-md-5 col-sm-12">
              <div class="col virtual-cases__tabsheet">
                <ul class="virtual-cases__tabsheet__list text-left">
                  <li
                    v-if="speakers && speakers.length > 0"
                    @click="changeTabsheet"
                    :data-tabid="`${symposiaid}`"
                    :data-tabsheet="`tabsheet-1-${symposiaid}`"
                    :class="
                      `virtual-cases__tabsheet__button-${symposiaid}` +
                      (speakers.length > 0 ? ' virtual-cases__tabsheet__button--active' : '')
                    "
                  >
                    {{ t("congress.speakers") }}
                  </li>
                  <li
                    v-if="presentations && presentations.length > 0"
                    @click="changeTabsheet"
                    :data-tabid="`${symposiaid}`"
                    :data-tabsheet="`tabsheet-2-${symposiaid}`"
                    :class="
                      `virtual-cases__tabsheet__button-${symposiaid}` +
                      (speakers.length <= 0 ? ' virtual-cases__tabsheet__button--active' : '')
                    "
                  >
                    {{ t("congresses.presentation") }}
                  </li>
                </ul>
                <div
                  v-if="presentations && presentations.length > 0"
                  :id="`tabsheet-2-${symposiaid}`"
                  :class="`virtual-cases__tabsheet__container-${symposiaid}`"
                  :style="{ display: speakers.length <= 0 ? 'block' : 'none' }"
                >
                  <ul class="speakers_lists presentation_lists" v-if="presentations.length">
                    <li class="viewed" v-for="(presentitem, prei) in presentations" :key="prei">
                      <a @click="redirectMultimedia(presentitem.url, prei, false)">
                        <span class="title-text secondary-color text-bold"
                          ><span><em class="bi bi-file-earmark-slides"></em></span
                          ><span class="list-text">{{ presentitem.title }}</span></span
                        ></a
                      >
                    </li>
                  </ul>
                </div>
                <div
                  v-if="speakers && speakers.length > 0"
                  :id="`tabsheet-1-${symposiaid}`"
                  :class="`virtual-cases__tabsheet__container-${symposiaid} virtual-cases__tabsheet__container agendaSpeakers`"
                  :style="{ display: speakers.length > 0 ? 'block' : 'none' }"
                >
                  <ul v-if="speakers.length" class="tab-content-scroll">
                    <li v-for="(item, i) in speakers" :key="i">
                      <AgendaSpeakersItem v-bind="item" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import AgendaSpeakersItem from "../../agenda/AgendaSpeakersItem.vue";
import V4Carousel from "../../carousel/V4Carousel.vue";
import TranslateService from "../../../core/services/translate.service.js";
import ActivityService from "../../../core/services/activity.service";
import $ from "jquery";

export default {
  name: "PresentationTabs",
  props: {
    symposiaid: Number,
    symposianame: String,
    symposiadesc: String,
    thumbimg: String,
    formattedDate: String,
  },
  data() {
    return {
      isLoading: true,
      list: [],
      presentations: [],
      speakers: [],
      translator: TranslateService.create(),
      activitySvc: ActivityService.create(),
    };
  },
  components: {
    AgendaSpeakersItem,
    V4Carousel,
  },
  mounted() {
    this.fetch();
    if (this.speakers && this.speakers.length > 0) {
      $(`#tabsheet-1-${this.symposiaid}`).addClass("virtual-cases__tabsheet__button--active");
    } else {
      $(`#tabsheet-2-${this.symposiaid}`).addClass("virtual-cases__tabsheet__button--active");
    }
  },
  methods: {
    async fetch() {
      const results = await fetch(`/agenda-session/multimedia?type=media&id=${this.symposiaid}`).then((res) =>
        res.json(),
      );
      this.list = results.data;
      const presentation = await fetch(`/agenda-session/multimedia?type=document&id=${this.symposiaid}`).then((res) =>
        res.json(),
      );
      this.presentations = presentation.data;
      const speaker = await fetch(`/agenda-session/speakers?id=${this.symposiaid}`).then((res) => res.json());
      this.speakers = speaker.data;
      this.isLoading = false;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    changeTabsheet(e) {
      $(".virtual-cases__tabsheet__button-" + $(e.currentTarget).data("tabid")).removeClass(
        "virtual-cases__tabsheet__button--active",
      );
      $(".virtual-cases__tabsheet__container-" + $(e.currentTarget).data("tabid")).hide("fast");
      $("#" + $(e.currentTarget).data("tabsheet")).show("fast");
      $(e.currentTarget).addClass("virtual-cases__tabsheet__button--active");
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    parseImage(img) {
      if (img && img.substr(0, 1) != "/") {
        img = "/" + img;
      }
      return img;
    },
    showDefaultImage(event) {
      event.target.poster = "/images/v4/picture.png";
    },
    redirectMultimedia(route, key, bool) {
      let params = {
        title: bool ? this.list[key].title : this.presentations[key].title,
        model_type: bool ? this.list[key].type : this.presentations[key].type,
        model_id: bool ? this.list[key].id : this.presentations[key].id,
        congress_id: bool ? this.list[key].congress_id : this.presentations[key].congress_id,
      };
      this.activitySvc.saveActivity("open-symposium-multimedia", params);
      return window.open(route, "_blank").focus();
    },
  },
};
</script>
