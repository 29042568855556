<template>
  <div>
    <div v-if="isLoading">{{t('general.loading')}}...</div>
    <template v-if="!isLoading">
      <div>
        <div v-if="!isMobile()">
          <V4Carousel :slidesToShow="3" :slidesToScroll="3" :name="'profile-achievement'" :rowsToShow="1"  v-if="!isLoading">
            <template v-slot:items>
                <div v-for="(itemAchieve,k) in list" :key="k">
                  <AchievementItem v-bind="itemAchieve"></AchievementItem>
                </div>
            </template>
          </V4Carousel>
        </div>
        <div v-else>
          <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'profile-achievement'" :rowsToShow="1"  v-if="!isLoading">
              <template v-slot:items>
                <div v-for="(itemAchieve,k) in list" :key="k">
                  <AchievementItem v-bind="itemAchieve"></AchievementItem>
                </div>
              </template>
          </V4Carousel>
        </div>
      </div>
    </template>
  </div> 
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import AchievementFactory from './factories/Achievement.factory';
import V4Carousel from '../carousel/V4Carousel.vue';
import AchievementItem from './AchievementItem.vue';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'Achievements',
  components: {
    V4Carousel,
    AchievementItem
  },
  data() {
    return {
      isLoading: true,
      list: [],
      translator : TranslateService.create()
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const response = await this.getData();
      const results = response.data.data.map(k => AchievementFactory.get(k));
      this.list = results;
      this.isLoading = false; 
      
      if(this.list.length==0 ) {
        $("#my_achievements").hide();
        $('#my_achievements_menu').css('display', 'none');
      }
    },
    getData() {
      return axios.get('/profile/achievements');
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    },
    t(tag){
      return this.translator.translate(tag)
    },
  }
}
</script>