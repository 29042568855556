<template>
   <div class="slider-pagination-row agenda-slider">
    <div v-if="isLoading">
        {{ t("congresses.loading") }}
    </div>
    <div v-if="!isLoading" class="custom-congress-carousel">
        <div class="row">
            <div class="col-12 mt-2 pb-2 text-center view-program-text">
                <a @click="showLeavingModal(agendalink)" target="_blank">
                    <span class="secondary-color text-bold">{{ t('general.view_program_congress') }} 
                        <i class="bi bi-box-arrow-up-right"></i>
                    </span>
                </a>
            </div>
        </div>
        <div v-if="!isMobile()">
            <!-- DESKTOP VERSION -->
            <V4Carousel  :slidesToShow="3" :slidesToScroll="3" :name="'link'" :numberCarousel="true">
                <template v-slot:items>
                    <div v-for="(item, i) in list" v-bind:key="i">
                        <v4-card v-bind="item"></v4-card>
                    </div>
                </template>

            </V4Carousel>
        </div>
        <div v-else>
            <!-- MOBILE VERSION -->
            <V4Carousel  :slidesToShow="1" :slidesToScroll="1" :name="'link'" :numberCarousel="true">
                <template v-slot:items>
                    <div v-for="(item, i) in list" v-bind:key="i">
                        <v4-card v-bind="item"></v4-card>
                    </div>
                </template>
            </V4Carousel>
        </div>        
    </div>
    <!-- <center> -->
        <p class="no_records" v-if="isRecord">{{t("general.no-results")}}</p>
    <!-- </center>    -->
</div>
</template>

<script>
import TranslateService from '../../../core/services/translate.service.js';
import AgendaFactory from '../../agenda/factories/Agenda.factory.js';
import V4Carousel from '../../carousel/V4Carousel.vue';
import axios from 'axios';

export default {
    name: 'AgendaRecommend',
    components: {
        V4Carousel
    },
    props: {
        link: String,
        agendalink: String
    },
    data() {
        return { 
            isLoading: true,
            list: [],
            isRecord: false,
            translator : TranslateService.create()         
        }
    },
    mounted() {
        this.fetch();
    },   
    methods: {
        async fetch() {
            const response = await this.getData();
            const results = response.data.data.map(k => AgendaFactory.get(k));
            this.list = results;
            this.isLoading = false;
            if(this.list.length==0){
                this.isRecord = true;
                $('.agenda-recommendation-section').css('display', 'none');
                $('#agenda_menu').css('display', 'none');
                //Show header button
                $('#agenda-external-btn-header').removeClass('d-none');
            }
        },
        getData() {
            return axios.get('/congresses/agenda?simposium=0&slug='+this.link);
        },               
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        t(tag){
            return this.translator.translate(tag)
        },
        showLeavingModal(url) {
            // this.$root.$emit('leavingMMPModal', url);
            document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: url }));                    
        }
    }
}
</script>
