<template>
  <section class="content-larges custom-congress-content" id="content">
    <div class="contents-head">
      <h2 class="text-center custom-congress-title">{{t("congress.content")}}</h2>
      <CustomContentSearch ref="customContentSearch" :congressid="congressid" @searchFullContent="searchFullContent">
      </CustomContentSearch>
    </div>
    <div class="container">
      <SearchFormResults :size="pageSize"></SearchFormResults>
    </div>
  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import ContentFactory from './factories/Content.factory';
import CustomContentSearch from '../events/event-internal/CustomContentSearch.vue';
import SearchFormResults from '../search-form-results/SearchFormResults.vue';
import $ from 'jquery';
export default {
  name: 'InternalCongressContent',
  components: {
    CustomContentSearch,
    SearchFormResults
  },
  props: {
    slug: String,
    congressid: Number
  },
  data() {
    return {
      isRecord: false,
      refreshSub: 0,
      isSearch: false,
      list: [],
      translator: TranslateService.create(),
      pageSize: 6
    }
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async searchFullContent(data) {
      const results = await data[0].map(k => ContentFactory.get(k));
      this.list = [];
      this.list = results;
      if(this.list.length==0) {
        this.isRecord = true;
        $("#related").hide();
        this.refreshSub++;
      }
      this.isSearch = true;
    },
  }
}
</script>
