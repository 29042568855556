<template>
  <div class="events-calendar">
    <div v-if="isLoading">{{ t("general.loading") }}...</div>
    <VueCalendar v-if="!isLoading" :eventList="eventList" :locale="location"></VueCalendar>
  </div>
</template>

<script>
import TranslateService from "../../../core/services/translate.service";
import EventFactory from "../factories/Event.factory";
import EventRepository from "../repositories/Event.repository";
import VueCalendar from "../../vue-calendar/VueCalendar.vue";

export default {
  props: {
    location: String,
  },
  data() {
    return {
      isLoading: true,
      eventList: [],
      error: "",
      translator: TranslateService.create(),
    };
  },

  mounted() {
    this.get();
  },

  methods: {
    async get() {
      this.isLoading = true;
      this.error = "";

      try {
        const events = await EventRepository.getEventsByDate();
        this.eventList = this.refactorEvents(events);
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },

    refactorEvents(events) {
      if (!events) {
        throw new Error("Events not found");
      }
      return events.map((ev) => {
        let t = EventFactory.get(ev);
        t.date = t.date_start;
        return t;
      });
    },
    t(tag) {
      return this.translator.translate(tag);
    },
  },
  components: {
    VueCalendar,
  },
};
</script>
<style>
ul.v4-detailed-list.v4-detailed-list--compressed {
  max-height: 271px;
  overflow-y: auto;
}
.v4-detailed-list li {
  min-height: 70px;
}
</style>
