import { defineStore } from 'pinia';

export const useEmailPasswordStore = defineStore({
  id: 'emailPasswordStore',
  state: () => ({
    email: '',
    password: '',
    repeatPassword: '',
    validPasswordForm: false,
  }),
  actions: {
    setForm(form) {
      this.email = form.email;
      this.password = form.password;
      this.repeatPassword = form.repeatPassword;
      this.validPasswordForm = form.validPasswordForm;
    },
  },
});
