<template>
  <ModalComponent v-show="showModal" @close="closeModal" id="speaker-modal">
    <template v-slot:header>&nbsp;</template>
    <template v-slot:body>
      <div class="col-sm-12" id="modal-box">
        <div class="speak-user d-flex flex-row flex-wrap justify-content-center align-items-center">
          <div v-if="speakerItem.video" class="speaker-video">
            <VideoPlayer
              :id="speakerItem.id"
              :videoid="videoData.videoId"
              :videotype="1"
              class="speakers-video-player"
              :videouri="speakerItem.video"
              :accountid="videoData.accountId"
              :playerid="videoData.playerId"
              :embedid="'default'"
              :datasourcetype="0"
              :datatype="0"
            ></VideoPlayer>
          </div>
          <div v-if="speakerItem.photo && !speakerItem.video" class="speaker-img">
            <img :src="speakerItem.photo" @error="imageUrlAlt" alt="">
          </div>
          <div class="user-name-speaker">
            <h4 class="py-3">{{speakerItem.name}} {{speakerItem.surname}}</h4>
            <p class="new-sec_info">{{speakerItem.institution}}</p>
            <p class="infodesc" v-html="speakerItem.bio"></p>
          </div>
        </div>
      </div>
      <SpeakersRelatedContent
        v-if="speakerItem && speakerItem.relatedContent && speakerItem.relatedContent.length > 0 && !speakerItem.hideRelatedContent && !isMobile()"
        :relatedContent="!speakerItem.hideRelatedContent ? speakerItem.relatedContent : []"
      ></SpeakersRelatedContent>
    </template>
    <template v-slot:footer>&nbsp;</template>
  </ModalComponent>
</template>
<script>
import ModalComponent from '../internal-congress/ModalComponent.vue';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import VideoPlayer from '../multimedia/VideoPlayer.vue';
import SpeakersRelatedContent from '../custom-template/speakers/SpeakersRelatedContent.vue';

export default {
  name: 'SpeakerModal',
  props: {
    show: Boolean,
  },
  components: {
    ModalComponent, VideoPlayer, SpeakersRelatedContent
  },
  data() {
    return {
      showModal: this.show ?? false,
      speakerItem: {},
      videoData: {},
    }
  },
  mounted() {
    document.addEventListener("showSpeakerModal", (speaker) => {
      this.speakerItem = speaker.detail;
      this.showModal = true;
      this.videoData = this.prepareVideoData(this.speakerItem.video);
      GoogleAnalytics.sendOpenContentEvent(this.speakerItem.name + ' ' + this.speakerItem.surname, 'modal');
    });
  },
  methods: {
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    },
    closeModal() {
      this.showModal = false;
      GoogleAnalytics.sendCloseContentEvent(this.speakerItem.name + ' ' + this.speakerItem.surname, 'modal');
    },
    imageUrlAlt(event) {
      event.target.src = "/images/v4/assets/avatar.svg";
    },
    prepareVideoData(videoUri) {
      const congress_video_info = {};
      if (/brightcove/i.test(videoUri)) {
          congress_video_info.videoType = 1;
          congress_video_info.parsedUri = new URL(videoUri);
          congress_video_info.explodedPath = congress_video_info.parsedUri.pathname.split('/');
          const query = Object.fromEntries(congress_video_info.parsedUri.searchParams.entries());
          congress_video_info.accountId = congress_video_info.explodedPath[1];
          congress_video_info.videoId = query.videoId;
          congress_video_info.player = congress_video_info.explodedPath[2].split('_');
          congress_video_info.playerId = congress_video_info.player[0];
      }
      return congress_video_info;
    }
  }
}
</script>
