<template>
    <section id="people-choice-section">
        <div class="container">
            <div class="people-choice-section align-items-center" :id="configuration.section_wording.replace(/\s+/g, '')">
                <h2 class="people-choice-section__title text-center">
                    {{ configuration.section_wording }}
                </h2>
                <div v-if="isLoading">
                    {{ t("congresses.loading") }}
                </div>
                <div v-else>
                    <div v-if="!confirmedVotes && !isVotingDateExpired">
                        <people-choice-content :configuration="configuration" :multimedias="multimedias"></people-choice-content>
                    </div>

                    <div v-if="!confirmedVotes && isVotingDateExpired">
                        <people-choice-podium :configuration="configuration" :multimedias="multimedias"></people-choice-podium>
                    </div>

                    <div v-if="confirmedVotes && !isVotingDateExpired">
                        <people-choice-ranking :configuration="configuration" :multimedias="multimedias"></people-choice-ranking>
                    </div>

                    <div v-if="confirmedVotes && isVotingDateExpired">
                        <people-choice-podium :configuration="configuration" :multimedias="multimedias"></people-choice-podium>
                    </div>
                    <div v-if="showCountdown">
                        <people-choice-countdown :configuration="configuration"></people-choice-countdown>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import TranslateService from "../../core/services/translate.service";
import axios from "axios";
import PeopleChoiceContent from "./people-choice/PeopleChoiceContent.vue";
import PeopleChoicePodium from "./people-choice/PeopleChoicePodium.vue";
import PeopleChoiceRanking from "./people-choice/PeopleChoiceListRanking.vue";
import PeopleChoiceCountdown from "./people-choice/PeopleChoiceCountdown.vue";
import { useVotingStore } from "../../../v4/store/voting.store";

export default {
    name: "PeopleChoiceSection",
    components: { PeopleChoiceContent, PeopleChoicePodium, PeopleChoiceRanking, PeopleChoiceCountdown },
    props: {
        configuration: Object,
        congressId: Number,
    },
    setup(props) {
        const translator = TranslateService.create();
        const t = (tag) => translator.translate(tag);
        const votingStore = useVotingStore();
        const votingState = computed(() => votingStore.votingState);
        const isVotingDateExpired = computed(() => votingStore.votingState === "expired");
        const isLoading = ref(true);
        const configuration = props.configuration;
        const multimedias = ref([]);
        const userVotes = ref([]);
        
        const confirmedVotes = computed(() => {
            return votingStore.votes.some(vote => vote.confirmed == 1);
        });

        const showCountdown = computed(() => {
            return configuration.configuration.custom_people_choice_show_contdown == 1;
        });

        const getDataForPeopleChoice = async () => {
            try {
                const response = await axios.get(`/congresses/people-choice?congressId=${props.congressId}`, {
                    congressId: props.congressId,
                });

                if (response.data && response.data.data) {
                    multimedias.value = response.data.data.multimedias;
                    userVotes.value = response.data.data.userVotes;
                    votingStore.votes = response.data.data.userVotes;
                } else {
                    console.warn("No people choice data found");
                    multimedias.value = [];
                }
            } catch (error) {
                console.error(error);
            }
        };


        onMounted(async () => {
            await getDataForPeopleChoice();
            votingStore.startDate = configuration.configuration.custom_people_choice_date_start;
            votingStore.endDate = configuration.configuration.custom_people_choice_date_end;
            votingStore.startClock();
            isLoading.value = false;
        });

        const isMobile = () => {
            try {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                );
            } catch (error) {
                console.error("Error determining if the device is mobile:", error);
                return false;
            }
        };

        watch([confirmedVotes, isVotingDateExpired], ([newConfirmedVotes, newIsVotingDateExpired]) => {
            if (newConfirmedVotes && !newIsVotingDateExpired) {
                getDataForPeopleChoice();
            }
        });

        return {
            t,
            isMobile,
            isLoading,
            configuration,
            multimedias,
            userVotes,
            confirmedVotes,
            votingState,
            isVotingDateExpired,
            showCountdown,
            votingStore,
        };
    },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>