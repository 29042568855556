<template>
  <div :class="liveControlsCSS">
    <!--  <div class="congress-live-controls-info">
            <div class="congress-live-controls-info-title">Title</div>
            <div class="congress-live-controls-info-author"></div>
        </div> -->
    <div class="congress-live-controls-group">
      <div class="congress-live-controls-list">
        <div class="congress-live-controls-group-buttons visible congress-button-left ">
          <div
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted"
            @click="showModal('help')"
          >
            <span class="video-menu-icon"><i class="bi bi-question-circle"></i></span>
            <span class="video-menu-text">Help</span>
          </div>
        </div>
        <div v-if="congress.agenda_session.enable_ask_question" 
          class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted modal-ask-question"
          :class="{ active: selected == 'askquestion' }"
        >
          <AskQuestionModal :model-id="congress.agenda_session.id" :modelType="'AgendaSession'" :useButton="fale"/>
        </div>
        <!-- Remove "visible" attribute and remove "d-none" to "congress-live-controls-group-main" to have main button and hidden buttons -->
        <div class="congress-live-controls-group-buttons visible congress-button-right" id="livefloatingmenus">
          <!-- TEST -->
          <div class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted test-modal-button-div" hidden>
            <div
                class="show-test-modal-button"
                @click="showTestModal(1)"
                id="showTestModalBefore"
                disabled
                hidden
              >
                <span class="video-menu-icon js-before-test-edit"><i class="bi bi bi-pencil"></i></span>
                <span class="video-menu-icon js-before-test-check" hidden><i class="bi bi-check"></i></span>
                <span class="video-menu-text js-before-test-text">Resume Quiz</span>
            </div>
            <div
                class="show-test-modal-button"
                @click="showTestModal(2)"
                id="showTestModalAfter"
                disabled
                hidden
              >
                <span class="video-menu-icon js-after-test-edit"><i class="bi bi bi-pencil"></i></span>
                <span class="video-menu-icon js-after-test-check" hidden><i class="bi bi-check"></i></span>
                <span class="video-menu-text js-after-test-text">Resume Quiz</span>
            </div>
          </div>
          <!-- MUTE/UNMUTE -->
          <div
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted"
            style="margin-right: 30px;"
            v-if="congressinfo.isVimeo"
          >
            <img
              style="width: 25px;"
              class="congress-live-controls-group-buttons-item-muted-image"
              :src="'images/v4/icons/live-options-unmuteimg.svg'"
              alt=""
            />
          </div>
          <!-- Footer icons -->
          <div
            id = "live-reactions"
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted reaction-tag"
            :class="{ active: selected == 'reaction' }"
            @click="showModal('reaction')"
            v-if="congressinfo.hasReactions"
          >
            <a
              class="live_reactions "
              data-reactions-type="horizontal"
              :data-unique-id="congress.agenda_session.id"
              :data-congress-id="congress.id"
              data-emoji-class=""
            >
              <span class="livefloatreaction"
                ><span class="video-menu-icon"><i class="bi bi-hand-thumbs-up"></i></span
              ></span>
              <span class="video-menu-text textreaction">Reactions</span>
            </a>
          </div>
          <!-- <div class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted" :class="{'active' : selected=='uapp'}" @click="showModal('uapp')">
                        <span class="video-menu-icon"><i class="bi bi-phone-vibrate"></i></span>
                        <span class="video-menu-text">Use app</span>
                    </div> -->
          <div
            v-if="congressinfo.hasPadlet"
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted"
            :class="{ active: selected == 'padlet' }"
            @click="showModal('padlet')"
          >
            <span class="video-menu-icon"><i class="bi bi-columns-gap"></i></span>
            <span class="video-menu-text">Padlet</span>
          </div>

          <div
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted"
            v-if="congressinfo.hasPdf && !thecoopcheck"
            :class="{ active: selected == 'pdf' }"
            @click="showModal('pdf')"
          >
            <span class="video-menu-icon"><i class="bi bi-file-earmark-pdf"></i></span>
            <span class="video-menu-text">Interactive PDF</span>
          </div>
          <div
            id = "live-qa"
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted"
            @click="showModal('qa')"
            v-if="congressinfo.isFinished == false && !congress.agenda_session.deactivate_qa"
            :class="{ active: selected == 'qa' }"
          >
            <span class="video-menu-icon"><i class="bi bi-chat-left"></i></span>
            <span class="video-menu-text">Q&A</span>
          </div>
          <div
            id = "live-notes"
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted"
            @click="showModal('notes')"
            :class="{ active: selected == 'notes' }"
          >
            <span class="video-menu-icon"><i class="bi bi-journal-text"></i></span>
            <span class="video-menu-text">Notes</span>
          </div>
          <div
            id = "live-vote"
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted"
            @click="showModal('vote')"
            :class="{ active: selected == 'vote' }"
          >
            <span class="video-menu-icon"><i class="bi bi-clipboard-data"></i></span>
            <span class="video-menu-text">Live voting</span>
          </div>
          <div
            id = "live-posts"
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted livepostlist"
            @click="showModal('posts')"
            :class="{ active: selected == 'posts' }"
          >
            <span class="video-menu-icon"><i class="bi bi-stickies"></i></span>
            <span class="video-menu-text">Posts</span>
          </div>
          <div v-show="congress.agenda_session.meeting_platform == 'WEBEX'"
            class="congress-live-controls-group-buttons-item congress-live-controls-group-buttons-item-muted livepostlist"
            @click="store.toggleShowParticipants()"
            :class="{ active: selected == 'participants' }"
          >
            <span class="video-menu-icon"><i class="bi" :class="{'bi-people-fill': store.showParticipants, 'bi-people': !store.showParticipants}"></i></span>
            <span class="video-menu-text">Participants</span>
          </div>
        </div>
      </div>
      <div class="congress-live-controls-group-main d-none">
        <img :src="'/images/v4/icons/live-options.svg'" alt="options" />
      </div>
    </div>
    <LiveModalComponent v-show="isModalVoteVisible" @close="closeModal" v-bind:type="'vote'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <CongressLiveVoting :livepolls="livepolls" :status="true" />
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>
    <LiveModalComponent v-show="isModalPostsVisible" @close="closeModal" v-bind:type="'posts'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <CongressLivePosts :moderatormessages="moderatorlist" />
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>
    <LiveModalComponent v-show="isModalNotesVisible" @close="closeModal" v-bind:type="'notes'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <CongressLiveNotes :agendaid="congress.agenda_session.id" :slug="congress.slug" :notes="notes" />
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>
    <LiveModalComponent v-show="isModalQAVisible" @close="closeModal" v-bind:type="'qa'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <CongressLiveQuesAns :agendaid="congress.agenda_session.id" :slug="congress.slug" />
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>
    <!-- for help popup -->
    <LiveModalComponent v-show="isModalhelpVisible" @close="closeModal" v-bind:type="'help'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <CongressLiveHelp :agendaid="congress.agenda_session.id" :slug="congress.slug" />
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>

    <LiveModalComponent v-show="isModalPadletVisible" @close="closeModal" v-bind:type="'padlet'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <div class="mmp-padlet-tec">
          <iframe
            :src="'https://padlet.com/embed/' + congressinfo.padlet_embed"
            frameborder="0"
            allow="camera;microphone;geolocation"
            style="width:100%;height:100%;display:block;padding:0;margin:0"
          ></iframe>
        </div>
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>
  </div>
</template>
<script>
import LiveModalComponent from './LiveModalComponent.vue';
import CongressLiveVoting from './CongressLiveVoting.vue';
import CongressLiveNotes from './CongressLiveNotes.vue';
import CongressLiveQuesAns from './CongressLiveQuesAns.vue';
import CongressLivePosts from './CongressLivePosts.vue';
import CongressLiveHelp from './CongressLiveHelp.vue';
import TranslateService from '../../core/services/translate.service.js';
import Utils from '../../Utils';
import '../../../../libs/reactions/reactions';
import MessageFactory from './factories/Message.factory';
import webSocket from '../../LiveWebsocketsAttendee';
import { useWebexStore } from '../../store/webex.store'
import $ from 'jquery';
import AskQuestionModal from './AskQuestionModal.vue';


export default {
  props: {
    congress: Object,
    congressinfo: Object,
    thecoopcheck: Boolean,
    moderatormessages: Array,
    livepolls: Array,
    notes: Object
  },
  components: {
    LiveModalComponent,
    CongressLiveVoting,
    CongressLivePosts,
    CongressLiveNotes,
    CongressLiveQuesAns,
    CongressLiveHelp,
    AskQuestionModal,
  },
  setup() {
    const store = useWebexStore()

    return { store }
  },
  data() {
    return {
      liveControlsCSS: "congress-live-controls",
      isModalVoteVisible: false,
      isModalPostsVisible: false,
      isModalNotesVisible: false,
      isModalQAVisible: false,
      isModalhelpVisible: false,
      isModalPadletVisible: false,
      selected: '',
      moderatorlist: [],
      translator: TranslateService.create()
    };
  },
  mounted() {
    $('.live_reactions').liveReactions({
      postUrl: Utils.getUrl(true, 'save-reaction')
    });
    this.getModerators();
    this.addCorrectClassesToLiveControl();
    webSocket.init();

    document.addEventListener("mmp-footer-show", (data) => {
      const liveFooter = document.getElementsByClassName('congress-live-controls');
      if (data.detail) {
        liveFooter[0].classList.add('mmp-footer-show');
      } else {
        liveFooter[0].classList.remove('mmp-footer-show');
      }
    });
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    addCorrectClassesToLiveControl() {
      this.liveControlsCSS = "congress-live-controls";
      if(this.congress.iconBarBgType) {
        this.liveControlsCSS += " congress-live-controls--gradient";
      }
    },
    /*
     * Method to open the popup
     * @Param i - contains the type of popup
     */
    showModal(i) {
      this.closeModal();
      if (i == 'vote') {
        this.isModalVoteVisible = true;
        this.selected = 'vote';
      } else if (i == 'posts') {
        this.isModalPostsVisible = true;
        this.selected = 'posts';
      } else if (i == 'notes') {
        this.isModalNotesVisible = true;
        this.selected = 'notes';
      } else if (i == 'qa') {
        this.isModalQAVisible = true;
        this.selected = 'qa';
      } else if (i == 'help') {
        this.isModalhelpVisible = true;
        this.selected = 'help';
      } else if (i == 'pdf') {
        this.selected = 'pdf';
      } else if (i == 'uapp') {
        this.selected = 'uapp';
      } else if (i == 'reaction') {
        this.selected = 'reaction';
      } else if (i == 'padlet') {
        this.isModalPadletVisible = true;
        this.selected = 'padlet';
      } else {
        this.selected = '';
      }
      const iframe = document.getElementById('iframe-live');
      if (iframe) {
          iframe.classList.add('disable-pointer-events');
      }
    },
    /*
     * Method to close all popups
     */
    closeModal() {
      this.isModalVoteVisible = false;
      this.isModalPostsVisible = false;
      this.isModalNotesVisible = false;
      this.isModalhelpVisible = false;
      this.isModalQAVisible = false;
      this.isModalPadletVisible = false;
      this.selected = '';
      const iframe = document.getElementById('iframe-live');
      if (iframe) {
          iframe.classList.remove('disable-pointer-events');
      }
    },
    /**
     * Method to parse posts data
     */

    getModerators() {
      this.moderatorlist = this.moderatormessages.map(k => MessageFactory.get(k));
    },
    showTestModal(type) {
      // this.$root.$emit('showTestModal', type);
      document.dispatchEvent(new CustomEvent("showTestModal", { type: type}));
    },
  }
};
</script>
