<template>
    <div class="virtual-booth-section__highlight">
        <div v-if="isLoading">
            {{ t("congresses.loading") }}
        </div>
        <template v-if="!isLoading && list.length > 0">
            <V4Carousel
                :name="'virtual-booth-highlited'"
                :slidesToShow="3"
                :slidesToScroll="1"
                :isLoading="isLoading"
                :numberCarousel="false"
                :infinite="false"
                :autoplay = "configuration.configuration.custom_automatic_rotation == 1"
                :autoplaySpeed = "configuration.configuration.custom_seconds_for_rotation"
                v-if="!isLoading"
                >
                <template v-slot:items>
                    <div v-for="(item, i) in list" v-bind:key="i">
                        <CardThumbnail v-bind="item"></CardThumbnail>
                    </div>
                </template>
            </V4Carousel>
        </template>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import 'swiper/css/bundle';
import TranslateService from '../../../core/services/translate.service.js';
import V4Carousel from '../../carousel/V4Carousel.vue';
import ContentFactory from '../../content/factories/Content.factory'
import CardThumbnail from '../components/CardThumbnail.vue';

export default {
    name: 'VirtualBoothMultipleContent',
    components: {
        V4Carousel,
        CardThumbnail,
    },
    props: {
        list: Array,
        configuration: Object,
    },
    setup(props) {
        const translator = TranslateService.create();
        const t = (tag) => translator.translate(tag);

        const isLoading = ref(true);
        const list = ref(props.list);
        const configuration = props.configuration;

        onMounted(() => {
            loadslider();
            list.value = list.value.map((ev) => {
                if (ev.type == "multimedia") {
                    return ContentFactory.get(ev);
                }
            });
        });

        const isMobile = () => {
            try {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            } catch (error) {
                console.error('Error determining if the device is mobile:', error);
                return false;
            }
        };

        const loadslider = () => {
            isLoading.value = false;
        };

        return {
            t,
            isMobile,
            isLoading,
            list,
            configuration,
        };
    },
}
</script>
