import Axios from "axios";
import Utils from "../../objects/v4/Utils";

export class ConsumptionHelper {
  modelId;
  modelType;
  consumptionId;
  watched_time = 0;
  constructor(modelId, modelType) {
    if(!modelId) {
      throw new Error("The multimedia ID is missing!");
    }
    this.modelId = modelId;
    if (!!modelType) {
      this.modelType = modelType;
    } else {
      this.modelType = this.getModelTypeFromURL();
    }
  }

  getModelTypeFromURL() {
    const pathName = document.location.pathname;
    if (!!pathName.match(/live/i)) {
      return 'agenda_session';
    }
    return "multimedia";
  }

  update(watched_time, duration=null) {
    return new Promise((resolve,reject) => {
      if(watched_time > this.watched_time) {
        const data = {
          model_id: this.modelId,
          model_type: this.modelType,
          consumption_id: this.consumptionId,
          watched_time,
          duration
        };
        Axios.post(Utils.getUrl(false, 'consumption-update'), data).then(res => {
          this.watched_time = watched_time;
          if (!!res.data.consumption) this.consumptionId = res.data.consumption.id;
          resolve(res.data);
        }).catch(err => {
          if(err.response.status === 410) {
            resolve(err.response.data);
          } else {
            reject(err.response.data);
          }
        });
      } else {
        resolve({
          message: "Consumption update ignored, watched time is previous or the same as sent.",
          status: "NOT_UPDATED",
          consumption: {
            "watched_time": this.watched_time,
            "model_id": this.modelId,
            "model_type": this.modelType,
          }
          // fake data
        });
      }
    });
  }

  get() {
    return new Promise((resolve,reject) => {
      const uri = Utils.getUrl(false, 'consumption-get');
      const data = {
        model_id: this.modelId,
        model_type: this.modelType,
      };
      Axios.post(uri, data).then(res => {
        if (!!res.data.consumption) this.consumptionId = res.data.consumption.id;
        resolve(res.data);
      }).catch(err => {
        if (err.response.status === 410) {
          resolve(err.response.data);
        } else {
          reject(err.response.data);
        }
      });
    });
  }
}
