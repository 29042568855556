<template>
  <div class="content-list-scroll audience_listing">
    <div v-if="isLoading">{{t('general.loading')}}...</div>

    <AlertModel class="moderator-popup-tick" v-show="isModalVisible" @close="closeModal">
      <template v-slot:header></template>
      <template v-slot:body>
        <div class="col-sm-12">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"><i class="bi bi-check2"></i> The ask was sended successfully to the ponent.</h5>
          </div>
        </div>
      </template>
    </AlertModel>

    <div v-if="!isLoading && !congress.agenda_session.deactivate_qa">
      <div class="question-box question-count" v-for="(item, i) in audienceData" v-bind:key="i" :id="'main_audience_block_'+item.id">
        <div class="question-title"><h4 class="text-left">{{item.user.name+' '+item.user.surname}}</h4></div>
        <div class="question-desc">
          <div class="content-des-box text-left" :class="(item.status=='discarded') ? 'lines' : ''" :id="'content_block_'+item.id" @input="event => onInput(event, item.id)">
            <span v-html="item.question"></span> <i v-if="item.status != 'discarded'" class="bi bi-pencil-square" :id="'content_edit_icon_'+item.id" @click="editBox(item.id);"></i>
          </div>
          <div class="cancel-save clearfix">
            <div class="pull-left" style="display:none;" :id="'content_action_'+item.id">
              <div class="cancel-quest"><a href="javascript:void(0);" @click="hideBox(item.agenda_session_id, item.id);">Cancel</a></div>
              <div class="save-quest"><a href="javascript:void(0);" @click="updateBox(item.agenda_session_id, item.id);">Save</a></div>
            </div>
            <div class="pull-right">
              <div class="send-btn">
                <a href="javascript:void(0);" @click="sendQuestion(item.agenda_session_id,item.id,item.question,'validated');">
                  <button><img src="/images/v4/icons/send.svg"></button>
                </a>
              </div>
              <div class="delete-btn">
                <a href="javascript:void(0);" @click="sendQuestion(item.agenda_session_id,item.id,item.question,'discarded');">
                  <button><i class="bi bi-trash"></i></button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="(!messageReceived && !isLoading && audienceData.length==0 && !congress.agenda_session.deactivate_qa)">No records found.</div>
    <div class="text-danger" v-if="(congress.agenda_session.deactivate_qa && !isLoading)">Q&A deactivated for this Agenda Session.</div>
  </div>
</template>
<script>
import AlertModel from "../internal-congress/ModalComponent.vue";
import AudienceFactory from './factories/Audience.factory';
import TranslateService from '../../core/services/translate.service.js';
// import eventCallerService from '../../core/services/eventCaller.service';
import { WSHelper } from '../../../../libs/ws/ws-helper';
import Utils from '../../Utils';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'moderatorAudience',
  props: ['slug', 'itemid', 'userid', 'congress'],
  components: {
    AlertModel
  },
  data() {
    return {
      isLoading: true,
      allData: [],
      audienceData: [],
      validatedData: [],
      isAudience: true,
      isPonent: false,
      isModalVisible: false,
      translator: TranslateService.create(),
      messageReceived: false,
    }
  },
  mounted() {
    try {
      this.liveData();

      let that = this;

      this.ws = new WSHelper(true);

      this.ws.registerCallback((evt) => {
        switch (evt.operation) {
          case 'conn_retry_failure':
            // do something like displaying a loading circle?
            break;
          case 'conn_down':
            console.debug('Connection down...');
            break;
          case 'msg_received':
            // A message was received:
            this.onWSMessageShowpoll(evt);
            break;
          case 'conn_started':
          case 'conn_restarted':
            // Ask current status:
            const agendaSessionId = Utils.getAgendaSessionId();
            that.ws.sendObject({ "agenda_id": agendaSessionId, operation: "register_ws", "uid": window.user.uid });
            break;
        }
      });

      this.ws.start().then(res => {
        console.debug(res);
      }).catch(err => {
        console.debug("ERR", err);
      });
    } catch (error) {
      console.error('An error occurred:', error);
    }
  },
  methods:{
    onWSMessageShowpoll(evt) {
      let that = this;
      if (evt.json && evt.json.data) {
          const data = evt.json.data;
          switch (data.action) {
            case "speaker_question_sent":
              this.messageReceived = true;
            break;
          }
      }
    },
    sendQuestion(sessionId, qtnId, qtn, status) {
      this.questionAction(sessionId, qtnId, qtn, status);
    },
    async questionAction(sessionId, qtnId, qtn, status) {
      const response = await this.getQtnValidation(sessionId, qtnId, qtn, status);
      if(response.status==200){
        if(status=='update') {
          $('#content_edit_icon_'+qtnId).show();
          $('#content_action_'+qtnId).hide();
          this.liveData();
        } else if(status=='validated') {
          this.audienceData = this.audienceData.filter(obj => obj.id !== qtnId);
          this.messageReceived = false;
          document.dispatchEvent(new CustomEvent("updateAudienceData"));
          // eventCallerService.$emit("updateAudienceData");
          $('#main_audience_block_'+qtnId).remove();
          this.isModalVisible = true;
          setTimeout(function(){ this.isModalVisible = false; }, 800);
          this.liveCount();
        } else if(status=='discarded') {
          $('#content_block_'+qtnId).css('text-decoration','line-through');
          this.liveCount();
        }

      }
    },
    getQtnValidation(sessionId, qtnId, qtn, status) {
      return axios.post('/webapi/events/'+this.slug+'/validate-live-questions', {
        agendaSessionId: sessionId,
        live_question: qtnId,
        question: qtn,
        status: status
      });
    },
    async liveCount() {
      const response = await this.getLiveQtnData();
      var audienceArray = [];
      var ponentArray = [];
      if(response.data.data.length>0) {
        this.allData = response.data.data.reverse().map(k => AudienceFactory.get(k));
        this.allData.forEach(data => {
          if((data.status=='created') || (data.status=='discarded') || (data.status=='updated')) {
            audienceArray.push(data);
          }
          if(data.status=='validated') {
            ponentArray.push(data);
          }
        });
        $('#audience_count').text('('+audienceArray.length+')');
        $('#ponent_count').text('('+ponentArray.length+')');
      }
    },
    async liveData() {
      const response = await this.getLiveQtnData();
      this.audienceData = [];
      this.validatedData = [];
      if(response.data.data.length>0) {
        this.allData = response.data.data.reverse().map(k => AudienceFactory.get(k));
        this.allData.forEach(data => {
          if((data.status=='created') || (data.status=='discarded') || (data.status=='updated')) {
            this.audienceData.push(data);
          }
          if(data.status=='validated') {
            this.validatedData.push(data);
          }
        });
        $('#audience_count').text('('+this.audienceData.length+')');
        $('#ponent_count').text('('+this.validatedData.length+')');
      }
      this.$emit("onAudDataLoad", this.audienceData.length);
      this.isLoading = false;
    },
    getLiveQtnData() {
      return axios.post('/webapi/events/'+this.slug+'/get-live-questions', {
        agendaSessionId: this.itemid,shownIds: []
      });
    },
    editBox(boxid) {
      window.boxid = $('#content_block_'+boxid+' span').text();
      $('#content_block_'+boxid).attr('contenteditable',true).focus();
      $('#content_edit_icon_'+boxid).hide();
      $('#content_action_'+boxid).show();
    },
    updateBox(sessionId, boxid) {
      window.boxid = $('#content_block_'+boxid+' span').text();
      var qtn = $('#content_block_'+boxid+' span').text();
      this.questionAction(sessionId, boxid, qtn, 'update');
    },
    hideBox(sessionId, boxid) {
      $('#content_block_'+boxid+' span').text(window.boxid);
      $('#content_block_'+boxid).attr('contenteditable',false);
      $('#content_edit_icon_'+boxid).show();
      $('#content_action_'+boxid).hide();
    },
    onInput(event, index) {
      const value = event.target.innerText;
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    closeModal() {
      this.isModalVisible = false;
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }
  }
}
</script>
