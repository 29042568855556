<template>
  <div>
    <section class="new-rd custom-congress-welcome" style="margin: 0% auto; margin-bottom: 0;">
      <div class="upcoming-session" v-if="!isLoading && upcomingData.length > 0 || !showSession">
        <div class="container">
          <h4 class="text-center">{{ t('congress.upcoming-session') }}</h4>
          <div class="card-items row justify-content-center">
            <div class="col-sm-4" v-for="(item, i) in upcomingData.slice(0, 3)" v-bind:key="i">
              <V4Card v-bind="item"></V4Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import PageStayController from '../../page-stay/PageStayController';
import AgendaFactory from '../agenda/factories/Agenda.factory';
import axios from 'axios';
import $ from 'jquery';
import V4Card from '../card/V4Card.vue';
export default {
  name: 'upcoming',
  components: {
    V4Card
  },
  data() {
    return {
      basicInfo: [],
      upcomingData: [],
      translator: TranslateService.create(),
      isLoading: true,
      substring: '',
      showread: '',
      description: '',
      showSession: true
    };
  },
  props: {
    congressId: Number,
    slug: String,
    headerlogo: String,
    countryId: Number
  },
  mounted() {
    this.fetch();
    this.initStayPage();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async fetch() {
      const response = await this.getUpcomingData();
      const results = response.data.data.map(k => AgendaFactory.get(k));
      this.upcomingData = results;
      const mustShow = response.data.show;
      this.showSession = mustShow;
      if (!results || (results.length == 0 || !mustShow)) {
        $('#upcoming-sessions-section').hide();
        $('#upcoming-sessions-section_menu').hide();
      }

      this.isLoading = false;
    },
    getUpcomingData() {
      return axios.get(`/congresses/upcoming-sessions?slug=${this.slug}&countryId=${this.countryId ?? null}`);
    },
    initStayPage() {
      PageStayController.startStayPageTime('congress', this.congressId);
    }
  }
};
</script>
