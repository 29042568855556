<template>
    <div class="v4-event-subscription--sticky-panel">
        <div v-for="(item, i) in list" v-bind:key="i" style="width: 400px;">
            <EventSubscription v-bind="item" :sticky="true" :styles="calculatePosition(i)"></EventSubscription>
        </div>
    </div>
</template>

<script>
import EventRepository from '../repositories/Event.repository';
import EventSubscriptionFactory from '../factories/EventSubscription.factory';
import EventSubscription from '../event-subscription/EventSubscription.vue';
import AgendaFactory from '../../agenda/factories/Agenda.factory';

    export default {
        components: {
            EventSubscription,
        },
        props: {
            slug: String,
            congressId: Number,
        },
        data() {
            return {
                isLoading: false,
                list: [],
            }
        },

        mounted() {
            this.$nextTick(function () {
                this.updateEvents();
            })
        },

        methods: {
            calculatePosition(index) {
                const px = index > 0 ? index * 15 : '';
                return `top: ${px}px; right: ${px}px; left: -${px}px`;
            },
            async updateEvents() {
                // this.bus.$emit('remove', {});
                this.isLoading = true;

                try {
                    const events = await this.getEvents();
                    this.list = [...this.refactorEvents(events)].reverse();          
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isLoading = false;
                }
            },

            refactorEvents(events) {
                if(!events) {
                    throw new Error('Events not found');
                }

                return events.lives.map(ev => {
                    if(ev.type == 'agenda_session') {
                        return AgendaFactory.get(ev);
                    } else {
                        return EventSubscriptionFactory.get(ev);
                    }
                });
            },

            async getEvents() {
                return await EventRepository.getLiveEvents(this.slug, this.congressId);
            }
        }
    }
</script>
