<template>
  <div>
    <div v-if="isLoading">
      {{ t("congresses.loading") }}
    </div>
    <div v-if="error">
      {{ error }}
    </div>

    <div v-if="!isLoading && !list.length">
      {{ t("general.no-results") }}
    </div>
    <div v-if="!isMobile()">
      <div class="row" v-if="!isLoading">
        <div class="col-6 px-0 pb-2" v-for="content in list" :key="content.id">
          <div @click="handlePromoClick(content.title)">
            <V4Card v-bind="content"></V4Card>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'content'" :isLoading="isLoading" v-if="!isLoading">
        <template v-slot:items>
          <div v-for="(item, i) in list" v-bind:key="i">
            <div @click="handlePromoClick(item.title)">
              <V4Card v-bind="item"></V4Card>
            </div>
          </div>
        </template>
      </V4Carousel>
    </div>
  </div>
</template>

<script>
import ContentRepository from "../home-form/repositories/Content.repository";
import ContentFactory from "../content/factories/Content.factory";
import TranslateService from "../../core/services/translate.service.js";
import V4Card from "../card/V4Card.vue";
import V4Carousel from "../carousel/V4Carousel.vue";
import $ from "jquery";
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
  data() {
    return {
      isLoading: true,
      list: [],
      error: "",
      translator: TranslateService.create(),
      form: {
        events: false,
        content: true,
        all: false,
        bookmarks: false,
        q: "",
        qTherapeuticAreas: [],
        qPathologies: [],
        format: [],
        content_type: ["content"],
        qKeywords: [],
        date: [],
        size: 4,
        page: 0,
      },
    };
  },

  mounted() {
    this.get();
  },

  methods: {
    async get() {
      try {
        this.isLoading = true;
        this.error = "";
        const list = await ContentRepository.search({
          content_type: ["content"],
          size: 4,
          page: 0,
          initialSearch: true,
          sort_by: "date",
        });
        this.list = list[0].map((c) => ContentFactory.get(c));
        this.isLoading = false;
      } catch (error) {
        this.list = [];
        this.error = error + " something happened";
        this.isLoading = false;
      }

      if (this.list.length == 0) {
        $("#content").hide();
      }
    },
    handlePromoClick(title) {
      if (typeof GoogleAnalytics !== 'undefined' && typeof GoogleAnalytics.sendPromoClickEvent === 'function') {
          GoogleAnalytics.sendPromoClickEvent(title);
      } else {
          console.error('GoogleAnalytics.sendPromoClickEvent no está definido');
      }
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    t(tag) {
      return this.translator.translate(tag);
    },
  },
  components: {
    V4Card,
    V4Carousel
  },
};
</script>
