import DateService from "../../../core/services/date.service";

export default {
    get(item) {
        return {
            id: item?.id ?? '',
            title: item?.title ?? '',
            date: item?.created_at ?? '',
            date_formatted: DateService.fullDate(item.created_at)
        }
    }
};