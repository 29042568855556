<template>
    <div class="video_item" @click="getMultimediaURL()">
        <div class="video_item_inner">
            <img :src="parseImage(image)" @error="showDefaultImage($event)" alt=""/>
            <div class="video_item_inner_layer">
                <a rel="noopener noreferrer">
                    <i class="bi bi-play-circle"></i>
                </a>
            </div>
        </div>
        <h4 class="video_item_title">{{title}}</h4>
    </div>
</template>

<script>
import TranslationService from '../../core/services/translation.service';
    export default {
        name: 'AgendaMediaContentItem',
        props: {
            id: Number,
            multimedia_type_id: Number,
            external_link: String,
            title: String,
            image: String
        },
        methods:{
            showDefaultImage(event){
                const loc = document.location;
                event.target.src = `${loc.protocol}//${loc.host}/`+"images/v4/picture.png"
            },
            parseImage(img) {
                if (img && img.substr(0,1) != '/') {
                    img = '/' + img;
                }
                return img;
            },
            getMultimediaURL() {
                let url = TranslationService.transRoute('detail-multimedia', {multimediaId: this.id});
                if(this.external_link && this.external_link != "" && this.multimedia_type_id != 4 && this.multimedia_type_id != 5 && this.multimedia_type_id != 12) {
                    // this.$root.$emit('leavingMMPModal', this.external_link);
                    document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.external_url }));
                } else {
                    window.open(url, '_blank');
                }
            }
        }
    }
</script>
