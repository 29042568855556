<template>
  <footer id="v4-footer">
    <div class="container-fluid">
        <div class="flex-row w-100">
            <div class="row justify-content-lg-around justify-content-around flex-wrap flex-lg-nowrap">
                <div class="col-xl-12 col-lg-12">
                    <div class="row">
                        <div class="col p-0 footer-logos">
                            <div class="justify-content-lg-start justify-content-center d-flex flex-wrap flex-lg-nowrap">
                              <a :href="app_url" class="logo-footer logo-footer-first"></a>
                              <a href="https://www.amgen.com/" target="_blank" class="logo-footer logo-footer-second"></a>
                            </div>
                        </div>
                        <div class="col-lg-6 p-0 col-md-12 d-flex align-items-center">
                            <p class="footer-legal-text text-center">
                                <span>{{ t('general.footer_1') }} {{ year }}
                                    {{ t('general.footer_2') }}</span>
                                <br>
                            </p>
                        </div>
                        <div class="col p-0 footer-links-container">
                            <ul
                                class="d-flex justify-content-md-end justify-content-center align-items-center footer-links m-0">
                                <li>
                                    <a href="https://www.amgen.eu/cookie-information-for-users" @click="handleOutboundClick('https://www.amgen.eu/cookie-information-for-users')" target="_blank">{{ t('general.cookie-statement') }}</a>
                                </li>
                                <li>
                                    <a href="javascript:Cookiebot.renew();" @click="handleMenuNavEvent('Cookie Declaration', 'footer'); handlePromoClickEvent('Cookie Declaration')">{{ t('general.cookie-declaration') }}</a>
                                </li>
                                <li>
                                    <a href="https://www.amgen.com/terms-of-use/" target="_blank" @click="handleOutboundClick('https://www.amgen.com/terms-of-use/')">{{ t('general.terms-of-use') }}</a>
                                </li>
                                <li>
                                    <a href="https://www.amgen.com/privacy-statement/" target="_blank" @click="handleOutboundClick('https://www.amgen.com/privacy-statement/')">{{ t('general.privacy-statement') }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </footer>
</template>

<script>
import TranslateService from '../../../core/services/translate.service';
import GoogleAnalytics from '../../../GoogleAnalytics.js';

export default {
  name: 'FooterTemplate',
  data() {
    return {
      translator: TranslateService.create(),
      app_url: window.app.env.APP_URL,
      year: window.app.year
    };
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    handleOutboundClick(url) {
      const parts = url.split('/');
      const urlDomain = parts[2];
      const linkText = parts.slice(3).join(' ').replace(/-/g, ' ');
      GoogleAnalytics.sendOutboundClickEvent(linkText, url, urlDomain);
    },
    handleMenuNavEvent(menuName, menuType) {
      GoogleAnalytics.sendMenuNavEvent(menuName, menuType);
    },
    handlePromoClickEvent(buttonText) {
      GoogleAnalytics.sendPromoClickEvent(buttonText);
    },
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
