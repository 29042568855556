<template>
    <article @click.stop="goToMultimedia(multimedia.url)" :style="multimedia.image ? { 'background-image': `url(${ parseImage(multimedia.image) })`} : ''">
        <button v-if="votingState != 'countdown'"
            @click.stop="hasVoted ? unvote(multimedia.id, multimedia.congress_id) : displayVoteButtons()" 
            :class="hasVoted ? `people-choice-content-section-card__voted-button ${activeVotedMedal ?? ''}` : 'people-choice-content-section-card__vote-button'"
            @mouseover="isVoteButtonHovered = true"
            @mouseleave="isVoteButtonHovered = false"
            :style="hasVoted ? 'width: 145px;' : ''" 
        >
            <span v-if="hasVoted && isVoteButtonHovered">
                <unvoteIconSvg />
                Unvote
            </span>
            <span v-else>
                {{ hasVoted ? getMedalText() : 'Vote' }}
            </span>
        </button>
        <div v-if="showVoteButtons" class="people-choice-content-section-card__vote-button__medals">
            <button v-if="!isMedalUsed(5)" @click.stop="vote(multimedia.id, 5, multimedia.congress_id, $event)" class="people-choice-content-section-card__vote-button__medals__gold">🥇</button>
            <button v-if="!isMedalUsed(2)" @click.stop="vote(multimedia.id, 2, multimedia.congress_id, $event)" class="people-choice-content-section-card__vote-button__medals__silver">🥈</button>
            <button v-if="!isMedalUsed(1)" @click.stop="vote(multimedia.id, 1, multimedia.congress_id, $event)" class="people-choice-content-section-card__vote-button__medals__bronze">🥉</button>
        </div>
        <div class="people-choice-content-section-card__tag" :style="'background-color:' + multimedia.card_color + ';'">
            <span class="people-choice-content-section-card__tag__icon" :class="multimedia.card_icon"></span>
            <span class="people-choice-content-section-card__tag__text"> {{ multimedia.card_type ? multimedia.card_type : multimedia.multimedia_type_str }}</span>
        </div>
        <p class="people-choice-content-section-card__text">{{ multimedia.title }}</p>
    </article>
</template>

<script>
import { ref, computed } from "vue";
import TranslateService from "../../../../core/services/translate.service";
import ActivityService from '../../../../core/services/activity.service.js';
import { useVotingStore } from "../../../../store/voting.store";
import unvoteIconSvg from "./unvoteIconSvg.vue";
import confetti from "canvas-confetti";
import GoogleAnalytics from "../../../../GoogleAnalytics";

export default {
    name: "PeopleChoiceContentCard",
    components: { unvoteIconSvg },
    props: {
        multimedia: Object,
    },
    setup(props) {
        const translator = TranslateService.create();
        const t = (tag) => translator.translate(tag);
        const activitySvc = ActivityService.create();

        const isLoading = ref(true);
        const showVoteButtons = ref(false);
        const multimedia = props.multimedia;
        const votingStore = useVotingStore();
        const votingState = computed(() => votingStore.votingState);
        const isVoteButtonHovered = ref(false);

        const getMedalText = () => {
            return activeVotedMedal.value === 'gold' ? '🥇 Voted' 
                 : activeVotedMedal.value === 'silver' ? '🥈 Voted' 
                 : activeVotedMedal.value === 'bronze' ? '🥉 Voted' 
                 : 'Voted';
        };

        const displayVoteButtons = () => {
            showVoteButtons.value = !showVoteButtons.value;
            GoogleAnalytics.sendExpandContentEvent('display-vote-buttons');
        }

        const vote = (multimediaId, medal, congressId, event) => {
            votingStore.vote(multimediaId, medal, congressId);
            showVoteButtons.value = false;
            launchConfetti(event);
            GoogleAnalytics.sendSaveContentEvent(multimedia.title, multimedia.type, 'vote');
        };

        const unvote = (multimediaId, congressId) => {
            votingStore.unvote(multimediaId, congressId);
            GoogleAnalytics.sendSaveContentEvent(multimedia.title, multimedia.type, 'unvote');
        };

        const isMedalUsed = (points) => {
            return votingStore.votes.some(vote => vote.voting_points == points && vote.multimedia_id !== multimedia.id);
        };

        const hasVoted = computed(() => {
            return votingStore.votes.some(vote => vote.multimedia_id === multimedia.id);
        });

        const activeVotedMedal = computed(() => {
            const activeVote = votingStore.votes.find(vote => vote.multimedia_id === multimedia.id);
            if (!activeVote) return "";

            switch (activeVote.voting_points) {
                case 1:
                    return "bronze";
                case 2:
                    return "silver";
                case 5:
                    return "gold";
                default:
                    return "";
            }
        });

        const launchConfetti = (event) => {
            const { clientX: x, clientY: y } = event;
            confetti({
                particleCount: 100,
                spread: 60,
                origin: {
                    x: x / window.innerWidth,
                    y: y / window.innerHeight,
                },
            });
        };

        const saveActivity = (event) => {
            let params = {
                title: multimedia.title,
                model_type: multimedia.type,
                model_id: multimedia.id,
                congress_id: window.app.event ? window.app.event.id : null
            };
            activitySvc.saveActivity(event, params);
        };

        const getActivityType = () => {
            switch (multimedia.type) {
            case 'multimedia':
                return 'open-multimedia';
            case 'congress':
                return 'enter-congress';
            case 'journey':
                return 'enter-kj';
            }
        };

        const goToMultimedia = (url) => {
            GoogleAnalytics.sendOpenContentEvent(multimedia.title, multimedia.type);
            if (multimedia.type !== 'agenda_session' && multimedia.type !== 'agenda') {
                if (multimedia.external_redirect) {
                    document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: multimedia.external_url }));       
                } else {
                    saveActivity(getActivityType());
                    return window.open(url, '_blank').focus();
                }
            } else {
                if (multimedia.cards_behaviour == 'external link') {
                    document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: multimedia.external_link }));   
                } else if (multimedia.cards_behaviour == 'open modal') {
                    let agendaURL = '';
                if ((multimedia.is_live && multimedia.is_accessible && !multimedia.external_congress)
                    || (multimedia.is_on_demand  && multimedia.is_accessible && !multimedia.external_congress && multimedia.external_link != '')) {
                    if(multimedia.meeting_platform == 'ZOOM' && window.app.env.ENABLE_LIVE){
                        agendaURL = window.app.env.LIVE_URL+'/zoom/'+multimedia.slug+'/'+multimedia.id+'?encryptsKey=live-encrypts'+window.user.id;
                    }else if(multimedia.meeting_platform == 'WEBEX' && window.app.env.ENABLE_LIVE){
                            agendaURL = window.app.env.LIVE_URL+'/webex/'+multimedia.slug+'/'+multimedia.id+'?encryptsKey=live-encrypts'+window.user.id;
                    }else{
                        agendaURL = TranslationService.transRoute('event-detail-live', {
                            slug: multimedia.slug,
                            agendaSessionId: multimedia.id
                        });
                    }
                    saveActivity('live');
                } else {
                    agendaURL = TranslationService.transRoute('event-agenda-detail', {
                        slug: multimedia.slug,
                        agendaSessionId: multimedia.id
                    });
                    saveActivity('agenda');
                }
                    return window.open(agendaURL, '_blank').focus();
                }
            }
        }

        const parseImage = (img) => {
            if (img && img.substr(0,1) != '/') {
                img = '/' + img;
            }
            return img;
        }

        const isMobile = () => {
            try {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                );
            } catch (error) {
                console.error("Error determining if the device is mobile:", error);
                return false;
            }
        };

        return {
            t,
            isMobile,
            isLoading,
            multimedia,
            parseImage,
            showVoteButtons,
            displayVoteButtons,
            votingStore,
            votingState,
            isMedalUsed,
            hasVoted,
            vote,
            unvote,
            activeVotedMedal,
            isVoteButtonHovered,
            getMedalText,
            unvoteIconSvg,
            goToMultimedia,
        };
    },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>