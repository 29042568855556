<template>
  <div>
    <div class="v4-card" :class="multimedia_category_class" @click="cardRedirect(link)">
      <header class="v4-card--header">
        <span class="v4-tag" v-if="is_new">
          {{ t('general.new-in') }}
        </span>
        <div v-if="type == 'multimedia'">
          <div class="v4-tag__multimedia" :style="'background-color:' + card_color + ';'">
            <span :class="'v4-tag__multimedia__' + multimedia_type_str">
              <span :class="card_icon" style="margin-right: 3px;"></span>
              <small class="text-tag">{{ card_type ? card_type : multimedia_type_str }} </small>
            </span>
          </div>
        </div>
        <div v-else-if="type == 'congress'" class="v4-tag__event" :style="'background-color:' + card_color + ';'">
          <span class="v4-tag__event--congress">
            <span :class="card_icon" style="margin-right: 3px;"></span>
            <small class="text-tag">{{ card_type }}</small>
          </span>
        </div>
        <div v-else-if="type == 'journey'" class="v4-tag__event" :style="'background-color:' + card_color + ';'">
          <span class="v4-tag__event--knowledge">
            <span class="bi bi-journal-text" style="margin-right: 3px;"></span>
            <small class="text-tag">Knowledge</small>
          </span>
        </div>
        <div
          v-else-if="type == 'agenda' || type == 'agenda_session'"
          class=""
          :style="'background-color:' + this.$attrs.card_color + ';'"
        >
          <span
            :class="
              multimedia_type_str === 'Symposium' ? 'v4-tag__event--symposium' : 'v4-tag__event--agendarecommendations'
            "
            >{{ card_type ? card_type : multimedia_type_str }}</span
          >
        </div>
        <div v-else class="v4-tag__event" :style="'background-color:' + this.$attrs.card_color + ';'">
          <span class="v4-tag__event--congress">
            <span :class="card_icon" style="margin-right: 3px;"></span>
            <small class="text-tag">{{ card_type }}</small>
          </span>
        </div>
        <div class="v4-tag-live" v-if="is_live && !external_congress">
          <span class="live-oval">
            ···
          </span>
          <span>
            live now!
          </span>
        </div>
        <div class="v4-tag-ondemand" v-else-if="is_on_demand">
          <span>
            On Demand
          </span>
        </div>
      </header>

      <article class="v4-card--body">
        <p class="v4-card--body--date">{{ defaultDate }}</p>
        <h3 class="v4-card--body--title">
          <a v-if="type !== 'agenda_session' && type !== 'agenda'" href="javascript:void(0)" v-html="defaultTitle"></a>
          <a v-else href="javascript:void(0)" v-html="defaultTitle"></a>
        </h3>
      </article>

      <footer class="v4-card--footer limit-3-rows pb-0">
        <a
          v-if="type == 'agenda_session' || type == 'agenda'"
          :href="preventOpen ? 'javascript:void(0)' : link"
          :target="preventOpen ? '' : '_blank'"
          class="agenda-card-speakers"
          >{{ speakersList }}</a
        >
        <a
          v-for="(kw, index) in keywordList"
          :key="index"
          class="v4-card--hash"
          href="javascript:void(0)"
          @click.stop="filterKeywords(kw.id, kw.name)"
          >#{{ kw.name }}</a
        >
      </footer>
      <CardFooter
        :id="id"
        :title="title"
        :slug="slug"
        :type="type"
        :isPast="isPast"
        :liked="liked"
        :disliked="disliked"
        :saved="saved"
        :link="link"
        :description="description"
        :startDate="startDate"
        :endDate="endDate"
        :agendaTimezone="agendaTimezone"
        :isLive="is_live"
        :isBookASeat="isBookASeat"
        :isRegistered="isRegistered"
        :isOnDemand="is_on_demand"
      >
    </CardFooter>
    </div>
    <div v-if="avTags" class="searchTags">
      <span v-for="(tag, index) in avTags" :key="index">
        <span v-if="index == 0" class="tag">{{ t('home.grid.search-tags') }}&nbsp;</span>
        <span v-if="index != avTags.length - 1 || msTags.length > 0" class="available text-capitalize"
          >{{ tag[0] }},&nbsp;</span
        >
        <span v-else class="available text-capitalize">{{ tag[0] }}</span>
      </span>
      <span v-if="msTags.length > 0">
        <span v-for="(mTag, index) in msTags" :key="index">
          <span class="missing text-capitalize">{{ mTag[0] }}</span>
          <span v-if="index != msTags.length - 1">,&nbsp;</span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import TranslationService from '../../core/services/translation.service';
import ActivityService from '../../core/services/activity.service';
import GoogleAnalytics from '../../GoogleAnalytics';
import CardFooter from './CardFooter.vue';

export default {
  name: 'V4Card',
  components: {
    CardFooter
  },
  props: {
    title: String,
    date_formatted: String,
    slug: String,
    link: String,
    keywords: Array,
    shortened_keywords: Array,
    is_new: Boolean,
    is_live: Boolean,
    is_on_demand: Boolean,
    is_multimedia: Boolean,
    multimedia_type_str: String,
    disliked: [Number, Boolean],
    liked: [Number, Boolean],
    saved: [Number, Boolean],
    isPast: [Number, Boolean],
    id: Number,
    type: String,
    cards_behaviour: String,
    multimedia_category_id: Number,
    multimedia_category_class: String,
    card_type: String,
    card_color: String,
    card_icon: String,
    from_type: String,
    speakersList: String,
    external_redirect: Boolean,
    external_url: String,
    availableTags: Array,
    missingTags: Array,
    is_accessible: Boolean,
    external_congress: Boolean,
    multimedias: Array,
    preventOpen: Boolean,
    meeting_platform: String,
    description: String,
    startDate: String,
    endDate: String,
    agendaTimezone: String,
    is_live: Boolean,
    isBookASeat: Boolean,
    isRegistered: Boolean,
    is_on_demand: Boolean
  },
  data() {
    return {
      defaultTitle: this.title,
      defaultDate: this.date_formatted,
      base_link: '/events/',
      /*  keywordList: this.shortened_keywords, */
      keywordList: this.keywords,
      avTags: this.availableTags,
      msTags: this.missingTags,
      translator: TranslateService.create(),
      activitySvc: ActivityService.create()
    };
  },
  computed: {
    multimediaTypeToLower() {
      return this.multimedia_type_str.toLowerCase();
    }
  },

  mounted() {},

  methods: {
    asString(obj) {
      try {
        return JSON.stringify(obj);
      } catch (e) {
        return 'F, []';
      }
    },

    onMultimediaClick() {
      const dataTracks = this.$attrs.dataTracks || [];
      // Save item to localStorage:
      console.debug(this.$attrs, this);
      localStorage.setItem(`datatracks-m-${this.id}`, JSON.stringify(dataTracks));
      return true;
    },

    parseCardBehavoiour() {},
    t(tag) {
      return this.translator.translate(tag);
    },
    getCardType(type) {
      if (type == 'Standalone') {
        return 'general.c-internal-card';
      } else {
        return 'general.c-external-card';
      }
    },
    filterKeywords(id, keyword) {
      let url = TranslationService.transRoute('search-page');
      if (this.from_type == 'profilesub' || this.from_type == 'content') {
        this.$emit('kwchange', id);
      } else if (this.from_type == 'bookmark') {
        var obj = { key_id: id, key_name: keyword };
        this.$emit('words', obj);
      }
      //pending to send ecripyed keyword to results page
      GoogleAnalytics.sendPromoClickEvent(keyword);
      GoogleAnalytics.sendFilterEvent('keyword', this.from_type ?? 'content', keyword)
      return window.open(url, '_blank').focus();
    },
    cardRedirect(url) {
      if (this.preventOpen) {
        return;
      }

      if (this.type !== 'agenda_session' && this.type !== 'agenda') {
        this.onMultimediaClick();
        if (this.cards_behaviour == 'disable modal') {
          return;
        }
        if (this.external_redirect) {
          // this.$root.$emit('leavingMMPModal', this.external_url);
          document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.external_url }));       
        } else {
          this.saveActivity(this.getActivityType());
          return window.open(url, '_blank').focus();
        }
      } else {
        if (this.cards_behaviour == 'external link') {
          // this.$root.$emit('leavingMMPModal', this.$attrs.external_link);
          document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.$attrs.external_link }));   
        } else if (this.cards_behaviour == 'open modal') {
          let agendaURL = '';
          if ((this.is_live && this.is_accessible && !this.external_congress)
              || (this.is_on_demand  && this.is_accessible && !this.external_congress && this.$attrs.external_link != '')) {
            if(this.meeting_platform == 'ZOOM' && window.app.env.ENABLE_LIVE){
              agendaURL = window.app.env.LIVE_URL+'/zoom/'+this.slug+'/'+this.id+'?encryptsKey=live-encrypts'+window.user.id;
            }else if(this.meeting_platform == 'WEBEX' && window.app.env.ENABLE_LIVE){
                      agendaURL = window.app.env.LIVE_URL+'/webex/'+this.slug+'/'+this.id+'?encryptsKey=live-encrypts'+window.user.id;
            }else{
              agendaURL = TranslationService.transRoute('event-detail-live', {
              slug: this.slug,
              agendaSessionId: this.id
            });
            }
            this.saveActivity('live');
          } else {
            agendaURL = TranslationService.transRoute('event-agenda-detail', {
              slug: this.slug,
              agendaSessionId: this.id
            });
            this.saveActivity('agenda');
          }
          return window.open(agendaURL, '_blank').focus();
        }
      }
    },
    saveActivity(event) {
      let params = {
        title: this.title,
        model_type: this.type,
        model_id: this.id,
        congress_id: window.app.event ? window.app.event.id : null
      };
      this.activitySvc.saveActivity(event, params);
    },
    getActivityType() {
      switch (this.type) {
        case 'multimedia':
          return 'open-multimedia';
        case 'congress':
          return 'enter-congress';
        case 'journey':
          return 'enter-kj';
      }
    }
  },
};
</script>
