<template>
  <div>
    <div id="left-side-page" class="row h-100">
      <div id="pre-home-welcome-text" class="col-md-12 col-lg-6 d-lg-block">
        <VParticleBall />
      </div>
      <div class="col-md-12 col-lg-6 my-auto">
        <div id="login-register-box">
          <div class="login-selector d-flex flex-column mb-5 justify-content-between">
            <h2 class="pre-home-title mb-4">{{ t('register-mmp.hcp-title') }}</h2>
            <p class="pre-home-text mb-3">{{ t('register-mmp.hcp-text') }}</p>
            <h3 class="mb-2">Country</h3>
            <select
              v-if="dataReady"
              class="select selectpicker pre-home-country-btn mb-3"
              v-model="selectedCountryId"
              @change="onCountrySelected"
              data-live-search="true"
              :title="t('pre-home.country-select-placeholder')"
              :data-live-search-placeholder="t('general.search')"
              data-testid="select-country-pre-home"
              id="pre-home-country-btn"
            >
              <option default value="-1" hidden>{{ t('satellital.select-a-country') }}</option>
              <option :key="index" v-for="(country, index) of receivedCountries" :value="country.id">{{ country.name }}</option>
            </select>
            <div v-else class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>

            <button class="v4-btn-primary pre-home-login-button"
              :class="{ 'disabled': selectedCountryId == -1 }"
              :disabled="selectedCountryId == -1"
              data-testid="pre-home-login-button"
              @click="gotoLogin()"
            >{{ t('register-mmp.login-prehome-btn') }}
            </button>

            <p class="mt-3 pre-home-register-text" :class="{ 'pre-home-register-inactive': selectedCountryId == -1, '': selectedCountryId != -1 }">
              {{ t('pre-home.new') }} <a class="register-btn" @click="gotoRegister()" :class="{ 'pre-home-register-inactive': selectedCountryId == -1, 'register-btn-active': selectedCountryId != -1 }">{{ t('register-mmp.register-prehome-btn') }}</a>
            </p>

            <div class="mt-4" v-if="integrationMessage"> {{ integrationMessage }}</div>

            <div class="border-top border-white my-5"></div>

            <h2 class="pre-home-title mb-4">{{ t('register-mmp.amgen-staff-title') }}</h2>

            <button data-testid="pre-home-okta-button" class="v4-btn-primary mx-auto" @click="oktaLogin()">{{ t('register-mmp.okta-access-button') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TranslateService from '../../core/services/translate.service';
import TranslationService from '../../core/services/translation.service';
import ActivityService from '../../core/services/activity.service';
import $ from 'jquery'
import VParticleBall from '../particle-ball/VParticleBall.vue';
import axios from 'axios';

export default {
  data() {
    return {
      translator: TranslateService.create(),
      activitySvc: ActivityService.create(),
      receivedCountries: [],
      country: {},
      selectedCountryId: '-1',
      integrationMessage: '',
      dataReady: false,
    };
  },
  mounted() {
    this.getCountries(); 
  },
  methods: {
    showForm(form) {
      if (form == 'login') {
        this.showRegister = false;
        this.showLogin = true;
      } else if (form == 'register') {
        this.showLogin = false;
        this.showRegister = true;
      }
    },
    getCountries(){
      axios
        .get('/get-countries')
        .then(response => {
          if(response.data && response.data.countries){
            this.receivedCountries = response.data.countries;
            this.dataReady = true;
            setTimeout(() => {
              $('#pre-home-country-btn').val(-1);
              $('.selectpicker').selectpicker('refresh');
            }, 400);
          }
        })
        .catch(error => {
          console.error('getCountries', error);
        });
    },
    oktaLogin() {
      axios.get(
        '/login/okta'
      ).then(response => {
        document.location.href = response.data.oktaRedirect;
      });
    },
    gotoRegister() {
      if (this.selectedCountryId == -1) {
        return;
      }

      this.saveActivityAndRedirect('go-to-register');
      window.location.href = TranslationService.transRoute('global-register') + '?country=' + this.selectedCountryId;
    },
    gotoLogin() {
      if (this.selectedCountryId == -1) {
        return;
      }
      this.saveActivityAndRedirect('go-to-login');

      if (this.country.idm) {
        this.integrationMessage = 'Pending to integrate IDM';
        return;
      }
      window.location.href = `/mmp-login?country=${this.selectedCountryId}`;
    },
    saveActivityAndRedirect(activityType) {
      this.activitySvc.saveActivity(activityType, {
        model_type: 'country',
        model_id: this.selectedCountryId,
      });
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    getSelectedCountry() {
      return this.receivedCountries.find(country => country.id === this.selectedCountryId);
    },
    onCountrySelected() {
      this.country = this.getSelectedCountry();
    },
  },
  components: {
    VParticleBall
  }
};
</script>
