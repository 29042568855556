import Utils from '../../../v4/Utils';
import LiveUtils from './Utils';
import WordCloudController from '../../../v4/WordCloudController';
import Translate from '../../../v4/Translations';
import { WSHelper } from '../../../../libs/ws/ws-helper';
import Sortable from 'sortablejs';
import LiveReactionsController from '../live/LiveReactionsController';
import Toastify from 'toastify';
// import eventCallerService from '../../core/services/eventCaller.service';
import DOMPurify from 'dompurify';
import axios from 'axios';
import $ from 'jquery';

const LiveBackPollsController = {
  pollButtonAction: {
    selector: '.poll-action-js'
  },
  pollActionButtons: {
    publicAnswers: {
      selector: 'button.poll-action-js[data-action=3]'
    },
    closeSurvey: {
      selector: 'button.poll-action-js[data-action=2]'
    },
    publicSurvey: {
      selector: 'button.poll-action-js[data-action=1]'
    },
    editSurvey: {
      selector: 'button.poll-action-js[data-action=edit]'
    },
    editPoll: {
      selector: 'button.poll-action-js[data-action=edit-poll]'
    },
    cancelEditPoll: {
      selector: 'button.poll-action-js[data-action=cancel-edit-poll]'
    },
    saveChanges: {
      selector: 'button.poll-action-js[data-action=save]'
    },
    deleteSurvey: {
      selector: 'button.poll-action-js[data-action=delete]'
    },
    viewAnswersAs: {
      selector: 'button.poll-action-js[data-action=change-type]'
    }
  },
  pollStatusEl: {
    selector: '.poll-item-status-js'
  },
  wordcloudCanvasEl: {
    selector: '#wordcloud-canvas-'
  },
  formNewPoll: {
    selector: '#moderator-new-poll'
  },
  addOptionButton: {
    selector: '.poll-add-option-js'
  },
  removeOptionButton: {
    selector: '.poll-remove-option-js'
  },
  recoverOptionButton: {
    selector: '.poll-recover-option-js'
  },
  newOptionEl: {
    selector: '.poll-new-option'
  },
  optionInputs: {
    selector: '.congress-live-back-moderator-form-options input.input-question'
  },
  closeSurveyDisabled: {
    selector: '[data-action=2]:not(:disabled)'
  },
  pollEl: {
    selector: '.poll-item-container-js'
  },
  pollTitleEl: {
    selector: '.congress-live-back-moderator-polls-item-header-title'
  },
  pollsToggleEl: {
    selector: '.congress-live-back-moderator-polls-item-header-toggle-js'
  },
  pollsItemContainer: {
    selector: '.congress-live-back-moderator-polls-item-container'
  },
  pollResults: {
    selector: '.poll-item-content-js'
  },
  forceSpeakerViewBtn: {
    selector: '#moderator-polls-force-btn'
  },
  orderSpeakerQuestions: {
    order: {
      selector: '.congress-live-card-speaker-btn.order'
    },
    save: {
      selector: '.congress-live-card-speaker-btn.save'
    },
    cancel: {
      selector: '.congress-live-card-speaker-btn.cancel'
    }
  },
  questionItem: {
    selector: '.congress-live-back-ask-list-item'
  },editqtnItem: {
    selector: '.edit-question-item'
  },editqtnsaveItem: {
    selector: '.save_question_item'
  },validateQtnItem:{
    selector: '.send_to_validate'
  },discardQtnItem:{
    selector: '.send_to_discard'
  },cancelQtnItem:{
    selector: '.cancel_question_item'
  },
  sortableQuestions: null,
  routes: {
    getPollResults: 'get-poll-answers',
    createPoll: 'create-poll',
    changePollStatus: 'change-poll-status',
    deletePoll: 'delete-poll'
  },
  timer: {
    FULL_DASH_ARRAY: 283,
    TIME_LIMIT: window.app.event ? window.app.event.time_limit || 20 : 20,
    timePassed: 0,
    timeLeft: window.app.event ? window.app.event.time_limit || 20 : 20,
    timerInterval: null
  },
  pollExpirationData: {},

  init() {

    this.setListeners();
    // this.initSetIntervals();
    this.initWordclouds();
    this.initWebSockets();
    this.initSurveyEvents();
    try {
      this.pollExpirationData = JSON.parse(
        sessionStorage.getItem(`moderator-expiration-data-${Utils.getAgendaSessionId()}`)
      );
    } catch (e) {
      this.pollExpirationData = {};
    }
    this.initPollExpirationInterval();
  },

  setListeners() {
    $(document).on('click', this.pollButtonAction.selector, ev => this.pollButtonActionHandler(ev));
    $('#moderator-new-poll').submit(ev => this.createPollHandler(ev));
    $(document).on('click', this.addOptionButton.selector, ev => this.addOption(ev));
    $(document).on('click', this.removeOptionButton.selector, ev => this.removeOption(ev));
    $(document).on('click', this.recoverOptionButton.selector, ev => this.recoverOption(ev));
    $(document).on('click', this.pollsToggleEl.selector, ev => this.pollsToggle(ev));
    $(document).on('click', this.pollActionButtons.closeSurvey.selector, ev => {
      this.closeSurvey(ev);
    });
    //Force speakers view
    $(document).on('click', this.forceSpeakerViewBtn.selector, ev => this.forceSpeakerView(ev));
    this.orderQuestionsListeners();
    // LiveReactionsController.init();
    //new actions
    $(document).on('click', this.editqtnItem.selector, ev => this.editBox(ev));
    $(document).on('click', this.editqtnsaveItem.selector, ev => this.saveQtn(ev));
    $(document).on('click', this.validateQtnItem.selector, ev => this.actionQtn(ev));
    $(document).on('click', this.discardQtnItem.selector, ev => this.actionQtn(ev));
    $(document).on('click', this.cancelQtnItem.selector, ev => this.editBox(ev));
  },

  initRequests() {},

  getDomGroup(target) {
    const parent = target.closest('.congress-live-back-moderator-surveys-item');
    return parent.querySelector('.congress-live-survey-polls-container');
  },
  fillInitialSurveyPagination() {
    const items = document.querySelectorAll(".surveysPagination");
    items.forEach(item => {
      const domGroup = this.getDomGroup(item);
      item.innerHTML = `1/${domGroup.children.length}`;
    });
  },
  initSurveyEvents() {
    const getDomPagination = target => {
      const parent = target.closest('.congress-live-back-moderator-surveys-item');
      return parent.querySelector('.surveysPagination');
    };
    const movePage = (target, domGroup, direction = 'forward') => {
      const nodes = domGroup.children;
      const nodeSize = nodes.length;
      let activeItem = -1;
      for (let i = 0; i < nodeSize; i++) {
        const node = nodes[i];
        if(!node.classList.contains("sr-only")) {
          activeItem = i;
        }
        node.classList.add("sr-only");
      }
      let nextItem = activeItem;
      if(direction === 'forward') {
        nextItem = activeItem + 1 >= nodeSize ? 0 : activeItem + 1;
      } else {
        nextItem = activeItem - 1 < 0 ? (nodeSize - 1) : activeItem - 1;
      }
      nodes[nextItem].classList.remove("sr-only");
      const pagination = getDomPagination(target);
      pagination.innerHTML = `${nextItem + 1}/${nodeSize}`;
    };
    console.debug('pre survey events');
    this.fillInitialSurveyPagination();
    $('body').on('click', '.surveys-btn-left', ev => {
      console.debug('Event received', 'left');
      const domGroup = this.getDomGroup(ev.currentTarget);
      movePage(ev.currentTarget, domGroup, 'backward');
    });
    $('body').on('click', '.surveys-btn-right', ev => {
      console.debug('Event received', 'right');
      const domGroup = this.getDomGroup(ev.currentTarget);
      movePage(ev.currentTarget, domGroup, 'forward');
    });
  },

  initWebSockets() {
    this.ws = new WSHelper(true);
    this.registerWSEvents();
    this.ws
      .start()
      .then(res => {
        console.debug(res);
      })
      .catch(err => {
        console.debug("ERR", err);
      });
    try {
      const polls = $(this.pollEl.selector);
      for (const poll of polls) {
        const pollId = $(poll).data('pollid');
        this.updatePollResults(pollId, {
          success: this.updatePollResultsSuccess,
          error: this.updatePollResultsError
        });
      }
    } catch (e) {
      console.debug("ERR", e);
    }
  },

  displayToastifyMessage(type , key , value){
    if ( type == 1 ) {
      Toastify.success(key, value);
    } else {
      Toastify.error(key, value);
    }
  },

  sendWSStatusMessage() {
    const agendaSessionId = Utils.getAgendaSessionId();
    this.agId = agendaSessionId;
    this.ws.sendObject({ agenda_id: agendaSessionId, operation: 'register_ws', uid: window.user.uid });
  },
  zeroPad(num) {
    return num < 10 ? '0' + num : '' + num;
  },
  showHourglass(poll) {
    let hourglass = poll.querySelector('.countdown-hourglass');
    if (!hourglass) return;
    hourglass.classList.remove('sr-only');
  },
  hideHourglass(poll) {
    let hourglass = poll.querySelector('.countdown-hourglass');
    if (!hourglass) return;
    hourglass.classList.add('sr-only');
  },
  applyCountdownToHourglass(poll, difference) {
    let hourglass = poll.querySelector('.countdown-hourglass');
    if (!hourglass) return;
    hourglass.querySelector('.hg-min').innerHTML = this.zeroPad(Math.floor(difference / 60000));
    hourglass.querySelector('.hg-sec').innerHTML = this.zeroPad(Math.floor((difference / 1000) % 60));
  },

  initPollExpirationInterval() {
    this.timer.pollExpirationInterval = setInterval(() => {
      Object.keys(this.pollExpirationData || {}).forEach(pollKey => {
        const pollItemsClass = `.congress-live-back-moderator-polls-item[data-pollid=${poll.id}]`;
        const pollItems = document.querySelectorAll(Utils.escapeHtml(pollItemsClass));
        for (let i = 0; i < pollItems.length; i++) {
          let status = 0;
          try {
            status = parseInt(pollItems[i].getAttribute('data-status'), 10);
          } catch (e) {
            console.debug("ERR", e);
          }
          pollItems[i].setAttribute('data-expirationTime', poll.expiration_time);
          let timeLeft = poll.expiration_time * 1000 - Date.now();
          let timeLeftVisible = timeLeft < 0 || status > 1 ? 0 : timeLeft;
          const prevTimeLeft = pollItems[i].getAttribute('data-expirationState');
          pollItems[i].setAttribute('data-expirationState', timeLeftVisible);
          if (poll.expiration_time > 0 && timeLeft > -1) {
            if (status < 2) {
              this.showHourglass(pollItems[i]);
            }
            this.applyCountdownToHourglass(pollItems[i], timeLeftVisible);
            try {
              const num = parseInt(prevTimeLeft, 10);
              if (num < 2000 && status < 2) {
                this.closeSurveyById(`${poll.id}`);
              }
            } catch (e) {
              console.debug("ERR", e);
            }
          }
        }
      });
    }, 1000);

    // slowly fade unused hourglass after the poll has been closed:
    this.timer.hidePollHourglass = setInterval(() => {
      const items = document.querySelectorAll('.congress-live-back-moderator-polls-item');
      items.forEach(item => {
        try {
          const dataId = item.getAttribute('data-pollId');
          if (!!dataId) {
            const expirationTime = parseInt(item.getAttribute('data-expirationTime'), 10);
            const timeLeft = parseInt(item.getAttribute('data-expirationState'), 10);
            const status = parseInt(item.getAttribute('data-status'), 10);
            if (expirationTime === 0 || timeLeft === 0 || status > 1) this.hideHourglass(item);
          }
        } catch (e) {
          console.debug("ERR", e);
        }
      });
    }, 30000); // slowly fade away.
  },

  onWSMessageReceived(evt) {
    if (evt.json && evt.json.data) {
      /* The data object structure is freely expandable.
       * Required fields:
       * action: "show_poll", "poll_ended", "live_end", etc.
       * timestamp
       * Optional Fields:
       * html: html data (i.e, poll modal, next-session modal, etc)
       */
      const data = evt.json.data;
      //If current agenda do action
      if (this.checkIsCurrentAgenda(data.agendaId)) {
        switch (data.action) {
          case 'registered':
            this.wsToken = data.token;
            console.debug('Moderator token received, id:', this.wsToken);
            break;
          case 'next_session':
            this.getFinishedSessionInfo();
            break;
          case 'poll_ended':
            console.debug('Poll closed!', data);
            break;
          case 'speaker_question_sent':
            console.debug('The user has sent a question!', data);
            this.createOrUpdateQuestion(data);
            break;
          case 'speaker_question_answered':
              $('#answered_heading_'+data.id).addClass('content-grey-text');
              $('#content_block_'+data.id).addClass('content-grey-text');
              break;
          case 'sp_mod_poll_results':
            Object.keys(data.polls).forEach(key => {
              const response = {
                status: 200,
                data: {
                  data: {
                    poll: {
                      id: data.polls[key].id,
                      status: data.polls[key].status
                    }
                  },
                  html: data.polls[key].html,
                  question: data.polls[key].question
                }
              };
              this.updatePollResultsSuccess(response);
            });
            break;
          case 'next_session':
            //TODO
            break;
          case "live_reaction":
            LiveReactionsController.showReaction(data.message);
            break;
          default:
            console.debug('Unknown action', data.action);
            break;
        }
      }
    } else {
      console.debug('Unknown message received...');
    }
  },

  checkIsCurrentAgenda(agendaId) {
    if (!agendaId) {
      return true;
    } else {
      const agendaSessionId = Utils.getAgendaSessionId();
      return agendaSessionId == agendaId;
    }
  },

  createOrUpdateQuestion(data) {
    const agendaSessionId = Utils.getAgendaSessionId();
    //If question is from current agendaId
    if (agendaSessionId == data.agendaId) {
      //List of questions
      const el = document.querySelector('.audience_listing');
      // alert($('#main_audience_block_'+data.id).length);
      //If question already exists, update. Else create and prepend
      if (data.id && $('#main_audience_block_'+data.id).length) {
        // let questionItem = $(`${this.questionItem.selector}[data-id=${data.id}]`);
        // questionItem.replaceWith(data.html);
      } else {
        const element = document.createElement('span');
        element.innerHTML = data.html;
        el.prepend(element);
        const total = document.querySelectorAll('.question-count').length;
        document.querySelector('#audience_count').innerHTML = '('+total+')';
      }
    }
  },

  registerWSEvents() {
    if (!this.ws) return;
    this.ws.registerCallback(evt => {
      console.debug('received event!', evt);
      switch (evt.operation) {
        case 'conn_down':
          console.debug('Connection down...');
          break;
        case 'msg_received':
          // A message was received:
          this.onWSMessageReceived(evt);
          break;
        case 'conn_started':
        case 'conn_restarted':
          // Ask current status:
          this.sendWSStatusMessage();
          break;
      }
    });
  },

  initSetIntervals() {
    this.setUpdatePollResultsIntervals();
  },

  initWordclouds() {
    const polls = $(this.pollEl.selector);
    for (const poll of polls) {
      const pollId = $(poll).data('pollid');
      const type = $(poll).data('type');
      const status = $(poll).data('status');

      if (type === 3 && (status === 2 || status === 3)) {
        this.updatePollResults(pollId, {
          success: this.updatePollResultsSuccess,
          error: this.updatePollResultsError
        });
      }
    }
  },

  pollButtonActionHandler(ev) {
    const action = ev.currentTarget.dataset.action;
    const pollEl = $(ev.currentTarget).closest('[data-pollId]');
    let handlerData = { pollEl: pollEl };

    if (action === 'edit') {
      //
    } else if (action === 'edit-poll') {
      this.editPollActionView(pollEl, true);
    } else if (action === 'cancel-edit-poll') {
      this.editPollActionView(pollEl, false);
    } else if (action === 'save') {
      handlerData = { ...handlerData, callback: this.saveChangesActions };
      this.collectOptions(ev, handlerData);
    } else if (action === 'delete') {
      handlerData = { ...handlerData, callback: this.deleteSurveyActions };
      this.deletePoll(handlerData, {
        success: response => this.deletePollSuccess(response, handlerData),
        error: this.deletePollError
      });
    } else if (['1', '2', '3'].includes(action)) {
      if (action === '1') {
        handlerData = { ...handlerData, callback: this.publicSurveyActions };
      } else if (action === '2') {
        handlerData = { ...handlerData, callback: this.closeSurveyActions };
      } else if (action === '3') {
        handlerData = { ...handlerData, callback: this.publicAnswersActions };
      }
      this.changePollStatus(handlerData, action, {
        success: response => this.changePollStatusSuccess(response, handlerData),
        error: this.changePollStatusError
      });
    }
  },

  editPollActionView(pollEl, editable) {
    let url = Utils.getUrl(false, 'edit-poll-action');
    let data = new FormData();
    const pollId = pollEl.data('pollid');
    data.append('pollId', pollId);
    data.append('editable', editable);
    axios
      .post(url, data)
      .then(res => {
        if (res.status === 200) {
          let pollContainer = pollEl.find('.moderator-poll-editable-info-view');
          if (pollContainer.length) {
            //Change template (edit/view)
            var pollContainer_ = document.getElementsByClassName('moderator-poll-editable-info-view');
            pollContainer_.innerHTML(Utils.escapeHtml(res.data.html));
            //Show hide buttons
            const editPollButton = pollEl.find(this.pollActionButtons.editPoll.selector);
            const saveChangesButton = pollEl.find(this.pollActionButtons.saveChanges.selector);
            const cancelEditButton = pollEl.find(this.pollActionButtons.cancelEditPoll.selector);
            const publishButton = pollEl.find(this.pollActionButtons.publicSurvey.selector);
            if (editable) {
              //Hide edit poll and publish, show save and cancel
              Utils.disable(editPollButton);
              Utils.disable(publishButton);
              Utils.enable(saveChangesButton);
              Utils.enable(cancelEditButton);
            } else {
              //Show edit poll and publish, hide save and cancel
              Utils.enable(editPollButton);
              Utils.enable(publishButton);
              Utils.disable(saveChangesButton);
              Utils.disable(cancelEditButton);
            }
          }
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },

  setUpdatePollResultsIntervals() {
    const pollEls = $(this.closeSurveyDisabled.selector).closest('[data-pollId]');
    for (let poll of pollEls) {
      this.setUpdatePollResultsInterval(poll);
    }
  },

  setUpdatePollResultsInterval(poll) {
    const updatePollResultsInterval = setInterval(() => {
      const stillPublished = $(poll).find(this.closeSurveyDisabled.selector).length > 0;
      if (!stillPublished) {
        clearInterval(updatePollResultsInterval);
      } else {
        const pollId = poll.dataset.pollid;
        this.updatePollResults(pollId, {
          success: this.updatePollResultsSuccess,
          error: this.updatePollResultsError
        });
      }
    }, 15000);
  },

  updatePollResults(pollId, callBackFns) {
    const url = Utils.getUrl(true, this.routes.getPollResults);
    const agendaSessionId = Utils.getAgendaSessionId();
    const data = {
      agendaSessionId: agendaSessionId,
      pollId: pollId
    };
    this.makeRequest(url, data, callBackFns);
  },

  updatePollResultsSuccess(response) {
    const { data, status } = response;
    if (data && data.html && status === 200) {
      const pollId = data.data.poll.id;
      const pollStatus = data.data.poll.status;
      const poll = document.querySelector(Utils.escapeHtml(`${this.pollEl.selector}[data-pollId="${pollId}"]`));
      const pollStrStatus = this.getStrStatus(pollStatus);

      this.updatePollStatus(poll, pollStrStatus, pollStatus, pollId);
      poll.setAttribute('data-status', pollStatus);
      const pollResultsEl = poll.querySelector(this.pollResults.selector);
      const sanitizedHtml = DOMPurify.sanitize(data.html);
      pollResultsEl.innerHTML = Utils.escapeHtml(sanitizedHtml);
      //If updated question title
      if (data.question) {
        const pollTitleEl = poll.querySelector(this.pollTitleEl.selector);
        pollTitleEl.textContent = Utils.escapeHtml(data.question);
      }
      this.initWordcloud(response, pollId);
    }
  },

  getStrStatus(status) {
    const Translation = new Translate();
    let str = '';
    if (status === 0) str = Translation.translate('live', 'poll_created');
    else if (status === 1) str = Translation.translate('live', 'poll_published');
    else if (status === 2) str = Translation.translate('live', 'poll_finished');
    else if (status === 3) str = Translation.translate('live', 'answers_published');
    return str;
  },

  updatePollStatus(poll, statusStr, status, pollId) {
    $(poll)
      .find(this.pollStatusEl.selector)
      .text(statusStr);
    this.updateButtonsFromStatus(pollId, status);
  },

  updatePollResultsError(err) {
    console.debug("ERR", err);
  },

  createPollHandler(ev) {
    ev.preventDefault();
    this.createPoll(ev, {
      success: this.createPollSuccess,
      error: this.createPollError
    });
  },

  createPoll(ev, callBacksFn) {
    const url = Utils.getUrl(true, this.routes.createPoll);
    const agendaSessionId = Utils.getAgendaSessionId();
    const form = $(ev.currentTarget);
    let formSerialized = form.serializeJSON();
    formSerialized = { ...formSerialized, agendaSessionId: agendaSessionId };

    this.makeRequest(url, formSerialized, callBacksFn);
  },

  createPollSuccess(response) {
    const form = $(this.formNewPoll.selector)[0];
    const { data, status } = response;
    if (data && status === 200 && data.html) {
      const container = document.querySelector('.congress-live-back-moderator-polls-container');
      const sanitizedHtml = DOMPurify.sanitize(data.html);
      container.insertAdjacentHTML('beforeend', Utils.escapeHtml(sanitizedHtml));
    }
    form.reset();
  },

  createPollError(err) {
    console.debug("ERR", err);
  },

  changePollStatus(handlerData, action, callBacksFn) {
    const url = Utils.getUrl(true, this.routes.changePollStatus);
    const agendaSessionId = Utils.getAgendaSessionId();
    const pollId = handlerData.pollEl.data('pollid');
    // const type = handlerData.type;
    const data = {
      agendaSessionId: agendaSessionId,
      pollId: pollId,
      status: parseInt(action)
    };
    // if( status === '3' ) data.push({ type: parseInt(type) });

    this.makeRequest(url, data, callBacksFn);
  },

  changePollStatusSuccess(response, handlerData) {
    const { data, status } = response;
    if (data && status === 200 && data.html) {
      LiveUtils.openModal(LiveUtils.modalTypes.modalResponse.selector, data.html);
      if (data.answersHtml) handlerData = { ...handlerData, answersHtml: data.answersHtml };

      let sFn = handlerData.callback;

      if (typeof sFn == 'function') sFn.bind(this)(handlerData);
    }
  },

  changePollStatusError(err) {
    console.debug("ERR", err);
  },

  deletePoll(handlerData, callBacksFn) {
    const url = Utils.getUrl(true, this.routes.deletePoll);
    const agendaSessionId = Utils.getAgendaSessionId();
    const pollId = handlerData['pollEl'].data('pollid');
    const data = {
      agendaSessionId: agendaSessionId,
      pollId: pollId
    };
    this.makeRequest(url, data, callBacksFn);
  },

  deletePollSuccess(response, handlerData) {
    const { data, status } = response;
    if (data && status === 200 && data.html) {
      LiveUtils.openModal(LiveUtils.modalTypes.modalResponse.selector, data.html);

      let sFn = handlerData.callback;

      if (typeof sFn == 'function') sFn.bind(this)(handlerData);
    }
  },

  deletePollError(err) {
    console.debug("ERR", err);
  },

  publicAnswersActions(data) {
    const pollEl = document.querySelector(data.pollEl);
    const closeSurveyEl = pollEl.querySelector(this.pollActionButtons.closeSurvey.selector);
    const publicAnswersEl = pollEl.querySelector(this.pollActionButtons.publicAnswers.selector);

    Utils.enable(closeSurveyEl);
    closeSurveyEl.textContent = 'Unpublish';
    Utils.disable(publicAnswersEl);
  },

  updateButtonsFromStatus(pollId, status) {
    //Poll element and type
    const sanitizedHtml = DOMPurify.sanitize(`${this.pollEl.selector}[data-pollId=${pollId}]`);
    let pollEl = $(Utils.escapeHtml(sanitizedHtml));
    const type = pollEl.data('type');
    //Buttons
    const publicAnswersEl = pollEl.find(this.pollActionButtons.publicAnswers.selector);
    const closeSurveyEl = pollEl.find(this.pollActionButtons.closeSurvey.selector);
    const publicSurveyEl = pollEl.find(this.pollActionButtons.publicSurvey.selector);
    const editSurveyEl = pollEl.find(this.pollActionButtons.editSurvey.selector);
    const editPollEl = pollEl.find(this.pollActionButtons.editPoll.selector);
    const cancelEditEl = pollEl.find(this.pollActionButtons.cancelEditPoll.selector);
    const saveChangesEl = pollEl.find(this.pollActionButtons.saveChanges.selector);
    const deleteSurveyEl = pollEl.find(this.pollActionButtons.deleteSurvey.selector);
    const viewAnswersAsEl = pollEl.find(this.pollActionButtons.viewAnswersAs.selector);
    switch (status) {
      case 0:
        Utils.disable(publicAnswersEl);
        break;
      case 1:
        Utils.disable(editSurveyEl);
        Utils.disable(saveChangesEl);
        Utils.disable(deleteSurveyEl);
        Utils.disable(publicSurveyEl);
        Utils.disable(editPollEl);
        Utils.disable(cancelEditEl);
        Utils.enable(closeSurveyEl);
        break;
      case 2:
        Utils.disable(closeSurveyEl);
        Utils.enable(viewAnswersAsEl);
        if (type !== 4) Utils.enable(publicAnswersEl);
        break;
      case 3:
        Utils.enable(closeSurveyEl);
        Utils.disable(publicAnswersEl);
        break;
    }
  },

  closeSurveyActions(data) {
    const pollEl = document.querySelector(data.pollEl);
    const type = pollEl.dataset.type;
    const closeSurveyEl = pollEl.querySelector(this.pollActionButtons.closeSurvey.selector);
    const publicAnswersEl = pollEl.querySelector(this.pollActionButtons.publicAnswers.selector);
    const viewAnswersAsEl = pollEl.querySelector(this.pollActionButtons.viewAnswersAs.selector);

    Utils.disable(closeSurveyEl);
    Utils.enable(viewAnswersAsEl);
    if (type !== 4) Utils.enable(publicAnswersEl);
    //WS publish survey
  },

  publicSurveyActions(data) {
    const pollEl = document.querySelector(data.pollEl);
    const answersHtml = Utils.escapeHtml(data.answersHtml);
    const editSurveyEl = pollEl.querySelector(this.pollActionButtons.editSurvey.selector);
    const saveChangesEl = pollEl.querySelector(this.pollActionButtons.saveChanges.selector);
    const deleteSurveyEl = pollEl.querySelector(this.pollActionButtons.deleteSurvey.selector);
    const closeSurveyEl = pollEl.querySelector(this.pollActionButtons.closeSurvey.selector);
    const publicSurveyEl = pollEl.querySelector(this.pollActionButtons.publicSurvey.selector);
    const editPollEl = pollEl.querySelector(this.pollActionButtons.editPoll.selector);
    const cancelEditEl = pollEl.querySelector(this.pollActionButtons.cancelEditPoll.selector);

    Utils.disable(editSurveyEl);
    Utils.disable(saveChangesEl);
    Utils.disable(deleteSurveyEl);
    Utils.disable(publicSurveyEl);
    Utils.disable(editPollEl);
    Utils.disable(cancelEditEl);
    Utils.enable(closeSurveyEl);
    const sanitizedHtml = DOMPurify.sanitize(answersHtml);
    pollEl.querySelector(this.pollResults.selector).innerHTML = Utils.escapeHtml(sanitizedHtml);

    // this.setUpdatePollResultsInterval(pollEl[0]);
    //WS publish survey
    this.publishSurvey();
  },

  initWordcloud(response, pollId) {
    const sanitizedHtml = DOMPurify.sanitize(`${this.wordcloudCanvasEl.selector}${pollId}`);
    const selector = Utils.escapeHtml(sanitizedHtml);
    if ($(selector).length && response.data) {
      const data = response.data.data.answers.answers.wordcloud;
      WordCloudController.initWordcloud(data, pollId);
    }
  },

  editSurveyActions(data) {},

  saveChangesActions(data) {
    const pollEl = document.querySelector(Utils.escapeHtml(data.pollEl));
    const type = document.querySelector(Utils.escapeHtml(data.pollEl)).dataset.type;
    const question = data.question;
    const options = data.options;
    const publishSurveyEl = pollEl.querySelector(this.pollActionButtons.publicSurvey.selector);
    // const htmlEdit = data.htmlEdit;

    if (type === '1' || type === '4') {
      if (options.length >= 2) Utils.enable(publishSurveyEl);
      else Utils.disable(publishSurveyEl);
    }

    pollEl.querySelector(this.pollTitleEl.selector).textContent = question;
    // pollEl.querySelector(this.pollResults.selector).innerHTML = htmlEdit;
    this.editPollActionView(data.pollEl, false);
  },

  deleteSurveyActions(data) {
    Utils.hideElement(data.pollEl);
  },

  collectOptions(ev, handlerData) {
    const button = $(ev.currentTarget);
    const container = button.closest('.poll-item-container-js');
    const inputs = container.find(this.optionInputs.selector);
    const optionsArr = this.buildOptionsArr(inputs);
    const pollId = container.data('pollid');
    const questionInputValue = container.find('.congress-live-back-moderator-form-input-question > input').val();
    const nextAgendaSessionId = container
      .find('.congress-live-back-moderator-form-input-next-agenda-session > select')
      .val();
    const nextPrivateUserSession = container
      .find('.congress-live-back-moderator-form-input-next-agenda-session > input[type="checkbox"]')
      .prop('checked');
    const nextPollId = container.find('.congress-live-back-moderator-form-input-next-poll > input').val();

    const optionsNotToDelete = this.getNotToDeleteOptions(optionsArr);

    const data = {
      question: questionInputValue,
      pollId: pollId,
      options: optionsArr,
      nextPollId: parseInt(nextPollId) || null,
      nextAgendaSessionId: parseInt(nextAgendaSessionId) || null,
      nextPrivateUserSession: nextPrivateUserSession || false
    };

    handlerData = {
      ...handlerData,
      options: optionsNotToDelete,
      question: questionInputValue
    };

    this.updatePoll(data, {
      success: response => this.updatePollSuccess(response, handlerData),
      error: this.updatePollError
    });
  },

  buildOptionsArr(inputs) {
    let arr = [];
    for (const input of inputs) {
      const $input = $(input);
      let value = $input.val();
      let isCorrect = $input
        .parent()
        .find("input[type='checkbox']")
        .prop('checked');
      if (value) {
        let option = {};
        let id = $input.data('option-id');
        let toDelete = $input.data('option-delete');

        if (!id) {
          id = null;
          if (toDelete) continue;
        }

        option.id = id;
        option.isCorrect = isCorrect;
        option.value = value;
        option.toDelete = toDelete;

        arr.push(option);
      }
    }

    return arr;
  },

  getNotToDeleteOptions(options) {
    return options.filter(option => {
      return option.toDelete === 0;
    });
  },

  addOption(ev) {
    const button = $(ev.currentTarget);
    const newOptionInputContainer = button.closest(this.pollResults.selector).find(this.newOptionEl.selector);
    const html = newOptionInputContainer.last().clone();

    html
      .find('input')
      .val('')
      .attr('data-option-delete', 0)
      .prop('disabled', false);
    html.find(this.removeOptionButton.selector).toggleClass('d-none', false);
    html.find(this.recoverOptionButton.selector).toggleClass('d-none', true);
    html.removeClass('d-none');

    newOptionInputContainer.parent().append(html);
  },

  removeOption(ev) {
    const button = $(ev.currentTarget);
    const container = button.closest('.form-option-wrapper-js');
    const input = container.find('input');

    input.attr('data-option-delete', 1);
    input.prop('disabled', true);

    button.toggleClass('d-none');
    button.siblings(this.recoverOptionButton.selector).toggleClass('d-none');
  },

  recoverOption(ev) {
    const button = $(ev.currentTarget);
    const container = button.closest('.form-option-wrapper-js');
    const input = container.find('input');

    input.attr('data-option-delete', 0);
    input.prop('disabled', false);

    button.toggleClass('d-none');
    button.siblings(this.removeOptionButton.selector).toggleClass('d-none');
  },

  updatePoll(data, callBacksFn) {
    const url = Utils.getUrl(true, this.routes.createPoll);
    const agendaSessionId = Utils.getAgendaSessionId();
    data = { ...data, agendaSessionId: agendaSessionId };

    this.makeRequest(url, data, callBacksFn);
  },

  updatePollSuccess(response, handlerData) {
    const { data, status } = response;
    if (data && status === 200 && data.html && data.htmlEdit) {
      LiveUtils.openModal(LiveUtils.modalTypes.modalResponse.selector, data.html);
      handlerData = { ...handlerData, htmlEdit: data.htmlEdit, response: response };

      let sFn = handlerData.callback;
      if (typeof sFn == 'function') sFn.bind(this)(handlerData);
    }
  },

  updatePollError(err) {
    console.debug("ERR", err);
  },

  pollsToggle(ev) {
    const button = $(ev.currentTarget);
    button.toggleClass('closed');
    let pollContent = button.closest(this.pollEl.selector).find(this.pollsItemContainer.selector);
    pollContent.slideToggle(500);
  },

  makeRequest(url, data, callbacksFn) {
    const $req = axios.post(url, data);
    $req.then(response => {
      let sFn = callbacksFn.success;

      if (typeof sFn == 'function') sFn.bind(this)(response);
    });

    $req.catch(response => {
      let eFn = callbacksFn.error;

      if (typeof eFn == 'function') eFn.bind(this)(response);
    });
  },

  forceSpeakerView(ev) {
    let pollId = ev.currentTarget.dataset.pollid;
    let url = Utils.getUrl(false, 'force-speaker-view');
    let data = new FormData();
    data.append('agendaSessionId', Utils.getAgendaSessionId());
    data.append('pollId', pollId);
    axios
      .post(url, data)
      .then(res => {
        if (res.status === 200) {
          // LiveUtils.openModal(LiveUtils.modalTypes.modalResponse.selector, data.html);
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },

  //ORDER SPEAKERS QUESTIONS SECTION
  orderQuestionsListeners() {
    $(document).on('click', this.orderSpeakerQuestions.order.selector, ev => this.openOrderSpeakerQuestions(ev));
    $(document).on('click', this.orderSpeakerQuestions.save.selector, ev => this.saveOrderSpeakerQuestions(ev));
    $(document).on('click', this.orderSpeakerQuestions.cancel.selector, ev => this.cancelOrderSpeakerQuestions(ev));
  },

  openOrderSpeakerQuestions(ev) {
    let url = Utils.getUrl(false, 'filter-questions');
    let data = new FormData();
    data.append('agendaSessionId', Utils.getAgendaSessionId());
    data.append('editable', true);
    axios
      .post(url, data)
      .then(res => {
        let questionsContainer = $('.ask-speaker-draggable-list');
        if (questionsContainer.length) {
          //Refresh questions
          questionsContainer_ = document.getElementsByClassName('ask-speaker-draggable-list');
          questionsContainer_.innerHTML(Utils.escapeHtml(res.data.html));
          //Make them draggable
          this.sortableQuestions = Sortable.Sortable.create(questionsContainer[0], {
            animation: 150,
            ghostClass: 'blue-background-class'
          });
          //Hide order button, show cancel and apply buttons
          $(this.orderSpeakerQuestions.order.selector).hide();
          $(this.orderSpeakerQuestions.save.selector).show();
          $(this.orderSpeakerQuestions.cancel.selector).show();
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },

  saveOrderSpeakerQuestions(ev) {
    let selectedOrder = this.sortableQuestions.toArray();
    let url = Utils.getUrl(false, 'save-questions-order');
    let data = new FormData();
    data.append('agendaSessionId', Utils.getAgendaSessionId());
    data.append('orderedQuestions', selectedOrder);
    axios
      .post(url, data)
      .then(res => {
        var html = res.data.html;
        LiveUtils.openModal(LiveUtils.modalTypes.modalResponse.selector, Utils.escapeHtml(html));
        this.cancelOrderSpeakerQuestions();
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },

  cancelOrderSpeakerQuestions(ev) {
    let url = Utils.getUrl(false, 'filter-questions');
    let data = new FormData();
    data.append('agendaSessionId', Utils.getAgendaSessionId());
    data.append('editable', false);
    axios
      .post(url, data)
      .then(res => {
        let questionsContainer = $('.ask-speaker-draggable-list');
        if (questionsContainer.length) {
          //Refresh questions
          questionsContainer_ = document.getElementsByClassName('ask-speaker-draggable-list');
          questionsContainer_.innerHTML(Utils.escapeHtml(res.data.html));
          //Show order button, hide cancel and apply buttons
          $(this.orderSpeakerQuestions.order.selector).show();
          $(this.orderSpeakerQuestions.save.selector).hide();
          $(this.orderSpeakerQuestions.cancel.selector).hide();

          //Destroy sortable
          this.sortableQuestions.destroy();
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },editBox(ev){
    const action = ev.target.dataset.action;
    if(action=='cancel_question'){
      const qtnid = ev.target.dataset.qtnid;
      $('#content_block_'+qtnid+' span').text(window.qtnid);
      $('#content_block_'+qtnid).attr('contenteditable',false);
      $('#content_edit_icon_'+qtnid).show();
      $('#content_action_'+qtnid).hide();
    }else{
      const qtnid = ev.target.dataset.id;
      window.qtnid = $('#content_block_'+qtnid+' span').text();
      $('#content_block_'+qtnid).attr('contenteditable',true).focus();
      $('#content_edit_icon_'+qtnid).hide();
      $('#content_action_'+qtnid).show();
    }
  },saveQtn(ev){
    const action = ev.target.dataset.action;
    const qtnid = ev.target.dataset.qtnid;
    const agendaid = ev.target.dataset.agendaid;
    window.qtnid = $('#content_block_'+qtnid+' span').text();
    var elementId = '#content_block_' + qtnid + ' span';
    var qtn = $(Utils.escapeHtml(elementId)).text();
    this.questionActions(agendaid, qtnid, qtn, 'update');
  },actionQtn(ev){
    const action = ev.currentTarget.getAttribute('data-action');
    const qtnid = ev.currentTarget.getAttribute('data-qtnid');
    const agendaid = ev.currentTarget.getAttribute('data-agendaid');
    const question = ev.currentTarget.getAttribute('data-question');
    this.questionActions(agendaid, qtnid, question, action);
  },questionActions(sessionId, qtnId, qtn, status) {
    let url = Utils.getUrl(true, 'validate-live-questions');
    let data = new FormData();
    data.append('agendaSessionId', sessionId);
    data.append('live_question', qtnId);
    data.append('question', qtn);
    data.append('status', status);
    axios
      .post(url, data)
      .then(res => {
        if (res.status === 200) {
          if(status=='update'){
            $('#content_edit_icon_'+qtnId).show();
            $('#content_action_'+qtnId).hide();
            document.dispatchEvent(new CustomEvent("updateAudienceData"));
            // eventCallerService.$emit("updateAudienceData");
          }else if(status=='validated'){
            $('#main_audience_block_'+qtnId).remove();
            this.liveCount(sessionId);
            document.dispatchEvent(new CustomEvent("updateAudienceData"));
            // eventCallerService.$emit("updateAudienceData");
          }else if(status=='discarded'){
            $('#content_block_'+qtnId).css('text-decoration','line-through');
          }
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },liveCount(sessionId){
    var audienceArray = [];
    var ponentArray = [];
    let url = Utils.getUrl(true, 'get-live-questions');
    axios
      .post(url, {agendaSessionId: sessionId,shownIds: []})
      .then(res => {
        if (res.status === 200) {
          if(res.data.data.length>0){
            res.data.data.forEach(data => {
              if((data.status=='created') || (data.status=='discarded') || (data.status=='updated')){
                audienceArray.push(data);
              }
              if(data.status=='validated'){
                ponentArray.push(data);
              }
            });
            $('#audience_count').text('('+audienceArray.length+')');
            $('#ponent_count').text('('+ponentArray.length+')');
          }
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },
  //ORDER SPEAKERS QUESTIONS SECTION

  //Websocket methods section
  publishSurvey() {
    let url = Utils.getUrl(false, 'publish-survey');
    let data = new FormData();
    data.append('agendaSessionId', Utils.getAgendaSessionId());
    axios
      .post(url, data)
      .then(res => {
        if (res.status === 200) {
          // LiveUtils.openModal(LiveUtils.modalTypes.modalResponse.selector, data.html);
          //Enable force speakers view button
          $('#moderator-polls-force-btn').prop('disabled', false);
          this.pollExpirationData = res.data.pollData;
          sessionStorage.setItem(
            `moderator-expiration-data-${Utils.getAgendaSessionId()}`,
            JSON.stringify(this.pollExpirationData)
          );
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },
  closeSurveyById(leId) {
    let url = Utils.getUrl(false, 'close-survey');
    let data = new FormData();
    data.append('pollId', leId);
    axios
      .post(url, data)
      .then(res => {
        if (res.status === 200) {
          // LiveUtils.openModal(LiveUtils.modalTypes.modalResponse.selector, data.html);
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },
  closeSurvey(ev) {
    const leId = ev.target.getAttribute('data-id');
    this.closeSurveyById(leId);
  },

  getFinishedSessionInfo() {
    let url = Utils.getUrl(true, 'get-finished-session-info');
    let data = new FormData();
    data.append('agendaSessionId', Utils.getAgendaSessionId());
    axios
      .post(url, data)
      .then(res => {
        if (res.status === 200) {
          if (res.data.nextSession != null) {
            window.location.href = this.getRouteUrl()+'/v4/congress-live-session';
          }
        }
      })
      .catch(error => {
        console.debug("ERR", error);
      });
  },
  /**
   * this function used to get the base url
   */
  getRouteUrl(){
    let e = window.app.route.url;
    if( 'undefined' == typeof e)
      throw new Error('This config does not exists. EMC01');
    return e;
  },

  /****************
   * TIMER SECTION
   ****************/
  /**
   * When timer has finished, clear interval (avoids negative seconds)
   */
  onTimesUp() {
    clearInterval(this.timer.timerInterval);
    if ($('#next-user-session-btn').length) {
      let url = $('#next-user-session-btn').attr('href');
      window.location.replace(url);
    }
  },

  /**
   * Starts the timer (seconds and circle)
   */
  startTimer() {
    //Initialize countdown circle
    document.getElementById('next-session-countdown').innerHTML = `
        <div class="base-timer">
          <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
              <linearGradient id="countdown-linear" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%"   stop-color="#00bce4"/>
                  <stop offset="100%" stop-color="#005490"/>
              </linearGradient>
              <path
                id="base-timer-path-remaining"
                stroke-dasharray="283"
                stroke="url(#countdown-linear)"
                class="base-timer__path-remaining"
                d="
                  M 50, 50
                  m -45, 0
                  a 45,45 0 1,0 90,0
                  a 45,45 0 1,0 -90,0
                "
              ></path>
            </g>
          </svg>
        </div>
        `;

    //Interval each second
    this.timer.timerInterval = setInterval(() => {
      this.timer.timePassed = this.timer.timePassed += 1;
      this.timer.timeLeft = this.timer.TIME_LIMIT - this.timer.timePassed;
      var countdownNumberEl = document.getElementById('next-session-countdown-number');
      countdownNumberEl.textContent = this.timer.timeLeft;
      this.setCircleDasharray();

      if (this.timer.timeLeft === 0) {
        this.onTimesUp();
      }
    }, 1000);
  },

  /**
   * Formats the time to seconds
   */
  formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  },

  /**
   * Calculate time fraction (for circle)
   */
  calculateTimeFraction() {
    const rawTimeFraction = this.timer.timeLeft / this.timer.TIME_LIMIT;
    return rawTimeFraction - (1 / this.timer.TIME_LIMIT) * (1 - rawTimeFraction);
  },

  /**
   * Draws the circle progress
   */
  setCircleDasharray() {
    const circleDasharray = `${(this.calculateTimeFraction() * this.timer.FULL_DASH_ARRAY).toFixed(0)} 283`;
    document.getElementById('base-timer-path-remaining').setAttribute('stroke-dasharray', circleDasharray);
  }
};

export default LiveBackPollsController;
