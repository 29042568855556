export class WSHelper {
  onCrashActive = false;
  reConnect = false;
  reConnectErrorSent = false;
  reConnectTries = 0;
  maxConnectTries = 20;
  host = null;
  ws = null;
  callbacks = null;
  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  constructor(reConnect = false) {
    // if (!window.app.env.ACTIVE_WS) {
    //   console.log('WS disabled');
    //   throw new Error('Websocket disabled by env');
    // }
    this.host = window.wsService || "";
    if(this.host.length === 0 && (document.location.host === "med.local" || document.location.host === "localhost")) {
      this.host = "ws://localhost:8080/";
    }
    // this.host = "wss://ws.medicalmeetingpoint.com/";
    this.reConnect = reConnect;
    this.callbacks = {};
    console.debug(`Started WS Helper for Live Sessions using ${this.host}`);
  }

  getWs() {
    return this.ws;
  }

  registerCallback(callback) {
    const uuid = this.uuidv4();
    this.callbacks[uuid] = callback;
    return uuid;
  }
  unregisterCallback(uuid) {
    delete this.callbacks[uuid];
  }

  sendMessageToCallbacks(obj) {
    console.debug("[WS-Helper] Relying message: ", obj);
    Object.keys(this.callbacks).forEach(callback => {
      this.callbacks[callback](obj);
    });
  }

  createWebSocket() {
    return new Promise((resolve, reject) => {
      try {
        this.ws = new WebSocket(this.host);
        this.ws.onclose = err => {
          console.debug('[WS-Helper] [ERR] Connection to webSocket failure', err);
          if(!!reject) {
            reject({ operation: 'conn_down', success: false, raw: err, timestamp: Date.now() });
          }
        };
        this.ws.onopen = () => {
          console.debug('[WS-Helper] Connection to webSocket success');
          resolve(this.ws);
        };
      } catch (e) {
        console.debug("[WS-Helper] [ERR] Connection to webSocket failure", e);
        reject({ operation: 'conn_down', success: false, raw: err, timestamp: Date.now() });
      }
    });
  }

  addOnOpenEvent() {
    return new Promise((resolve, reject) => {
      console.debug('[WS-Helper] Added on connection open event');
      const obj = { operation: 'conn_started', success: true, timestamp: Date.now() };
      this.sendMessageToCallbacks(obj);
      resolve(true);
    })
  }

  addOnMessageEvent() {
    return new Promise((resolve, reject) => {
      this.ws.onmessage = raw => {
        let json = null;
        try {
          json = JSON.parse(raw.data);
          this.sendMessageToCallbacks({ operation: 'msg_received', success: true, raw, json, timestamp: Date.now() });
        } catch (e) {
          console.debug('[WS-Helper] JSON Data not found, skipping ', raw.data);
        }
        resolve(true);
      };
    });
  }

  addOnErrorAndOnClose(ev) {
    return new Promise((resolve, reject) => {
      this.ws.onerror = (e) => {
        console.debug("[WS-Helper] [ERR] WebSocket Oops...", e);
      };
      this.ws.onclose = (e) => {
        console.debug("[WS-Helper] [ERR] WebSocket Failure...", e);
        this.ws = null;
        if(!this.onCrashActive) {
          ev();
        }
      };
      resolve(true);
    });
  }

  onCrash() {
    this.onCrashActive = true;
    console.debug("[WS-Helper] On Crash Invoked...");
    if (this.reConnect && (this.reConnectTries < this.maxConnectTries)) {
      this.sendMessageToCallbacks({ operation: 'conn_retry', success: false, timestamp: Date.now() });
      console.debug(`[WS-Helper] Trying attempt ${this.reConnectTries + 1} of ${this.maxConnectTries}`);
      setTimeout(() => {
        this.start()
        .then(res => {
          this.onCrashActive = false;
          this.reConnectTries = 0;
          this.sendMessageToCallbacks({ operation: 'conn_retry_success', success: true, timestamp: Date.now() });
          this.sendMessageToCallbacks({ operation: 'conn_restarted', success: true, timestamp: Date.now() });
        }).catch(err => {
          this.reConnectTries++;
          this.sendMessageToCallbacks({ operation: 'conn_retry_failure', success: false, timestamp: Date.now() });
          this.onCrash();
        });
      }, 1000 + (this.reConnectTries * 500));
    } else {
      const obj = { operation: 'conn_failure', success: false, timestamp: Date.now() };
      this.sendMessageToCallbacks(obj);
    }
  }

  start() {
    return new Promise((resolve, reject) => {
      console.debug("[WS-Helper] Start invoked...");
      this.createWebSocket()
      .then(() => this.addOnOpenEvent())
      .then(() => this.addOnMessageEvent())
      .then(() => this.addOnErrorAndOnClose(this.onCrash.bind(this)))
      .then(() => {
        const obj = { operation: 'conn_started', success: true, timestamp: Date.now() };
        this.sendMessageToCallbacks(obj);
        resolve(obj);
      })
      .catch(err => {
        console.debug("Start Chain failure", err);
        if(!this.onCrashActive) {
          this.onCrash();
        }
        reject(err);
      });
    });
  }

  sendObject(obj) {
    if(!this.ws) {
      console.debug(`[WS-Helper] Socket connection not initialized`);
      return false;
    }
    this.ws.send(JSON.stringify(obj));
    return true;
  }
}
