<template>
<div class="agendaHeaders">
    <p class="created_date">{{this.agendaInfo.date_formatted}}</p>
    <h2 v-html="this.agendaInfo.title" />
    <div class="row">
      <div class="col-sm-8">
        <p class="content-description"><span v-html="this.agendaInfo.description"></span></p>
        <img class="agenda-thumbnail-img" v-if="agendaInfo.image && showImage" style="max-width: 100%;" :src="parseImage(agendaInfo.image)" alt="" @error="onImageError($event)" />
      </div>
    </div>
    <div class="agendaDownloads">
        <ul>
            <li v-for="(item, i) in this.agendaDoc" :key="i">
                <a @click="getMultimediaURL(item)" class='v4-action-link'>
                    <em :class="getIcon(item)" ></em>
                    <span v-html="item.title"></span>
                </a>
            </li>
        </ul>
    </div>

</div>
</template>

<script>
    import AgendaFactory from '../agenda/factories/Agenda.factory.js';
    import TranslateService from '../../core/services/translate.service.js';
    import PageStayController from '../../page-stay/PageStayController.js';
    import TranslationService from '../../core/services/translation.service.js';
    import axios from 'axios';

    export default {
        name: 'AgendaHeader',
        props: {
            agendasessionid: String,
        },

        created:function(){

             this.agendaBasicInfo();
             this.agendaDocInfo();
        },
        mounted:function(){
            let that = this;
            setInterval(function(){
                that.$nextTick();
            } , 1000);
            document.addEventListener("showAgendaImage", () => {
                this.showImage = true;
            });
/*             this.$root.$on('showAgendaImage', () => {
                this.showImage = true;
            }); */
            this.initStayPage();
        },
        methods:{
            agendaBasicInfo: function agendaBasicInfo() {
             axios
                .get(`/agenda-session/info`, {params:{
                    id: this.agendasessionid,
                    timezone: this.userTimezone
                }})
                .then(res => {
                   this.agendaInfo = AgendaFactory.get(res.data.data);
                   this.status = true;
                })
                .catch(err => {
                    console.warn(err)
                    this.status = false;
                });
            },
            agendaDocInfo: function agendaDocInfo() {
             axios
                .get(`/agenda-session/multimedia`, {params:{
                    id: this.agendasessionid,
                    type : "document",
                }})
                .then(res => {
                   this.agendaDoc = res.data.data;
                   this.status = true;
                })
                .catch(err => {
                    console.warn(err)
                    this.status = false;
                });
            },
            t(tag){
                return this.translator.translate(tag)
            },
            parseImage(img) {
                if (img && img.substr(0,1) != '/') {
                    img = '/' + img;
                }
                return img;
            },
            onImageError(event){
                event.target.style.display = "none";
            },
            initStayPage() {
                PageStayController.startStayPageTime('agenda-session', this.agendasessionid);
            },
            getIcon(document) {
                if (document.multimedia_type_id == 5 || document.multimedia_type_id == 4) {
                    return 'bi bi-file-play';
                } else if (document.external_link) {
                    return 'bi bi-link';
                } else {
                    return 'bi bi-file-earmark';
                }
            },
            getMultimediaURL(document) {
                let url = TranslationService.transRoute('detail-multimedia', {multimediaId: document.id});
                if(document.external_link && document.external_link != "" && document.multimedia_type_id != 4 && document.multimedia_type_id != 5 && document.multimedia_type_id != 12) {
                    document.dispatchEvent(new CustomEvent("leavingMMPModalURL", { detail: document.external_link }));                    
                    // this.$root.$emit('leavingMMPModalURL', document.external_link);
                } else {
                    window.open(url, '_blank');
                }
            }
        },
        data:function(){
            return {
                agendaInfo: Object,
                status : true,
                agendaDoc: Array,
                translator : TranslateService.create(),
                showImage: false,
                userTimezone: window.user.time_zone ?? 'UTC'
            }
        },

    }
</script>
