<template>
    <div class="connectmi-event__footer">
        <div class="connectmi-event__footer__wrapper">
            <img :src="createImagePath('amgen-logo.svg')" alt="amgen-logo" />
            <p class="text text--sm m-0">Amgen (Europe) GmbH. Suurstoffi 22, 6343 Rotkreuz ZG, Switzerland © 2022 Amgen Inc. All rights reserved</p>
        </div>
    </div>
</template>

<script>    
    export default {
        props: {
            imagePath: String
        },


        methods: {
            createImagePath(image) {
                return this.imagePath + image;
            },
        }
    }
</script>