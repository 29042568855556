export default class SatellitalStorage {
  static getUserData(slug) {
    return {
      countryId: this.getKey(slug, 'country-id'),
      email: this.getKey(slug, 'email')
    };
  }
  static getProfessionalData(slug) {
    return this.getJSONKey(slug, 'professional-data') || {};
  }
  static getEventResponseData(slug) {
    return this.getJSONKey(slug, 'event-options') || {};
  }
  static getEventTermsData(slug) {
    return this.getJSONKey(slug, 'event-terms') || [];
  }
  static getRegisterOptionsData(slug) {
    return this.getJSONKey(slug, "register-options") || {};
  }
  static getCountryTermsData(slug) {
    return this.getJSONKey(slug, 'country-terms') || [];
  }
  static getKey(slug, item) {
    const data = localStorage.getItem(`satellital-${slug}-${item}`);
    if (data !== null && data !== undefined) {
      return decodeURIComponent(escape(atob(data)));
    }
    return null;
  }
  static setKey(slug, item, data) {
    localStorage.setItem(`satellital-${slug}-${item}`, btoa(unescape(encodeURIComponent(data))));
  }
  static setSpecialKey(slug, item, data) {
    let base64 = btoa(
      unescape(
        encodeURIComponent(
          data.replace(/[\u00A0-\u2666]/g, function(c) {
            return '&#' + c.charCodeAt(0) + ';';
          })
        )
      )
    );
    localStorage.setItem(`satellital-${slug}-${item}`, base64);
  }
  static deleteKey(slug, item) {
    localStorage.removeItem(`satellital-${slug}-${item}`);
  }
  static getJSONKey(slug, item) {
    try {
      return JSON.parse(this.getKey(slug, item));
    } catch (e) {
      return {};
    }
  }
  static setJSONKey(slug, item, data) {
    this.setKey(slug, item, JSON.stringify(data));
  }
  static setSpecialJSONKey(slug, item, data) {
    this.setSpecialKey(slug, item, JSON.stringify(data));
  }
  static createPackageForPreRegister(email, password, countryId, professionalData) {
    const data = { email, password, professionalData, countryId };
    const serializedData = JSON.stringify(data);
    localStorage.setItem('preregister-data', btoa(serializedData));
  }
  static resetSatellitalRegistration(slug) {
    localStorage.removeItem(`satellital-${slug}-email`);
    localStorage.removeItem(`satellital-${slug}-country-id`);
    localStorage.removeItem(`satellital-${slug}-password`);
    localStorage.removeItem(`satellital-${slug}-event-options`);
    localStorage.removeItem(`satellital-${slug}-professional-data`);
    localStorage.removeItem(`satellital-${slug}-professional-file-name`);
    localStorage.removeItem(`satellital-${slug}-professional-file`);
    localStorage.removeItem(`satellital-${slug}-internal`);
    localStorage.removeItem(`satellital-${slug}-loggedIn`);
    localStorage.removeItem(`satellital-${slug}-onBehalf`);
    localStorage.removeItem(`satellital-${slug}-onBehalfEmail`);
    localStorage.removeItem(`satellital-${slug}-event-terms`);
    localStorage.removeItem(`satellital-${slug}-country-terms`);
  }
  static getChecksForStep(slug, step = 1) {
    const userData = this.getUserData(slug);
    const step0Check = !!userData.email;
    if (step === 0) return step0Check;
    const step1Check = step0Check || !!userData.countryId;
    if (step === 1) return step1Check;
    const professionalData = this.getProfessionalData(slug);
    const step2Check = step1Check && !!professionalData && professionalData.name;
    if (step === 2) return step2Check;
    const eventData = this.getEventResponseData(slug);
    const step3Check = step2Check && eventData !== null && eventData !== undefined;
    return step3Check;
  }
  static checkStorage(slug, spa = false, step = 1) {
    // Check if important data is missing:
    if (!this.getChecksForStep(slug, step)) {
      // Remove everything:
      this.resetSatellitalRegistration(slug);
      // Start again:
      if (spa) {
        document.location.reload();
      } else {
        document.location.href = './start';
      }
    }
  }
}
