<template>
<div>
<div class="v4-calendar-event-list-modal free-modals" v-if="eventselected.length > 1">
    <div class="event-modal-header">
        <div class="oval" @click="onCloseModalClicked(2)">x</div>
    </div>
    <div class="event-modal-body">
        <ul class="v4-detailed-list v4-detailed-list--compressed new-compressed">
            <li v-for="eventsel in eventselected" :key="eventsel.id">
                <p class="v4-detailed-list--index">
                    <span>
                        {{getDayWeekShort(eventsel.date)}}
                    </span>
                    <span>{{getDayNumber(eventsel.date)}}</span>
                </p>
                <div class="v4-detailed-list--body">
                    <h4 class="v4-detailed-list--title">
                        <a @click="openPopup(eventsel.id)" target="_blank"><span v-html="eventsel.title "></span></a>
                    </h4>
                </div>
            </li>
        </ul>
    </div>
</div>
<VueCalendarEventModal @closeModal="onCloseModalClicked(3)" v-if="showSingleEvent" :eventselected="SingleEvent"></VueCalendarEventModal>
</div>
</template>

<script>
import TranslationService from '../../core/services/translation.service';
import TranslateService from '../../core/services/translate.service';
import DateService from '../../core/services/date.service';
import VueCalendarEventModal from './VueCalendarEventModal.vue';

export default {
    name: 'VueEventListModal',
    props: {
        eventselected: Array,
    },
    data() {
        return {
            keywordList: this.keywords,
            translator : TranslateService.create(),
            showSingleEvent:false,
            SingleEvent:Array
        }
    },
    methods: {
        onCloseModalClicked(type) {
            if(type == 3)
                this.showSingleEvent = false;
            else
                this.$emit('closeModal',type);
        },
        getLink() {
            return TranslationService.transRoute('event-detail', {slug: this.slug});
        },
        showPreRegisterPage() {
            window.open(
                window.location.origin + TranslationService.transRoute('event-detail', {slug: this.slug ?? ''})+ '/register',
                '_blank'
            );
        },
        formatDate(date) {
            return DateService.fullDate(date);
        },
        t(tag){
            return this.translator.translate(tag);
        },
        openPopup(event) {
           const singleevent = this.getEventbyId(event);
           if(!singleevent) {
                this.showSingleEvent = false;
                return;
            }
            this.SingleEvent = [];
            Object.keys(singleevent).forEach(key => {
                this.SingleEvent[key] = singleevent[key];
            });
            this.showSingleEvent = true;
            this.SingleEvent = [this.SingleEvent];
        },
        getEventbyId(eventid) {
            return this.eventselected.find(ev => ev.id == eventid);
        },
        getDayNumber(date) {
            let d = new Date(date);
            return d ? d.getDate() : null;
        },
        getDayWeekShort(date) {
            let d = new Date(date);
            return d ? d.toLocaleString(window.app.env.locale, {weekday: 'short'}) : null;
        }
    },
    components: {
        VueCalendarEventModal
    }
}
</script>
