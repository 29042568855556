<template>
  <section class="moderator-pg">
    <div v-if="isLoading">
        {{t('general.loading')}}...
    </div>
    <div class="new-padd-left-right">
      <div class="todays-session" v-if="!isspeaker">
        <h3 class="text-left active mb-3">Today Sessions
          <i class="bi bi-chevron-up" @click="toggelSession()" v-if="todaySessionShow"></i>
          <i class="bi bi-chevron-down" @click="toggelSession()" v-else></i>
        </h3>
      </div>
      <div class="slider-sessions" v-if="!isspeaker && todaySessionShow">
        <template v-if="!isLoading">
          <TopSession :list="sessions" ></TopSession>
        </template>
      </div>
      <ModeratorDetails
        :slug="slug"
        :itemid="itemid"
        :details="details"
        :key="defaultKey"
        :speakers="speakers"
        :isspeaker="isspeaker"
        :meetPlatform="meeting_platform"
        :meetCode="meeting_code"
        :meetPassword="meeting_password"
        :streamUrl="stream_url"
        :routes="routes"
        :userid="userid"
        :jwt="jwt"
        :webexData="webexData"
        :event-url="eventUrl"
        :agendaId="itemid"
        :videotype="videotype"
        :congress="congress">
      </ModeratorDetails>
    </div>
    <div id="footer_div" class="bottom-panel-last clearfix sticky-footer">
      <div class="pull-left col-md-4">
        <div class="help"><a href="javascript:void(0);" @click="showModal('help')"><i class="bi bi-question-circle"></i> Help</a></div>
      </div>
      <div class="footer-center-button col-md-2">
        <a href="javascript:void(0);" class="end-session-button" @click="doConfirm()" v-if="!isspeaker && showEndSessionButton"><span>End Session</span></a>
      </div>
      <div class="pull-right col-md-6">
        <div class="notes-qa">
          <a href="javascript:void(0);" @click="showModal('posts')" v-if="!isspeaker"><i class="bi bi-stickies"></i> Posts</a>
          <a href="javascript:void(0);" @click="showModal('qa')" v-if="!isspeaker && !congress.agenda_session.deactivate_qa"><i class="bi bi-chat-left"></i> Q&A</a>
          <a href="javascript:void(0);" @click="showModal('notes')" v-if="!isspeaker"><i class="bi bi-journal-text"></i> Notes</a>
          <a href="javascript:void(0);" @click="showModal('vote')"><i class="bi bi-clipboard-data"></i> Live voting</a>
          <a v-show="congress.agenda_session.meeting_platform == 'WEBEX'" href="javascript:void(0);" @click="store.toggleShowParticipants()">
            <i class="bi" :class="{'bi-people-fill': store.showParticipants, 'bi-people': !store.showParticipants}"></i> Participants
          </a>
        </div>
      </div>
    </div>

    <LiveModalComponent v-show="isModalVoteVisible" @close="closeModal" v-bind:type="'vote'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <CongressLiveVoting :livepolls="livepolls" :status="false"/>
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>

    <LiveModalComponent v-show="isModalNotesVisible" @close="closeModal" v-bind:type="'notes'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <CongressLiveNotes :agendaid="itemid" :slug="slug" :notes="notes" />
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>

    <LiveModalComponent v-show="isModalQAVisible" @close="closeModal" v-bind:type="'qa'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <CongressLiveQuesAns :agendaid="itemid"  :slug="slug"/>
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>

    <LiveModalComponent v-show="isModalPostVisible" @close="closeModal" v-bind:type="'vote'">
      <template v-slot:header>&nbsp;</template>
      <template v-slot:body>
        <ModeratorPosts :slug="slug" :agendaid="itemid"/>
      </template>
      <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>

    <!-- for help popup -->
    <LiveModalComponent v-show="isModalhelpVisible" @close="closeModal" v-bind:type="'help'">
        <template v-slot:header>&nbsp;</template>
        <template v-slot:body>
            <CongressLiveHelp :agendaid="itemid" :slug="slug" />
        </template>
        <template v-slot:footer>&nbsp;</template>
    </LiveModalComponent>

    <div class="confirm-dialog">
      <ConfirmDialogue :title="true" ref="confirmDialogue"></ConfirmDialogue>
    </div>
    <CongressLiveFinishedSession></CongressLiveFinishedSession>
    <FooterTemplate></FooterTemplate>
  </section>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import AgendaFactory from '../agenda/factories/Agenda.factory';
import { useWebexStore } from '../../store/webex.store'
import axios from 'axios';


import TopSession from './topSession.vue';
import ModeratorDetails from './moderatorDetails.vue';
import CongressLiveVoting from '../congresslive/CongressLiveVoting.vue';
import CongressLiveNotes from '../congresslive/CongressLiveNotes.vue';
import CongressLiveQuesAns from '../congresslive/CongressLiveQuesAns.vue';
import CongressLiveHelp from '../congresslive/CongressLiveHelp.vue';
import ModeratorPosts from './moderatorPosts.vue';
import CongressLiveFinishedSession from '../congresslive/CongressLiveFinishedSession.vue';
import ConfirmDialogue from '../agenda/ConfirmDialogue.vue';
import LiveModalComponent from '../congresslive/LiveModalComponent.vue';
import FooterTemplate from '../custom-template/partials/FooterTemplate.vue';

export default {
  name: 'moderator',
  props:['slug', 'itemid', 'speakers', 'isspeaker', 'routes', 'livepolls', 'notes', 'userid', 'webexData', 'jwt', 'congress', 'eventUrl'],
  setup () {
    const store = useWebexStore()
    return { store }
  },
  components: {
    TopSession,
    ModeratorDetails,
    CongressLiveVoting,
    CongressLiveNotes,
    CongressLiveQuesAns,
    CongressLiveHelp,
    ModeratorPosts,
    CongressLiveFinishedSession,
    ConfirmDialogue,
    LiveModalComponent,
    FooterTemplate,
  },
  data: function() {
    return{
      meeting_platform: '',
      meeting_code: '',
      meeting_password: '',
      stream_url: '',
      isModalVoteVisible: false,
      isModalNotesVisible: false,
      isModalQAVisible : false,
      isModalPostVisible: false,
      isModalhelpVisible: false,
      sessions: [],
      /* bus: new Vue(), */
      isLoading: true,
      defaultKey:0,
      details: [],
      translator : TranslateService.create(),
      todaySessionShow: true,
      lastScrollTop:0,
      userTimezone: window.user.time_zone ?? 'UTC',
      videotype: 0,
      showEndSessionButton: true
    }
  },
  mounted() {
    this.videoData(this.itemid);
    this.sessionData();

    if (this.congress.agenda_session.is_finished) {
      this.showEndSessionButton = false;
    }

    document.addEventListener("mmp-footer-show", (data) => {
      const liveFooter = document.getElementById('footer_div');
      const modalBackdrop = document.getElementsByClassName('modal-backdrop');

      if (data.detail) {
        liveFooter.classList.add('mmp-footer-show');
        modalBackdrop.forEach(modal => {
          modal.classList.add('mmp-footer-show');
        });
      } else {
        liveFooter.classList.remove('mmp-footer-show');
        modalBackdrop.forEach(modal => {
          modal.classList.remove('mmp-footer-show');
        });
      }
    });
  },
  methods: {
    async doConfirm() {
      const ok = await this.$refs.confirmDialogue.show({
        title: this.t("live.finish_the_session"),
        message: this.t("live.finish_the_session_q"),
        okButton: 'Yes',
        cancelButton: 'No'
      });
      if(ok){
        const response = await this.endSessions();
        this.showEndSessionButton = false;
      }
    },
    endSessions() {
      let data = new FormData();
      data.append('agendaSessionId', this.itemid);
      return axios.post('/webapi/live/finish-the-session', data);
    },
    async sessionData() {
      const response = await this.getsessionData();
      this.sessions = response.data.data.map(k => AgendaFactory.get(k, this.slug));
      this.isLoading = false;
    },
    getsessionData() {
      return axios.get('/agenda-session/today-sessions?id='+this.itemid+'&slug='+this.slug+'&timezone='+this.userTimezone);
    },
    async videoData(id) {
      const response = await this.getvideoData(id);
      if(response.data.status==200){
        this.meeting_platform = response.data.data.meeting_platform;
        this.meeting_code = response.data.data.meeting_code;
        this.meeting_password = response.data.data.meeting_password;
        this.stream_url = response.data.data.stream_url;
        if(this.isspeaker){
          var objects = {
            title: response.data.data.title,
            id: response.data.data.id,
            is_live: response.data.data.is_live
          }
          console.log(objects);
          this.details.push(objects);
        }
        this.defaultKey++;
        if (typeof this.stream_url !== "undefined" && this.stream_url && this.stream_url != "" && this.stream_url.length > 0 && this.meeting_platform == 'EXTERNAL') {
          this.videotype = 5;
          if (this.stream_url.match(/brightcove/g) != null) {
            this.videotype = 1;
          }

          if (this.stream_url.match(/player.vimeo.com/g) != null) {
            this.videotype = 2;
          }

          if (this.stream_url.match(/soundcloud/g) != null) {
            this.videotype = 3;
          }

          let path = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
          if (this.stream_url.match(path) != null) {
            this.videotype = 3;
          }

          if (this.stream_url.match(/cdn3.wowza.com/g) != null) {
            this.videotype = 4;
          }
        } else {
          this.videotype = 0;
        }
      }
    },
    getvideoData(id){
      return axios.get('/agenda-session/info?id='+id);
    },
    /*
        * Method to open the popup
        * @Param i - contains the type of popup
    */
    showModal(i) {
      if ( i == 'vote') {
          if(this.isModalVoteVisible){
            this.isModalVoteVisible = false;
          } else{
            this.closeModal();
            this.isModalVoteVisible = true;
          }
      } else if ( i == 'notes' ) {
          if(this.isModalNotesVisible){
            this.isModalNotesVisible = false;
          } else{
            this.closeModal();
            this.isModalNotesVisible = true;
          }
      } else if ( i == 'qa' ) {
          if(this.isModalQAVisible){
            this.isModalQAVisible = false;
          } else{
            this.closeModal();
            this.isModalQAVisible = true;
          }
      } else if ( i == 'posts' ) {
          if(this.isModalPostVisible){
            this.isModalPostVisible = false;
          } else{
            this.closeModal();
            this.isModalPostVisible = true;
          }
      } else if ( i == 'help' ) {
          if(this.isModalhelpVisible){
            this.isModalhelpVisible = false;
          } else{
            this.closeModal();
            this.isModalhelpVisible = true;
          }
      }
    },
    /*
      * Method to close all popups
     */
    closeModal() {
      this.isModalVoteVisible = false;
      this.isModalNotesVisible = false;
      this.isModalQAVisible = false;
      this.isModalPostVisible = false;
      this.isModalhelpVisible = false;
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    },
    toggelSession() {
      this.todaySessionShow = !this.todaySessionShow
    },
    t(tag){
      return this.translator.translate(tag)
    }
  }
}
</script>
