<template>
  <transition name="fade">
    <div v-if="showLoader" id="custom-loader-container">
      <div class="box col">
        <div class="custom-loader" id="custom-loader">
          <p>{{ t('general.loading') }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import TranslateService from '../../core/services/translate.service';

export default {
  mounted() {
    document.addEventListener("showCustomLoader", (data) => {
      if (data.detail) {
        document.querySelector('body').classList.add('loading')
        this.showLoader = true;
      } else {
        document.querySelector('body').classList.remove('loading')
        this.showLoader = false;
      }
    });
  },
  data() {
    return {
      translator: TranslateService.create(),
      showLoader: false,
    }
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag)
    }
  },
}
</script>
