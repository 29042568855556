<template>
  <section>
    <div class="register-form-container">
      <form class="pre-home-register-form register-form-container__form w-50 mb-5" v-if="dataLoaded">
        <CreatePassword v-if="!loggedUser || isOnBehalf" :isOnBehalf="isOnBehalf"></CreatePassword>

        <div v-if="loggedUser && !isOnBehalf" class="text-right mt-5 register-form-container__form__submit-section">
          <a class="register-form-container__form__submit-section__back" data-toggle="collapse" href="#collapseForm"
            aria-expanded="true" aria-controls="collapseForm" data-testid="collapse-data">
            <i id="toggle-expand-form" class="bi bi-chevron-expand" data-testid="collapse-icon">
            </i>
            {{ t('register-mmp.collapse-data') }}
          </a>
        </div>

        <div :class="{ 'collapse': false }" id="collapseForm">
          <div class="register-input-group">
            <label for="register-name-input">{{ t('form.name') }} *</label>
            <input type="text" name="register-name-input" @keyup="updateFormStatus" @input="filterInput($event, 'name')" v-model="professionalData.name" 
              class="register-name-input" v-bind:placeholder="isOnBehalf ? t('pre-home.form.name-on-behalf') : t('pre-home.form.name')" data-testid="register-name" />
          </div>

          <div class="register-separator-lg">
            <div class="register-input-group">
              <label for="register-surname-input">{{ t('form.surname') }} *</label>
              <input type="text" name="register-surname-input" @keyup="updateFormStatus" @input="filterInput($event, 'surname')"
                v-model="this.professionalData.surname" class="register-surname-input"
                v-bind:placeholder="isOnBehalf ? t('pre-home.form.surname-on-behalf') : t('pre-home.form.surname')" data-testid="register-surname" />
            </div>
          </div>

          <div class="register-separator-lg">
            <div class="register-input-group register-select-input">
              <label for="register-select-input">{{ t('form.speciality') }} *</label>
              <select name="register-select-input" @change="updateFormStatus" multiple v-model="selectedSpecialities"
                class="select selectpicker" v-bind:placeholder="t('pre-home.form.speciality')"
                :title="t('satellital.nothing-selected')" data-live-search="true"
                :data-live-search-placeholder="t('general.search')" data-testid="register-specialities">
                <option disabled hidden value="-1">{{ t('pre-home.form.speciality') }} *</option>
                <option v-for="speciality of  professionalData.specialities" :key="speciality.id"
                  :value="speciality.id">{{
                  speciality.name
                  }}</option>
              </select>
            </div>
          </div>

          <div class="register-separator-lg">
            <div class="register-input-group">
              <label for="register-institution-input">{{ t('form.institution') }} *</label>
              <input type="text" name="register-institution-input" @keyup="updateFormStatus" @input="filterInput($event, 'institution')"
                v-model="professionalData.institution" class="register-institution-input"
                v-bind:placeholder="isOnBehalf ? t('pre-home.form.institution-on-behalf') : t('pre-home.form.institution')" data-testid="register-institution" />
            </div>
          </div>
        </div>


        <div class="register-separator-lg" v-if="!loggedUser || isOnBehalf">
          <div class="register-input-group">
            <label for="register-surname-input">{{ t('satellital.amgen_contact_email') }}</label>
            <input type="email" name="register-surname-input" @keyup="updateFormStatus"
              v-model="professionalData.amgen_contact_email" class="register-amgen-contact-email-input"
              v-bind:placeholder="t('satellital.amgen_contact_email')" data-testid="register-amgenContactEmail" />
          </div>
        </div>

        <div class="register-separator-xl" v-if="!loggedUser || isOnBehalf">
          <div class="upload-file-group mb-4 mt-4" style="">
            <label for="my-file-selector" class="form-control-file mt-2">
              <input id="my-file-selector" type="file" accept="application/pdf,image/png,image/jpeg" hidden
                @change="onFileSelectorChange" />
              <button type="button" @click="onFileButtonClick" class="v4-btn-secondary upload-file-button"
                data-testid="register-file">{{ t('pre-home.file') }}</button>
            </label>
            <small>Max: 2MB</small>
            <div v-if="selectedFileErrorMessage.length > 0">{{ selectedFileErrorMessage }}</div>
            <span :key="fileRenderKey" v-if="selectedFilename.length > 0">
              <span id="upload-file-info" class="label label-info">
                <img class="mr-1" src="/images/v4/icons/upload.svg" alt="file"
                  :hidden="selectedFilename.length > 0" />{{
                selectedFilename
                }}
              </span>
              <span id="clear-file" class="label label-info" @click="clearFileSelector()">{{ t('general.clear')
                }}</span>
            </span>
          </div>
        </div>

        <TermConditionsQuestions ref="termsAndQuestionsComponent" v-if="!isOnBehalf" :loggedUser="loggedUser"
          :country="countryId" :slug="slug"></TermConditionsQuestions>

        <div v-if="!loggedUser || isOnBehalf" class="register-info-consent">
          {{ t('register-congress.info_consent') }}
        </div>

        <div class="register-form-container__form__error" v-if="responseMessage != null" v-html="responseMessage">
        </div>

        <div class="text-right mt-5 register-form-container__form__submit-section">
          <a @click="backStep()" class="register-form-container__form__submit-section__back">{{ t('satellital.back')
            }}</a>
          <button type="submit" class="v4-btn-primary register-form-container__form__submit-section__submit"
            @click="doEmailRegistration" :disabled="formComplies == false" data-testid="register-submitButton">
            {{ t('pre-home.continue') }}
          </button>
        </div>

      </form>
    </div>
  </section>
</template>

<script>
import TranslateService from '../../../core/services/translate.service.js';
import SatellitalUtils from '../../satellital/utils/utils';
import CreatePassword from './CreatePassword.vue'
import TermConditionsQuestions from './TermConditionsQuestions.vue';
import PageStayController from '../../../page-stay/PageStayController.js';
import { useCreatePwd } from '../../../store/modal.store.js';
import { useEmailPasswordStore } from '../../../store/form.store.js';
import { computed, reactive, ref, watch, toRefs, onMounted } from 'vue';
import 'bootstrap-select';
import axios from 'axios';
import $ from 'jquery';
import GoogleAnalytics from '../../../GoogleAnalytics.js';

export default {
  setup(props) {
    const createPwdStore = useCreatePwd();
    const emailPasswordStore = useEmailPasswordStore();

    const validPasswordForm = computed(() => emailPasswordStore.validPasswordForm);
    const validEmailForm = computed(() => emailPasswordStore.email);

    const formComplies = ref(false);
    const state = reactive({
      translator: TranslateService.create(),
      passwordForm: {
        email: props.email ?? '',
        password: '',
        validPasswordForm: props.email ? true : false,
      },
      termAndQuestionsForm: {
        validStatus: false,
        termsChecked: {},
        questionResponses: {},
        newsletter: false,

      },
      professionalData: {
        name: '',
        surname: '',
        specialities: [],
        institution: '',
      },
      isOnBehalf: props.isOnBehalf,
      selectedSpecialities: [],
      hasTermsAndConditions: false,
      errorMessage: '',
    });
    const updateFormStatus = () => {
      state.errorMessage = '';
      const {
        name,
        surname,
        specialities,
        institution,
      } = state.professionalData;

      const conditions = {
        name: name.length === 0,
        surname: surname.length === 0,
        institution: state.professionalData.institution.length === 0,
        specialities: specialities.length === 0,
        validPasswordForm: !state.isOnBehalf && !state.passwordForm.validPasswordForm,
        validStatus: !state.isOnBehalf && !state.termAndQuestionsForm.validStatus,
        email: !state.passwordForm.email
      };
      formComplies.value = !Object.values(conditions).some(Boolean);
    };
    /*
    initTermConditionsQuestionComponent() {
         this.$root.$on('termConditionsAndQuestions', (termAndQuestionsForm) => {
           this.termAndQuestionsForm.termsChecked = this.$refs.termsAndQuestionsComponent.termsAndConditions.map(item => ({
             'id': item.id,
             'scope': item.scope
           }));
           this.hasTermsAndConditions = this.$refs.termsAndQuestionsComponent.termsAndConditions.length > 0;
           this.termAndQuestionsForm.questionResponses = termAndQuestionsForm.questionResponses;
           this.termAndQuestionsForm.newsletter = termAndQuestionsForm.newsletter;
           this.termAndQuestionsForm.validStatus = termAndQuestionsForm.validStatus;
           this.updateFormStatus();
         });
       }, */
       const handleEvent = (event) => {
        const detail = event.detail;
        if (detail) {
          const { form: { validStatus, termsChecked, questionResponses, newsletter }, termsAndConditions } = detail;
          state.termAndQuestionsForm.validStatus = validStatus ?? false;
          state.termAndQuestionsForm.termsChecked = termsAndConditions.map(({ id, scope }) => ({ id, scope }));
          state.hasTermsAndConditions = state.termAndQuestionsForm.termsChecked.length > 0;
          state.termAndQuestionsForm.questionResponses = questionResponses ?? null;
          state.termAndQuestionsForm.newsletter = newsletter ?? false;
          updateFormStatus();
        }
      };

    watch([validPasswordForm, validEmailForm], ([newValidPasswordForm, newValidEmailForm]) => {
      state.passwordForm.validPasswordForm = newValidPasswordForm;
      state.passwordForm.email = newValidEmailForm;
      state.passwordForm.password = emailPasswordStore.password;
      updateFormStatus();
    });

    onMounted(() => {
      document.addEventListener('termConditionsAndQuestions', handleEvent);
    });

    return {
      createPwdStore,
      emailPasswordStore,
      formComplies,
      updateFormStatus,
      ...toRefs(state),

    };
  },
  components: {
    CreatePassword,
    TermConditionsQuestions,
  },
  props: {
    email: String,
    countryId: Number,
    slug: String,
    congress: Object,
    registerType: String,
    owaForm: Object,
    isOnBehalf: Boolean,
  },
  data() {
    return {
      translator: TranslateService.create(),
      responseMessage: null,
      selectedFileErrorMessage: '',
      fileRenderKey: 0,
      selectedFileBlob: null,
      file: null,
      filename: '',
      selectedFilename: '',
      userData: null,
      loggedUser: this.email ? true : false,
      dataLoaded: false,
    };
  },
  async beforeMount() {
    if (this.countryId == -1) {
      window.location.href = '/en'
    }
    document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
    await this.getRegisterData();
  },
  mounted() {
    document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
    PageStayController.startStayPageTime('register-manual', this.congress ? this.congress.id : '0', false);
  },
  watch: {
    dataLoaded(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          $('.selectpicker').selectpicker('refresh');
        });
      }
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
    },
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async getRegisterData() {
      try {
        const response = await axios.get(`/congress-register/data`,
          {
            params: {
              loggedUser: this.loggedUser,
            }
          });
        this.professionalData.specialities = response.data.specialties;
        if (!this.isOnBehalf) {
          this.userData = response.data.userData;
          this.initFormWithUserData();
        }
        this.dataLoaded = true;
      } catch (error) {
        console.error('Error getting register data:', error);
      }
    },
    backStep() {
      this.$emit('back-step');
    },
    filterInput(event, field) {
      var RE_EMOJI = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
      this.professionalData[field] = event.target.value.replace(RE_EMOJI, '');
    },
    initFormWithUserData() {
      if (this.userData) {
        this.professionalData.name = this.userData.name;
        this.professionalData.surname = this.userData.surname;
        this.selectedSpecialities = this.userData.specialties;
        this.professionalData.institution = this.userData.institution;
      }
    },
    doEmailRegistration(e) {
      e.preventDefault();
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.updateFormStatus();
      this.responseMessage = null;
      if (this.formComplies) {
        PageStayController.setAdditionalData(this.passwordForm.email, this.countryId, null);
        PageStayController.stopStayPageTime();
        const url = '/do-register';
        const data = this.getDataForm();

        axios.post(url, data)
          .then((res) => {
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
            GoogleAnalytics.sendRegisterEvent();
            this.$emit('next-step', 3);
          })
          .catch(error => {
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
            this.responseMessage = error.response.data.errorMessage || error.response.data.message;
            console.error(error);
          }
          );
      } else {
        document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
      }
    },
    getDataForm() {
      return {
        userEmail: this.passwordForm.email,
        password: this.passwordForm.password,
        name: this.professionalData.name,
        surname: this.professionalData.surname,
        specialities: this.selectedSpecialities,
        institution: this.professionalData.institution,
        amgenContactEmail: this.professionalData.amgen_contact_email ?? '',
        newsletter: this.termAndQuestionsForm.newsletter,
        countryId: this.countryId,
        responses: this.termAndQuestionsForm.questionResponses,
        termsChecked: this.termAndQuestionsForm.termsChecked,
        registerType: this.registerType,
        professionalFile: {
          content: this.file,
          name: this.selectedFilename,
        },
        slug: this.slug,
        owaForm: this.owaForm,
        isOnBehalf: this.isOnBehalf,
      }
    },
    onFileSelectorChange($ev) {
      if ($ev.target.files[0]) {
        const file = $ev.target.files[0];
        if (file.size / 1024 / 1024 >= 2) {
          this.selectedFileErrorMessage = this.t('satellital.file_too_big');
          this.fileRenderKey++;
          this.clearFileSelector();
          setTimeout(() => {
            this.selectedFileErrorMessage = '';
            this.fileRenderKey++;
          }, 1500);
        } else {
          this.selectedFilename = file.name;
          this.selectedFileBlob = file;
          SatellitalUtils.convertBlobToData64(file)
            .then(res => {
              this.file = res;
              this.fileName = file.name;
            })
            .catch(err => {
              console.warn('uh-oh', err);
            });
        }
      }
    },
    onFileButtonClick() {
      document.querySelector('#my-file-selector').click();
    },
    clearFileSelector() {
      document.querySelector('#my-file-selector').value = null;
      this.selectedFilename = '';
      this.selectedFileBlob = null;
    },
  }
};
</script>
