import AssistanceButton from '../objects/v4/modules/elements/assistance/AssistanceButton.vue'
import HomeSearchForm from '../objects/v4/modules/home-form/HomeSearchForm.vue';
import TrendingKeywordsList from '../objects/v4/modules/trending-keywords-list/TrendingKeywordsList.vue'
import EventsCalendar from '../objects/v4/modules/events/events-calendar/events-calendar.vue';
import EventsCarousel from '../objects/v4/modules/events/events-carousel/events-carousel.vue';
import ContentList from '../objects/v4/modules/content-list/ContentList.vue';
import TrendingList from '../objects/v4/modules/trending-list/TrendingList.vue';
import KnowledgeJourneyCarousel from '../objects/v4/modules/knowledge-journey/knowledge-journey-carousel/KnowledgeJourneyCarousel.vue';
import ClinicalCasesCarousel from '../objects/v4/modules/clinical-cases/ClinicalCasesCarousel.vue';

const vueComponents = [
    ['AssistanceButton', AssistanceButton],
    ['HomeSearchForm', HomeSearchForm],
    ['TrendingKeywordsList', TrendingKeywordsList],
    ['EventsCalendar', EventsCalendar],
    ['EventsCarousel', EventsCarousel],
    ['ContentList', ContentList],
    ['TrendingList', TrendingList],
    ['KnowledgeJourneyCarousel', KnowledgeJourneyCarousel],
    ['ClinicalCasesCarousel', ClinicalCasesCarousel],
]

export default vueComponents