<template>
  <div>
    <Dropdown @clicked="clicked" :defaultLabel="fullUserName" :items="itemsFlat" :containerWidth="'auto'" :containerFloat="'right'">
      <template v-slot:icon>
        <img src="/images/v4/multi-event/user_header.svg" alt="header-inner-icon" class="mr-2 header-inner-icon user-profile-icon" width="17">
      </template>
    </Dropdown>
  </div>
</template>

<script>
import axios from 'axios'
import TranslateService from '../../core/services/translate.service';
import TranslationService from '../../core/services/translation.service.js';
import Utils from '../../../v4/Utils';
import Dropdown from '../dropdown/Dropdown.vue';

export default {
	props: {
		user: Object,
    slug: String
	},
  data() {
    let dropdownItems = [];
    if(this.slug && this.slug == 'imlygicknowledge') {
      dropdownItems.shift();
    }
    return {
      items: dropdownItems,
      translator: TranslateService.create(),
    }
  },

	computed: {
		itemsFlat() {
			return this.items.map(item => item.text);
		},
		fullUserName() {
			return `Hi, ${window.user.firstName} ${window.user.lastName}`;
		}
	},

  mounted() {
    this.items = [
      {
        code: 'profile',
        text: this.t('home.header.profile'),
        link: TranslationService.transRoute('profile')
      },
      {
        code: 'logout',
        text: this.t('home.header.logout'),
        link: '/logout'
      },
    ];
  },
  methods: {
    clicked(item) {
      const element = this.findByText(item);
      
      if(element.code == 'logout') {
        const fn = () => {
					axios.post(element.link)
					.then(res => {
            window.localStorage.clear();
            if (Utils.isValidUrl(res.data.redirectTo)) {
              window.location.href = Utils.escapeHtml(res.data.redirectTo);
            }
					})
					.catch(err => {
						console.warn(err);
					});
        };
        if(!!window.amgenConnect) {
          const tt = setTimeout(() => {
            fn();
          }, 5000);
          window.amgenConnect.logout().then(() => {
            fn();
            clearTimeout(tt);
          }).catch(e => {
            fn();
            clearTimeout(tt);
          });
        } else {
          fn();
        }
      } else {
        window.location.href = Utils.escapeHtml(window.location.origin + element.link);
      }
    },
    t(tag) {
      return this.translator.translate(tag);
    },
      findByText(value) {
      return this.items.find(item => item.text == value);
    }
  },
  components: {
    Dropdown
  }
}
</script>
<style scoped>
.user-profile-icon {
	position:relative;
	top: -2px;
}
</style>
