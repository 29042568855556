<template>
  <div class="event-modal-footer d-none" v-if="!shouldHide()">
    <div class="footer-left-side">
      <em
        :class="localLike ? 'bi-hand-thumbs-up-fill' : 'bi bi-hand-thumbs-up ' + 'like-' + this.id"
        @click.stop="onLikeBtn($event, 'like')"
        v-if="this.type != 'agenda' && this.type != 'agenda_session'"
      ></em>
      <em
        :class="localDislike ? 'bi-hand-thumbs-down-fill' : 'bi bi-hand-thumbs-down ' + 'dislike-' + this.id"
        @click.stop="onLikeBtn($event, 'dislike')"
        v-if="this.type != 'agenda' && this.type != 'agenda_session'"
      ></em>
      <div class="footer-add-calendar" v-if="this.showAddToCalendar()">
        <!-- Button code -->
        <div title="Add to Calendar" class="addeventatc" @click.stop="registerLogAddToCalendar">
          <span>Add to Calendar</span>
          <i class="bi bi-chevron-down"></i>
          <span class="start">{{ this.startDate }}</span>
          <span class="end">{{ this.endDate }}</span>
          <span class="timezone">{{ this.agendaTimezone }}</span>
          <span class="title">{{ this.title }}</span>
          <span class="description">{{ plainTextDescription }}</span>
          <span class="location">{{ this.getLocation(this.slug, this.id) }}</span>
        </div>
      </div>
    </div>
    <div class="footer-right-side">
      <em
        :class="localSave ? 'bi-bookmark-fill ' : 'bi bi-bookmark ' + 'save-' + this.id"
        @click.stop="onSaveBtn($event)"
        v-if="this.type != 'agenda' && this.type != 'agenda_session'"
      ></em>
      <div
        :class="'share-icons share-icons-' + this.id"
        style="display: none"
        v-if="this.type != 'agenda' && this.type != 'agenda_session'"
      >
        <em
          href="https://api.whatsapp.com/send?text="
          :data-id="this.id"
          target="_blank"
          class="bi bi-whatsapp"
          style="color: #2dbcb6"
          @click.stop="shareAction($event)"
        ></em>
        <em
          :href="'mailto:?subject=Shared content from the Medical Meeting Point&body='"
          :data-id="this.id"
          class="bi bi-envelope"
          style="color: #2dbcb6"
          @click.stop="shareAction($event)"
        ></em>
      </div>
      <em
        class="bi bi-share-fill"
        @click.stop="showShareOptions()"
        v-if="this.type != 'agenda' && this.type != 'agenda_session'"
      ></em>
    </div>
  </div>
</template>

<script>
import ActivityService from "../../core/services/activity.service";
import TranslationService from "../../core/services/translation.service";
import Utils from "../../../v4/Utils";
import Translate from "../../../v4/Translations";
import axios from "axios";
import GoogleAnalytics from "../../GoogleAnalytics.js";
import $ from "jquery";

export default {
  props: {
    id: Number,
    title: String,
    slug: String,
    link: String,
    type: String,
    isPast: Boolean,
    liked: Boolean,
    disliked: Boolean,
    saved: Boolean,
    description: String,
    startDate: String,
    endDate: String,
    agendaTimezone: String,
    isLive: Boolean,
    isBookASeat: Boolean,
    isRegistered: Boolean,
    isOnDemand: Boolean,
  },
  data() {
    return {
      localLike: this.liked,
      localDislike: this.disliked,
      localSave: this.saved,
      defaultTitle: this.title,
      defaultDate: this.date_formatted,
      base_link: "/events/",
      keywordList: this.shortened_keywords,
      activitySvc: ActivityService.create(),
      plainTextDescription: this.getPlainTextDescription(this.description),
      Utils: Utils,
    };
  },
  computed: {
    multimediaTypeToLower() {
      return this.multimedia_type.toLowerCase();
    },
  },

  mounted() {
    this.setLike();
    this.isBookMarked();
    setTimeout(function () {
      addeventatc.settings({
        license: window.app.env.USER_CLIENT_ID,
      });
      addeventatc.refresh();
    }, 600);
  },

  methods: {
    onLikeBtn(ev, action) {
      ev.preventDefault();
      ev.stopPropagation();

      const url = "/profile/save-like";
      const fd = new FormData();
      fd.append("model_id", this.$props.id);
      fd.append("model_type", this.$props.type);
      fd.append("like", this.$props.title);
      fd.append("source", "");
      fd.append("congress_id", this.$props.congress_id);

      if (action == "like") {
        fd.append("liked", true);
      } else {
        fd.append("liked", false);
      }

            // Call save item
            axios.post(url, fd).then((response) => {
                //Grid
                this.checkLike(response.data.liked);
                this.checkDislike(response.data.disliked);
            }).catch((error) => {
                console.error('Error on like method', error);
            });
            GoogleAnalytics.sendContentReactionEvent(this.title, action, this.type);
        },

        checkLike(liked) {
            if (liked) {
                this.localLike = true;
                this.localDislike = false;
            } else {
                this.localLike = false;
            }
            this.$emit('updateFooter', { localLike: this.localLike, localDislike: this.localDislike, localSave: this.localSave });
        },

    checkDislike(disliked) {
      if (disliked) {
        this.localLike = false;
        this.localDislike = true;
      } else {
        this.localDislike = false;
      }
      this.$emit("updateFooter", {
        localLike: this.localLike,
        localDislike: this.localDislike,
        localSave: this.localSave,
      });
    },

    setLike() {
      if (this.liked) {
        this.localLike = true;
      } else if (this.disliked) {
        this.localLike = false;
      }
      this.$emit("updateFooter", {
        localLike: this.localLike,
        localDislike: this.localDislike,
        localSave: this.localSave,
      });
    },

    onSaveBtn(ev) {
      ev.preventDefault();
      ev.stopPropagation();

      const url = "/profile/save-bookmark";
      const fd = new FormData();
      fd.append("model_id", this.id);
      fd.append("model_type", this.type);
      fd.append("like", this.title);
      fd.append("source", "");
      fd.append("congress_id", this.congress_id);

      // Call save item
      axios
        .post(url, fd)
        .then((response) => {
          this.checkBookmarks(response.data.bookmarked);
        })
        .catch((error) => {
          console.error("Error on bookmark method", error);
        });
    },

    checkBookmarks(bookmarked) {
      if (bookmarked) {
        this.localSave = true;
        GoogleAnalytics.sendSaveContentEvent(this.title, "slider", this.type);
      } else {
        this.localSave = false;
        GoogleAnalytics.sendUnsaveContentEvent(this.title, "slider", this.type);
      }
      this.$emit("updateFooter", {
        localLike: this.localLike,
        localDislike: this.localDislike,
        localSave: this.localSave,
      });
    },

    isBookMarked() {
      if (this.saved) {
        this.localSave = true;
      }
      this.$emit("updateFooter", {
        localLike: this.localLike,
        localDislike: this.localDislike,
        localSave: this.localSave,
      });
    },

    showShareOptions() {
      let icons = $(`.share-icons-${this.id}`);

      if (icons.is(":visible")) {
        $(icons).hide(200);
        $(icons).fadeOut(200);
      } else {
        $(icons).show(200);
        $(icons).fadeIn(200);
      }
    },

    shareAction(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      const prepareLink = (type, itemId, url) => {
        let link = "";
        if (type == "multimedia") {
          const loc = document.location;
          link = escape(`${loc.protocol}//${loc.host}/${type}/${itemId}`);
        } else if (type == "congress") {
          link = url;
        }
        return link;
      };
      const itemId = this.id;
      const type = this.type;
      const title = this.title;
      const congressUrl = this.link;
      const link = prepareLink(type, itemId, congressUrl);
      const Translation = new Translate();
      const checkoutThis = Translation.translate("general", "check-out");
      let message = `${checkoutThis} ${title}: ${link}`;

      const target = $(ev.currentTarget);
      const href = target.attr("href");
      const url = href + message;
      if (target.attr("target") === "_blank") {
        window.open(url);
      } else {
        window.location.replace(url);
      }
      GoogleAnalytics.sendShareContentEvent(title, href.includes('whatsapp') ? 'whatsapp' : 'mail', type);
    },
    shouldHide() {
      return (this.type == "agenda" || this.type == "agenda_session") && (this.isPast || this.hideAddToCalendar());
    },

    registerLogAddToCalendar() {
      this.saveActivity("agenda-add-to-calendar");
    },

    saveActivity(event) {
      let params = {
        title: this.$props.title,
        model_type: this.$props.type,
        model_id: this.$props.id,
        congress_id: this.$props.congress_id,
      };

      this.activitySvc.saveActivity(event, params);
    },

    getLocation(slug, id) {
      let agendaURL = "";
      agendaURL = TranslationService.transRoute("event-detail-live", {
        slug: slug,
        agendaSessionId: id,
      });
      return window.app.env.APP_URL + agendaURL;
    },

    getPlainTextDescription(description) {
      let plainTextDescription = "";
      if (description) {
        plainTextDescription = description
          .replace(/<[^>]+>/g, "")
          .replace(/&nbsp;/g, " ")
          .replace(/&amp;/g, "&");
      }
      return plainTextDescription;
    },

    showAddToCalendar() {
      return (
        this.type == "agenda_session" &&
        !this.isLive &&
        !this.isOnDemand &&
        (!this.isBookASeat || (this.isBookASeat && this.isRegistered))
      );
    },

    hideAddToCalendar() {
      return window.user == null;
    },
  },
};
</script>
