import WebinarCasesCarousel from '../objects/v4/modules/congresses/WebinarCasesCarousel.vue';
import WebinarCasesItem from '../objects/v4/modules/congresses/WebinarCasesItem.vue';
import RelatedlistWeb from '../objects/v4/modules/congresses/RelatedlistWeb.vue';

const vueComponents = [
    ['WebinarCasesCarousel', WebinarCasesCarousel],
    ['WebinarCasesItem', WebinarCasesItem],
    ['RelatedlistWeb', RelatedlistWeb],
]

export default vueComponents