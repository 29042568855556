import { WebVTTCreator } from '../../libs/videojs-helpers/webvtt';
import vjsMarkers from '../../libs/videojs-helpers/vjs-markers';
import videojs from 'video.js';
import { ConsumptionHelper } from '../consumption/consumption-helper.js';
//import './vjs-wavesurfer.js';
import RoutesHelper from '../../objects/v4/routesHelper';
import Utils from '../../objects/v4/Utils';
//import './vjs-wavesurfer-bc';
import $ from 'jquery';
window.vjs = videojs;

export class VJSInitializer {
  selector = null;
  tracksAreRanged = true;
  consumptionHelpers = {};
  limits = {};
  intervals = {};
  availableVideos = {};
  styleFixerInterval = null;
  constructor(selector = '.video-js-player', tracksAreRanged = true) {
    this.selector = selector;
    this.availableVideos = {};
    this.tracksAreRanged = tracksAreRanged;
    this.addStyleFixerInterval();
  }

  generateCaptions(domId) {
    let { tracks } = this.availableVideos[domId];
    const document = new WebVTTCreator("captions");
    tracks.forEach(track => {
      document.addCaption(track.start, track.end, track.text);
    });
    return document;
  }
  generateChapters(domId) {
    let { tracks } = this.availableVideos[domId];
    const document = new WebVTTCreator("chapters");
    tracks.forEach(track => {
      document.addChapter(track.start, track.end, track.text);
    });
    return document;
  }

  addTracks(domId) {
    const captionsVTT = this.generateCaptions(domId);
    const chaptersVTT = this.generateChapters(domId);
    let { videoJS, tech } = this.availableVideos[domId];
    const baseObj = {tech, mode: "showing", language: "en", srclang: "en", default: true};
    videoJS.addRemoteTextTrack({...baseObj, src: chaptersVTT.toDataURL(), kind: 'chapters'}, true);
    videoJS.addRemoteTextTrack({...baseObj, src: captionsVTT.toDataURL(), kind: 'captions'}, true);
  }

  addPlayerStartEventForTracks(domId) {
    let { videoJS, tracks } = this.availableVideos[domId];
    videoJS.on('play', () => {
      let duration = videoJS.duration();
      if (!Number.isNaN(duration)) {
        vjsMarkers.removeMarkers(domId);
        vjsMarkers.addMarkers(domId, tracks, duration, this.tracksAreRanged);
      }
    });
  }

  removeVideoTracks(videoJS) {
    let oldTracks = videoJS.remoteTextTracks();
    let i = oldTracks.length;
    while (i--) { videoJS.removeRemoteTextTrack(oldTracks[i]); }
  }

  initBrightCove(domId) {
    const {videoJS, dataType, brightcoveId} = this.availableVideos[domId];
    if(dataType.toLowerCase() !== 'brightcove') return;
    videoJS.ready(function() {
      const player = this;
      player.catalog.getVideo(brightcoveId, function(error, video) {
        //deal with error
        player.catalog.load(video);
      });
    })
  }
  addAutoPause() {
    $('.modal-close').on('click', ev => {
      const id = ev.target.getAttribute("data-id");
      if (!!document.querySelector(`#video-js-player-${id}`)) {
        const vm = videojs(`video-js-player-${id}`);
        vm.ready(() => vm.pause());
      }
    });
    $('.mev-content-modal, .bp-video-modal').modal({
      show: false
    }).on('hidden.bs.modal', function (ev) {
      let isVideo = ev.target.getAttribute("is-video") || false;
      if (isVideo) {
        let id = ev.target.getAttribute("item-id");
        if (!!document.querySelector(`#video-js-player-${id}`)) {
          const vm = videojs(`video-js-player-${id}`);
          vm.ready(() => vm.pause());
        }
      }
    });
  }

  onVideoReady(domId) {
    const {videoJS, tracks, start, isAudio} = this.availableVideos[domId];
    videoJS.ready(ev => {
      // set Start:
      // this.initBrightCove(domId);
      videoJS.currentTime(start);
      // Remove old Tracks:
      this.removeVideoTracks(videoJS);
      // Add tracks if available:
      if (!!tracks) {
        this.addTracks(domId);
        this.addPlayerStartEventForTracks(domId);
      }
    });
  }

  destroy() {
    Object.keys(this.availableVideos).forEach(itemId => {
      this.availableVideos[itemId].videoJS.dispose();
    });
  }

  getDataFromDomItem(domVideo) {
    let domId = domVideo.getAttribute("id");
    let videoStart = domVideo.getAttribute("data-start") || 0;
    let dataType = domVideo.getAttribute("data-type") || "Html5";
    let tracks = domVideo.getAttribute("data-tracks") || null;
    let brightcoveId = domVideo.getAttribute("data-video-id") || null;
    let src = domVideo.getAttribute("data-src") || null;
    let poster = domVideo.getAttribute("data-poster") || null;
    let isAudio = domVideo.getAttribute("data-is-audio") || false;
    let realId = domVideo.getAttribute("data-real-id") || null;
    return {domId, videoStart, dataType, tracks, brightcoveId, src, poster, isAudio, realId};
  }

  getDataFor(domVideo) {
    const isMultimediaPage = () => document.location.pathname.startsWith("/multimedia/");
    let {domId, videoStart, dataType, tracks, brightcoveId, src, poster, isAudio, realId} = this.getDataFromDomItem(domVideo);
    if(!isMultimediaPage()) return {domId, videoStart, dataType, tracks, brightcoveId, src, poster, isAudio};
    let data = window.localStorage.getItem(`multimedia_${realId}`);
    if(!!data) {
      try {
        const parsedData = JSON.parse(data);
        tracks = JSON.stringify(parsedData.dataTracks || []);
        videoStart = parsedData.preferedTimestamp || 0;
        return {domId, videoStart, dataType, tracks, brightcoveId, src, poster, isAudio};
      } catch(e) {
        console.debug("ERR", e);
      }
    }
    return {domId, videoStart, dataType, tracks, brightcoveId, src, poster, isAudio};
  }

  initializeAll() {
    const domVideos = document.querySelectorAll(this.selector);
    domVideos.forEach(domVideo => {
      let {domId, videoStart, dataType, tracks, brightcoveId, src, poster, isAudio} = this.getDataFor(domVideo);
      if(!!tracks) tracks = JSON.parse(tracks);
      let tech = dataType === 'youtube' ? dataType : "Html5";
      let videoJS = null;
      const plugins = {
        wavesurfer: {
          backend: 'MediaElement',
          displayMilliseconds: true,
          debug: true,
          waveColor: 'white',
          progressColor: 'gray',
          cursorColor: 'gray',
          hideScrollbar: true
        }
      };

      switch (dataType) {
        case "youtube":
          videoJS = videojs(`#${domId}`, { "techOrder": [tech],"youtube": { "iv_load_policy": 1}});
        break;
        case "brightcove":
          if(!!isAudio) {
            // const player = document.querySelector(`#${domId}`);
            // player.setAttribute('data-account', player.getAttribute('data-pre-account'));
            // player.setAttribute('data-player', player.getAttribute('data-pre-player'));
            // player.setAttribute('data-video-id', player.getAttribute('data-pre-video-id'));
            videoJS = window.bc(`#${domId}`/* , { wavesurfer: plugins.wavesurfer } */);
          } else {
            videoJS = window.bc(`#${domId}`);
          }
          break;
        default:
          if(!!isAudio) {

            videoJS = videojs(`#${domId}`, {"poster": poster, "sources": [{"type": "audio/aac", "src": src}],
            "html5": true, "controls": true, plugins,
            "controlBar": {"fullscreenToggle": false}});

          } else {
            videoJS = videojs(`#${domId}`);
          }
        break;
      }
      this.availableVideos[domId] = { videoJS, tech, dataType, videoStart, tracks, brightcoveId, isAudio: !!isAudio };
      this.onVideoReady(domId);
    });
    this.addAutoPause();
  }

  initializeConsumptionControl() {
    const domVideos = document.querySelectorAll(this.selector);
    domVideos.forEach(domVideo => {
      const id = domVideo.getAttribute("data-real-id");
      const dataId = domVideo.getAttribute('id');
      const consumptionHelper = new ConsumptionHelper(id);
      this.consumptionHelpers[id] = consumptionHelper;
      const vjs = this.availableVideos[dataId].videoJS;
      consumptionHelper.get()
        .then(res => {
          this.limits[id] = res;
          console.debug(res);
          // If the video has some kind of blocking, lock the progress control bar:
          if (res.status !== 'NOT_BLOCKED') {
            const styleData = document.createElement('style');
            styleData.setAttribute('class', 'vjs-blocker');
            styleData.innerHTML = `
            .vjs-progress-control{
              pointer-events: none;
            }
            .vjs-play-control.vjs-paused.vjs-ended {
              pointer-events: none;
            }
            `;
            document.querySelector('head').appendChild(styleData);
          }
          // If the video is blocked, destroy it:
          if (res.status === 'BLOCKED') {
            console.debug(vjs);
            vjs.dispose();
            //Show restriction modal if multimedia page
            this.showMultimediaBlockedModal('blocked_message');
          }
          // Otherwise, add update interval only if the video has restrictions:
          else if(res.status === 'RESTRICTED_ACCESS') {
            vjs.currentTime(res.consumption.watched_time);
            this.intervals[id] = setInterval(() => {
              consumptionHelper.update(vjs.currentTime(), vjs.duration()).then(res => {
                this.limits[id] = res;
                if(res.status === 'BLOCKED') {
                  clearInterval(this.intervals[id]);
                  delete this.intervals[id];
                  // vjs.dispose();
                }
              }).catch(err => {
                console.debug("ERR", err);
              });
            }, 10000);
            //Show restriction modal if multimedia page
            this.showMultimediaBlockedModal('restricted_message');
          }
        })
        .catch(e => {
          console.debug("ERR", e);
        });
    });
  }

  showMultimediaBlockedModal(message) {
    if($('#multimedia-block-warn-modal').length) {
      let url = this.getMultimediaWarnRoute();
      axios.get(url, {
        params : { type : message }
      }).then((response) => {
          let modal = $('#multimedia-block-warn-modal');
          var multimediaBlockWarnModalBody = document.getElementsByClassName('.multimedia-block-warn-modal-body');
          multimediaBlockWarnModalBody.innerHTML(Utils.escapeHtml(response.data.message));
          modal.show();
        });

      $("#multimedia-block-warn-modal .close-button, .multimedia-block-warn-modal-button").click(() => $('#multimedia-block-warn-modal').hide());
    }
  }

  getMultimediaWarnRoute() {
    let routesHelper = new RoutesHelper();
    return routesHelper.getRoute('consumption-message');
  }

  /**
   * Fix for brightcove theme:
   */
  addStyleFixerInterval() {
    this.styleFixerInterval = setInterval(() => {
      const styleItem = document.querySelector(".bc-style-20oWeuZ2k-default");
      if(!!styleItem) {
        styleItem.innerHTML = ':root{--canceled: "1"}';
      }
    }, 1000);
  }
}
