<template>
  <div class="row">
    <div class="clinical-cases__description col">
      <div class="clinical-cases__description__container" v-html="kj.description">
      </div>
      <div class="clinical-cases__description__tools">
        <button class="v4-btn-primary" @click="goToContentTab()">
          <span v-if="kj.progress == 0">{{t('knowledge-journey.buttons.start-journey')}}</span>
          <span v-else>{{t('knowledge-journey.card.keep_learning')}}</span>
        </button>
        <button data-action="more" @click="readMoreDescription($event)" class="clinical-cases__description__tools__read-more d-none">
          {{t('congress.read-more')}}
        </button>
      </div>
    </div>
    <div class="clinical-cases__right-panel__panel col">
      <template v-if="currentDisplay">
        <KnowledgeJourneyProgress class="clinical-cases__tabsheet__progress"
          :progress="currentProgress"
          :diploma="showDiploma"
          :kjId="kj.id"
          :kjTitle="kj.title">
        </KnowledgeJourneyProgress>
      </template>
      <ul>
        <li>
          <em class="bi bi-clock"></em> <span>{{minutes}} {{this.t('knowledge-journey.presentation.minutes')}}</span>
        </li>
        <li>
          <em class="bi bi-clipboard-check"></em> <span>{{this.t('knowledge-journey.presentation.autoevaluation-test')}}</span>
        </li>
        <li v-if="diploma">
          <em class="bi bi-award"></em> <span>{{this.t('knowledge-journey.presentation.mmp-cert')}}</span>
        </li>
        <li>
          <em class="bi bi-globe"></em> <span>{{translatedLanguage}}</span>
        </li>
        <li>
          <em class="bi bi-people"></em> <span>{{studentsNumber}} {{this.t('knowledge-journey.presentation.students')}}</span>
        </li>
        <li>
          <em class="bi bi-card-text"></em> <span>{{chaptersNumber}} {{this.t('knowledge-journey.presentation.chapters')}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import KnowledgeJourneyProgress from './KnowledgeJourneyProgress.vue';
import $ from 'jquery';

export default {
  name: 'KnowledgeJourneyPresentation',
  components: {
    KnowledgeJourneyProgress
  },
  props: {
    kj: Object,
    infocard: Object,
    progress: Number,
    display: Boolean,
    diploma: Boolean
  },

  data() {
    return {
      currentDisplay: this.display,
      currentProgress: this.progress,
      minutes: 0,
      language: '',
      translatedLanguage: '',
      studentsNumber: 0,
      chaptersNumber: 0,
      showDiploma: this.diploma ?? false,
      translator: TranslateService.create(),
    }
  },

  mounted() {
    this.parseInfo();
    this.calcPresentationHeight();
    document.addEventListener("updateKJPercentage", (event) => {
        if(event.detail > this.progress) {
          this.currentProgress = event.detail;
        }      
      });
    /* this.$root.$on('updateKJPercentage', (percentage) => {
      if(percentage > this.progress) {
        this.currentProgress = percentage;
      }
    }); */
  },

  methods: {
    parseInfo() {
      this.minutes = this.kj.totalMinutes ?? 0;
      this.studentsNumber = this.kj.students ?? 0;
      this.language = this.kj.nativeLanguage.native ?? '';
      this.chaptersNumber = this.kj.chapters.length ?? 0;
      this.translatedLanguage = this.infocard.translated_language ?? this.kj.nativeLanguage.native;
    },
    calcPresentationHeight() {
      if ($(".clinical-cases__description__container").outerHeight() > 250) {
        $(".clinical-cases__description__container").addClass("clinical-cases__description__container--larger");
        $(".clinical-cases__description__tools__read-more").removeClass("d-none");
      }
    },
    readMoreDescription(e) {
      if ($(e.currentTarget).data('action') == 'more') {
        $(".clinical-cases__description__container").removeClass("clinical-cases__description__container--larger");
        $(".clinical-cases__description__tools__read-more").text(this.t('congress.read-less'));
        $(e.currentTarget).data('action', 'less');
      } else {
        $(".clinical-cases__description__container").addClass("clinical-cases__description__container--larger");
        $(".clinical-cases__description__tools__read-more").text(this.t('congress.read-more'));
        $(e.currentTarget).data('action', 'more');
      }
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    goToContentTab() {
      document.dispatchEvent(new CustomEvent("goToContentTab"));
      // this.$root.$emit('goToContentTab');
    }
  },
}
</script>
