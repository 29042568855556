import {BehaviorSubject} from "rxjs";
import {deleteCookie, getCookie, setCookie} from "./cookies";
import {distinctUntilChanged, distinctUntilKeyChanged, filter} from "rxjs/operators";
import {AmgenAuth} from "./amgen-auth";

export class AmgenSpecialities extends AmgenAuth {

    constructor(config) {

        super(config);

        this._promises = {};
        this._onSpecialityServiceReady = new BehaviorSubject(false);
        this._onSpecialities = new BehaviorSubject([]);
        this._onMainSpeciality = new BehaviorSubject(JSON.parse(getCookie('main_speciality')));
        this._onSelectedSpeciality = new BehaviorSubject(JSON.parse(getCookie('selected_speciality') || getCookie('main_speciality')));

        this.onUser.subscribe((user) => {

            if (user) {
                this.mainSpeciality = user.speciality || null;
            }
        });

        this.onAuthChange.subscribe((isAuth) => {

            if (!this.professional) {
                this.mainSpeciality = null;
                this.selectedSpeciality = null;
            }
        });
    }

    get onSpecialityServiceReady() {
        return this._onSpecialityServiceReady.asObservable()
            .pipe(
                filter(ready => ready)
            );
    }

    get onSpecialities() {
        return this._onSpecialities.asObservable();
    }

    get onMainSpeciality() {
        return this._onMainSpeciality.asObservable()
            .pipe(
                distinctUntilChanged((a, b) => {
                    return (a === b) || (a && b && a.id === b.id);
                })
            );
    }

    get onSelectedSpeciality() {
        return this._onSelectedSpeciality.asObservable()
            .pipe(
                distinctUntilChanged((a, b) => {
                    return (a === b) || (a && b && a.id === b.id);
                })
            );
    }

    get mainSpeciality() {
        return this._onMainSpeciality.getValue();
    }

    set mainSpeciality(value) {

        if (value) {
            setCookie('main_speciality', JSON.stringify(value), 1);

            if (!this.selectedSpeciality) this.selectedSpeciality = value;
        } else {
            deleteCookie('main_speciality');
        }

        this._onMainSpeciality.next(value);
    }

    get selectedSpeciality() {
        return this._onSelectedSpeciality.getValue();
    }

    set selectedSpeciality(value) {

        if (value) {
            setCookie('selected_speciality', JSON.stringify(value), 1);
        } else {
            deleteCookie('selected_speciality');
        }

        this._onSelectedSpeciality.next(value);
    }

    get specialities() {
        return this._onSpecialities.getValue();
    }

    set specialities(value) {
        this._onSpecialities.next(value || []);
    }

    _setCallbackListeners() {

        super._setCallbackListeners();

        window.addEventListener('message', (event) => {

          if (event.data && typeof event.data === 'string') {
            try {
              JSON.parse(event.data);
            } catch (e) {
              console.debug('ERR', e);
              return;
            }
          }

            let eventData = event.data && typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

            if (eventData) {

                let data = eventData.data;
                let error = eventData.error;

                switch (eventData.action) {

                    case 'specialities':

                        if (this._promises.specialities) {
                            if (error) this._promises.specialities.reject(error);
                            else this._promises.specialities.resolve(data);

                            delete this._promises.specialities;
                        }

                        if (error) this.specialities = null;
                        else this.specialities = data;

                        break;
                }
            }
        });
    }

    init() {

        super.init();

        this.onAuthServiceReady.subscribe(() => {

            this.getSpecialities()
                .finally(() => {
                    this._onSpecialityServiceReady.next(true);
                });
        });
    }

    getSpecialities() {

        return new Promise((resolve, reject) => {

            this._promises.specialities = {resolve, reject};

            this.postMessage('specialities');
        });
    }
}
