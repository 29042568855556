<template>
  <div>
    <div v-if="!isLoading" class="custom-template-agenda__carousel">
      <div v-if="!isMobile()">
        <div class="" :id="'agenda_common_1'" v-if="agendaInfo.length > 0">
          <v4-carousel :slidesToShow="3" :slidesToScroll="3" :name="'agenda1'">
            <template v-slot:items>
              <div v-for="(itemAgent, j) in agendaInfo" v-bind:key="j">
                <v4-card v-bind="itemAgent"></v4-card>
              </div>
            </template>
          </v4-carousel>
        </div>
      </div>
      <div v-else>
        <div class="" :id="'agenda_common_1'" v-if="agendaInfo.length > 0">
          <v4-carousel :slidesToShow="1" :slidesToScroll="1" :name="'agenda1'">
            <template v-slot:items>
              <div v-for="(itemAgent, j) in agendaInfo" v-bind:key="j">
                <v4-card v-bind="itemAgent"></v4-card>
              </div>
            </template>
          </v4-carousel>
        </div>
      </div>
    </div>
    <div v-if="isDisplay" class="d-flex justify-content-center my-5">
      <div class="my-5">{{ t('congress.no_result_found') }}</div>
    </div>
  </div>
</template>

<script>

import TranslateService from '../../../core/services/translate.service';
import AgendaFactory from '../../agenda/factories/Agenda.factory';
import $ from 'jquery';

export default {
  data() {
    return {
      isLoading: true,
      isDisplay: false,
      translator: TranslateService.create(),
      dataReady : false,
      agendaInfo: [],
    };
  },
  components: {},
  props: {
    agendaSessions: Object,
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    getEvents(slug) {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      // this.$root.$emit('showCustomLoader', true);
      this.isDisplay = false;
      const response = this.agendaSessions;
      if (response.data.data) {
        this.agendaInfo = response.data.data.map(item => {
          return AgendaFactory.get(item, slug);
        });
      }
      this.isLoading = false;
      $('.slick-slider').slick('refresh');
      if (this.agendaInfo.length == 0) {
        this.isDisplay = true;
      }
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
      // this.$root.$emit('showCustomLoader', false);
      this.dataReady = true;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  }
};
</script>
