<template>
  <ModalComponent v-show="showModal" @close="() => close(`${speakerItem.name} ${speakerItem.surname}`)" id="satellital-speakers-modal">
    <template v-slot:header>&nbsp;</template>
    <template v-slot:body>
      <div class="general-block">
        <div class="speakers-info">
          <div class="speaker-item">
            <div class=" speaker-img">
              <img :src="parseImage(speakerItem.photo)" @error="showDefaultImage($event)" alt="speaker" />
            </div>
            <div class="speaker-item-right">
              <h5 class="mb-3">{{ speakerItem.name }} {{ speakerItem.surname }}</h5>
              <p v-html="speakerItem.institution" class="mb-5"></p>
              <p v-html="speakerItem.bio"></p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>&nbsp;</template>
  </ModalComponent>
</template>

<script>
import ModalComponent from '../../internal-congress/ModalComponent.vue';
import PageStayController from '../../../page-stay/PageStayController.js';
import GoogleAnalytics from '../../../GoogleAnalytics.js';
import $ from 'jquery';

export default {
  props: {
    show: Boolean
  },
  components: {
    ModalComponent
  },
  data() {
    return {
      showModal: this.show ?? false,
      speakerItem: {}
    };
  },
  mounted() {
    document.addEventListener("showSatellitalSpeakerModal", (event) => {
      this.speakerItem = event.detail;
      this.showModal = true;
      GoogleAnalytics.sendOpenContentEvent(event.detail, 'modal');
    });
  },
  methods: {
    close(speakerName) {
      this.showModal = false;
      this.speakerItem = {};
      PageStayController.stopStayPageTime();
      GoogleAnalytics.sendCloseContentEvent(speakerName, 'modal');
    },
    imageUrlAlt(event) {
      event.target.src = '/images/v4/assets/avatar.svg';
    },
    parseImage(img) {
      if (img == null) {
        const loc = document.location;
        return `${loc.protocol}//${loc.host}/` + 'images/v4/default-user-image.png';
      }
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
    showDefaultImage(event) {
      const loc = document.location;
      event.target.src = `${loc.protocol}//${loc.host}/` + 'images/v4/default-user-image.png';
    }
  }
};
</script>
