<template>
  <div class="checkbox-form">
    <div class="answers">
      <label class="item">
        <span @click="showModal">
          <slot></slot>
        </span>
        <input :checked="modelValue" @change="updateValue" @click="preventIfNeeded" type="checkbox" :id="itemId"
          :disabled="disabled" />
        <span class="checkmark" v-bind:class="{ disabled: disabled }" />
      </label>
    </div>
  </div>
</template>

<script>
import GoogleAnalytics from '../../../GoogleAnalytics.js';

import { ref } from 'vue';

export default {
  setup() {
    const modelValue = ref(false)

    return {
      modelValue
    }
  },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    disabled: Boolean,
    itemId: String,
    value: Boolean,
    usingPopup: Boolean,
    text: String,
    title: String,
    checked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let blockByPopup = this.usingPopup;
    let isDisabled = this.disabled && blockByPopup;
    return {
      blockByPopup,
      isDisabled
    };
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.checked);
    },
    preventIfNeeded(e) {
      if (this.usingPopup && this.blockByPopup) {
        e.preventDefault();
        document.dispatchEvent(new CustomEvent("showTermModal", {
          detail: {
            title: this.title,
            text: this.text
          }
        }));
        this.blockByPopup = false;
      }
    },
    showModal(e) {
      this.blockByPopup = false;
      if (this.usingPopup) {
        e.preventDefault();
        document.dispatchEvent(new CustomEvent("showTermModal", {
          detail: {
            title: this.title,
            text: this.text
          }
        }));
      }
    },
    handleOutboundClick(url) {
      const parts = url.split('/');
      const urlDomain = parts[2];
      const linkText = parts.slice(3).join(' ').replace(/-/g, ' ');
      GoogleAnalytics.sendOutboundClickEvent(linkText, url, urlDomain);
    },
  }
};
</script>
