<template>
  <section>
    <div v-if="hideParticles" class="particle-ball-thingy">
      <VParticleBigBall></VParticleBigBall>
    </div>

    <div v-if="!hideBottomRightImage" class="bottom-particle-thingy">
      <img src="/images/v4/assets/satellital-bola-blur-cuarto.png" alt="Bottom static particle thingy">
    </div>

    <section>

     <RegisterFirstStep v-if="step == 1"
        :receivedCountries="receivedCountries"
        :email="email"
        :countryId="country"
        :slug="slug"
        :congress="congress"
        :tabs="tabs"
        :hasOnBehalfRole="hasOnBehalfRole"
        :isAlreadyRegisteredOnCongress="isAlreadyRegisteredOnCongress"
        @next-step="nextStep"
        @selected-country="getSelectedCountry"
        @on-behalf="onBehalf"
      ></RegisterFirstStep>

      <RegisterEventFormStep  v-if="step == 2"
        @back-step="backStep"
        :slug="slug"
        :email="email"
        :congress="congress"
        :countryId="selectedCountry"
        :selectedCountryData="selectedCountryData"
        @next-step="nextStep"
        :isOnBehalf="isOnBehalf"
      ></RegisterEventFormStep >

      <div v-if="step == 3">
        <RegisterFinalStep :isOnBehalf="isOnBehalf" :email="email" :slug="slug"></RegisterFinalStep>
      </div>

    </section>

  </section>

</template>

<script>

import RegisterFirstStep from './RegisterFirstStep.vue';
import RegisterEventFormStep from './RegisterEventFormStep.vue';
import RegisterFinalStep from './RegisterFinalStep.vue'
import VParticleBigBall from '../particle-ball/VParticleBigBall.vue';
import { useAppStore } from '../../store/app.store';

export default {
  setup(){
    const loaderStore = useAppStore();
    return {
      loaderStore
    }
  },
  components: {
    RegisterFirstStep,
    RegisterEventFormStep,
    RegisterFinalStep,
    VParticleBigBall
  },
  props: {
    receivedCountries: Array,
    email: String,
    country: Number,
    slug: String,
    congress: Object,
    tabs: Array,
    hasOnBehalfRole: Boolean,
    isAlreadyRegisteredOnCongress: Boolean,
    isLoggedIn: Boolean,
    hideBottomRightImage: Boolean,
  },
  data() {
    return {
      step: this.getInitialStep(),
      selectedCountry: -1,
      selectedCountryData: {},
      hideParticles: this.congress ? this.congress.hideParticles : true,
      isOnBehalf: false,
    };
  },
  mounted() {
    this.initSelectedCountry();
  },
  methods: {
    getSelectedCountry(countryId){
      let foundCountry = null;
      this.receivedCountries.forEach( country => {
        if(country.id == countryId){
          foundCountry = country;
        }
      });
      this.selectedCountry = countryId;
      this.selectedCountryData = foundCountry;
    },
    nextStep(receivedStep){
      this.step = receivedStep;
    },
    onBehalf(){
       this.isOnBehalf = true;
    },
    backStep(){
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.step--;
    },
    getInitialStep() {
      if (this.slug) {
        return 1;
      } else {
        return 2;
      }
    },
    initSelectedCountry() {
      if (this.country) {
        this.getSelectedCountry(this.country);
      }
    }
  }
};
</script>
