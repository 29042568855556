import axios from "axios";

export default {
    async list(requestData) {
        // await axios.post('/login', {
        //     email: 'carolv@amgen.com',
        //     password: 'carolvillaro'
        // });

        const response = await axios.get('/trending-content/month');
        return response.data.data;
    }
};