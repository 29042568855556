<template>
  <div>
    <div>
      <div class="dual-block row">
        <div :class="[(isspeaker && congress.agenda_session.deactivate_qa) ? 'col-sm-12' : 'col-sm-6']">
          <div class="full-video-box">
            <div class="full-video-size">
              <template v-if="meetPlatform == 'ZOOM'">
                <iframe :src="routes.zoom_url + '/' + meetCode + '/' + meetPassword" style="width:100%" frameborder="0"
                  allow="microphone; camera; fullscreen" title="Zoom Meeting">
                </iframe>
              </template>
              <template v-if="meetPlatform == 'WEBEX'">
                <webex
                  :src="meetCode"
                  :jwt="jwt"
                  :webex-data="webexData"
                  :is-moderator=true
                  :event-url="eventUrl"
                  title="WebEX"
                  allow="microphone; camera">
              </webex>
              </template>
              <template v-if="meetPlatform == 'EXTERNAL'">
                <template v-if="videotype == 1">
                  <div :id="'player_'+agendaId"></div>
                </template>
                <template v-if="videotype == 2">
                  <div>
                    <iframe 
                      id="iframe-live" 
                      title="External Video Type Vimeo"
                      class="iframe-live-vimeo" 
                      :src="streamUrl" 
                      frameborder="0"
                      allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                      allowfullscreen
                      mozallowfullscreen 
                      webkitallowfullscreen>
                    </iframe>
                  </div>
                </template>
                <template v-if="videotype == 3 && youtubevideoId != ''">
                 <!--  <youtube :video-id="youtubevideoId" ref="youtube" :player-vars="playerVars" :key="youtubeKey"></youtube> -->
                </template>
                <template v-if="videotype == 4 && streamUrl.includes('/cdn3.wowza.com/')">
                  <video id="wowza_player" width="100%" height="100%"
                    class="video-js video-js-player vjs-default-skin wowza-player" data-real-id="0" autoplay="true"
                    muted="muted" controls>
                    <source :src="streamUrl" type="application/x-mpegURL">
                  </video>
                </template>
                <template v-if="videotype == 4 && streamUrl.includes('/embed.wowza.com/')">
                  <iframe
                    id="iframe-live"
                    class="iframe-live-wowza wowza-player"
                    :src="streamUrl"
                    frameborder="0"
                    allow="fullscreen"
                    >
                  </iframe>
                </template>
                <template v-if="videotype == 5">
                  <iframe :id="'iframe_viewer-wb-' + itemid"
                    class="embed-responsive-item multimedia-embed-item video-js-player video-js" :data-id="itemid"
                    data-start="15" :data-real-id="itemid" :data-url="streamUrl" :data-src="streamUrl" frameborder="0"
                    :src="streamUrl" allow="fullscreen" title="External Video">
                  </iframe>
                </template>
              </template>
              <div class="live-now-top">
              </div>
            </div>
            <div class="video-title">
              <h3 class="text-left">{{ defaultTitle }}</h3>
            </div>

            <div id="parent" ref="infoBox">
              <div class="user-detail-cnt" v-for="(item, i) in speakersData" v-bind:key="i">
                <div class="user-img-detail"><img :src="item.photo" @error="showDefaultImage" alt="Detailed image user" />
                </div>
                <div class="user-detail-content">
                  <h4 class="text-left">{{ item.name + ' ' + item.surname }}</h4>
                  <p class="text-left" v-html="item.bio"></p>
                </div>
              </div>
            </div>
            <div class="btn-right">
              <button data-action="more" @click="readMoreDescription($event)"
                class="clinical-cases__description__tools__read-more d-none">{{ t('congress.read-more') }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-6" v-if="(isspeaker && !congress.agenda_session.deactivate_qa) || !isspeaker">
          <div class="title-two-list">
            <ul class="clearfix">
              <li :class="(isspeaker) ? 'speaker_aud_que' : ''"><a href="javascript:void(0)"
                  :class="(isAudience) ? 'active' : ''" @click="action(1)">Audience Questions <span id="audience_count"
                    v-if="!isspeaker">({{ audienceCount }})</span><span v-if="isspeaker"
                    id="ponent_count">({{ audienceCount }})</span></a></li>
              <li v-if="!isspeaker"><a href="javascript:void(0)" :class="(isPonent) ? 'active' : ''"
                  @click="action(2)">Sent to Speaker <span id="ponent_count">({{ ponentCount }})</span></a></li>
            </ul>
            <template v-if="isAudience">
              <ModeratorAudience :slug="slug" :itemid="itemid" :userid="userid" :key="componentKey" :congress="congress" v-if="!isspeaker">
              </ModeratorAudience>
              <ModeratorPonent :slug="slug" :itemid="itemid" :isspeaker="isspeaker" v-else></ModeratorPonent>
            </template>
            <template v-if="isPonent && !isspeaker">
              <ModeratorPonent :isspeaker="isspeaker" :slug="slug" :itemid="itemid" @onPonentDataLoad="updatePonentCount">
              </ModeratorPonent>
            </template>
          </div>
        </div>
      </div>
      <template v-if="!isspeaker">
        <ModeratorVote :slug="slug" :itemid="itemid" v-if="!isspeaker"></ModeratorVote>
      </template>
    </div>
  </div>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import SpeakerFactory from './factories/Speakers.factory';
import ModeratorAudience from '../moderator/moderatorAudience.vue';
import ModeratorPonent from '../moderator/moderatorPonent.vue';
import ModeratorVote from '../moderator/moderatorVote.vue'
import axios from 'axios';
import $ from 'jquery';
// import eventCallerService from '../../core/services/eventCaller.service';
import Webex from '../congresslive/Webex.vue';
// import { Youtube } from 'vue-youtube';
import brightcovePlayerLoader from '@brightcove/player-loader';
import VJSTrackHelper from '../multimedia/helpers/VJSTrackHelper';


export default {
  name: 'moderatorDetails',
  components: {
    ModeratorAudience,
    ModeratorPonent,
    ModeratorVote,
    Webex,
  },
  props: [
    'slug',
    'details',
    'speakers',
    'itemid',
    'isspeaker',
    'meetPlatform',
    'meetCode',
    'meetPassword',
    'streamUrl',
    'routes',
    'userid',
    'jwt',
    'webexData',
    'eventUrl',
    'agendaId',
    'videotype',
    'congress'
  ],
  data() {
    return {
      parentHeight: 0,
      youtubeKey: 0,
      youtubevideoId: '',
      playerVars: {
        autoplay: 1
      },
      accountid: 0,
      videoid: 0,
      speakersData: [],
      defaultTitle: '',
      isAudience: true,
      audienceCount: 0,
      ponentCount: 0,
      isPonent: false,
      translator: TranslateService.create(),
      componentKey: 0
    }
  }, mounted() {
    this.agendaSpeakers();
    if (typeof this.details !== "undefined" && this.details.length > 0) {
      this.defaultTitle = this.details[0].title;
    }
    document.addEventListener("updateAudienceData", () => {
      this.componentKey += 1;
    });
    /*eventCallerService.$on('updateAudienceData', data => {
      this.componentKey += 1;
    });*/
    if (typeof this.streamUrl !== "undefined" && this.streamUrl && this.streamUrl != "" && this.streamUrl.length > 0 && this.meetPlatform == 'EXTERNAL') {
      if (this.streamUrl.match(/brightcove/g) != null) {
        const accountid = this.streamUrl.split("/").slice(3)[0];
        const videoId = this.getParameterByName('videoId', this.streamUrl);
        this.brightcove(accountid, videoId, 'default', 'default', this.agendaId)
      }

      let path = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (this.streamUrl.match(path) != null) {
        this.youtubevideoId = this.youtube_parser(this.streamUrl);
        this.youtubeKey++;
        $('.plays').hide();
      }

      if (this.streamUrl.match(/cdn3.wowza.com/g) != null) {
        setTimeout(() => {
          let player = window.vjs("wowza_player");
        }, 2000);
      }
    }
  }, methods: {
    calcPresentationHeight() {
      if (this.speakersData.length > 0) {
        $("#parent").addClass("speakerchild");
        $(".clinical-cases__description__tools__read-more").removeClass("d-none");
      }
    },
    readMoreDescription(e) {
      if ($(e.currentTarget).data('action') == 'more') {
        $("#parent").removeClass("speakerchild");
        $(".clinical-cases__description__tools__read-more").text(this.t('congress.read-less'));
        $(e.currentTarget).data('action', 'less');
      } else {
        $("#parent").addClass("speakerchild");
        $(".clinical-cases__description__tools__read-more").text(this.t('congress.read-more'));
        $(e.currentTarget).data('action', 'more');
      }
    },
    action(number) {
      if (number == 1) {
        this.isAudience = true;
        this.isPonent = false;
      } else {
        this.isAudience = false;
        this.isPonent = true;
      }
    }, async agendaSpeakers() {
      const response = await this.getSpeakersData();
      this.speakersData = response.data.data.map(k => SpeakerFactory.get(k));
      this.isLoading = false;
      this.calcPresentationHeight();
    },
    getSpeakersData() {
      return axios.get(`/agenda-session/speakers`, {
        params: {
          id: this.itemid,
        }
      });
    }, showDefaultImage(event) {
      const loc = document.location;
      event.target.src = `${loc.protocol}//${loc.host}/` + "images/v4/default-user-image.png"
    }, brightcove(accountId, videoId, playerid, embedid, id) {
        const player = brightcovePlayerLoader({
          refNode: '#player_' + id,
          accountId,
          playerId: playerid,
          embedId: embedid,
          videoId
        });

        console.log("PLAYER")
        console.log(player)

        let self = this;

        player.then(res => {
          console.log("DENTRO THEN")
          self.videoJS = res.ref;
          self.trackHelper = VJSTrackHelper.create(res.ref, "players", this.id);
          self.trackHelper.initialize();


          console.log("DENTRO THEN ATTR")
          //Add missing attrs to video
          $($('#player_' + id).find('video-js')[0]).addClass('video-js-player');
          $($('#player_' + id).find('video-js')[0]).attr('id', `video-js-player-${self.id}`);
          $($('#player_' + id).find('video-js')[0]).attr('data-real-id', `${self.id}`);


          console.log("DENTRO THEN INIT")
          self.vjs = new VJSInitializer();
          self.vjs.initializeAll();
          self.vjs.initializeConsumptionControl();

          self.videoJS.on('ended', function () {
            self.$root.$emit('testVideoEnd');
          });
        });
      }, getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, '\\$&');
      let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }, youtube_parser(url) {
      let regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
      let match = url.match(regExp);
      return (match && match[1].length == 11) ? match[1] : false;
    }, isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    },
    updateAudienceCount(count) {
      this.audienceCount = count;
    },
    updatePonentCount(count) {
      this.ponentCount = count;
    },
    t(tag) {
      return this.translator.translate(tag)
    }
  }
}
</script>
