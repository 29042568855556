<template>
  <div class="agendaSpeakers" v-if="this.agendaSpeakersList.length > 0">
    <ul>
      <li v-for="(item, i) in this.agendaSpeakersList" :key="i">
        <AgendaSpeakersItem v-bind="item" :slug="slug" :template="template" />
      </li>
    </ul>
  </div>
  <div v-else>
    <p class="no_content text-left">{{ t('congress.no_speakers_found') }}</p>
  </div>
</template>

<script>
import AgendaSpeakersItem from './AgendaSpeakersItem.vue';
import TranslateService from '../../core/services/translate.service.js';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'AgendaSpeakers',
  components: {
    AgendaSpeakersItem
  },
  props: {
    agendasessionid: String,
    slug: String,
    template: Number
  },
  mounted() {
    axios
      .get(`/agenda-session/speakers`, {
        params: {
          id: this.agendasessionid
        }
      })
      .then(res => {
        this.agendaSpeakersList = res.data.data;
        this.status = true;
        if (this.agendaSpeakersList.length == 0) {
          $('#agenda-speakers-section').css('display', 'none');
        }
      })
      .catch(err => {
        console.warn(err);
        this.status = false;
      });
  },
  methods: {
    agendaSpeakersInfo: function agendaSpeakersInfo() {
      axios
        .get(`/agenda-session/speakers`, {
          params: {
            id: this.agendasessionid
          }
        })
        .then(res => {
          this.agendaSpeakersList = res.data.data;
          this.status = true;
        })
        .catch(err => {
          console.warn(err);
          this.status = false;
        });
    },
    t(tag) {
      return this.translator.translate(tag);
    }
  },
  data: function() {
    return {
      agendaSpeakersList: Array,
      status: Boolean,
      translator: TranslateService.create()
    };
  }
};
</script>
