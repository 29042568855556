<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha">
            <path fill="#D9D9D9" d="M0 0h24v24H0z" />
        </mask>
        <g mask="url(#a)">
            <path fill="#2DBCB6"
                d="M8 14c.283 0 .52-.096.712-.287A.968.968 0 0 0 9 13a.968.968 0 0 0-.288-.712A.967.967 0 0 0 8 12a.967.967 0 0 0-.713.288A.968.968 0 0 0 7 13c0 .283.096.52.287.713.192.191.43.287.713.287Zm4 0c.283 0 .52-.096.713-.287A.968.968 0 0 0 13 13a.968.968 0 0 0-.287-.712A.968.968 0 0 0 12 12a.967.967 0 0 0-.713.288A.968.968 0 0 0 11 13c0 .283.096.52.287.713.192.191.43.287.713.287Zm4 0c.283 0 .52-.096.712-.287A.968.968 0 0 0 17 13a.968.968 0 0 0-.288-.712A.968.968 0 0 0 16 12a.967.967 0 0 0-.713.288A.968.968 0 0 0 15 13c0 .283.096.52.287.713.192.191.43.287.713.287Zm-4 8c-1.25 0-2.42-.237-3.513-.712a9.149 9.149 0 0 1-2.85-1.925 9.147 9.147 0 0 1-1.925-2.85A8.707 8.707 0 0 1 3 13c0-1.25.237-2.42.712-3.512a9.148 9.148 0 0 1 1.925-2.85 9.148 9.148 0 0 1 2.85-1.925A8.707 8.707 0 0 1 12 4c1.25 0 2.42.238 3.512.713a9.15 9.15 0 0 1 2.85 1.925 9.149 9.149 0 0 1 1.926 2.85A8.707 8.707 0 0 1 21 13c0 1.25-.238 2.42-.712 3.513a9.148 9.148 0 0 1-1.925 2.85 9.15 9.15 0 0 1-2.85 1.925A8.707 8.707 0 0 1 12 22ZM5.6 2.35 7 3.75 2.75 8l-1.4-1.4L5.6 2.35Zm12.8 0 4.25 4.25-1.4 1.4L17 3.75l1.4-1.4ZM12 20c1.95 0 3.604-.68 4.962-2.037C18.322 16.604 19 14.95 19 13c0-1.95-.68-3.604-2.038-4.962C15.604 6.679 13.95 6 12 6c-1.95 0-3.604.68-4.963 2.038C5.68 9.396 5 11.05 5 13c0 1.95.68 3.604 2.037 4.963C8.396 19.32 10.05 20 12 20Z" />
        </g>
    </svg>
</template>
