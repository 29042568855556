<template>
    <div class="box-common-size">
        <div class="left-timer" v-if="expiration_time > 0" :data-poll-id="id"><i class="bi bi-hourglass-split"></i> <span>{{timePoll}}</span></div>
        <div class="live-title"><h4>Live Voting</h4></div>
        <div class="second-title"><h2 class="text-center">{{ question }}</h2></div>
        <div class="third-content"><p class="text-center">Multi-answer question</p></div>
        <div class="box-padd-new">
            <div class="text-answer">
                <form action="" method="post" @submit="showSubmit" class="modal-questions-form" :data-poll-id="id" :data-poll-type="type" :data-poll-expiration="expiration_time" :id="'quesform_' + id">
                <div class="single-radio-boxes">
                    <input type="hidden" name="pollId" class="questiontextid" ref="answer" :value="id">
                    <div class="checkbox-form" v-for="chkans in options" :key="chkans.id">
                        <div class="answers">
                            <label class="item">{{chkans.option}}<input type="checkbox" :id="`first-${chkans.id}`" :value="chkans.id" name="answer[]" v-model="answer"> <span class="checkmark"></span></label>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import LivePollsController from "../../../v4/modules/live/LivePollsController"
import $ from 'jquery';

export default {
    props: {
        id : Number,
        type: Number,
        question : String,
        expiration_time : Number,
        options : Array,
    },
    data:function(){
        return{
            answer: [],
            timePoll: 0
        }
    },
    mounted() {
        let id = '#quesform_' + this.id;
        $(document).on('submit', id.toString() , ev => this.showSubmit(ev));
        this.timePoll = this.expiration_time;
    },
    watch: {
        timePoll: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timePoll--;
                    }, 1000);
                }else{
                    this.popupQuesType();
                }
            },
        }
    },
    methods: {
        async showSubmit(event){
            event.preventDefault();
            event.stopPropagation();
            if ( this.answer.length == 0 ) {
                LivePollsController.displayToastifyMessage(2 , "Error" , "Answer is required")
            } else {
                var response = await LivePollsController.setAnswerPollFunc(event);
                if ( response.data.status == 201 ) {
                    LivePollsController.displayToastifyMessage(2 , "Error" , response.data.message)
                } else if ( response.data.status == 200 ) {
                    this.popupQuesType();
                    document.dispatchEvent(new CustomEvent("submitLiveSingle"));
                    // this.$root.$emit('submitLiveSingle', {});
                }
            }
        },
        popupQuesType(){
            this.$parent.$parent.closePopup(this.id);
        }
    }
}
</script>
