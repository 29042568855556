<template>
  <div class="connectmi-event__agenda__event"  >
    <div class="connectmi-event__agenda__event__body" @click="openAgenda()">
      <div v-if="this.agenda.is_live || this.agenda.is_on_demand" class="connectmi-event__agenda__event__tag">
        <p v-if="this.agenda.is_live">
          <img :src="createImagePath('icon-live.svg')" alt="live" /> <span>LIVE NOW</span>
        </p>
        <p v-if="this.agenda.is_on_demand">
          <span>ON DEMAND</span>
        </p>
      </div>
      <div class="connectmi-event__agenda__event__date">
        <p v-if="this.agenda.date_formatted">{{ this.agenda.date_formatted }}</p>
      </div>
      <p class="connectmi-event__agenda__event__title">{{ this.agenda.title }}</p>
      <p v-if="this.agenda.speakersList" class="connectmi-event__agenda__event__speakers">{{ this.agenda.speakersList }}</p>
    </div>
    <div :class="'connectmi-event__agenda__event__share-icons share-icons-'+ this.agenda.id" style="display: none;">
        <em href="https://api.whatsapp.com/send?text=" :data-id="this.agenda.id" target="_blank" class="bi bi-whatsapp" @click="onShare($event)"></em>
        <em :href="'mailto:?subject=Shared content from the Medical Meeting Point&body='" :data-id="this.agenda.id" class="bi bi-envelope" @click="onShare($event)"></em>
    </div>
    <div
      :class="
        'connectmi-event__agenda__event__footer connectmi-event__agenda__event__footer--gradient-' +
          parseInt(parseInt(this.gradient) + 1)
      "
      :style="(color1 && color2) ? 'background: linear-gradient(to right, ' + color1 + ', ' + color2 + ');' : ''"
    >
      <div v-if="this.agenda.type == 'multimedia'" class="connectmi-event__agenda__event__footer__icons">
          <div @click="showShareOptions()">
            <img :src="createImagePath('icon-share.svg')" alt="share" />
          </div>
          <div  @click="onBookmark()">
            <img v-if="this.bookmarked" :id="'bookmarked-'+this.agenda.id" :src="createImagePath('icon-bookmark-fill.svg')" alt="bookmark" />
            <img v-else :id="'unbookmarked-'+this.agenda.id" :src="createImagePath('icon-bookmark.svg')" alt="bookmark" />
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import TranslationService from '../../../core/services/translation.service';
import ActivityService from '../../../core/services/activity.service';
import Translate from '../../../../v4/Translations';
import axios from 'axios';
import $ from 'jquery';

export default {
  props: {
    gradient: String,
    color1: String,
    color2: String,
    agenda: Object,
    imagePath: String
  },

  computed: {
  },

  data() {
    return {
      activitySvc: ActivityService.create(),
      label: this.agenda.agenda_category_name ?? "",
      bookmarked: this.agenda.saved
    };
  },

  mounted() {

  },

  methods: {
    openAgenda() {
      if(this.agenda.type == 'agenda_session') {
          if (this.agenda.cards_behaviour == 'external link') {
              // this.$root.$emit('leavingMMPModal', this.agenda.external_link);
              document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.agenda.external_link }));   
          } else if (this.agenda.cards_behaviour == 'open modal') {
              let agendaURL = '';
              if ((this.agenda.is_live || this.agenda.is_on_demand)&& this.agenda.is_accessible && !this.agenda.external_congress) {
                  agendaURL = TranslationService.transRoute('event-detail-live', { slug: this.agenda.slug, agendaSessionId: this.agenda.id });
                  this.saveActivity('live', 'agenda_session');
              } else {
                  agendaURL = TranslationService.transRoute('event-agenda-detail', { slug: this.agenda.slug, agendaSessionId: this.agenda.id });
                  this.saveActivity('agenda', 'agenda_session');
              }
              return window.open(agendaURL, '_blank').focus();
          }
      }else if(this.agenda.type == 'multimedia'){
          this.onMultimediaClick();
          if(this.agenda.external_redirect) {
            // this.$root.$emit('leavingMMPModal', this.agenda.external_url);
            document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.agenda.external_url }));   
        } else {
            this.saveActivity('open-multimedia', 'multimedia');
            return window.open(this.agenda.link, '_blank').focus();
        }
      }
    },
    createImagePath(image) {
      return this.imagePath + image;
    },
    onMultimediaClick() {
      const dataTracks = this.agenda.dataTracks || [];
      // Save item to localStorage:
      localStorage.setItem(`datatracks-m-${this.agenda.id}`, JSON.stringify(dataTracks));
      return true;
    },
    saveActivity(event, modelType) {
      let params = {
          title: this.agenda.title,
          model_type: modelType,
          model_id: this.agenda.id,
          source: null,
          congress_id: this.agenda.congress_id
      }
      this.activitySvc.saveActivity(event, params);
    },
    onBookmark() {
      const url = '/profile/save-bookmark';
      const fd = new FormData();
      fd.append("model_id", this.agenda.id);
      fd.append("model_type", this.agenda.type);
      fd.append("like", this.agenda.title);
      fd.append("source", '');
      fd.append("congress_id", this.agenda.congress_id);

      // Call save item
      axios.post(url, fd).then((response) => {
        if(response.data){
          this.bookmarked = response.data.bookmarked;
        }
      }).catch((error) => {
          console.error('Error on bookmark method', error);
      });

    },
    showShareOptions() {
      let icons = $(`.share-icons-${this.agenda.id}`);

      if (icons.is(":visible")) {
          $(icons).hide(200);
          $(icons).fadeOut(200);
      } else {
          $(icons).show(200);
          $(icons).fadeIn(200);
      }
    },
    onShare(ev) {
      const prepareLink = (type, itemId) => {
          let link = '';
          if (type == 'multimedia') {
              const loc = document.location;
              link = escape(`${loc.protocol}//${loc.host}/${type}/${itemId}`);
          }
          return link;
      };
      const itemId = this.agenda.id;
      const type = this.agenda.type;
      const title = this.agenda.title;
      const link = prepareLink(type, itemId);
      const Translation = new Translate();
      const checkoutThis = Translation.translate('general', 'check-out');
      let message = `${checkoutThis} ${title}: ${link}`;

      const target = $(ev.currentTarget);
      const href = target.attr('href');
      const url = href + message;
      if (target.attr('target') === '_blank') {
          window.open(url);
      } else {
          window.location.replace(url);
      }
    }
  }
};
</script>
