<template>
  <ModalComponent v-show="showModal" @close="() => close(`${agendaItem.title}`)" id="satellital-agenda-modal">
    <template v-slot:header>&nbsp;</template>
    <template v-slot:body>
      <div class="general-block">
        <div class="agenda-info">
          <p class="agenda-date">{{ agendaItem.date_formatted }}</p>
          <h4 class="mb-3 agenda_title">{{ agendaItem.title }}</h4>
            <p v-html="agendaItem.description"></p>
            <section class="mt-5" v-if="agendaItem.hasSpeakers">
              <h4 class=" mb-3">{{ t('congress.speakers') }}</h4>
              <div v-for="(item, i) in agendaItem.speakers" :key="i">
                <div class="speaker-item">
                  <div class="speaker_item_left">
                    <img :src="parseImage(item.photo)" @error="showDefaultImage($event)" alt="speaker" />
                  </div>
                  <div class="speaker_item_right">
                    <h5>{{ item.name }} {{ item.surname }}</h5>
                    <p v-html="item.short_bio"></p>
                  </div>
                </div>
              </div>
            </section>
        </div>
      </div>
    </template>
    <template v-slot:footer>&nbsp;</template>
  </ModalComponent>
</template>

<script>
import ModalComponent from '../../internal-congress/ModalComponent.vue';
import TranslateService from '../../../core/services/translate.service.js';
import PageStayController from '../../../page-stay/PageStayController.js';
import GoogleAnalytics from '../../../GoogleAnalytics.js';
export default {
  props: {
    show: Boolean
  },
  components: {
    ModalComponent
  },
  data() {
    return {
      translator: TranslateService.create(),
      showModal: this.show ?? false,
      agendaItem: {}
    };
  },
  mounted() {
    document.addEventListener("showSatellitalAgendaModal", (event) => {
      this.agendaItem = event.detail;
      this.showModal = true;
      GoogleAnalytics.sendOpenContentEvent(event.detail, 'modal');
    });
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    close(agendatitle) {
      this.showModal = false;
      this.agendaItem = {};
      PageStayController.stopStayPageTime();
      GoogleAnalytics.sendCloseContentEvent(agendatitle, 'modal');
    },
    imageUrlAlt(event) {
      event.target.src = '/images/v4/assets/avatar.svg';
    },
    parseImage(img) {
      if (img == null) {
        const loc = document.location;
        return `${loc.protocol}//${loc.host}/` + 'images/v4/default-user-image.png';
      }
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
    showDefaultImage(event) {
      const loc = document.location;
      event.target.src = `${loc.protocol}//${loc.host}/` + 'images/v4/default-user-image.png';
    }
  }
};
</script>
