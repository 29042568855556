<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        :width="width" 
        :height="height" 
        :fill="fill"
        viewBox="0 0 23 24"
    >
        <path 
            :fill="fill" 
            d="M5.25 23.25v-2.5h5v-3.875a6.712 6.712 0 0 1-2.734-1.297 5.6 5.6 0 0 1-1.766-2.39c-1.563-.188-2.87-.87-3.922-2.047S.25 8.583.25 7V5.75c0-.688.245-1.276.734-1.766A2.407 2.407 0 0 1 2.75 3.25h2.5V.75h12.5v2.5h2.5c.688 0 1.276.245 1.766.734.49.49.734 1.079.734 1.766V7c0 1.583-.526 2.964-1.578 4.14-1.052 1.178-2.36 1.86-3.922 2.047a5.6 5.6 0 0 1-1.766 2.391 6.712 6.712 0 0 1-2.734 1.297v3.875h5v2.5H5.25Zm0-12.75V5.75h-2.5V7c0 .792.23 1.505.688 2.14A3.754 3.754 0 0 0 5.25 10.5Zm6.25 4c1.042 0 1.927-.365 2.656-1.094.73-.729 1.094-1.614 1.094-2.656v-7.5h-7.5v7.5c0 1.042.365 1.927 1.094 2.656.729.73 1.614 1.094 2.656 1.094Zm6.25-4a3.754 3.754 0 0 0 1.813-1.36A3.571 3.571 0 0 0 20.25 7V5.75h-2.5v4.75Z"
        />
    </svg>
</template>

<script>
export default {
    props: {
        fill: {
            type: String,
            default: "#9A9A9A"
        },
        width: {
            type: [String, Number],
            default: 30
        },
        height: {
            type: [String, Number],
            default: 30
        }
    }
};
</script>