import { WSHelper } from '../../libs/ws/ws-helper';
import Utils from './Utils';
import WordCloudController from '../v4/WordCloudController';
// import linkifyHtml from 'linkifyjs/html';
import linkifyHtml from "linkify-html";
import FormUtils from '../v4/FormUtils';
import LiveReactionsController from '../v4/modules/live/LiveReactionsController';
// import eventCallerService from './core/services/eventCaller.service';
import axios from 'axios';
import DOMPurify from 'dompurify';
import $ from 'jquery';

const LiveWebsocketsAttendee = {
  ws: null,
  wsToken: null,
  agId: null,
  dataPollIds: [],
  modalTypes: {
    modalResponse: {
      selector: '#modal-response'
    },
    modalAnswers: {
      selector: '#modal-answers'
    },
    modalQuestions: {
      selector: '#modal-questions'
    },
    modalRedirectSession: {
      selector: '#modal-redirect'
    }
  },
  questionsForm: {
    selector: '.modal-questions-form'
  },
  modalContentRenderEl: {
    selector: '.content-render'
  },
  wordcloudCanvasEl: {
    selector: '#wordcloud-canvas'
  },
  surveyEl: {
    prev: '.survey-pagination .prev',
    next: '.survey-pagination .next',
    start: '.survey-pagination .start',
    finish: '.survey-pagination .finish'
  },
  pollsContainer: {
    selector: '.congress-live-back-moderator-polls-container'
  },
  initIntervalsFlag: true,
  getPollFlag: true,
  shownPollsIds: [],
  shownAnswersPollsIds: [],
  routes: {
    setAnswerPoll: 'set-answer-poll',
    getPoll: 'get-poll',
    getSurvey: 'get-survey',
    getPollAnswers: 'get-poll-answers',
    checkPoll: 'check-poll',
    checkActivePolls: 'check-active-polls'
  },
  timer: {
    FULL_DASH_ARRAY: 283,
    TIME_LIMIT: window.app.event ? window.app.event.time_limit || 20 : 20,
    timePassed: 0,
    timeLeft: window.app.event ? window.app.event.time_limit || 20 : 20,
    timerInterval: null,
    pollExpirationInterval: null
  },
  new_message: '',
  zeroPad(num) {
    return num < 10 ? '0' + num : '' + num;
  },

  init() {
    if (!Utils.isLoggedIn()) return false;
    this.initializeSessionVariables();
    this.setListeners();
    this.initRequests();
    this.initWebSockets();
    this.initSlickQuestions();
    this.initPollExpirationInterval();
    // this.initSetIntervals();
  },

  setListeners() {
    // $(document).on('submit', this.questionsForm.selector, ev => this.questionsFormSubmitHandler(ev));
    $(document).on('click', this.surveyEl.prev, ev => this.onSurveyPgChange('prev'));
    $(document).on('click', this.surveyEl.next, ev => this.onSurveyPgChange('next'));
    $(document).on('click', this.surveyEl.start, ev => this.onSurveyPgChange('start'));
    $(document).on('click', this.surveyEl.finish, ev => this.onSurveyPgChange('finish'));

    $(document).on('click', '.message-to-the-audience-close', ev => this.closeMessageToTheAudience());
    $(document).on('click', '.finished-session-next-body-info-cancel', ev => this.cancelSessionRedirect());
  },

  sendWSStatusMessage() {
    const agendaSessionId = Utils.getAgendaSessionId();
    this.agId = agendaSessionId;
    this.ws.sendObject({ agenda_id: agendaSessionId, operation: 'register_ws', uid: window.user.uid });
  },

  onWSMessageReceived(evt) {
    if (evt.json && evt.json.data) {
      /* The data object structure is freely expandable.
       * Required fields:
       * action: "show_poll", "poll_ended", "live_end", etc.
       * timestamp
       * Optional Fields:
       * html: html data (i.e, poll modal, next-session modal, etc)
       */
      const data = evt.json.data;
      //   console.log("new_message1" , this.new_message)
      //If current agenda do action
      if (this.checkIsCurrentAgenda(data.agendaId)) {
        switch (data.action) {
          case 'registered':
            this.wsToken = data.token;
            const token = this.wsToken;
            this.ws.sendObject({ operation: 'read_topic', token });
            this.ws.sendObject({ operation: 'read_user_topic', token });
            break;
          case 'user_data':
            this.dataPollIds = data.poll_ids || [];
            this.filterByPollIds();
            break;
          case 'show_message':
            this.showAudienceMessage(data.message);
            this.addAudienceMessageToList(data.id, data.message, data.timestamp);
            return data.message;
            break;
          case 'show_poll':
            const response = { data, status: 200 };
            console.log('show poll -> ', response);
            try {
              this.getActivePollSuccess(response, this);
            } catch (e) {
              console.debug('ERR', e);
            }
            const polls = document.querySelectorAll('.poll-item');
            polls.forEach((poll, idx) => {
              poll.classList.add(`poll-item-${idx}`);
              try {
                const pollId = poll.children[0].dataset.pollId;
                poll.setAttribute('data-id', pollId);
                poll.classList.add(`poll-id-${pollId}`);
              } catch (e) {
                console.debug('ERR', 'Form not found, skipping...');
              }
            });
            this.filterByPollIds();
            break;
          case 'poll_results':
            console.debug(data.polls);
            this.showPollResults(data.polls || {}, data.statusId);
            // poll results? floating-menu-item-results
            break;
          case 'poll_ended':
            // hide poll:
            let modal = $(this.modalTypes.modalQuestions.selector);
            modal.modal('hide');
            break;
          case 'next_session':
            this.getFinishedSessionInfo();
            break;
          case 'live_reaction':
            $('.textreaction').html(data.numberReactions + ' Reactions');
            LiveReactionsController.showReaction(data.message);
            break;
          default:
            console.debug('Unknown action', data.action);
            break;
        }
      }
    } else {
      console.debug('Unknown message received...');
    }
  },

  checkIsCurrentAgenda(agendaId) {
    if (!agendaId) {
      return true;
    } else {
      const agendaSessionId = Utils.getAgendaSessionId();
      return agendaSessionId == agendaId;
    }
  },

  registerWSEvents() {
    if (!this.ws) return;
    // console.log("message" , this.new_message);
    this.ws.registerCallback(evt => {
      console.debug('[Live Controller] received event!', evt);
      switch (evt.operation) {
        case 'conn_retry_failure':
          // do something like displaying a loading circle?
          break;
        case 'conn_down':
          console.debug('Connection down...');
          break;
        case 'msg_received':
          // A message was received:
          this.onWSMessageReceived(evt);
          break;
        case 'conn_started':
        case 'conn_restarted':
          // Ask current status:
          this.sendWSStatusMessage();
          break;
      }
    });
  },

  applyCountdownToHourglass(poll, difference) {
    let hourglass = poll.parentElement.querySelector('.countdown-hourglass');
    if (!hourglass) return;
    hourglass.classList.remove('sr-only');
    hourglass.querySelector('.hg-min').innerHTML = this.zeroPad(Math.floor(difference / 60000));
    hourglass.querySelector('.hg-sec').innerHTML = this.zeroPad(Math.floor((difference / 1000) % 60));
  },

  initPollExpirationInterval() {
    this.timer.pollExpirationInterval = setInterval(() => {
      const polls = document.querySelectorAll('.poll-item > form');
      polls.forEach(poll => {
        try {
          const expiration = parseInt(poll.getAttribute('data-poll-expiration') || 0, 10);
          if (expiration > 0) {
            const difference = expiration * 1000 - Date.now();
            this.applyCountdownToHourglass(poll, difference);
            if (difference < 0) {
              console.debug(difference, poll.getAttribute('data-poll-id'));
              poll.closest('.poll-item').remove();
            }
          }
        } catch (e) {
          console.debug('ERR', "Couldn't decode expiration time", e);
        }
      });
      const pollStates = document.querySelectorAll('.poll-item .survey-pagination').length;
      const pollHiddenStates = document.querySelectorAll('.poll-item.sr-only').length;
      if (pollStates === 0) {
        $('.modal-live-questions').modal('hide');
      } else {
        this.refreshPagination();
        if (pollStates === pollHiddenStates) {
          this.onSurveyPgChange('start');
        }
      }
    }, 1000);
  },

  initWebSockets() {
    this.ws = new WSHelper(true);
    this.registerWSEvents();
    this.ws
      .start()
      .then(res => {
        console.debug('[LiveController] Started at first attempt...');
      })
      .catch(err => {
        console.debug('ERR', err);
      });
  },

  filterByPollIds() {
    this.dataPollIds.forEach(id => {
      const item = document.querySelector(`.poll-id-${id}`);
      if (!!item) {
        item.remove();
      }
    });
    this.onSurveyPgChange('start');
    const pollStates = document.querySelectorAll('.poll-item .survey-pagination');
    if (pollStates.length === 0) {
      $('.modal-live-questions').modal('hide');
    }
  },

  refreshPagination() {
    try {
      const polls = document.querySelectorAll('.poll-item .survey-pagination');
      if (polls.length === 0) {
        return;
      } else {
        if (polls.length === 1) {
          polls[0].classList.add('sr-only');
        }
        polls.forEach((poll, idx) => {
          const currentPg = poll.querySelector('.current-pg');
          const totalPg = poll.querySelector('.total-pg');
          currentPg.innerHTML = this.zeroPad(idx + 1);
          totalPg.innerHTML = this.zeroPad(polls.length);
        });
      }
    } catch (e) {
      console.debug('ERR', 'refreshPagination failure', e);
    }
  },

  onSurveyPgChange(direction) {
    const polls = document.querySelectorAll('.poll-item');
    if (polls.length === 0) {
      try {
        setTimeout(() => {
          let modal = $(this.modalTypes.modalQuestions.selector);
          modal.modal('hide');
        }, 750);
      } catch (e) {
        console.debug('ERR', e);
      }
      return;
    }
    switch (direction) {
      case 'start':
        polls.forEach(poll => poll.classList.add('sr-only'));
        polls[0].classList.remove('sr-only');
        break;
      case 'finish':
        polls.forEach(poll => poll.classList.add('sr-only'));
        polls[polls.length - 1].classList.remove('sr-only');
        break;
      case 'next':
      case 'prev':
        let currentIdx = -1;
        polls.forEach((poll, idx) => {
          if (!poll.classList.contains('sr-only')) currentIdx = idx;
          poll.classList.add('sr-only');
        });
        if (direction === 'prev') {
          currentIdx = currentIdx > 0 ? currentIdx - 1 : currentIdx;
          polls[currentIdx].classList.remove('sr-only');
        } else {
          currentIdx = currentIdx < polls.length - 1 ? currentIdx + 1 : currentIdx;
          polls[currentIdx].classList.remove('sr-only');
        }
        break;
      default:
        break;
    }
  },

  questionsFormSubmitHandler(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    this.setAnswerPoll(ev, {
      success: this.setAnswerPollSuccess,
      error: this.setAnswerPollError
    });
  },

  initRequests() {},

  initializeSessionVariables() {
    const sessionShownPollsIds = sessionStorage.shownPollsIds;
    const sessionShownAnswersPollsIds = sessionStorage.shownAnswersPollsIds;

    this.shownPollsIds = sessionShownPollsIds ? JSON.parse(sessionShownPollsIds) : [];
    this.shownAnswersPollsIds = sessionShownAnswersPollsIds ? JSON.parse(sessionShownAnswersPollsIds) : [];
  },

  initSetIntervals() {
    setInterval(() => {
      if (this.initIntervalsFlag) {
        this.initIntervalsFlag = false;
        this.getActivePoll({
          success: response => this.getActivePollSuccess(response, this),
          error: this.getActivePollError
        });
      }
    }, 15000);

    setInterval(() => {
      if (this.shownAnswersPollsIds.length > 0) {
        this.checkActivePollsAnswers(this.shownAnswersPollsIds, {
          success: this.checkActivePollsAnswersSuccess,
          error: this.checkActivePollsAnswersError
        });
      }
    }, 20000);
  },

  setTrueGetPollFlag() {
    this.getPollFlag = true;
  },

  getActivePoll(callbacksFn, nextPollId = false) {
    if (this.getPollFlag || nextPollId) {
      this.getPollFlag = false;
      const url = Utils.getUrl(true, this.routes.getSurvey);
      const agendaSessionId = Utils.getAgendaSessionId();
      const data = {
        agendaSessionId: agendaSessionId,
        shownPollsIds: this.shownPollsIds,
        pollId: nextPollId
      };

      this.makeRequest(url, data, callbacksFn);
    }
  },

  getActivePollSuccess(response, nextPollId) {
    const { data, status } = response;

    if (data && status === 200 && data.html) {
      // console.log("data.html -> ",data.html);
      const { html, surveyIds } = data;
      const ids = data.ids || [];
      const modalSelector = this.modalTypes.modalQuestions.selector;
      if (nextPollId) {
        if (this.checkIfModalIsShowed(this.modalTypes.modalQuestions.selector)) {
          // console.log("this.modalTypes.modalQuestions.selector -> ",this.modalTypes.modalQuestions.selector);
          this.changeModalContent(this.modalTypes.modalQuestions.selector, html);
        } else {
          // console.log("modalSelector -> ",modalSelector);
          this.openModal(modalSelector, html);
        }
      } else {
        // console.log("openModal modalSelector -> ",modalSelector);
        this.openModal(modalSelector, html);
      }

      this.shownPollsIds.push(...ids);
      this.shownPollsIds = [...new Set(this.shownPollsIds)];
      this.addToSessionStorage('shownPollsIds', this.shownPollsIds);
      // this.setCheckIfPollStillActiveInterval(modalSelector, ids);
      FormUtils.setFormListeners('poll-items');
      $('.selectpicker').selectpicker();
    } else if (status !== 200 && nextPollId) {
      this.hideModal(this.modalTypes.modalQuestions.selector);
    }
    this.setCheckOpenedModalsInterval(() =>
      this.getAnswersPoll({
        success: this.getAnswersPollSuccess,
        error: this.getAnswersPollError
      })
    );
  },

  changeModalContent(modalSelector, html) {
    const modalElement = document.querySelector(modalSelector);
    const contentRenderElement = modalElement.querySelector(this.modalContentRenderEl.selector);
    const sanitizedHtml = DOMPurify.sanitize(html);
    contentRenderElement.innerHTML = Utils.escapeHtml(sanitizedHtml);
  },

  setCheckIfPollStillActiveInterval(modalSelector, pollId) {
    const checkIfPollStillActiveInterval = setInterval(() => {
      const modalIsOpen = this.checkIfModalIsShowed(modalSelector);
      if (modalIsOpen) {
        this.checkIfPollStillActive(pollId, {
          success: response => this.checkIfPollStillActiveSuccess(response, modalSelector),
          error: this.checkIfPollStillActiveError
        });
      } else {
        clearInterval(checkIfPollStillActiveInterval);
      }
    }, 15000);
  },

  checkIfPollStillActive(pollId, callBacksFn) {
    const url = Utils.getUrl(true, this.routes.checkPoll);
    const agendaSessionId = Utils.getAgendaSessionId();
    const data = {
      agendaSessionId: agendaSessionId,
      pollId: pollId
    };

    this.makeRequest(url, data, callBacksFn);
  },

  checkIfPollStillActiveSuccess(response, modalSelector) {
    const { data, status } = response;
    if (data && status === 200 && data.html) {
      if (!data.published) {
        this.hideModal(modalSelector);
        this.openModal(this.modalTypes.modalResponse.selector, data.html);
      }
    }
  },

  checkIfPollStillActiveError(error) {
    console.debug('ERR', error);
  },

  getActivePollError(error) {
    this.setCheckOpenedModalsInterval(() =>
      this.getAnswersPoll({
        success: this.getAnswersPollSuccess,
        error: this.getAnswersPollError
      })
    );
    console.debug('ERR', error);
  },

  setCheckOpenedModalsInterval(callback) {
    let checkOpenedModalsInterval = setInterval(() => {
      const hasOpenedModals = this.checkIfModalTypesHasShowedModal();
      if (!hasOpenedModals) {
        this.setTrueGetPollFlag();
        clearInterval(checkOpenedModalsInterval);
        if (typeof callback == 'function') callback();
      }
    }, 1000);
  },

  setAnswerPoll(ev, callbacksFn) {
    const form = document.querySelector('.slick-current .modal-questions-form');
    const url = Utils.getUrl(true, this.routes.setAnswerPoll);
    const agendaSessionId = Utils.getAgendaSessionId();
    let formD = new FormData(form);
    const formSerialized = {};
    // need to convert it before using not with XMLHttpRequest
    for (let [key, val] of formD.entries()) {
      Object.assign(formSerialized, { [key]: val });
    }
    console.log(formSerialized);
    const matchAnswer = this.retrieveMatchAnswer(form);
    const textAnswer = this.retrieveMatchAnswer(form, 'text');

    if (matchAnswer) formSerialized = { ...formSerialized, answer: matchAnswer };
    if (textAnswer) formSerialized = { ...formSerialized, answer: textAnswer };

    const formData = { ...formSerialized, agendaSessionId: agendaSessionId };

    console.debug('~ formData', formData);

    this.makeRequest(url, formData, callbacksFn);
  },

  setAnswerPollSuccess(response) {
    const { data, status } = response;
    const hasSurveyEnded = () => {
      const isSurvey = !!document.querySelector('.is-survey');
      if (!isSurvey) return false;
      try {
        const current = parseInt(document.querySelector('.is-survey').getAttribute('data-page'), 10);
        const total = parseInt(document.querySelector('.is-survey').getAttribute('data-total'), 10);
        return current < total - 1;
      } catch (e) {
        return false;
      }
    };
    const isSurvey = hasSurveyEnded();
    if (data && (status === 200 || status === 201)) {
      if (isSurvey) {
        this.onSurveyPgChange('next');
        document.querySelectorAll(Utils.escapeHtml(`.poll-item-submit-${data.data.id}`)).forEach(element => {
          element.disabled = true;
        });
      } else {
        if (data.nextPollId) {
          this.getActivePoll(
            {
              success: response => this.getActivePollSuccess(response, data.nextPollId),
              error: this.getActivePollError
            },
            data.nextPollId
          );
        } else if (data.nextPrivateUserSession != null) {
          if (!data.nextPrivateUserSession.error) {
            if (data.nextPrivateUserSession.externalUrl != null) {
              var win = window.open(data.nextPrivateUserSession.externalUrl, '_blank');
              win.focus();
            } else {
              window.location.replace(Utils.isValidUrl(data.nextPrivateUserSession.sessionUrl));
            }
          } else {
            console.debug('ERR', data.nextPrivateUserSession.errorMsg);
          }
        } else if (data.nextAgendaSession) {
          window.location.replace(Utils.isValidUrl(data.nextAgendaSession));
          /*
          Això es per mostrar modal al acabar la sessió per anar a la següent, si no va, als 5 minuts redireccionem directament.
          Es deixa comentat per reutilitzar en següents concressos que ho requereixin.
          this.hideModal(this.modalTypes.modalQuestions.selector);
          this.openModal(this.modalTypes.modalResponse.selector, data.html);
          this.goNextSession(data.nextAgendaSession, data.redirectAt, data.redirectAtLimit, data.redirectHtml);
          */
        } else if (data.html) {
          this.hideModal(this.modalTypes.modalQuestions.selector);
          this.openModal(this.modalTypes.modalResponse.selector, data.html);
        }
      }
    }
  },

  goNextSession(nextAgendaSessionUrl, redirectAt, redirectAtLimit, redirectHtml) {
    //console.debug('goNextSession', nextAgendaSessionUrl, redirectAt, redirectHtml);
    setInterval(function() {
      if (Date.parse(redirectAt) < new Date()) {
        this.openModal(this.modalTypes.modalRedirectSession.selector, redirectHtml);
      } else if (Date.parse(redirectAtLimit) < new Date()) {
        window.location.replace(nextAgendaSessionUrl);
      }
      //TODO Es pot fer un elseif amb la condició de si queda menys d'un minut, fer un modal amb un countdown
    }, 5000);
  },

  setAnswerPollError(error) {
    this.hideModal(this.modalTypes.modalQuestions.selector);
    console.debug('ERR', error);
  },

  getAnswersPoll(callbacksFn) {
    if (this.getPollFlag) {
      this.getPollFlag = false;
      const url = Utils.getUrl(true, this.routes.getPollAnswers);
      const agendaSessionId = Utils.getAgendaSessionId();
      const data = {
        agendaSessionId: agendaSessionId,
        shownPollsIds: this.shownAnswersPollsIds
      };
      this.makeRequest(url, data, callbacksFn);
    }
  },

  getAnswersPollSuccess(response) {
    const { data, status } = response;
    if (data && status === 200 && data.html) {
      const id = data.id;
      const self = this;
      this.openModal(this.modalTypes.modalAnswers.selector, data.html);
      Utils.callbackWhenModalIsOpen(self, this.modalTypes.modalAnswers.selector, this.initWordcloud, response);

      this.shownAnswersPollsIds.push(id);
      this.addToSessionStorage('shownAnswersPollsIds', this.shownAnswersPollsIds);
    }
    this.setCheckOpenedModalsInterval(() => this.setTrueIntervalsFlag());
  },

  checkActivePollsAnswers(shownPollsIds, callBacksFn) {
    const url = Utils.getUrl(true, this.routes.checkActivePolls);
    const agendaSessionId = Utils.getAgendaSessionId();
    const data = {
      agendaSessionId: agendaSessionId,
      shownPollsIds: shownPollsIds,
      status: 3
    };
    this.makeRequest(url, data, callBacksFn);
  },

  checkActivePollsAnswersSuccess(response) {
    const { data, status } = response;
    if (data && status === 200) {
      const { activePolls } = data;
      this.addToSessionStorage('shownAnswersPollsIds', activePolls);
      this.shownAnswersPollsIds = activePolls;
    }
  },

  checkActivePollsAnswersError(error) {
    console.debug('ERR', error);
  },

  initWordcloud(self, response) {
    const pollId = response.data.data.poll.id;
    const selector = `${self.wordcloudCanvasEl.selector}-${pollId}`;
    const element = document.querySelector(Utils.escapeHtml(selector));
    if (element && element.innerHTML.length) {
      const data = response.data.data.answers.answers.wordcloud;
      WordCloudController.initWordcloud(data, pollId);
    }
  },

  getAnswersPollError(error) {
    this.setCheckOpenedModalsInterval(() => this.setTrueIntervalsFlag());
    console.debug('ERR', error);
  },

  setTrueIntervalsFlag() {
    this.initIntervalsFlag = true;
  },

  checkIfModalTypesHasShowedModal() {
    for (const modalType in this.modalTypes) {
      const selector = this.modalTypes[modalType].selector;
      const isShowed = this.checkIfModalIsShowed(selector);
      if (isShowed) return true;
    }
    return false;
  },

  checkIfModalIsShowed(modalId) {
    return $(modalId).hasClass('show');
  },

  openModal(modalId, html = '') {
    let modal = $(modalId);
    modal.modal('show');
    modal.find('.spinner-render').hide();
    var modalContentRenderElSelector = document.getElementsByClassName(this.modalContentRenderEl.selector);
    modalContentRenderElSelector.innerHTML(Utils.escapeHtml(html));
    modal.find(this.modalContentRenderEl.selector).show();
  },

  hideModal(modalId) {
    let modal = $(modalId);
    modal.modal('hide');
    modal.find('.spinner-render').show();
    modal.find(this.modalContentRenderEl.selector).hide();
    modal.find(this.modalContentRenderEl.selector).html('');
  },

  addToSessionStorage(key, value) {
    value = JSON.stringify(value);
    sessionStorage.setItem(key, value);
  },

  makeRequest(url, data, callbacksFn) {
    const $req = axios.post(url, data);
    $req.then(response => {
      let sFn = callbacksFn.success;

      if (typeof sFn == 'function') sFn.bind(this)(response);
    });

    $req.catch(response => {
      let eFn = callbacksFn.error;

      if (typeof eFn == 'function') eFn.bind(this)(response);
    });
  },

  initSlickQuestions() {
    let slickOptions = {
      infinite: true,
      slidesToShow: 1,
      arrows: true,
      prevArrow: $('.polls-btn-left'),
      nextArrow: $('.polls-btn-right'),
      draggable: true,
      respondTo: 'window',
      centerMode: false
    };

    $(this.pollsContainer.selector).on('init reInit afterChange', (event, slick, currentSlide, nextSlide) => {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      i = slick.slideCount == 0 ? 0 : i;
      $('.pollsPagination').text(i + '/' + slick.slideCount);
    });
    $(this.pollsContainer.selector).slick(slickOptions);
  },

  retrieveMatchAnswer(form, type = 'image') {
    const selector = `[id^="${type}-answers-"]`;
    const matchAnswers = $(form).find(selector);
    const result = [];
    if (!matchAnswers.length) return;
    matchAnswers.each(function() {
      const answer = $(this).data();
      const { answerid, matchid } = answer;
      if (!matchid) return;
      result.push({ answer_option: answerid, answer_match: matchid });
    });
    return result;
  },

  /*******************
   * WEBSOCKET METHODS
   *******************/
  showPollResults(polls, statusId) {
    $('.congress-live-back-moderator-polls-container').slick('removeSlide', null, null, true);

    Object.keys(polls).forEach(key => {
      $('.congress-live-back-moderator-polls-container').slick('slickAdd', polls[key].html);
    });

    //If published, show modal
    if (statusId == 3) {
      if (!$('.floating-menu-item-results.active').length) {
        let el = $('.floating-menu-item.active');
        if (el.length) {
          $('.floating-menu-item.active').addClass('d-none');
          $('.floating-menu-item.active').removeClass('active');
        }
        $('.floating-menu-item-results').removeClass('hidden-results');
        $('.floating-menu-item-results').addClass('active');
        $('.congress-live-controls-group-buttons-item-results').show();
      }
    }
  },
  closePollsMessage() {
    if ($('.floating-menu-item-results').length) {
      $('.floating-menu-item-results').hide();
    }
  },
  showAudienceMessage(message) {
    this.closeMessageToTheAudience();
    if ($('#message-to-the-audience').length) {
      $('.message-to-the-audience-message').html(
        linkifyHtml(message, {
          target: '_blank'
        })
      );
      $('#message-to-the-audience').show();
    }
  },
  addAudienceMessageToList(id, message, timestamp) {
    if (message.length > 0) {
      let stamp = new Date(timestamp * 1000);
      let data = {
        id: id,
        message: message,
        stamp: stamp,
        hour: stamp.getHours(),
        min: stamp.getMinutes(),
        linkmessage: linkifyHtml(message, {
          target: '_blank'
        })
      };
      // eventCallerService.$emit('appendModeratorPost', data);
      document.dispatchEvent(new CustomEvent("appendModeratorPost", { detail: data }));


    }
  },
  closeMessageToTheAudience() {
    if ($('#message-to-the-audience').length) {
      $('#message-to-the-audience').hide();
    }
  },
  getFinishedSessionInfo() {
    let url = Utils.getUrl(true, 'get-finished-session-info');
    let data = new FormData();
    data.append('agendaSessionId', Utils.getAgendaSessionId());
    axios
      .post(url, data)
      .then(res => {
        if (res.status === 200) {
          // eventCallerService.$emit('showFinishedSession', res.data);
          document.dispatchEvent(new CustomEvent("showFinishedSession", { detail: res.data }));
        }
      })
      .catch(error => {
        console.debug('ERR', error);
      });
  },

  /**
   * Cancels timer and redirection
   */
  cancelSessionRedirect() {
    clearInterval(this.timer.timerInterval);
    this.timer.timeLeft = 0;
    var countdownNumberEl = document.getElementById('next-session-countdown-number');
    countdownNumberEl.textContent = this.timer.timeLeft;
    this.setCircleDasharray();
  },

  /****************
   * TIMER SECTION
   ****************/
  /**
   * When timer has finished, clear interval (avoids negative seconds)
   */
  onTimesUp() {
    clearInterval(this.timer.timerInterval);
    if ($('#next-user-session-btn').length) {
      let url = $('#next-user-session-btn').attr('href');
      window.location.replace(url);
    }
  },

  /**
   * Starts the timer (seconds and circle)
   */
  startTimer() {
    //Initialize countdown circle
    document.getElementById('next-session-countdown').innerHTML = `
        <div class="base-timer">
          <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
              <linearGradient id="countdown-linear" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%"   stop-color="#00bce4"/>
                  <stop offset="100%" stop-color="#005490"/>
              </linearGradient>
              <path
                id="base-timer-path-remaining"
                stroke-dasharray="283"
                stroke="url(#countdown-linear)"
                class="base-timer__path-remaining"
                d="
                  M 50, 50
                  m -45, 0
                  a 45,45 0 1,0 90,0
                  a 45,45 0 1,0 -90,0
                "
              ></path>
            </g>
          </svg>
        </div>
        `;

    //Interval each second
    this.timer.timerInterval = setInterval(() => {
      this.timer.timePassed = this.timer.timePassed += 1;
      this.timer.timeLeft = this.timer.TIME_LIMIT - this.timer.timePassed;
      var countdownNumberEl = document.getElementById('next-session-countdown-number');
      countdownNumberEl.textContent = this.timer.timeLeft;
      this.setCircleDasharray();

      if (this.timer.timeLeft === 0) {
        this.onTimesUp();
      }
    }, 1000);
  },

  /**
   * Formats the time to seconds
   */
  formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  },

  /**
   * Calculate time fraction (for circle)
   */
  calculateTimeFraction() {
    const rawTimeFraction = this.timer.timeLeft / this.timer.TIME_LIMIT;
    return rawTimeFraction - (1 / this.timer.TIME_LIMIT) * (1 - rawTimeFraction);
  },

  /**
   * Draws the circle progress
   */
  setCircleDasharray() {
    const circleDasharray = `${(this.calculateTimeFraction() * this.timer.FULL_DASH_ARRAY).toFixed(0)} 283`;
    document.getElementById('base-timer-path-remaining').setAttribute('stroke-dasharray', circleDasharray);
  }
};

export default LiveWebsocketsAttendee;
