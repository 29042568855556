<template>
  <section class="speakers mode-speakaer custom-congress-speakers-satellital" id="satellital-speakers">
        <SatellitalSpeakerTabs
          v-if="!hideTabs"
          :tabInfo="tabInfo"
          :speakerInfo="speakerInfo"
          :parseImage="parseImage"
          :imageUrlAlt="imageUrlAlt"
          :getSpeakerItemInfo="getSpeakerItemInfo"
          :openModal="openModal"
          :isMobile="isMobile"
          :openTab="openTab"
          :selected_tab_id="selected_tab_id"
        />
        <SatellitalSpeakerCarousel
          v-else
          :tabInfo="tabInfo"
          :speakerInfo="speakerInfo"
          :parseImage="parseImage"
          :imageUrlAlt="imageUrlAlt"
          :getSpeakerItemInfo="getSpeakerItemInfo"
          :openModal="openModal"
          :isMobile="isMobile"
        />
  </section>
</template>
<script>
import V4Carousel from '../../carousel/V4Carousel.vue';
import PageStayController from '../../../page-stay/PageStayController';
import SatellitalSpeakerCarousel from './SatellitalSpeakersCarousel.vue';
import SatellitalSpeakerTabs from './SatellitalSpeakersTabs.vue';
import SpeakerFactory from '../../internal-congress/factories/Speaker.factory';
import SatellitalStorage from '../utils/storage';
import ActivityService from '../../../core/services/activity.service';
import GoogleAnalytics from '../../../GoogleAnalytics.js';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'SatellitalCongressSpeakers',
  components: {
    V4Carousel,
    SatellitalSpeakerCarousel,
    SatellitalSpeakerTabs,
  },
  props: {
    slug: String,
    tabid: Number,
    active: Number,
  },
  data() {
    return {
      tabInfo: [],
      speakerInfo: [],
      selected_tab_id: 0,
      congressId: '',
      hideTabs: false,
      activitySvc: ActivityService.create(),
    };
  },
  mounted() {
    $('.speaker_hidden').hide();
    if (this.tabid == this.active) {
      this.speakers(this.slug, this.tabid);
    }
    this.congressId = SatellitalStorage.getKey(this.slug, 'congress_id');
  },
  methods: {
    openTab(tabId) {
      setTimeout(() => {
        $('.speaker_hidden').hide();
        $('#speaker_common_' + tabId).css({ display: 'inline-block', width: '75vw' });
        $('.v4-carousel-speakers' + tabId).slick('resize');
        $('.v4-carousel-speakers' + tabId).slick('refresh');
      }, 100);
      this.selected_tab_id = tabId;
    },
    async speakers(slug, tabId) {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      await axios.get(`/satellital/grouped-speakers`, {
        params: {
          slug: slug,
          tabId: tabId
        }
      }).then(resp => {
        var speakers = [];
        var tabs = [];
        var hideTabs = false;
        if (Array.isArray(resp.data.data)) {
          // No grouping case
          speakers = { 0: resp.data.data.map(k => SpeakerFactory.get(k)) };
          speakers = Object.values(speakers);
          this.hideTabs = true;
        } else {
          // Grouping case
          $.each(resp.data.data, function (key, value) {
            const results = value.map(k => SpeakerFactory.get(k));
            speakers.push(results);
            tabs.push(key);

            if (key == '') {
              this.hideTabs = true;
            }
          });
        }
        this.speakerInfo = speakers;
        this.tabInfo = tabs;

        if (this.hideTabs) {
          $('.speakers-list').addClass('d-none');
        }
        this.openTab(0);
        document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
      })
        .catch(error => {
          console.error(error);
          document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
        });
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    parseImage(img) {
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
    imageUrlAlt(event) {
      event.target.src = '/images/v4/assets/avatar.svg';
    },
    getSpeakerItemInfo(item) {
      let info = '';
      if (item.position) info = item.position;
      if (item.position && item.institution) info = info + ' - ';
      if (item.institution) info = info + item.institution;
      return info;
    },
    openModal(speaker) {
      document.dispatchEvent(new CustomEvent("showSatellitalSpeakerModal", {
        detail: {
          name: speaker.name,
          surname: speaker.surname,
          institution: speaker.institution,
          bio: speaker.bio,
          photo: this.parseImage(speaker.photo)
        }
      }));
      PageStayController.startStayPageTime('congress-register-speaker-modal', this.congressId ?? '0', false);

      this.activitySvc.saveActivity('open-satellital-speaker-modal', {
          name: speaker.name,
          model_type: 'speaker',
          model_id: speaker.id,
          source: null,
          congress_id: this.congressId
      });
      GoogleAnalytics.sendOpenContentEvent('Speaker ' + speaker.name, 'card');
    }
  }
};
</script>
