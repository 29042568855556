<template>
    <transition name="fade">
        <section class="congress-live-row congress-session-row" v-if="showEndSession">
            <div class="congress-session-block">
                <div class="container">
                    <h4 class="congress-session-title">{{ t('live.end_of_session') }}</h4>
                    <h6 v-if="nextSession" class="congress-session-subtitle">{{ t('live.dont_leave') }}</h6>
                    <h6 v-else class="congress-session-subtitle">{{ t('live.join_thanks') }}</h6>
                    <div class="session-row">
                        <ul class="congress-session-ul" >
                            <li v-for="agenda in sessionList" :key="agenda.id" class="congress-session-list"
                                :class="{'session-ended': agenda.is_finished}" @click="goToSession(agenda)">
                                <div class="list-left-block">
                                    <span class="session-time">{{ agenda.formattedHours }}</span>
                                </div>
                                <div class="list-right-block">
                                    <span class="session-text" v-html="agenda.title"></span>
                                    <span v-if="agenda.is_finished" class="session-ended-text">{{t('live.poll_finished')}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="nextSession">
                <div v-if="showNextSessionModal" class="modal-backdrop notes next-session next-session-modal">
                    <div class="vertical-center-pro">
                        <div class="vertical-center">
                            <div role="dialog" aria-labelledby="videoModal" aria-describedby="modalDescription" id="videoModal" tabindex="-1" aria-hidden="true" class="fade show">
                                <header id="modalTitle" class="modal-header"></header>
                                <button type="button" aria-label="Close modal" class="btn-close" @click="cancelSessionRedirect()">x</button>
                                <section id="modalDescription" class="modal-body next-session-popup p-0">
                                    <div v-if="nextSession && showNextSessionModal" class="next-session-popup">
                                        <div class="next-session-popup__header next-session-blink">
                                            <p>{{ t('live.next_session_message') }}</p>
                                        </div>
                                        <div class="next-session-popup__body">
                                            <div v-for="session in nextSession" :key="session.id"  class="next-session-popup__session" @click="goToSession(session)">
                                                <i class="bi bi-play-circle"></i>
                                                    <div>
                                                        <p>{{ session.title }}</p>
                                                        <span>{{ session.speakersList }}</span>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </transition>
</template>
<script>
// import eventCallerService from '../../core/services/eventCaller.service';
import TranslateService from '../../core/services/translate.service';
import TranslationService from '../../core/services/translation.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import $ from 'jquery';

export default {
    mounted() {
        document.addEventListener('showFinishedSession', event => {
            const data = event.detail;
            this.nextSession = data.nextSessions ?? null;
            this.sessionList = data.sessionListF;
            this.showEndSession = true;
        });
        // eventCallerService.$on('showFinishedSession', (data) => {
        //     this.nextSession = data.nextSessions ?? null;
        //     this.sessionList = data.sessionListF;
        //     this.showEndSession = true;
        // });
    },
    data() {
        return {
            showNextSessionModal: true,
            showEndSession: false,
            nextSession: null,
            sessionList: [],
            translator: TranslateService.create(),
        }
    },
    methods: {
        t(tag) {
            return this.translator.translate(tag);
        },
        cancelSessionRedirect() {
            this.showNextSessionModal = false;
        },
        goToSession(session) {
            GoogleAnalytics.sendMenuNavEvent('Live', 'next session button');
            if (session.cards_behaviour == 'external link') {
                // this.$root.$emit('leavingMMPModal', session.external_link);
                document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: session.external_link }));     
            } else if (session.cards_behaviour == 'open modal') {
                let agendaURL = '';
                if ((session.is_live || session.is_on_demand) && session.is_accessible) {
                    agendaURL = TranslationService.transRoute('event-detail-live', { slug: window.app.event.slug, agendaSessionId: session.id });
                } else {
                    agendaURL = TranslationService.transRoute('event-agenda-detail', { slug: window.app.event.slug, agendaSessionId: session.id });
                }
                window.open(agendaURL).focus();
            }
        }
    }
}
</script>
