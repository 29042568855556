<template>
   <div class="box-common-size">
        <div class="new-voting-padd slider-arrow-hided">
           <div class="left-timer" v-if="expiration_time > 0" :data-poll-id="id"><i class="bi bi-hourglass-split"></i> <span>{{timePoll}}</span></div>
           <div class="live-title"><h4>Live Voting</h4></div>
           <div class="second-title"><h2 class="text-center">{{ question }}</h2></div>
            <form action="" ref="quesForm" @submit="showSubmit" method="post" class="modal-questions-form" :data-poll-id="id" :data-poll-type="type" :data-poll-expiration="expiration_time" :id="'quesform_' + id">
                <input type="hidden" name="pollId" class="questiontextid" ref="answer" :value="id">
                <div id="fillgaps-list">
                    <div class="new-slide">
                        <div class="third-content">
                            <div class="contentdrops text-center" v-html="getquestiontext(filldetails)"></div>
                            <div class="line-next-padd-even">
                                <div class="text-answer">
                                    <form>
                                        <div class="done text-right"><a href="javascript:void(0);">Done</a></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </form>
        </div>
    </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service.js';
import LivePollsController from "../../../v4/modules/live/LivePollsController"
import $ from 'jquery';

export default {
    props: {
        expiration_time:Number,
        question: String,
        id:Number,
        type:Number,
	    filldetails: Object
    },
    mounted() {
        let id = '#quesform_' + this.id;
        $(document).on('submit', id.toString() , ev => this.showSubmit(ev));
        this.timePoll = this.expiration_time;
    },
    watch: {
        timePoll: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timePoll--;
                    }, 1000);
                }else{
                    this.popupQuesType();
                }
            },
        }
    },
    methods: {
        t(tag){
            return this.translator.translate(tag)
        },
        async showSubmit(event){
            event.preventDefault();
            event.stopPropagation();
            var response = await LivePollsController.setAnswerPollFunc(event);
            if ( response.data.status == 201 ) {
                LivePollsController.displayToastifyMessage(2 , "Error" , response.data.message)
            } else if ( response.data.status == 200 ) {
                this.popupQuesType("close");
                document.dispatchEvent(new CustomEvent("submitLiveSingle"));
                // this.$root.$emit('submitLiveSingle', {});
            }
        },
        popupQuesType(){
            this.$parent.$parent.closePopup(this.id);
        },
        getquestiontext(questiondetails){
            let questiontext = questiondetails.fill_text.split(" ");
            let special = ""; let questionhtml = '';let answerName= '';
            questiontext.forEach(function(text, i) {
                let index = text.indexOf("#");
                if(index == 0){ special = text; }else{ answerName = text; }
                if(special){
                    if(questiondetails.options.length > 0){
                        questionhtml += "<select class='select-picker' name='"+questiondetails.id+"' title='choose_answer' data-size='4' required>";
                        questiondetails.options.forEach(function(option, j) {
                            if('#'+option.name == special){
                                questionhtml += "<option value='"+option.id+"'>"+option.option+"</option>";
                            }
                        });
                        questionhtml += "</select>";
                    }
                }else{
                    questionhtml += "<span>"+answerName+" </span>";
                }
                let special = "";let answerName= '';
            });
            return questionhtml;
        }
    },
    data:function(){
        return{
            translator : TranslateService.create(),
            timePoll: 0
        }
    }
}
</script>
