<template>
    <div>
        <div v-if="isLoading">{{t('general.loading')}}...</div>
        <div v-if="!isLoading">
            <div class="pb-5" v-for="(keyword, key, index) in list" v-bind:key="key">
                <p class="text-bold">
                    #{{key}}
                </p>
                <div v-if="!isMobile()">
                    <v4-carousel v-bind="{slidesToShow:3, slidesToScroll:3, name: `trending-content-${index}`, isLoading: false}">
                        <template v-slot:items>
                            <v4-card v-for="ev in keyword" v-bind="ev" :key="ev.id"></v4-card>
                        </template>
                    </v4-carousel>
                </div>
                <div v-else>
                    <v4-carousel v-bind="{slidesToShow:1, slidesToScroll:1, name: `trending-content-${index}`, isLoading: false}">
                        <template v-slot:items>
                            <v4-card v-for="ev in keyword" v-bind="ev" :key="ev.id"></v4-card>
                        </template>
                    </v4-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventFactory from '../events/factories/Event.factory';
import ContentFactory from '../content/factories/Content.factory';
import TranslateService from '../../core/services/translate.service';
import V4Card from '../card/V4Card.vue';
import V4Carousel from '../carousel/V4Carousel.vue';
import axios from 'axios';

export default {
    components: {
        V4Card,
        V4Carousel,
    },
    data() {
        return {
            isLoading: true,
            list: [],
            error: '',
            translator : TranslateService.create()
        }
    },

    mounted() {
        this.get();
    },

    methods: {
        format(ev) {
            return EventFactory.get(ev)
        },

        async get() {
            try {
                this.isLoading = true;
                this.error = '';
                const response = await axios.get('/trending-keywords');
                const list = response.data.data;

                for (const item in list.keywords) {
                    if (Object.hasOwnProperty.call(list.keywords, item)) {
                        let parsedObject = list.keywords[item];
                        parsedObject = parsedObject.map(ev => {
                            if (ev.type == 'multimedia') {
                                return ContentFactory.get(ev);
                            } else {
                                return EventFactory.get(ev);
                            }
                        })
                        list.keywords[item] = parsedObject;
                    }
                }
                this.list = list.keywords;
                this.isLoading = false;
            } catch (error) {
                this.list = [];
                this.error = "something happened";
                this.isLoading = false;
            }
        },

        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        },
        t(tag){
            return this.translator.translate(tag)
        },
    }
}
</script>
