<template>
    <div class="swiper mySwiper virtual-slider">
        <div v-if="isLoading">
            {{ t("congresses.loading") }}
        </div>
        <template v-if="!isLoading && list.length > 0">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in list" v-bind:key="item">
                    <div class="image-cube">
                        <div class="virtual-slider-section">
                            <a href="javascript:void(0)" @click="cardRedirect(item)"><img :src="parseImage(item.image)" @error="showDefaultImage($event)" /></a>
                            <div class="virtual-content">
                            <span class="virtual-tag" :style="'background-color:'+item.card_color+';'"><em :class="getIcon(item.multimedia_type_code)"></em> {{item.card_type}}</span>
                            <span class="virtual-title-text" v-html="item.title"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-arrow-prev bi bi-arrow-left"></div>
            <div class="swiper-arrow-next bi bi-arrow-right"></div>
        </template>
    </div>
</template>
<script>
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import TranslateService from '../../../core/services/translate.service.js';
import axios from 'axios';
import $ from 'jquery';

export default {
    name: 'VirtualBooth',
    props: {
        link: String
    },
    data() {
        return {
            isLoading: true,
            list: [],
            translator : TranslateService.create()
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        async fetch() {
            axios.get(`/congresses/old-virtual-booth?slug=${this.link}`)
            .then(res => {
                this.list = res.data.data;
                if(this.list.length < 1){
                    $('#virtualbooth_menu').css('display','none');
                    $('#virtualbooth').css('display','none');
                    $("a[href='#virtualbooth']").css('display', 'none');
                }
            }).catch(err => {
                console.warn(err);
            }).finally(() => this.loadslider(),this.isLoading = false);
        },
        loadslider() {
            let loop = this.list.length > 2;
            if(this.list.length > 0) {
                var swiper = new Swiper(".mySwiper", {
                    effect: 'coverflow',
                    centeredSlides: true,
                    slidesPerView: 1,
                    loop: loop,
                    speed: 600,
                    grabCursor: true,
                    watchOverflow: true,
                    coverflowEffect: {
                        rotate: 40,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                        slideShadows: false,
                        transformEl:null,
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 2,
                        },
                        990: {
                            slidesPerView: 4,
                        }
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-arrow-next',
                        prevEl: '.swiper-arrow-prev',
                    },
                });
            }
        },
        parseImage(img) {
            if (img && img.substr(0,1) != '/') {
                img = '/' + img;
            }
            return img;
        },
        t(tag){
            return this.translator.translate(tag)
        },
        getIcon(type) {
            type = this.parseTitle(type);
            switch (type) {
                case 'video':
                    return 'bi bi-play-btn'
                case 'Document':
                    return 'bi bi-card-text'
                case 'podcast':
                    return 'bi bi-broadcast-pin'
                default:
                    return 'bi bi-card-text'
            }
        },
        showDefaultImage(event){
            event.target.src = "/images/v4/picture.png"
        },
        parseTitle(tag){
            switch(tag){
                case 'pdf document':
                    return 'Document'
                case 'html':
                    return 'Web'
                case 'video':
                    return 'Video'
                default:
                    return tag;
            }
        },
        cardRedirect(item) {
            //EAHP KJ redirects
            if([1534, 1535, 1536, 1537, 1538].includes(item.id) && item.slug) {
                let url = new URL(item.external_link);
                url.searchParams.set('origin', btoa(item.slug));
                window.open(url, '_blank');
            } else {
                window.open(item.url, '_blank');
            }
        }
    }
}
</script>
