<template>
<section class="agenda">
    <div class="container">
        <div class="agenda-list">
            <ul class="text-center">
                <li v-for="(item, k) in tabInfo" v-bind:key="k" @click="openTab(k)"><a href="javascript:void(0);" :class="{ 'active' : k == selected_tab_id }">{{item}}</a></li>
            </ul>
        </div>

        <div v-for="(item, i) in tabInfo" v-bind:key="i">
            <div v-if="!isLoading">
                <div v-if="!isMobile()">
                    <div class="hidden" :id="'agenda_common_'+i">
                        <V4Carousel :slidesToShow="3" :slidesToScroll="1" :name="'agenda'+i">
                            <template v-slot:items>
                                <div v-for="(itemAgent, j) in agendaInfo[i]" v-bind:key="j">
                                    <V4Card v-bind="itemAgent" :slug="slug"></V4Card>
                                </div>
                            </template>
                        </V4Carousel>
                    </div>
                </div>
                <div v-else>
                    <div class="hidden" :id="'agenda_common_'+i">
                        <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'agenda'+i">
                            <template v-slot:items>
                                <div v-for="(itemAgent, j) in agendaInfo[i]" v-bind:key="j">
                                    <V4Card v-bind="itemAgent" :slug="slug"></V4Card>
                                </div>
                            </template>
                        </V4Carousel>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import AgendaFactory from '../agenda/factories/Agenda.factory';
import axios from 'axios';
import V4Carousel from '../carousel/V4Carousel.vue';
import V4Card from '../card/V4Card.vue';
import $ from 'jquery';

export default {
    name: 'CalendarSection',
    data() {
        return {
            isLoading: true,
            agendaInfo: [],
            tabInfo: [],
            hidden: false,
            first: true,
            translator: TranslateService.create(),
            selected_tab_id : 0
        }
    },
    components: {
        V4Carousel,
        V4Card
    },
    props: {slug:String},
    mounted() {
        this.agenda(this.slug);
    },
    methods: {
        t(tag) {
            return this.translator.translate(tag);
        },
        async agenda(slug) {
            const response = await this.getAgendaData();
            var agenda = [];
            var tabs = [];
            $.each(response.data.data, function (key, value) {
                const results = value.map(k => AgendaFactory.get(k, slug));
                agenda.push(results);
                tabs.push(key);
            });
            this.agendaInfo = agenda;
            this.tabInfo = tabs;
            this.isLoading = false;
            if(this.tabInfo.length == 0)  {
                $('#calendar').css('display', 'none');
                $('#calendar_menu').css('display', 'none');
                $("a[href='#calendar']").css('display', 'none');
            }
            setTimeout(() => {
                this.openTab(0);
            }, 800);
        },
        getAgendaData() {
            return axios.get('/congresses/grouped-agenda-month?slug=' + this.slug + '&type=future');
        },
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        openTab(tabId) {
            $('.hidden').hide();
            $('#agenda_common_' + tabId).css('display', 'block');
            this.selected_tab_id = tabId;
            $('.slick-slider').slick('refresh');
        }

    }
}
</script>
