<template>
  <ModalComponent v-show="showModal" @close="close" id="satellital-speakers-modal">
    <template v-slot:header>&nbsp;</template>
    <template v-slot:body>
      <div v-if="isLoading == true">
        {{ t('congresses.loading') }}
      </div>
      <section class="container">
        <div class="row sort-search mt-5" style="row-gap: 25px;" v-if="!isLoading && speakers.length > 0">
          <div :class="!isMobile() ? 'w-25' : 'w-100'" v-for="(itemInfo, j) in speakers" v-bind:key="j">
            <div class="col-sm-12">
              <div class="speak-user text-center">
                <div class="speaker-img">
                  <img :src="parseImage(itemInfo.photo)" @error="imageUrlAlt" alt="" />
                </div>
                <div class="user-name-speaker">
                  <h4 class="limit-2-rows">{{ itemInfo.name + ' ' + itemInfo.surname }}</h4>
                  <p class="limit-3-rows">{{ getSpeakerItemInfo(itemInfo) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!isLoading" class="container">
          <h3>{{ t('general.no-content-found') }}</h3>
        </div>
      </section>
    </template>
    <template v-slot:footer>&nbsp;</template>
  </ModalComponent>
</template>
  
  
<script>
import ModalComponent from '../../internal-congress/ModalComponent.vue';
import { ref, onMounted } from 'vue';
import TranslateService from '../../../core/services/translate.service';
import GoogleAnalytics from '../../../GoogleAnalytics.js';
import axios from 'axios';

export default {
  name: 'SymposiaSpeakersModal',
  components: {
    ModalComponent,
  },
  setup() {
    const translator = TranslateService.create();
    const t = (tag) => translator.translate(tag);
    const isLoading = ref(true);
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const showModal = ref(false);
    const multimediaId = ref(0);
    const speakers = ref([]);

    const close = () => {
      multimediaId.value = 0;
      showModal.value = false;
      isLoading.value = true;
    };

    const getMultimediaSpeakers = async (multimediaId) => {
      try {
        const response = await axios.get(`/get-multimedia/speakers`, {
          params: {
            id: multimediaId,
          }
        });
        
        speakers.value = response.data.data;
        isLoading.value = false;

      } catch (error) {
        console.error(error);
      }
    }

    const parseImage = (img) => {
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    }

    const getSpeakerItemInfo = (item) => {
      let info = '';
      if(item.position) info = item.position;
      if(item.position && item.institution) info = info + ' - ';
      if(item.institution) info = info + item.institution;
      return info;
    }

    onMounted(() => {
      document.addEventListener("showSymposiaSpeakersModal", (event) => {
        multimediaId.value = event.detail.multimediaId;
        getMultimediaSpeakers(multimediaId.value);
        showModal.value = true;
        GoogleAnalytics.sendOpenContentEvent(event.detail, 'modal');
      });
    });
    
    return {
      t,
      isMobile,
      ModalComponent,
      showModal,
      close,
      isLoading,
      parseImage,
      getSpeakerItemInfo,
      speakers,
      multimediaId,
    };
  },
};
</script>