<template>
  <div @click="saveActivity()" class="connectmi-event__content-card__wrapper">
    <a :href="'/en/events/connectmi2022/content?categoryId=' + this.content.id">
      <div class="connectmi-event__content-card">
        <p class="text text--nm text--bold">{{ this.content.name }}</p>
      </div>
    </a>
  </div>
</template>

<script>
import ModalSpeaker from '../modals/ModalSpeaker.vue';
import ActivityService from '../../../core/services/activity.service';
import moment from 'moment';

export default {
  props: {
    imagePath: String,
    content: Object
  },

  components: {
    ModalSpeaker
  },

  data() {
    return {
      activitySvc: ActivityService.create(),
    };
  },

  mounted() {
  },

  methods: {
    createImagePath(image) {
      return this.imagePath + image;
    },

    truncate(input) {
      if (this.variant === 'committee') {
        return input.length > 100 ? input.substring(0, 100).trim() + '...' : input;
      } else {
        return input.length > 60 ? input.substring(0, 60).trim() + '...' : input;
      }
    },
    formatDateStart(date) {
        return moment(date).format("DD.MM.YYYY");
    },
    saveActivity() {
      let params = {
          title: this.content.name,
          model_type: 'multimedia-category',
          model_id: this.content.id,
          source: null,
          congress_id: this.content.congress_id
      }
      this.activitySvc.saveActivity('enter-multimedia-category', params);
    }
  }
};
</script>
