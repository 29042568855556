<template>
    <div>
        <div v-if="isLoading">{{ t("congress.loading_text")}}</div>
        <div v-if="!isLoading && list.length > 0">
            <h4 class="v4-subtitle-live m-0">{{ t("congress.media_content") }}</h4>
            <div v-if="!isMobile()">
                <!-- DESKTOP VERSION -->
                <V4Carousel :slidesToShow="2" :slidesToScroll="2" :name="'agendamedlisting'" :bus="bus">
                    <template v-slot:items>
                        <div v-for="(item, i) in list" v-bind:key="i">
                            <agenda-media-content-item v-bind="item" />
                        </div>
                    </template>
                </V4Carousel>
            </div>
            <div v-else>
                <!-- MOBILE VERSION -->
                <V4Carousel  :slidesToShow="1" :slidesToScroll="1" :name="'agendamedlisting'" :bus="bus">
                    <template v-slot:items>
                        <div v-for="(item, i) in list" v-bind:key="i">
                            <agenda-media-content-item v-bind="item" />
                        </div>
                    </template>
                </V4Carousel>
            </div>
        </div>
    </div>
</template>

<script>
import AgendaMediaContentItem from '../agenda/AgendaMediaContentItem.vue';
import TranslateService from '../../core/services/translate.service.js';
import V4Carousel from '../carousel/V4Carousel.vue';
import axios from 'axios';

    export default {
        props: {
            agendasessionid:Number
        },
        components: {
            AgendaMediaContentItem,
            V4Carousel
        },
        created:function(){
           axios
                .get(`/agenda-session/multimedia`, {params:{
                    id: this.agendasessionid,
                    type : "media",
                }})
                .then(res => {
                    var result = JSON.parse(JSON.stringify(res));
                    this.list = result.data.data;
                    if(result.data.data && result.data.data.length == 0) {
                        document.dispatchEvent(new CustomEvent("showAgendaImage"));
                        // this.$root.$emit('showAgendaImage');
                    }
                    this.isLoading = false;
                })
                .catch(err => {
                    console.warn(err)
                });
        },
        methods: {
            showDefaultImage(event){
                const loc = document.location;
                event.target.src = `${loc.protocol}//${loc.host}/`+"images/v4/picture.png"
            },
            isMobile() {
                return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
            },
            t(tag){
                return this.translator.translate(tag)
            }
        },
        data:function(){
            return{
                list : Array,
                /* bus: new Vue(), */
                isLoading: true,
                translator : TranslateService.create()
            }
        }
    }
</script>
