<template>
  <div class="clinical-cases__carousel__helper custom-congress-carousel">
    <div v-if="!isMobile()">
      <v4-carousel
        :slidesToShow="3"
        :slidesToScroll="3"
        :rowsToShow="this.setNumberOfRows()"
        :name="'clinical-cases-carousel'"
        :isLoading="isLoading"
        v-if="!isLoading"
      >
        <template v-slot:items>
          <ClinicalCasesItem v-for="ev in list" v-bind="ev" :key="ev.id"></ClinicalCasesItem>
        </template>
      </v4-carousel>
    </div>
    <div v-else>
      <v4-carousel
        :slidesToShow="1"
        :slidesToScroll="1"
        :rowsToShow="this.setNumberOfRows()"
        :name="'clinical-cases-carousel'"
        :isLoading="isLoading"
        v-if="!isLoading"
      >
        <template v-slot:items>
          <ClinicalCasesItem v-for="ev in list" v-bind="ev" :key="ev.id"></ClinicalCasesItem>
        </template>
      </v4-carousel>
    </div>
  </div>
</template>

<script>
import ClinicalCasesRepository from "./repositories/clinical-cases.repository";
import ClinicalCasesFactory from "./factories/clinical-cases.factory";
import ClinicalCasesItem from "./ClinicalCasesItem.vue";
import V4Carousel from "../carousel/V4Carousel.vue";
import $ from 'jquery';

export default {
  props: {
    rows: Number,
    sortable: Boolean,
    fromrelatedid: Number,
    slug: String,
    onlyWithProgress: Boolean,
  },
  data() {
    return {
      isLoading: true,
      list: [],
    };
  },
  mounted() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const data = await ClinicalCasesRepository.getClinicalCases(
          this.fromrelatedid,
          this.slug,
          this.onlyWithProgress
        ).then((response) => {
          let results = response.results;

          results = results.map((ev) => {
            return ClinicalCasesFactory.get(ev);
          });

          this.list = results;
          this.isLoading = false;

          if (!this.list.length) {
            $("#clinical-cases-section, #my_clinical_cases").css("display", "none");
            $("#related-cc").css("display", "none");
            $("#clinicalcases").css("display", "none");
            $("#clinicalcases_menu").css("display", "none");
            $("#clinicalcase_menu").css("display", "none");
          } else {
            $(".my-clinical-cases-count").text(`(${this.list.length})`);
          }
        });
      } catch (error) {
        console.log(error);
        this.isLoading = true;
        this.list = [];
      }
    },

    setNumberOfRows() {
      if (this.slug) {
        return 1;
      }
      if (!this.rows) {
        if (this.list.length) {
          if (this.list.length > 3 && this.list.length <= 6) {
            return 2;
          } else if (this.list.length > 6) {
            return 3;
          } else {
            return 1;
          }
        }
      }
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  components: {
    ClinicalCasesItem,
    V4Carousel
  },
};
</script>
