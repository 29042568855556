<template>
<div class="row">
  <div class="col">
    <confirm-dialogue ref="kjResultModal"></confirm-dialogue>
    <template v-if="currentDisplay">
      <KnowledgeJourneyProgress class="clinical-cases__tabsheet__progress" 
        :progress="currentProgress" 
        :diploma="showDiploma"
        :kjId="kj.id"
        :kjTitle="kj.title">
      </KnowledgeJourneyProgress>
    </template>
    <ul class="clinical-cases__tabsheet__supporting">
      <div v-for="(document, i) in supportingDocuments" v-bind:key="i">
        <li>
          <i :class="getIcon(document)"></i>
          <div class="col">
            <a @click="getMultimediaURL(document)">{{document.title}}</a>
          </div>
        </li>
      </div>
    </ul>
  </div>
</div>
</template>

<script>

import KnowledgeJourneyProgress from './KnowledgeJourneyProgress.vue';

export default {
  name: 'KnowledgeJourneySupporting',
  components: {
    KnowledgeJourneyProgress
  },
  props: {
    kj: Object,
    si: Array,
    progress: Number,
    display: Boolean,
    diploma: Boolean
  },

  data() {
    return {
      currentDisplay: this.display,
      currentProgress: this.progress,
      supportingDocuments: [],
      showDiploma: this.diploma ?? false,
    }
  },

  mounted() {
    this.parseDocuments();
    document.addEventListener("updateKJPercentage", (event) => {
        if(event.detail > this.progress) {
            this.currentProgress = event.detail;
        }
      });
    /* this.$root.$on('updateKJPercentage', (percentage) => {
      if(percentage > this.progress) {
        this.currentProgress = percentage;
      }
    }); */
  },

  methods: {
    parseDocuments() {
      this.supportingDocuments = this.si;
    },
    getIcon(document) {
      if(document.external_redirect) {
        return 'bi bi-link';
      } else if (document.multimedia_type_id == 5 || document.multimedia_type_id == 4 || document.multimedia_type_id == 12) {
        return 'bi bi-file-play';
      } else {
        return 'bi bi-file-earmark';
      }
    },
    getMultimediaURL(documentMultimedia) {
      if(window.app.event && window.app.event.slug == 'imlygicknowledge') {
        this.imlygicBehaviour(documentMultimedia);
      } else {
        if(documentMultimedia.external_redirect) {
          document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: documentMultimedia.url }));
          // this.$root.$emit('leavingMMPModal', document.url);
        } else {
          window.open(documentMultimedia.url, '_blank');
        }
      }
    },
    imlygicBehaviour(multimedia) {
      if(multimedia.external_redirect) {
        document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: multimedia.url }));
        // this.$root.$emit('leavingMMPModal', multimedia.url);
      } else {
        if(multimedia.content_format == 'DOCUMENT' && multimedia.file) {
          const link = document.createElement('a');
          link.href = this.parseLink(multimedia.file);
          link.setAttribute('download', `${multimedia.title}.pdf`);
          document.body.appendChild(link);
          link.click();
        } else {
          window.open(multimedia.url, '_blank');
        }
      }
    },
    parseLink(link) {
      if (link && link.substr(0,1) != '/') {
        link = '/' + link;
      }
      return link;
    },
  },
}
</script>
