window.dataLayer = window.dataLayer || [];

const GoogleAnalytics = {
  reportEvent: function (data) {
    console.log('reportEvent', data);
    if (data.length === 0 || data === undefined) {
      console.error('The data parameter is empty.');
      return;
    }

    for (let key in data) {
      if (key !== 'event' && data[key] === '') {
        console.error(`The ${key} property is an empty string.`);
        return;
      }
    }

    const regex = /^\{.*\}$/;
    if (regex.test(data)) {
      console.error('The data parameter does not have a valid format.');
      return;
    }

    if (window.dataLayer) {
      console.log('datalayer', data);
      dataLayer.push(data);
    } else {
      console.error('dataLayer is not defined.');
    }
  },

  // Events
  sendMenuNavEvent: function (menuName, menuType) {
    if (menuName !== null && menuName !== '' && menuType !== null && menuType !== '') {
      const data = {
        'event': 'e_menuNav',
        'menuName': menuName,
        'menuType': menuType,
      };
      console.log('e_menuNav', data);
      this.reportEvent(data);
    }
  },
  sendPromoClickEvent: function (buttonText) {
    if (buttonText !== null && buttonText !== '') {
      const data = {
        'event': 'e_promoClick',
        'clickText': buttonText
      };
      console.log('e_promoClick', data);
      this.reportEvent(data);
    }
  },
  sendOutboundClickEvent: function (linkText, linkHref, urlDomain) {
    if (linkText !== null && linkText !== '' && linkHref !== null && linkHref !== '' && urlDomain !== null && urlDomain !== '') {
      const data = {
        'event': 'e_outboundClick',
        'clickText': linkText,
        'clickURL': linkHref,
        'clickDomain': urlDomain,
      };
      console.log('e_outboundClick', data);
      this.reportEvent(data);
    }
  },
  sendProfileEditEvent: function (fieldEdited) {
    if (fieldEdited !== null && fieldEdited !== '') {
      const data = {
        'event': 'e_usereditProfile',
        'fieldEdited': fieldEdited,
      };
      console.log('e_usereditProfile', data);
      this.reportEvent(data);
    }
  },
  sendSubscriptionCount: function (subNumber) {
    if (subNumber !== null && subNumber !== '') {
      const data = {
        'event': 'e_userSubscription',
        'numberOfSubscriptions': subNumber,
      };
      console.log('e_userSubscription', data);
      this.reportEvent(data);
    }
  },
  sendBookmarkCount: function (bookNumber) {
    if (bookNumber !== null && bookNumber !== '') {
      const data = {
        'event': 'e_userBookmarks',
        'numberOfBookmarks': bookNumber,
      };
      console.log('e_userBookmarks', data);
      this.reportEvent(data);
    }
  },
  sendErrorEvent: function (errorCode) {
    if (errorCode !== null && errorCode !== '') {
      const data = {
        'event': 'e_httpError',
        'httpErrorCode': errorCode,
      };
      console.log('e_httpError', data);
      this.reportEvent(data);
    }
  },
  sendOpenContentEvent: function (contentName, componentType) {
    if (contentName !== null && contentName !== '' && componentType !== null && componentType !== '') {
      const data = {
        'event': 'e_contentOpen',
        'contentName': contentName,
        'componentType': componentType
      };
      console.log('e_contentOpen', data);
      this.reportEvent(data);
    }
  },
  sendCloseContentEvent: function (contentName, componentType) {
    if (contentName !== null && contentName !== '' && componentType !== null && componentType !== '') {
      const data = {
        'event': 'e_contentClose',
        'contentName': contentName,
        'componentType': componentType
      };
      console.log('e_contentClose', data);
      this.reportEvent(data);
    }
  },
  sendExpandContentEvent: function (contentName) {
    if (contentName !== null && contentName !== '') {
      const data = {
        'event': 'e_contentExpand',
        'contentName': contentName,
      };
      console.log('e_contentExpand', data);
      this.reportEvent(data);
    }
  },
  sendCollapseContentEvent: function (contentName) {
    if (contentName !== null && contentName !== '') {
      const data = {
        'event': 'e_contentCollapse',
        'contentName': contentName,
      };
      console.log('e_contentCollapse', data);
      this.reportEvent(data);
    }
  },
  sendSaveContentEvent: function (contentName, componentType, contentCategory = '') {
    if (contentName !== null && contentName !== '' && componentType !== null && componentType !== '') {
      const data = {
        'event': 'e_save',
        'contentName': contentName,
        'componentType': componentType,
      };
      if (contentCategory !== null && contentCategory !== '') {
        data['contentCategory'] = contentCategory;
      }
      console.log('e_save', data);
      this.reportEvent(data);
    }
  },
  sendUnsaveContentEvent: function (contentName, componentType, contentCategory = '') {
    if (contentName !== null && contentName !== '' && componentType !== null && componentType !== '') {
      const data = {
        'event': 'e_unsave',
        'contentName': contentName,
        'componentType': componentType,
      };
      if (contentCategory !== null && contentCategory !== '') {
        data['contentCategory'] = contentCategory;
      }
      console.log('e_unsave', data);
      this.reportEvent(data);
    }
  },
  sendDownloadContentEvent: function (clickText, clickUrl) {
    if (clickText !== null && clickText !== '' && clickUrl !== null && clickUrl !== '') {
      const data = {
        'event': 'e_downloadClick',
        'clickText': clickText,
        'clickURL': clickUrl
      };
      console.log('e_downloadClick', data);
      this.reportEvent(data);
    }
  },
  sendShareContentEvent: function (contentName, shareType, contentCategory = '') {
    if (contentName !== null && contentName !== '' && shareType !== null && shareType !== '') {
      const data = {
        'event': 'e_share',
        'contentName': contentName,
        'shareType': shareType,
      };
      if (contentCategory !== null && contentCategory !== '') {
        data['contentCategory'] = contentCategory;
      }
      console.log('e_share', data);
      this.reportEvent(data);
    }
  },
  sendContentReactionEvent: function (contentName, userReaction, contentCategory = '') {
    if (contentName !== null && contentName !== '' && userReaction !== null && userReaction !== '') {
      const data = {
        'event': 'e_reaction',
        'contentName': contentName,
        'userReaction': userReaction,
      };
      if (contentCategory !== null && contentCategory !== '') {
        data['contentCategory'] = contentCategory;
      }
      console.log('e_reaction', data);
      this.reportEvent(data);
    }
  },
  sendFilterEvent: function (filterName, filterCategory, filterValue, clickAction = '') {
    if (filterName !== null && filterName !== '' && filterCategory !== null && filterCategory !== '' && filterValue !== null && filterValue !== '') {
      const data = {
        'event': 'e_filter',
        'filterName': filterName,
        'filterCategory': filterCategory,
        'filterValue': filterValue,
      };
      if (clickAction !== null && clickAction !== '') {
        data['clickAction'] = clickAction;
      }
      console.log('e_filter', data);
      this.reportEvent(data);
    }
  },
  sendContentClickEvent: function (clickAction, contentName, componentType, contentCategory, contentPosition = '') {
    if (clickAction !== null && clickAction !== '' && contentName !== null
      && contentName !== '' && componentType !== null && componentType !== '' && contentCategory !== null && contentCategory !== '') {
      const data = {
        'event': 'e_contentClick',
        'clickAction': clickAction,
        'contentName': contentName,
        'componentType': componentType,
        'contentCategory': contentCategory,
      };
      if (contentPosition !== null && contentPosition !== '') {
        data['contentPosition'] = contentPosition;
      }
      console.log('e_contentClick', data);
      this.reportEvent(data);
    }
  },
  sendStartQuizEvent: function (quizName) {
    if (quizName !== null && quizName !== '') {
      const data = {
        'event': 'e_quizStart',
        'quizName': quizName,
      };
      console.log('e_quizStart', data);
      this.reportEvent(data);
    }
  },
  sendSubmitAnswerEvent: function (quizQuestionNumber, quizName, quizQuestion, quizUserAnswer, quizResult) {
    if (quizQuestionNumber !== null && quizQuestionNumber !== '' && quizName !== null && quizName !== ''
      && quizQuestion !== null && quizQuestion !== '' && quizUserAnswer !== null && quizUserAnswer !== ''
      && quizResult !== null && quizResult !== '') {
      const data = {
        'event': 'e_quizanwerSubmit',
        'quizQuestionNumber': quizQuestionNumber,
        'quizName': quizName,
        'quizQuestion': quizQuestion,
        'quizUserAnswer': quizUserAnswer,
        'quizResult': quizResult,
      };
      console.log('e_quizanwerSubmit', data);
      this.reportEvent(data);
    }
  },
  sendQuizStepEvent: function (stepName, quizName) {
    if (stepName !== null && stepName !== '' && quizName !== null && quizName !== '') {
      const data = {
        'event': 'e_quizStepComplete',
        'stepName': stepName,
        'quizName': quizName,
      };
      console.log('e_quizStepComplete', data);
      this.reportEvent(data);
    }
  },
  sendQuizAbandonEvent: function (stepName, quizName) {
    if (stepName !== null && stepName !== '' && quizName !== null && quizName !== '') {
      const data = {
        'event': 'e_quizAbandon',
        'stepName': stepName,
        'quizName': quizName,
      };
      console.log('e_quizAbandon', data);
      this.reportEvent(data);
    }
  },
  sendQuizCompleteEvent: function (quizName) {
    if (quizName !== null && quizName !== '') {
      const data = {
        'event': 'e_quizComplete',
        'quizName': quizName,
      };
      console.log('e_quizComplete', data);
      this.reportEvent(data);
    }
  },
  sendSearchEvent: function (searchTerm, searchResults, searchCategory = '') {
    if (searchTerm !== null && searchTerm !== '' && searchResults !== null && searchResults !== '') {
      const data = {
        'event': 'e_searchResults',
        'searchTerm': searchTerm,
        'searchResults': searchResults,
      };
      if (searchCategory !== null && searchCategory !== '') {
        data['searchCategory'] = searchCategory;
      }
      console.log('e_searchResults', data);
      this.reportEvent(data);
    }
  },
  sendSearchResultsEvent: function (clickText, searchTerm = '', searchResultsPosition = '', searchCategory = '') {
    if (clickText !== null && clickText !== '') {
      const data = {
        'event': 'e_searchResultsClick',
        'clickText': clickText,
      };
      if (searchTerm !== null && searchTerm !== '') {
        data['searchTerm'] = searchTerm;
      }
      if (searchResultsPosition !== null && searchResultsPosition !== '') {
        data['searchResultsPosition'] = searchResultsPosition;
      }
      if (searchCategory !== null && searchCategory !== '') {
        data['searchCategory'] = searchCategory;
      }
      console.log('e_searchResultsClick', data);
      this.reportEvent(data);
    }
  },
  sendChatbotEvent: function (clickAction) {
    if (clickAction !== null && clickAction !== '') {
      const data = {
        'event': 'e_chatbot',
        'clickAction': clickAction,
      };
      console.log('e_chatbot', data);
      this.reportEvent(data);
    }
  },
  // sendChatbotClickEvent: function (contentName) {
    // if (contentName !== null && contentName !== '') {
      // const data = {
        // 'event': 'e_chatbotClick',
        // 'contentName': contentName,
      // };
      // this.reportEvent(data);
    // }
  // },
  sendRegisterEvent: function () {
    const data = {
      'event': 'e_userRegister',
    };
    console.log('e_userRegister', data);
    this.reportEvent(data);
  },
  sendForgotPasswordEvent: function () {
    const data = {
      'event': 'e_forgotPassword',
    };
    console.log('e_forgotPassword', data);
    this.reportEvent(data);
  },
  sendLoginEvent: function () {
    const data = {
      'event': 'e_userLogin',
    };
    console.log('e_userLogin', data);
    this.reportEvent(data);
  },
  sendLogoutEvent: function (userId = '') {
    const data = {
      'event': 'e_userLogout',
    };
    if (userId !== null && userId !== '') {
      data['userId'] = userId;
    }
    console.log('e_userLogout', data);
    this.reportEvent(data);
  },
};

export default GoogleAnalytics;
