export default {
    get(content) {
         return {
            id: content?.id ?? '',
            name: content?.name ?? '',
            surname: content?.surname ?? '',
            photo: content?.photo ?? '',
            position: content?.position ?? '',
            bio: content?.bio ?? false,
            short_bio: content?.short_bio ?? '',
            institution: content?.institution ?? ''
        };
    },
    
};