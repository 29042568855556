import EventFactory from '../../../modules/events/factories/Event.factory';

export default {
    getKeywords(theContent) {
      if(!!theContent && Array.isArray(theContent.keywords)) {
        return theContent.keywords;
      }
      return [];
    },
    get(theContent) {
        const content = JSON.parse(JSON.stringify(theContent));
        const formatted_dates = EventFactory.formatDates(content);
        return {
            id: content?.id ?? '',
            title: content?.title ?? '',
            description: content?.description ?? '',
            slug: content?.slug ?? '',
            congress_id: content?.congress_id ?? '',
            hasLiveSessions: content?.hasLiveSessions ?? false,
            image: content?.image ?? '',
            link: '/'+window.app.env.locale+'/multimedia/'+content?.id ?? '',
            date_start: content?.date_start ?? '',
            date_end: content?.date_end,
            ...formatted_dates,
            keywords: this.getKeywords(theContent),
            shortened_keywords: content?.shortened_keywords ? content?.shortened_keywords.splice(0, 5) : [],
            is_new: content?.isNew ?? false,
            is_live: false,
            is_on_demand: false,
            is_multimedia: content.is_multimedia ?? content.type == 'multimedia',
            multimedia_type: this.parseTitle(content?.multimedia_type) ?? "",
            multimedia_type_str: this.parseTitle(content?.multimedia_type) ?? "",
            multimedia_category_id: content?.multimedia_category_id ?? 0,
            type: 'multimedia',
            saved: content?.saved ?? false,
            liked: content?.liked ?? false,
            dataTracks: content?.dataTracks ?? [],
            disliked: content?.disliked ?? false,
            open_preregister: content?.open_preregister ?? false,
            card_color: content?.card_color ?? '',
            card_type: content?.card_type ?? '',
            card_icon: content?.card_icon ?? '',
            external_redirect: content?.external_redirect ?? false,
            external_url : content?.url ?? '',
            availableTags: content?.availableTags ?? [],
            missingTags: content?.missingTags ?? []
        };
    },


    getLink(content) {
        return {
            link: '/'+window.app.env.locale+'/multimedia/'+content?.id ?? '',
        };
    },

    parseTitle(tag){

        switch(tag){
            case 'pdf document':
                return 'document'
            case 'html':
                return 'web'
            default:
                return tag;
        }
    }
};
