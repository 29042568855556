window.dataLayer = window.dataLayer || [];

function getDataLayer() {
    var current_url = window.location.href;
    fetch('/google-analytics-data?url=' + current_url)
        .then(response => response.json())
        .then(data => {
            window.dataLayer.push(data);
        })
        .catch(error => {
            console.error('Error fetching Google Analytics data:', error);
        });
}

getDataLayer();

let googleAnalyticsContainerId = window.app.env.GOOGLE_ANALYTICS_CONTAINER_ID;

//Google Tag Manager
(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', googleAnalyticsContainerId);