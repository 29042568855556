<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" v-bind:class="type">
      <div class="vertical-center-pro">
        <div class="vertical-center">
          <div class="fade show" role="dialog" aria-labelledby="videoModal" aria-describedby="modalDescription"
            id="videoModal" tabindex="-1" aria-hidden="true">
            <header class="modal-header" id="modalTitle">
            </header>
            <button type="button" class="btn-close" @click="close" aria-label="Close modal">
              x
            </button>

            <section class="modal-body" id="modalDescription">
              <slot name="body">
                This is the default body!
              </slot>
            </section>

            <footer class="modal-footer">
              <slot name="footer">
                This is the default footer!
              </slot>
              <button type="button" class="btn-green" @click="close" aria-label="Close modal">
                Close me!
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    type: String,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  }

};
</script>
