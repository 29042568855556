/**
 * This is the controller for the ChatBot IFRAME.
 *
 * Always gonna need the next information:
 * - Location: must be added on the include blade
 *
 * - Triggers:
 *    1. click: onClick,
 *    2. time: when acc a certain amount of time
 *    3. carousel: when scrolling or passing around many cards
 */

import $ from 'jquery';

const ChatbotController = {
  _triggerList: [],
  _trigger: 'click',
  _scrolls: 0,
  _opened: 0,

  chatbotElement: {
    selector: '#chatbotElement',
    container: '.chatbot-container',
    openbutton: '#chatbot-handler',
    iframe: '#chatbotIframe',
    closebutton: '.chatbot-container-close',
  },

  url: {
    chatbot: window.app.env.chatbot ||'https://mmp-chatbot.basetis.com',
  },

  init: function() {
    this.setListeners();
    this.hidenChatbot();
  },

  setListeners: function() {
    this.addListener();
  },

  hidenChatbot: function() {
    $(this.chatbotElement.iframe).attr('src', this.completeURL());
    $(this.chatbotElement.container).hide();
  },

  addListener: function() {
    $(this.chatbotElement.openbutton).click(ev => this.openChatbot(ev));
    $(this.chatbotElement.closebutton).click(ev => this.closeChatbot(ev));
    // $(document).scroll(() => this.handlerScrollTrigger());
    // setInterval(() => {
    //   if (!this.isOpened() && !this.isAlreadyTriggered('time')) this.triggerOpening('time')
    // }, 60000);
  },

  handlerScrollTrigger () {
    this._scrolls++;
    if (this._scrolls > 350 && !this.isOpened() && !this.isAlreadyTriggered('carousel')) {
        this.triggerOpening('carousel')
    };
  },

  openChatbot: function(event) {
    this._opened = 1;
    this.setTrigger('click');
    // $(this.chatbotElement.iframe).attr('src', this.completeURL());
    $(this.chatbotElement.container).show();
    $(this.chatbotElement.openbutton).hide();
  },

  closeChatbot: function(event) {
    this._opened = 0;
    this._scrolls = 0;
    $(this.chatbotElement.container).hide();
    $(this.chatbotElement.openbutton).show();
  },

  triggerOpening (trigger) {
    this._opened = 1;
    this.setTrigger(trigger);
    // $(this.chatbotElement.iframe).attr('src', this.completeURL());
    $(this.chatbotElement.container).show();
    $(this.chatbotElement.openbutton).hide();
  },

  completeURL () {
    let fullroute = this.url.chatbot;

    const params = {
      user_id: this.getUserId(),
      location: this.getLocation(),
      trigger: this.getTrigger() || 'click',
    }

    Object.keys(params).forEach((p, i) => {
      (i === 0) ? fullroute = `${fullroute}?${p}=${params[p]}` : fullroute = `${fullroute}&${p}=${params[p]}` ;
    });

    return fullroute;
  },

  getUserId () {
    const user = window.user || null;
    if (user) return user.uid;
    else return 'null';
  },

  getLocation () {
    const location = $(this.chatbotElement.iframe).attr('data-location');
    return location;
  },

  getTrigger () { return this._trigger; },

  setTrigger(trigger) {
    this._trigger = trigger;
    this._triggerList.push(trigger);
  },

  isAlreadyTriggered (trigger) {
    return this._triggerList.includes(trigger) && trigger !== 'click';
  },

  isOpened () {
    return this._opened === 1
  },
};

export default ChatbotController;
