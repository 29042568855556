<template>
  <div :class="classToRender">
    <p>{{ this.day }}</p>
    <p>{{ this.desc }}</p>
  </div>
</template>

<script>
export default {
  props: {
    tabNumber: Number,
    day: String,
    desc: String,
    selected: Number
  },

  data() {
    return {
      classToRender: 'connectmi-event__agenda__day'
    };
  },

  mounted() {
    if(this.tabNumber === this.selected){
      this.classToRender = 'connectmi-event__agenda__selected';
    }
  },

  methods: {}
};
</script>
