<template>
  <select
    ref="select"
    placeholder="language"
    class="language-select"
    v-model="selectedLanguage"
    v-bind:class="{'is-small': isSmall, 'is-invalid': isInvalid}"
    v-bind:data-live-search-placeholder="t('profile.language')"
    v-bind:data-live-search="enableSearch ? 'true' : null"
    v-on:change="optionSelected">
    <option
      v-for="(option, index) of options"
      v-bind:key="index"
      v-bind:value="option.id"
      v-bind:selected="selected === option.code">{{ option.name }}</option>
  </select>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import $ from 'jquery'
import 'bootstrap-select'
import { useLocaleStore } from '../../../v4/store/locale.store';

const translator = TranslateService.create()

export default {
  emits: ['input'],
  setup () {
    const store = useLocaleStore();
    return { store }
  },
  props: {
    languages: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number]
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    enableSearch: {
      type: Boolean,
      default: true
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: this.languages,
      selected: null,
      selectedLanguage: this.value
    }
  },
  mounted () {
    $(this.$refs.select).selectpicker()

    if (this.value != null) {
      this.selected = this.findOptionByLocaleCode(parseInt(this.value))
    }
  },
  updated () {
    $(this.$refs.select).selectpicker('refresh')
  },
  methods: {
    t(tag) {
      return translator.translate(tag)
    },
    createOthersOption() {
      const othersOption = {
        code: 'other',
        id: 0,
        name: 'Other'
      }

      const options = this.options.filter(option => option.code !== 'other')
      options.push(othersOption)
      this.options = options

      return othersOption
    },
    removeOthersOption() {
      this.options = this.options.filter(option => option.code !== 'other')
    },
    findOptionByLocaleId(id) {
      const option = this.options
        .filter(option => option.id == id)
        .shift()
        ?.code

      if (option) {
        this.removeOthersOption()
        return option
      }

      return this.createOthersOption().code
    },
    findOptionByLocaleCode(languageCode) {
      const option = this.options
        .filter(option => option.code == languageCode)
        .shift()
        ?.code

      if (option) {
        this.removeOthersOption()
        return option
      }

      return this.createOthersOption().code
    },
    optionSelected(event) {
      this.selected = this.findOptionByLocaleId(event.target.value)

      if (this.selected != null) {
        this.store.saveUserLocale(this.selected)
        this.$emit('input', this.selected)
      }
    }
  },
  watch: {
    value(newValue) {
      this.selectedLanguage = newValue;
    },
    'store.userLocales' (newValue) {
      if (this.languages.length === 0) {
        this.options = newValue

        if (this.selected == null && this.store.currentUserLocale != null) {
          this.selected = this.findOptionByLocaleCode(this.store.currentUserLocale)
        }
      }
    },
    'store.currentUserLocale': {
      handler (newValue) {
        if (this.options.length > 0) {
          this.selected = this.findOptionByLocaleCode(newValue)
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
  .dropdown.bootstrap-select.language-select.is-small {
    width: 200px !important
  }
</style>
