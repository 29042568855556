<template>
<form action="/v4/search" @submit.prevent="onFormSubmit">
    <div v-if="!isDomReady">
        {{t('general.loading')}}...
    </div>
    <div class="row" :class="{'opacity-0': !isDomReady}">
        <div class="col-12">
            <div class="row">
                <div class="col-12 pl-2">
                    <div class="search-quick-filters text-center row justify-content-center pl-5 pr-5 mt-3">
                        <a v-for="(c, index) in categories" :key="index" class="search-quick-filters-item ml-1 mr-1" :data-id="c.id" :data-name="c.name" @click="onClickCategory($event)">{{ c.name }}</a>
                    </div>
                </div>
            </div>

            <div class="my-4 form-second-row form-second-row-active">
                <div class="row justify-content-center">
                    <div class="col-2 v4-select-container">
                        <FormSelect @input="onSelectChange" v-if="multimediaOptions.length" v-bind="{id: 'content_type', title: 'Content type', defaultOptions: multimediaOptions, multiple: true}"></FormSelect>
                    </div>
                    <div class="col-2 v4-select-container">
                        <FormSelect @input="onSelectChange" v-if="keywordsOptions.length" v-bind="{id: 'qKeywords', title: 'Keywords', defaultOptions: keywordsOptions, multiple: true}"></FormSelect>
                    </div>
                    <div class="col-2 v4-select-container" v-show="showDate">
                        <DatePicker ref="datepicker"></DatePicker>
                    </div>
                </div>

            </div>

            <div class="d-flex justify-content-center search mb-5">
                <input v-model="form.q" type="text" name="q" class="v4-search-query" :placeholder="t('new-home.search.write-in')">
                <button class="ml-3 v4-btn-primary" @click="searchList">
                    <i class="bi bi-search"></i>
                    <p>{{t('general.search')}}</p>
                </button>
            </div>

        </div>
    </div>
</form>
</template>

<script>
import ContentRepository from '../home-form/repositories/Content.repository';
import UtilsService from '../../core/services/utils.service';
import DatePicker from '../form/datepicker/DatePicker.vue';
import FormSelect from '../form/select/FormSelect.vue';
import TranslateService from '../../core/services/translate.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';


export default {
    name: 'ContentForm',
    components: {
        DatePicker,
        FormSelect
    },
    props: {
        therapeutics: Object,
        contentType: Array,
        redirectMode: Boolean,
        userTherapeuticAreas: Array,
        keywords: Array,
        categories: Array,
        congressid: Number,
        hideDateFilter: Boolean
    },
    data() {
        return {
            therapeuticsOptions: UtilsService.mapObjectToArray(this.therapeutics),
            contentTypeOptions: UtilsService.mapObjectToArray(this.contentType),
            pathologiesOptions: [],
            multimediaOptions: [],
            keywordsOptions: this.keywords,
            specialitiesOptions: [],
            showDate: !this.hideDateFilter,

            isDomReady: false,
            form: {
                events: false,
                content: false,
                all: false,
                bookmarks: false,
                q: '',
                qTherapeuticAreas: [],
                qPathologies: [],
                format: [],
                content_type: [],
                qKeywords: [],
                qCategories: [],
                date: [],
                qStartDate: null,
                qEndDate: null,
                congress: [this.congressid.toString()],
                size: 100,
                excludeType: [5]
            },
            formAux:{},
            translator: TranslateService.create()
        }
    },

    mounted() {
        this.prepareSelects();
        window.addEventListener('load', () => {
            this.isDomReady = true;
        });
        document.addEventListener("changeselectpicker", (data) => {
            this.setFormSelects(data.detail);
        });
        /* this.$root.$on('changeselectpicker', (data) => {
            this.setFormSelects(data);
        }); */
        $('.selectpicker').selectpicker('refresh');
    },

    methods: {
        onInputChecked(checkbox) {
            this.setFormInputFromDTO(checkbox)
        },

        onSwitchToggle(toggleData) {
            this.setFormInputFromDTO(toggleData)
        },

        onSelectChange(selected) {
            this.setFormInputFromDTO(selected)
        },

        setFormInputFromDTO(inputData) {
            this.form[inputData.key] = inputData.value;
        },

        onFormSubmit(ev) {
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
        //   this.$root.$emit('showCustomLoader', true);
          this.search();
        },

        searchList(){
            const filterCategory = Object.keys(this.formAux).join(', ');
            const filterValue = Object.values(this.formAux).flat().join(', ');
            GoogleAnalytics.sendFilterEvent(filterValue, filterCategory, filterValue);
        },

        async prepareSelects() {
            let filters = await axios.get('/webapi/home/models-filters');

            filters = filters.data.data;
            const keywords = filters.keywords;
            const multimedia = filters.multimediaTypes;
            const therapeutics = filters.therapeuticAreas;
            const specialities = filters.specialities;
            const pathologies = filters.pathologies;

            this.keywordsOptions = this.mapSelect(Object.values(keywords));
            this.multimediaOptions = this.mapSelect(Object.values(multimedia));
            this.therapeuticsOptions = this.mapSelect(Object.values(therapeutics));
            this.specialitiesOptions = this.mapSelect(Object.values(specialities));
            this.pathologiesOptions = this.mapSelect(Object.values(pathologies));

            $('.selectpicker').selectpicker('refresh');
        },

        mapSelect(collection) {

            return collection.map(i => {

                return {
                    id: i.id,
                    name: i.name,
                    therapeutic_area_id: i.therapeutic_area_id
                }
            });
        },

        async onClickKeyword(event) {
            this.form.qKeywords = [event.currentTarget.dataset.id];
            this.form.content_type = ["content"];
            const data = await ContentRepository.search(this.form);
            document.dispatchEvent(new CustomEvent("SearchEvent", { detail: data }));                    
            // this.$root.$emit('SearchEvent', data);
        },
        async onClickCategory(event) {
            let isActive = event.currentTarget.classList.contains('active');
            $(`.search-quick-filters-item`).removeClass("active");
            if (isActive) {
                event.currentTarget.classList.remove('active');
                this.form.qCategories = [];
            } else {
                event.currentTarget.classList.add('active');
                this.form.qCategories = [event.currentTarget.dataset.id];
            }
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
            // this.$root.$emit('showCustomLoader', true);
            this.search();
        },

        async search() {
            if(this.$refs.datepicker && this.$refs.datepicker.range && this.$refs.datepicker.range.start && this.$refs.datepicker.range.end) {
                this.form.qStartDate = moment(this.$refs.datepicker.range.start).format("DD/MM/YYYY");
                this.form.qEndDate = moment(this.$refs.datepicker.range.end).format("DD/MM/YYYY");
            }
            const data = await ContentRepository.search(this.form);
            document.dispatchEvent(new CustomEvent("SearchEvent", { detail: data }));                    
            // this.$root.$emit('SearchEvent', data);
        },

        setFormSelects(selector) {
            this.form[selector.key] = selector.value;
            this.formAux[selector.key] = selector.name;
        },

        t(tag) {
            return this.translator.translate(tag);
        },
    }
}
</script>

<style lang="scss">
.opacity-0 {
    opacity: 0;
}

@media only screen and (max-width: 600px) {
    .search {
        height: 120px;
        display: grid !important;
    }
}

.keywords__title {
    font-family: "HelveticaNeueLightCondensed";
    font-weight: 900 !important;
    text-align: center;
    font-size: 18px;
    color: white;
}

.keyword {
    color: #2DBCB6;
    font-size: 14px;
    letter-spacing: 0.58px;
    line-height: 18px;
    text-align: left;
}

.keywords-container {
    width: 60%;
    display: block;
    left: 20%;
    position: relative;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .keywords-container {
        width: unset;
        left: unset;
    }
}
</style>>
