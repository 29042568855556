import moment  from "moment";


export default {
    get(content) {
        return {
            id : content?.id ?? 0,
            message : content?.message ?? "",
            created_time : moment(content.created_at).format("HH:mm")
        }
    },
    getLivePoll(content) {
        return {
            id : content?.poll.id ?? 0,
            question : content?.poll.question ?? "",
            created_time : moment(content.poll.created_at).format("HH:mm"),
            status : content?.poll.status ?? 0,
            answers: content?.answers,
            // isModerator : content?.isModerator,
            // isSpeaker: content?.isSpeaker,
            type: content?.poll.type,
            answered : content?.poll.answered ? content?.poll.answered  : 0
        }
    }
}