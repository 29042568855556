<template>
<div>
    <div v-if="isLoading">
        {{t('general.loading')}}...
    </div>
    <div v-if="!isLoading">
        <div v-if="!isMobile()">
            <V4Carousel v-bind="{slidesToShow:3, slidesToScroll:3, name: 'related-content'}">
                <template v-slot:items>
                        <V4Card v-for="item in list" v-bind="item" :key="item.id" @click="handlePromoClick(item.title)"></V4Card>
                </template>
            </V4Carousel>
        </div>
        <div v-else>
            <V4Carousel v-bind="{slidesToShow:1, slidesToScroll:1, name: 'related-content'}">
                <template v-slot:items>
                    <V4Card v-for="item in list" v-bind="item" :key="item.id" @click="handlePromoClick(item.title)"></V4Card>
                </template>
            </V4Carousel>
        </div>
        <p class="no_records" v-if="isRecord">{{t("general.no-results")}}</p>
    </div>
</div>
</template>

<script>
import ContentFactory from '../content/factories/Content.factory';
import V4Card from '../card/V4Card.vue';
import V4Carousel from '../carousel/V4Carousel.vue';
import TranslateService from '../../core/services/translate.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
    name: 'RelatedContent',
    components: {
        V4Card,
        V4Carousel
    },
    props: {
        items: Array,
    },
    data() {
        return {
            list: [],
            isLoading: true,
            // bus : new Vue(),
            translator : TranslateService.create(),
            isRecord: false,
        }
    },

    mounted() {
        this.parseObjects();
    },

    methods: {

        parseObjects() {
            this.list = this.items.map(item => ContentFactory.get(item));
            this.isLoading = false;
            if(this.list.length < 1) {
                this.isRecord = true;
            }
        },

        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        t(tag){
            return this.translator.translate(tag)
        },
        handlePromoClick(title) {
            if (typeof GoogleAnalytics !== 'undefined' && typeof GoogleAnalytics.sendPromoClickEvent === 'function') {
                GoogleAnalytics.sendPromoClickEvent(title);
            } else {
                console.error('GoogleAnalytics.sendPromoClickEvent no está definido');
            }
        },
    }
}
</script>
