<template>
	<div>
		<div class="container-fluid congress-live-notes-container p-0" id="congress-live-notes-container">
			<div class="container-lg p-0">
		        <h3 class="congress-live-section-title">{{ t('live.live_notes') }}</h3>
		    </div>
		    <section class="congress-live-notes">
		        <div class="container p-0 ">
		            <div class="congress-live-notes-content congress-live-notes-content-js">
		                <form @submit.prevent="onFormSubmit" method="post" id="form-notes">
		                    <p class="congress-live-notes-content-message contmessage" style="display:none;">
		                    <span>
		                        {{ t('live.saved_successfully') }}
		                    </span>
		                    </p>
		                    <textarea name="notes" id="form-notes-textarea" rows="6" class="congress-live-notes-content-textarea speakernotes" maxlength="255" required="" v-if="!notes"></textarea>
		                    <textarea name="notes" id="form-notes-textarea" rows="6" class="congress-live-notes-content-textarea speakernotes" maxlength="255" required v-if="notes" v-model="notes.text"></textarea>
		                    <div class="congress-live-notes-content-btn-container">
		                        <div class="d-flex position-relative notes-footer-row">
		                         	<div class="footer-left-block">
		                        	<button type="submit" class="congress-live-notes-content-save">
		                                <span class="button-text mb-0">Save</span>
		                            </button>
		                            </div>
		                            <div class="footer-right-block">

		                            <div class="live-download-note-js" @click="onDownloadNote">
 										<span class="notes-icon-footer"><i class="bi bi-box-arrow-in-down"></i></span>
		                            </div>
		                            <div class="footer-share-icons">
		                            <div class="congress-live-notes-content-share congress-live-notes-content-share-js" @click="showsharebtn">
		                                <span class="notes-icon-footer"><i class="bi bi-share"></i></span>
		                            </div>
		                            <div class="congress-live-notes-content-share-icons congresslist" style="display:none;">
		                                <a @click="notesShareWithText" href="javascript:void(0)" data-url="https://api.whatsapp.com/send?text=" class="congress-live-share-js congress-live-notes-content-share-icons-whatsapp" target="_blank">
		                                   <span class="notes-icon-footer"><i class="bi bi-whatsapp"></i></span>
		                                </a>
		                                <a @click="notesShareWithText" href="javascript:void(0)" :data-url="'mailto:?subject='+t('general.shared-note')+'&body='" class="congress-live-share-js congress-live-notes-content-share-icons-mail">
		                                     <span class="notes-icon-footer"><i class="bi bi-envelope"></i></span>
		                                </a>
		                            	</div>
									</div>
		                            </div>
		                        </div>
		                    </div>
		                </form>
		            </div>
		        </div>
		    </section>
		</div>
	</div>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import axios from 'axios';
import $ from 'jquery';

export default {
    props: {
        slug: String,
        agendaid: [Number, String],
        notes:Object
    },
    methods: {
        t(tag){
            return this.translator.translate(tag)
        },
        showsharebtn(){
	    	if($('.congresslist').css('display') == 'none'){
				$('.congresslist').css('display','block');
			}
			else {
				$('.congresslist').css('display','none');
			}
	    },
	    onFormSubmit(ev) {
            var link = '/webapi/live/'+this.slug+'/post-notes';
            var notes = $('.speakernotes').val();
            axios.post(link,{
                notes: notes,
                slug: this.slug,
                agendaSessionId: this.agendaid
            })
            .then(res => {
                $('.speakernotes').addClass('textarea-success-msg');
                $('.contmessage').find('span').html(this.t('live.saved_successfully'));
                $('.contmessage').css('display','block');
                setTimeout(function(){ $('.contmessage').css('display','none'); $('.speakernotes').removeClass('textarea-success-msg'); }, 1000);
            })
            .catch(err => {
                console.warn(err);
            });
        },
        notesShareWithText(ev) {
	        ev.preventDefault();
	        ev.stopPropagation();
	        let textarea = $('#form-notes-textarea').val();
	        if (!textarea) {
	            textarea = $('#form-floating-notes-textarea').val();
	        }
	        if(textarea == undefined || textarea == ''){
	            $('.speakernotes').addClass('textarea-success-msg');
	            $('.contmessage').find('span').html("Please Add Notes to Share!");
	            $('.contmessage').css('display','block');
	            setTimeout(function(){ $('.contmessage').css('display','none'); $('.speakernotes').removeClass('textarea-success-msg'); }, 3000);
	            return true;
	        }
	        const target = $(ev.currentTarget);
	        const href = target.data('url');
	        const url = href + textarea;
	        console.log(url);
	        if (target.attr('target') === '_blank') {
	            window.open(url);
	        } else {
	            window.location.replace(url);
	        }
	    },
	    onDownloadNote(ev) {
	    	// Get the source we want to download
	    	let content = $('#form-notes-textarea').val();
	        if (!content) {
	            content = $('#form-floating-notes-textarea').val();
	        }
	    	if(content == undefined || content == ''){
	            $('.speakernotes').addClass('textarea-success-msg');
	            $('.contmessage').find('span').html("Please Add Notes to Download!");
	            $('.contmessage').css('display','block');
	            setTimeout(function(){ $('.contmessage').css('display','none'); $('.speakernotes').removeClass('textarea-success-msg'); }, 3000);
	            return true;
	        }
			// Download note content
			ev.preventDefault()
			$('<a href="data:text/plain,' + content.trim() + '" download="' + 'note' + '.docx">')[0].click()
		},
    },
    data:function(){
        return{
            translator : TranslateService.create()
        }
    },

}
</script>
