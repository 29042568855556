<template>
<div>
    <div v-if="isLoading">{{t('general.loading')}}...</div>
    <div v-if="!isLoading" :key="categoriesKey">

        <!-- LIST CONTENT -->
        <div v-if="!isMobile()">
            <V4Carousel :slidesToShow="3" :slidesToScroll="3" :rowsToShow="this.setNumberOfRows()" :name="'krasContent'" ref="kras-carousel" 
                :isLoading="isLoading" v-if="!isLoading" :numberCarousel="true">
                <template v-slot:items>
                    <div v-for="(item, i) in content" v-bind:key="i" class="m-0 p-0 mb-3">
                        <V4Card :key="item.id" v-bind="item"></V4Card>
                    </div>
                </template>
            </V4Carousel>
        </div>
        <div v-else>
            <V4Carousel :slidesToShow="1" :slidesToScroll="1" :rowsToShow="this.setNumberOfRows()" :name="'krasContent'" ref="kras-carousel" 
                :isLoading="isLoading" v-if="!isLoading" :numberCarousel="true">
                <template v-slot:items>
                    <div v-for="(item, i) in content" v-bind:key="i">
                        <V4Card :key="item.id" v-bind="item"></V4Card>
                    </div>
                </template>
            </V4Carousel>
        </div>

        <!-- LIST CATEGORIZED CONTENT -->
        <div v-for="(category, index) in categories" :key="index">
            <p class="text-bold">
                {{category.name}}
            </p>
            <div v-if="!isMobile()">
                <V4Carousel v-bind="{slidesToShow:3, slidesToScroll:3, name: `content-categorized-${index}`, isLoading: false, numberCarousel: false}">
                    <template v-slot:items>
                        <V4Card v-for="ev in category.multimedia" v-bind="ev" :key="ev.id"></V4Card>
                    </template>
                </V4Carousel>
            </div>
            <div v-else>
                <V4Carousel v-bind="{slidesToShow:1, slidesToScroll:1, name: `content-categorized-${index}`, isLoading: false, numberCarousel: false}">
                    <template v-slot:items>
                        <V4Card v-for="ev in category.multimedia" v-bind="ev" :key="ev.id"></V4Card>
                    </template>
                </V4Carousel>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import ContentFactory from '../content/factories/Content.factory';
import ContentRepository from '../home-form/repositories/Content.repository';
import V4Carousel from '../carousel/V4Carousel.vue';
import V4Card from '../card/V4Card.vue';


export default {
    name: 'ContentCategorized',
    components: {
        V4Carousel,
        V4Card
    },
    props: {
        congressid: Number
    },
    data() {
        return {
            isLoading: true,
            list: [],
            content: [],
            categories: [],
            categoriesKey: 0,
            form: {
                events: false,
                content: false,
                all: false,
                bookmarks: false,
                q: '',
                qTherapeuticAreas: [],
                qPathologies: [],
                format: [],
                content_type: [],
                qKeywords: [],
                date: [],
                page: 0,
                size: 100,
                congress: [this.congressid.toString()],
                excludeType: [5]
            },
            translator : TranslateService.create()
        }
    },

    mounted() {
        document.addEventListener("SearchEvent", (event) => {
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
            let results = event.data ? event.data[0] : [];
            this.processResults(results);
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
        });
        /* this.$root.$on('SearchEvent', (data) => {
          this.$root.$emit('showCustomLoader', true);
          let results = data ? data[0] : [];
          this.processResults(results);
          this.$root.$emit('showCustomLoader', false);
        }); */

        this.get();
    },

    methods: {
        async get() {
            try {
                this.isLoading = true;

                let contentByCategory = await ContentRepository.search(this.form);
                let results = contentByCategory ? contentByCategory[0] : [];

                this.processResults(results);

                if(this.categories.length == 0 && this.content.length == 0) {
                    $('#content').css('display', 'none');
                    $('#content_menu').css('display', 'none');
                }
            } catch (error) {
                this.list = [];
                this.isLoading = false;
            }
        },
        processResults(results) {
            //Categories
            let listCategories = [];
            if(results && results.categories) {
                results.categories.forEach(function callback(category, i){
                    listCategories.push(category);
                    listCategories[i].multimedia = listCategories[i].multimedia.map(item => ContentFactory.get(item));
                });
            }

            //Agendas
            const listContent = results && results.content ? results.content.map(k => ContentFactory.get(k)) : [];

            this.categories = listCategories;
            this.content = listContent;

            this.refreshCategories();
            this.isLoading = false;
        },
        refreshCategories() {
          this.categoriesKey++;
        },
        setNumberOfRows() {
            return this.list.length > 3 ? 2 : 1;
        },
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        t(tag){
            return this.translator.translate(tag)
        },
    }
}
</script>
