<template>
  <article class="focus-card" :id="`focus-card-${ id }`" :class="multimedia_category_class" @click="cardRedirect(url)" :style="{ backgroundImage: `url(${ backgroundImage })` }">
    <div class="focus-card__content" :class="focusType == 'title_focus' ? 'title-focus' : 'image-focus'">
      <div class="focus-card__content__tag" :style="'background-color:' + card_color + ';'">
        <span class="focus-card__content__tag__icon" :class="card_icon"></span>
        <span class="focus-card__content__tag__text"> {{ card_type ? card_type : multimedia_type_str }}</span>
      </div>
      <div class="focus-card__content__title">
        <h3>{{ title }}</h3>
      </div>
    </div>
  </article>
</template>

<script>
import { ref, onMounted } from 'vue';
import TranslateService from '../../core/services/translate.service.js';
import TranslationService from '../../core/services/translation.service.js';
import ActivityService from '../../core/services/activity.service.js';

export default {
  name: 'FocusCard',
  props: {
    id: Number,
    title: String,
    slug: String,
    url: String,
    multimedia_category_class: String,
    preventOpen: Boolean,
    type: String,
    cards_behaviour: String,
    external_redirect: Boolean,
    external_url: String,
    is_live: Boolean,
    is_accessible: Boolean,
    external_congress: Boolean,
    is_on_demand: Boolean,
    meeting_platform: String,
    multimedia_type_str: String,
    card_type: String,
    card_color: String,
    card_icon: String,
    focusType: String,
  },
  setup(props, { attrs }) {
      const translator = TranslateService.create();
      const t = (tag) => translator.translate(tag);
      const activitySvc = ActivityService.create();
      const id = props.id;
      const title = props.title;

      const parseImage = (img) => {
        if (img && img.substr(0,1) != '/') {
            img = '/' + img;
        }
        return img;
      };

      const backgroundImage = parseImage(attrs.image);

      onMounted(() => {

      });

      const isMobile = () => {
          try {
              return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
          } catch (error) {
              console.error('Error determining if the device is mobile:', error);
              return false;
          }
      };

      const getActivityType = () => {
        switch (props.type) {
          case 'multimedia':
            return 'open-multimedia';
          case 'congress':
            return 'enter-congress';
          case 'journey':
            return 'enter-kj';
        }
      };

      const onMultimediaClick = () => {
        const dataTracks = attrs.dataTracks || [];
        // Save item to localStorage:
        localStorage.setItem(`datatracks-m-${props.id}`, JSON.stringify(dataTracks));
        return true;
      };

      const saveActivity = (event) => {
        let params = {
          title: props.title,
          model_type: props.type,
          model_id: props.id,
          congress_id: window.app.event ? window.app.event.id : null
        };
        activitySvc.saveActivity(event, params);
      };

      const cardRedirect = (url) => {
        if (props.preventOpen) {
          return;
        }

        if (props.type !== 'agenda_session' && props.type !== 'agenda') {
          onMultimediaClick();
          if (props.cards_behaviour == 'disable modal') {
            return;
          }
          if (props.external_redirect) {
            document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: props.external_url }));       
          } else {
            saveActivity(getActivityType());
            return window.open(url, '_blank').focus();
          }
        } else {
          if (props.cards_behaviour == 'external link') {
            document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: attrs.external_link }));   
          } else if (props.cards_behaviour == 'open modal') {
            let agendaURL = '';
            if ((props.is_live && props.is_accessible && !props.external_congress)
                || (props.is_on_demand  && props.is_accessible && !props.external_congress && attrs.external_link != '')) {
              if(props.meeting_platform == 'ZOOM' && window.app.env.ENABLE_LIVE){
                agendaURL = window.app.env.LIVE_URL+'/zoom/'+props.slug+'/'+props.id+'?encryptsKey=live-encrypts'+window.user.id;
              }else if(props.meeting_platform == 'WEBEX' && window.app.env.ENABLE_LIVE){
                        agendaURL = window.app.env.LIVE_URL+'/webex/'+props.slug+'/'+props.id+'?encryptsKey=live-encrypts'+window.user.id;
              }else{
                agendaURL = TranslationService.transRoute('event-detail-live', {
                slug: props.slug,
                agendaSessionId: props.id
              });
              }
              saveActivity('live');
            } else {
              agendaURL = TranslationService.transRoute('event-agenda-detail', {
                slug: props.slug,
                agendaSessionId: props.id
              });
              saveActivity('agenda');
            }
            return window.open(agendaURL, '_blank').focus();
          }
        }
      };

      return {
          t,
          isMobile,
          id,
          title,
          cardRedirect,
          backgroundImage,
      };
  },
}
</script>