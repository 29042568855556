<template>
  <div class="v4-detailed-card kj-card custom-congress-details aaa">
    <div class="v4-detailed-card--title">
      <p>{{ title }}</p>
    </div>
    <div class="v4-detailed-card--info">
      <ul class="v4-detailed-card--info--list">
        <li>
          <span><i class="bi bi-clock"></i></span> {{ info.duration_min }} minutes
        </li>
        <li>
          <span><i class="bi bi-clipboard-check"></i></span> Auto-evaluation test
        </li>
        <li>
          <span><i class="bi bi-broadcast"></i></span> MMP {{ t("knowledge-journey.card.certification") }}
        </li>
        <li>
          <span><i class="bi bi-globe"></i></span> {{ info.translated_language }}
        </li>
      </ul>
      <div class="v4-detailed-card--info--categories">
        <a v-for="(ta, key) in therapeuticAreas" :key="key" class="v4-detailed-card--info--categories--item"> {{ ta.name }} </a>
      </div>
    </div>

    <div class="v4-detailed-card--info--hashtags">
      <a v-for="(kw, key) in keywords" :key="key" class="v4-detailed-card--info--hashtags--item">#{{ kw.name }}</a>
    </div>

    <div class="v4-detailed-card--progress">
      <p class="v4-detailed-card--progress--title">{{ t("knowledge-journey.card.your_progress") }}</p>

      <div class="v4-detailed-card--progress--bar">
        <span class="v4-detailed-card--progress--completed" :style="{ width: percentWidth }"></span>
      </div>

      <p class="v4-detailed-card--progress--percent-info">{{ info.progress }}%</p>
    </div>

    <a @click="openCard(); handlePromoClick(title)" href="javascript:void(0)" class="v4-detailed-card--action">{{info.progress > 0 ?
      t('knowledge-journey.card.keep_learning') : t('knowledge-journey.card.start')}}</a>
  </div>
</template>

<script>
import ActivityService from "../../../core/services/activity.service";
import TranslateService from "../../../core/services/translate.service";
import GoogleAnalytics from '../../../GoogleAnalytics.js';

export default {
  props: {
    id: Number,
    title: String,
    info: Object,
    keywords: Array,
    therapeuticAreas: Array,
    link: String,
  },
  computed: {
    percentWidth: function () {
      return `${this.info.progress}%`;
    },
  },
  data() {
    return {
      translator: TranslateService.create(),
      activitySvc: ActivityService.create(),
    };
  },
  mounted() {},
  methods: {
    t(tag, params = null) {
      let translation = this.translator.translate(tag);
      if (params) {
        translation = stringInject(translation, params);
      }
      return translation;
    },
    openCard() {
      let params = {
        title: this.title,
        model_type: "knowledge",
        model_id: this.id,
        congress_id: window.event ? window.event.id : null,
      };
      this.activitySvc.saveActivity("enter-kj", params);
      return window.open(this.link, "_blank").focus();
    },
    handlePromoClick(title) {
      if (typeof GoogleAnalytics !== 'undefined' && typeof GoogleAnalytics.sendPromoClickEvent === 'function') {
        GoogleAnalytics.sendPromoClickEvent(title);
      } else {
        console.error('GoogleAnalytics.sendPromoClickEvent is not defined');
      }
    },
  },
};
</script>
