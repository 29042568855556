<template>
    <header id="custom-template-header">
        <div class="fixed-top row align-items-center" :class="{ 'scrolled-menu' : windowTop > 60 }">
            <div class="col-2 col-md-2">
                <div class="dropdown custom-dropdown" id="dropdownMenu">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i :class="menuIcon"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a v-for="(item, index) in menuItems" :key="index" :href="item.link" class="dropdown-item">
                            {{item.text}}
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-8 col-md-8 d-flex justify-content-center">
                <div id="navbar" style="none">
                    <ul class="nav hide nav-sections-custom-template" :class="{ 'show' : windowTop > 60 }">
                        <li class="nav-item" :key="section.section_id" v-for="section in sections">
                            <a class="nav-link" :href="'#' + $parent.formatHrefAnchorText(section.section_wording)">{{ section.section_wording }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-2 col-md-2 custom-template-profile pr-4">
                <ProfileDropdownVue :user="user" :class="{ 'scrolled' : windowTop > 60 }"></ProfileDropdownVue>
            </div>
        </div>
    </header>
</template>

<script>
import TranslateService from '../../../core/services/translate.service';
import ProfileDropdownVue from '../../profile-dropdown/ProfileDropdown.vue';
import $ from 'jquery';
import axios from 'axios';

export default {
    name: 'HeaderTemplate',
      components: { ProfileDropdownVue },
    props: {
        sections: {},
    },
    data() {
        return {
            translator: TranslateService.create(),
            windowTop: 0,
            user: {},
            isMenuOpen: false,
            menuItems: {},
        };
    },
    created() {
        this.getItems();
    },
    mounted() {
        $('body').scrollspy({ target: '#navbar', offset: 100 });
        window.addEventListener("scroll", this.onScroll);
        this.addListenerToDrowdownMenu();
    },
    computed: {
        menuIcon() {
            return this.isMenuOpen ? 'bi bi-x-lg' : 'bi bi-list';
        },
    },
    methods: {
        t(tag) {
            return this.translator.translate(tag);
        },
        onScroll(e) {
            this.windowTop = window.top.scrollY;
        },
        async getItems() {
            await axios.get('/congresses/get-header-data').then(response => {
                    this.menuItems = response.data;
                }
            );
        },
        changeHamburgerIcon() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        addListenerToDrowdownMenu() {
            const self = this;
            $('#dropdownMenu').on('show.bs.dropdown hide.bs.dropdown', function () {
                self.changeHamburgerIcon();
            });
        }
    }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
