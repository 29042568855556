<template>
 <div v-if="!isLoading">
  <div v-if="!isMobile()">
     <V4Carousel :slidesToShow="5" :slidesToScroll="5" :name="'session'">
      <template v-slot:items>
        <div v-for="(item, i) in list" v-bind:key="i">
          <SessionItem v-bind="item" />
        </div>
      </template>
    </V4Carousel>
  </div>
  <div v-else>
    <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'session'">
      <template v-slot:items>
        <div v-for="(item, i) in list" v-bind:key="i">
          <SessionItem v-bind="item"/>
        </div>
      </template>
    </V4Carousel>
  </div>
  </div>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import SessionItem from './sessionItem.vue';
import V4Carousel from '../carousel/V4Carousel.vue';

export default {
    props: ['list'],
    components :{
      SessionItem,
      V4Carousel
    },
    data() {
        return {
            isLoading: true,
           /*  bus: new Vue(), */
            translator : TranslateService.create()

        }
    },mounted() {
      this.isLoading = false;
    },methods: {
      showDefaultImage(event){
        const loc = document.location;
        event.target.src = `${loc.protocol}//${loc.host}/`+"images/v4/picture.png"
      },
      isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      },
      t(tag){
        return this.translator.translate(tag)
      }
    }
}
</script>
