<template>
  <div id="speaker-container" class="multimedia-speaker">
    <div class="row" v-if="speakers.length > 0">
      <div v-for="(speaker, index) in speakers" :key="index" class="col col-xl-4">
        <div class="speaker mb-3">
          <AgendaSpeakersItem
            :name="speaker.name"
            :surname="speaker.surname"
            :position="speaker.position"
            :photo="speaker.photo"
            :institution="speaker.institution"
            :bio="speaker.bio"
            :short_bio="speaker.short_bio"
            :slug="slug"
            :template="template"
          >
          </AgendaSpeakersItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgendaSpeakersItem from "../agenda/AgendaSpeakersItem.vue";

export default {
  name: "SpeakersSection",
  components: {
    AgendaSpeakersItem
  },
  props: {
    speakers: Array,
    slug: String,
    template:Number
  },
  data() {
    return {
      list: []
    };
  }
};
</script>
