import { WSHelper } from '../../../../libs/ws/ws-helper';
import Utils from '../../Utils';
import LiveBackPollsController from './LiveBackPollsController';
import LiveReactionsController from '../live/LiveReactionsController';
import Toastify from 'toastify';
// import eventCallerService from '../../core/services/eventCaller.service';
import DOMPurify from 'dompurify';
import axios from 'axios';
import $ from 'jquery';

const LiveSpeakerPollsController = {
  pollEl: {
    selector: '.poll-item-container-js'
  },
  pollsContainerEl: {
    selector: '.congress-live-back-moderator-polls-container'
  },
  wordcloudCanvasEl: {
    selector: '#wordcloud-canvas-'
  },
  routes: {
    getPolls: 'get-polls',
  },
  timer: {
    FULL_DASH_ARRAY: 283,
    TIME_LIMIT: window.app.event ? (window.app.event.time_limit || 20) : 20,
    timePassed: 0,
    timeLeft: window.app.event ? (window.app.event.time_limit || 20) : 20,
    timerInterval: null,
  },

  init() {
    this.setListeners();
    this.initWebSockets();
    // this.initSetIntervals();
    LiveBackPollsController.initWordclouds();
    // LiveReactionsController.init();
  },

  setListeners() {
    this.setPadletListeners();
  },

  initRequests() {

  },

  initWebSockets() {
    this.ws = new WSHelper(true);
    this.registerWSEvents();
    this.ws.start().then(res => {
      console.debug(res);
    }).catch(err => {
      console.debug("ERR", err);
    });
    try {
      const polls = $(this.pollEl.selector);
      for (const poll of polls) {
        const pollId = $(poll).data('pollid');
        LiveBackPollsController.updatePollResults(pollId, {
          success: LiveBackPollsController.updatePollResultsSuccess,
          error: LiveBackPollsController.updatePollResultsError
        });
      }
    } catch(e) {
      console.debug("ERR", e);
    }
  },

  sendWSStatusMessage() {
    const agendaSessionId = Utils.getAgendaSessionId();
    this.agId = agendaSessionId;
    this.ws.sendObject({ "agenda_id": agendaSessionId, operation: "register_ws", "uid": window.user.uid});
  },

  onWSMessageReceived(evt) {
    if (evt.json && evt.json.data) {
      /* The data object structure is freely expandable.
        * Required fields:
        * action: "show_poll", "poll_ended", "live_end", etc.
        * timestamp
        * Optional Fields:
        * html: html data (i.e, poll modal, next-session modal, etc)
      */
      const data = evt.json.data;
      //If current agenda do action
      if(this.checkIsCurrentAgenda(data.agendaId)) {
        switch (data.action) {
          case "registered":
            this.wsToken = data.token;
            console.debug("Moderator token received, id:", this.wsToken);
          break;
          case "next_session":
            this.getFinishedSessionInfo();
          break;
          case "poll_ended":
            console.debug("Poll closed!", data);
          break;
          case "speaker_question_sent":
            document.dispatchEvent(new CustomEvent("receiveSpeakerQuestion", { detail: data.liveQuestion }));
            // eventCallerService.$emit('receiveSpeakerQuestion', data.liveQuestion);
          break;
          case "speaker_question_discarded":
            document.dispatchEvent(new CustomEvent("discardSpeakerQuestion", { detail: data }));
            // eventCallerService.$emit('discardSpeakerQuestion', data);
          break;
          case "speaker_question_answered":
            document.dispatchEvent(new CustomEvent("answerSpeakerQuestion", { detail: data }));
            // eventCallerService.$emit('answerSpeakerQuestion', data);
          break;
          case "sp_mod_poll_results":
            // Current slick position:
            const pollIdElement = document.querySelector(".congress-live-back-moderator-polls-item.slick-current");
            let currentPollID = null;
            if (!!pollIdElement) {
              currentPollID = pollIdElement.getAttribute("data-pollid");
            }
            // Refresh slides:
            $('.congress-live-back-moderator-polls-container').slick('removeSlide', null, null, true);

            Object.keys(data.polls).forEach(key => {
              $('.congress-live-back-moderator-polls-container').slick('slickAdd',data.polls[key].html);
            });
            //If new poll added, go to slide:
            if(data.pollId != null) {
              this.goToPollSlide(data.pollId);
            }
            // If a poll is supplied:
            else if(currentPollID != null) {
              this.goToPollSlide(currentPollID);
            }
          break;
          case "speaker_force_view":
            this.goToPollSlide(data.id);
          break;
          case "speaker_questions_order":
            // this.refreshOrderedQuestions(data.agendaId);
            document.dispatchEvent(new CustomEvent('refreshSpeakerOrderedQuestions'));
            // eventCallerService.$emit('refreshSpeakerOrderedQuestions');
          break;
          case "live_reaction":
            LiveReactionsController.showReaction(data.message);
          break;
          default:
            console.debug("Unknown action", data.action);
          break;
        }
      }
    } else {
      console.debug("Unknown message received...");
    }
  },

  checkIsCurrentAgenda(agendaId) {
    if(!agendaId) {
      return true;
    } else {
      const agendaSessionId = Utils.getAgendaSessionId();
      return agendaSessionId == agendaId;
    }
  },

  registerWSEvents() {
    if(!this.ws) return;
    this.ws.registerCallback((evt) => {
      console.debug("received event!", evt);
      switch(evt.operation) {
        case "conn_down":
          console.debug("Connection down...");
        break;
        case "msg_received":
          // A message was received:
          this.onWSMessageReceived(evt);
        break;
        case "conn_started":
        case "conn_restarted":
          // Ask current status:
          this.sendWSStatusMessage();
        break;
      }
    });
  },

  initSetIntervals() {
    this.setUpdatePollResultsIntervals();
    setInterval(() => {
      this.getAnswers({
        success: this.getAnswersSuccess,
        error: this.getAnswersError
      });
    },10000);
  },
  setUpdatePollResultsIntervals() {
    const publishedPolls = $(`${this.pollEl.selector}[data-status=1]`);
    if( publishedPolls ){
      for( let poll of publishedPolls ) {
        this.setUpdatePollResultsInterval(poll);
      }
    }
  },

  setUpdatePollResultsInterval(poll){
    const pollId = poll.dataset.pollid;
    const updatePollResultsInterval = setInterval(() => {
      const $poll = $(`${this.pollEl.selector}[data-pollId=${pollId}]`);
      const status =  $poll.attr('data-status');
      const stillPublished = status == '1';
      if( ! stillPublished ){
        clearInterval(updatePollResultsInterval);
      }else {
        LiveBackPollsController.updatePollResults(pollId, {
          success: LiveBackPollsController.updatePollResultsSuccess,
          error: LiveBackPollsController.updatePollResultsError
        });
      }
    }, 3000);
  },
  getAnswers(callBacksFn){
    const url = Utils.getUrl(true, this.routes.getPolls);
    const agendaSessionId = Utils.getAgendaSessionId();
    const shownPollsIds = this.getShownPollsIds();
    const status = [1, 2, 3];
    const data = {
      agendaSessionId: agendaSessionId,
      shownPollsIds: shownPollsIds,
      status: status
    };
    this.makeRequest(url, data, callBacksFn);
  },

  getAnswersSuccess(response){
    const {data, status} = response;

    if( data && status === 200 ){
      for( const i in data.data ){
        const obj = data.data[i];
        if( obj.html ){
          const poll = Utils.escapeHtml(obj.html);
          const $poll = $(Utils.escapeHtml(DOMPurify.sanitize(poll)));
          const pollStatus = $poll.data('status');
          const type = $poll.data('type');
          const pollId = $poll.data('pollid');
          const container = document.querySelector('.congress-live-back-moderator-polls-container');
          container.insertAdjacentHTML('afterbegin', Utils.escapeHtml(DOMPurify.sanitize(poll)));
          if( pollStatus === 1 ){
            this.setUpdatePollResultsInterval($poll[0]);
          }
          if( type === 3 ){
            this.initWordcloud(obj, pollId);
          }

        }
      }
    }
  },
  displayToastifyMessage(type , key , value){
    if ( type == 1 ) {
      Toastify.success(key, value);
    } else {
      Toastify.error(key, value);
    }
  },
  initWordcloud(obj, pollId) {
    const selector = Utils.escapeHtml(DOMPurify.sanitize(`${this.wordcloudCanvasEl.selector}${pollId}`));
    if ($(Utils.escapeHtml(DOMPurify.sanitize(selector))).length && obj.data ) {
      const data = obj.data.answers.answers.wordcloud;
      WordCloudController.initWordcloud(data, pollId);
    }
  },
  getAnswersError(err){
    console.debug("ERR", err);
  },

  getShownPollsIds(){
    let ids = [];
    const polls = $(this.pollEl.selector);
    for(const poll of polls){
      const pollId = $(poll).data('pollid');
      ids.push(pollId);
    }
    return ids;
  },

  goToPollSlide(pollId) {
    try {
      let selectedSlide = $(`.congress-live-back-moderator-polls-item[data-pollid=${pollId}]`);
      if(selectedSlide.length) {
        let pollSlide = selectedSlide.closest('.slick-slide');
        let index = pollSlide.data('slick-index');
        $('.congress-live-back-moderator-polls-container').slick('slickGoTo', index);
      }
    } catch(e) {
      console.debug("ERR", e);
    }
  },

  refreshOrderedQuestions(agendaSessionId) {
    let url = Utils.getUrl(false, 'refresh-speaker-ordered-questions');
    let data = new FormData();
    data.append("agendaSessionId", Utils.getAgendaSessionId());
    data.append("editable", false);
    axios.post(url, data).then(res => {
      let questionsContainer = $(".ask-speaker-draggable-list");
      if (questionsContainer.length) {
        //Refresh questions
        var questionsContainer_ = document.getElementsByClassName('ask-speaker-draggable-list');
        questionsContainer_.innerHTML(Utils.escapeHtml(res.data.html));
      }
    }).catch(error => {
      console.debug("ERR", error);
    });
  },

  pollsToggle(ev) {
    const button = $(ev.currentTarget);
    button.toggleClass('closed');
    let pollContent = button.closest(this.pollEl.selector).find(this.pollsItemContainer.selector);
    pollContent.slideToggle(500);
  },

  makeRequest(url, data, callbacksFn) {
    const $req = axios.post(url, data);
    $req.then((response) => {
      let sFn = callbacksFn.success;

      if (typeof sFn == "function")
        sFn.bind(this)(response);
    });

    $req.catch((response) => {
      let eFn = callbacksFn.error;

      if (typeof eFn == "function")
        eFn.bind(this)(response);
    });
  },

  getFinishedSessionInfo() {
    let url = Utils.getUrl(true, 'get-finished-session-info');
    let data = new FormData();
    data.append("agendaSessionId", Utils.getAgendaSessionId());
    axios.post(url, data).then(res => {
        if (res.status === 200) {
          // eventCallerService.$emit('showFinishedSession', res.data);
          document.dispatchEvent(new CustomEvent("showFinishedSession", { detail: res.data }));
        }
    }).catch(error => {
      console.debug("ERR", error);
    });
  },

  /****************
   * TIMER SECTION
   ****************/
  /**
   * When timer has finished, clear interval (avoids negative seconds)
   */
  onTimesUp() {
    clearInterval(this.timer.timerInterval);
    if ($('#next-user-session-btn').length) {
      let url = $('#next-user-session-btn').attr('href');
      window.location.replace(url);
    }
  },

  /**
   * Starts the timer (seconds and circle)
   */
  startTimer() {
    //Initialize countdown circle
    document.getElementById("next-session-countdown").innerHTML = `
        <div class="base-timer">
          <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
              <linearGradient id="countdown-linear" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%"   stop-color="#00bce4"/>
                  <stop offset="100%" stop-color="#005490"/>
              </linearGradient>
              <path
                id="base-timer-path-remaining"
                stroke-dasharray="283"
                stroke="url(#countdown-linear)"
                class="base-timer__path-remaining"
                d="
                  M 50, 50
                  m -45, 0
                  a 45,45 0 1,0 90,0
                  a 45,45 0 1,0 -90,0
                "
              ></path>
            </g>
          </svg>
        </div>
        `;

    //Interval each second
    this.timer.timerInterval = setInterval(() => {
      this.timer.timePassed = this.timer.timePassed += 1;
      this.timer.timeLeft = this.timer.TIME_LIMIT - this.timer.timePassed;
      var countdownNumberEl = document.getElementById('next-session-countdown-number');
      countdownNumberEl.textContent = this.timer.timeLeft;
      this.setCircleDasharray();

      if (this.timer.timeLeft === 0) {
        this.onTimesUp();
      }
    }, 1000);
  },

  /**
   * Formats the time to seconds
   */
  formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  },

  /**
   * Calculate time fraction (for circle)
   */
  calculateTimeFraction() {
    const rawTimeFraction = this.timer.timeLeft / this.timer.TIME_LIMIT;
    return rawTimeFraction - (1 / this.timer.TIME_LIMIT) * (1 - rawTimeFraction);
  },

  /**
   * Draws the circle progress
   */
  setCircleDasharray() {
    const circleDasharray = `${(
      this.calculateTimeFraction() * this.timer.FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    document
      .getElementById("base-timer-path-remaining")
      .setAttribute("stroke-dasharray", circleDasharray);
  },

  setPadletListeners() {
    $('body').on('click', '.live-speaker-multimedia-video-controls-left-item-mural', ev => {
      if (!$('.floating-menu-item-mural.active').length) {
        $('.floating-menu-item-mural').removeClass('d-none');
        $('.floating-menu-item-mural').addClass('active');
      } else {
        $('.floating-menu-item-mural').addClass('d-none');
        $('.floating-menu-item-mural').removeClass('active');
      }
    });
    //Close menu item modal
    $('body').on('click', '.floating-menu-item-close', ev => {
      let itemModal = $(ev.currentTarget).closest('.floating-menu-item');
      if (itemModal.length) {
        itemModal.addClass('d-none');
        itemModal.removeClass('active');
      }
    });
  },
};

export default LiveSpeakerPollsController;
