<template>
    <div>
        <div v-if="isLoading">
            {{t('general.loading')}}...
        </div>

        <ul class="v4-detailed-list" v-if="!isLoading">
            <li v-for="(item, index) in list" v-bind="item" v-bind:key="item.id">
                <p class="v4-detailed-list--index">
                    {{ index+1 }}
                </p>

            <div class="v4-detailed-list--body">
                <a style="cursor: pointer; text-decoration: none;" :href="escapeHtml(loadMultimediaPage(item))" target="_blank" @click="handlePromoClick(item.title)">
                    <h4 class="v4-detailed-list--title">
                      {{ shortTitle(item.title)  }}
                    </h4>
                </a>
                <p class="v4-detailed-list--info">
                    {{ item.date_formatted }}
                </p>
            </div>
        </li>
    </ul>
</div>
</template>

<script>
import TrendingRepository from './repositories/Trending.repository';
import TrendingFactory from './factories/Trending.factory';
import TranslateService from '../../core/services/translate.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import Utils from '../../../v4/Utils';

export default {
    data() {
        return {
            isLoading: true,
            list: [],
            error: '',
            translator : TranslateService.create(),
        }
    },

    mounted() {
        this.get();
    },

    methods: {
        async get() {
            try {
                this.isLoading = true;
                this.error = '';
                const items = await TrendingRepository.list();
                this.list = items.map(i => TrendingFactory.get(i));
                this.isLoading = false;
            } catch (error) {
                this.list = [];
                this.error = "something happened";
                this.isLoading = false;
            }
        },
        escapeHtml(item) {
            return Utils.escapeHtml(item);
        },
        loadMultimediaPage(item) {
            var location = window.location.origin;
            var locale = window.app.env.locale;
            var itemId = item.id;
            var result = location + '/' + locale + '/multimedia/' + itemId;
            return Utils.escapeHtml(result);
        },
        shortTitle(item){
            if(item && item.length > 60) {
            return item.substring(0,60) + "..." ;
            }else{
            return item;
            }
        },
        t(tag) {
            return this.translator.translate(tag);
        },

        handlePromoClick(title) {
            if (typeof GoogleAnalytics !== 'undefined' && typeof GoogleAnalytics.sendPromoClickEvent === 'function') {
                GoogleAnalytics.sendPromoClickEvent(title);
            } else {
                console.error('GoogleAnalytics.sendPromoClickEvent is not defined');
            }
        },
    }
}
</script>
