<template>
  <div>
    <div class="agenda-card-standard">
      <div class="agenda-card-compact" @click="cardRedirect(itemInfo.link)">
        <header class="agenda-card__header">
          <span class="agenda-tag__event"></span>
        </header>
        <article class="agenda-card__body">
          <h3 class="agenda-card__body__title">
            <a href="#" v-html="itemInfo.title"></a>
          </h3>
          <p class="agenda-card__body__date">{{ itemInfo.date_formatted }}</p>
        </article>
      </div>
      <p class="agenda-card__subtitle" v-if="itemInfo.speakers.length > 0">
        <span>{{ t('congress.speakers') }}</span>
        <i class="bi bi-chevron-down" v-if="itemInfo.speakers.length > 0" @click="toggleDropDown($event)"></i>
      </p>
      <div class="agenda-card-compact__speakers-container" :class="{ short: itemInfo.speakers.length > 0 }"
        v-if="itemInfo.speakers.length > 0">
        <div class="agenda-card-compact__speaker" v-for="(speaker, index) in itemInfo.speakers" :key="index">
          <img alt="speaker-image" v-if="speaker.photo" :src="parseImage(speaker.photo)" />
          <div>
            <span class="agenda-card-compact__speaker-name">{{ speaker.name }} {{ speaker.surname }}</span>
            <span class="agenda-card-compact__speaker-job">{{ speaker.position }}</span>
          </div>
        </div>
      </div>
      <AgendaCardFooter :itemInfo="itemInfo"></AgendaCardFooter>
    </div>
  </div>
</template>

  <script>
  import TranslateService from '../../../core/services/translate.service';
  import TranslationService from '../../../core/services/translation.service';
  import ActivityService from '../../../core/services/activity.service';
  import AgendaCardFooter from './AgendaCardFooter.vue';
  import GoogleAnalytics from '../../../GoogleAnalytics.js';

  export default {
    name: 'AgendaCardCompact',
    components: {AgendaCardFooter},
    data() {
      return {
        translator: TranslateService.create(),
        activitySvc: ActivityService.create(),
      };
    },
    props: {
      cardType: String,
      itemInfo: Object,
    },
    methods: {
      t(tag) {
        return this.translator.translate(tag);
      },
      imageUrlAlt(event) {
        event.target.src = "/images/v4/assets/avatar.svg";
      },
      cardRedirect(url) {
        if (this.itemInfo.preventOpen) {
          return;
        }
        
        const { cards_behaviour, is_live, is_accessible, external_congress, is_on_demand, multimedias, meeting_platform, slug, id } = this.itemInfo;
        const { ENABLE_LIVE, LIVE_URL } = window.app.env;
        const userId = window.user.id;
        
        const openExternalLink = () => document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.$attrs.external_link }));       
        // this.$root.$emit('leavingMMPModal', this.$attrs.external_link);

        const openModal = () => {
          const shouldOpenLive = (is_live && is_accessible && !external_congress) || 
                                (is_on_demand && is_accessible && !external_congress && multimedias.length <= 0);

          const agendaURLs = {
            ZOOM: `${LIVE_URL}/zoom/${slug}/${id}?encryptsKey=live-encrypts${userId}`,
            WEBEX: `${LIVE_URL}/webex/${slug}/${id}?encryptsKey=live-encrypts${userId}`,
            DEFAULT_LIVE: TranslationService.transRoute('event-detail-live', { slug, agendaSessionId: id }),
            DEFAULT_AGENDA: TranslationService.transRoute('event-agenda-detail', { slug, agendaSessionId: id })
          };

          let agendaURL = agendaURLs.DEFAULT_AGENDA;
          if (shouldOpenLive) {
            agendaURL = meeting_platform === 'ZOOM' && ENABLE_LIVE
              ? agendaURLs.ZOOM
              : meeting_platform === 'WEBEX' && ENABLE_LIVE
                ? agendaURLs.WEBEX
                : agendaURLs.DEFAULT_LIVE;
            this.saveActivity('live');
          } else {
            this.saveActivity('agenda');
          }
          GoogleAnalytics.sendOpenContentEvent(this.itemInfo.title, 'card');
          GoogleAnalytics.sendPromoClickEvent(this.itemInfo.title);
          window.open(agendaURL, '_blank').focus();
        };

        const behaviorActions = {
          'external link': openExternalLink,
          'open modal': openModal
        };

        const action = behaviorActions[cards_behaviour];
        if (action) action();
        
      },
      saveActivity(event) {
        let params = {
          title: this.title,
          model_type: this.type,
          model_id: this.id,
          congress_id: window.app.event ? window.app.event.id : null
        };
        this.activitySvc.saveActivity(event, params);
      },
      toggleDropDown(e) {
        e.target.classList.toggle("open");
        e.target.parentElement.nextElementSibling.classList.toggle("open");
        if(e.target.classList.contains("open")) {
          GoogleAnalytics.sendExpandContentEvent('speakersContainer');
        } else{
          GoogleAnalytics.sendCollapseContentEvent('speakersContainer');
        }
      },
      getAgendaItemInfo(item) {
        let info = '';
        if(item.position) info = item.position;
        if(item.position && item.institution) info = info + ' - ';
        if(item.institution) info = info + item.institution;
        return info;
      },
      parseImage(img) {
        if (img && img.charAt(0) != '/') {
          img = '/' + img;
        }
        return img;
      }
    }
  };
  </script>