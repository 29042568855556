import ProfileHeader from '../objects/v4/modules/profile/ProfileHeader.vue'
import Subscriptions from '../objects/v4/modules/profile/Subscriptions.vue'
import Bookmarks from '../objects/v4/modules/profile/Bookmarks.vue'
import Achievements from '../objects/v4/modules/profile/Achievements.vue'
import EditProfile from '../objects/v4/modules/profile/EditProfile.vue'
import AskQuestions from '../objects/v4/modules/profile/AskQuestions.vue'

const vueComponents = [
    ['ProfileHeader', ProfileHeader],
    ['Subscriptions', Subscriptions],
    ['Bookmarks', Bookmarks],
    ['Achievements', Achievements],
    ['EditProfile', EditProfile],
    ['AskQuestions', AskQuestions],
]

export default vueComponents