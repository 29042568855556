<template>
  <div class="row">
    <div class="col clinical-cases__tabsheet">
      <ul class="clinical-cases__tabsheet__list">
        <li
          @click="changeTabsheet($event, 'introduction', null)"
          data-tabsheet="tabsheet-1"
          class="clinical-cases__tabsheet__button"
          id="tabsheet-1-tab"
        >
        {{t('clinical-case.tabs.introduction')}}
          
        </li>
        <li
          @click="changeTabsheet($event, 'clinical case', null)"
          data-tabsheet="tabsheet-2"
          class="clinical-cases__tabsheet__button"
          id="tabsheet-2-tab"
        >
        {{t('clinical-case.tabs.clinical-case')}}

        </li>
        <li
          @click="changeTabsheet($event, 'supporting information', null)"
          data-tabsheet="tabsheet-3"
          class="clinical-cases__tabsheet__button"
          id="tabsheet-3-tab"
        >
        {{t('clinical-case.tabs.support')}}
        </li>
      </ul>
      <ClinicalCasesPresentation
        v-if="!!cc.description"
        :cc="cc"
        id="tabsheet-1"
        class="clinical-cases__tabsheet__container clinical-cases__tabsheet__container"
      ></ClinicalCasesPresentation>
      <ClinicalCasesContent
        ref="contentRef"
        :cc="cc"
        id="tabsheet-2"
        class="clinical-cases__tabsheet__container"
      ></ClinicalCasesContent>
      <ClinicalCasesSupporting
        v-if="!!si"
        :si="si"
        :cc="cc"
        id="tabsheet-3"
        class="clinical-cases__tabsheet__container"
        style="display: none"
      ></ClinicalCasesSupporting>
    </div>
  </div>
</template>

<script>
import ClinicalCasesPresentation from './ClinicalCasesPresentation.vue';
import ClinicalCasesContent from './ClinicalCasesContent.vue';
import ClinicalCasesSupporting from './ClinicalCasesSupporting.vue';
import TranslateService from '../../core/services/translate.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import $ from 'jquery';

export default {
  name: 'ClinicalCasesTabsheet',
  components: {
    ClinicalCasesPresentation,
    ClinicalCasesContent,
    ClinicalCasesSupporting
  },

  props: {
    cc: Object,
    si: Array
  },
  data() {
    return {
      translator: TranslateService.create()
    };
  },
  mounted() {
    this.selectInitialTab();
    document.addEventListener("goToContentTab", () => {
      this.changeTabsheet(null, 'content', '#tabsheet-2-tab');
    });
   /*  this.$root.$on('goToContentTab', percentage => {
      this.changeTabsheet(null, '#tabsheet-2-tab');
    }); */
  },

  methods: {
    changeTabsheet(e, menuName, forcedTab = null) {
      //Stop video if playing
      this.stopContentVideo();
      //Change tab
      let target = forcedTab ? $(forcedTab) : e.currentTarget;
      $('.clinical-cases__tabsheet__button').removeClass('clinical-cases__tabsheet__button--active');
      $('.clinical-cases__tabsheet__container').hide('fast');
      $('#' + $(target).data('tabsheet')).show('fast');
      $(target).addClass('clinical-cases__tabsheet__button--active');
      GoogleAnalytics.sendMenuNavEvent(menuName, 'menu clinical case');
    },
    selectInitialTab() {
      let selectedTab = `#tabsheet-${this.cc.tab_select}-tab`;
      this.changeTabsheet(null, null, selectedTab);
    },
    stopContentVideo() {
      if (this.$refs.contentRef && this.$refs.contentRef.videoJS) {
        this.$refs.contentRef.videoJS.pause();
      }
    },
    t(tag) {
      return this.translator.translate(tag);
    },
  }
};
</script>
