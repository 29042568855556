<template>
  <div v-if="Object.keys(un).length > 0" class="knowledge-journey__content__info col">
    <template v-if="un.results">
      <div class="knowledge-journey__content__test__container kj-test-form-js">
        <div class="knowledge-journey__content__test__container__carousel">
          <div
            v-for="(q, i) in un.results"
            :key="i"
            class="knowledge-journey__content__test__container__question kj-test-question-js test-question"
            :data-type="q.question_type"
          >
            <template v-if="q.question_type == 1">
              <div class="knowledge-journey__content__test__container__question__header">
                <span class="kj-badge kj-badge--lower kj-badge--outlined">Single question answer</span>
                <div class="knowledge-journey__content__test__container__question__arrows">
                  <i class="kj-test-prev-js bi bi-chevron-left"></i>
                  <p>
                    <span>{{ i + 1 }}</span> / {{ un.results.length }}
                  </p>
                  <i class="kj-test-next-js bi bi-chevron-right"></i>
                </div>
              </div>
              <div class="knowledge-journey__content__test__container__question__container">
                <p>{{ q.question }}</p>
                <div v-for="(a, j) in q.answers" :key="j" class="form-check">
                  <input
                    class="form-check-input answer"
                    :name="q.id"
                    type="radio"
                    :value="a.id"
                    :checked="a.checked"
                    style="pointer-events: none"
                  />
                  <label class="form-check-label" :for="q.id" :style="{ color: a.color }">
                    {{ a.answer }}
                    <em v-if="a.explanation" class="kj-answer-info bi-info-circle">
                      <div v-if="a.explanation" class="kj-answer-info-explanation" v-html="a.explanation">
                      </div>
                    </em>
                  </label>
                </div>
              </div>
            </template>
            <template v-else-if="q.question_type == 2">
              <div class="knowledge-journey__content__test__container__question__header">
                <span class="kj-badge kj-badge--lower kj-badge--outlined">Multi-answer question</span>
                <div class="knowledge-journey__content__test__container__question__arrows">
                  <i class="kj-test-prev-js bi bi-chevron-left"></i>
                  <p>
                    <span>{{ i + 1 }}</span> / {{ un.results.length }}
                  </p>
                  <i class="kj-test-next-js bi bi-chevron-right"></i>
                </div>
              </div>
              <div class="knowledge-journey__content__test__container__question__container">
                <p>{{ q.question }}</p>
                <div v-for="(a, j) in q.answers" :key="j" class="form-check">
                  <input
                    class="form-check-input answer"
                    :name="q.id"
                    type="checkbox"
                    :value="a.id"
                    :checked="a.checked"
                    style="pointer-events: none"
                  />
                  <label class="form-check-label" :for="q.id" :style="{ color: a.color }">
                    {{ a.answer }}
                    <em v-if="a.explanation" class="kj-answer-info bi-info-circle">
                      <div v-if="a.explanation" class="kj-answer-info-explanation" v-html="a.explanation" >
                      </div>
                    </em>
                  </label>
                </div>
              </div>
            </template>
            <template v-else-if="q.question_type == 5">
              <div class="knowledge-journey__content__test__container__question__header">
                <span class="kj-badge kj-badge--lower kj-badge--outlined">Fill de gap</span>
                <div class="knowledge-journey__content__test__container__question__arrows">
                  <i class="kj-test-prev-js bi bi-chevron-left"></i>
                  <p>
                    <span>{{ i + 1 }}</span> / {{ un.results.length }}
                  </p>
                  <i class="kj-test-next-js bi bi-chevron-right"></i>
                </div>
              </div>
              <div class="knowledge-journey__content__test__container__question__container fill-gap-js">
                <p v-html="q.fill_text"></p>
                <span v-for="(a, j) in q.answers" :key="j" :data-qid="q.id" :data-name="a.name" class="ftg-result">
                  <span style="color: green" v-if="a.correct">{{ a.correct }}</span>
                  <span style="color: red" v-if="a.wrong">/ {{ a.wrong }}</span>
                </span>
              </div>
            </template>
            <template v-else-if="q.question_type == 6">
              <div class="knowledge-journey__content__test__container__question__header">
                <span class="kj-badge kj-badge--lower kj-badge--outlined">Open question</span>
                <div class="knowledge-journey__content__test__container__question__arrows">
                  <i class="kj-test-prev-js bi bi-chevron-left"></i>
                  <p>
                    <span>{{ i + 1 }}</span> / {{ un.results.length }}
                  </p>
                  <i class="kj-test-next-js bi bi-chevron-right"></i>
                </div>
              </div>
              <div class="knowledge-journey__content__test__container__question__container">
                <p>{{ q.question }}</p>
                <textarea :name="q.id" placeholder="Enter your answer..."></textarea>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="!un.isPassed" class="d-flex flex-column position-relative">
        <div class="knowledge-journey__test__reminder">
          <div class="knowledge-journey__test__description">
            <p>{{t('knowledge-journey.test.can-repeat')}}</p>
            <a class="knowledge-journey__test__repeat" @click="_repeatTest()">{{t('knowledge-journey.test.try-again')}} <em class="bi bi-arrow-repeat"></em></a>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="un.questions">
      <form class="knowledge-journey__content__test__container kj-test-form-js" id="knowledge-journeys-form">
        <div class="knowledge-journey__content__test__container__carousel">
          <div
            v-for="(q, i) in un.questions"
            :key="i"
            class="knowledge-journey__content__test__container__question kj-test-question-js test-question"
            :data-type="q.question_type"
          >
            <template v-if="q.question_type == 1">
              <div class="knowledge-journey__content__test__container__question__header">
                <span class="kj-badge kj-badge--lower kj-badge--outlined">Single question answer</span>
                <div class="knowledge-journey__content__test__container__question__arrows">
                  <i class="kj-test-prev-js bi bi-chevron-left"></i>
                  <p>
                    <span>{{ i + 1 }}</span> / {{ un.questions.length }}
                  </p>
                  <i class="kj-test-next-js bi bi-chevron-right"></i>
                </div>
              </div>
              <div class="knowledge-journey__content__test__container__question__container">
                <p>{{ q.question }}</p>
                <div v-for="(a, j) in q.answers" :key="j" class="form-check">
                  <input required class="form-check-input answer" :name="q.id" type="radio" :value="a.id" :id="`kj-input-${a.id}`" />
                  <label class="form-check-label" :for="`kj-input-${a.id}`">
                    {{ a.answer }}
                  </label>
                </div>
              </div>
            </template>
            <template v-else-if="q.question_type == 2">
              <div class="knowledge-journey__content__test__container__question__header">
                <span class="kj-badge kj-badge--lower kj-badge--outlined">Multi-answer question</span>
                <div class="knowledge-journey__content__test__container__question__arrows">
                  <i class="kj-test-prev-js bi bi-chevron-left"></i>
                  <p>
                    <span>{{ i + 1 }}</span> / {{ un.questions.length }}
                  </p>
                  <i class="kj-test-next-js bi bi-chevron-right"></i>
                </div>
              </div>
              <div class="knowledge-journey__content__test__container__question__container">
                <p>{{ q.question }}</p>
                <div v-for="(a, j) in q.answers" :key="j" class="form-check">
                  <input required class="form-check-input answer" :name="q.id" type="checkbox" :value="a.id" :id="`kj-input-${a.id}`"/>
                  <label class="form-check-label" :for="`kj-input-${a.id}`">
                    {{ a.answer }}
                  </label>
                </div>
              </div>
            </template>
            <template v-else-if="q.question_type == 5">
              <div class="knowledge-journey__content__test__container__question__header">
                <span class="kj-badge kj-badge--lower kj-badge--outlined">Fill de gap</span>
                <div class="knowledge-journey__content__test__container__question__arrows">
                  <i class="kj-test-prev-js bi bi-chevron-left"></i>
                  <p>
                    <span>{{ i + 1 }}</span> / {{ un.questions.length }}
                  </p>
                  <i class="kj-test-next-js bi bi-chevron-right"></i>
                </div>
              </div>
              <div class="knowledge-journey__content__test__container__question__container fill-gap-js">
                <p v-html="q.fill_text"></p>
                <select
                  required
                  v-for="(a, j) in q.answers"
                  :data-name="q.id"
                  :key="j"
                  :name="j"
                  class="kj-test-select form-control selectpicker answer"
                >
                  <option v-for="(b, k) in a" :key="k" :value="b.id">{{ b.answer }}</option>
                </select>
              </div>
            </template>
            <template v-else-if="q.question_type == 6">
              <div class="knowledge-journey__content__test__container__question__header">
                <span class="kj-badge kj-badge--lower kj-badge--outlined">Open question</span>
                <div class="knowledge-journey__content__test__container__question__arrows">
                  <i class="kj-test-prev-js bi bi-chevron-left"></i>
                  <p>
                    <span>{{ i + 1 }}</span> / {{ un.questions.length }}
                  </p>
                  <i class="kj-test-next-js bi bi-chevron-right"></i>
                </div>
              </div>
              <div class="knowledge-journey__content__test__container__question__container">
                <p>{{ q.question }}</p>
                <textarea required :name="q.id" class="answer" placeholder="Enter your answer..."></textarea>
              </div>
            </template>
          </div>
        </div>
      </form>
      <div class="d-flex flex-column position-relative">
        <button type="button" @click="handleSubmitEvent" class="knowledge-journey__test__send ml-auto v4-btn-primary kj-test-send-js" form="knowledge-journeys-form" disabled>
          <p>{{ t('general.submit') }}</p>
        </button>
      </div>
    </template>
    <template v-else>
      <p class="d-flex" style="display: none !important;">
        <span v-if="this.$parent.kjObj.chapters.length > 1" class="kj-badge">chapter {{ un.chapter.sort_order }}</span>
      </p>
      <p class="knowledge-journey__content__info__title__chapter">{{ un.chapter.title }}</p>
      <p class="knowledge-journey__content__info__title__unit">{{ un.title }}</p>
      <div v-if="this.media && !isFile" id="video-container">
        <div id="brightPlayer"></div>
      </div>
      <DocumentViewer
        v-else-if="this.media"
        :id="this.media.id"
        :fileuri="getDocumentFileURL()"
      >
      </DocumentViewer>
    </template>
    <ConfirmDialogue ref="confirmDialogue"></ConfirmDialogue>
  </div>
</template>

<script>
import brightcovePlayerLoader from '@brightcove/player-loader';
import formUtils from '../../core/services/formUtils.service';
import DocumentViewer from '../multimedia/DocumentViewer.vue';
import ConfirmDialogue from '../agenda/ConfirmDialogue.vue';
import axios from 'axios';
import $ from 'jquery';
import TranslateService from '../../core/services/translate.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
  name: 'KnowledgeJourneyUnit',
  components: {
    DocumentViewer,
    ConfirmDialogue
  },
  props: {
    un: Object
  },

  data() {
    return {
      media: null,
      isFile: false,
      filePath: '',
      videoJS: null,
      questionText: '',
      questionNumber: '',
      quizAnswer: '',
      selectors: {
        chapter: 'knowledge-journey__content__chapters__container',
        test: 'knowledge-journey__content__test__container__carousel',
        testSelect: 'kj-test-select',
        testForm: 'kj-test-form-js',
        testPrev: 'kj-test-prev-js',
        testNext: 'kj-test-next-js',
        testSend: 'kj-test-send-js',
        answer: '.answer',
        question: '.test-question',
        ftgResult: '.ftg-result'
      },
      translator: TranslateService.create()
    };
  },

  created() {
    if (Object.keys(this.un).length) {
      if (!this.un.questions && !this.un.results) {
        this.media = this.un.multimedia;
      }
    }
  },

  mounted() {
    if (Object.keys(this.un).length) {
      if (!this.un.questions && !this.un.results && this.media) {
        if ((this.media.multimedia_type_id == 4 || this.media.multimedia_type_id == 5)
          && this.media.accountId && this.media.videoId && this.media.playerId && this.media.embedId) {
          this.initBrightCove(this.media.accountId, this.media.videoId, this.media.playerId, this.media.embedId, this.media.id);
          this.blockVideoSeeking();
        } else {
          this.isFile = true;
          this.filePath = document.location.protocol + '//' + document.location.host + '/' + this.media.file;
          this.initOnDocumentEnd();
        }
      } else {
        this.initTestContent();
      }
      if(this.un.results) {
        this.formatFillTheGapResult();
      }
    }
  },

  methods: {
    handleSubmitEvent() {
      this.questionText = document.querySelector('.knowledge-journey__content__test__container__question__container p').textContent;
      this.questionNumber = document.querySelector('.knowledge-journey__content__test__container__question__arrows span').textContent;
      const questionTypeText = document.querySelector('.kj-badge--lower').textContent.trim();
      this.quizAnswer = this.getUserAnswer(questionTypeText);
    },
    getUserAnswer(questionType) {
      if (questionType === 'Single question answer' || questionType === 'Multi-answer question') {
        const radioInputs = document.querySelectorAll('.knowledge-journey__content__test__container__question__container input[type="radio"]:checked');
        let selectedAnswers = [];
        radioInputs.forEach(input => {
          if (input.checked){
            selectedAnswers.push(input.nextElementSibling.textContent.trim());
          }
        });
        return selectedAnswers.join(', ');
      } else if (questionType === 'Fill de gap') {
        return document.querySelector('.kj-test-select').value;
      } else{
        return document.querySelector('.knowledge-journey__content__test__container__question__container textarea').value;
      }
    },
    correctAnswer(isPassed){
      return isPassed ? 'Congratulations' : 'Test Not Passed';
    },
    _repeatTest() {
      this.$parent.getTestQuestions(this.un.type_id, this.un.type, this.un.type == 'kj');
    },

    initTestContent() {
      let self = this;

      $('.' + this.selectors.test).slick({
        infinite: false,
        arrows: false,
        dots: false,
        fade: true
      });

      $('.' + this.selectors.testPrev).click(function () {
        $('.' + self.selectors.test).slick('slickPrev');
      });

      $('.' + this.selectors.testNext).click(function () {
        $('.' + self.selectors.test).slick('slickNext');
      });

      $('.' + this.selectors.testSend).click(function (e) {
        const url = '/kj/sendtest';
        // we get the formData form the FormUtils
        const formData = formUtils.getFormData($('.' + self.selectors.testForm));

        // we append specific data for this form
        formData.append('kjTestID', self.un.id);
        formData.append('type', self.un.type);
        formData.append('type_id', self.un.type_id);
        formData.append('kjId', self.$parent.kjObj.id);
        axios
          .post(url, formData)
          .then((response) => {
            let unit = response.data;
            self.$parent.showResults(unit);
            GoogleAnalytics.sendSubmitAnswerEvent(self.questionNumber, unit.currentTitle, self.questionText, self.quizAnswer, self.correctAnswer(unit.isPassed));
            GoogleAnalytics.sendQuizStepEvent(self.$parent.quizStep, unit.currentTitle);
            document.dispatchEvent(new CustomEvent("updateKJPercentage", { detail: Math.trunc(response.data.kj.progress) }));                    
            // self.$root.$emit('updateKJPercentage', Math.trunc(response.data.kj.progress));
            //Show certificate buttons
            let showCertificate = response.data.kj && response.data.kj.can_give_diploma && response.data.kj.progress == 100;
            if(showCertificate) {
                document.dispatchEvent(new CustomEvent("showDiplomaButton"));
                // this.$root.$emit('showDiplomaButton');
                self.$parent.showDiploma = true;
            }
          })
          .catch((e) => {
            console.debug('ERR', e);
          });
      });

      if (this.un.questions) {
        let that = this;
        $('.' + this.selectors.testSelect).each(function () {
          $(this).prepend(
            $('<option>', {
                value: null,
                text: that.t('form.choose_answer')
            })
          );
          let selectName = $(this).attr('name');
          $(this).attr('name', $(this).data('name'));
          $(this)
            .prev()
            .html(
              $(this)
                .prev()
                .html()
                .replace('#' + selectName, $(this)[0].outerHTML)
            );
          $(this).remove();
        });
      }

      $('.selectpicker').selectpicker();

      $('body').on('change keyup', this.selectors.answer, (e) => this.refreshAnsweredQuestions());
    },

    formatFillTheGapResult() {
      if (this.un.results) {
        $(this.selectors.ftgResult).each(function () {
          let questionName = $(this).attr('data-name');
          $(this)
            .prev()
            .html(
              $(this)
                .prev()
                .html()
                .replace('#' + questionName, $(this)[0].outerHTML)
            );
          $(this).remove();
        });
      }
    },

    initBrightCove(accountId, videoId, playerId, embedId) {
      const player = brightcovePlayerLoader({
        refNode: '#brightPlayer',
        accountId,
        playerId: playerId,
        embedId: embedId,
        videoId
      });

      player.catch((err) => {
        console.warn(err);
      });

      //End of video
      let self = this;
      player.then((res) => {
        self.videoJS = res.ref;
        self.videoJS.on('ended', function () {
          self.finishUnit();
        });
      });
    },

    t(tag) {
      return this.translator.translate(tag);
    },

    refreshAnsweredQuestions(e) {
      let answeredQuestions = 0;
      let questions = $(this.selectors.question).toArray();

      questions.forEach((question) => {
        let isAnswered;
        if ($(question).attr('data-type') == '6') {
          isAnswered = $(question).find('textarea').val().length > 0 ? true : false;
        } else if ($(question).attr('data-type') == '5') {
          isAnswered = $(question).find('select').length
            ? $(question).find('select').length == $(question).find("select option:selected[value]").length
            : false;
        } else {
          let answers = $(question).find(this.selectors.answer).toArray();
          isAnswered = answers.some((answer) => {
            return answer.hasAttribute('data-linked') || answer.checked;
          });
        }

        if (isAnswered) {
          answeredQuestions++;
        }
      });

      if (answeredQuestions >= questions.length) {
        $('.' + this.selectors.testSend).removeAttr('disabled');
      }
      document.dispatchEvent(new CustomEvent("changeAnsweredQuestions", { detail: answeredQuestions }));
      // this.$root.$emit('changeAnsweredQuestions', answeredQuestions);
    },

    blockVideoSeeking() {
      if(this.$parent.kjObj.lineal) {
        const styleData = document.createElement('style');
        styleData.setAttribute('class', 'vjs-blocker');
        styleData.innerHTML = `
              .vjs-progress-control{
                pointer-events: none;
              }
              .vjs-play-control.vjs-paused.vjs-ended {
                pointer-events: none;
              }
              `;
        document.querySelector('head').appendChild(styleData);
      }
    },

    initOnDocumentEnd() {
      window['kjDocEnd'] = (id) => {
        this.finishUnit();
      };
    },

    finishUnit() {
      let self = this;
      let kjId = this.un.kj_id;
      let unitId = this.un.id;

      const url = '/kj/finishunit';
      const fd = new FormData();
      fd.append('kjId', kjId);
      fd.append('kjUnit', unitId);

      axios
        .post(url, fd)
        .then((response) => {
          // self.$parent.kjObj = response.data.kj;
          self.$parent.enabledTest = response.data.kj.progress == 100;
          document.dispatchEvent(new CustomEvent("updateKJPercentage", { detail: Math.trunc(response.data.kj.progress) }));                    
          // self.$root.$emit('updateKJPercentage', Math.trunc(response.data.kj.progress));
          //Show certificate buttons
          let showCertificate = response.data.kj && response.data.kj.can_give_diploma && response.data.kj.progress == 100;
          if(showCertificate) {
              document.dispatchEvent(new CustomEvent("showDiplomaButton"));
              // this.$root.$emit('showDiplomaButton');
              self.$parent.showDiploma = true;
          }
        })
        .catch((e) => {
          console.debug('ERR', e);
        });
    },

    getDocumentFileURL() {
      let downloadableParam = this.media.downloadable ? '&disableDownload=false' : '&disableDownload=true';
      let requestableParam = this.media.requestable && !this.media.downloadable ? '&disableRequest=false' : '&disableRequest=true';
        
      let fileUri = `/pdfjs/web/viewer.html?file=${this.filePath}?search=${downloadableParam}${requestableParam}`;
      if(this.media.requestable) {
        fileUri = `${fileUri}&reqCallback=requestMultimediaAction(${this.media.id})`;
      }
      
      return `${fileUri}&callback=kjDocEnd(${this.media.id})&resizeControl=true`;
    }
  }
};
</script>
