import axios from "axios";

export default {
    async search(requestData) {
        const defaultRequestData = {
            url: window.location.href
        };

        requestData = {
            ...requestData,
            ...defaultRequestData
        };
        const response = await axios.post('/v4/query', requestData);
        return response.data;
    }
};