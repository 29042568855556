import { defineStore } from "pinia";
import axios from "axios";
import moment from "moment";

export const useVotingStore = defineStore("voting", {
  state: () => ({
    votes: [],
    startDate: "",
    endDate: "",
    currentTime: moment.utc().toISOString(),
  }),
  getters: {
    votingState(state) {
      const current = moment.utc(state.currentTime);
      const start = state.startDate ? moment.utc(state.startDate) : null;
      const end = state.endDate ? moment.utc(state.endDate) : null;
      if (start && current.isSameOrBefore(start)) return "countdown";
      if (start && end && current.isBetween(start, end)) return "active";
      if (end && current.isSameOrAfter(end)) return "expired";
      return null;
    },

    countdownTime(state) {
      const current = moment.utc(state.currentTime);
      var deadline = null;

      if (state.votingState === "countdown") {
        deadline = state.startDate ? moment.utc(state.startDate) : null;
      } else if (state.votingState === "active") {
        deadline = state.endDate ? moment.utc(state.endDate) : null;
      }

      if (deadline) {
        const diff = moment.duration(deadline.diff(current));
        return {
          diffDays: diff.days(),
          diffHours: diff.hours(),
          diffMinutes: diff.minutes(),
          diffSeconds: diff.seconds(),
        };
      }
      return null;
    },
  },

  actions: {
    startClock() {
      setInterval(() => {
        this.currentTime = moment.utc().toISOString();
      }, 1000);
    },
    async vote(multimediaId, points, congressId) {
      try {
        await axios.post("/congresses/people-choice/vote", {
          multimediaId: multimediaId,
          votingPoint: points,
          congressId: congressId,
        });
        this.votes.push({ confirmed: 0, multimedia_id: multimediaId, voting_points: points });
      } catch (error) {
        console.error("Error al votar:", error);
      }
    },

    async unvote(multimediaId, congressId) {
      try {
        await axios.post("/congresses/people-choice/unvote", {
          multimediaId: multimediaId,
          congressId: congressId,
        });

        this.votes = this.votes.filter((vote) => vote.multimedia_id !== multimediaId);
      } catch (error) {
        console.error("Error al votar:", error);
      }
    },
    async submitVotes(congressId) {
      await axios.post("/congresses/people-choice/confirm-votes", {
        congressId: congressId,
      });
      this.votes = this.votes.map((vote) => ({
        ...vote,
        confirmed: 1,
      }));
    },
  },
});
