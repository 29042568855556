<template>
    <div class="box-padd-new">
        <div class="text-answer">
            <form action="javascript:void(0);" ref="quesForm" @submit="showSubmit" method="post" class="modal-questions-form" :data-poll-id="id" :data-poll-type="type" :data-poll-expiration="expiration_time" :id="'quesform_' + id">
                <input type="hidden" name="pollId" class="questiontextid" ref="answer" :value="id">
                <input type="hidden" name="type" id="questiontype" ref="answertype" :value="type">
                <textarea placeholder="Enter your answer..." name="answer" id="answer" v-model="answer"></textarea>

            </form>
        </div>
    </div>
</template>
<script>
import LivePollsController from "../../../v4/modules/live/LivePollsController"
import $ from 'jquery';

export default {
    props: {
        id : Number,
        type: Number,
        question : String,
        expiration_time : Number,
        expiration_timeout:Number
    },
    data:function(){
        return{
            answer: "",
        }
    },
    mounted() {
        let id = '#quesform_' + this.id;
        $(document).on('submit', id.toString() , ev => this.showSubmit(ev));
    },
    methods: {
        async showSubmit(event){
            console.log(900)
            event.preventDefault();
            event.stopPropagation();
            if ( this.answer == '' ) {
                LivePollsController.displayToastifyMessage(2 , "Error" , "Answer is required")
            } else {
                var response = await LivePollsController.setAnswerPollFunc(event);
                if ( response.data.status == 201 ) {
                    LivePollsController.displayToastifyMessage(2 , "Error" , response.data.message)
                } else if ( response.data.status == 200 ) {
                    this.popupQuesType()
                }
            }

        },
        popupQuesType(){
            this.$parent.$parent.closePopup(this.id);
        }
    }
}
</script>
