<template>
    <div class="ml-auto" :style="styles">
        <button @click='toggleShow' class='anchor' :class="{active: showMenu}">
			<slot name="icon"></slot>
			{{ defaultLabel }}

			<i class="bi bi-chevron-down ml-3 dd-arrow"></i>
		</button>
        <div v-if='showMenu' class='menu' :style="{width: styles.width}">
            <div class='menu-item' v-for="(item, index) in items" v-bind:key="index" @click='itemClicked(item, index)'>{{item}}</div>
        </div>
    </div>
</template>

<script>
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
    props: {
		items: Array,
		defaultLabel: String,
		containerWidth: String,
    containerFloat: String
	},
	data() {
		return {	
			styles: {
				width: this.containerWidth ?? '150px',
				float: this.containerFloat ?? 'none'
			},
			showMenu: false,
		}
	},

    methods: {
		toggleShow: function () {
			this.showMenu = !this.showMenu;
		},
		itemClicked: function (item, index) {
			this.toggleShow();
			if (index === 0) {
				GoogleAnalytics.sendMenuNavEvent(item, 'header');
			} else if (index === 1) {
				GoogleAnalytics.sendLogoutEvent(window.user.id.toString());
			}
			this.$emit('clicked', item);
		},
	}
}
</script>
<style scoped>
.dd-arrow {
	position: relative;
	top: 2px;
	transition: all 0.3s linear;
}
.anchor.active .dd-arrow {
	transform: rotate(180deg);
	top: -4px;
}

.anchor {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
    border: 1px solid transparent;
    padding: .75rem 0;
    font-size: 0.9rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	color: #fff;
    background-color: transparent;
}

.menu {
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: .25rem;
	color: #212529;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 0.9rem;
	list-style: none;
	padding: .5rem 0;
	position: absolute;
	right: 10px;
	width: 120px;
	text-align: left;
	z-index: 9999;
}

.menu-item {
	color: #212529;
	padding: .25rem 1.5rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

span {
	font-weight: bold;
	color: #229954;
	font-size: 1.25rem;
}
</style>
