<template>
  <div>
    <div class="speakers-card-standard">
      <div v-if="isStandardEllipse()" class="speakers-card-standard__ellipse1"></div>
      <div v-if="isStandardEllipse()" class="speakers-card-standard__ellipse2"></div>
      <div class="speakers-card-standard__img">
        <img :src="parseImage(itemInfo.photo)" @error="imageUrlAlt" alt="" v-bind:class="{ image_black_white: isStandardEllipse()}">
      </div>
      <div class="speakers-card-standard__name" v-bind:class="{ name_standard_ellipse: isStandardEllipse()}">
        <h4 class="limit-2-rows">{{itemInfo.name+' '+itemInfo.surname}}</h4>
        <p class="limit-3-rows">{{getSpeakerItemInfo(itemInfo)}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../../core/services/translate.service';

export default {
  name: 'SpeakersCardStandard',
  data() {
    return {
      translator: TranslateService.create(),
    };
  },
  props: {
    cardType: String,
    itemInfo: Object,
  },

  mounted() {
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    imageUrlAlt(event) {
      event.target.src = "/images/v4/assets/avatar.svg";
    },
    getSpeakerItemInfo(item) {
      let info = '';
      if(item.position) info = item.position;
      if(item.position && item.institution) info = info + ' - ';
      if(item.institution) info = info + item.institution;
      return info;
    },
    parseImage(img) {
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    isStandardEllipse(){
      return this.cardType == 'standard_ellipse';
    }
  }
};
</script>
