<template>
    <section class="v4-satellital-tabs-container" :key="renderKey">
        <div class="tabs">
          <span class="tab secondary-color text-bold" v-for="(tab, index) of tabs" :key="index" v-bind:class="{ 'active': index === active }" @click="onIndexChange(index)">
            {{tab.title}}
          </span>
          <div class="tabs-content">
            <div class="tab-content" v-for="(tab, index) of tabs" :hidden="index !== active" :key="index">
              <!-- view-height-limit -->
              <div v-if="tab.type==2" class="tab-inner-content" v-bind:class="{'view-height-limit': !isOpenTab[index] && (!!tabHeights[index] && tabHeights[index] > 200) }">
                <SatellitalCongressAgenda :slug="slug" :tabid="index" :active="active"></SatellitalCongressAgenda>
              </div>
              <div v-else-if="tab.type==3" class="tab-inner-content" v-bind:class="{'view-height-limit': !isOpenTab[index] && (!!tabHeights[index] && tabHeights[index] > 200) }">
                <SatellitalCongressSpeakers :slug="slug" :tabid="index" :active="active"></SatellitalCongressSpeakers>
              </div>
              <div v-else-if="tab.type==4" class="tab-inner-content" v-bind:class="{'view-height-limit': !isOpenTab[index] && (!!tabHeights[index] && tabHeights[index] > 200) }">
                <satellital-congress-steering-committee :slug="slug" :tabid="index" :active="active" :congressId="congressId"></satellital-congress-steering-committee>
              </div> 
              <div v-else class="tab-inner-content" v-html="tab.description" v-bind:class="{'view-height-limit': !isOpenTab[index] && (!!tabHeights[index] && tabHeights[index] > 200 && !tab.hide_read_more) }"></div>
              <div class="view-more" @click="toggleOpenTabState(index)" v-if="(!!tabHeights[index] && tabHeights[index] > 200 && !tab.hide_read_more) ">
                <template v-if="!isOpenTab[index]">{{t('congress.read-more')}}</template>
                <template v-if="!!isOpenTab[index]">{{t('congress.read-less')}}</template>
              </div>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service.js';
import SatellitalCongressAgenda from './components/SatellitalCongressAgenda.vue'
import SatellitalCongressSpeakers from './components/SatellitalCongressSpeakers.vue'
import SatellitalCongressSteeringCommittee from './components/SatellitalCongressSteeringCommittee.vue'

export default {
    props: {
      spa:Boolean,
      tabs:Array,
      slug: String,
      congressId: Number,
    },
    components: {
      SatellitalCongressAgenda,
      SatellitalCongressSpeakers,
      SatellitalCongressSteeringCommittee
    },
    data() {
      return {
        translator: TranslateService.create(),
        active: 0,
        renderKey: 0,
        tabHeights: {},
        isOpenTab: {},
      }
    },

    mounted() {
      const tabs = document.querySelectorAll(".tab-content");
      tabs.forEach((tab, index) => {
        if (!tab.hasAttribute("hidden")) {
          this.tabHeights[index] = tab.clientHeight;
        } else {
          tab.removeAttribute("hidden");
          tab.setAttribute("style","height: 0; overflow:hidden");
          this.tabHeights[index] = tab.scrollHeight;
          tab.setAttribute("hidden", "true");
          tab.setAttribute("style", "");
        }
      });
      this.renderKey++;
    },

    methods: {
      t(tag){
        return this.translator.translate(tag)
      },
      toggleOpenTabState(index) {
        this.isOpenTab[index] = !this.isOpenTab[index];
        this.renderKey++;
      },
      onIndexChange(index) {
        this.active = index;
        this.renderKey++;
      },
    }
}
</script>
