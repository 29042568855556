<template>
    <div>
        <div class="not-found-icon">
            <img :class="{run: shouldShow}" src="/images/v4/bola-rotando.svg" alt="">
        </div>
        <div class="results-not-found text-center my-3">
            <h2>{{t('new-home.search.ouch')}}</h2>
            <p style="max-width: 400px; margin: auto;"><span>{{t('home.grid.no-results-but')}}</span> <span v-if="showRelated">{{t('home.grid.no-results-but-2')}}</span></p>
        </div>
    </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
    export default {
        props: {
            showRelated: Boolean
        },
        data() {
            return {
                shouldShow: false,
                translator : TranslateService.create()
            }
        },

        mounted() {
            setTimeout(() => {
                this.shouldShow = true;
            }, 1000);
        },

        methods: {
            t(tag){
                return this.translator.translate(tag)
            },
        }
    }
</script>
