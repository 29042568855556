<template>
    <div class="container-ask-question">
        <table class="table-ask-question">
            <thead>
                <tr>
                    <th class="text-left">Question</th>
                    <th class="text-left">Content Title</th>
                    <th class="text-left">Associated Event</th>
                    <th class="text-left">Date</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in askQuestionList2"
                    :key="item.id"
                >
                    <td><span v-html="item.question"></span></td>
                    <td><span v-html="item.content_title"></span></td>
                    <td><span v-html="item.event_name"></span></td>
                    <td><span> {{ item.created_at }} </span></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { forEach } from 'lodash';
import TranslateService from '../../core/services/translate.service.js';


export default {
    name:"tableQuestion",
    props:{
        askQuestionList: Object,
    },
    data() {
        return {
            translator : TranslateService.create(),
            askQuestionList2: Object,
        }
    },
    mounted(){
        this.askQuestionList2 = this.askQuestionsInfo();
    },
    methods: {
        t(tag){
            return this.translator.translate(tag)
        },

        askQuestionsInfo(){
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }

            this.askQuestionList.forEach((element)=> {
                var date = (new Date(element.created_at)).toLocaleDateString(undefined, options);
                var eventName = element.event_name ?? 'None';
                element.created_at = date;
                element.event_name = eventName;
            });

            return this.askQuestionList;
        }
    }
}
</script>