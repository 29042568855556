<template>
  <transition name="modal">
    <div class="event-register-modal-mask" @click="close($event)">
      <div class="event-register-modal-wrapper">
        <div class="event-register-modal-container">

          <i class="bi bi-x-lg event-register-modal-close" @click="close($event)"></i>

          <div class="event-register-modal-header">
            <i class="bi bi-calendar-check"></i>
            <p>You have successfully registered!</p>
          </div>

          <div class="event-register-modal-body">
            <p>We are pleased to inform you that your registration for the session was successful. To ensure that you don't miss the session, you can add the event to your calendar by clicking on the "Add to Calendar" button below.</p>
            <p>Should you require any assistance or have any questions, please do not hesitate to contact us at <a>support.agency@medicalmeetingpoint.com</a>. Our tem will be happy to help.</p>
          </div>

          <div class="event-register-modal-footer">            
            <button title="Add to Calendar" class="addeventatc" @click="registerLogAddToCalendar">
              <span>Add to Calendar</span>
              <i class="bi bi-chevron-down"></i>
              <span class="start">{{ start }}</span>
              <span class="end">{{ end }}</span>
              <span class="timezone">{{ timezone }}</span>
              <span class="title">{{ title }}</span>
              <span class="description">{{ plainTextDescription }}</span>
              <span class="location">{{ location }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

import ActivityService from '../../core/services/activity.service';

export default {
  props: ['start', 'end', 'timezone', 'title', 'description', 'plainTextDescription', 'location', 'agendaSessionId'],

  data() {
    return {
      activitySvc: ActivityService.create(),
    }
  },

  mounted() {
    
  },

  methods: {
    close(e) {
      if (e.target.classList.contains('event-register-modal-close') || e.target.classList.contains('event-register-modal-mask')) {
        this.$emit('close');
      }
    },
    registerLogAddToCalendar() {
      this.saveActivity('agenda-add-to-calendar');
    },
    saveActivity(event) {
      let params = {
        title: this.title,
        model_type: 'agenda_session',
        model_id: this.agendaSessionId,
        congress_id: window.app.event ? window.app.event.id : null
      };
      this.activitySvc.saveActivity(event, params);
    },
  }
}
</script>