import SurveyLiveQuestion from '../objects/v4/modules/survey/SurveyLiveQuestion.vue';
import SurveyLiveSingle from '../objects/v4/modules/survey/SurveyLiveSingle.vue';
import SurveyLiveMultiple from '../objects/v4/modules/survey/SurveyLiveMultiple.vue';
import SurveyLiveRadio from '../objects/v4/modules/survey/SurveyLiveRadio.vue';
import SurveyLiveFillGaps from '../objects/v4/modules/survey/SurveyLiveFillgaps.vue';
import SurveyLiveMatch from '../objects/v4/modules/survey/SurveyLiveMatch.vue';
import SurveyModalComponent from '../objects/v4/modules/congresslive/SurveyModalComponent.vue';
import LiveSurveyPopup from '../objects/v4/modules/survey/LiveSurveyPopup.vue';

import Moderator from '../objects/v4/modules/moderator/moderator.vue';
import ModeratorDetails from '../objects/v4/modules/moderator/moderatorDetails.vue';
import ModeratorAudience from '../objects/v4/modules/moderator/moderatorAudience.vue';
import ModeratorPonent from '../objects/v4/modules/moderator/moderatorPonent.vue';
import ModeratorVote from '../objects/v4/modules/moderator/moderatorVote.vue';
import ModeratorPosts from '../objects/v4/modules/moderator/moderatorPosts.vue';

import CongressLive from '../objects/v4/modules/congresslive/CongressLive.vue';
import CongressLiveMedia from '../objects/v4/modules/congresslive/CongressLiveMedia.vue';
import CongressLiveFloatingMenu from '../objects/v4/modules/congresslive/CongressLiveFloatingMenu.vue';
import CongressLiveContentSpeaker from '../objects/v4/modules/congresslive/CongressLiveContentSpeaker.vue';
import CongressLiveVoting from '../objects/v4/modules/congresslive/CongressLiveVoting.vue';
import CongressLiveVotingPopup from '../objects/v4/modules/congresslive/CongressLiveVotingPopup.vue';
import CongressLiveNotes from '../objects/v4/modules/congresslive/CongressLiveNotes.vue';
import CongressLiveQuesAns from '../objects/v4/modules/congresslive/CongressLiveQuesAns.vue';
import CongressLiveHelp from '../objects/v4/modules/congresslive/CongressLiveHelp.vue';
import CongressLiveFinishedSession from '../objects/v4/modules/congresslive/CongressLiveFinishedSession.vue';
import CongressLivePosts from '../objects/v4/modules/congresslive/CongressLivePosts.vue';
import LiveModalComponent from '../objects/v4/modules/congresslive/LiveModalComponent.vue';
import TopSession from '../objects/v4/modules/moderator/topSession.vue';

import AuroraEffect from '../objects/v4/modules/connectmi2022/components/AuroraEffect.vue';
import LanguageSelect from '../objects/v4/modules/select-language/LanguageSelect.vue';
import ProfileDropdown from '../objects/v4/modules/profile-dropdown/ProfileDropdown.vue';
import Dropdown from '../objects/v4/modules/dropdown/Dropdown.vue';
import CustomStaticHeader from '../objects/v4/modules/events/event-internal/CustomStaticHeader.vue';
import StickyHeader from '../objects/v4/modules/header/StickyHeader.vue';
import Modal from '../../../nova-components/NovaCkeditor/resources/js/components/modal.vue';
import SpeakerModal from '../objects/v4/modules/modals/SpeakerModal.vue';
import PopupModal from '../objects/v4/modules/agenda/PopupModal.vue';
import Webex from '../objects/v4/modules/congresslive/Webex.vue';
import WebexParticipants from '../objects/v4/modules/congresslive/WebexParticipants.vue';

const vueComponents = [
   ['survey-live-question', SurveyLiveQuestion],
   ['survey-live-single', SurveyLiveSingle],
   ['survey-live-multiple', SurveyLiveMultiple],
   ['survey-live-radio', SurveyLiveRadio],
   ['survey-live-fill-gaps', SurveyLiveFillGaps],
   ['survey-live-match', SurveyLiveMatch],
   ['survey-modal-component', SurveyModalComponent],

   ['moderator', Moderator],
   ['moderator-details', ModeratorDetails],
   ['moderator-audience', ModeratorAudience],
   ['moderator-ponent', ModeratorPonent],
   ['moderator-vote', ModeratorVote],
   ['moderator-posts', ModeratorPosts],

   ['congress-live', CongressLive],
   ['congress-live-media', CongressLiveMedia],
   ['congress-live-floating-menu', CongressLiveFloatingMenu],
   ['congress-live-content-speaker', CongressLiveContentSpeaker],
   ['congress-live-voting', CongressLiveVoting],
   ['congress-live-voting-popup', CongressLiveVotingPopup],
   ['congress-live-notes', CongressLiveNotes],
   ['congress-live-ques-ans', CongressLiveQuesAns],
   ['congress-live-help', CongressLiveHelp],
   ['congress-live-finished-session', CongressLiveFinishedSession],
   ['congress-live-posts', CongressLivePosts],
   ['live-survey-popup', LiveSurveyPopup],
   ['live-modal-component', LiveModalComponent],
   ['top-session', TopSession],

   ['aurora-effect', AuroraEffect],
   ['language-select', LanguageSelect],
   ['profile-dropdown', ProfileDropdown],
   ['dropdown', Dropdown],
   ['custom-static-header', CustomStaticHeader],
   ['sticky-header', StickyHeader],
   ['modal', Modal],
   ['speaker-modal', SpeakerModal],
   ['popup-modal', PopupModal],
   ['webex', Webex],
   ['webex-participants', WebexParticipants],
]

export default vueComponents
