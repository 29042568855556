<template>
  <div class="connectmi-event__modal--blur d-none">
    <div class="connectmi-event__modal--blur__wrapper">
      <img
        :src="createImagePath('icon-close.svg')"
        class="connectmi-event__modal--blur__close"
        v-on:click="closeHandler()"
        alt="close-icon"
      />
      <div class="connectmi-event__modal--blur__speaker">
        <div
          class="connectmi-event__speaker-card__image__container connectmi-event__speaker-card__image--variant-3 mr-5"
        >
          <img class="connectmi-event__speaker-card__image" :src="this.getSpeakerImage(this.image)" alt="speaker" />
        </div>
        <div class="w-100">
          <p class="mb-1 text text--lg">{{ this.name }}</p>
          <p class="mb-3 text text--nm text--italic">{{ this.job }}</p>
          <p class="text text--sm text--bold">{{ this.bio }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imagePath: String,
    image: String,
    name: String,
    job: String,
    bio: String
  },

  mounted() {
    document.body.insertBefore(this.$el, document.body.lastChild);
  },

  methods: {
    getSpeakerImage() {
      return this.image ? '/' + this.image : this.imagePath + 'empty-avatar.jpg';
    },
    createImagePath(image) {
      return this.imagePath + image;
    },
    closeHandler() {
      this.$el.classList.add('d-none');
    },
    show() {
      this.$el.classList.remove('d-none');
    }
  }
};
</script>
