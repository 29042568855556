<template>
<div class="box-sessions" @click="redirectToLive();">
    <div class="session-box-color" :class="(current ? 'blue-box' : (is_finished ? 'gray-light-box' : 'white-box') )">
      <div class="top-head-box clearfix">
       <div class="title-session time-now pull-left" v-if="formattedHoursForCard!='' && !current"><p>{{formattedHoursForCard}}</p></div>
       <div class="title-session pull-left" v-if="current"><p>Current session</p></div>
       <div class="live-now pull-right" v-if="is_live"><p>Live now!</p></div>
     </div>
     <div class="sessions-desc">
      <p class="text-left">{{title}}</p>
      <label class="text-left">{{speakersList}}</label>
    </div>
  </div>
</div>
</template>
<script>
import TranslationService from '../../core/services/translation.service';
import ActivityService from '../../core/services/activity.service';

export default {
  props: {
        id: Number,
        title: String,
        formattedHoursForCard: String,
        display_end: String,
        is_live: Boolean,
        current: Boolean,
        speakersList: String,
        is_finished: Number,
    },
    data() {
        return {
            slug: window.app.event.slug,
            activitySvc: ActivityService.create()

        }
    },methods:{
        redirectToLive(){
          if(!this.current){

            let agendaURL = '';
            if (this.is_live) {
              agendaURL = TranslationService.transRoute('event-detail-live', {
                slug: this.slug,
                agendaSessionId: this.id
              });
              this.saveActivity('live');
            } else {
              agendaURL = TranslationService.transRoute('event-agenda-detail', {
                slug: this.slug,
                agendaSessionId: this.id
              });
              this.saveActivity('agenda');
            }
            return window.open(agendaURL, '_blank').focus();

          }      
        },
        saveActivity(event) {
          let params = {
            title: this.title,
            model_id: this.id,
            congress_id: window.app.event ? window.app.event.id : null
          };
          this.activitySvc.saveActivity(event, params);
        }
    }
}
</script>