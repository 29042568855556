<template>
  <div class ="old-symposia-section">
    <div  v-scroll-spy class="base-blocks welcome-tops">
      <section id="symposia">
        <div class="container row p-0">
          <div class="title-content col-12 col-sm-12 col-md-12 col-xl-12">
            <h1 class="subtitle-section-light custom-congress-title" id="symposiatitle">{{configuration.section_wording}}</h1>
          </div>
          <div class="d-flex title-rounded-style symposia-section">
            <div class="slider-pagination-row">
              <div v-if="isLoading">
                {{ t('congresses.loading') }}
              </div>
              <template v-if="!isLoading && list.length">
                <V4Carousel
                  v-bind="{ slidesToShow: 1, slidesToScroll: 1, name: 'oursymposia', isLoading: false, isResponsive: true }"
                  class="custom-congress-carousel">
                  <template v-slot:items>
                    <div v-for="(item, i) in list" :key="i" class="slider-content-row">
                      <span :title="item.title" class="title-text secondary-color text-bold symposia-main-slider">
                        <div style="overflow:hidden; text-overflow:ellipsis;">{{ item.title }}</div>
                      </span>
                      <OldSymposiaTabs :symposiaid="item.id" :symposianame="item.title" :symposiadesc="item.description"
                        :thumbimg="item.image" :formattedDate="item.date_formatted"></OldSymposiaTabs>
                    </div>
                  </template>
                </V4Carousel>
              </template>
              <div v-if="!isLoading && !list.length">
                <p>{{ t('congresses.no_symposias') }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../../v4/core/services/translate.service.js'; 
import AgendaFactory from '../../../v4/modules/agenda/factories/Agenda.factory.js';
import V4Carousel from '../../../v4/modules/carousel/V4Carousel.vue';
import OldSymposiaTabs from './symposia/OldSymposiaTabs.vue';
import $ from 'jquery';

export default {
  name: 'OldSymposiaSection',
  components: {
    V4Carousel,
    OldSymposiaTabs
  },
  props: {
    configuration: Object,
  },
  data() {
    return {
      isLoading: true,
      list: [],
      translator: TranslateService.create()
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const results = await fetch(`/congresses/agenda?simposium=1&congressId=${this.configuration.congress_id}`).then(res => res.json());
      this.list = results.data.map(ev => {
        return AgendaFactory.get(ev);
      });
      this.list = results.data;
      this.isLoading = false;
      this.updateUI();
    },
    updateUI() {
      if (this.list.length == 1) {
        $('#symposiatitle').html(`${ this.configuration.section_wording }`);
      } else if (this.list.length > 1) {
        $('#symposiatitle').html(`${ this.configuration.section_wording } (${this.list.length})`);
      } else if (!this.list.length) {
        $('#symposia').css('display', 'none');
        $('#symposia_menu').css('display', 'none');
      }
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    t(tag) {
      return this.translator.translate(tag);
    }
  }
};
</script>
