<template>
  <div>
    <div class="d-flex flex-row" :key="checkBoxesKey">
      <FormCheckbox
        v-for="event in eventList"
        :key="event.id"
        @input="onInputChecked(event)"
        :defaultLabel="t(event.label)"
        :value="event.value"
        :id="event.id"
        :checked="event.checked"
      ></FormCheckbox>
    </div>

    <div v-if="isLoading">{{ t("general.loading") }}...</div>
    <div v-if="!isMobile()">
      <!-- DESKTOP -->
      <V4Carousel :slidesToShow="2" :slidesToScroll="2" :name="'events'" :isLoading="isLoading" v-if="!isLoading">
        <template v-slot:items>
          <div v-for="(item, i) in list" v-bind:key="i">
            <div @click="handlePromoClick(item.title)">
              <V4Card v-bind="item" />
            </div>
          </div>
        </template>
      </V4Carousel>
    </div>
    <div v-else>
      <!-- MOBILE -->
      <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'events'" :isLoading="isLoading" v-if="!isLoading">
        <template v-slot:items>
          <div v-for="(item, i) in list" v-bind:key="i">
            <V4Card v-bind="item" />
          </div>
        </template>
      </V4Carousel>
    </div>
    <div v-if="!isLoading && Boolean(error)">
      <div class="events-nodata" v-if="error.message">
        <div class="no-data-content">
          <h6>{{ error.message }}</h6>
          <p>
            Please try with another filter or check all events
            <span class="secondary-color"><a :href="getEventsRoute()">here</a></span>
          </p>
        </div>
      </div>
      <div class="events-nodata" v-if="!error.message">
        <div class="nodata-icon"><i class="bi bi-binoculars"></i></div>
        <div class="no-data-content">
          <h6>There are no events by applying the selected filter.</h6>
          <p>
            Please try with another filter or check all events
            <span class="secondary-color"><a :href="getEventsRoute()">here</a></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventRepository from "../repositories/Event.repository";
import EventFactory from "../factories/Event.factory";
import TranslateService from "../../../core/services/translate.service";
import TranslationService from "../../../core/services/translation.service.js";
import FormCheckbox from "../../form/checkbox/FormCheckbox.vue";
import V4Carousel from "../../carousel/V4Carousel.vue";
import V4Card from "../../card/V4Card.vue";
import GoogleAnalytics from '../../../GoogleAnalytics.js';
import $ from "jquery";

export default {
  data() {
    return {
      translations: {
        "events.not-found": "There are no events by applying the selected filter.",
      },
      isLoading: false,
      // bus: new Vue(),
      error: "",
      disabledControls: false,
      checkBoxesKey: 0,
      eventList: [
        {
          label: "new-home.events.pre-register-open",
          id: "pre-register",
          value: 1,
          checked: true,
        },
        {
          label: "home.grid.new",
          id: "new-in",
          value: 2,
          checked: true,
        },
        {
          label: "new-home.events.next-events",
          id: "my-next-events",
          value: 3,
          checked: true,
        },
        {
          label: "general.all",
          id: "all-events-carousel",
          value: 4,
          checked: true,
        },
      ],
      list: [],
      translator: TranslateService.create(),
      // selectedEventValue: 4,
    };
  },

  mounted() {
    this.$nextTick(function () {
      this.updateEvents();
    });
  },

  methods: {
    onInputChecked(checkbox) {
      this.checkboxLogic(checkbox);
      this.allCheckLogic();
      this.disabledControls = true;
      this.checkBoxesKey++;
      const evItem = this.getEventItemById(checkbox.id);
      this.selectedEventValue = checkbox.value;
      // this.updateEvents();
    },

    allCheckLogic() {
      let events = this.eventList.filter((item) => (item.checked === true) && (item.id != "all-events-carousel") ) ?? [];
      var allChecked = events.length >= (this.eventList.length - 1) ? true : false;

      $("#all-events-carousel")[0].checked = allChecked;
      const evItem = this.getEventItemById("all-events-carousel");
      evItem.checked = allChecked;

      return this.eventList.filter((item) => item.checked === allChecked);
    },

    async updateEvents() {
      // this.bus.$emit('remove', {});
      this.isLoading = true;
      this.error = "";

      try {
        const events = await this.findFilteredEvents(this.getActiveEventsValue());
        this.list = this.refactorEvents(events) ?? [];
      } catch (error) {
        // console.log(error);
        this.error = this.translations[error.message] ?? "Results not found";
        this.list = [];
      } finally {
        this.isLoading = false;
        this.disabledControls = false;
        this.checkBoxesKey++;
      }
    },

    refactorEvents(events) {
      if (!events.length) {
        throw new Error("events.not-found");
      }

      const filteredNullEvents = events.filter((e) => {
        return e !== null;
      });

      return filteredNullEvents.map((ev) => {
        return EventFactory.get(ev);
      });
    },

    getActiveEventsValue() {
      const events = this.getActiveEvents() ?? [];
      return events.map((ev) => ev.value);
    },

    getActiveEvents() {
      let events = this.eventList.filter((item) => item.checked === true) ?? [];
      if (events.length == 0) {
        $("#all-events-carousel")[0].checked = true;
        const evItem = this.getEventItemById("all-events-carousel");
        evItem.checked = true;
      }
      return this.eventList.filter((item) => item.checked === true);
    },

    getEventItemById(id) {
      return this.eventList.find((ev) => ev.id == id);
    },

    async findFilteredEvents(filters) {
      return EventRepository.getFiltered(filters);
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },

    checkboxLogic(checkbox) {
      var value = checkbox.checked ? false : true;
      if (checkbox.id == "all-events-carousel") 
      {
        this.eventList.forEach((ev) => {
          $("#" + ev.id)[0].checked = value;
          ev.checked = value;
        });
      } else {
        checkbox.checked = value;
        $("#" + checkbox.id)[0].checked = value;        
      }
    },

    t(tag) {
      return this.translator.translate(tag);
    },
    getEventsRoute() {
      return TranslationService.transRoute("events-home");
    },
    handlePromoClick(title) {
        if (typeof GoogleAnalytics !== 'undefined' && typeof GoogleAnalytics.sendPromoClickEvent === 'function') {
            GoogleAnalytics.sendPromoClickEvent(title);
        } else {
            console.error('GoogleAnalytics.sendPromoClickEvent is not defined');
        }
    },
  },
  components: {
    FormCheckbox,
    V4Carousel,
    V4Card
  },
};
</script>
<style scoped>
  .slick-dots{
    display: flex !important;
  }
</style>
