<template>
  <section class="custom-template-content" :id="$parent.$parent.formatHrefAnchorText(configuration.section_wording)">
    <div class="custom-template-content__head">
      <h2 class="text-center custom-template-content__head__title">{{configuration.section_wording}}</h2>
      <CustomTemplateContentSearch v-if="dataReady" ref="templateContentSearch" :therapeutics="therapeutics"
        :congressid="configuration.congress_id" @searchFullContent="searchFullContent"
        :filtersType="configuration.configuration.custom_content_filters_type"
        :filtersAvailable="configuration.configuration.custom_content_available_filters"
        :contentGrid="configuration.configuration.custom_content_grid">
      </CustomTemplateContentSearch>
    </div>
    <div class="custom-template-content__container container">
      <ContentTabs v-if="configuration.configuration.custom_content_grid == 'categorized'"
        :size="pageSize" :card_type = "configuration.configuration.custom_card">
      </ContentTabs>
      <SearchFormResults v-else :size="pageSize" :card_type = "configuration.configuration.custom_card"></SearchFormResults>
    </div>
  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import CustomTemplateContentSearch from './components/CustomTemplateContentSearch.vue';
import SearchFormResults from '../search-form-results/SearchFormResults.vue';
import ContentTabs from './components/ContentTabs.vue';
import axios from 'axios';

export default {
  name: 'ContentSection',
  props: {
    configuration: Object,
  },
  data() {
    return {
      translator: TranslateService.create(),
      dataReady : false,
      therapeutics: [],
      pageSize: 6
    };
  },
  components: { ContentTabs, CustomTemplateContentSearch, SearchFormResults },
  mounted() {
    this.getDataForFilters();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async getDataForFilters(){
      await axios
        .get('/congresses/content/filters-data')
        .then(response => {
          if(response.data && response.data.therapeutics){
            this.therapeutics = response.data.therapeutics;
            this.dataReady = true;
          }
        })
        .catch(error => {
          console.error('getDataForFilters', error);
        });
    },
    async searchFullContent(data) {
      // console.log('En custom');
      // console.log(data);
    }
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
