<template>
    <div class="connectmi-event__content__header">
        <div class="connectmi-event__content__header__wrapper">
            <img :src="createImagePath('connectmi2022-logo.svg')" alt="connectmi2022-logo"  @click="goBack" class="connectmi-event__header__content_logo"/>
            <button @click="goBack" class="connectmi-event__button connectmi-event__button--sm sconnectmi-event__button--shadow">
                <img :src="createImagePath('go-back.svg')" alt="go-back" />
            </button>
        </div>
    </div>
</template>

<script>
import Utils from '../../Utils'

    export default {
        props: {
            imagePath: String
        },
        methods: {
            createImagePath(image) {
                return this.imagePath + image;
            },
            goBack() {
                let path = Utils.escapeHtml(location.pathname);
                if(path.includes("agenda") || path.includes("multimedia")){
                    location.href= '/en/events/connectmi2022/';
                }else{
                    let arr = path.split('/');
                    arr.pop();
                    location.href = Utils.isValidUrl(arr.join(Utils.escapeHtml('/')));
                }
            }
        }
    }
</script>
