import axios from 'axios';

export default class ActivityService {

  static create() {
    return new ActivityService();
  }
  saveActivity(event, data) {
    let url = '/profile/save-activity';
    let fd = new FormData();

    fd.append('event', event);

    let params = {
      email: data.email ? data.email : undefined,
      model_type: data.model_type ? data.model_type : null,
      model_id: data.model_id ? data.model_id : undefined,
      source: (data.source ?? window.source) || null,
      congress_id: data.congress_id ? data.congress_id : undefined
    };

    switch (event) {
      //Register
      case 'register':
        params['register'] = data.title;
        url = '/save-activity';
        break;
      //Register OWA
      case 'go-to-owa':
        params['register'] = data.title;
        url = '/save-activity';
        break;
      //Register DocCheck
      case 'go-to-doccheck':
        params['register'] = data.title;
        url = '/save-activity';
        break;
      //Register Manual
      case 'go-to-manual':
        params['register'] = data.title;
        url = '/save-activity';
        break;
      //Enter congress
      case 'enter-congress':
        params['congress'] = data.title;
        break;
      //View file
      case 'download-file':
        params['file'] = data.title;
        break;
      //Open multimedia
      case 'open-multimedia':
        params['multimedia'] = data.title;
        break;
      //Enter live
      case 'live':
        params['live'] = data.title;
        break;
      //Download calendar
      case 'calendar':
        params['calendaar'] = data.title;
        break;
      //Open agenda detail
      case 'agenda':
        params['agenda'] = data.title;
      //Enter KJ
      case 'enter-kj':
        params['kj'] = data.title;
        break;
      //Enter CC
      case 'enter-cc':
        params['cc'] = data.title;
        break;
      //Open KJ unit
      case 'kjunit':
        params['info'] = data.title;
        break;
      //Request file
      case 'request-file':
        params['file'] = data.title;
        break;
      //Send KJ test
      case 'send-test':
        params['info'] = data.title;
        break;
      //Diploma download
      case 'diploma-download':
        params['info'] = data.title;
        break;
      //Diploma shared
      case 'diploma-shared':
        params['info'] = data.title;
        break;
      //Congress external code
      case 'congress-external-code':
        params['congress'] = data.title;
        break;
      //Congress external redirect
      case 'congress-external-redirect':
        params['congress'] = data.title;
        break;
      //Open symposium multimedia
      case 'open-symposium-multimedia':
        params['symposium-multimedia'] = data.title;
        break;
      //Enter multimedia category
      case 'enter-multimedia-category':
        params['multimedia-category'] = data.title;
        break;
      //Enter satellital agenda modal
      case 'open-satellital-agenda-modal':
        params['agenda-modal'] = data.title;
        url = '/save-activity';
        break;
       //Enter satelital speaker modal
      case 'open-satellital-speaker-modal':
        params['speaker-modal'] = data.title;
        url = '/save-activity';
        break;
      //Reset password
      case 'reset-password':
        params['reset-password'] = data.title;
        url = '/save-activity';
        break;
      //set password
      case 'set-password':
        params['set-password'] = data.title;
        url = '/save-activity';
        break;
      //From pre-home to login
      case 'go-to-login':
        params['go-to-login'] = data.title;
        url = '/save-activity';
        break;
      case 'go-to-register':
        params['go-to-register'] = data.title;
        url = '/save-activity';
        break;
      //Select timezone filter in Agenda Section
      case 'select-timezone-agenda-section':
        params['timezone-selected'] = data.title;
        break;
      //Select category filter in Agenda Section
      case 'select-categories-agenda-section':
        params['category-selected'] = data.title;
        break;
      //Select slider filter in Agenda Section
      case 'select-slider-agenda-section':
        params['slot-selected'] = data.title;
        break;
      //Book a seat into the agenda
      case 'agenda-book-seat':
        params['agenda'] = data.title;
        break;
      //Add to calendar the agenda
      case 'agenda-add-to-calendar':
        params['agenda'] = data.title;
        break;
      //Download full agenda
      case 'agenda-download-full-calendar':
        params['file'] = data.title;
        break;
    }

    params = JSON.stringify(params);
    fd.append("parameters", params);

    axios.post(url, fd).then(response => {
      this.data = response.data;
    }).catch(e => {
      console.debug(e);
    })
  }
}
