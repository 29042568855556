import RoutesHelper from '../v4/routesHelper';
import ClipboardJS from 'clipboard';

const Utils = {
  getUrl: function(hasSlug, route) {
    let routesHelper = new RoutesHelper();
    let slug = hasSlug ? this.getEventDetailConfig().slug : null;
    const routeEl = routesHelper.getRoute(
      route,
      slug,
      '%slug%'
    );
    return routeEl;
  },

  getEventDetailConfig() {
    let e = window.app.event;
    if( 'undefined' == typeof e)
      throw new Error('This config does not exists. EMC01');
    return e;
  },

    isAmgen: function (email) {
      return email.indexOf('@amgen.com') !== -1;
    },

    removeSpaces: function (string) {
      return string.split(' ').join('');
    },

    isNotEmptyStr: function (str) {
      return /\S/.test(str);
    },

    hideElement (selector, time,  callback) {
      const $elements = $($(selector));
      for(const element of $elements){
        const $element = $(element);
        if (!$element.hasClass('d-none')) {
          time = time ? time : 400;
          $element.css('opacity', 0);
          setTimeout(() => {
            $element.addClass('d-none');
          }, time);

        }
      }
      setTimeout(() => {
        if(typeof callback == "function") callback();
      }, time);
    },

  showElement (selector) {
    $(selector).css('opacity', 0);
    $(selector).removeClass('d-none');

    setTimeout(() => {
      $(selector).css('opacity', 1);
    }, 10);
  },

  hideModal (selector) {
    $(selector).modal('hide');
  },

  getAgendaSessionId() {
    let e = window.app.agenda.agendaSessionId;
    if( 'undefined' == typeof e)
      throw new Error('This config does not exists. EMC01');
    return e;
  },

  isLoggedIn() {
    return window.user !== undefined;
  },

  callbackWhenModalIsOpen(self, modalId, callback, data) {
    $(modalId).on('shown.bs.modal', function (e) {
      callback(self, data);
    });
  },

  disable(el) {
    document.querySelector(el).disabled = true;
  },

  enable(el) {
    document.querySelector(el).disabled = false;
  },

  initClipboardJS: function(container_id, selector, text) {
      const el = this;
      const clipboard = new ClipboardJS('.btn', {
        container: document.getElementById(container_id)
      });
  
      clipboard.on('success', function(e) {
        $(selector).text("Copied");
        e.clearSelection();
        setTimeout(function() {
          $(selector).text(text);
        }, 2500);
      });
    },

  escapeHtml(text) {
    var map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
  },

  isValidUrl(string) {
    let givenUrl;
    try {
      givenUrl = new URL(string);
    } catch(error) {
      console.log("error is", error);
      return false;
    }
    return givenUrl.protocol === "http:" || givenUrl.protocol === "https:";
  }
};

export default Utils
