<template>
    <div>
        <div v-if="!isMobile()"> 
            <!-- DESKTOP --> 
            <ProfileSearchFields @titlechange="searchTitle" ref="profilesearchfields" :therapeutics="therapeutics" :user-therapeutic-areas="userareas" :slug="slug" :from_type="from_type"></ProfileSearchFields>    
        </div>
        <div v-else class="button-row">
            <!-- MOBILE -->
            <button type="button" data-toggle="modal" data-target="#content-modal" class="button-open-content">           
                <i class="bi bi-filter"></i> {{ t("congresses.show_filters") }}
            </button>
            <!-- Modal -->
            <div class="modal fade" id="content-modal" tabindex="-1" role="dialog" aria-labelledby="content-modal"
            aria-hidden="true">
                <div class="modal-dialog modal-lg theme-dark-modal" role="document">
                    <div class="modal-content p-md-5">
                        <div class="modal-body px-3">
                           
                            <div class="modal-close-row">
                                <i class="bi bi-x-lg" data-dismiss="modal" aria-label="Close"></i>
                            </div>
                            <div class="content-modal-body modal-body-content ">
                                <ProfileSearchFields  @titlechange="searchTitle" :ref="profile_search_fields" :therapeutics="therapeutics" :user-therapeutic-areas="userareas" :slug="slug"></ProfileSearchFields>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import ProfileSearchFields from './ProfileSearchFields.vue';
export default {     
    name: 'ProfileSearch',
    components: {
        ProfileSearchFields
    },   
    props: {  
        therapeutics : Object,
        slug : String,
        userareas  : Array,
        from_type : String,        
    },
    methods: {
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        t(tag){
                return this.translator.translate(tag)
        },
        searchTitle(filter_data){  
            if ( this.from_type == "profsub" || this.from_type == "bookmark") {
                this.$emit("searchFullTitle", filter_data)  
            }                           
        },
        updatekeyword(id) {
            if ( this.from_type == "profsub") {
                this.$refs.profilesearchfields.updatekeyword(id)
            } 
        },
        updateBookmarkkey(obj) {
            if ( this.from_type == "bookmark") {
                this.$refs.profilesearchfields.updateBookmarkkey(obj);
            } 
        }
    },
    data() {
        return {            
            profile_search_fields: null,
            translator : TranslateService.create()
        }
    }
}
</script>
