<template>
    <div class="assistance-section__form">
        <form @submit.prevent="submitForm">
            <div class="assistance-section__form__description">
                <p>Please fill out the form below to submit your request.</p>
            </div>
            <div class="assistance-section__form__group">
                <label for="full-name">Full Name</label>
                <input type="text" id="full-name" name="full-name" :value="user.full_name" disabled  required />
            </div>
            <div class="assistance-section__form__group">
                <label for="email">Email</label>
                <input type="email" id="email" name="email" :value="user.email" disabled  required />
            </div>
            <div class="assistance-section__form__group">
                <label for="country">Country</label>
                <input type="text" id="country" name="country" :value="user.country_name" disabled required />
            </div>
            <div class="assistance-section__form__group">
                <label for="product">Product</label>
                <input type="text" v-model="form.product" id="product" name="product" placeholder="" required />
            </div>
            <div class="assistance-section__form__group">
                <label for="message">Message</label>
                <textarea id="message" v-model="form.message" name="message" placeholder="" rows="4" required></textarea>
            </div>
            <div class="assistance-section__form__actions">
                <button class="assistance-section__form__actions__send" :class="loadingSumbit ? 'disabled' : ''" :disabled="loadingSumbit">Send</button>
                <button class="assistance-section__form__actions__cancel" type="button" :disabled="loadingSumbit" @click="cancelAssistanceForm()">Cancel</button>
            </div>
            <span class="assistance-section__form__error-text" v-if="submitFormError" v-html="submitFormError"></span>
            <span class="assistance-section__form__success-text" v-if="submitFormSuccess" v-html="submitFormSuccess"></span>
        </form>
    </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue';
import TranslateService from '../../../core/services/translate.service.js';
import axios from 'axios';

export default {
    name: 'AssistanceForm',
    props: {
        user: Object,
        selectedForm: String
    },
    setup(props) {
        const translator = TranslateService.create();
        const t = (tag) => translator.translate(tag);
        const user = props.user;
        const submitFormError = ref('');
        const submitFormSuccess = ref('');
        const loadingSumbit = ref(false);

        const form = reactive({
            full_name: props.user?.full_name || '',
            email: props.user?.email || '',
            country_id: props.user?.country_id || '',
            type: props.selectedForm || '', 
            product: '',
            message: '',
        })
    
        const cancelAssistanceForm = () => {
            document.dispatchEvent(new CustomEvent("displayAssistanceForm", { detail: {
                type: '',
                show: false,
            } }));
        }

        const submitForm = async (e) => {
            loadingSumbit.value = true;
            e.preventDefault();
            const url = '/assistance/send';
            await axios.post(url, form)
                .then(function (response) {
                    submitFormSuccess.value = response.data.message;
                })
                .catch(function (error) {
                    submitFormError.value = error.response.data.message;
                }).finally(function () {
                    loadingSumbit.value = false;
                });
        }

        const isMobile = () => {
            try {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            } catch (error) {
                console.error('Error determining if the device is mobile:', error);
                return false;
            }
        };

        return {
            t,
            form,
            isMobile,
            user,
            cancelAssistanceForm,
            submitForm,
            submitFormError,
            submitFormSuccess,
            loadingSumbit,
        };
    },
}
</script>