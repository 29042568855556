<template>
<div>
    <div class="container">
        <h1 class="section-subtitle text-center mb-2">{{t('new-home.content.find-content')}}</h1>
        <EventForm :therapeutics="therapeutics" :contentType="contentType" :user-therapeutic-areas="userTherapeuticAreas" :page="'content'"></EventForm>
    </div>
    <div>
        <SearchFormResults :size="pageSize"></SearchFormResults>
    </div>
</div>
</template>

<script>
import ContentRepository from '../../home-form/repositories/Content.repository';
import TranslateService from '../../../core/services/translate.service';
import PageStayController from '../../../page-stay/PageStayController';
import EventForm from '../../events/event-form/EventForm.vue';
import SearchFormResults from  '../../../../v4/modules/search-form-results/SearchFormResults.vue';

export default {
    components: {
        EventForm,
        SearchFormResults
    },
    props: {
        therapeutics: Object,
        contentType: Array,
        userTherapeuticAreas: Array,
    },
    data() {
        return {
            isLoading: true,
            list: [],
            error: '',
            pageSize: 12,
            defaultForm: {
                events: null,
                content: null,
                all: null,
                'knowledge-journey': null,
                bookmarks: false,
                q: '',
                qTherapeuticAreas: [],
                qPathologies: [],
                format: [],
                content_type: [],
                qKeywords: [],
                date: [],
                page: 0,
                size: this.pageSize
            },
            form: {},
            translator : TranslateService.create()
        }
    },

    mounted() {
        this.initStayPage();
    },

    methods: {
        async get() {
            try {
                this.isLoading = true;
                this.error = '';
                this.form.content_type = this.contentType;
                this.form.format = [1];
                this.list = await ContentRepository.search(this.form).then((promise) => {
                    // this.$root.$emit('SearchEvent', promise);
                    document.dispatchEvent(new CustomEvent("SearchEvent", { detail: this.list }));
                });

            } catch (error) {
                this.list = [];
                this.error = "something happened";
            }
        },
        t(tag){
            return this.translator.translate(tag)
        },
        initStayPage() {
            PageStayController.startStayPageTime('content', 0);
        },
    }
}
</script>
