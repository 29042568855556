<template>
    <div class="livevoting-popup survey-popup">
        <SurveyModalComponent v-bind:type="'textareaanswer'" v-show="isVotingpopupVisible" @close="closeModal" :key="k">
            <template v-slot:body>
                <div id="survey-question-list" v-if="!isLoading && formdetails.length > 0">
                    <div class="voting-list-items" v-for="(formcontent,i) in formdetails" :id="formcontent.poll.id"
                        :key="i">
                        <div class="box-common-size" id="isTextAreaAnswerVisible">
                            <div class="left-timer" v-if="formcontent.poll.expiration_time > 0"
                                :data-totaltime="formcontent.poll.expiration_timeout"
                                :data-poll-id="formcontent.poll.id">
                                <i class="bi bi-hourglass-split"></i>
                                <span>{{ formcontent.poll.expiration_timeout }}</span>
                            </div>
                            <div class="live-title">
                                <h4>Live Voting</h4>
                            </div>
                            <div class="second-title">
                                <h2 class="text-center">{{ formcontent.poll.question }}</h2>
                            </div>
                            <div class="third-content">
                                <p class="text-center questiontext">{{ getquestiontype(formcontent.poll.type) }}</p>
                            </div>
                            <SurveyLiveQuestion v-if="formcontent.poll.type == 2" :type="formcontent.poll.type"
                                :expiration_time="formcontent.poll.expiration_timeout"
                                :expiration_timeout="formcontent.poll.expiration_time" :id="formcontent.poll.id"
                                :question="formcontent.poll.question" @popupQuesClose="closePopup(3)"
                                :key="formcontent.poll.id"></SurveyLiveQuestion>
                            <SurveyLiveSingle v-if="formcontent.poll.type == 1" :type="formcontent.poll.type"
                                :expiration_time="formcontent.poll.expiration_timeout"
                                :expiration_timeout="formcontent.poll.expiration_time" :id="formcontent.poll.id"
                                :question="formcontent.poll.question" @popupQuesClose="closePopup(1)"
                                :options="formcontent.poll.options" :key="formcontent.poll.id"> </SurveyLiveSingle>
                            <SurveyLiveMultiple v-if="formcontent.poll.type == 4" :type="formcontent.poll.type"
                                :expiration_time="formcontent.poll.expiration_timeout"
                                :expiration_timeout="formcontent.poll.expiration_time" @popupQuesClose="closePopup(4)"
                                :id="formcontent.poll.id" :question="formcontent.poll.question"
                                :options="formcontent.poll.options" :key="formcontent.poll.id"></SurveyLiveMultiple>
                            <SurveyLiveRadio :id="formcontent.poll.id" v-if="formcontent.poll.type == 6"
                                :expiration_time="formcontent.poll.expiration_timeout"
                                :expiration_timeout="formcontent.poll.expiration_time"
                                :question="formcontent.poll.question" :list="formcontent.poll.options"
                                @popupQuesClose="closePopup(4)" :type="formcontent.poll.type"
                                :key="formcontent.poll.id"></SurveyLiveRadio>
                            <SurveyLiveMatch :id="formcontent.poll.id" v-if="formcontent.poll.type == 7"
                                :list="formcontent.poll.options" :expiration_time="formcontent.poll.expiration_timeout"
                                :expiration_timeout="formcontent.poll.expiration_time"
                                :question="formcontent.poll.question" @popupQuesClose="closePopup(5)"
                                :type="formcontent.poll.type" :key="formcontent.poll.id"></SurveyLiveMatch>
                            <SurveyLiveFillGaps :id="formcontent.poll.id" v-if="formcontent.poll.type == 8"
                                :expiration_time="formcontent.poll.expiration_timeout"
                                :expiration_timeout="formcontent.poll.expiration_time"
                                :question="formcontent.poll.question" @popupQuesClose="closePopup(6)"
                                :type="formcontent.poll.type" :key="formcontent.poll.id"
                                :filldetails="formcontent.poll"></SurveyLiveFillGaps>
                        </div>
                    </div>
                </div>
                <div class="box-padd-new box-padd-full">
                    <div class="footer-box-cm clearfix popup-slider-icon">
                        <div class="one-five pull-left">
                            <a href="javascript:void(0);"><i class="bi bi-chevron-left" v-on:click="slickPrev"></i></a>
                            <label>
                                <span class="active-highlight pagingInfo">{{prevcount}}</span> / {{totalcount}}
                            </label>
                            <a href="javascript:void(0);"><i class="bi bi-chevron-right" v-on:click="slickNext"></i></a>
                        </div>
                        <div class="send-now-btn pull-right">
                            <button class="btn btn-primary v4-btn-primary" type="button"
                                v-on:click="setAnswerPoll">Send</button>
                        </div>
                    </div>
                </div>
            </template>
        </SurveyModalComponent>
    </div>
</template>

<script>
import { WSHelper } from '../../../../libs/ws/ws-helper';
import Utils from '../../Utils';
import SurveyModalComponent from '../congresslive/SurveyModalComponent.vue';
import SurveyLiveQuestion from './SurveyLiveQuestion.vue';
import SurveyLiveSingle from './SurveyLiveSingle.vue';
import SurveyLiveMultiple from './SurveyLiveMultiple.vue';
import SurveyLiveRadio from './SurveyLiveRadio.vue';
import SurveyLiveMatch from './SurveyLiveMatch.vue';
import SurveyLiveFillGaps from './SurveyLiveFillgaps.vue';
import { useLivePollStore } from '../../store/live.store.js';
import $ from 'jquery';

export default {
    setup () {
        const livePollStore = useLivePollStore();
        return {
            livePollStore
        }
    },
    components: {
        SurveyModalComponent,
        SurveyLiveQuestion,
        SurveyLiveSingle,
        SurveyLiveMultiple,
        SurveyLiveRadio,
        SurveyLiveMatch,
        SurveyLiveFillGaps
    },
    data(){
       return{
            isVotingpopupVisible: false,
            isLoading:false,
            isTextAreaAnswerVisible: false,
            isRadioAnswerVisible: false,
            isCheckboxVisible: false,
            isMatchTextVisible: false,
            isMatchImageVisible: false,
            isFillGapVisible: false,
            formdetails:Array,
            prevcount:0,
            totalcount:0,
            k:0
       }
    },
    methods: {
        closeModal() {
            this.isVotingpopupVisible = false;
        },
        closePopup(id){
            // console.log("parent-parent" , id)
            this.formdetails = this.formdetails.filter(function(obj){
                console.log(obj.poll.id)
                return obj.poll.id != id;
            });
            this.totalcount = this.formdetails.length;
            let that = this;
            that.removeslider();
            if(this.totalcount >=1){
                this.isVotingpopupVisible = true;
                this.$nextTick(function(){
                    that.loadslider();
                });
            }
        },
        removeslider(){
            $('#survey-question-list').slick('unslick');
        },
        loadslider(){
            if($('#survey-question-list').hasClass('slick-initialized')){
                console.log('slick-already-initialized');
                $('#survey-question-list').slick('setPosition');
            }else{
                 console.log('slick-initialized');
                $('#survey-question-list').not('.slick-initialized').slick({
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    arrows: false
                });
            }
            $('#survey-question-list').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                this.prevcount = (currentSlide ? currentSlide : 0) + 1;
                console.log('clicked');
                $('.pagingInfo').html(this.prevcount);
            });
        },
        slickNext() {
            $('#survey-question-list').slick('slickNext');
        },
        slickPrev() {
            $('#survey-question-list').slick('slickPrev');
        },
        setAnswerPoll(){
            $('.slick-current .modal-questions-form').trigger("submit")
        },
        onWSMessageShowpoll(evt) {
            let that = this;
            // that.removeslider();
            if (evt.json && evt.json.data) {
                const data = evt.json.data;
                if(data.action == "show_poll"){
                    const response = { data, status: 200 };
                    console.log("formdetails -> ", response);
                    this.formdetails = [];
                    try {
                        if(response.data.isSurvey){
                            this.livePollStore.pendings = response.data.livePollsData;
                            //this.$store.commit('setPendings', response.data.livePollsData);
                            this.formdetails = response.data.livePollsData;
                            this.totalcount = this.formdetails.length;
                            if(this.totalcount >=1){
                                this.isVotingpopupVisible = true;
                                this.$nextTick(function(){
                                    that.loadslider();
                                    that.changetimequestion();
                                });
                            }
                        }
                    } catch(e) {
                        console.debug("ERR", e);
                    }
                }
            }
        },
        changetimequestion(){
            let that = this;
            let presenttime = Date.now()
            $(".left-timer").each(function(){
                let questiontime = $(this).data('totaltime');
                if(questiontime !=0){
                   if(questiontime < presenttime){
                        let pollid = $(this).data("poll-id");
                        if(this.isVotingpopupVisible){
                            that.closePopup(pollid);
                        }
                    }
                }
            });
        },
        getquestiontype(type) {
            switch (type) {
                case 1:
                return 'Single question answer';

                case 2:
                return 'Open question';

                case 4:
                return 'Multi-answer question';

                case 6:
                return 'Match!';

                case 7:
                return 'Match!';

                case 8:
                return 'Fill Gaps!';

                default:
                return 'Open question';
            }
        },
    },
    mounted(){
        let that = this;
        this.ws = new WSHelper(true);
        this.ws.registerCallback((evt) => {
            switch (evt.operation) {
                case 'conn_retry_failure':
                  // do something like displaying a loading circle?
                  break;
                case 'conn_down':
                  console.debug('Connection down...');
                  break;
                case 'msg_received':
                  // A message was received:
                  this.onWSMessageShowpoll(evt);
                  break;
                case 'conn_started':
                case 'conn_restarted':
                  // Ask current status:
                    const agendaSessionId = Utils.getAgendaSessionId();
                    that.ws.sendObject({ "agenda_id": agendaSessionId, operation: "register_ws", "uid": window.user.uid});
                  break;
              }
        });
        this.ws.start().then(res => {
          // alert(res);
        }).catch(err => {
          // alert("ERR", err);
        });
    }
}
</script>
