<template>
  <form :action="getSearchRoute()" @submit.prevent="onFormSubmit">
    <div class="row">
      <div class="col-12 mobile-filter-popup" v-if="dataIsReady">
        <div id="main-mobile-filters" class="row">
          <div class="col-12 d-flex flex-row checkbox-left">
            <form-checkbox
              @input="onInputChecked"
              v-bind="{
                id: 'events',
                defaultLabel: t('new-home.title.events'),
                defaultValue: 'events',
                isChecked: shouldBeChecked('events'),
              }"
            ></form-checkbox>
            <form-checkbox
              @input="onInputChecked"
              v-bind="{
                id: 'content',
                defaultLabel: t('general.content'),
                defaultValue: 'content',
                isChecked: shouldBeChecked('content'),
              }"
            ></form-checkbox>
            <form-checkbox
              @input="onInputChecked"
              v-bind="{
                id: 'knowledge-journey',
                defaultLabel: t('knowledge-journey.title'),
                defaultValue: 'knowledge-journey',
                isChecked: shouldBeChecked('knowledge-journey'),
              }"
            ></form-checkbox>
            <form-checkbox
              @input="onInputChecked"
              v-bind="{
                id: 'all',
                defaultLabel: t('general.all'),
                defaultValue: 'all',
                isChecked: shouldBeChecked('all'),
              }"
            ></form-checkbox>
          </div>

          <div class="col-12 switch-left">
            <div class="v4-form-switch--bookmark" style="width: 250px">
              <!-- <form-checkbox @input="onInputChecked" v-bind="{defaultLabel: 'Only Bookmark', defaultValue:'only-bookmark', isChecked: false}"></form-checkbox> -->
              <form-switch
                @input="onSwitchToggle"
                v-bind="{
                  id: 'bookmarks',
                  value: shouldBeChecked('bookmarks'),
                  label: t('new-home.search.only-bookmarks'),
                }"
              ></form-switch>
            </div>
          </div>
        </div>

        <div class="mt-2 mb-5 form-seconds-row">
          <div class="row" :key="formSectionKey">
            <div class="col-2 v4-select-container v4-form-name-therapeutic-areas" v-if="therapeuticsOptions.length">
              <form-select
                @input="onSelectChange"
                v-bind="{
                  id: 'therapeuticAreas',
                  title: t('form.therapeutic_areas'),
                  defaultOptions: therapeuticsOptions,
                  multiple: true,
                }"
                :userTA="userTherapeuticAreas"
              ></form-select>
            </div>
            <div class="col-2 v4-select-container v4-form-name-diseases" v-if="pathologiesOptions.length">
              <form-select
                @input="onSelectChange"
                v-bind="{
                  id: 'diseases',
                  title: t('form.diseases'),
                  defaultOptions: pathologiesOptions,
                  multiple: true,
                }"
              ></form-select>
            </div>
            <div class="col-2 v4-select-container v4-form-name-format" v-if="formatsOptions.length">
              <form-select
                @input="onSelectChange"
                v-bind="{ id: 'format', title: t('form.format'), defaultOptions: formatsOptions, multiple: true }"
              ></form-select>
            </div>
            <div class="col-2 v4-select-container v4-form-name-content-type" v-if="multimediaOptions.length">
              <form-select
                @input="onSelectChange"
                v-bind="{
                  id: 'content_type',
                  title: t('form.content-type'),
                  defaultOptions: multimediaOptions,
                  multiple: true,
                }"
              ></form-select>
            </div>
            <div class="col-2 v4-select-container v4-form-name-keywords" v-if="keywordsOptions.length">
              <form-select
                @input="onSelectChange"
                v-bind="{
                  id: 'keywords',
                  title: t('home.search.keywords'),
                  defaultOptions: keywordsOptions,
                  multiple: true,
                }"
              ></form-select>
            </div>
            <div class="col-2 v4-select-container">
              <!-- <form-select @input="onSelectChange" v-bind="{id: 'date', label: 'Date'}"></form-select> -->
              <date-picker></date-picker>
            </div>
          </div>
          <div class="pt-3 text-right">
            <i class="bi bi-trash" style="color: #2dbcb6"></i>
            <a href="javascript:void(0)" class="v4-action-link" @click="resetFilters()">{{
              t("new-home.search.delete-filters")
            }}</a>
          </div>
        </div>
        <div class="text-center apply-btn-center row">
          <button class="v4-btn-primary">Apply</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ContentRepository from "./repositories/Content.repository";
import UtilsService from "../../core/services/utils.service";
import DatePicker from "../form/datepicker/DatePicker.vue";
import TranslateService from "../../core/services/translate.service.js";
import GoogleAnalytics from '../../GoogleAnalytics.js';
import TranslationService from "../../core/services/translation.service";
import axios from "axios";
import $ from "jquery";

export default {
  components: { DatePicker },
  props: {
    // therapeutics: Object,
    // contentType: Object,
    redirectMode: Boolean,
    selectLists: Object,
    initialSelect: Boolean,
    userTherapeuticAreas: Array,
  },
  data() {
    return {
      defaultSelectList: this.selectLists ?? [],
      // therapeuticsOptions: UtilsService.mapObjectToArray(this.therapeutics),
      // contentTypeOptions: UtilsService.mapObjectToArray(this.contentType),
      therapeuticsOptions: [],
      pathologiesOptions: [],
      multimediaOptions: [],
      keywordsOptions: [],
      specialitiesOptions: [],
      formatsOptions: [],
      shouldRedirect: this.redirectMode ?? true,
      secondRowActive: false,
      dataIsReady: this.redirectMode ? true : false,
      formSectionKey: 0,

      defaultForm: {
        events: null,
        content: null,
        all: null,
        "knowledge-journey": null,
        bookmarks: false,
        q: "",
        qTherapeuticAreas: [],
        qPathologies: [],
        format: [],
        content_type: [],
        qKeywords: [],
        qStartDate: null,
        qEndDate: null,
        date: [],
        page: 0,
        size: 12,
      },
      form: {},
      translator: TranslateService.create(),
    };
  },

  mounted() {
    this.shouldMakeSearchRequest();
    this.prepareSelects();

    document.addEventListener("SearchFormResultsPageChanged", (paginationData) => {
      this.page = paginationData.currentPage;
      this.form["page"] = paginationData.currentPage;
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }))
      this.search(event.detail.currentPage);
    });

    document.addEventListener("KeywordSearch", (event, keywordText) => {
      GoogleAnalytics.sendPromoClickEvent(keywordText);
      const { data, $event } = event.detail;
      this.form = data;
      this.search();
      this.onFormSubmit($event);
    });

    document.addEventListener("changeselectpicker", (data) => {
      this.setFormSelects(data.detail);
    });
  },

  methods: {
    resetFilters() {
      this.form = this.defaultForm;
      $(".selectpicker").val("default");
      $(".selectpicker").selectpicker("refresh");
      $("#date-selector").value = "";
    },

    async prepareSelects() {
      let filters = await axios.get("/webapi/home/models-filters");

      filters = filters.data.data;
      const keywords = filters.keywords;
      const multimedia = filters.multimediaTypes;
      const therapeutics = filters.therapeuticAreas;
      const specialities = filters.specialities;
      const pathologies = filters.pathologies;
      const formats = filters.formats;

      this.keywordsOptions = this.mapSelect(Object.values(keywords));
      this.multimediaOptions = this.mapSelect(Object.values(multimedia));
      this.therapeuticsOptions = this.mapSelect(Object.values(therapeutics));
      this.specialitiesOptions = this.mapSelect(Object.values(specialities));
      this.pathologiesOptions = this.mapSelect(Object.values(pathologies));
      this.formatsOptions = this.mapSelect(Object.values(formats));
    },
    mapSelect(collection) {
      return collection.map((i) => {
        return {
          id: i.id,
          name: i.name,
          therapeutic_area_id: i.therapeutic_area_id,
        };
      });
    },

    shouldBeChecked(formField) {
      return Boolean(this.form[formField]);
    },

    t(tag) {
      return this.translator.translate(tag);
    },

    onInputChecked(checkbox) {
      this.setFormInputFromDTO(checkbox, true);
    },

    onSwitchToggle(toggleData) {
      this.setFormInputFromDTO(toggleData, true);
    },

    onSelectChange(selected) {
      this.setFormInputFromDTO(selected);
    },

    setFormInputFromDTO(inputData, isBoolOrNull) {
      let value = "";

      if (inputData.key == "all") {
        value = Boolean(inputData.value) ? true : null;

        this.form["content"] = value;
        this.form["knowledge-journey"] = value;
        this.form["events"] = value;

        let checkboxes = $("#content,#knowledge-journey,#events").toArray();

        checkboxes.forEach((checkbox) => {
          checkbox.checked = value;
        });
      } else {
        this.form["content"] = false;
        this.form["knowledge-journey"] = false;
        this.form["events"] = false;
        this.form["all"] = false;

        if (isBoolOrNull) {
          value = Boolean(inputData.value) ? true : null;
        } else {
          value = inputData.value;
        }
        $("#all")[0].checked = false;
      }

      this.form[inputData.key] = value;
    },

    removeEmpty(obj) {
      return Object.entries(obj)
        .filter(([_, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    },

    toggleSecondRow() {
      this.secondRowActive = !this.secondRowActive;
      $(".form-second-row").removeClass("d-none");
      setTimeout(() => {
        this.formSectionKey++;
      }, 10000);
    },

    shouldMakeSearchRequest() {
      const data = this.parseURLFormData();

      if (Boolean(data)) {
        this.form = data;
        this.search();
        this.dataIsReady = true;
      } else {
        this.form = this.defaultForm;
      }
    },

    parseURLFormData() {
      const url = new URLSearchParams(window.location.search);
      const q = url.get("q");
      if (q) {
        return JSON.parse(atob(q));
      }
      return "";
    },

    onFormSubmit(ev) {
      const formString = JSON.stringify(this.form);
      const base = btoa(formString);
      const url =
        ev.target.action !== undefined
          ? new URL(ev.target.action)
          : new URL(window.location.origin + ev.target.dataset.action);
      url.searchParams.append("q", base);

      if (this.redirectMode) {
        window.location.href = url;
      } else {
        this.search();
      }
    },

    prepareSearchRequest() {
      const form = this.removeEmpty(this.form);
      const contents = ["events", "content", "knowledge-journey", "all"];
      this.form.content_type = [];

      contents.forEach((element) => {
        const existElement = form[element] ?? null;

        if (existElement) {
          form.content_type.push(element);
          delete form[element];
        }
      });

      return form;
    },

    async search() {
      const form = this.prepareSearchRequest(form);

      document.dispatchEvent(new CustomEvent("SearchHomeLoading", { detail: true }));
      let data = await ContentRepository.search(form);
      // data[0] = data[0].slice(0, 12);
      document.dispatchEvent(new CustomEvent("searchHomeLoaded", { detail: data }));
    },

    setFormSelects(selector) {
      this.form[selector.key] = selector.value;
    },
    getSearchRoute() {
      return TranslationService.transRoute("search-page");
    },
  },
};
</script>
