<template>
  <div>
    <div v-if="isLoading">{{ t("general.loading") }}......</div>
    <div v-if="!isLoading" class="custom-congress-carousel">
      <div v-if="!isMobile()">
        <!-- DESKTOP VERSION -->
        <V4Carousel :slidesToShow="3" :slidesToScroll="3" :name="'knowledge-journey'" :key="refreshItem">
          <template v-slot:items>
            <div v-for="(item, i) in list" v-bind:key="i">
              <KnowledgeJourneyItem v-bind="item"></KnowledgeJourneyItem>
            </div>
          </template>
        </V4Carousel>
      </div>
      <div v-else>
        <!-- MOBILE VERSION -->
        <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'knowledge-journey'" :key="refreshItem">
          <template v-slot:items>
            <div v-for="(item, i) in list" v-bind:key="i">
              <KnowledgeJourneyItem v-bind="item"></KnowledgeJourneyItem>
            </div>
          </template>
        </V4Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import TranslateService from "../../../core/services/translate.service";
import KnowledgeJourneyFactory from "../factories/KnowledgeJourney.factory";
import KnowledgeJourneyItem from "../knowledge-journey-item/KnowledgeJourneyItem.vue";
import V4Carousel from "../../carousel/V4Carousel.vue";
import axios from "axios";
import $ from "jquery";

export default {
  data() {
    return {
      isLoading: true,
      list: [],
      fulllist: [],
      refreshItem: 0,
      translator: TranslateService.create(),
    };
  },
  props: {
    slug: String,
    onlyWithProgress: Boolean,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const response = await this.getData();
      const results = response.data.data.map((k) => KnowledgeJourneyFactory.get(k));
      this.list = results;
      this.fulllist = results;
      this.isLoading = false;
      if (this.list.length == 0) {
        $("#knowledgejourney").css("display", "none");
        $("#knowledgejourney_menu").css("display", "none");
        $("#my_knowledge_journeys").css("display", "none");
        $("#my_knowledge_journeys_menu").css("display", "none");
        $("a[href='#knowledgejourney']").css("display", "none");
      } else {
        $(".my-knowledge-journey-count").text(`(${this.list.length})`);
      }
    },
    getData() {
      return axios.post("/get-knowlegde-journeys", {
        slug: this.slug,
        onlyWithProgress: this.onlyWithProgress,
      });
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    updateItem(title) {
      if (title != "") {
        this.list = this.fulllist;
        this.list = this.fulllist.filter(function (obj) {
          return obj.title.toLowerCase().indexOf(title.toLowerCase()) !== -1;
        });
        this.refreshItem++;
      } else {
        this.list = this.fulllist;
        this.refreshItem++;
      }
    },
    t(tag) {
      return this.translator.translate(tag);
    },
  },
  components: {
    KnowledgeJourneyItem,
    V4Carousel,
  },
};
</script>
