export default {
    get(content) {
        return {
            id: content?.id ?? '',
            question: content?.question ?? '',
            options: content?.options ?? '',
            currentAnswers: content?.currentAnswers ?? '',
            answers: content?.answers ?? '',
            agenda_session_id: content?.agenda_session_id ?? '',
            published:content?.published ?? '',
            status:content?.status ?? '',
            status_str:content?.status_str ?? '',
            type:content?.type ?? '',
            type_str:content?.type_str ?? '',

        };
    },

    getGroup(group) {
        return {
            polls: group?.polls ?? [],
            question: group?.title ?? 'Group',
            type_str: 'multiple',
            status: group?.status ?? '',
            status_str: group?.status_str ?? '',
            id: group?.id ?? '',
        }
    }
};
