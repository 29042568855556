<template>
<div>
    
    <div class="mb-5">
        <h3 class="v4-bold-title mb-2">Find content</h3>

        <EventForm 
        :therapeutics="therapeutics" 
        :content-type="contentType"
        :page="'knowledge'"></EventForm>
    </div>

    <div>
        <SearchFormResults :size="pageSize"></SearchFormResults>
    </div>
    
</div>
</template>

<script>

import SearchFormResults from '../../search-form-results/SearchFormResults.vue';
import EventForm from '../../events/event-form/EventForm.vue';

export default {
    name: 'KnowledgeJourneySearch',
    components: {
        SearchFormResults, EventForm
    },
    props: {
        therapeutics: Object,
        contentType: Array,
    },
    data() {
        return {
            isLoading: true,
            list: [],
            error: '',
            pageSize: 12,
            form: {
                events: false,
                content: true,
                all: false,
                bookmarks: false,
                q: '',
                page: 0,
                size: this.pageSize,
                therapeuticAreas: [],
                diseases: [],
                format: [],
                content_type: [],
                keywords: [],
                date: [],
                category: [],
                subcategory: [],
                congress: []
            },
        }
    },

    mounted() {},

    methods: {
        async get() {
            try {
                this.isLoading = true;
                this.error = '';
                 this.list = await ContentRepository.search({
                     content_type: ['knowledge-journey'],
                     format: [1]
                 });

           
            } catch (error) {
                this.list = [];
                this.error = "something happened";
            }
        }
    }
}
</script>
