export default {
    get(content) {
        return {
            id: content?.id ?? '',
            user_id: content?.user_id ?? '',
            agenda_session_id: content?.agenda_session_id ?? '',
            order: content?.order ?? '',
            question: content?.question ?? '',
            answer: content?.answer ?? '',
            email: content?.email ?? '',
            status: content?.status ?? '',
            answered: content?.answered ?? '',
            created_at: content?.created_at ?? '',
            updated_at: content?.updated_at ?? '',
            user: content?.user ?? '',
        };
    } 
};
