<template>
  <section id="custom-congress">
    <HeaderTemplate ref="headerTemplate"
      :sections="sections">
    </HeaderTemplate>
    <div v-if="dataReady" class= "congress-contents" id="congress-container">
        <SectionComponent :key="section.section_id" v-for="section in sections"
          :section="section" :congressId="congressId" :congress_slug="congress_slug">
        </SectionComponent>
    </div>

    <FooterTemplate></FooterTemplate>
    <ConfirmDialogue ref="leavingMMPModal"></ConfirmDialogue>
    <CustomLoader ref="customLoader"></CustomLoader>
    <SpeakerModel ref="showSpeakerModal"></SpeakerModel>
    <EventSubscriptionSticky :congressId="congressId" ></EventSubscriptionSticky>
  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import SectionComponent from './SectionComponent.vue';
import ConfirmDialogue from '../agenda/ConfirmDialogue.vue';
import CustomLoader from '../loader/CustomLoader.vue';
import SpeakerModel from '../modals/SpeakerModal.vue';
import HeaderTemplate from './partials/HeaderTemplate.vue';
import FooterTemplate from './partials/FooterTemplate.vue';
import EventSubscriptionSticky from '../events/event-subscription-sticky/EventSubscriptionSticky.vue';
import axios from 'axios';

export default {
  name: 'CustomCongress',
  components: { SectionComponent, ConfirmDialogue, CustomLoader, SpeakerModel, HeaderTemplate, FooterTemplate, EventSubscriptionSticky},
  props: {
      congressId: Number
  },
  data() {
    return {
      translator: TranslateService.create(),
      sections: [],
      congress_slug: '',
      dataReady: false,
      app_url: window.app.env.APP_URL,
      year: window.app.year
    };
  },
  mounted() {
    this.getSections();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async getSections() {
      const response = await axios.get(
        '/congresses/get-sections',
        { params:
          {
            congressId: this.congressId
          }
        }
      );

      this.sections = response.data.sections;
      this.congress_slug = response.data.congress_slug;
      this.dataReady = true;
    },
    formatHrefAnchorText(string) {
      return string.replace(/\s/g, '');
    }
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
