<template>
  <div class="congress-video-player">
    <section id="page-congress-live" class="full-vh" :class="{ 'page-congress-live-workshop': congressinfo.isWorkshop }"
      :data-techbox="congress.agenda_session.tech_box">
      <div class="" :class="{ 'page-congress-live-workshop': congressinfo.isWowza }">
        <!-- If workshop -->
        <div v-if="congressinfo.isWorkshop">
          <div class="congress-live-content-video col"
            :class="{ 'congress-live-content-video-ondemand': congressinfo.isFinished == 1 }"
            v-if="(congressinfo.hasStreamURL && congressinfo.isPlenary) || (!congressinfo.zoom_meet_url && !congressinfo.zoom_meet_url_guest)">
            <VideoPlayer :id="congress.agenda_session.id" :videoid="videoinfo.videoId" :videotype="videoinfo.videoType"
              :videouri="videoinfo.videoUri" :accountid="videoinfo.accountId" :playerid="videoinfo.playerId"
              :embedid="videoinfo.embedId" :datasourcetype="videoinfo.dataSourceType" :datatype="videoinfo.dataType"
              :datatracks="[]" :isios="isios" :hasondemandrestrictions="hasondemandrestrictions"></VideoPlayer>
            <div class="congress-live-controls-info"></div>
          </div>
          <div v-else>
            <div class="congress-live-content-video congress-live-content-video-zoom col"
              v-if="congressinfo.hasZoomCode">
              <iframe v-if="congress.agenda_session.meeting_platform == 'ZOOM'" id="zoom-frame"
                :src="congressinfo.zoom_meet_url"
                style="position:absolute;top:44px;left:0;width:100%;height:100%;border:0;" title="Zoom"
                allow="microphone; camera">
              </iframe>
              <webex v-if="congress.agenda_session.meeting_platform == 'WEBEX'" :src="congressinfo.zoom_meet_url_guest"
                :jwt="jwt" :webex-data="webexData" :is-moderator="congressinfo.isModerator"
                :event-url="congressinfo.event_url" title="WebEX" allow="microphone; camera">
              </webex>
              <div class="congress-live-controls-info"></div>
            </div>
          </div>
        </div>
        <!-- If not workshop -->
        <div v-else>
          <div class="congress-live-content-video col" :class="{
              'congress-live-content-video-ondemand': congressinfo.isFinished || congressinfo.isExternalCongress,
              'congress-live-content-video-zoom': congressinfo.isWowza
            }">
            <VideoPlayer :id="congress.agenda_session.id" :videoid="videoinfo.videoId" :videotype="videoinfo.videoType"
              :videouri="videoinfo.videoUri" :accountid="videoinfo.accountId" :playerid="videoinfo.playerId"
              :embedid="videoinfo.embedId" :datasourcetype="videoinfo.dataSourceType" :datatype="videoinfo.dataType"
              :datatracks="[]" :isios="isios" :hasondemandrestrictions="hasondemandrestrictions"></VideoPlayer>
            <div class="congress-live-controls-info"></div>
          </div>
        </div>
      </div>
    </section>
    <CongressLiveFinishedSession></CongressLiveFinishedSession>
    <!-- MESSAGE TO THE AUDIENCE -->
    <div id="message-to-the-audience" style="display: none;">
      <div class="message-to-the-audience-close">x</div>
      <div class="message-to-the-audience-title">{{ t('live.moderator_message') }}</div>
      <div class="message-to-the-audience-message"></div>
    </div>
  </div>
</template>
<script>
import CongressLiveFloatingMenu from './CongressLiveFloatingMenu.vue';
import LiveModalComponent from './LiveModalComponent.vue';
import TranslateService from '../../core/services/translate.service.js';
import PageStayController from '../../page-stay/PageStayController';
import VideoPlayer from '../multimedia/VideoPlayer.vue';
import CongressLiveFinishedSession from './CongressLiveFinishedSession.vue'
import $ from 'jquery';
import Webex from './Webex.vue';

export default {
  props: {
    congressinfo: Object,
    videoinfo: Object,
    congress: Object,
    jwt: String,
    webexData: Object,
    isios: {
      type: Boolean,
      default: false
    },
    hasondemandrestrictions: {
      type: Boolean,
      default: false
    },
  },
  components: {
    CongressLiveFloatingMenu,
    LiveModalComponent,
    VideoPlayer,
    CongressLiveFinishedSession,
    Webex,
  },
  data() {
    return {
      isPause: false,
      isPlay: true,
      translator: TranslateService.create(),
      isIOS: this.isios ? true : false,
      hasOnDemandRestricions: this.hasondemandrestrictions ? true : false
    };
  },
  mounted() {
    this.initStayPage();
  },
  methods: {
    clickPayPauseButton(type) {
      if (type == 'play') {
        this.isPlay = false;
        $('.congress-live-controls-info').hide();
      }
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    initStayPage() {
      PageStayController.startStayPageTime('agenda-live', this.congress.agenda_session.id);
    }
  }
};
</script>
