import EventFactory from '../../../modules/events/factories/Event.factory';

export default {
    get(content) {
        const formatted_dates = EventFactory.formatDates(content);
        return {
            id: content?.id ?? '',
            title: content?.title ?? '',
            description: content?.description ?? '',
            slug: content?.slug ?? '',
            hasLiveSessions: content?.hasLiveSessions ?? false,
            image: content?.image ?? '',
            link: '/'+window.app.env.locale+'/multimedia/'+content?.id ?? '',
            date_start: content?.date_start ?? '',
            date_end: content?.date_end,
            ...formatted_dates,
            keywords: content?.shortened_keywords ?? [],
            shortened_keywords: content.shortened_keywords === undefined ? [] : content?.shortened_keywords.splice(0, 5),
            is_new: content?.isNew ?? false,
            is_live: false,
            is_on_demand: false,
            is_multimedia: true,
            multimedia_type: this.parseTitle(content?.multimedia_type) ?? "",
            multimedia_type_str: this.parseTitle(content?.multimedia_type) ?? "",
            type: content?.type ?? "",
            saved: content?.saved == 1 ? true : false,
            liked: content?.liked == 1 ? true : false,
            disliked: content?.disliked == 1 ? true : false,
            open_preregister: content?.open_preregister ?? false,
            type : content?.type ?? "",
            card_color : content?.card_color ?? "",
            card_type: content?.card_type ?? "",
            card_icon: content?.card_icon ?? '',
        };
    },
    

    getLink(content) {
        return {
            link: '/'+window.app.env.locale+'/multimedia/'+content?.id ?? '',
        };
    },
    parseTitle(tag){
        switch(tag){
            case 'pdf document':
                return 'document'
            case 'html':
                return 'web'
            default:
                return tag;
        }
    }
};
