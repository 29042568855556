<template>
    <form action="/v4/search" @submit.prevent="onFormSubmit">
    <div v-if="!isDomReady">
        {{ t("congresses.loading") }}
    </div>
    <div class="row" :class="{'opacity-0': !isDomReady}">
        <div class="col-12">
            <div class="my-4 form-second-row form-second-row-active">
                <div class="row">
                    <div class="col-2 v4-select-container" v-if="therapeuticsOptions.length">
                        <FormSelect @input="onSelectChange" v-bind="{id: this.from_type+'-qTherapeuticAreas', name: this.from_type+'-qTherapeuticAreas', title: t('form.therapeutic_areas'), defaultOptions: therapeuticsOptions, multiple: true}"></FormSelect>
                    </div>
                    <div class="col-2 v4-select-container" v-if="diseasesOptions.length">
                        <FormSelect @input="onSelectChange" v-bind="{id: this.from_type+'qPathologies', name: this.from_type+'qPathologies', title: t('form.diseases'), defaultOptions: diseasesOptions, multiple: true}"></FormSelect>
                    </div>
                    <div class="col-2 v4-select-container" v-if="keywordsOptions.length">
                        <FormSelect @input="onSelectChange" v-bind="{id: this.from_type+'qKeywords', name: this.from_type+'qKeywords', title: t('home.search.keywords'), defaultOptions: keywordsOptions, multiple: true}" v-model="kwword"></FormSelect>
                    </div>
                    <div class="col-2 v4-select-container" v-if="eventType.length">
                        <FormSelect @input="onSelectChange" v-bind="{id: this.from_type+'eventtype', name: this.from_type+'eventtype', title: t('form.event-type'), defaultOptions: eventType, multiple: false}"></FormSelect>
                    </div>
                     <div class="col-2 v4-select-container">
                        <DatePicker ref="datepicker"></DatePicker>
                    </div>

                </div>
            </div>
            <div class="d-flex justify-content-center search">
                <input v-model="form.q" type="text" name="q" class="v4-search-query" :placeholder="t('new-home.search.write-in')">
                <button class="ml-3 v4-btn-primary" type="button" @click="searchList">
                    <i class="bi bi-search"></i>
                    <p>{{t('general.search')}}</p>
                </button>
            </div>

        </div>
    </div>
    </form>
</template>

<script>
import ContentRepository from '../home-form/repositories/Content.repository';
import UtilsService from '../../core/services/utils.service';
import DatePicker from '../form/datepicker/DatePicker.vue';
import TranslateService from '../../core/services/translate.service.js';
import FormSelect from '../form/select/FormSelect.vue';
import moment from 'moment';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import axios from 'axios';
import $ from 'jquery';

export default {
    name: 'ProfileSearchFields',
    components: { 
        DatePicker,
        FormSelect,
    },
    props: {
            therapeutics: Object,
            contentType: Array,
            redirectMode: Boolean,
            userTherapeuticAreas: Array,
            slug : String,
            from_type : String,
        },
    data() {
            return {
                therapeuticsOptions: [],
                contentTypeOptions: UtilsService.mapObjectToArray(this.contentType),
                keywordsOptions: [],
                diseasesOptions: [],
                eventType:[],
                resultsCount: 0,
                translator : TranslateService.create(),
                isDomReady: false,
                keywordsOption: [],
                form: {
                    events: false,
                    content: false,
                    all: false,
                    bookmarks: false,
                    q: '',
                    qTherapeuticAreas: [],
                    qPathologies: [],
                    format: [],
                    content_type: [],
                    diseases: [],
                    date: [],
                    timezones: [],
                    qKeywords: [],

                },
                formAux: {},
                search_fields:{},
                eventArray: [],
                eventKey: [],
                kwword:"",
            }
        },

        mounted() {
            this.prepareSelects();
            document.addEventListener("changeselectpicker", (data) => {
                this.setFormSelects(data.detail);
            });
            /* this.$root.$on('changeselectpicker', (data) => {
                this.setFormSelects(data);
            }); */
            // this.$refs.datepicker.range.start = null;
            // this.$refs.datepicker.range.end = null;
            $('.selectpicker').selectpicker('refresh');
            document.addEventListener("leavingMMPModal", (event) => {
                this.resultsCount = event.detail;
        });
            // this.$root.$on('SearchCount', (data) => {
            //     this.resultsCount = data;
            // });
        },

        methods: {
           onInputChecked(checkbox) {
               this.setFormInputFromDTO(checkbox)
           },

           onSwitchToggle(toggleData) {
               this.setFormInputFromDTO(toggleData)
           },

           onSelectChange(selected) {
               console.log(selected)
               this.setFormInputFromDTO(selected)
           },

           setFormInputFromDTO(inputData) {
                this.form[inputData.key] = inputData.value;
           },

           onFormSubmit(ev) {
                this.search();
           },
           t(tag){
                return this.translator.translate(tag)
            },
            async prepareSelects() {
                let filters = await axios.get("/profile/dropdown-values");
                filters = filters.data.data;
                const diseases = filters.diseases;
                const languages = filters.languages;
                const therapeutics = filters.therapeuticAreas;
                const keywords = filters.keywords;
                const timezones = filters.timezones;
                // this.diseasesOptions = Object.values(diseases);
                // this.therapeuticsOptions = Object.values(therapeutics);
                // this.keywordsOptions = Object.values(keywords);
                let eventTypes = filters.eventTypes;
                // console.log(filters.eventTypes)
                // eventTypes.forEach((obj , index) => {
                //     this.eventKey[index] = obj.code;
                //     this.eventArray[obj.code] = obj.name;
                // });

                this.keywordsOption = Object.values(keywords);

                // console.log(this.eventType)
                this.keywordsOptions = this.mapSelect(UtilsService.mapObjectToArray(keywords));
                this.therapeuticsOptions = this.mapSelect(UtilsService.mapObjectToArray(therapeutics));
                this.diseasesOptions = this.mapSelect(UtilsService.mapObjectToArray(diseases));
                this.eventType = this.mapSelectEventTypes(eventTypes);
                this.eventType[2] = {
                    id : "both",
                    name : this.t('form.event-type')
                };

                $('.selectpicker').selectpicker('refresh');
                this.isDomReady = true;
            },

            mapSelect(collection) {

                return collection.map(i => {

                    return {
                        id: i.id,
                        name: i.name,
                    }
                });
            },

             mapSelectEventTypes(collection) {

                return collection.map(i => {

                    return {
                        id: i.code,
                        name: i.name,
                    }
                });
            },

           async search() {
               this.form.contentType = this.contentType;
               const data = await ContentRepository.search(this.form);
               document.dispatchEvent(new CustomEvent("SearchEvent", { detail: data }));                    
            //    this.$root.$emit('SearchEvent', data);
           },
            setFormSelects(selector){
                this.form[selector.key] = selector.value;
                this.formAux[selector.key] = selector.name;
            },
            searchList(){
                let selected_keywords = [];
                let selected_diseases = [];
                let selected_therea = [];

                const filterCategory = Object.keys(this.formAux).join(', ');
                const filterValue = Object.values(this.formAux).flat().join(', ');
                GoogleAnalytics.sendFilterEvent(filterValue, filterCategory, filterValue);

                if ( this.form["qKeywords"] !== undefined && this.form["qKeywords"].length > 0 ) {
                    let that = this
                    selected_keywords = this.form["qKeywords"]
                }

                if ( this.form["qTherapeuticAreas"] !== undefined && this.form["qTherapeuticAreas"].length > 0 ) {
                    let that = this
                    selected_therea = this.form["qTherapeuticAreas"]
                }
                if ( this.form["qPathologies"] !== undefined && this.form["qPathologies"].length > 0 ) {
                    selected_diseases = this.form["qPathologies"];
                }
                this.search_fields["q"] = this.form.q;
                this.search_fields["keywords"] = selected_keywords;
                this.search_fields["diseases"] = selected_diseases;
                this.search_fields["therea"] = selected_therea;
                this.search_fields["event_type"] = this.form.eventtype !== undefined ? this.form.eventtype : "";
                this.search_fields["date"] = this.form.date;
                this.search_fields["from_date"] = moment(this.$refs.datepicker.range.start).format("YYYY-MM-DD");
                this.search_fields["to_date"] = moment(this.$refs.datepicker.range.end).format("YYYY-MM-DD");
                // console.log(this.$refs.datepicker)
                if ( this.from_type == "profsub" || this.from_type == "bookmark") {
                    this.$emit("titlechange", this.search_fields)
                    GoogleAnalytics.sendSearchEvent(this.form.q, this.resultsCount, this.formatFromType(this.from_type));
                }
                // this.$root.$emit('SearchTerm', this.form.q);
                document.dispatchEvent(new CustomEvent("SearchTerm", { detail: this.form.q }));
            },
            formatFromType(){
                if ( this.from_type == "profsub" ) {
                    return "MySubscriptions"
                } else if ( this.from_type == "bookmark" ) {
                    return "MyBookmarks"
                }
            },
            updatekeyword(id) {
                if ( this.from_type == "profsub" ) {
                    this.form["qKeywords"].push(id.toString());
                    $("#qKeywords").val(this.form["qKeywords"]);
                    $('.selectpicker').selectpicker('refresh');
                    if ( this.from_type == "profsub") {
                        this.searchList()
                    }
                }
            },
            updateBookmarkkey(obj) {
                if ( this.from_type == "bookmark" ) {
                    this.form["qKeywords"].push(obj.key_id.toString());
                    // $("#qKeywords").val(this.form["qKeywords"]);
                    $('#qKeywords option[value="'+obj.key_id.toString()+'"]').attr("selected", "selected");
                    $('.selectpicker').selectpicker('refresh');
                    this.searchList();
                }
            }
        }
    }
</script>
<style scoped>
.opacity-0 {
    opacity: 0;
}

@media only screen and (max-width: 600px) {
    .search{
        height: 120px;
        display: grid !important;
    }
}
</style>
