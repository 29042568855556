<template>
  <section>
    <div class="register-first-step-container">
      <div class="register-event-container" v-if="slug">
        <section>
          <section>
            <section class="event-title-wrapper">
              <div v-if="!congress.hideWelcomeText" class="welcome-to">{{ t('congresses.welcome') }}</div>
              <div v-if="checkTitleLogo()">
                <img :src="parseImage(congress.title_logo)" alt="Logo" />
              </div>
              <div v-else class="event-title" v-html="congress.name"></div>
            </section>
            <section class="v4-satellital-tabs" v-if="congress">
              <SatellitalTab :tabs="tabs" :slug="slug" :congressId="congress.id"></SatellitalTab>
            </section>
          </section>
        </section>
      </div>

      <div class="register-section-country-container">
        <button class="register-section-country-container__hidden-register-container float-right" @click="hiddenRegisterContainer()">
          <svg id="hidden-register-container" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" stroke="currentColor" stroke-width="2"/>
          </svg>
        </button>
        <div v-if="!email" id="register-container" class="register-section-container text-center d-flex align-items-center justify-content-center">
          <div class="register-section-country-container__hcp-column w-80">
            <h2 class="align-center mb-4"> {{ t('register-mmp.hcp-title') }} </h2>
            <p>{{ t('register-mmp.hcp-text-event') }}</p>
            <div class="register-section-country-container__hcp-column__form d-flex flex-row align-items-center">
              <div class="register-section-country-container__hcp-column__form__select_country">
                <select
                  class="select selectpicker"
                  v-model="selectedCountryId"
                  @change="onCountrySelected"
                  data-live-search="true"
                  :title="t('register-mmp.country-select-placeholder')"
                  :data-live-search-placeholder="t('general.search')"
                  data-testid="select-country-first-step"
                >
                  <option default value="-1" hidden>{{ t('satellital.select-a-country') }}</option>
                  <option :key="index" v-for="(country, index) of countries" :value="country.id">{{ country.name }}</option>
                </select>
              </div>
              <button class="register-section-country-container__hcp-column__form__register-button v4-btn-secondary ml-3"
                :class="{ 'disabled': selectedCountryId == -1 }"
                :disabled="selectedCountryId == -1"
                @click="register()"
                data-testid="register-first-step-button"
              >{{ t('register-mmp.register') }}
              </button>
              <button class="register-section-country-container__hcp-column__form__login-button v4-btn-primary ml-3"
                :class="{ 'disabled': selectedCountryId == -1 }"
                :disabled="selectedCountryId == -1"
                data-testid="login-first-step-button"
                @click="login()"
              >{{ t('register-mmp.login') }}</button>
            </div>
            <div class="register-section-country-container__hcp-column__integration-message mt-4" v-if="integrationMessage">
              {{ integrationMessage }}
            </div>
          </div>

          <div class="register-section-country-container__amgen-staff-column w-50 d-flex flex-column align-items-center">
            <h2 class="align-center mb-4"> {{ t('register-mmp.amgen-staff-title') }} </h2>
            <button class="v4-btn-primary ml-3"
              @click="oktaLogin()">{{ t('register-mmp.okta-access-button') }}</button>
          </div>
        </div>
        <div v-else>
          <div id="register-container" class="register-section-logged">
            <button v-if="!isAlreadyRegisteredOnCongress" class="register-section-logged__next-button v4-btn-primary" @click="eventRegisterStep()">{{ email ? t('register-mmp.next-logged') : t('register-mmp.next') }}</button>
            <button v-else class="register-section-logged__next-button v4-btn-primary" @click="redirectToEvent()">{{ t('register-mmp.next-registered') }}</button>
            <button v-if="hasOnBehalfRole || isAmgenUser" class="register-section-logged__behalf-button v4-btn-primary" @click="onBehalf()">{{ t('register-mmp.on-behalf') }}</button>
          </div>
        </div>
      </div>
    </div>
    <SatellitalAgendaModal></SatellitalAgendaModal>
    <SatellitalSpeakersModal></SatellitalSpeakersModal>
  </section>
</template>

<script>
import SatellitalTab from '../satellital/SatellitalTab.vue';
import SatellitalAgendaModal from '../satellital/modals/SatellitalAgendaModal.vue';
import SatellitalSpeakersModal from '../satellital/modals/SatellitalSpeakersModal.vue';
import TranslateService from '../../core/services/translate.service.js';
import TranslationService from '../../core/services/translation.service';
import PageStayController from '../../page-stay/PageStayController.js';
import ActivityService from '../../core/services/activity.service';
import $ from 'jquery';
import axios from 'axios';

export default {
  components: {
    SatellitalTab,
    SatellitalAgendaModal,
    SatellitalSpeakersModal
  },
  props: {
    receivedCountries: Array,
    email: String,
    countryId: Number,
    slug: String,
    congress: Object,
    tabs: Array,
    hasOnBehalfRole: Boolean,
    isAlreadyRegisteredOnCongress: Boolean,
  },
  data() {
    const selectedCountryId = '-1';
    return {
      translator: TranslateService.create(),
      activitySvc: ActivityService.create(),
      country: {},
      selectedCountryId,
      countries: this.receivedCountries,
      integrationMessage: '',
      isAmgenUser: window.user ? window.user.is_amgen : 0,
    };
  },
  mounted() {
    if (!this.hasOnBehalfRole && this.isAlreadyRegisteredOnCongress) {
      document.location.href = window.app.env.APP_URL;
    }
    document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
    $('body').find('.selectpicker').selectpicker('render');

    document.addEventListener("mmp-footer-show", (data) => {
      const registerFooter = document.getElementsByClassName('register-section-country-container');
      if (data.detail && registerFooter.length > 0) {
        registerFooter[0].classList.add('mmp-footer-show');
      } else {
        registerFooter[0].classList.remove('mmp-footer-show');
      }
    });

  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    redirectToEvent() {
      document.location.href = TranslationService.transRoute('event-detail', {slug: this.slug});
    },
    getSelectedCountry() {
      return this.countries.find(country => country.id === this.selectedCountryId);
    },
    register() {
      if (this.country.idm) {
        this.integrationMessage = 'Pending to integrate IDM';
      } else {
        this.saveActivity('go-to-register');
        this.$emit('next-step', 2);
        this.$emit('selected-country', this.selectedCountryId);
      }
    },
    login() {
      if (this.country.idm) {
        this.integrationMessage = 'Pending to integrate IDM';
      } else {
        this.saveActivity('go-to-login');
        const baseUrl = window.app.env.APP_URL + '/mmp-login?country=' + this.country.id;
        const url = this.slug ? baseUrl + '&slug=' + this.slug : baseUrl;
        document.location.href = url;
      }
    },
    oktaLogin() {
      axios.get(
        '/login/okta'
      ).then(response => {
        document.location.href = response.data.oktaRedirect;
      });
    },
    onCountrySelected() {
      this.country = this.getSelectedCountry();
    },
    eventRegisterStep() {
      this.$emit('next-step', 2);
      this.$emit('selected-country', this.countryId);
    },
    onBehalf() {
      this.$emit('next-step', 2);
      this.$emit('on-behalf', true);
    },
    saveActivity(activityType) {
      this.activitySvc.saveActivity(activityType, {
        model_type: 'country',
        model_id: this.selectedCountryId,
        congress_id: this.congress.id
      });
    },
    checkTitleLogo() {
      return this.congress.title_logo && this.congress.title_logo != 'unset' && this.congress.title_logo != '';
    },
    parseImage(img) {
      if (img && img.substr(0, 1) != '/') {
        img = '/' + img;
      }
      return img;
    },
    hiddenRegisterContainer() {
      var div = document.getElementById('register-container');
      var arrow = document.getElementById('hidden-register-container');
      $(div).toggleClass('hidden');
      $(arrow).toggleClass('collapsed');
      arrow.classList.toggle("fa-rotate-180");
    }
  }
};
</script>
