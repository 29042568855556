
import RegisterSteps from '../objects/v4/modules/register/RegisterSteps.vue';
import RegisterFirstStep from '../objects/v4/modules/register/RegisterFirstStep.vue';
import RegisterFinalStep from '../objects/v4/modules/register/RegisterFinalStep.vue';
import RegisterEventFormStep from '../objects/v4/modules/register/RegisterEventFormStep.vue';
import RegisterEventForm from '../objects/v4/modules/register/components/RegisterEventForm.vue';
import VParticleBigBall from '../objects/v4/modules/particle-ball/VParticleBigBall.vue';

import SatellitalTab from '../objects/v4/modules/satellital/SatellitalTab.vue';
import SatellitalCongressAgenda from '../objects/v4/modules/satellital/components/SatellitalCongressAgenda.vue';
import SatellitalCongressSpeakers from '../objects/v4/modules/satellital/components/SatellitalCongressSpeakers.vue';
import SatellitalAgendaModal from '../objects/v4/modules/satellital/modals/SatellitalAgendaModal.vue';
import SatellitalSpeakersModal from '../objects/v4/modules/satellital/modals/SatellitalSpeakersModal.vue';


const vueComponents = [
    ['RegisterSteps', RegisterSteps],
    ['RegisterFirstStep', RegisterFirstStep],
    ['RegisterFinalStep', RegisterFinalStep],
    ['RegisterEventFormStep', RegisterEventFormStep],
    ['RegisterEventForm', RegisterEventForm],
    ['VParticleBigBall', VParticleBigBall],

    ['SatellitalTab', SatellitalTab],
    ['SatellitalCongressAgenda', SatellitalCongressAgenda],
    ['SatellitalCongressSpeakers', SatellitalCongressSpeakers],
    ['SatellitalAgendaModal', SatellitalAgendaModal],
    ['SatellitalSpeakersModal', SatellitalSpeakersModal],
]

export default vueComponents
