import axios from "axios";

export default {
    async getFiltered(filters) {
        filters = filters ?? [];
        
        const url = this.getFilteredUrl(filters);
        const response = await axios.get(url);
        return response.data.data;
    },
    
    getFilteredUrl(filters) {
        const args = filters ? filters.join(',') : '';
        return `/filtered-congresses/${args}`;
    },

    async getEventsByDate() {
        const response = await axios.get(
            this.getEventsByDateUrl()
        );
        return response.data.data;
    },

    getEventsByDateUrl() {
        return '/accesible-congresses';
    },

    async getMyNextEvents() {
        const response = await axios.get(
            this.getMyNextEventsUrl()
        );
        return response.data;
    },

    getMyNextEventsUrl() {
        return '/carousel-congresses';
    },

    async getLiveEvents(slug = null, congressId = null) {
        const response = await axios.get(
            this.getLiveEventsUrl(), {params :{
                slug: slug,
                congressId: congressId
            }}
        );

        return response.data.data;
    },

    getLiveEventsUrl() {
        return '/accesible-lives';
    },
};