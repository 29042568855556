/**
  webvtt.js: Webvtt creator (chapters and captions are supported for now)
  by hikari :)
*/
export class WebVTTCreator {
  chapters = [];
  captions = [];
  docType = "chapters";
  constructor(docType) {
    this.docType = docType;
    this.chapters = [];
    this.captions = [];
  }

  checkParams(...params) {
    return params.filter(param => param !== null && param !== undefined).length > 0;
  }

  transformTime(time) {
    const z = n => n < 10 ? `0${n}` : `${n}`;
    const y = n => n < 100 ? (n < 10 ? `00${n}` : `0${n}`) : `${n}`;
    return `${z(Math.floor(time/3600))}:${z(Math.floor(time/60)%60)}:${z(Math.floor(time%60))}.${y((time * 1000) - (Math.floor(time) * 1000))}`;
  }

  addChapter(start, end, text) {
    if(!this.checkParams(start, end, text)) {
      return {success: false, message: "Missing chapters information"};
    }
    this.chapters.push({start, end, text});
    return {success: true, message: "ok"};
  }

  addCaption(start, end, text) {
    if(!this.checkParams(start, end, text)) {
      return {success: false, message: "Missing captions information"};
    }
    this.captions.push({start, end, text});
    return {success: true, message: "ok"};
  }

  export() {
    let doc = "WEBVTT";
    if (this.docType === "chapters") {
      this.chapters.forEach(chapter => {
        doc += `\n\n${this.transformTime(chapter.start)} --> ${this.transformTime(chapter.end)}\n${chapter.text}`;
      });
    } else {
      this.captions.forEach(chapter => {
        doc += `\n\n${this.transformTime(chapter.start)} --> ${this.transformTime(chapter.end)}\n<v word>${chapter.text}`;
      });
    }
    return doc;
  }

  toDataURL() {
    const data = this.export();
    let binaryArray = [];
    for (var i = 0; i < data.length; i++) {
      binaryArray.push(data.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(binaryArray)], {type: 'text/vtt'});
    return URL.createObjectURL(blob);
  }
}
