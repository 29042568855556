<template>
    <div v-if="dataReady" class="our-symposia-section__content d-flex" 
      :class="alignment === 'Left'
      ? (!isMobile() ? 'flex-row' : 'flex-column')
      : (!isMobile() ? 'flex-row-reverse' : 'flex-column-reverse')
    ">
        <div class="our-symposia-section__content__video" :style="alignment === 'Left' ? 'margin-right: -40px; margin-left: 40px;' : 'margin-right: 40px; margin-left: -40px;'">
          <VideoPlayer
              v-if="multimediaData.external_link"
              :id="videoPlayerId"
              :videoid="videoData.videoId"
              :videotype="typeVideo"
              class="single-symposia-video-player"
              :videouri="multimediaData.external_link"
              :accountid="videoData.accountId"
              :playerid="videoData.playerId"
              :embedid="'default'"
              :datasourcetype="0"
              :datatype="0"
          ></VideoPlayer>
        </div>
        <div class="our-symposia-section__content__description-box">
          <div class="our-symposia-section__content__description-box__text" v-html="multimedia.title"></div>
          <div class="our-symposia-section__content__description-box__speakers-button d-flex flex-row" @click="openSpeakersModal()">
              <i class="bi bi-people"></i>
              <span>{{ speakersWording }}</span>
          </div>
        </div>
      </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import TranslateService from '../../../core/services/translate.service';
import V4Carousel from '../../carousel/V4Carousel.vue';
import VideoPlayer from '../../multimedia/VideoPlayer.vue';
export default {
  name: 'SymposiaContent',
  components: {
    V4Carousel,
    VideoPlayer,
  },
  props: {
    multimedia: Object,
    alignment: String,
    speakersWording: String,
  },
  setup(props) {
    const translator = TranslateService.create();
    const t = (tag) => translator.translate(tag);
    const alignment = props.alignment;
    const multimediaData = props.multimedia;
    const videoData = ref({});
    const videoPlayerId = multimediaData.id + '_' + 'symposia-content-video';
    const speakersWording = props.speakersWording;
    const typeVideo = ref(1);
    const dataReady = ref(false);

    
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const prepareVideoData = (videoUri) => {
        const congress_video_info = {};
        if (/brightcove/i.test(videoUri)) {
            congress_video_info.videoType = 1;
            congress_video_info.parsedUri = new URL(videoUri);
            congress_video_info.explodedPath = congress_video_info.parsedUri.pathname.split('/');
            const query = Object.fromEntries(congress_video_info.parsedUri.searchParams.entries());
            congress_video_info.accountId = congress_video_info.explodedPath[1];
            congress_video_info.videoId = query.videoId;
            congress_video_info.player = congress_video_info.explodedPath[2].split('_');
            congress_video_info.playerId = congress_video_info.player[0];
            videoData.value = congress_video_info;
        }
    }

    const openSpeakersModal = () => {
      document.dispatchEvent(new CustomEvent("showSymposiaSpeakersModal", {
        detail: {
          multimediaId: multimediaData.id,
        }
      }));
    }

    const getMultimediaVideoType = (multimediaVideoLink) => {
      if (/brightcove/i.test(multimediaVideoLink)) {
        return 1;
      } else if (/vimeo.com/i.test(multimediaVideoLink)) {
          return 2;
      }else if (/cdn3.wowza.com/i.test(multimediaVideoLink) || /embed.wowza.com/i.test(multimediaVideoLink)) {
          return 4;
      } else {
          return 5;
      }
    }

    onMounted(() => {
        prepareVideoData(multimediaData.external_link);
        typeVideo.value = getMultimediaVideoType(multimediaData.external_link);
        dataReady.value = true;
    });
    
    return {
      t,
      isMobile,
      alignment,
      multimediaData,
      VideoPlayer,
      videoPlayerId,
      videoData,
      speakersWording,
      openSpeakersModal,
      typeVideo,
      dataReady,
    };
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>