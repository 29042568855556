<template>
  <form :action="getSearchRoute()" @submit.prevent="onFormSubmit">
    <div v-if="!isDomReady">{{ t("general.loading") }}...</div>
    <div class="row" :class="{ 'opacity-0': !isDomReady }">
      <div class="col-12">
        <div class="row">
          <div class="col-4 pl-2">
            <div class="v4-form-switch--bookmark" style="width: 250px">
              <FormSwitch
                ref="bookmarksRef"
                @input="onSwitchToggle"
                v-bind="{
                  id: 'bookmarks',
                  label: t('new-home.search.only-bookmarks'),
                }"
              ></FormSwitch>
            </div>
          </div>
        </div>

        <div class="my-4 form-second-row form-second-row-active">
          <div class="row">
            <div
              class="col-2 v4-select-container"
              v-if="therapeuticsOptions.length"
            >
              <FormSelect
                @input="onSelectChange"
                v-bind="{
                  id: 'qTherapeuticAreas',
                  title: t('form.therapeutic_areas'),
                  defaultOptions: therapeuticsOptions,
                  multiple: true,
                }"
                :userTA="userTherapeuticAreas"
              ></FormSelect>
            </div>
            <div
              class="col-2 v4-select-container"
              v-if="pathologiesOptions.length"
            >
              <FormSelect
                @input="onSelectChange"
                v-bind="{
                  id: 'qPathologies',
                  title: t('form.diseases'),
                  defaultOptions: pathologiesOptions,
                  multiple: true,
                }"
              ></FormSelect>
            </div>
            <div
              class="col-2 v4-select-container"
              v-if="showSelector('format') && formatOptions.length"
            >
              <FormSelect
                @input="onSelectChange"
                v-bind="{
                  id: 'format',
                  title: t('form.format'),
                  defaultOptions: formatOptions,
                  multiple: true,
                }"
              ></FormSelect>
            </div>
            <div
              class="col-2 v4-select-container"
              v-if="showSelector('qContentType') && multimediaOptions.length"
            >
              <FormSelect
                @input="onSelectChange"
                v-bind="{
                  id: 'qContentType',
                  title: t('form.content-type'),
                  defaultOptions: multimediaOptions,
                  multiple: true,
                }"
              ></FormSelect>
            </div>
            <div
              class="col-2 v4-select-container"
              v-if="keywordsOptions.length"
            >
              <FormSelect
                @input="onSelectChange"
                v-bind="{
                  id: 'qKeywords',
                  title: t('home.search.keywords'),
                  defaultOptions: keywordsOptions,
                  multiple: true,
                }"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container">
              <DatePicker ref="datepicker" style="color: #000;"></DatePicker>
            </div>
          </div>
          <div class="pt-3 text-right">
            <i class="bi bi-trash" style="color: #2dbcb6"></i>
            <a
              href="javascript:void(0)"
              class="v4-action-link"
              @click="resetFilters()"
              >{{ t("new-home.search.delete-filters") }}</a
            >
          </div>
        </div>

            <div class="d-flex justify-content-center search">
              <input
            v-model="form.q"
            type="text"
            name="q"
            class="v4-search-query"
                       
            :placeholder="t('new-home.search.write-in')"
          />
              <button class="ml-3 v4-btn-primary" @click="searchList">
                <i class="bi bi-search"></i>
                <p>{{  t("general.search")  }}</p>
              </button>
            </div>

        <div
          class="my-4 form-second-row form-second-row-active"
          style="height: fit-content; margin-bottom: 0 !important"
        >
          <div class="row" style="justify-content: flex-end">
            <div class="col-2 v4-select-container">
              <select
                @change="changeSortBy"
                id="sortBy"
                name="sortBy"
                :placeholder="t('general.sort-by')"
                class="selectpicker"
                data-show-subtext="true"
              >
                <option
                  value="best_match"
                  :data-subtext="t('general.relevance')"
                >
                  {{ t("general.sort-by") }}
                </option>
                <option selected value="date" :data-subtext="t('general.date')">
                  {{ t("general.sort-by") }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ContentRepository from "../../home-form/repositories/Content.repository";
import UtilsService from "../../../core/services/utils.service";
import DatePicker from "../../form/datepicker/DatePicker.vue";
import TranslateService from "../../../core/services/translate.service.js";
import TranslationService from "../../../core/services/translation.service.js";
import FormSwitch from "../../form/switch/FormSwitch.vue";
import FormSelect from "../../form/select/FormSelect.vue";
import moment from "moment";
import axios from "axios";
import $ from "jquery";
import { useSelectPickerStore } from "../../../store/selectPicker.store.js";
import { watch, computed, reactive, ref } from "vue";
import GoogleAnalytics from '../../../GoogleAnalytics.js';

export default {
  setup(props) {
    const store = useSelectPickerStore();
    const changeSelectPickerData = computed(() => store.selectPickerData);

    const form = reactive({
      events: false,
      content: false,
      all: false,
      bookmarks: false,
      size: 12,
      q: "",
      qTherapeuticAreas: [],
      qPathologies: [],
      format: [],
      content_type: [],
      qContentType: [],
      qKeywords: [],
      date: [],
      qStartDate: null,
      qEndDate: null,
      sortBy: "date",
      initialSearch: false,
    });

    const setFormSelects = (selector) => {
      form[selector.key] = selector.value;
    };

    watch(changeSelectPickerData, (newValue) => {
      setFormSelects(newValue);
    });

    return {
      changeSelectPickerData,
      setFormSelects,
      form,
    };
  },
  components: {
    DatePicker,
    FormSwitch,
    FormSelect,
  },
  props: {
    therapeutics: Object,
    contentType: Array,
    redirectMode: Boolean,
    userTherapeuticAreas: Array,
    page: String,
  },
  data() {
    return {
      therapeuticsOptions: UtilsService.mapObjectToArray(this.therapeutics),
      contentTypeOptions: UtilsService.mapObjectToArray(this.contentType),
      pathologiesOptions: [],
      formatOptions: [],
      multimediaOptions: [],
      keywordsOptions: [],
      specialitiesOptions: [],

      hasSelectedValue: false,
      isDomReady: false,
      defaultForm: {
        events: false,
        content: false,
        all: false,
        bookmarks: false,
        size: 12,
        q: "",
        qTherapeuticAreas: [],
        qPathologies: [],
        format: [],
        content_type: [],
        qContentType: [],
        qKeywords: [],
        date: [],
        qStartDate: null,
        qEndDate: null,
        sortBy: "date",
        initialSearch: false,
      },
      translator: TranslateService.create(),
    };
  },

  mounted() {
    this.prepareSelects();
    window.addEventListener("load", () => {
      this.isDomReady = true;
    });

    $(".selectpicker").selectpicker("refresh");

    document.addEventListener("SearchFormResultsPageChanged", (event) => {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.search(event.detail.currentPage);
    });

    this.search();

    this.addSelectorsListeners();
  },

  methods: {
    onInputChecked(checkbox) {
      this.setFormInputFromDTO(checkbox);
    },

    searchList(){
        GoogleAnalytics.sendSearchEvent(this.form.q, this.resultsCount, this.page+'Search');
        document.dispatchEvent(new CustomEvent("SearchTerm", { detail: this.form.q }));
        // this.$root.$emit('SearchTerm', this.form.q);
        document.dispatchEvent(new CustomEvent("searchCategory", { detail: this.page }));
        // this.$root.$emit('searchCategory', this.page);
    },

    onSwitchToggle(toggleData) {
      this.setFormInputFromDTO(toggleData);
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.search();
    },

    onSelectChange(selected) {
      this.setFormInputFromDTO(selected);
    },

    setFormInputFromDTO(inputData) {
      this.form[inputData.key] = inputData.value;
    },

    changeSortBy(ev) {
      this.form["sortBy"] = ev.currentTarget.value;
    },

    t(tag) {
      return this.translator.translate(tag);
    },

    onFormSubmit(ev) {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.form.initialSearch = false;
      this.search(0, true);
    },

    async prepareSelects() {
      let filters = await axios.get("/webapi/home/models-filters");

      filters = filters.data.data;
      const keywords = filters.keywords;
      const multimedia = filters.multimediaTypes;
      const therapeutics = filters.therapeuticAreas;
      const specialities = filters.specialities;
      const pathologies = filters.pathologies;
      const formats = filters.formats;

      this.keywordsOptions = this.mapSelect(Object.values(keywords));
      this.multimediaOptions = this.mapSelect(Object.values(multimedia));
      this.therapeuticsOptions = this.mapSelect(Object.values(therapeutics));
      this.specialitiesOptions = this.mapSelect(Object.values(specialities));
      this.pathologiesOptions = this.mapSelect(Object.values(pathologies));
      this.formatOptions = this.mapSelect(Object.values(formats));

      $(".selectpicker").selectpicker("refresh");
    },

    mapSelect(collection) {
      return collection.map((i) => {
        return {
          id: i.id,
          name: i.name,
          therapeutic_area_id: i.therapeutic_area_id,
        };
      });
    },

    async search(page = 0, resetPage = false) {
      document.dispatchEvent(
        new CustomEvent("SearchHomeLoading", { detail: true })
      );
      this.form["page"] = page;
      this.form.content_type = this.contentType;
      if (
        this.$refs.datepicker &&
        this.$refs.datepicker.dateRange &&
        this.$refs.datepicker.dateRange.startDate &&
        this.$refs.datepicker.dateRange.endDate
      ) {
        this.form.qStartDate = moment(this.$refs.datepicker.dateRange.startDate).format(
          "DD/MM/YYYY"
        );
        this.form.qEndDate = moment(this.$refs.datepicker.dateRange.endDate).format(
          "DD/MM/YYYY"
        );
      }
      let data = await ContentRepository.search(this.form);
      document.dispatchEvent(
        new CustomEvent("searchHomeLoaded", { detail: data })
      );

      if (resetPage) {
        document.dispatchEvent(new CustomEvent("resetPagination"));
      }
    },
    getSearchRoute() {
      return TranslationService.transRoute("search-page");
    },
    resetFilters() {
      this.form = this.defaultForm;
      $(".selectpicker").val("default");
      $("#sortBy").selectpicker("val", "date");
      this.$refs.bookmarksRef.checked = false;
      this.$refs.datepicker.dateRange = {
        startDate: null,
        endDate: null,
      };
    },
    showSelector(sel) {
      if ((sel == "qContentType" || sel == "format") && this.page == "events") {
        return false;
      }
      return true;
    },
    addSelectorsListeners() {
      $("body").on("hidden.bs.select", ".selectpicker", (ev) => {
        //Only if changed
        if (this.hasSelectedValue) {
          setTimeout(() => {
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
            this.search();
            this.hasSelectedValue = false;
          }, 75);
        }
      });

      //Set selected value to false when opening selector
      $('body').on("shown.bs.select", '.selectpicker', ev => {
          this.hasSelectedValue = false;
          const title = $(ev.target).attr('title');
          GoogleAnalytics.sendExpandContentEvent(title);
      });

      //Capture when dropdown is closed
      $('body').on("hidden.bs.select", '.selectpicker', ev => {
          const title = $(ev.target).attr('title');
          GoogleAnalytics.sendCollapseContentEvent(title);
      });

      //Set selected value to false when selected value
      $('body').on("changed.bs.select", '.selectpicker', ev => {
          this.hasSelectedValue = true;
          const targetSelector = $(ev.target);
          const category = $(ev.target).attr('title');
          const selectedOptionText = targetSelector.find('option:selected').text();
          GoogleAnalytics.sendFilterEvent(selectedOptionText, category, selectedOptionText);
      });
    },
  },
};
</script>
<style scoped>
.opacity-0 {
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .search {
    height: 120px;
    display: grid !important;
  }
}
</style>
