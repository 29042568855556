<template>
    <div>
        <p class="text-uppercase v4-subtitl light-condensed">{{t('home.carousel.subscriptions')}}</p>

        <div v-if="isLoading">{{t('general.loading')}}...</div>
        <div v-if="!isMobile()">
        <V4Carousel :slidesToShow="3" :slidesToScroll="3" :name="'events'" :isLoading="isLoading" v-if="!isLoading">
            <template v-slot:items>
                <div v-for="(item, i) in list" v-bind:key="i">
                    <EventSubscription v-bind="item"></EventSubscription>
                </div>
            </template>
        </V4Carousel>
        </div>
        <div v-else>
            <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'events'" :isLoading="isLoading" v-if="!isLoading">
            <template v-slot:items>
                <div v-for="(item, i) in list" v-bind:key="i">
                    <EventSubscription v-bind="item"></EventSubscription>
                </div>
            </template>
        </V4Carousel>
        </div>
        <div v-if="!list.length && !isLoading">
            {{t('general.no-results')}}
        </div>
    </div>
</template>

<script>
    import EventRepository from '../repositories/Event.repository';
    import EventSubscriptionFactory from '../factories/EventSubscription.factory';
    import TranslateService from '../../../core/services/translate.service';
    import EventSubscription from '../event-subscription/EventSubscription.vue';
    import V4Carousel from '../../carousel/V4Carousel.vue';
    import $ from 'jquery';

    export default {
        components: {
            EventSubscription,
            V4Carousel,
        },
        data() {
            return {
                isLoading: false,
                list: [],
                translator : TranslateService.create()
            }
        },

        mounted() {
            this.$nextTick(function () {
                this.updateEvents();
            })
        },

        methods: {
            async updateEvents() {
                //this.bus.$emit('remove', {});
                this.isLoading = true;

                const events = await this.getEvents();
                const formattedEvents = events.map(ev => {
                    return EventSubscriptionFactory.get(ev);
                });

                this.list = formattedEvents;
                this.isLoading = false;
                
                if(this.list.length == 0) {
                    $('#subscriptions-carousel').addClass('d-none');
                }
            },          

            getActiveEventsValue() {
                const events = this.getActiveEvents() ?? [];
                return events.map(ev => ev.value);
            },

            getActiveEvents() {
                return this.eventList.filter(item => item.checked === true);
            },

            getEventItemById(id) {
                return this.eventList.find(ev => ev.id == id);
            },

            async getEvents() {
                return await EventRepository.getMyNextEvents();
            },

            isMobile() {
                return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
            },

            t(tag){
                return this.translator.translate(tag)
            },
        }
    }
</script>
