export default {
    fullDate(date) {
        if(!date) {
            return '';
        }
        
        const parsed = new Date(date);
        return Intl.DateTimeFormat('en-US', {day:'numeric', month: 'long', year: 'numeric'}).format(parsed);
    },

    cardDate(start, end) {
        if(!start) {
            return '';
        }

        let startDay = this.formatDay(start);
        let endDay = end ? this.formatDay(end) : '';
        const parsed = new Date(start);
        let startMonthYear = Intl.DateTimeFormat('en-US', {month: 'long', year: 'numeric'}).format(parsed);

        if(startDay == endDay || (startDay && !endDay)) {
            return `${startDay}, ${startMonthYear}`;    
        } else {
            return `${startDay} - ${endDay}, ${startMonthYear}`;
        }
    },
    formatDay(date) {
        const parsed = new Date(date);
        const day = parsed.getDate();
        let suffix = 'th';
        if (day % 100 < 11 || day % 100 > 13) {
            switch(day % 10) {
                case 1: suffix = 'st'; break;
                case 2: suffix = 'nd'; break;
                case 3: suffix = 'rd'; break;
            }
        }
        return `${day}${suffix}`;
    },
};