<template>
<div>
    <div v-if="isLoading">
        {{t('general.loading')}}...
    </div>

    <div v-if="!isLoading">
        <a v-for="item in list" v-bind="item" v-bind:key="item.id" 
            class="v4-action-link mx-2" 
            href="javascript:void(0)"
            data-action="/v4/search"
            @click="filterByKeyword($event, item.id, item.name)">
            #{{ item.name }}
        </a>
    </div>
</div>
</template>

<script>
import TrendingKeywordRepository from './repositories/TrendingKeywords.repository';
import TrendingKeywordFactory from './factories/TrendingKeyword.factory';
import TranslateService from '../../core/services/translate.service';

export default {
    data() {
        return {
            isLoading: true,
            list: [],
            error: '',
            form: {
                events: false,
                content: false,
                all: false,
                bookmarks: false,
                q: '',
                qTherapeuticAreas: [],
                qPathologies: [],
                format: [],
                contentType: [],
                qKeywords: [],
                date: [],
            },
            translator : TranslateService.create()
        }
    },

    mounted() {
        this.get();
    },

    methods: {
        async get() {
            try {
                this.isLoading = true;
                this.error = '';
                const items = await TrendingKeywordRepository.list();
                const lastItems = items.slice(0, 5);
                this.list = lastItems.map(i => TrendingKeywordFactory.get(i));
                this.isLoading = false;
            } catch (error) {
                this.list = [];
                this.error = "something happened";
                this.isLoading = false;
            }
        },

        filterByKeyword($event, keywordId, keywordText) {
            this.form.qKeywords = [keywordId.toString()];

            document.dispatchEvent(new CustomEvent('KeywordSearch', {
              detail: {
                data: this.form,
                $event: $event,
                keywordText: keywordText
              }
            }))
        },
        t(tag) {
            return this.translator.translate(tag);
        },
    }
}
</script>
