<template>
  <section class="steering_committee_register" id="satellital-steering_committee">
      <div class="four-steering_committee">
        <div>
          <div v-if="isLoading">
            {{t('general.loading')}}...
          </div>
        </div>
        <div v-if="!isLoading">
          <div v-if="!isMobile()">
            <div :id="'steering_committee_common'">
              <v4-carousel :slidesToShow="4" :slidesToScroll="4" :name="'steering_committee'">
                <template v-slot:items>
                  <div v-for="(itemInfo, j) in steeringCommitteeList" v-bind:key="j" @click="openModal(itemInfo)">
                    <div class="col-steering_committee">
                      <div class="steering_committee-card">
                        <div class="steering_committee-img">
                          <img :src="parseImage(itemInfo.photo)" @error="imageUrlAlt" alt="" />
                        </div>
                        <div class="steering_committee-user-name">
                          <h4 class="limit-2-rows">{{ itemInfo.name + ' ' + itemInfo.surname }}</h4>
                          <p class="limit-3-rows">{{ getSpeakerItemInfo(itemInfo) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </v4-carousel>
            </div>
          </div>
          <div v-else>
            <div :id="'steering_committee_common'">
              <v4-carousel :slidesToShow="1" :slidesToScroll="1" :name="'steering_committee'">
                <template v-slot:items>
                  <div v-for="(itemInfo, j) in steeringCommitteeList" v-bind:key="j" @click="openModal(itemInfo)">
                    <div class="col-steering_committee">
                      <div class="steering_committee-card">
                        <div class="steering_committee-img">
                          <img :src="parseImage(itemInfo.photo)" @error="imageUrlAlt" alt="" />
                        </div>
                        <div class="steering_committee-user-name">
                          <h4>{{ itemInfo.name + ' ' + itemInfo.surname }}</h4>
                          <p>{{ itemInfo.position }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </v4-carousel>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
import SteeringCommitteeFactory from '../../custom-template/factories/SteeringCommittee.factory.js';
import PageStayController from '../../../page-stay/PageStayController.js';
import ActivityService from '../../../core/services/activity.service';
import GoogleAnalytics from '../../../GoogleAnalytics.js';
import TranslateService from '../../../core/services/translate.service';
import axios from 'axios';

export default {
  name: 'SatellitalCongressSteeringCommittee',

  props: {
    slug: String,
    active: Number,
    congressId: Number,
  },
  data() {
    return {
      translator: TranslateService.create(),
      activitySvc: ActivityService.create(),
      steeringCommitteeList: [],
      singleSteeringCommitteeInfo: {},
      isLoading: true,
    };
  },
  mounted() {
    this.getSteeringCommittee();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async getSteeringCommittee() {
      await axios
      .get(`/congresses/steering-committee/get-members`,
        { params:
            {
              congressId: this.congressId
            }
        })
        .then(response => {
          if(response.data && response.data.data) {
            this.fillSteeringCommittee(response.data.data);
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
        });

    },
    fillSteeringCommittee(steeringCommittee){
      this.steeringCommitteeList = Object.values(steeringCommittee).map(steering_committee => SteeringCommitteeFactory.get(steering_committee));
      this.singleSteeringCommitteeInfo = this.steeringCommitteeList;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    parseImage(img) {
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
    imageUrlAlt(event) {
      event.target.src = '/images/v4/assets/avatar.svg';
    },
    getSpeakerItemInfo(item) {
      let info = '';
      if (item.position) info = item.position;
      if (item.position && item.institution) info = info + ' - ';
      if (item.institution) info = info + item.institution;
      return info;
    },
    openModal(steeringCommittee) {
      document.dispatchEvent(new CustomEvent("showSatellitalSpeakerModal", { detail: {
        name: steeringCommittee.name,
        surname: steeringCommittee.surname,
        institution: steeringCommittee.institution,
        bio: steeringCommittee.bio,
        photo: this.parseImage(steeringCommittee.photo)
      }}));
      // this.$root.$emit('showSatellitalSpeakerModal', {
      //   name: steeringCommittee.name,
      //   surname: steeringCommittee.surname,
      //   institution: steeringCommittee.institution,
      //   bio: steeringCommittee.bio,
      //   photo: this.parseImage(steeringCommittee.photo)
      // });
      PageStayController.startStayPageTime('congress-register-speaker-modal', this.congressId ?? '0', false); 

      this.activitySvc.saveActivity('open-satellital-steering-committee-modal', {
          name: steeringCommittee.name,
          model_type: 'steering-committee',
          model_id: steeringCommittee.id,
          source: null,
          congress_id: this.congressId
      });
      GoogleAnalytics.sendOpenContentEvent('Steering committee ' + steeringCommittee.name, 'card');
    }
  }
};
</script>
