<template>
 <div class="new-certified">
  <div class="top-left-label"><p><i class="bi bi-award"></i> Certified course</p></div>
  <div class="title-middle-center">
   <h3><a href="javascript:void(0);">{{rowTitle}}</a></h3> 
  </div>
  <div class="v4-detailed-card--info--categories">
   <a href="javascript:void(0);" @click="downloadDiploma(false)" class="v4-detailed-card--info--categories--item">
       <span>Your Certified Diploma</span>
    </a> 
    <a class="v4-detailed-card--info--categories--item" @click="downloadDiploma(true)" href="javascript:void(0);">
        <i class="bi bi-download"></i>
    </a> 
    <a class="v4-detailed-card--info--categories--item" href="javascript:void(0);" @click.stop="showShareOptions()">
        <i class="bi bi-share"></i>
    </a> 
   <div :class="'share-icons share-icons-'+ rowId" style="display: none;">
    <em href="https://api.whatsapp.com/send?text=" :data-id="this.id" target="_blank" class="bi bi-whatsapp" style="color: #2DBCB6;" @click.stop="shareAction($event)"></em>
    <em :href="'mailto:?subject=Shared content from the Medical Meeting Point&body='" :data-id="this.id" class="bi bi-envelope" style="color: #2DBCB6;" @click.stop="shareAction($event)"></em>
  </div>
  </div>
  <div class="v4-detailed-card--progress">
    <a :href="rowLink" class="v4-detailed-card--action">Go to the course</a>
  </div> 
 </div>
</template>

<script>
import ActivityService from '../../core/services/activity.service';
import TranslateService from '../../core/services/translate.service';
import Utils from '../../Utils';
import axios from 'axios';
import $ from 'jquery';
export default {
    name: 'AchievementItem',
    props: {
        id: Number,
        title: String,
        link: String
    },
    data() {
            return {
            rowId: this.id,
            rowTitle: this.title,
            rowLink: this.link,
            translator : TranslateService.create(),
            activitySvc: ActivityService.create()
        }
    },
    methods: {
        t(tag){
            return this.translator.translate(tag)
        },
        showShareOptions() {
            let icons = $(`.share-icons-${this.id}`);

            if (icons.is(":visible")) {
                $(icons).hide(200);
                $(icons).fadeOut(200);
            } else {
                $(icons).show(200);
                $(icons).fadeIn(200);
            }
        },
        downloadDiploma(isDownload = false) {
            const download = '/kj/diploma';
            const fd = new FormData();
            fd.append("kjId", this.id);

            axios({
                url: download,
                method: 'POST',
                responseType: 'blob',
                data: fd,
            }).then((response) => {
                const url = URL.createObjectURL(response.data);
                this.saveActivity('diploma-download');
                if(isDownload) {
                    const link = document.createElement('a');
                    link.setAttribute('href', Utils.escapeHtml(url));
                    link.setAttribute('download', 'certificate.pdf');
                    document.body.appendChild(Utils.escapeHtml(link));
                    link.click();
                } else {
                    window.open(url);
                }
            });
        },
        shareAction(ev) {
            const target = $(ev.currentTarget);
            const diplomaHashUri = '/kj/diploma/hash';
            const fd = new FormData();
            fd.append('kjId', this.id);

            axios.post(diplomaHashUri, fd).then(res => {
                const checkoutThis = this.t('general.check-out');
                const hash = res.data.hash;
                let link = `/kj/diploma/share/${hash}`;
                let message = escape(`${checkoutThis} certificate: ${link}`);
                const href = target.attr('href');
                const finalUrl = href + message;
                this.saveActivity('diploma-shared');
                if (target.attr('target') === '_blank') {
                    window.open(finalUrl);
                } else {
                    window.location.replace(finalUrl);
                }
            });
        },
        saveActivity(event) {
            let params = {
                title: this.title,
                model_type: 'knowledge',
                model_id: this.id,
                congress_id: window.event ? window.event.id : null
            }
            this.activitySvc.saveActivity(event, params);
        },
    }

}
</script>