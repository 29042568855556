<template>
 <nav class="header-nav" :class="mainClass">
  <ul class="nav-links" id="header-links">
    <li v-for="(item, i) in list" :key="i"><a class="p-2 secondary-color text-bold" v-on:click="sendMenuNavEvent(getMenuName(item.text), 'menu header')" :href="item.link">{{ item.text }}</a></li>
  </ul>
  <div v-if="list" v-on:click="openMobileNav()" id="burger" class="mobile-burger-menu">
   <div class="line1"></div>
   <div class="line2"></div>
   <div class="line3"></div>
  </div>
 </nav>
</template>

<script>
import GoogleAnalytics from '../../../GoogleAnalytics.js';
import $ from 'jquery';

export default {
    props: {
        list: Array,
        mainClass: String
    },
    methods: {
        openMobileNav() {
            $.each($('.nav-links'), function(index, el) {
                el.classList.toggle('nav-active');
            })
            $.each($('.mobile-burger-menu'), function(index, el) {
                el.classList.toggle('toggle');
            })
        },
        getMenuName(originalName) {
            return originalName.replace(/-home$/, '').replace(/-/g, ' ');
        },
        sendMenuNavEvent(menuName, menuType) {
            GoogleAnalytics.sendMenuNavEvent(menuName, menuType);
        },
        openDropdownNav() {
            const dropdownLink = document.querySelectorAll('.dropdown-link');
            dropdownLink.forEach((dropdown) => {
                dropdown.addEventListener('mouseover', () => {
                    dropdown.children[1].style.display = 'block';
                })
                dropdown.addEventListener('mouseleave', () => {
                    dropdown.children[1].style.display = 'none';
                })
            })
        },
        countClicksOnMobileDropdown() {
            const dropdownLink = document.querySelectorAll('.dropdown-link');
            dropdownLink.forEach((dropdown) => {
                let counts = dropdown.clicks || 0
                dropdown.addEventListener('click', () => {
                    counts++
                    if (counts % 2 == 0) {
                        window.location.href = dropdown.children[0].getAttribute('href');
                    } else {
                        event.preventDefault();
                        dropdown.children[1].style.display = 'block';
                        setTimeout(() => {
                            dropdown.children[1].style.display = 'none';
                        }, 5000);
                    }
                })
                setTimeout(() => {
                    counts = 0;
                }, 8000);
            })
        }
    },
    mounted() {
        this.openDropdownNav();
        if (window.innerWidth < 768) {
            this.countClicksOnMobileDropdown();
        }
    }
}
</script>