import CustomCongress from '../objects/v4/modules/custom-template/CustomCongress.vue';
import AgendaCarrousel from '../objects/v4/modules/custom-template/agenda/AgendaCarrousel.vue';

import SpeakersCardImage from '../objects/v4/modules/custom-template/speakers/SpeakersCardImage.vue';
import SpeakersCardStandard from '../objects/v4/modules/custom-template/speakers/SpeakersCardStandard.vue';
import SpeakersDescriptionBox from '../objects/v4/modules/custom-template/speakers/SpeakersDescriptionBox.vue';


const vueComponents = [
    ['CustomCongress', CustomCongress],
    ['AgendaCarrousel', AgendaCarrousel],
    ['SpeakersCardImage', SpeakersCardImage],
    ['SpeakersCardStandard', SpeakersCardStandard],
    ['SpeakersDescriptionBox', SpeakersDescriptionBox],
]

export default vueComponents