<template>
  <div>
    <div class="custom-template-content__tabs">
      <ul class="text-left">
        <li v-for="(item, k) in tabInfo" v-bind:key="k" @click="openTab(k)">
          <a href="javascript:void(0);" :class="{ active: k == selected_tab_id }">{{ item }}</a>
        </li>
      </ul>
    </div>
    <div v-if="!isLoading" class="custom-template-content__carousel" v-for="(item, i) in tabInfo" v-bind:key="i">
      <div v-if="!isMobile()">
        <div class="hidden_content" :id="'content_common_' + i" v-if="contentInfo[i]?.length > 0">
          <search-form-results :size="size" :card_type = "card_type"></search-form-results>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="hidden_content custom-template-content__carousel" :id="'content_common_' + i" v-if="contentInfo[i]?.length > 0">
        <search-form-results :size="size" :card_type = "card_type"></search-form-results>
      </div>
    </div>
  </div>
</template>

<script>

import TranslateService from '../../../core/services/translate.service';
import $ from 'jquery';

export default {
  data() {
    return {
      isLoading: true,
      selected_tab_id: 0,
      translator: TranslateService.create(),
      dataReady : false,
      contentInfo: [],
      tabInfo: [],
      tabIdsInfo: [],
      categories: [],
      contents: [],
    };
  },
  components: {},
  props: {
    size: Number,
    card_type: String,
  },
  mounted() {
    document.addEventListener("contentTabsChange", (event) => {
      const { categories, multimedias } = event.detail;
      this.categories = categories;
      this.contents = multimedias;
      this.getTabContents();
    });
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    getTabContents() {
      // this.$root.$emit('showCustomLoader', true);
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      var response = this.contents;
      var categories_arr = this.categories;
      var content = [];
      var tabs = [];
      var tabsIds= [];

      $.each(response, function(key, value) {
        content.push(value);
        tabs.push(categories_arr[key]);
        tabsIds.push(key);
      });

      this.contentInfo = content;
      this.tabInfo = tabs;
      this.tabIdsInfo = tabsIds;
      this.isLoading = false;
      setTimeout(() => {
        this.openTab(0);
      }, 800);

      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
      this.dataReady = true;
    },
    openTab(tabId) {
      $('.hidden_content').hide();
      $('#content_common_' + tabId).css('display', 'block');
      this.selected_tab_id = tabId;
      $('.slick-slider').slick('refresh');
      document.dispatchEvent(new CustomEvent("contentTabsOpen", { detail: this.tabIdsInfo[this.selected_tab_id] }));
    },
  }
};
</script>
