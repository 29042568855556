<template>
    <div class="box-common-size">
      <div class="new-voting-padd">
          <div class="left-timer" v-if="expiration_time > 0" :data-poll-id="id"><i class="bi bi-hourglass-split"></i> <span>{{timePoll}}</span></div>
          <div class="live-title"><h4>Live Voting</h4></div>
          <div class="second-title"><h2 class="text-center">{{ question }}</h2></div>
          <div class="third-content"><p class="text-center">Match!</p></div>
      </div>
    <div class="box-padd-new">
        <div class="line-next-padd-even slider-arrow-hided">
            <div class="line-next-padd-even">
                <div id="radio-question-list">
                 <div class="new-pro-sliders">
                  <div class="text-answer">
                   <form action="" ref="quesForm" @submit="showSubmit" method="post" class="modal-questions-form" :data-poll-id="id" :data-poll-type="type" :data-poll-expiration="expiration_time" :id="'quesform_' + id">
                    <div class="single-radio-boxes matchboxestec">
                        <input type="hidden" name="pollId" class="questiontextid" ref="answer" :value="id">
                        <div class="radio" v-for="itemsec in list" :key="itemsec.id">
                           <div class="new-line-box">
                            <div class="table-middle">
                             <div class="middle-table">
                            <input :id="`radio-${itemsec.id}`" name="radio" type="radio" checked="checked" v-model="answer"> <label :for="`radio-${itemsec.id}`" class="radio-label">{{itemsec.option}}</label>
                            </div>
                            </div>
                            </div>
                            <div class="second-new-match">
                             <img :src="itemsec.match_option.image" @error="showDefaultImage($event)">
                             <input id="new-radio" name="radio" type="radio" checked="checked" v-model="answer"> <label for="newsoros" class="radio-label"></label>
                            </div>
                        </div>
                    </div>
                    <div class="done text-right"><a href="javascript:void(0);">Done</a></div>
                   </form>
                  </div>
                 </div>
             </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import LivePollsController from "../../../v4/modules/live/LivePollsController"
import $ from 'jquery';

export default {
    props: {
        list: Array,
        expiration_time:Number,
        question: String,
        id:Number,
        type:Number
    },
     data:function(){
        return{
            answer: "",
            timePoll: 0
        }
    },
    mounted() {
        $('#radio-question-list').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            console.log('clicked');
            $('.pagingInfo').html(i);
        });
        $('#radio-question-list').slick({
            slidesToScroll: 1,
            slidesToShow: 1,
            arrows: true
        });
        let id = '#quesform_' + this.id;
        $(document).on('submit', id.toString() , ev => this.showSubmit(ev));
        this.timePoll = this.expiration_time;
    },
    watch: {
        timePoll: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timePoll--;
                    }, 1000);
                }else{
                    this.popupQuesType();
                }
            },
        }
    },
    methods: {
        slickNext() {
            $('#radio-question-list').slick('slickNext');
        },

        slickPrev() {
            $('#radio-question-list').slick('slickPrev');
        },
        async showSubmit(event){
            event.preventDefault();
            event.stopPropagation();
            if ( this.answer == '' ) {
                LivePollsController.displayToastifyMessage(2 , "Error" , "Answer is required")
            } else {
                var response = await LivePollsController.setAnswerPollFunc(event);
                if ( response.data.status == 201 ) {
                    LivePollsController.displayToastifyMessage(2 , "Error" , response.data.message)
                } else if ( response.data.status == 200 ) {
                    this.popupQuesType();
                    document.dispatchEvent(new CustomEvent("submitLiveSingle"));
                    // this.$root.$emit('submitLiveSingle', {});
                }
            }
        },
        popupQuesType(){
              this.$parent.$parent.closePopup(this.id);
        },
        showDefaultImage(event){
            event.target.src = "/images/v4/picture.png"
        }
    }
}
</script>
