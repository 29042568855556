import RoutesHelper from './routesHelper';
import Utils from './Utils';
import WordCloud from 'wordcloud';

const WordCloudController = {
    init: function() {
        const url = Utils.getUrl(true, this.routes.getPoll);
        const agendaSessionId = this.getAgendaSessionId();
        const pollId = 1;

        var objectPoll = {
            agendaSessionId: agendaSessionId,
            pollId: pollId
        }

        axios.post(url, objectPoll)
        .then((response) => {
            var wordCloud = response.data.data.answers.answers.wordcloud;
            var wordCloud_list = {
                list: wordCloud,
                gridSize: Math.round(16 * $('#wordcloud-canvas').width() / 1024),
                weightFactor: function (size) {
                  return Math.pow(size, 2.3) * $('#wordcloud-canvas').width() / 1024;
                },
                fontFamily: 'Helvetica',
                color: '#fff',
                backgroundColor: '#f3c108'
            };

            WordCloud.minFontSize = "15px"

            WordCloud(document.getElementById('wordcloud-canvas'), wordCloud_list );

            console.debug(response);
        })
        .catch((error) => {
            console.debug('error', error);

        });



    },

    getAgendaSessionId() {
        let e = window.app.agenda.agendaSessionId;
        if( 'undefined' == typeof e)
          throw new Error('This config does not exists. EMC01');
        return e;
      },

    getUrlGetPoll() {
        let routesHelper = new RoutesHelper();
        return routesHelper.getRoute(
            this.routes.getPoll
        );
    },

    initWordcloud(wordcloudArray, pollId){
        let selector = 'wordcloud-canvas';
        if(pollId !== undefined)
            selector = selector+"-"+pollId;

        var wordcloud_list = {
            list: wordcloudArray,
            rotateRatio: 0,
            gridSize: Math.round(2 * document.getElementById(selector).offsetWidth / 600),//1200
            weightFactor: function (size) {
                return Math.pow(size, 2.3) * document.getElementById(selector).offsetWidth / 600;
            },
            fontFamily: 'Helvetica',
            color: '#fff',
            backgroundColor: '#f3c108'
        };

        function setupCanvas(canvas) {
            // Get the device pixel ratio, falling back to 1.
            var dpr = window.devicePixelRatio || 1;
            // Get the size of the canvas in CSS pixels.
            var rect = canvas.getBoundingClientRect();
            // Give the canvas pixel dimensions of their CSS
            // size * the device pixel ratio.
            canvas.width = rect.width * dpr;
            canvas.height = rect.height * dpr;
            var ctx = canvas.getContext('2d');
            // Scale all drawing operations by the dpr, so you
            // don't have to worry about the difference.
            ctx.scale(dpr, dpr);
            return ctx;
        }
        // Now this line will be the same size on the page
        // but will look sharper on high-DPI devices!
        var ctx = setupCanvas(document.getElementById(selector));
        ctx.lineWidth = 5;
        ctx.beginPath();
        ctx.moveTo(100, 100);
        ctx.lineTo(200, 200);
        ctx.stroke();

        //WordCloud.minFontSize = "50px";
        WordCloud(document.getElementById(selector), wordcloud_list );
    },

    routes: {
        getPoll: 'get-poll'
    }
}

export default WordCloudController;
