<template>
    <section class="container virtual-booth-section__focus">
        <div v-if="isLoading">
            {{ t("congresses.loading") }}
        </div>
        <div v-if="!isLoading">
            <V4Carousel 
                :name="'virtual-booth-focus'" 
                :slidesToShow="!isMobile() ? 3 : 1" :slidesToScroll="1" 
                :infinite="false"
                :autoplay = "configuration.configuration.custom_automatic_rotation == 1"
                :autoplaySpeed = "configuration.configuration.custom_seconds_for_rotation"
            >
                <template v-slot:items>
                    <div v-for="(item, i) in list" v-bind:key="i">
                        <FocusCard v-bind="item" :focusType="configuration.configuration.custom_card_type"></FocusCard>
                    </div>
                </template>
            </V4Carousel>
        </div>
    </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import TranslateService from '../../../core/services/translate.service.js';
import V4Carousel from '../../carousel/V4Carousel.vue';
import FocusCard from '../../card/FocusCard.vue';

export default {
    name: 'VirtualBoothFocusContent',
    components: { V4Carousel, FocusCard },
    props: {
        configuration: Object,
        list: Array,
    },
    setup(props) {
        const translator = TranslateService.create();
        const t = (tag) => translator.translate(tag);

        const isLoading = ref(false);
        const list = props.list;
        const configuration = props.configuration;

        onMounted(() => {

        });

        const isMobile = () => {
            try {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            } catch (error) {
                console.error('Error determining if the device is mobile:', error);
                return false;
            }
        };

        return {
            t,
            isMobile,
            isLoading,
            list,
            configuration,
        };
    },
}
</script>