import EventFactory from '../../../modules/events/factories/Event.factory';

export default {
    get(congress) {
        const formatted_dates = EventFactory.formatDates(congress);
        var dots = (congress.description.length>300) ? ' ...' : '';
        var congressRead = false;
        if(congress.description.length>300){
            congressRead = true;
        }
        return {
            id: congress?.id ?? '',
            name: congress?.name ?? '',
            date_start: congress?.date_start ?? '',
            date_end: congress?.date_end ?? '',
            description: congress?.description ?? '',
            short_description: congress?.short_description ?? '',
            organized_by: congress?.organized_by ?? '',
            accredited_by: congress?.accredited_by ?? '',
            banner_image: '/'+congress?.banner_image ?? '',
            event_link: congress?.event_link ?? '',
            ...formatted_dates,
            substring: congress?.description.substring(0, 300)+dots, //replace(/<\/?[^>]+>/ig, " ")
            showread: congressRead
        };
    },  
};