<template>
  <div class="text-center speakers-card-image" 
    :class="{ 'greyscale': isBlackAndWhiteCard() }"
    :style="{ 'padding': 0, 'background-image': `url(${ itemInfo.photo ? parseImage(itemInfo.photo) : parseImage(backupAvatar)})`}"
    @mouseenter="setHovered(itemInfo.id)"
    @mouseleave="setHovered(null)"
  >
    <div class="speakers-card-image__name" :class="{ 'background-opacity': isHovered === itemInfo.id }">
      <h4 :class="{ 'background-opacity': isHovered === null }" class="limit-2-rows" >{{itemInfo.name+' '+itemInfo.surname}}</h4>
      <p class="limit-2-rows hidden-paragraph px-3" 
        :class="{ 'expanded-paragraph': isHovered === itemInfo.id && itemInfo }"
      >
        {{getSpeakerItemInfo(itemInfo)}}
      </p>
    </div>
  </div>
</template>
  
<script>
import TranslateService from '../../../core/services/translate.service';
import $ from 'jquery';

export default {
  name: 'SpeakersCardImage',
  props: {
    cardType: String,
    itemInfo: Object,
  },
  data() {
    return {
      translator: TranslateService.create(),
      isHovered: null,
      backupAvatar: '/images/v4/assets/avatar.svg',
    };
  },
  mounted() {
    if (this.alignment != 'center') {
      $('.custom-template-speakers__carousel__speaker__0').addClass("selected-card");
    }
  },
  methods: {
    t(tag) {
        return this.translator.translate(tag);
    },
    isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    parseImage(img) {
        if (img && img.charAt(0) != '/') {
            img = '/' + img;
        }
        return img;
    },
    getSpeakerItemInfo(item) {
        let info = '';
        if(item.position) info = item.position;
        if(item.position && item.institution) info = info + ' - ';
        if(item.institution) info = info + item.institution;
        return info;
    },
    setHovered(cardId) {
      this.isHovered = cardId;
    },
    isBlackAndWhiteCard(){
      return this.cardType == 'image_black_and_white';
    }
  }
};
</script>
