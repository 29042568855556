<template>
    <div>
        <h3 class="congress-live-section-title">Help</h3>
        <section class="congress-live-qa">
            <div class="congress-live-qa-content congress-live-qa-content-js">
                <form @submit.prevent="onFormSubmit" method="post">    <p class="congress-live-notes-content-message contmessage" style="display:none;">
                        <span>{{ t('live.sent_success') }}</span>
                    </p>
                    <textarea name="message" rows="5" class="modal-light-textarea floating-qa-textarea messagetext" maxlength="255" required=""></textarea>
                    <div class="congress-live-qa-content-btn-container">
                        <div class="d-flex position-relative justify-content-end qa-footer-row">
                            <button class="live-question-ans">
                                <span class="qa-icon-footer"><img src="/images/v4/icons/send.svg" alt="Help"></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import axios from "axios";
import $ from 'jquery';

export default {
    props:{
        slug: String,
        agendaid: [Number, String]
    },
    data() {
        return {
            translator : TranslateService.create()
        }
    },
    methods: {
        t(tag){
            return this.translator.translate(tag)
        },
        onFormSubmit(ev) {
            var link = '/webapi/live/help';
            var message = $('.messagetext').val();
            axios.post(link,{
                message: message,
                slug: this.slug,
                agendaSessionId: this.agendaid
            })
            .then(res => {
                console.info(res);
                $('.messagetext').val(' ');
                $('.contmessage').css('display','block');
                $('.messagetext').addClass('textarea-success-msg');
                setTimeout(function(){ $('.contmessage').css('display','none');$('.messagetext').removeClass('textarea-success-msg'); }, 1000);
            })
            .catch(err => {
                console.warn(err);
            });
        }
    },
}
</script>
