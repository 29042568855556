<template>
<div>
    <div ref="stickyheader" id="sticky-header">
        <div v-if="!isMobile()" class="header-distribution">
            <div class="left-logo">
                <a :href="getRoute('events')" style="float:left">
                    <img :src="logoPath" alt="" style="width: 40px;" />
                </a>
            </div>
            <div class="nav-options p-0">
                <nav v-if="useScroll" class="m-0 align-self-start" v-scroll-spy-active v-scroll-spy-link>
                    <a v-for="(item, index) in items" :key="index" :href="item.link" :data-target="item.link" class="p-2 secondary-color text-bold" v-on:click="sendMenuNavEvent(getMenuName(item.text), 'menu header')">
                        {{ item.text }}
                    </a>
                </nav>
                <nav v-else class="m-0 align-self-start">
                    <a v-for="(item, i) in items" :key="i" class="p-2 secondary-color text-bold" :href="item.link" v-on:click="sendMenuNavEvent(getMenuName(item.text), 'menu header')">{{item.text}}</a>
                </nav>
            </div>
            <div class="profile-dropdown" v-if="!!user">
                <profile-dropdown v-bind:user="user"></profile-dropdown>
            </div>
        </div>
        <div v-else class="mobile-sticky-header">
            <div class="d-flex flex-column flex-md-row align-items-center px-md-4 mb-5 image-container">
                <div class="mmp-logo">
                    <a :href="getRoute('events')" class="my-0 mr-md-auto">
                        <img src="/images/v4/logo_mmp.svg" alt="" width="200">
                    </a>
                </div>
                <CustomStaticHeader
                    :list="items"
                ></CustomStaticHeader>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import TranslationService from '../../core/services/translation.service';
import CustomStaticHeader from '../events/event-internal/CustomStaticHeader.vue';
import $ from 'jquery';
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
    props: {
        user: Object,
        items: Array,
        useScroll: Boolean,
        logoPath: String
    },

    data() {
        return {
            translator : TranslateService.create(),
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            const stickyHeader = document.querySelector('#sticky-header');
            const sectionHeight = 200;
            if (pageYOffset >= (sectionHeight)) {
                stickyHeader.style.visibility = 'visible';
                stickyHeader.style.opacity = 1;
                $('.main-header-nav').addClass('d-none');
            } else {
                stickyHeader.style.visibility = 'hidden';
                stickyHeader.style.opacity = 0;
                $('.main-header-nav').removeClass('d-none');
            }
        });
    },

    methods: {
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        t(tag){
            return this.translator.translate(tag)
        },
        getRoute(route) {
            return TranslationService.transRoute(route);
        },
        getMenuName(originalName) {
            return originalName.replace(/-home$/, '').replace(/-/g, ' ');
        },
        sendMenuNavEvent(menuName, menuType) {
            GoogleAnalytics.sendMenuNavEvent(menuName, menuType);
        },
    },
    components: {
        CustomStaticHeader
    }
}
</script>
