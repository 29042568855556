<template>
  <div class="v4-detailed-card--progress" :class="(panel) ? 'v4-detailed-card--progress--white' : ''">
    <p class="v4-detailed-card--progress--title">{{t('knowledge-journey.card.your_progress')}}</p>

    <div class="v4-detailed-card--progress--bar">
      <span class="v4-detailed-card--progress--completed" :style="{width: progress + '%'}"></span>
    </div>
    <p class="v4-detailed-card--progress--percent-info">
      {{ progress }}%
      <a v-if="progress == 100"  @click="openCertificate()" class="v4-detailed-card--progress--see" href="javascript:void(0);">
          {{ t('knowledge-journey.buttons.certificate') }}
      </a>
    </p>
  </div>
</template>

<script>
import ActivityService from '../../core/services/activity.service';
import TranslateService from '../../core/services/translate.service';
import axios from 'axios';
    export default {
        name: 'KnowledgeJourneyProgress',
        props: {
          progress: Number,
          panel: Boolean,
          diploma: Boolean,
          kjId: Number,
          kjTitle: String
        },
        methods: {
          t(tag){
            return this.translator.translate(tag);
          },
          openCertificate() {
            const download = '/kj/diploma';
            const fd = new FormData();
            fd.append("kjId", this.kjId);

            axios({
              url: download,
              method: 'POST',
              responseType: 'blob',
              data: fd,
            }).then((response) => {
              const url = URL.createObjectURL(response.data);
              this.saveActivity('diploma-download');
              window.open(url);
            });
          },
          saveActivity(event) {
            let params = {
              title: this.kjTitle,
              model_type: 'knowledge',
              model_id: this.kjId,
              congress_id: window.event ? window.event.id : null
            }
            this.activitySvc.saveActivity(event, params);
          },
        },
        data() {
          return {
            translator : TranslateService.create(),
            showDiploma: this.diploma,
            activitySvc: ActivityService.create()
          }
        },
        mounted() {
          document.addEventListener("showDiplomaButton", () => {
            this.showDiploma = true;
          });
          /* this.$root.$on('showDiplomaButton', () => {
            this.showDiploma = true;
          }); */
        }
    }
</script>