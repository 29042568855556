<template>
  <div>
    <section>
      <div style="">
        <div class="webinar-cases__description col-md-12 col-sm-12" :class="{'podcast-player': isPodcast }">
          <template v-if="liveNow==0">
            <template v-if="videotype == 1 && isios && !hasondemandrestrictions">
              <div style="position: relative; display: block; max-width: 1920px;">
                <div
                  style="padding-top: 56.25%;">
                  <iframe
                    :src="videouri"
                    allowfullscreen
                    autoplay
                    muted
                    allow="encrypted-media;autoplay;muted"
                    style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe>
                </div>
              </div>
            </template>
            <template v-else-if="videotype==1">
              <div :id="'player_'+id"></div>
            </template>
            <template v-if="videotype==2">
              <div>
                <iframe id="iframe-live" class="iframe-live-vimeo" :src="videouri" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
              </div>
            </template>
            <template v-if="videotype==3">
              <!-- <youtube :video-id="youtubevideoId" ref="youtube" :player-vars="playerVars" @ended="ended"></youtube> -->
            </template>
            <template v-if="videotype==4 && videouri.includes('/cdn3.wowza.com/')">
              <video id="wowza_player" width="100%" height="100%" class="video-js video-js-player vjs-default-skin wowza-player" data-real-id="0" autoplay="true"
                muted="muted" onended="ended" controls>
                  <source :src="videouri"
                    type="application/x-mpegURL">
              </video>
            </template>
            <template v-if="videotype==4 && videouri.includes('/embed.wowza.com/')">
              <iframe
                id="iframe-live"
                class="iframe-live-wowza"
                :src="videouri"
                frameborder="0"
                allow="fullscreen"

                >
              </iframe>
            </template>
            <template v-if="videotype==5">
              <iframe id="iframe_viewer-wb-" itemId
                class="embed-responsive-item multimedia-embed-item video-js-player video-js" :data-id="itemId"
                data-start="15"
                :data-real-id="itemId"
                :data-url="videouri"
                :data-src="videouri" frameborder="0"
                :src="videouri"
                allowfullscreen>
              </iframe>
            </template>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import { Youtube } from 'vue-youtube';
import brightcovePlayerLoader from '@brightcove/player-loader';
import TranslateService from '../../core/services/translate.service';
import moment from 'moment'
import VJSTrackHelper from './helpers/VJSTrackHelper';
import { VJSInitializer } from '../../../../libs/videojs-helpers/vjs-initializer';
import $ from 'jquery';
//  let {
//   VJSInitializer
// } = require('../../../../libs/videojs-helpers/vjs-initializer');

  export default {
    data() {
      return {
        isRecord: false,
        readmore: false,
        isMultirecord: false,
        isAgendarecord: false,
        basicInfo: [],
        speakers: [],
        multimediaResult: [],
        agendaInfo: [],
        apiAgenda: [],
        videoImg: '',
        videoURL: '',
        translator: TranslateService.create(),
        itemId:'',
        datasource: '',
        datatypes: '',
        youtubevideoId: '',
        liveNow: '',
        playerVars: {
            autoplay: 1
        },
        agendaTitle:'',
        agendaStart:'',
        agendaEnd: '',
        agendaDesc: '',
        dataTracks: [],
        vjs: null,
        isPodcast: this.ispodcast ?? false,
        isIOS: this.isios ? true : false,
        hasOnDemandRestrictions: this.hasondemandrestrictions ? true : false
      }
    },
    components: {
      //Youtube
    },
    props: ['id','videotype', 'videouri', 'accountid', 'playerid', 'embedid', 'videoid', 'datasourcetype', 'datatype', 'datatracks', 'ispodcast', 'isios', 'hasondemandrestrictions'],
    mounted() {
      this.itemId = this.id;
      this.videoURL = this.videouri;

      if(this.videotype==1 && !this.isios){
        this.brightcove(this.accountid, this.videoid, this.playerid, this.embedid, this.id);
      }
      if(this.videotype==3){
        this.datatypes = this.datatype;
        this.datasource = this.datasourcetype;
        this.dataTracks = this.datatracks;
        this.youtubevideoId = this.youtube_parser(this.videouri);
      }
    },
    methods:{
      t(tag) {
        return this.translator.translate(tag);
      },
      hideShortdesc: function (event) {
        this.readmore = true;
      },
      showShortdesc: function (event) {
        this.readmore = false;
      },
      moment: function () {
        return moment();
      },
      imageUrlAlt(event) {
        event.target.src = "/images/v4/assets/avatar.svg";
      },
      brightcove(accountId, videoId, playerid, embedid, id) {
        const player = brightcovePlayerLoader({
          refNode: '#player_' + id,
          accountId,
          playerId: playerid,
          embedId: embedid,
          videoId
        });

        let self = this;

        player.then(res => {
          self.videoJS = res.ref;
          self.trackHelper = VJSTrackHelper.create(res.ref, "players", this.id);
          self.trackHelper.initialize();

          //Add missing attrs to video
          $($('#player_' + id).find('video-js')[0]).addClass('video-js-player');
          $($('#player_' + id).find('video-js')[0]).attr('id', `video-js-player-${self.id}`);
          $($('#player_' + id).find('video-js')[0]).attr('data-real-id', `${self.id}`);

          self.vjs = new VJSInitializer();
          self.vjs.initializeAll();
          self.vjs.initializeConsumptionControl();

          self.videoJS.on('ended', function () {
            document.dispatchEvent(new CustomEvent("testVideoEnd"));
            // self.$root.$emit('testVideoEnd');
          });
        });
      },
      youtube_parser(url){
        var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
        var match = url.match(regExp);
        return (match && match[1].length==11)? match[1] : false;
      },
      ended() {
        document.dispatchEvent(new CustomEvent("testVideoEnd"));
        // this.$root.$emit('testVideoEnd');
      }
    },
    filters: {
      moment: function (date) {
        return moment(date).format("hh:mm");
      },
      momentEnd: function (date) {
        return moment(date).format("hh:mm A");
      }
    }
  }
</script>
