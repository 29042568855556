import CustomLoader from '../objects/v4/modules/loader/CustomLoader.vue';
import TermConditionsQuestions from '../objects/v4/modules/register/components/TermConditionsQuestions.vue';
import CheckboxHtml from '../objects/v4/modules/form/checkbox/CheckboxHtml.vue';
import ConfirmDialogue from '../objects/v4/modules/agenda/ConfirmDialogue.vue';
import ModalComponent from '../objects/v4/modules/internal-congress/ModalComponent.vue';
import V4Carousel from  '../objects/v4/modules/carousel/V4Carousel.vue';
import V4Card from  '../objects/v4/modules/card/V4Card.vue';
import CardFooter from '../objects/v4/modules/card/CardFooter.vue';

const vueComponents = [
    ['CustomLoader', CustomLoader],
    ['TermConditionsQuestions', TermConditionsQuestions],
    ['CheckboxHtml', CheckboxHtml],
    ['ConfirmDialogue', ConfirmDialogue],
    ['ModalComponent', ModalComponent],
    ['V4Carousel', V4Carousel],
    ['V4Card', V4Card],
    ['CardFooter', CardFooter],
    
]
export default vueComponents