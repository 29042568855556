<template>
  <div>
    <h1 class="v4-title mb-2">{{ title }}</h1>
    <div class="row">
      <div class="col">
        <div class="mb-1">
          <ul class="clinical-cases__therapeutic-areas">
            <li v-for="(ta, key) in therapeutic" :key="key">
              {{ ta }}<span v-if="key != therapeutic.length - 1">,</span>
            </li>
          </ul>
        </div>
        <div class="mb-1">
          <ul class="clinical-cases__keywords">
            <li v-for="(kw, key) in keywords" :key="key">
              {{ '#' + kw }}
            </li>
          </ul>
        </div>
      </div>
      <KnowledgeJourneyProgress class="clinical-cases__tabsheet__progress" 
        :progress="currentProgress" 
        :diploma="diploma" 
        :panel="true" 
        :kjId="id" 
        :kjTitle="title">
      </KnowledgeJourneyProgress>
    </div>
  </div>
</template>

<script>
import PageStayController from '../../page-stay/PageStayController';
import KnowledgeJourneyProgress from './KnowledgeJourneyProgress.vue';
  export default {
    name: 'KnowledgeJourneyHeader',
    components: {
      KnowledgeJourneyProgress
    },
    props: {
      id: Number,
      title: String,
      therapeutic: Array,
      keywords: Array,
      progress: Number,
      diploma: Boolean
    },
    mounted() {
      document.addEventListener("updateKJPercentage", (event) => {
        if(event.detail > this.progress) {
          this.currentProgress = event.detail;
        }      
      });
      /* this.$root.$on('updateKJPercentage', (percentage) => {
        if(percentage > this.progress) {
          this.currentProgress = percentage;
        }
      }); */
      this.initStayPage();
    }, 
    data() {
      return {
        currentProgress: this.progress
      }
    },
    methods: {
      initStayPage() {
        PageStayController.startStayPageTime('knowledge-journey', this.id);
      },
    }
  }
</script>