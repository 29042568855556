<template>
  <div>
    <div class="row">
      <div class="col-6">
        <p class="text-uppercase v4-subtitle light-condensed">{{ t("congress.calendar-title") }}</p>
      </div>

      <div class="col-6 text-right v4-calendar--view-options">
        <a href="javascript:void(0)" class="change-view-option" :class="{ active: !showAsList }"
          @click="setAsList(false)">
          <i class="bi bi-calendar3"></i>
        </a>
        <a href="javascript:void(0)" class="change-view-option" :class="{ active: showAsList }"
          @click="setAsList(true)">
          <i class="bi bi-list-ul"></i>
        </a>
      </div>
    </div>

    <div class="v4-calendar--container">
      <Calendar v-if="false === showAsList" :value="null"
        highlight: true
        expanded is-dark
        v-bind:attributes="attributes"
        @dayclick="dayClicked" :locale="location">
        <template v-slot:header-left-button>
          <a>
            <i class="bi bi-arrow-left"></i>
          </a>
        </template>
        <template v-slot:header-right-button>
          <a>
            <i class="bi bi-arrow-right"></i>
          </a>
        </template>
      </Calendar>

      <div v-if="showAsList">
        <div>
          <a @click="prevMonth()">
            <i class="bi bi-arrow-left"></i>
          </a>
          <span class="d-inline-block m-auto calendar-title-list text-center">
            {{ currentMonth }}
          </span>
          <a @click="nextMonth()">
            <i class="bi bi-arrow-right"></i>
          </a>
        </div>
        <ul class="v4-detailed-list v4-detailed-list--compressed" v-if="eventMonthList.length">
          <li v-for="event in eventMonthList" :key="event.id">
            <p class="v4-detailed-list--index">
              <span class="d-block">{{ getDayNumber(event.date_start) }}</span>
              <span class="d-block v4-detailed-list--info">
                {{ getDayWeekShort(event.date_start) }}
              </span>
            </p>

            <div class="v4-detailed-list--body">
              <h4 class="v4-detailed-list--title">
                <a :href="getEventLink(event)" target="_blank"><span v-html="event.title"></span></a>
              </h4>
            </div>
          </li>
        </ul>
        <div class="text-center my-3" v-if="!eventMonthList.length">No results</div>
      </div>
    </div>

    <VueCalendarEventModal @closeModal="closeModal(1)" v-if="showingDetailEvent" :eventselected="eventSelected">
    </VueCalendarEventModal>

    <VueEventListModal @closeModal="closeModal(2)" v-if="showingDetailListEvent" :eventselected="eventSelected">
    </VueEventListModal>
  </div>
</template>

<script>
import { Calendar, DatePicker } from 'v-calendar';
import TranslateService from "../../core/services/translate.service";
import TranslationService from "../../core/services/translation.service";
import VueCalendarEventModal from "./VueCalendarEventModal.vue";
import VueEventListModal from "./VueEventListModal.vue";
import _ from "lodash";
import 'v-calendar/style.css';
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
  name: "VueCalendar",
  props: {
    asList: Boolean,
    eventList: Array,
  },
  data() {
    return {
      showingDetailEvent: false,
      showingDetailListEvent: false,
      eventSelected: {
        title: "",
        hashtags: [],
        date: "",
      },
      showAsList: this.asList ? true : false,
      events: this.eventList ?? [],
      eventMonthList: [],
      attributes: [],
      monthNames: [],
      currentMonth: "September",
      currentMonthIndex: 9,
      currentYear: 2021,
      location: window.app.env.locale,
      translator: TranslateService.create(),
    };
  },

  mounted() {
    this.setAttributes();
    this.setMonths();
    this.setCurrentDate();
    this.setEventMonthList();
  },

  methods: {
    nextMonth() {
      let currentIndex = this.monthNames.findIndex((m) => m == this.currentMonth);
      let nextIndex = "";
      if (currentIndex + 1 > 11) {
        nextIndex = 0;
        this.currentYear++;
      } else {
        nextIndex = currentIndex + 1;
      }
      this.setCurrentMonth(nextIndex);
    },
    prevMonth() {
      let currentIndex = this.monthNames.findIndex((m) => m == this.currentMonth);
      let nextIndex = "";

      if (currentIndex - 1 < 0) {
        nextIndex = 11;
        this.currentYear--;
      } else {
        nextIndex = currentIndex - 1;
      }

                this.setCurrentMonth(nextIndex);
            },
            setMonths() {
                for (let index = 1; index < 13; index++) {
                    const defaultDate = `2021-${index}-1`;
                    const month = Intl.DateTimeFormat('en-US', {month: 'long'}).format(new Date(defaultDate));
                    this.monthNames.push(month);
                }
            },
            setCurrentMonth(nextMonthIndex) {
                this.currentMonth = this.monthNames[nextMonthIndex];
                this.currentMonthIndex = nextMonthIndex;
                this.setEventMonthList();
            },
            setCurrentDate() {
                const now = new Date();
                this.currentYear = now.getFullYear();
                this.currentMonth = this.monthNames[now.getMonth()];
                this.currentMonthIndex = now.getMonth();
            },
            closeModal(type) {
              if(type == 1)
                this.showingDetailEvent = false;
              else if(type == 2)
                this.showingDetailListEvent = false;
              GoogleAnalytics.sendCloseContentEvent('Calendar', 'modal');
            },
            setAttributes() {
                this.attributes = [this.getDefaultConfig()];
            },
            setEventMonthList() {
                this.eventMonthList = this.getEventsByMonthAndYear();
            },
            getEventsByMonthAndYear() {
                let monthUniversal = this.currentMonthIndex+1;
                let padMonth = monthUniversal.toString().padStart(2, '0')
                const partialDate = `${this.currentYear}-${padMonth}`;

      let filteredEvents = this.eventList.filter((ev) => ev.date.startsWith(partialDate));
      return _.orderBy(filteredEvents, "date", "asc");
    },
    getDefaultConfig() {
      return {
        // dot: false,
        // key: 'today',
        highlight: {
          fillMode: "light",
          color: "green",
          contentClass: "highlighted",
        },

        dates: this.getDates(),
      };
    },

    dayClicked(data) {
      this.showingDetailEvent = false;
      const event = this.findByDate(data.id);

      if (event.length == 0) {
        return;
      }

      if (event.length > 0) {
        if (event.length == 1) {
          this.showingDetailEvent = true;
          GoogleAnalytics.sendOpenContentEvent('Calendar', 'modal');
        } else if (event.length > 1) {
          this.showingDetailListEvent = true;
          GoogleAnalytics.sendOpenContentEvent('Calendar', 'modal');
        }
      } else {
        this.showingDetailEvent = false;
        this.showingDetailListEvent = false;
      }
      this.eventSelected = event;
    },

    findByDate(date) {
      let eventdate = this.events.filter(function (ev) {
        return new Date(ev.date).toISOString().split("T")[0] == date;
      });
      return eventdate;
    },

    getDates() {
      return this.events.map((ev) => new Date(ev.date));
    },

    setAsList(active) {
      this.showAsList = active;
    },

    t(tag) {
      return this.translator.translate(tag);
    },
    getEventLink(ev) {
      return TranslationService.transRoute("event-detail", { slug: ev.slug });
    },
    getDayNumber(date) {
      let d = new Date(date);
      return d ? d.getDate() : null;
    },
    getDayWeekShort(date) {
      let d = new Date(date);
      return d ? d.toLocaleString(window.app.env.locale, { weekday: "short" }) : null;
    },
  },
  components: {
    VueCalendarEventModal,
    VueEventListModal,
    Calendar,
    DatePicker
  },
};
</script>

<style>
.v4-calendar--container {
  background-color: transparent;
}
.vc-container.vc-is-dark {
  background-color: transparent;
  border: 0;
}
.vc-bordered {
  background: #001020;
}
.vc-title {
  text-transform: capitalize;
}
.vc-header.align-center {
  margin-bottom: 20px !important;
}
.vc-day-content.vc-focusable {
  font-weight: bold;
}
.v4-calendar--view-options .change-view-option {
  color: #2dbcb6;
  font-size: 1.2rem;
  margin-left: 0.2rem;
  padding: 0.3rem;
}
.change-view-option.active {
  border-bottom: 2px solid white;
  color: white;
}
.calendar-title-list {
  width: 85%;
}
</style>
