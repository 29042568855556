<template>
    <div>
        <h1 class="v4-title mb-2">{{ title }}</h1>
        <div class="row cc-header-items">
            <div class="col">
                <div class="mb-1">
                    <ul class="clinical-cases__therapeutic-areas">
                        <li v-for="(ta, key) in therapeutic" :key="key">
                            {{ ta }}<span v-if="key != therapeutic.length - 1">,</span>
                        </li>
                    </ul>
                </div>
                <div class="mb-1 clinical-cases__keywords-container">
                    <ul class="clinical-cases__keywords">
                        <li v-for="(kw, key) in shownKeywords" :key="key">
                            {{ '#' + kw }}
                        </li>
                    </ul>
                    <em @click="toggleExpand()" v-if="keywords.length > 8" :class="this.expanded ? 'bi bi-dash-circle' : 'bi bi-plus-circle'"></em>
                </div>
            </div>
            <ClinicalCasesProgress class="clinical-cases__tabsheet__progress" :progress="currentProgress" :panel="true" :key="progress"></ClinicalCasesProgress>
        </div>
    </div>
</template>

<script>
import PageStayController from '../../page-stay/PageStayController';
import ClinicalCasesProgress from './ClinicalCasesProgress.vue';

    export default {
        name: 'ClinicalCasesHeader',
        components: {
            ClinicalCasesProgress
        },
        mounted() {
            document.addEventListener("updateCCPercentage", (event) => {
                if(event.detail > this.progress) {
                    this.currentProgress = event.detail;
                }
            });
           /*  this.$root.$on('updateCCPercentage', (percentage) => {
                if(percentage > this.progress) {
                    this.currentProgress = percentage;
                }
            }); */
            this.initStayPage();
        },  
        props: {
            id: Number,
            title: String,
            therapeutic: Array,
            keywords: Array,
            progress: Number
        },
        data() {
            return {
                expanded: false,
                currentProgress: this.progress
            }
        },
        computed:{
            shownKeywords() {
                if(this.expanded) {
                    return this.keywords;
                } else {
                    return this.keywords.slice(0, 8);
                }
            },
        },
        methods: {
            toggleExpand(){
                this.expanded = !this.expanded;
            },
            initStayPage() {
                PageStayController.startStayPageTime('clinical-case', this.id);
            },
        }
    }
</script>
