<template>
<div v-if="isDisplay">
<section class="tripple-card-box tripple-card-block" id="tripplecard">
 <div class="row">
  <div class="col-sm-4">
   <div class="first-box-card" v-bind:style="{ 'background-image': 'url(' + this.bannerInfo.image + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat' }">
    <div class="vertical-box">
      <div class="vertical-middle">
       <p class="tripple-card-space"><span>{{this.bannerInfo.message}}</span></p>
      </div>
     </div>
    </div>
   </div>
   <div class="col-sm-4">
   <div class="second-box-card">
    <div class="vertical-box">
      <div class="vertical-middle" v-if="this.bannerInfo.logo =='unset'">
       <p class="tripple-card-space"><span>{{this.agendatitle}}</span></p>
      </div>
      <div class="vertical-middle" v-else>
        <img v-if="this.bannerInfo.logo" :src="this.bannerInfo.logo" @error="showDefaultImage($event)"/>
        <div class="congress-title" v-html="this.bannerInfo.congressName" v-else></div>
      </div>
     </div>
    </div>
   </div>
   <div class="col-sm-4">
   <div class="third-box-card">
    <div class="vertical-box">
      <div class="vertical-middle">
       <p v-if="this.bannerInfo.sentence != null"><span>{{this.bannerInfo.sentence}}</span></p>
       <p class="users-cards" v-if="this.bannerInfo.author != null"><span>{{this.bannerInfo.author}}</span></p>
      </div>
     </div>
    </div>
   </div>
  </div>
</section>
</div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import axios from 'axios';
    export default {
      name: 'tripple',
        data() {
          return { 
               isDisplay: true,
               translator: TranslateService.create(),
               bannerInfo : Object,
          }
      },
      props: {
          slug : String,
          agendatitle: String
      },   
      created:function(){
        this.getBottomBanner();
      },
      methods:{
          async getBottomBanner(){
            let bannerResponse = await this.getBannerList();
            if ( bannerResponse.data.status == 200 ) {
                this.bannerInfo = bannerResponse.data.data;
            }
            if(Object.keys(this.bannerInfo).length==0){
              this.isDisplay = false;
            }
          },    
          getBannerList(){
            return axios.get("/congresses/thank-you-banner" , { 
                params : {
                    slug : this.slug
                }
            })
          },
          t(tag) {
            return this.translator.translate(tag);
          },        
          showDefaultImage(event){
              event.target.src = "/images/v4/picture.png"
          }
      }
    }
    
</script>
