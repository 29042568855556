<template>
  <section id="section-component">
    <div>
      <WelcomeSection v-if="section.section_id == 1" :configuration="section"></WelcomeSection>
      <UpcomingSessionsSection v-if="section.section_id == 2" :configuration="section"></UpcomingSessionsSection>
      <AgendaSection v-if="section.section_id == 3" :configuration="section"></AgendaSection>
      <SpeakersSection v-if="section.section_id == 4" :configuration="section"></SpeakersSection>
      <SteeringCommitteeSection v-if="section.section_id == 5" :configuration="section"></SteeringCommitteeSection>
      <ContentSection v-if="section.section_id == 6" :configuration="section"></ContentSection>
      <VirtualBoothSection v-if="section.section_id == 7" :configuration="section" :congress_slug="congress_slug"></VirtualBoothSection>
      <OurSymposiaSection v-if="section.section_id == 8 && section.configuration.display_component !== 'old_symposia'" :configuration="section"></OurSymposiaSection>
      <OldSymposiaSection v-if="section.section_id == 8 && section.configuration.display_component === 'old_symposia'" :configuration="section"></OldSymposiaSection>
      <FAQsSection v-if="section.section_id == 9" :configuration="section"></FAQsSection>
      <BannerSection v-if="section.section_id == 10" :configuration="section"></BannerSection>
      <AsynchronusDiscussionSection v-if="section.section_id == 11" :configuration="section"></AsynchronusDiscussionSection>
      <PeopleChoiceSection v-if="section.section_id == 12" :configuration="section" :congressId="congressId"></PeopleChoiceSection>
    </div>
  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import WelcomeSection from './WelcomeSection.vue';
import UpcomingSessionsSection from './UpcomingSessionsSection.vue';
import AgendaSection from './agenda/AgendaSection.vue';
import SpeakersSection from './speakers/SpeakersSection.vue';
import SteeringCommitteeSection from './SteeringCommitteeSection.vue';
import ContentSection from './ContentSection.vue';
import VirtualBoothSection from './VirtualBoothSection.vue';
import OurSymposiaSection from './OurSymposiaSection.vue';
import OldSymposiaSection from './OldSymposiaSection.vue';
import FAQsSection from './FAQsSection.vue';
import BannerSection from './BannerSection.vue';
import AsynchronusDiscussionSection from './AsynchronusDiscussionSection.vue';
import PeopleChoiceSection from './PeopleChoiceSection.vue';

export default {
  name: 'SectionComponent',
  data() {
    return {
      translator: TranslateService.create(),
    };
  },
  components: {
    WelcomeSection,
    SpeakersSection,
    ContentSection,
    AgendaSection,
    UpcomingSessionsSection,
    SteeringCommitteeSection,
    VirtualBoothSection,
    OurSymposiaSection,
    FAQsSection,
    BannerSection,
    AsynchronusDiscussionSection,
    OldSymposiaSection,
    PeopleChoiceSection,
  },
  props: {
    section: Object,
    congressId: Number,
    congress_slug: String,
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
