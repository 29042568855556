<template>
  <ModalComponent v-show="showModal" @close="close" id="satellital-speakers-modal" class="symposia-presentations-modal">
    <template v-slot:header>&nbsp;</template>
    <template v-slot:body>
      <div v-if="isLoading">
        {{ t('congresses.loading') }}
      </div>
      <section class="container">
        <div class="row sort-search" v-if="!isLoading && multimedias.length > 0">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 m-0 p-0" v-for="(content) in multimedias" :key="content.id">
            <div class="v4-card-search-result">
              <V4Card v-bind="content"></V4Card>
            </div>
          </div>
        </div>
        <div v-else-if="!isLoading" class="container">
          <h3>{{ t('general.no-content-found') }}</h3>
        </div>
      </section>
    </template>
    <template v-slot:footer>&nbsp;</template>
  </ModalComponent>
</template>
  
  
<script>
import ModalComponent from '../../internal-congress/ModalComponent.vue';
import { ref, onMounted } from 'vue';
import TranslateService from '../../../core/services/translate.service';
import ContentFactory from '../../content/factories/Content.factory';
import GoogleAnalytics from '../../../GoogleAnalytics.js';
import axios from 'axios';

export default {
  name: 'SymposiaPresentationsModal',
  components: {
    ModalComponent,
  },
  props: {
    symposiaId: String,
    show: Boolean,
  },
  setup(props) {
    const translator = TranslateService.create();
    const t = (tag) => translator.translate(tag);
    const isLoading = ref(true);
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const showModal = ref(props.show ?? false);
    const multimedias = ref([]);

    const close = () => {
      multimedias.value = [];
      showModal.value = false;
      isLoading.value = true;
    };

    const getSymposiaContent = async (symposiaId) => {
      try {
        const response = await axios.get(`/agenda-session/multimedia`, {
          params: {
            id: symposiaId,
            type: 'document',
          }
        });
        
        multimedias.value = response.data.data.map((ev) => {
              return ContentFactory.get(ev);
        });

        isLoading.value = false;

      } catch (error) {
        console.error(error);
      }
    }

    onMounted(() => {
      document.addEventListener("showSymposiaPresentationsModal", (event) => {
        showModal.value = true;
        getSymposiaContent(props.symposiaId);
        GoogleAnalytics.sendOpenContentEvent(event.detail, 'modal');
      });
    });
    
    return {
      t,
      isMobile,
      ModalComponent,
      showModal,
      close,
      isLoading,
      multimedias,
    };
  },
};
</script>