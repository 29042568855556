import axios from 'axios';
import RoutesHelper from '../../v4/routesHelper';

const PageStayController = {

  start: null,
  stop: null,
  modelType: null,
  modelId: null,
  email: null,
  country_id: null,
  routes: {
    stop: 'page-stay-stop'
  },

  startStayPageTime(modelType, modelId, withAutoExit = true) {
    this.start = new Date();
    this.modelType = modelType;
    this.modelId = modelId;

    if(withAutoExit){
      //Add leave listener
      window.onbeforeunload = () => {
        this.stopStayPageTime(false);
        return null;
      }

      //If is mobile, call every 5m
      if(this.isMobile()) {
        this.stopStayPageTime(true);
        setInterval(() => {
          this.stopStayPageTime(true);
        }, 300000);
      }
    }
    
  },

  setAdditionalData(email, country_id, modelTypeToModify = null) {
    this.email = email;
    this.country_id = country_id;
    if(modelTypeToModify != null){
      this.modelType = modelTypeToModify;
    }
    
  },

  stopStayPageTime(forced = false) {
    try {
      this.stop = new Date();
      
      //Get transition key
      let transition = '';
      if(window.localStorage.getItem('user-transition')) {
        transition = window.localStorage.getItem('user-transition');
      } else {
        transition = this.generateUuid();
        window.localStorage.setItem('user-transition', transition);
      }
      
      //Call stop and save staying time
      let url = this.getUrl(this.routes.stop);
      let params = {
        token: 'd43680ffa91882c61412f0229d43eac8',
        modelType: this.modelType,
        modelId: this.modelId,
        start: this.start,
        stop: this.stop,
        transition: transition,
        browser: this.getBrowser() ?? null,
        operatingSystem: this.getOperatingSystem() ?? null,
        forced: forced,
        email: this.email,
        country_id: this.country_id
      };
      axios.post(url, params)
      .then(response => {
        console.log('User stay time saved.');
      }).catch(err => {
        console.log('User stay time could not be saved.');
      });
    } catch (e) {
      console.log('Error saving page stay', e);
    }
  },

  getUrl: function(route) {
    let routesHelper = new RoutesHelper();
    return routesHelper.getRoute(route);
  },

  generateUuid() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  },

  getBrowser() {
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion); 
    var majorVersion = parseInt(navigator.appVersion,10);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset+6);
      if ((verOffset=nAgt.indexOf("Version"))!=-1)  fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset+7);
      if ((verOffset=nAgt.indexOf("Version"))!=-1) fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < (verOffset=nAgt.lastIndexOf('/')) ) {
      browserName = nAgt.substring(nameOffset,verOffset);
      fullVersion = nAgt.substring(verOffset+1);
      if (browserName.toLowerCase()==browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!=-1) fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!=-1) fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
      fullVersion  = ''+parseFloat(navigator.appVersion); 
      majorVersion = parseInt(navigator.appVersion,10);
    }

    return `${browserName} ${fullVersion}`;
  },

  getOperatingSystem() {
    return navigator ? navigator.userAgent : null;
  },

  isMobile() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  },
}

export default PageStayController;