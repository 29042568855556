<template>
    <transition name="fade">
        <div class="popup-modal" v-if="isVisible">
            <div class="v-modal-backdrop"></div>
            <div class="window">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>
<script>

import $ from 'jquery';

export default {
    name: 'PopupModal',

    data: () => ({
        isVisible: false,
    }),

    methods: {
        open() {
            this.isVisible = true;
            $('body').addClass('popupopen');
        },

        close() {
            this.isVisible = false
        },
    },
}
</script>