<template>
  <section class="custom-template-speakers" :id="$parent.$parent.formatHrefAnchorText(configuration.section_wording)">
    <div class="container">
      <div>
        <h2 class="text-center custom-template-speakers__head">{{ configuration.section_wording }} </h2>
      </div>
    </div>
    <div class="container">
      <div v-if="isLoading">
        {{t('general.loading')}}...
      </div>
      <div v-if="this.congress.speakers_grouping_method && this.congress.speakers_grouping_method != 'No Grouping'">
        <SpeakersTabs :configuration="this.configuration"/>
      </div>
      <div v-else>
        <SpeakersCarrousel :configuration="this.configuration"/>
      </div>
    </div>
  </section>
</template>

<script>
import TranslateService from '../../../core/services/translate.service';
import SpeakersTabs from './SpeakersTabs.vue';
import SpeakersCarrousel from './SpeakersCarrousel.vue';
import axios from 'axios';

export default {
  name: 'CustomSpeakersSection',
  components: { SpeakersTabs, SpeakersCarrousel },
  props: {
    configuration: Object,
  },
  data() {
    return {
      translator: TranslateService.create(),
      isLoading: true,
      hideTab: false,
      alignment: this.configuration.configuration.custom_speakers_alignment,
      cardType: this.configuration.configuration.custom_speakers_card_type,
      hideRelatedContent: true,
      speakerInfo: [],
      tabInfo: [],
      selected_tab_id: 0,
      isMobileDevice: false,
      singleSpeakerInfo: {},
      refreshSpeakerBox: 1,
      refreshCarousel: 1,
      selectedCard: 0,
      congress: [],
      dataReady: false,
    };
  },
  mounted() {
    this.congressData();
    window.addEventListener('resize', this.handleResize);
    this.isMobileDevice = this.isMobile();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async congressData() {
      await axios
        .get('/congresses/agenda/get-congress-data',
        { params:
            {
              congressId: this.configuration.congress_id
            }
        })
        .then(response => {
          if(response.data && response.data.data) {
            this.congress = response.data.data;
            this.isLoading = false;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    handleResize() {
      this.isMobileDevice = this.isMobile();
    },
  }
};
</script>

