<template>
  <div v-if="isLoading">
    {{ t('congresses.loading') }}
  </div>
  <div id="our-symposia-section" class="py-3">
    <section v-if="!isLoading && list.length" class="container" :id="sectionWording.replace(/\s+/g, '')">
      <div class="our-symposia-section align-items-center">
        <h2 class="our-symposia-section__title text-center">{{ sectionWording }}</h2>
        <button v-if="showPresentationsBtn" class="our-symposia-section__button btn" :class="{ 'button-fade-in': isButtonClicked }" @click="openPresentationsModal(String(activeSymposiaId))">{{ associatedContentWording }}</button>
        <div class="tabs-component">
          <ul class="tabs-component-tabs">
            <a v-show="isMobile() || (!isMobile() && list.filter(symposia => symposia.multimedias?.length > 0).length > 3)" @click="isMobile() ? changeTabMobile('prev') : ''" href="javascript:void()" ref="tabs-prev-btn" class="tabs-component-tabs-prev-btn"><i class="bi bi-arrow-left"></i></a>
            <V4Carousel :key="tabsMenuCarouselKey" class="symposia-tabs-menu" :slidesToShow="!isMobile() ? 3 : 1" :slidesToScroll="1" :rowsToShow="1" :name="'symposia-menu'" :isNumberCarousel="false" :customArrows="customArrows" :infinite="false">
              <template v-slot:items>
                <SymposiaTabItem class="tabs-component-tab" v-for="(symposia, i) in list.filter(symposia => symposia.multimedias?.length > 0)"
                  :symposia="symposia"
                  :activeSymposiaId="String(activeSymposiaId)"
                  :key="i" >
                </SymposiaTabItem>
              </template>
            </V4Carousel>
            <a v-show="isMobile() || (!isMobile() && list.filter(symposia => symposia.multimedias?.length > 0).length > 3)" @click="isMobile() ? changeTabMobile('next') : ''" href="javascript:void()" ref="tabs-next-btn" class="tabs-component-tabs-next-btn"><i class="bi bi-arrow-right"></i></a>
          </ul>
        </div>
        <Tabs :options="{ useUrlFragment: false }" cache-lifetime="0" ref="tabs" @changed="refreshCarousel" id="symposia-deprecated-menu">
          <Tab v-for="(symposia, i) in list.filter(symposia => symposia.multimedias?.length > 0)" :key="i" class="our-symposia-section__symposia-tab" :id="symposia.id" :name="symposia.title">
            <V4Carousel :key="carouselKey" :slidesToShow=1 :slidesToScroll=1 :rowsToShow="1" :isResponsive="true" :name="'symposia'+i" :isNumberCarousel="false" :customArrows="customArrows.value">
              <template v-slot:items>
                <SymposiaContent v-for="(multimedia, j) in symposia.multimedias" :multimedia="multimedia" :alignment="alignment" :speakersWording="speakersWording" :key="j" :class="'symposya-content'+j"></SymposiaContent>
              </template>
            </V4Carousel>
          </Tab>
        </Tabs>
        <SymposiaPresentationsModal :symposiaId="String(activeSymposiaId)"></SymposiaPresentationsModal>
        <SymposiaSpeakersModal></SymposiaSpeakersModal>
        <div v-if="!isLoading && !list.length">
          <p>{{ t('congresses.no_symposias') }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {Tabs, Tab} from 'vue3-tabs-component';
import { ref, onMounted, nextTick } from 'vue';
import TranslateService from '../../core/services/translate.service.js';
import AgendaFactory from '../agenda/factories/Agenda.factory';
import V4Carousel from '../carousel/V4Carousel.vue';
import PresentationTabs from '../events/event-internal/PresentationTabs.vue';
import SymposiaContent from './symposia/SymposiaContent.vue';
import SymposiaPresentationsModal from './symposia/SymposiaPresentationsModal.vue';
import SymposiaSpeakersModal from './symposia/SymposiaSpeakersModal.vue';
import SymposiaTabItem from './symposia/SymposiaTabItem.vue';
import axios from 'axios';

export default {
  name: 'OurSymposia',
  components: {
    V4Carousel,
    PresentationTabs,
    SymposiaContent,
    Tabs,
    Tab,
    SymposiaPresentationsModal,
    SymposiaSpeakersModal,
    SymposiaTabItem,
  },
  props: {
    configuration: Object,
  },
  setup(props) {
    const isLoading = ref(true);
    const list = ref([]);
    const translator = TranslateService.create();
    const alignment = props.configuration.configuration.alignment;
    const associatedContentWording = props.configuration.configuration.associated_content_wording ?? 'Presentations';
    const speakersWording = props.configuration.configuration.speakers_wording ?? 'Speakers';
    const carouselKey = ref(0);
    const activeSymposiaId = ref('');
    const showPresentationsModal = ref(false)
    const isButtonClicked = ref(false);
    const showPresentationsBtn = ref(false);
    const activeSymposiaList = ref();
    const customArrows = ref([]);
    const tabsMenuCarouselKey = ref(0);
    const sectionWording = props.configuration.section_wording;

    const changeTab = (tabId) => {
      activeSymposiaId.value = tabId;
      changeActiveBtn();
      isButtonClicked.value = true;
      setTimeout(() => {
        isButtonClicked.value = false;
      }, 1000);
    }

    const changeTabMobile = (action) => {

      const tabs = list.value.filter(symposia => symposia.multimedias?.length > 0);
      let position = tabs.findIndex((objeto) => objeto.id == activeSymposiaId.value);
      position = !position ? 0 : position;
      if (typeof tabs !== "undefined" && tabs.length > 0 && typeof position !== "undefined" && position !== null) {
        if (!window.location.hash) {
          location.hash = activeSymposiaId.value;
          changeTab(activeSymposiaId.value);
        }
        if (action && action == 'prev'){
          if (tabs[position - 1] && tabs[position-1].id) {
              location.hash = location.hash.replace(`#${activeSymposiaId.value}`, '#'+tabs[position-1].id);
              changeTab(tabs[position-1].id);
          }
        }

        if (action && action == 'next'){
          if (tabs[position + 1] && tabs[position+1].id) {
              location.hash = location.hash.replace(`#${activeSymposiaId.value}`, '#'+tabs[position+1].id);
              changeTab(tabs[position+1].id);
          }
        }
      }
    }

    const refreshTabsMenuCarousel = () => {
      tabsMenuCarouselKey.value += 1;
    }

    const refreshCarousel = () => {
      carouselKey.value += 1;
    }

    const changeActiveBtn = () => {
      activeSymposiaList.value = list.value.filter((symposia) => symposia.id == activeSymposiaId.value);
      showPresentationsBtn.value = activeSymposiaList.value[0].showPresentationsModalBtn;
    }

    const openPresentationsModal = (symposiaId) => {
      document.dispatchEvent(new CustomEvent("showSymposiaPresentationsModal", {
        detail: {
          symposiaId: String(symposiaId),
        }
      }));
    }

    const t = (tag) => translator.translate(tag);

    const getSymposiaData = async () => {
      try {
        const response = await axios.get(`/congresses/agenda?simposium=1&congressId=${props.configuration.congress_id}`);
        list.value = response.data.data.map(ev => AgendaFactory.get(ev));
      } catch (error) {
        console.error(error);
      }
      isLoading.value = false;
    };

    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    onMounted(async () => {
      await getSymposiaData();

      setTimeout(() => {
        customArrows.value = {
          prev: document.querySelector('.tabs-component-tabs-prev-btn'),
          next: document.querySelector('.tabs-component-tabs-next-btn'),
        };
        refreshTabsMenuCarousel();
        refreshCarousel();
        const activeTab = document.querySelector('.tabs-component-tab-a.is-active');
        if (activeTab) {
          activeSymposiaId.value = activeTab.getAttribute('href').replace('#', '');
        }

        if (window.location.hash) {
          changeTab(location.hash.replace('#', ''));
        }
      }, "2000");

      document.addEventListener("changeMenuTab", (tab) => {
        changeTab(tab.detail.tab.computedId);
      });

    });

    return {
      isLoading,
      list,
      t,
      isMobile,
      alignment,
      associatedContentWording,
      speakersWording,
      carouselKey,
      refreshCarousel,
      activeSymposiaId,
      openPresentationsModal,
      changeTab,
      changeTabMobile,
      SymposiaPresentationsModal,
      showPresentationsModal,
      SymposiaSpeakersModal,
      isButtonClicked,
      showPresentationsBtn,
      activeSymposiaList,
      customArrows,
      tabsMenuCarouselKey,
      sectionWording,
    };
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped>
  .button-fade-in {
    animation: fadeIn 1s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
