<template>
    <a href="#people-choice-section">
        <div class="people-choice-countdown">
            <div v-if="votingState === 'countdown' || votingState === 'active'" class="countdown-timer">
                <div v-if="timer.diffDays > 0" class="time-unit">
                    <span>{{ timer.diffDays }}</span>
                    <label>Days</label>
                </div>
                <div v-if="timer.diffDays > 0" class="separator"></div>
                <div class="time-unit">
                    <span>{{ timer.diffHours }}</span>
                    <label>Hours</label>
                </div>
                <div class="separator"></div>
                <div class="time-unit">
                    <span>{{ timer.diffMinutes }}</span>
                    <label>Minutes</label>
                </div>
                <div class="separator"></div>
                <div class="time-unit">
                    <span>{{ timer.diffSeconds }}</span>
                    <label>Seconds</label>
                </div>
            </div>
            
            <div class="countdown-description">
                <p class="d-flex align-items-center gap-2" v-if="votingState === 'countdown'">
                    <alarmIconSvg />
                    <span><b>Voting has not started yet.</b> The countdown has begun!</span>
                </p>

                <p class="d-flex align-items-center gap-2" v-if="votingState === 'active'">
                    <timerIconSvg color='#27D28B' />
                    <span><b>Time is running out</b> to vote!</span>
                </p>
                <p class="d-flex align-items-center gap-2" v-if="votingState === 'expired'">
                    <timerIconSvg color="#FF7D7D" />
                    <span><b style="color:#FF7D7D">Time's up!</b> Voting is over</span>
                </p>
            </div>
        </div>
    </a>
</template>

<script>
import { computed } from "vue";
import { useVotingStore } from "../../../store/voting.store";
import alarmIconSvg from "./people-choice-countdown-partials/alarmIconSvg.vue";
import timerIconSvg from "./people-choice-countdown-partials/timerIconSvg.vue";

export default {
    name: "PeopleChoiceCountdown",
    components: { alarmIconSvg, timerIconSvg },
    setup() {
        const votingStore = useVotingStore();
        const timer = computed(() => votingStore.countdownTime);
        const votingState = computed(() => votingStore.votingState);

        return {
            timer,
            votingState,
        };
    },
};
</script>
