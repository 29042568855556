<template>
  <section class="agenda custom-congress-agenda" id="agenda" v-if="showSection">
    <div class="container">
      <div class="agenda-head custom-congress-title">
        <h2 class="text-center">{{ t('congress.agenda') }}</h2>
      </div>

      <div class="agenda-form-wrapper">
        <div class="agenda-timezones" v-if="agendaFiltersView!==0">
          <div class="mb-4" :class="{'agenda-timezones-wrapper': agendaFiltersView!==1}">
            <div class="agenda-form-timezone">
              <select
                class="select selectpicker bootstrap-select--bordered"
                @change="onDropDownTimezoneChange"
                v-model="selectedTimezone"
                data-live-search="true"
                :title="t('satellital.nothing-selected')"
                :data-live-search-placeholder="t('general.search')"
              >
                <option :key="index" v-for="(zone, index) of allTimezones" :value="zone">{{ zone }}</option>
              </select>
            </div>

            <div class="agenda-form-slider" :class="{'mt-4': agendaFiltersView===1}">
              <div class="agenda-form-advice">
                <span v-if="!!timezoneText" class="text-center" v-html="timezoneText"></span>
                <i class="bi bi-arrow-repeat" v-on:click="refreshSlider" v-if="agendaFiltersView!==1"></i>
              </div>
  
              <div class="w-100">
                 <Slider v-model="selectedSlider" v-bind="sliderOptions" @change="onSliderChange" :class="{'slider-bordered': agendaFiltersView===1}" />
              </div>
            </div>

            <div class="agenda-form-category" v-if="agendaFiltersView!==1">
              <select
                class="select selectpicker bootstrap-select--bordered"
                @change="onDropDownCategoriesChange"
                v-model="selectedCategory"
                data-live-search="true"
                :title="t('congress.all-categories')"
                :data-live-search-placeholder="t('general.search')"
                multiple="multiple"
              >
                <option
                  v-for="(category, index) of this.allCategories"
                  :key="index" 
                  :value="index"
                  :data-content="`<i class='bi bi-circle-fill' style='color: ` + category.color + `'></i> <span>` + category.name + `</span>`"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div v-if="dataReady">
        <div v-if="fullCalendar">
          <InternalCongressAgendaCalendar
            :key="loopKey"
            :agendaSessions="events"
            :timezone="selectedTimezone"
            :minutesToOpenLiveSession="minutesToOpenLiveSession"
            :activeViewType="activeViewType"
            :fullCalendarCustom="fullCalendarCustom"
            :availableViews="availableViews"
            :congressTemplateConfiguration="congressTemplateConfiguration"
            :minimalisticAgendaPopUp="minimalisticAgendaPopUp"
          />
        </div>

        <div v-else>
          <InternalCongressAgendaTabs
            :key="loopKey"
            :agendaSessions="events"
          />
        </div>
      </div>

    </div>
    <div v-if="!!agendaFile" class="d-flex justify-content-center mt-3">
      <div>
        <a
          :href="parseLink(agendaFile)"
          :download="`${slug}.pdf`"
          class="v4-btn-primary satellital-country-next"
          @click="onAgendaFileClick"
        >
          <span class="title-text text-bold">
            <span class="list-text"> {{ t('congresses.download_full_agenda') }}</span>
          </span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import ActivityService from '../../core/services/activity.service';
import InternalCongressAgendaCalendar from './InternalCongressAgendaCalendar.vue';
import InternalCongressAgendaTabs from './InternalCongressAgendaTabs.vue';
import Slider from '@vueform/slider';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'AgendaComponent',
  data() {
    return {
      activitySvc: ActivityService.create(),
      activeViewType: this.fullCalendarDefaultView,
      isLoading: true,
      hidden: false,
      first: true,
      translator: TranslateService.create(),
      selectedTimezone: window.user.time_zone ?? 'UTC',
      loopKey: 0,
      selectedSlider: [this.timezoneStartHour,this.timezoneEndHour],
      sliderOptions: {
        max: 24,
        min: 0,
        step: 1,
        merge: 1,
        tooltipPosition: 'bottom',
        format: function(value) {
          if (value === 24) {
            return `23:59`;
          }
          return `${value}:00`;
        }
      },
      selectedCategory: [],
      events: [],
      dataReady: false,
      availableViews: this.fullCalendarAvailableViews,
    };
  },
  components: { InternalCongressAgendaTabs, InternalCongressAgendaCalendar, Slider },
  props: {
    slug: String,
    countryId: Number,
    allTimezones: Array,
    agendaFiltersView: Number,
    timezoneText: String,
    timezoneStartHour: Number,
    timezoneEndHour: Number,
    agendaFile: String,
    showSection: Boolean,
    allCategories: Object,
    fullCalendar: Boolean,
    fullCalendarDefaultView: String,
    fullCalendarCustom: Number,
    minutesToOpenLiveSession : Number,
    fullCalendarAvailableViews: String,
    congressTemplateConfiguration: Object,
    minimalisticAgendaPopUp : Boolean,
  },
  mounted() {
    if (!this.showSection) {
      $('#agenda_menu').hide();
    }
    this.getEvents();
    setTimeout(function(){
      addeventatc.settings({
          license: window.app.env.USER_CLIENT_ID,
      });
      addeventatc.refresh();
    }, 600);
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    onDropDownTimezoneChange() {
      this.saveActivity('select-timezone-agenda-section', this.selectedTimezone);
      this.getEvents();
    },
    onDropDownCategoriesChange() {
      this.saveActivity('select-categories-agenda-section', this.selectedCategory);
      this.getEvents();
    },
    onSliderChange() {
      setTimeout(() => {
        this.saveActivity('select-slider-agenda-section', this.selectedSlider);
        this.getEvents();
      }, 200);
    },
    onAgendaFileClick(){
      this.saveActivity('agenda-download-full-calendar', this.agendaFile);
    },
    parseLink(link) {
      if (link && link.substr(0, 1) != '/') {
        link = '/' + link;
      }
      return link;
    },
    refreshSlider() {
      this.selectedSlider = [this.timezoneStartHour,this.timezoneEndHour];
       this.getEvents();
    },
    async getEvents() {
      const response = await this.getAgendaData();
      this.events = response;
      this.dataReady = true;
      // this.$root.$emit('giveMeType');
      document.dispatchEvent(new CustomEvent("giveMeType"));
      if (localStorage.getItem('fullCalendarActiveViewType')) {
        this.activeViewType = localStorage.getItem('fullCalendarActiveViewType');
        localStorage.removeItem('fullCalendarActiveViewType');
      }
      this.loopKey += 1;
    },
    getAgendaData() {
      return axios.get(
        '/congresses/grouped-agenda',
        { params:
          {
            slug: this.slug,
            countryId: this.countryId,
            timezone: this.selectedTimezone,
            startTime: this.selectedSlider[0],
            endTime: this.selectedSlider[1],
            categories: this.selectedCategory,
            agendaFiltersView: this.agendaFiltersView
          }
        }
      );
    },
    saveActivity(event, value) {
      let params = {
          title: value,
          model_type: 'congress',
          model_id: window.app.event ? window.app.event.id : null,
          congress_id: window.app.event ? window.app.event.id : null
      };
      this.activitySvc.saveActivity(event, params);
    },
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
