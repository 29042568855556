<template>
    <section :id="$parent.$parent.formatHrefAnchorText(configuration.section_wording)">
      <div class="upcoming-session-section" v-if="!isLoading && upcomingData.length > 0 || !showSession">
        <div class="container">
          <h4 class="text-center">{{ t('congress.upcoming-session') }}</h4>
          <div class="upcoming-session-section__card-items row justify-content-center">
            <div class="col-sm-4" v-for="(item, i) in upcomingData.slice(0, 3)" v-bind:key="i">
              <div v-if="cardType=='Standard'">
                <AgendaCardStandard :cardType="cardType" :itemInfo="item"></AgendaCardStandard>
              </div>
              <div v-else-if="cardType=='Compact'">
                <AgendaCardCompact :cardType="cardType" :itemInfo="item"></AgendaCardCompact>
                <EventModalComponent v-show="isModalVisible" @close="closeModal(item)" :start="item.start" :end="item.end" :timezone="item.agendaTimezone" :title="item.title" :description="item.description" :plainTextDescription="getPlainTextDescription(item.description)" :location="getLocation(item.slug, item.id)" :agendaSessionId="item.id"></EventModalComponent>
              </div>
              <div v-else>
                <AgendaCardDetailed :cardType="cardType" :itemInfo="item"></AgendaCardDetailed>
                <EventModalComponent v-show="isModalVisible" @close="closeModal(item)" :start="item.start" :end="item.end" :timezone="item.agendaTimezone" :title="item.title" :description="item.description" :plainTextDescription="getPlainTextDescription(item.description)" :location="getLocation(item.slug, item.id)" :agendaSessionId="item.id"></EventModalComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import PageStayController from '../../page-stay/PageStayController';
import AgendaFactory from '../agenda/factories/Agenda.factory';
import AgendaCardStandard from './agenda/AgendaCardStandard.vue';
import AgendaCardCompact from './agenda/AgendaCardCompact.vue';
import AgendaCardDetailed from './agenda/AgendaCardDetailed.vue';
import EventModalComponent from '../modals/eventRegisterModal.vue';
import TranslationService from '../../core/services/translation.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'UpcomingSessionsSection',
  components: { AgendaCardStandard, AgendaCardCompact, EventModalComponent, AgendaCardDetailed},
  data() {
    return {
      upcomingData: [],
      translator: TranslateService.create(),
      isLoading: true,
      showSession: true,
      cardType: this.configuration.configuration.custom_upcoming_sessions_card_type,
      isModalVisible: false,
    };
  },
  props: {
    configuration: Object
  },
  mounted() {
    this.getSectionData();
    this.initStayPage();
    document.addEventListener("showEventRegisterModal", (event) => {
      this.isModalVisible = true;
      GoogleAnalytics.sendOpenContentEvent(event.detail, 'modal');
    });
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    async getSectionData() {
      const response = await axios.get(
        '/congresses/upcoming-sessions/get-data', 
        { params:
          {
            id: this.configuration.congress_id,
          }
        }
      );

      const results = response.data.data.map(k => AgendaFactory.get(k));
      this.upcomingData = results;
      const mustShow = response.data.show;
      this.showSession = mustShow;
      if (!results || (results.length == 0 || !mustShow)) {
        $('#upcoming-sessions-section').hide();
        $('#upcoming-sessions-section_menu').hide();
      }

      this.isLoading = false;
    },
    initStayPage() {
      PageStayController.startStayPageTime('congress', this.configuration.congress_id);
    },
    getLocation(slug, id) {
      let agendaURL = '';
      agendaURL = TranslationService.transRoute('event-detail-live', {
          slug: slug,
          agendaSessionId: id
      });
      return window.app.env.APP_URL+agendaURL;
    },
    getPlainTextDescription(description) {
      let plainTextDescription = '';
      if (description) {
          plainTextDescription = description.replace(/<[^>]+>/g, '')
              .replace(/&nbsp;/g, ' ')
              .replace(/&amp;/g, '&');
      }
      return plainTextDescription;
    },
    closeModal(item) {
      this.isModalVisible = false;
      GoogleAnalytics.sendCloseContentEvent(item.title,'modal');
    },
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
