<template>
    <div ref="" class="agenda-list custom-congress-date">
      <ul>
        <li v-for="(item, k) in tabInfo" v-bind:key="k" @click="openTab(k)">
          <a href="javascript:void(0);" :class="{ active: k == selected_tab_id }" class="agenda-days">{{ item }}</a>
        </li>
      </ul>
    </div>
    <div class="agenda-satellital" v-for="(item, i) in tabInfo" v-bind:key="i">
        <div v-if="!isMobile()">
            <div class="hidden" :id="'agenda_common_' + i" v-if="agendaInfo[i].length > 0">
                <V4Carousel :slidesToShow="3" :slidesToScroll="3" :name="'agenda' + i">
                    <template v-slot:items>
                        <div v-for="(itemAgent, j) in agendaInfo[i]" v-bind:key="j" @click="openModal(itemAgent)">
                            <V4Card v-bind="itemAgent" preventOpen></V4Card>
                        </div>
                    </template>
                </V4Carousel>
            </div>
        </div>
        <div v-else>
            <div class="hidden custom-congress-carousel" :id="'agenda_common_' + i" v-if="agendaInfo[i].length > 0">
                <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'agenda' + i">
                    <template v-slot:items>
                        <div v-for="(itemAgent, j) in agendaInfo[i]" v-bind:key="j" @click="openModal(itemAgent)">
                            <V4Card v-bind="itemAgent" preventOpen></V4Card>
                        </div>
                    </template>
                </V4Carousel>
            </div>
        </div>
    </div>
</template>

<script>
import V4Carousel from '../../carousel/V4Carousel.vue';
import V4Card from '../../card/V4Card.vue';

export default {
    name: 'SatellitalAgendaTabs',
    components: {
        V4Card,
        V4Carousel,
    },
    props: {
        tabInfo: Array,
        agendaInfo: Array,
        isMobile: Function,
        openModal: Function,
        openTab: Function,
        selected_tab_id: Number
    }
};
</script>
  
