<template>
  <div class="connectmi-event__welcome">
    <div class="connectmi-event__welcome__helper">
      <div class="connectmi-event__welcome__info">
        <p class="connectmi-event__welcome__info__date">From {{ startDate }} to {{ endDate }}</p>
        <img :src="createImagePath('connectmi2022-logo.svg')" alt="connectmi-logo" />
        <p class="connectmi-event__welcome__info__desc" v-html="description"></p>
      </div>
      <div class="connectmi-event__welcome__hearts">
        <img :src="createImagePath('heart-yellow.svg')" alt="yellow-heart" />
        <img :src="createImagePath('heart-pink.svg')" alt="pink-heart" />
      </div>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';

export default {
  props: {
    imagePath: String,
    description: String,
    startDate: String,
    endDate: String
  },

  data() {
    return {
      translator: TranslateService.create()
    };
  },

  mounted() {},

  methods: {
    createImagePath(image) {
      return this.imagePath + image;
    },
    t(tag) {
      return this.translator.translate(tag);
    }
  }
};
</script>
