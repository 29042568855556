<template>
  <div class="edit-profile">
      <div v-if="isLoading">
          {{t('general.loading')}}...
      </div>
      <div v-if="!isLoading">
<div class="card-body">
  <form @submit.prevent="validate">
     <div class="add-new-view">
    <div class="v4-input-container">
      <label>{{t("profile.name")}}</label>
      <input type="text" class="filters-input-js" v-bind:class="{ 'is-invalid': nameError }" id="name" :placeholder="t('profile.placeholder_name')" v-model="name" @change="onChange($event.target.id)">
      <div class="invalid-name" id="name_id" v-if="nameError">
        {{ nameMsg }}
      </div>
    </div>
    <div class="v4-input-container">
     <label>{{t("profile.surname")}}</label>
      <input type="text" class="filters-input-js" v-bind:class="{ 'is-invalid': surnameError }" id="surname" :placeholder="t('profile.placeholder_surname')" v-model="surname"  @change="onChange($event.target.id)">
      <div class="invalid-surname" id="surname_id" v-if="surnameError">
        {{ surnameMsg }}
      </div>
    </div>

    <div v-if="is_healthcare_verified == 1 || is_healthcare_verified == 3 || is_healthcare_verified == 4">
      <div class="v4-input-container">
        <label>{{t("profile.institution")}}</label>
        <input type="text" class="filters-input-js" v-bind:class="{ 'is-invalid': institutionError }" id="institution" :placeholder="t('profile.placeholder_institution')" v-model="institution" @change="onChange($event.target.id)">
        <div class="invalid-institution" id="institution_id" v-if="institutionError">
          {{ institutionMsg }}
        </div>
      </div>
      <div class="v4-input-container">
        <label>{{t("profile.institution_address")}}</label>
        <input type="text" class="filters-input-js" v-bind:class="{ 'is-invalid': institution_addressError }" id="institution_address" :placeholder="t('profile.placeholder_institution_address')" v-model="institution_address" @change="onChange($event.target.id)">
        <div class="invalid-institution_address" id="institution_address_id" v-if="institution_addressError">
          {{ institution_addressMsg }}
        </div>
      </div>
      <div class="v4-input-container">
        <select @change="onChange($event.target.id)" id="speciality" name="speciality" :placeholder="t('profile.speciality')" multiple="" :title="t('profile.placeholder_speciality')" class="selectpicker" v-bind:class="{ 'is-invalid': specialityError }" v-bind:data-live-search-placeholder="t('profile.speciality')" data-live-search="true">
          <option v-for="specialities in specialityOptions" :value="specialities.id"  v-bind:key="specialities.id" :selected="selectOption(userspecialityoptions,specialities.id)">{{specialities.name}}</option>
        </select>
        <div class="invalid-speciality" id="specialityer" v-if="specialityError">
          {{ specialityMsg }}
        </div>
      </div>
    </div>
    <div v-else-if="is_healthcare_verified == 2 && isAmgenUser">
      <div class="v4-input-container">
        <select @change="onChange($event.target.id)" id="department" name="department" placeholder="department" class="selectpicker" v-bind:class="{ 'is-invalid': departmentError }" v-bind:data-live-search-placeholder="t('profile.department')" data-live-search="true">
          <option v-for="departments in departmentOptions" :value="departments.id"  v-bind:key="departments.id" :selected="selectsingleOption(userdata.department_id,departments.id)">{{departments.name}}</option>
        </select>
        <div class="invalid-department" id="departmenter" v-if="departmentError">
          {{ departmentMsg }}
        </div>
      </div>
    </div>
    <div class="v4-input-container">
       <select @change="onChange($event.target.id)" id="time_zone" name="time_zone" placeholder="time_zone" class="selectpicker" v-bind:class="{ 'is-invalid': time_zoneError }" v-bind:data-live-search-placeholder="t('profile.timezone')" data-live-search="true">
        <option :key="index" v-for="(zone, index) of time_zoneOptions" :value="zone" :selected="selectsingleOption(userdata.time_zone,zone)">{{ zone }}</option>
      </select>
      <div class="invalid-time_zone" id="time_zoneer" v-if="time_zoneError">
        {{ time_zoneMsg }}
      </div>
    </div>
    <div class="v4-input-container">
      <LanguageSelect
        id="languages"
        v-model="selectedLanguageName"
        @change="onChange($event.target.id)"
        :languages="languageOptions"
        :value="this.userdata.locale_id"
        :is-invalid="languageError" />
      <div class="invalid-language" id="languageer" v-if="languageError">
        {{ languageMsg }}
      </div>
    </div>
    <div class="v4-input-container bottom-instants">
      <div class="edit-bottom-inputs">
        <div class="edit-delete-icon"><a href="javascript:void(0)" @click="removesocial('instagram_id');"><i class="bi bi-trash"></i></a></div>
        <i class="bi bi-instagram"></i> <input type="text" class="filters-input-js socialfields" v-bind:class="{ 'is-invalid': instagramError }" id="instagram_id" :placeholder="t('profile.placeholder_instagram')" v-model="instagram_id"  @change="onChange($event.target.id)">
      </div>
      <div class="invalid-instagram" id="instagram_1" v-if="instagramError">
        {{ instagramMsg }}
      </div>
    </div>
    <div class="v4-input-container bottom-instants">
      <div class="edit-bottom-inputs">
        <div class="edit-delete-icon"><a href="javascript:void(0)" @click="removesocial('twitter_id');"><i class="bi bi-trash"></i></a></div>
        <i class="bi bi-twitter"></i> <input type="text" class="filters-input-js socialfields" v-bind:class="{ 'is-invalid': twitterError }" id="twitter_id" :placeholder="t('profile.placeholder_twitter')" v-model="twitter_id" @change="onChange($event.target.id)">
      </div>
      <div class="invalid-twitter" id="twitter_1" v-if="twitterError">
        {{ twitterMsg }}
      </div>
    </div>
    <div class="v4-input-container bottom-instants">
      <div class="edit-bottom-inputs">
        <div class="edit-delete-icon"><a href="javascript:void(0)" @click="removesocial('facebook_id');"><i class="bi bi-trash"></i></a></div>
        <i class="bi bi-facebook"></i> <input type="text" class="filters-input-js socialfields" v-bind:class="{ 'is-invalid': facebookError }" id="facebook_id" :placeholder="t('profile.placeholder_facebook')" v-model="facebook_id"  @change="onChange($event.target.id)">
      </div>
      <div class="invalid-facebook" id="facebook_1" v-if="facebookError">
        {{ facebookMsg }}
      </div>
    </div>
    <div class="v4-input-container bottom-instants">
      <div class="edit-bottom-inputs">
        <div class="edit-delete-icon"><a href="javascript:void(0)" @click="removesocial('linkedin_id');"><i class="bi bi-trash"></i></a></div>
        <i class="bi bi-linkedin"></i> <input type="text" class="filters-input-js socialfields" v-bind:class="{ 'is-invalid': linkedinError }" id="linkedin_id" :placeholder="t('profile.placeholder_linkedin')" v-model="linkedin_id"  @change="onChange($event.target.id)">
      </div>
      <div class="invalid-linkedin" id="linkedin_1" v-if="linkedinError">
        {{ linkedinMsg }}
      </div>
    </div>
    </div>

    <div class="new-bottom-btn text-right">
    <button class="btn" type="submit" @click="backtoprofile();">{{t("general.cancel")}}</button>
    <button class="v4-btn-primary" type="submit" @click="onSelectChange();">{{t("general.save-changes")}}</button>
    </div>
  </form>
</div>
</div>
</div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import UtilsService from '../../core/services/utils.service';
import TranslationService from '../../core/services/translation.service.js';
import LanguageSelect from '../select-language/LanguageSelect.vue';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import axios from 'axios';
import $ from 'jquery';

export default {
name: 'EditProfile',
props: {
  userdata: Object,
  language: Array,
  specialities: String,
  departments: String,
  time_zones: String,
  userspecialities: String
},
data() {
  return {
    isLoading: true,
    name: '',
    surname: '',
    instagram_id: '',
    twitter_id: '',
    facebook_id: '',
    linkedin_id: '',
    institution: '',
    institution_address: '',
    speciality: '',
    department: '',
    time_zone: '',
    nameError: false,
    surnameError: false,
    instagramError: false,
    twitterError: false,
    facebookError: false,
    linkedinError: false,
    languageError: false,
    institutionError: false,
    institution_addressError: false,
    specialityError: false,
    departmentError: false,
    time_zoneError: false,
    nameMsg: '',
    surnameMsg: '',
    instagramMsg: '',
    twitterMsg: '',
    facebookMsg: '',
    linkedinMsg: '',
    languageMsg: '',
    institutionMsg: '',
    institution_addressMsg: '',
    specialityMsg: '',
    departmentMsg: '',
    time_zoneMsg: '',
    translator: TranslateService.create(),
    languageOptions: this.language,
    specialityOptions: UtilsService.mapObjectToArray(JSON.parse(this.specialities)),
    departmentOptions: UtilsService.mapObjectToArray(JSON.parse(this.departments)),
    time_zoneOptions: JSON.parse(this.time_zones),
    newlanguages: '',
    userspecialityoptions: '',
    newspeciality: '',
    newdepartment: '',
    newtime_zone: '',
    is_healthcare_verified: '',
    isAmgenUser: window.user.is_amgen,
    selectedLanguage: null,
    changedFields: []
  };
},
components: {
  LanguageSelect
},
mounted() {
    this.getUserdata();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    getUserdata() {
      this.name = this.userdata.name;
      this.surname = this.userdata.surname;
      this.instagram_id = this.userdata.instagram_url;
      this.twitter_id = this.userdata.twitter_url;
      this.facebook_id = this.userdata.facebook_url;
      this.linkedin_id = this.userdata.linkedin_url;
      this.newlanguages = $('#languages').val();
      this.institution = this.userdata.institution;
      this.institution_address = this.userdata.institution_address;
      this.userspecialityoptions = this.mapSelect(Object.values(JSON.parse(this.userspecialities)));
      this.newspeciality = $('#speciality').val();
      this.newdepartment = $('#department').val();
      this.newtime_zone = this.userdata.time_zone;
      this.is_healthcare_verified = this.userdata.is_healthcare_verified;
      this.isLoading = false;
    },
  onChange(id) {
    if (!this.changedFields.includes(id)) {
      this.changedFields.push(id);
    }
  },
  onSelectChange() {
    this.newlanguages = $('#languages').val();
    this.instagram_id = $('#instagram_id').val();
    this.twitter_id = $('#twitter_id').val();
    this.facebook_id = $('#facebook_id').val();
    this.linkedin_id = $('#linkedin_id').val();
    this.newspeciality = $('#speciality').val();
    this.newdepartment = $('#department').val();
    this.newtime_zone = $('#time_zone').val();
  },
  validate() {
    // this.onSelectChange();
    if (this.changedFields.length > 0) GoogleAnalytics.sendProfileEditEvent(this.getChangedFieldsAsString());
    var len = this.name.length;
    if (len < 1) {
      this.nameError = true;
      this.nameMsg = 'Name field is required';
    } else {
      this.nameError = false;
      document.getElementById('name').className = 'form-control is-valid';
    }

    var surname = this.surname.length;
    if (surname < 1) {
      this.surnameError = true;
      this.surnameMsg = 'Surname field is required.';
    } else {
      this.surnameError = false;
      document.getElementById('surname').className = 'form-control is-valid';
    }

    if (this.newlanguages == '' || this.newlanguages == null || this.newlanguages.length < 1) {
      this.languageError = true;
      this.languageMsg = 'Language field is required.';
    } else {
      this.languageError = false;
      document.getElementById('languages').className = 'form-control is-valid';
    }

    if (this.is_healthcare_verified == 1 || this.is_healthcare_verified == 3 || this.is_healthcare_verified == 4) {
      if (this.newspeciality == '' || this.newspeciality == null || this.newspeciality.length < 1) {
        this.specialityError = true;
        this.specialityMsg = 'Speciality field is required.';
      } else {
        this.specialityError = false;
        document.getElementById('languages').className = 'form-control is-valid';
      }
    }

    if (this.is_healthcare_verified == 2 && this.isAmgenUser) {
      if (this.newdepartment == '' || this.newdepartment == null || this.newdepartment.length < 1) {
        this.departmentError = true;
        this.departmentMsg = 'Department field is required.';
      } else {
        this.departmentError = false;
        document.getElementById('languages').className = 'form-control is-valid';
      }
    }

    if (this.newtime_zone == '' || this.newtime_zone == null || this.newtime_zone.length < 1) {
      this.time_zoneError = true;
      this.time_zoneMsg = 'Time zone field is required.';
    } else {
      this.time_zoneError = false;
      document.getElementById('time_zone').className = 'form-control is-valid';
    }

    if (!this.nameError && !this.surnameError && !this.languageError && !this.specialityError && !this.departmentError && !this.time_zoneError) {
      this.save();
    }
  },
  async save() {
    const response = await this.postData();
    if (response.status == 200) {
      window.location.href = TranslationService.transRoute('profile');
    }
  },
  backtoprofile() {
    this.getUserdata();
    window.location.href = TranslationService.transRoute('profile');
  },
  postData() {
    return axios.post('/profile',
      {
        name: this.name,
        surname: this.surname,
        instagram_url: this.instagram_id,
        twitter_url: this.twitter_id,
        facebook_url: this.facebook_id,
        linkedin_url: this.linkedin_id,
        language: this.newlanguages,
        institution: this.institution,
        institution_address: this.institution_address,
        speciality: JSON.stringify(this.newspeciality),
        department: this.newdepartment,
        time_zone: this.newtime_zone
      });
  },
  isMobile() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  },
  mapSelect(collection) {
    return collection.map(i => i.id);
  },
  selectOption(list, id) {
    return list.includes(parseInt(id));
  },
  selectsingleOption(list, id) {
    return list == id ? true : false;
  },
  removesocial(fieldname) {
    $('#' + fieldname).val(' ');
  },
  getChangedFieldsAsString() {
    return this.changedFields.join(',');
  },
  restrictSpecialChars(val) {
    return /[ºª@\/\\#,+()$~%":*¿?<>{}=\[\];_]/.test(val);
  }
},
computed: {
  selectedLanguageName: {
    get() {
      return this.selectedLanguage ??
        this.language
          .filter(language => language.id == this.userdata.locale_id)
          .shift()
          ?.code
    },
    set(newValue) {
      this.selectedLanguage = newValue
    }
  }
},
watch: {
  selectedLanguage(newValue, oldValue) {
    this.newlanguages = newValue;      
    if (newValue !== oldValue) {
      this.postData()
    }
  },
  name(val) {
    if (val == null) {
      this.name = '';
      return;
    }
    this.name = val.replace(/[ºª@\/\\#,+()$~%":*¿?<>{}=\[\];_]/, "");
    if (this.restrictSpecialChars(val)){
      this.nameError = true;
      this.nameMsg = 'Special characters are not allowed';
    };
  },
  surname(val) {
    if (val == null) {
      this.surname = '';
      return;
    }
    this.surname = val.replace(/[ºª@\/\\#,+()$~%":*¿?<>{}=\[\];_]/, "");
    if (this.restrictSpecialChars(val)){
      this.surnameError = true;
      this.surnameMsg = 'Special characters are not allowed';
    };
  },
  institution(val) {
    if (val == null) {
      this.institution = '';
      return;
    }
    this.institution = val.replace(/[ºª@\/\\#,+()$~%":*¿?<>{}=\[\];_]/, "");
    if (this.restrictSpecialChars(val)){
      this.institutionError = true;
      this.institutionMsg = 'Special characters are not allowed';
    };
  },
}
};
</script>
