<template>
  <section id="virtual-booth-section" class="py-3">
    <div class="virtual-booth-section align-items-center"  :class="without_container_class" :id="configuration.section_wording.replace(/\s+/g, '')">
      <h2 class="virtual-booth-section__title text-center">{{ configuration.section_wording }}</h2>
      <div v-if="isLoading">
        {{ t('congresses.loading') }}
      </div>
      <VirtualBoothMultipleContent v-if="configuration.configuration.custom_card_type == 'multiple_content' && !isLoading && list.length" :configuration="configuration" :list="list"></VirtualBoothMultipleContent>
      <VirtualBoothFocusContent v-if="(configuration.configuration.custom_card_type == 'title_focus' || configuration.configuration.custom_card_type == 'image_focus') && !isLoading && list.length" :configuration="configuration" :list="list"></VirtualBoothFocusContent>
      <VirtualBoothHighlightContent v-if="configuration.configuration.custom_card_type == 'highlight_content'&& !isLoading && list.length" :configuration="configuration" :list="list"></VirtualBoothHighlightContent>
      <div v-if="!isLoading && !list.length">
        <p>{{ t('congresses.no_virtual_room') }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import TranslateService from '../../core/services/translate.service';
import VirtualBoothMultipleContent from './virtual-booth/VirtualBoothMultipleContent.vue';
import VirtualBoothFocusContent from './virtual-booth/VirtualBoothFocusContent.vue';
import VirtualBoothHighlightContent from './virtual-booth/VirtualBoothHighlightContent.vue';
import axios from 'axios';

export default {
  name: 'VirtualBoothSection',
  components: {
    VirtualBoothMultipleContent,
    VirtualBoothFocusContent,
    VirtualBoothHighlightContent,
  },
  props: {
    configuration: Object,
    congress_slug: String,
  },
  setup(props) {
    const translator = TranslateService.create();
    const t = (tag) => translator.translate(tag);

    const isLoading = ref(true);
    const list = ref([]);
    const configuration = props.configuration;
    let without_container_class = '';
    if (configuration.configuration.custom_card_type == 'multiple_content' || configuration.configuration.custom_card_type == 'highlight_content') {
      without_container_class = 'without-container';
    }

    onMounted(() => {
      getVirtualBoothData();
    });

    const isMobile = () => {
      try {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      } catch (error) {
        console.error('Error determining if the device is mobile:', error);
        return false;
      }
    };

    const getVirtualBoothData = async () => {
      try {
        if (!props.congress_slug) {
          console.error('The value of props.congress_slug is invalid');
          isLoading.value = false;
          return;
        }

        const response = await axios.get(`/congresses/virtual-booth?slug=${props.congress_slug}`);
        if (response.data && response.data.data) {
          list.value = response.data.data;
        } else {
          console.warn('No virtual booth data found');
          list.value = [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      t,
      isMobile,
      isLoading,
      list,
      without_container_class,
    };
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
