<template>
  <section class="speakers mode-speakaer custom-congress-speakers" id="speakers" v-if="isDisplay">
    <div class="container">
      <div class="speakers-head">
        <h2 class="text-center custom-congress-title">{{t("congress.speakers")}} </h2>
      </div>
      <div v-if="isLoading">
        {{t('general.loading')}}...
      </div>
      <div class="speakers-list custom-congress-date" v-if="!isLoading">
        <ul class="text-center">
          <li :id="'speakers_head_'+k" v-for="(item, k) in tabInfo" v-bind:key="k" @click="openTab(k)"><a href="javascript:void(0);" :class="{ 'active' : k == selected_tab_id }">{{item}}</a></li>
        </ul>
      </div>
      <div class="four-speakers custom-congress-carousel">
        <div>
          <div v-for="(item, i) in tabInfo" v-bind:key="i">
            <div v-if="!isLoading">
              <div v-if="!isMobile()">
                <div class="speaker_hidden" :id="'speaker_common_'+i">
                  <V4Carousel  :slidesToShow="4" :slidesToScroll="4" :name="'speakers'+i">
                    <template v-slot:items>
                      <div v-for="(itemInfo, j) in speakerInfo[i]" v-bind:key="j">
                        <div class="col-sm-12" @click="showModal(itemInfo);">
                          <div class="speak-user text-center">
                            <div class="speaker-img">
                              <img :src="parseImage(itemInfo.photo)" @error="imageUrlAlt" alt="">
                            </div>
                            <div class="user-name-speaker">
                              <h4 class="limit-2-rows">{{itemInfo.name+' '+itemInfo.surname}}</h4>
                              <p class="limit-3-rows">{{getSpeakerItemInfo(itemInfo)}}</p>
                            </div>
                          </div>
                        </div>
                      </div> 
                    </template>
                  </V4Carousel>
                </div>
              </div>
              <div v-else>
                <div class="speaker_hidden" :id="'speaker_common_'+i">
                  <V4Carousel  :slidesToShow="1" :slidesToScroll="1" :name="'speakers'+i">
                    <template v-slot:items>
                      <div v-for="(itemInfo, j) in speakerInfo[i]" v-bind:key="j">
                        <div class="col-sm-12" @click="showModal(itemInfo);">
                          <div class="speak-user text-center">
                            <div class="speaker-img">
                              <img :src="parseImage(itemInfo.photo)" @error="imageUrlAlt" alt="">
                            </div>
                            <div class="user-name-speaker">
                              <h4>{{itemInfo.name+' '+itemInfo.surname}}</h4>
                              <p>{{itemInfo.position}}</p>
                            </div>
                          </div>
                        </div>
                      </div> 
                    </template>
                  </V4Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import SpeakerFactory from './factories/Speaker.factory';
import SpeakerModel from "./ModalComponent.vue";
import V4Carousel from '../carousel/V4Carousel.vue';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'speakers',
  components: {
    SpeakerModel,
    V4Carousel
  },
  data() {
    return { 
      isDisplay: true,
      isModalVisible: false,
      isLoading: true,
      speakerInfo: [],
      full_name: '',
      display_pic: '',
      bio_info: '',
      position_info: '',
      institution_info: '',
      tabInfo: [],
      translator: TranslateService.create(),
      selected_tab_id : 0,
    }
  },
  props: ['slug'],
  mounted() {
    this.speakers();
  },
  methods:{
    t(tag) {
      return this.translator.translate(tag);
    },
    async speakers() {
      const response = await this.getSpeakerData();
      var speak = [];
      var tabs = [];
      var hideTabs = false;
      $.each(response.data.data, function(key, value) {
        const results = value.map(k => SpeakerFactory.get(k));
        speak.push(results);
        tabs.push(key);
        
        //If only show featured speakers, hide tabs
        if(key == '') {
          hideTabs = true;
        }
      });
      this.speakerInfo = speak;
      this.tabInfo = tabs;
      this.isLoading = false;
      if(hideTabs) {
        $('.speakers-list').addClass('d-none');
      }
      setTimeout(() => {
        this.openTab(0);
      }, 800);
      if(this.speakerInfo.length==0) {
        this.isDisplay = false;
        $('#speakers_menu').hide();
      }
    },
    getSpeakerData() {
      return axios.get('/congresses/grouped-speakers?slug='+this.slug);
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    openTab(tabId) {
      setTimeout(() => {
        $('.speaker_hidden').hide();
        $('#speaker_common_'+tabId).show();
        $('.v4-carousel-speakers'+tabId).slick('resize');
        $('.v4-carousel-speakers'+tabId).slick('refresh');
      }, 100);
      this.selected_tab_id = tabId;
    },
    showModal(itemInfo) {
      document.dispatchEvent(new CustomEvent("showSpeakerModal", { detail: {
        id: itemInfo.id,
        name: itemInfo.name,
        surname: itemInfo.surname,
        institution: itemInfo.institution,
        bio: itemInfo.bio,
        photo: this.parseImage(itemInfo.photo),
        video: itemInfo.video
      }}));
    },
    imageUrlAlt(event) {
      event.target.src = "/images/v4/assets/avatar.svg";
    },
    getSpeakerItemInfo(item) {
      let info = '';
      if(item.position) info = item.position;
      if(item.position && item.institution) info = info + ' - ';
      if(item.institution) info = info + item.institution;
      return info;
    },
    parseImage(img) {
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
  }
}
</script>

<style lang="scss">
  .btn {
    padding: 8px 16px;
    border-radius: 5px;
    &--primary {
      background-color: green;
      color: #fff;
    }
    &--secondary {
      background-color: #dddd;
      color: #000;
    }
  }

  // utilities
  .overflow-hidden {
    overflow: hidden;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .d-flex {
    display: flex;
  }
  .align-items-center {
    align-items: center;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  // reset
  button {
    background: none;
    border: none;
    outline: inherit;
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
</style>
