<template>
  <div>
    <div v-if="!isMobile()">
      <!-- DESKTOP -->
      <CustomContentSearchFields 
        :therapeutics="therapeutics" 
        :user-therapeutic-areas="userareas"
        :congressid="congressid" 
        @SearchEvent="searchContent" 
        :filtersAvailable="filtersAvailable" 
        :filtersType="filtersType"
        :contentGrid="contentGrid"
      >
      </CustomContentSearchFields>
    </div>
    <div v-else class="button-row">
      <!-- MOBILE -->
      <button type="button" data-toggle="modal" data-target="#content-modal" class="button-open-content">
        <i class="bi bi-filter"></i> {{ t("congresses.show_filters") }}
      </button>
      <!-- Modal -->
      <div class="modal fade" id="content-modal" tabindex="-1" role="dialog" aria-labelledby="content-modal" aria-hidden="true">
        <div class="modal-dialog modal-lg theme-dark-modal" role="document">
          <div class="modal-content p-md-5">
            <div class="modal-body px-3">
              <div class="modal-close-row">
                <i class="bi bi-x-lg" data-dismiss="modal" aria-label="Close"></i>
              </div>
              <div class="content-modal-body modal-body-content ">
                <CustomContentSearchFields 
                  :therapeutics="therapeutics" 
                  :user-therapeutic-areas="userareas"
                  :congressid="congressid" 
                  @SearchEvent="searchContent" 
                  :filtersAvailable="filtersAvailable" 
                  :filtersType="filtersType"
                  :contentGrid="contentGrid"
                >
                </CustomContentSearchFields>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TranslateService from '../../../core/services/translate.service.js';
import CustomContentSearchFields from './CustomContentSearchFields.vue';

export default {       
  name: 'CustomTemplateContentSearch',
  components: {
    CustomContentSearchFields
  },
  props: {
    therapeutics: {
      type: Object,
      required: true
    },
    userareas: {
      type: Array,
      default: () => []
    },
    congressid: {
      type: Number,
      required: true
    },
    filtersAvailable: {
      type: Array,
      default: null
    },
    filtersType: {
      type: String,
      default: null
    },
    contentGrid: {
      type: String,
      required: true
    }
  },
  methods: {
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    searchContent(filter_data) {
      this.$emit("searchFullContent", filter_data);                  
    },
    // keywordChanged(keyword_id) {
    //   this.$refs.contentSearchFields.updateKeyword(keyword_id);
    // }
  },
  data() {
    return {
      translator : TranslateService.create()
    }
  }
}
</script>
