<template>
  <div>
    <div v-if="!isMobile()">
      <!-- DESKTOP -->
      <ContentSearchFields :congressid="congressid" @SearchEvent="searchContent">
      </ContentSearchFields>    
    </div>
    <div v-else class="button-row">
      <!-- MOBILE -->
      <button type="button" data-toggle="modal" data-target="#content-modal" class="button-open-content">
        <i class="bi bi-filter"></i> {{ t("congresses.show_filters") }}
      </button>
      <!-- Modal -->
      <div class="modal fade" id="content-modal" tabindex="-1" role="dialog" aria-labelledby="content-modal" aria-hidden="true">
        <div class="modal-dialog modal-lg theme-dark-modal" role="document">
          <div class="modal-content p-md-5">
            <div class="modal-body px-3">
              <div class="modal-close-row">
                <i class="bi bi-x-lg" data-dismiss="modal" aria-label="Close"></i>
              </div>
              <div class="content-modal-body modal-body-content ">
                <ContentSearchFields :congressid="congressid" @SearchEvent="searchContent">
                </ContentSearchFields>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TranslateService from '../../../core/services/translate.service.js';
import ContentSearchFields from './ContentSearchFields.vue';

export default { 
  name: 'CustomContentSearch',
  components: {
    ContentSearchFields
  },       
  props: {
    congressid: Number
  },
  methods: {
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    searchContent(filter_data) {
      console.log('En search content');
      this.$emit("searchFullContent", filter_data);                  
    },
    // keywordChanged(keyword_id) {
    //   this.$refs.contentSearchFields.updateKeyword(keyword_id);
    // }
  },
  data() {
    return {
      translator : TranslateService.create()
    }
  }
}
</script>
