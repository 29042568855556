<template>
  <div class="connectmi-event__header">
    <div class="connectmi-event__header__helper">
      <a @click="scrollToTop()">
        <img class="connectmi-event__header__logo" :src="createImagePath('amgen-logo.svg')" alt="amgen-logo" />
      </a>
     <CustomFloatingMenu :list="this.list"></CustomFloatingMenu>
      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Menu
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a v-for="(li, key) in this.list" :key="key" :href="li.link" class="dropdown-item">{{ li.text }}</a>
        </div>
      </div>
      <button class="connectmi-event__header__live" v-if="currentLive" @click="openLive()">Now Live!</button>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import TranslationService from '../../core/services/translation.service';
import ActivityService from '../../core/services/activity.service';
import EventRepository from '../events/repositories/Event.repository';
import AgendaFactory from '../agenda/factories/Agenda.factory';
import PageStayController from '../../page-stay/PageStayController';
import CustomFloatingMenu from '../events/event-internal/CustomFloatingMenu.vue';
import $ from 'jquery';

export default {
  name: 'ConnectMiEventHeader',
  components: {
    CustomFloatingMenu
  },

  props: {
    imagePath: String,
    slug: String,
    congressId: Number,
    hasAgenda:Boolean,
    hasCommitte:Boolean,
    hasSpeakers:Boolean,
    hasContent:Boolean,

  },
  data() {
    return {
      translator: TranslateService.create(),
      activitySvc: ActivityService.create(),
      currentLive: null,
      list: this.setListItems(),
    };
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  mounted() {
    this.initStayPage();
    this.getCurrentLives();
  },

  methods: {
    initStayPage() {
      PageStayController.startStayPageTime('congress', this.congressId);
    },
    createImagePath(image) {
      return this.imagePath + image;
    },
    handleScroll(e) {
      if ($(e.target).scrollTop() > 50) {
        $('.connectmi-event__header').addClass('connectmi-event__header--active');
      } else {
        $('.connectmi-event__header').removeClass('connectmi-event__header--active');
      }
    },
    async getCurrentLives(){
        const liveEvents = await EventRepository.getLiveEvents(this.slug);
        if(liveEvents && liveEvents.count && liveEvents.count > 0){
          this.currentLive = this.refactorLive(liveEvents.lives[0]);
        }
    },
    openLive() {
        if(this.currentLive.type == 'agenda_session') {
            if (this.currentLive.cards_behaviour == 'external link') {
                // this.$root.$emit('leavingMMPModal', this.currentLive.external_link);
                document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.currentLive.external_link }));        
            } else if (this.currentLive.cards_behaviour == 'open modal') {
                let agendaURL = '';
                if ((this.currentLive.is_live || this.currentLive.is_on_demand)&& this.currentLive.is_accessible && !this.currentLive.external_congress) {
                    agendaURL = TranslationService.transRoute('event-detail-live', { slug: this.slug, agendaSessionId: this.currentLive.id });
                    this.saveActivity('live');
                } else {
                    agendaURL = TranslationService.transRoute('event-agenda-detail', { slug: this.slug, agendaSessionId: this.currentLive.id });
                    this.saveActivity('agenda');
                }
                return window.open(agendaURL, '_blank').focus();
            }
        }
    },
    refactorLive(live) {
      if(!live){
        throw new Error('Live not found');
      }
      return AgendaFactory.get(live);
    },
    saveActivity(event) {
      let params = {
          title: this.currentLive.title,
          model_type: 'agenda_session',
          model_id: this.currentLive.id,
          source: null,
          congress_id: this.currentLive.congress_id
      }
      this.activitySvc.saveActivity(event, params);
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    scrollToTop(){
      window.scrollTo(0, 0);
    },
    setListItems(){
      let list = [];
      if(this.hasAgenda){
        list = [...list,({ link: '#agenda', text: 'Agenda'})];
      }
      if(this.hasCommitte){
        list = [...list,({ link: '#committee', text: 'Steering Committee' })];
      }
      if(this.hasSpeakers){
        list = [...list,({ link: '#speakers', text: 'Speakers' })];
      }
      if(this.hasContent){
        list = [...list,({ link: '#content', text: 'Content' })];
      }
      return list;
    }

  }
};
</script>
