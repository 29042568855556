<template>
  <section>
    <section class="congress-live-section">
      <div class="congress-nav-button">
        <span
          class="button-top"
          :class="{'button-top-active': showContentSpeaker}"
          @click="showblocks()">
          {{ this.setHeaderText()}}
          <span class="button-top-icon">
            <i v-bind:class="['bi', this.collapsedNavButton ? 'bi-chevron-right' : 'bi-chevron-left']"></i>
          </span>
        </span>
        <span class="live-now-header"> <i class="bi bi-circle-fill"></i> {{ t('congress.live-now') }}!</span>
      </div>
    </section>
    <section v-if="showContentSpeaker" class="contentspeakerblk contentspeakerlist congress-live">
      <section class="popup-scroll-section">
        <section id="congress-live" class="container-fluid">
          <div class="row">
            <div class="title-content col-12 col-sm-12 col-md-12 col-xl-12">
              <p class="date-text text-left" v-if="this.agendaInfo.date_formatted != ''">
                {{ this.agendaInfo.date_formatted }}
              </p>
              <h1 class="subtitle-heading">
                <span class="subtitle-span" v-html="this.agendaInfo.title"></span>
                <span class="live-text" v-if="this.agendaInfo.liveDate != ''"
                  >{{ t('congress.live_text') }}{{ this.agendaInfo.liveDate }}</span
                >
              </h1>
            </div>
            <div class="content-description col-12 col-sm-12 col-md-12 col-xl-12">
              <p class="no-margin" v-html="this.agendaInfo.description"></p>
            </div>
            <div class="content-download col-12 col-sm-12 col-md-12 col-xl-12" v-if="this.agendaDoc.length > 0">
              <ul class="content-ul">
                <li v-for="(item, index) in this.agendaDoc" :key="index" class="content-lists">
                  <a
                   :href="parseLink(item.file)"
                   :download="`${item.title}.pdf`"
                    class="v4-action-link"
                    v-if="item.downloadable == true || item.downloadable == 1"
                  >
                    <span class="title-text text-bold">
                      <span class="list-icons"><i class="bi bi-download"></i></span>
                      <span class="list-text" v-html="item.title"></span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="agenda-content container-fluid">
          <div>
            <CongressLiveMedia :agendasessionid="agendasessionid"></CongressLiveMedia>
          </div>
        </section>
        <section class="agenda-speakers-content container-fluid speakerssection" v-if="speakers.length > 0">
          <h4 class="v4-subtitle-live m-0">{{ t('congress.speakers') }}</h4>
          <SpeakersSection :agendasessionid="agendasessionid" :speakers="speakers" :template="template"></SpeakersSection>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import AgendaFactory from '../agenda/factories/Agenda.factory.js';
import TranslateService from '../../core/services/translate.service.js';
import CongressLiveMedia from './CongressLiveMedia.vue'
import SpeakersSection from '../multimedia/SpeakersSection.vue';
import axios from 'axios';
import $ from 'jquery';

export default {
  components : {
    CongressLiveMedia,
    SpeakersSection
  },
  props: {
    agendasessionid: Number,
    speakers: Array,
    template: Number,
    congress: Object
  },
  created: function() {
    this.agendaBasicInfo();
    this.agendaDocInfo();
  },
  mounted: function() {
      let that = this;
      this.interval = setInterval(function() {
      const liveCountdown = AgendaFactory.getLiveDateCountdown(that.agendaInfo.startDateUtc);
      if (liveCountdown === 'stop') {
        that.agendaInfo.liveDate = '';
        that.stopInterval();
      } else {
        that.agendaInfo.liveDate = liveCountdown;
        that.$nextTick();
      }
      }, 1000);
    document.addEventListener("showAgendaImage", () => {
      $('.agenda-thumbnail-img').removeClass('d-none');
    });
    /* this.$root.$on('showAgendaImage', () => {
          $('.agenda-thumbnail-img').removeClass('d-none');
        }); */
  },
  data: function() {
    return {
      translator: TranslateService.create(),
      agendaInfo: Object,
      agendaDoc: Array,
      collapsedNavButton: true,
      userTimezone: window.user.time_zone ?? 'UTC',
      interval: 0,
      showContentSpeaker: false,
    };
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    setHeaderText() {
      return this.congress.liveContentSectionHeader ?  this.congress.liveContentSectionHeader :'CONTENT AND SPEAKERS';
    },
    showblocks() {
      this.showContentSpeaker = !this.showContentSpeaker;
      this.collapsedNavButton = !this.collapsedNavButton;

      if (this.showContentSpeaker) {
        $('.v4-carousel-agendamedlisting').slick('refresh');
      }
    },
    agendaBasicInfo() {
      axios
        .get(`/agenda-session/info`, {
          params: {
            id: this.agendasessionid,
            timezone: this.userTimezone
          }
        })
        .then(res => {
          this.agendaInfo = AgendaFactory.get(res.data.data);
        });
    },
    agendaDocInfo() {
      axios
        .get(`/agenda-session/multimedia`, {
          params: {
            id: this.agendasessionid,
            type: 'document'
          }
        })
        .then(res => {
          this.agendaDoc = res.data.data;
        });
    },
    parseLink(link) {
      if (link && link.substr(0,1) != '/') {
        link = '/' + link;
      }
      return link;
    },
    stopInterval(){
      clearInterval(this.interval);
    }
  }
};
</script>
