
<template>
    <div class="agenda-event-popup" :style="{top: clientY + 'px', left: clientX + 'px'}">
        <div :class="image && !minimalisticAgendaPopUp ? 'agenda-event-popup__header has-image' : 'agenda-event-popup__header'" :style="image ? {background: 'url(/' + image + ')'} : null">
            <i class="bi bi-x-lg" @click="$emit('close')"></i>
        </div>
        <div class="agenda-event-popup__body">
          <div class="agenda-event-popup__body-wrapper">
              <i v-if="!minimalisticAgendaPopUp" class="bi bi-circle-fill" :style="{ color: categoryColor }"></i>
              <p v-bind:class="{ 'agenda-event-popup__title': !minimalisticAgendaPopUp, 'agenda-event-popup__title_minimal': minimalisticAgendaPopUp }" >{{ title }}</p>
              <p class="agenda-event-popup__date">{{ getFormatData() }}</p>
              <p v-if="!minimalisticAgendaPopUp" class="agenda-event-popup__description" v-html="description"></p>
              <button class="agenda-event-popup__view" v-if="!isLive" @click="cardRedirect(link)">
                  <span>View Full Session Details</span>
              </button>
              <button class="agenda-event-popup__join" v-if="isLive"  @click="cardRedirect(link)">
                  <span>Join</span>
              </button>
              <p class="agenda-event-popup__subtitle" v-if="speakers.length > 0">
                  <span>Speakers</span>
                  <i class="bi bi-chevron-down" v-if="speakers.length > 2" @click="toggleDropDown($event)"></i>
              </p>
              <div class="agenda-event-popup__speakers-container" :class="{ short: speakers.length > 2 }" v-if="speakers.length > 0">
                  <div class="agenda-event-popup__speaker" v-for="(speaker, index) in speakers" :key="index">
                      <img alt="speaker-image" v-if="speaker.photo" :src="'/' + speaker.photo" />
                      <div>
                          <span class="agenda-event-popup__speaker-name">{{ speaker.name }} {{ speaker.surname }}</span>
                          <span class="agenda-event-popup__speaker-job">{{ speaker.position }}</span>
                      </div>
                  </div>
              </div>
              <p class="agenda-event-popup__subtitle" v-if="contents.length > 0 && !minimalisticAgendaPopUp">
                  <span>On-demand content</span>
                  <i class="bi bi-chevron-down" v-if="contents.length > 2" @click="toggleDropDown($event)"></i>
              </p>
              <div class="agenda-event-popup__demand-container" :class="{ short: contents.length > 2 }" v-if="contents.length > 0 && !minimalisticAgendaPopUp">
                  <div class="agenda-event-popup__content" v-for="(content, index) in contents" :key="index">
                      <a>
                          <i :class="getIcon(content)"></i>
                          <span>{{ content.title }}</span>
                      </a>
                  </div>
              </div>
          </div>
        </div>
        <div class="agenda-event-popup__footer">
            <button class="agenda-event-popup__on-demand" v-if="onDemand">
                <span>On Demand</span>
            </button>
            <div class="agenda-event-popup__add-calendar" v-if="!isLive && !onDemand && (!isBookASeat|| (isBookASeat && isRegisteredData))">
                <!-- Button code -->
                <div title="Add to Calendar" class="addeventatc" @click="registerLogAddToCalendar">
                    <span>Add to Calendar</span>
                    <i class="bi bi-chevron-down"></i>
                    <span class="start">{{ startDate }}</span>
                    <span class="end">{{ endDate }}</span>
                    <span class="timezone">{{timezone}}</span>
                    <span class="title">{{title}}</span>
                    <span class="description">{{plainTextDescription}}</span>
                    <span class="location">{{ location }}</span>
                </div>
            </div>
            <div class="agenda-event-popup__live" v-if="isLive && !onDemand">
                <i class="bi bi-circle-fill"></i>
                <span>Live now</span>
            </div>
            <button class="agenda-event-popup__register" v-if="isBookASeat && !isRegisteredData && !onDemand && isInRegisterTime" @click="bookASeat($event, id)">
                <span>Register</span>
            </button>
            <button class="agenda-event-popup__unregister" v-if="isRegisteredData && !onDemand">
                <i class="bi bi-calendar-check"></i>
            </button>
        </div>
        <EventModalComponent v-show="showModal" @close="closeModal()" :start="startDate" :end="endDate" :timezone="timezone" :title="title" :description="description" :plainTextDescription="plainTextDescription" :location="location" :agendaSessionId="id"></EventModalComponent>
        <div class="agenda-event-popup__backdrop" @click="$emit('close')"></div>

    </div>
</template>

<script>
    import moment from 'moment';
    import ActivityService from '../../core/services/activity.service';
    import TranslationService from '../../core/services/translation.service';

    import EventModalComponent from '../modals/eventRegisterModal.vue';

    export default {

        props: ['id', 'clientX', 'clientY', 'popupEvent', 'image', 'categoryColor', 'title', 'date', 'hour', 'description', 'speakers', 'contents', 'isLive', 'isRegistered', 'onDemand', 'timezone', 'startDate', 'endDate', 'minutesToOpenLiveSession', 'isBookASeat', 'link',
        'slug', 'link', 'type', 'cards_behaviour', 'external_redirect', 'external_url', 'is_accessible', 'external_congress', 'preventOpen', 'location', 'minimalisticAgendaPopUp', 'meeting_platform'],

        components: {
          EventModalComponent
        },

        data() {
          return {
            activitySvc: ActivityService.create(),
            isInRegisterTime: false,
            showModal: false,
            isRegisteredData: this.isRegistered,
            plainTextDescription: this.getPlainTextDescription(this.description),
          };
        },

        created() {
          let finalTime = moment(this.startDate).subtract(this.minutesToOpenLiveSession, 'm').format("yyyy-MM-DD hh:mm:ss");
          
          if(finalTime > moment().format("yyyy-MM-DD hh:mm:ss")) {
            this.isInRegisterTime = true;
          }
        },
          
        mounted() {
          let a = document.querySelector('.agenda-event-popup').style.top;
          let oldTop = parseInt(a.substring(0, a.length - 2));

          let eleHeight = document.querySelector('.agenda-event-popup').offsetHeight;
          let scrHeight = window.innerHeight;
          let posHeight = this.popupEvent.y;

          if ((eleHeight + posHeight) > scrHeight) {
            // Popup shows out of screen
            let calc = (eleHeight + posHeight) - scrHeight;
            document.querySelector('.agenda-event-popup').style.top = 'calc(' + (oldTop - calc) + 'px - 1.5rem)';
          }
        },

        methods: {
            async bookASeat(e, agendaId) {
                this.saveActivity('agenda-book-seat');
                const response = await axios.post(
                    '/agenda-session/attendee-registration',
                    {
                        userId: window.user.id,
                        agendaSessionId: agendaId,
                    }
                );

                if (response.status == 200) {
                    this.isRegisteredData = true;
                    this.$emit('changeIsRegisteredData', {isRegisteredData: this.isRegisteredData, agendaSessionId:this.id});
                    this.openModal();
                };
            },

            toggleDropDown(e) {
                e.target.classList.toggle("open");
                e.target.parentElement.nextElementSibling.classList.toggle("open");
            },

            cardRedirect(url) {
              if (this.preventOpen) {
                return;
              }

              if (this.type !== 'agenda_session' && this.type !== 'agenda') {
                this.onMultimediaClick();
                if (this.cards_behaviour == 'disable modal') {
                  return;
                }
                if (this.external_redirect) {
                  document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.external_url }));
                  // this.$root.$emit('leavingMMPModal', this.external_url);
                } else {
                  this.saveActivity(this.getActivityType());
                  return window.open(url, '_blank').focus();
                }
              } else {
                if (this.cards_behaviour == 'external link') {
                  document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.$attrs.external_link }));
                  // this.$root.$emit('leavingMMPModal', this.$attrs.external_link);
                } else if (this.cards_behaviour == 'open modal') {
                  let agendaURL = '';
                  if ((this.isLive && this.is_accessible && !this.external_congress)
                    || (this.onDemand  && this.is_accessible && !this.external_congress && this.contents.length <= 0)) {
                    if(this.meeting_platform == 'ZOOM' && window.app.env.ENABLE_LIVE){
                      agendaURL = window.app.env.LIVE_URL+'/zoom/'+this.slug+'/'+this.id+'?encryptsKey=live-encrypts'+window.user.id;
                    }else if(this.meeting_platform == 'WEBEX' && window.app.env.ENABLE_LIVE){
                      agendaURL = window.app.env.LIVE_URL+'/webex/'+this.slug+'/'+this.id+'?encryptsKey=live-encrypts'+window.user.id; 
                    }else{
                      agendaURL = TranslationService.transRoute('event-detail-live', {
                        slug: this.slug,
                        agendaSessionId: this.id
                      });
                    }
                    this.saveActivity('live');
                  } else {
                    agendaURL = TranslationService.transRoute('event-agenda-detail', {
                      slug: this.slug,
                      agendaSessionId: this.id
                    });
                    this.saveActivity('agenda');
                  }
                  return window.open(agendaURL, '_blank').focus();
                }
              }
            },

            onMultimediaClick() {
              const dataTracks = this.$attrs.dataTracks || [];
              // Save item to localStorage:
              localStorage.setItem(`datatracks-m-${this.id}`, JSON.stringify(dataTracks));
              return true;
            },

            saveActivity(event) {
              let params = {
                title: this.title,
                model_type: this.type,
                model_id: this.id,
                congress_id: window.app.event ? window.app.event.id : null
              };
              this.activitySvc.saveActivity(event, params);
            },

            getActivityType() {
              switch (this.type) {
                case 'multimedia':
                  return 'open-multimedia';
                case 'congress':
                  return 'enter-congress';
                case 'journey':
                  return 'enter-kj';
              }
            },

            getFormatData() {
              let formattedStartDate = moment(this.startDate).format('Do, MMMM YYYY · h:mm');
              let formattedEndDate = moment(this.endDate).format('h:mm a');
              return formattedStartDate + ' - ' + formattedEndDate; 
            },

            openModal() {
              this.showModal = true;
            },

            closeModal() {
              this.showModal = false;
            },

            registerLogAddToCalendar() {
              this.saveActivity('agenda-add-to-calendar');
            },

            getIcon(document) {
                if (document.multimedia_type_id == 5) {
                    return 'bi bi-broadcast-pin';
                } else if (document.multimedia_type_id == 4) {
                    return 'bi bi-file-play';
                } else if (document.external_link) {
                    return 'bi bi-link';
                } else {
                    return 'bi bi-file-earmark-text';
                }
            },

            getPlainTextDescription(description) {
              let plainTextDescription = '';
              if (this.description) {
                plainTextDescription = description.replace(/<[^>]+>/g, '')
                                                  .replace(/&nbsp;/g, ' ')
                                                  .replace(/&amp;/g, '&');
              }
              return plainTextDescription;
            }
        }
    }
</script>