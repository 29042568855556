<template>
  <div class="connectmi-event__content">
    <div class="connectmi-event__content__helper">
      <h2 class="connectmi-event__content__title title title--xl">Content</h2>
      <div class="connectmi-event__content__wrapper">
        <contentCard
          v-for="(c, key) in content"
          :imagePath="imagePath"
          :key="key"
          :multimediaId="c.id"
          :content="c"
        ></contentCard>
      </div>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import contentCard from './components/ContentCard.vue';

export default {
  props: {
    slug: String,
    imagePath: String,
    content: Array
  },

  components: {
    contentCard
  },

  data() {
    return {
      translator: TranslateService.create()
    };
  },

  mounted() {
  },

  methods: {
    createImagePath(image) {
      return this.imagePath + image;
    },
    t(tag) {
      return this.translator.translate(tag);
    },
  }
};
</script>
