import DateService from "../../../core/services/date.service";
import TranslationService from '../../../core/services/translation.service';

export default {
    get(content) {
        const formatted_dates = this.formatDates(content);
        return {
            id: content?.id ?? '',
            title: content?.title ?? '',
            description: content?.description ?? '',
            slug: content?.slug ?? '',
            hasLiveSessions: content?.hasLiveSessions ?? false,
            image: content?.image ?? '',
            date_start: content?.startDate ?? '',
            date_end: content?.endDate,
            ...formatted_dates,
            type : content?.type ?? "",
            keywords: content?.keywords ?? [],
            shortened_keywords: content.keywords === undefined ? [] : content?.keywords.splice(0, 5),
            is_new: content?.isNew ?? false,
            is_live: content?.hasLiveSessions ?? false,
            is_on_demand: content?.keep_opened_session == 1 ? true : false,
            is_multimedia: false, 
            multimedia_type: content?.multimediaType ? content?.multimediaType.toLowerCase() : "" ,
            multimedia_type_str: content?.multimediaType ? content?.multimediaType.toLowerCase() : "" ,
            saved :  content?.saved ?? false,
            liked :  content?.liked ?? false,
            disliked : content?.disliked ?? false,
            congress_id: content?.id ?? '',
            link: this.getEventLink(content), 
            card_type: content?.card_type ?? "",    
            card_color: content?.card_color ?? "",  
            card_icon: content?.card_icon ?? '',   
            congress_type: content?.congress_type ?? "", 
            pathologies: content?.pathologies ?? [],   
            therapeuticAreas: content?.therapeuticAreas ?? [],   
            from_type: "profilesub" 
        };
    },
    formatDates(content) {
        const date_start = content?.date_start ?? content?.startDate ?? null;
        const date_end = content?.date_end ?? content?.endDate ?? null;

        if(!date_start) {
            return { date_start_formatted: '', date_end_formatted: '', date_formatted: ''};
        }

        const date_start_formatted = this.formatDate(date_start);
        const date_end_formatted = this.formatDate(date_end);
        const date_formatted = this.formatDateEnd(date_start_formatted, date_end_formatted , date_end);

        return {
            date_start_formatted, date_end_formatted, date_formatted
        };
    },
    formatDateEnd(startDate , endDate) {        
        var formattedString = startDate+" - "+endDate
        return formattedString;
    },   
  
    formatDate(date) {
        const parsed = new Date(date);
        const day = parsed.getDate();
        let suffix = 'th';
        switch(day%10){
            case 1: suffix = 'st';break;
            case 2: suffix = 'nd';break;
            case 3: suffix = 'rd';break;
            default: suffix = 'th';break;
        }
        let full_date = new Intl.DateTimeFormat('en-US',{year:'numeric',month:'short'}).formatToParts(parsed).reduce((acc, part) => {
            acc[part.type] = part.value;
            return acc;
        }, {})
        return `${day}${suffix}`+" "+`${full_date.month} ${full_date.year}`;
    },
    getEventLink(event){
        if(event?.card_type == 'Knowledge'){
            return '/kj/'+event.id;
        }else if(event?.type == 'multimedia'){
            return '/'+window.app.env.locale+'/multimedia/'+event.id;
        }else if(event?.type == 'congress'){
            return TranslationService.transRoute('event-detail', {slug: event?.slug ?? ''});
        }else{
            return '';
        }
    },
    getCardType(type){
        if ( type == "Standalone" ) {
            return "general.c-internal-card";
        } else {
            return "general.c-external-card";
        }
    }
};
