<template>
  <section class="custom-template-steering_committee" :id="$parent.$parent.formatHrefAnchorText(configuration.section_wording)">
    <div class="container">
      <div>
        <h2 class="text-center custom-template-steering_committee__head">{{ configuration.section_wording }} </h2>
      </div>
      <div v-if="isLoading">
        {{t('general.loading')}}...
      </div>
    </div>
    <div v-if="!isLoading" class="container">
      <div class="custom-template-steering_committee__carousel row" :class="alignment === 'left' ? 'd-flex flex-row' : (alignment === 'right' ? 'd-flex flex-row-reverse' : 'd-flex flex-row')">
        <div v-if="!isMobile()" :class="[alignment !== 'center' ? 'col-6' : 'col']">
          <V4Carousel :slidesToShow="alignment == 'center' ? 4 : 2" :slidesToScroll="alignment == 'center' ? 4 : 2" :rowsToShow="alignment == 'center' ? 1 : 2" :name="'steering_committee'">
            <template v-slot:items>
              <div v-for="(itemInfo, j) in steeringCommitteeList" v-bind:key="j" class="col-sm-12">
                <div :class="(selectedCard === j && alignment !='center') ? 'selected-card' : ''" style="border: 5px solid rgba(255, 0, 0, 0);">
                  <div class="custom-template-steering_committee__carousel__speaker" :style="isStandardOrStandardEllipse() ? 'border-radius:14px;' : 'border-radius: 20px;'"
                  :class="[alignment === 'center' ? 'custom-template-steering_committee__carousel__speaker__centered' : '']"
                  @click="alignment == 'center' ? showModal(itemInfo) : changeSelectedCard(j, itemInfo)">
                    <SpeakersCardStandard v-if="isStandardOrStandardEllipse()"
                      :cardType="cardType"
                      :itemInfo="itemInfo"
                      >
                    </SpeakersCardStandard>
                    <SpeakersCardImage v-if="isFullImage()"
                      :cardType="cardType"
                      :itemInfo="itemInfo"
                    >
                    </SpeakersCardImage>
                  </div>
                </div>
              </div>
            </template>
          </V4Carousel>
        </div>
        <div v-else>
          <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'steering_committee'" >
            <template v-slot:items>
              <div v-for="(itemInfo, j) in steeringCommitteeList" v-bind:key="j">
                <div class="custom-template-steering_committee__carousel__speaker" @click="alignment == 'center' ? showModal(itemInfo) : null">
                  <SpeakersCardStandard v-if="isStandardOrStandardEllipse()"
                    :cardType="cardType"
                    :itemInfo="itemInfo">
                  </SpeakersCardStandard>
                  <SpeakersCardImage v-if="isFullImage()"
                    :cardType="cardType"
                    :itemInfo="itemInfo">
                  </SpeakersCardImage>
                </div>
              </div>
            </template>
          </V4Carousel>
        </div>
        <div class="col-6" v-if="alignment !== 'center' && singleSteeringCommitteeInfo" :style="!singleSteeringCommitteeInfo.video ? 'padding-top:26px;' : ''">
          <SpeakersDescriptionBox :key="refreshSteeringCommitteeBox"
            :speakerInfo="singleSteeringCommitteeInfo"
            :source="'sc'">
          </SpeakersDescriptionBox>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import SpeakersCardImage from './speakers/SpeakersCardImage.vue';
import SpeakersCardStandard from './speakers/SpeakersCardStandard.vue';
import axios from 'axios';
import V4Carousel from '../carousel/V4Carousel.vue';
import SpeakersDescriptionBox from './speakers/SpeakersDescriptionBox.vue';

export default {
  name: 'SteeringCommitteeSection',
  components: { SpeakersCardImage, SpeakersCardStandard, V4Carousel, SpeakersDescriptionBox },
  data() {
    return {
      translator: TranslateService.create(),
      isLoading: true,
      alignment: this.configuration.configuration.custom_steering_committee_alignment,
      cardType: this.configuration.configuration.custom_steering_committee_card_type,
      steeringCommitteeList: [],
      isMobileDevice: false,
      singleSteeringCommitteeInfo: {},
      refreshSteeringCommitteeBox: 1,
      selectedCard: 0,
    };
  },
  props: {
    configuration: Object,
  },
  mounted() {
    this.getSteeringCommittee();
    window.addEventListener('resize', this.handleResize);
    this.isMobileDevice = this.isMobile();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    showModal(steeringCommittee) {
      document.dispatchEvent(new CustomEvent("showSpeakerModal", { detail: {
        id: steeringCommittee.id,
        name: steeringCommittee.name,
        surname: steeringCommittee.surname,
        institution: steeringCommittee.institution,
        bio: steeringCommittee.bio,
        photo: this.parseImage(steeringCommittee.photo),
        video: steeringCommittee.video
      }}));
    },
    changeSelectedCard(cardId, steeringCommitteeInfo) {
      this.selectedCard = cardId;
      this.refreshSteeringCommittee(steeringCommitteeInfo);
    },
    isStandardOrStandardEllipse(){
      return this.cardType == 'standard' || this.cardType == 'standard_ellipse';
    },
    isFullImage(){
      return this.cardType == 'image' || this.cardType == 'image_black_and_white';
    },
    async getSteeringCommittee() {
      await axios
        .get('/congresses/steering-committee/get-members',
        { params:
            {
              congressId: this.configuration.congress_id
            }
        })
        .then(response => {
          if(response.data && response.data.data) {
            this.fillSteeringCommittee(response.data.data);
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
        });
    },
    fillSteeringCommittee(steeringCommittee){
      this.steeringCommitteeList = Object.values(steeringCommittee).map(steering_committee => this.mapSteeringCommittee(steering_committee));
      this.singleSteeringCommitteeInfo = this.steeringCommitteeList[0];
    },
    mapSteeringCommittee(steering_committee) {
      return {
            id: steering_committee?.id ?? '',
            name: steering_committee?.name ?? '',
            surname: steering_committee?.surname ?? '',
            photo: steering_committee?.photo ?? '',
            position: steering_committee?.position ?? '',
            bio: steering_committee?.bio ?? false,
            short_bio: steering_committee?.short_bio ?? '',
            institution: steering_committee?.institution ?? '',
            video: steering_committee?.video ?? ''
      };
    },
    imageUrlAlt(event) {
      event.target.src = "/images/v4/assets/avatar.svg";
    },
    parseImage(img) {
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    handleResize() {
      this.isMobileDevice = this.isMobile();
    },
    refreshSteeringCommittee(steeringCommittee) {
      this.singleSteeringCommitteeInfo = steeringCommittee;
      this.refreshSteeringCommitteeBox++;
    },
  }
};
</script>
