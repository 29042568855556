<template>
  <div class="speaker_item" @click="openModal()">
    <div class="speaker_item_left">
      <img :src="parseImage(photo)" @error="showDefaultImage($event)" alt="speaker" />
    </div>
    <div class="speaker_item_right">
      <h5>{{ name }} {{ surname }}</h5>
      <p v-html="short_bio"></p>
    </div>

    <ModalSpeaker
      ref="modal"
      class="d-none connectmi-event"
      :imagePath="this.imagePath"
      :image="this.photo"
      :name="this.fullname()"
      :job="this.position"
      :bio="this.setBio()"
    ></ModalSpeaker>

  </div>
</template>

<script>
import ModalSpeaker from '../connectmi2022/modals/ModalSpeaker.vue';
export default {
  name: 'AgendaSpeakersItem',
  props: {
    name: String,
    short_bio: String,
    surname: String,
    position: String,
    photo: String,
    institution: String,
    bio: String,
    slug: String,
    template: Number
  },
  components: {
    ModalSpeaker
  },
  data() {
    return {
      imagePath: '/images/v4/congress/connectmi2022/'
    };
  },
  methods: {
    fullname() {
      return this.name + ' ' + this.surname;
    },
    setBio() {
      if(this.bio){
        return this.bio.replace(/<\/?[^>]+(>|$)/g, '');
      }
      return this.bio;
    },
    parseImage(img) {
      if (img == null) {
        const loc = document.location;
        if (this.template == 7) {
          return `${loc.protocol}//${loc.host}` + this.imagePath + 'empty-avatar.jpg';
        }
        return `${loc.protocol}//${loc.host}/` + 'images/v4/default-user-image.png';
      }
      if (img && img.charAt(0) != '/') {
        img = '/' + img;
      }
      return img;
    },
    showDefaultImage(event) {
      const loc = document.location;
      if (this.template == 7) {
         event.target.src = `${loc.protocol}//${loc.host}` + this.imagePath + 'empty-avatar.jpg';
      }else{
        event.target.src = `${loc.protocol}//${loc.host}/` + 'images/v4/default-user-image.png';
      }
    },
    openModal() {
      if (this.template == 7) {
         this.$refs.modal.show();
      } else {
        document.dispatchEvent(new CustomEvent("showSpeakerModal", { detail: {
          name: this.name,
          surname: this.surname,
          institution: this.institution,
          bio: this.bio,
          photo: this.parseImage(this.photo),
        }}));
      }
    }
  }
};
</script>
