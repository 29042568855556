import SatellitalStorage from './storage';
export default class SatellitalUtils {
  static doAdvancedPasswordCheck(password, repeat_password) {
    return {
      min_char: password.length >= 8,
      lowercase: !!password.match(/(?=.*[a-z])/),
      uppercase: !!password.match(/(?=.*[A-Z])/),
      number: !!password.match(/(?=.*[0-9])/),
      match: password === repeat_password,
    }
  }
  static checkScreenValidity(screen) {
    return screen === 'start' ||
    screen === 'step1' ||
    screen === 'step2' ||
    screen === 'step3' ||
    screen === 'step4' ||
    screen === 'success' ||
    screen === 'login' ||
    screen === 'error';
  }

  static getActiveIdForScreen(screen, noStep3 = false) {
    let activeId = 0;
    switch (screen) {
      case "step1":
        activeId = 1;
        break;
      case "step2":
        activeId = 2;
        break;
      case "step3":
        activeId = 3;
        break;
      case "step4":
        activeId = noStep3 ? 3 : 4;
        break;
      default:
        activeId = 0;
        break;
    }
    return activeId;
  }

  static getParamFromUri(param = "page") {
    const search = document.location.search.substring(1);
    const params = new URLSearchParams(search);
    const theParam = params.get(param);
    if(!!theParam) {
      return theParam;
    }
    return "";
  }

  static getAllKnownParamsFromUri(page) {
    let params = `page=${page}`;
    let action = this.getParamFromUri('action');
    let token = this.getParamFromUri('token');
    if(!!action) params += `&action=${action}`;
    if(!!token) params += `&token=${token}`;
    return params;
  }

  static checkEmail(email) {
    if (email.length === 0) {
      return true;
    }
    // return !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));
    return !(/^\S+@\S+\.\S+$/.test(email));
  }
  static checkIsAmgenEmail(email) {
    if (email.length === 0) {
      return false;
    }
    // return !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));
    return /^\S+@\S+\.\S+$/.test(email) && /@amgen\.com$/i.test(email); // return true if email is valid and is amgen email
  }
  static doBasicPasswordCheck(t, password, repeat_password) {
    if (password.length < 8) {
      return {
        showPasswordErrorWarning: true,
        passwordMessage: t('pre-home.errors.min_char'),
      };
    }
    if(!password.match(/(?=.*[a-z])/)) {
      return {
        showPasswordErrorWarning: true,
        passwordMessage: t('pre-home.errors.lowercase'),
      };
    }
    if(!password.match(/(?=.*[A-Z])/)) {
      return {
        showPasswordErrorWarning: true,
        passwordMessage: t('pre-home.errors.uppercase'),
      };
    }
    if(!password.match(/(?=.*[0-9])/)) {
      return {
        showPasswordErrorWarning: true,
        passwordMessage: t('pre-home.errors.number'),
      };
    }
    if (password !== repeat_password) {
      return {
        showPasswordErrorWarning: true,
        passwordMessage: t('pre-home.errors.match'),
      };
    }
    return {
      showPasswordErrorWarning: false,
      passwordMessage: ""
    };
  }
  static convertBlobToData64(blob) {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          let base64data = reader.result;
          resolve(base64data);
        }
      } catch(e) {
        reject(e);
      }
    });
  }
  static prepareDataForRegistration(slug) {
    const userData = SatellitalStorage.getUserData(slug);
    const professionalData = SatellitalStorage.getProfessionalData(slug);
    const eventData = SatellitalStorage.getEventResponseData(slug);
    const eventTermsData = SatellitalStorage.getEventTermsData(slug);
    const password = SatellitalStorage.getKey(slug, "password") || null;
    const onBehalf = SatellitalStorage.getJSONKey(slug, "onBehalf");
    const isOWA = SatellitalStorage.getJSONKey(slug, "is_owa");
    const isDocCheck = SatellitalStorage.getJSONKey(slug, "is-docCheck");
    const professionalFile = {
      content: SatellitalStorage.getKey(slug, "professional-file") || null,
      name: SatellitalStorage.getKey(slug, "professional-file-name") || null,
    };
    const existingNovalidated = SatellitalStorage.getKey(slug, "existingNovalidated");
    return {...userData, ...professionalData, responses: eventData,
            terms: eventTermsData, password, password_confirmation: password,
            slug, onBehalf, professionalFile, isOWA, isDocCheck, existingNovalidated};
  }
  static prepareDataForValidation(slug, professionalData, isGlobalRegister = false) {
    const userData = SatellitalStorage.getUserData(slug);
    const password = SatellitalStorage.getKey(slug, "password");
    const onBehalf = SatellitalStorage.getJSONKey(slug, "onBehalf");
    const existingNovalidated = SatellitalStorage.getKey(slug, "existingNovalidated");
    return {...userData, ...professionalData, slug, password, onBehalf, isGlobalRegister, existingNovalidated};
  }
}
