<template>
    <section>
        <div class="container-create-password mb-3">
            <div>
                <label for="email" class="text-uppercase container-create-password__label">{{ t('general.user-label') }}
                    *</label>
                <input id="user-email" class="container-create-password__input" v-model="form.email" type="email"
                    name="email" :placeholder="isOnBehalf ? t('pre-home.form.email-on-behalf') : t('pre-home.form.email')" required @keyup="checkForEmailAndPassword"
                    data-testid="email" :readonly="email ? true : false" />
                <div v-show="isAmgenEmail && !isOnBehalf" class="container-create-password__email-error-check mt-3 mb-3">
                    <div class="error-email-step">
                        <span class="error-item">
                            <img v-bind:src="passwordImage.ko"
                                alt="Email Status" />
                        </span>
                        <span class="error-text">{{ t('pre-home.errors.amgen_email_register_text') }}</span>
                        <span class="error-text oktaLink" @click="oktaLogin()"><strong>{{ t('pre-home.errors.amgen_email_okta_link') }}</strong></span>
                    </div>
                </div>
            </div>

            <div v-if="!isOnBehalf">
                <div class="mt-3 container-create-password__password">
                    <label for="password" class="text-uppercase container-create-password__label">{{ t('form.p-label')
                        }} *</label>
                    <input @keyup="checkForEmailAndPassword" v-model="form.password" id="password-user"
                        :type="fieldType.pwdType" class="container-create-password__input" name="password"
                        :placeholder="isCreatePwd ? t('pre-home.create-pwd-label.create') : t('pre-home.recovery-pwd.recover')" />
                    <i id="toggle-password" @click="togglePasswordItem('pwdType')"
                        class="bi bi-eye-fill container-create-password__psw-icon" data-testid="showPassword">
                    </i>
                </div>

                <div class="mt-3 container-create-password__password">
                    <label for="password" class="text-uppercase container-create-password__label">{{
                        t('satellital.repeat-pwd-label') }} *</label>
                    <input @keyup="checkForEmailAndPassword" v-model="form.repeatPassword" id="repeat-password-user"
                        :type="fieldType.repeatPwdType" class="container-create-password__input" name="password"
                        :placeholder="t('pre-home.form.verificate-pwd-label')" autocomplete="off"
                        data-testid="repeatPassword" />
                    <i id="toggle-repeat-password" @click="togglePasswordItem('repeatPwdType')"
                        class="bi bi-eye-fill container-create-password__psw-icon" data-testid="showRepeatPassword">
                    </i>
                </div>
                <div class="container-create-password__password-error-check mt-3 mb-3">
                    <div class="error-password-step">
                        <span class="error-item">
                            <img v-bind:src="passwordCheck.min_char ? passwordImage.ok : passwordImage.ko"
                                alt="Password Status" />
                        </span>
                        <span class="error-text">{{ t('pre-home.errors.min_char') }}</span>
                    </div>
                    <div class="error-password-step">
                        <span class="error-item">
                            <img v-bind:src="passwordCheck.lowercase ? passwordImage.ok : passwordImage.ko"
                                alt="Password Status" />
                        </span>
                        <span class="error-text">{{ t('pre-home.errors.lowercase') }}</span>
                    </div>
                    <div class="error-password-step">
                        <span class="error-item">
                            <img v-bind:src="passwordCheck.uppercase ? passwordImage.ok : passwordImage.ko"
                                alt="Password Status" />
                        </span>
                        <span class="error-text">{{ t('pre-home.errors.uppercase') }}</span>
                    </div>
                    <div class="error-password-step">
                        <span class="error-item">
                            <img v-bind:src="passwordCheck.number ? passwordImage.ok : passwordImage.ko"
                                alt="Password Status" />
                        </span>
                        <span class="error-text">{{ t('pre-home.errors.number') }}</span>
                    </div>
                    <div class="error-password-step">
                        <span class="error-item">
                            <img v-bind:src="passwordCheck.match ? passwordImage.ok : passwordImage.ko"
                                alt="Password Status" />
                        </span>
                        <span class="error-text">{{ t('pre-home.errors.match') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import TranslateService from '../../../core/services/translate.service.js';
import SatellitalUtils from '../../satellital/utils/utils';
import { useEmailPasswordStore } from '../../../store/form.store.js';
import axios from 'axios';

export default {
    setup() {
        const emailPasswordStore = useEmailPasswordStore();
        return {
            emailPasswordStore,
        }
    },
    data() {
        return {
            translator: TranslateService.create(),
            form: {
                email: '',
                password: '',
                repeatPassword: '',
                validPasswordForm: false,
            },
            fieldType: {
                pwdType: 'password',
                repeatPwdType: 'password'
            },
            isAmgenEmail: false,
            passwordCheck: {
                min_char: false,
                lowercase: false,
                uppercase: false,
                number: false,
                match: false
            },
            passwordImage: {
                ko: '/images/v4/assets/x-circle-fill.svg',
                ok: '/images/v4/assets/check-circle-fill.svg'
            },
        }
    },
    props: {
        isOnBehalf: Boolean,
        email: String,
        isCreatePwd: Boolean,
    },
    methods: {
        t(tag) {
            return this.translator.translate(tag);
        },
        passwordChange() {
            const { password, repeatPassword } = this.form;
            this.passwordCheck = SatellitalUtils.doAdvancedPasswordCheck(password, repeatPassword);
            const { min_char, lowercase, uppercase, number, match } = this.passwordCheck;
            return min_char && lowercase && uppercase && number && match;
        },
        checkForEmailAndPassword() {
            this.isAmgenEmail = SatellitalUtils.checkIsAmgenEmail(this.form.email);
            this.form.validPasswordForm = !SatellitalUtils.checkEmail(this.form.email) && !this.isAmgenEmail && this.passwordChange();
            this.emailPasswordStore.setForm(this.form);
        },
        togglePasswordItem(item) {
            if (this.fieldType[item] === 'password') {
                this.fieldType[item] = 'text';
            } else {
                this.fieldType[item] = 'password';
            }
        },
        oktaLogin() {
            axios.get(
                '/login/okta'
            ).then(response => {
                document.location.href = response.data.oktaRedirect;
            });
        },
    },
    mounted() {
        this.form.email = this.email;
    }
}

</script>
