<template>
  <form action="/v4/search" @submit.prevent="onFormSubmit" v-if="dataLoaded">
    <div v-if="!isDomReady">
      {{ t('congresses.loading') }}
    </div>
    <div v-else class="row container" :class="{ 'opacity-0': !isDomReady }" :key="keyToRefresh">
      <div class="col-12">
        <div class="my-4 form-second-row form-second-row-active container">
          <div class="row justify-content-center">
            <div class="col-2 v4-select-container container-name-therapeutic-areas" v-if="therapeuticsOptions.length">
              <FormSelect
                  id="qTherapeuticAreas"
                  :title="t('form.therapeutic_areas')"
                  :defaultOptions="therapeuticsOptions"
                  :multiple="true"
                  :preselectedvalues="filters.qTherapeuticAreas"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container container-name-diseases" v-if="pathologiesOptions.length">
              <FormSelect
                  id="qPathologies"
                  :title="diseasesLabel"
                  :defaultOptions="pathologiesOptions"
                  :multiple="true"
                  :preselectedvalues="filters.qPathologies"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container container-name-content-type" v-if="multimediaOptions.length">
              <FormSelect
                  id="content_type"
                  :title="t('form.content-type')"
                  :defaultOptions="multimediaOptions"
                  :multiple="true"
                  :preselectedvalues="filters.content_type"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container container-name-format" v-if="formatOptions.length">
              <FormSelect 
                  id="format"
                  :title="formatLabel"
                  :defaultOptions="formatOptions"
                  :multiple="true"
                  :preselectedvalues="filters.format"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container container-name-keywords" v-if="keywordsOptions.length">
              <FormSelect
                  id= 'qKeywords'
                  :title="t('home.search.keywords')"
                  :defaultOptions="keywordsOptions"
                  :multiple= "true"
                  :preselectedvalues="filters.qKeywords"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container container-name-dates">
               <DatePicker
                ref="congressDatepicker"
                :preselectedStartDate="filters.qStartDate"
                :preselectedEndDate="filters.qEndDate"
                @datechange="dateChange"
              ></DatePicker>
            </div>
            <div class="col-2 v4-select-container container-name-category" v-if="categoryOptions.length">
              <FormSelect
                  id="qCategories"
                  :title="t('form.category')"
                  :defaultOptions="categoryOptions"
                  :multiple="true"
                  :preselectedvalues="filters.qCategories"
              ></FormSelect>
            </div>
            <div class="col-2 v4-select-container container-name-subcategory" v-if="subcategoryOptions.length">
              <FormSelect
                  id='qSubCategories'
                  :title="t('form.subcategory')"
                  :defaultOptions="subcategoryOptions"
                  :multiple="true"
                  :preselectedvalues="filters.qSubCategories"
              ></FormSelect>
            </div>
          </div>
        </div>
        <div class="pt-3 text-right container d-flex flex-row justify-content-end" style="gap: 10px;">
          <i class="bi bi-trash" style="color: #2DBCB6;"></i>
          <a href="javascript:void(0)" class="v4-action-link" @click="resetFilters()">
            {{ t('new-home.search.delete-filters') }}
          </a>
        </div>

        <div class="d-flex justify-content-center search custom-congress-search">
          <input
            v-model="filters.q"
            type="text"
            name="q"
            class="v4-search-query"
            :placeholder="t('new-home.search.write-in')"
          />
          <button class="ml-3 v4-btn-primary">
            <i class="bi bi-search"></i>
            <p>{{ t('general.search') }}</p>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DatePicker from '../../form/datepicker/DatePicker.vue';
import TranslateService from '../../../core/services/translate.service.js';
import moment from 'moment';
import 'bootstrap-select';
import FormSelect from '../../form/select/FormSelect.vue';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'ContentSearchFields',
  components: { DatePicker, FormSelect },
  props: {
    redirectMode: Boolean,
    congressid: Number
  },
  data() {
    let translator = TranslateService.create();
    let startDate = moment().format();
    let endDate = moment().add(7,'days').format();
    return {
      dataLoaded: false,
      therapeuticsOptions: [],
      pathologiesOptions: [],
      multimediaOptions: [],
      keywordsOptions: [],
      categoryOptions: [],
      dateOptions: [],
      subcategoryOptions: [],
      formatOptions: [],
      dateRange: {startDate , endDate },
      translator,
      diseasesLabel: translator.translate('form.diseases'),
      formatLabel: translator.translate('form.format'),
      isDomReady: false,
      keyToRefresh: 0,
      hasSelectedValue: false,
      defaultFilters: {
        events: false,
        content: true,
        all: false,
        bookmarks: false,
        q: '',
        qTherapeuticAreas: [],
        qPathologies: [],
        format: [],
        content_type: [],
        qKeywords: [],
        date: [],
        qCategories: [],
        qSubCategories: [],
        congress: [],
        qStartDate: null,
        qEndDate: null
      },
      filters: {
        events: false,
        content: true,
        all: false,
        bookmarks: false,
        q: '',
        qTherapeuticAreas: [],
        qPathologies: [],
        format: [],
        content_type: [],
        qKeywords: [],
        date: [],
        qCategories: [],
        qSubCategories: [],
        congress: [],
        qStartDate: null,
        qEndDate: null
      },
      pagination: {
        page: 0,
        size: 6,
      }
    };
  },
  mounted() {
    this.getContentsAndFilters(0, true);
    
    document.addEventListener("changeselectpicker", (data) => {
      this.setFormSelects(data.detail);
    });

    // this.$root.$on('changeselectpicker', data => {
    //   this.setFormSelects(data);
    // });

    document.addEventListener("SearchFormResultsPageChanged", (paginationData) => {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.getContentsAndFilters(paginationData.detail.currentPage);
    });

    // this.$root.$on('SearchFormResultsPageChanged', paginationData => {
    //   this.$root.$emit('showCustomLoader', true);
    //   this.getContentsAndFilters(paginationData.currentPage);
    // });

    $('.selectpicker').selectpicker('refresh');
    this.addSelectorsListeners();
    this.isDomReady = true;
  },
  methods: {
    onInputChecked(checkbox) {
      this.setFormInputFromDTO(checkbox);
    },
    onSwitchToggle(toggleData) {
      this.setFormInputFromDTO(toggleData);
      this.getContentsAndFilters();
    },
    setFormInputFromDTO(inputData) {
      this.filters[inputData.key] = inputData.value;
    },
    onFormSubmit(ev) {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      this.getContentsAndFilters();
      this.resetPagination();
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    dateChange() {
      this.getContentsAndFilters();
    },
    async getContentsAndFilters(page = 0, initial = false) {
      this.updateFiltersValueFromForm();
      this.updatePagination(page);

      let data = await axios.get('/congresses/content/search-filters', {
        params: {
          congressId: this.congressid,
          filters: JSON.stringify(this.filters),
          pagination: JSON.stringify(this.pagination)
        }
      });

      const newFilters = data.data.newFilters || {};
      const allFiltersEmpty = Object.values(newFilters).every(filter => Array.isArray(filter) && filter.length === 0);
      if (!allFiltersEmpty) {
        this.refreshFiltersValues(newFilters);
      }

      this.showInResultsForm(data.data.multimediasCount, data.data.multimedias);
      if(initial){
        this.checkInitialsViews(data.data.multimediasCount);
      }
      this.dataLoaded = true;
    },
    refreshFiltersValues(newFilters) {
      const subcategory = newFilters.subcategories;
      const formats = newFilters.formats;

      this.therapeuticsOptions = newFilters.therapeuticAreas;
      this.pathologiesOptions = newFilters.diseases;
      this.keywordsOptions = newFilters.keywords;
      this.categoryOptions = newFilters.categories;
      this.multimediaOptions = newFilters.contentTypes;
      this.dateOptions = newFilters.dates;

      this.subcategoryOptions = this.mapSelect(Object.values(subcategory));
      this.formatOptions = this.mapSelect(Object.values(formats));

      this.keyToRefresh++;
      $('.selectpicker').selectpicker('refresh');
    },
    mapSelect(collection) {
      return collection.map(i => {
        return {
          id: i.id,
          name: i.name
        };
      });
    },
    async resetFilters() {
      this.isDomReady = false;
      this.filters = { ...this.defaultFilters };
      $('.selectpicker').val('default');
      $('.selectpicker').selectpicker('refresh');
      this.$refs.congressDatepicker.dateRange = null;
      await this.getContentsAndFilters()
      this.isDomReady = true;
    },
    updateFiltersValueFromForm(){
      if (this.congressid) {
        this.filters.congress = [this.congressid.toString()];
      }
      if (
        this.$refs.congressDatepicker &&
        this.$refs.congressDatepicker.dateRange &&
        this.$refs.congressDatepicker.dateRange.startDate &&
        this.$refs.congressDatepicker.dateRange.endDate
      ) {
        this.filters.qStartDate = moment(this.$refs.congressDatepicker.dateRange.startDate).format('DD/MM/YYYY');
        this.filters.qEndDate = moment(this.$refs.congressDatepicker.dateRange.endDate).format('DD/MM/YYYY');
      }

    },
    updatePagination(page){
      this.pagination['page'] = page;
    },
    showInResultsForm(contentCount, contents){
      const dataForResultsForm = [contents, contentCount];
      document.dispatchEvent(new CustomEvent("searchHomeLoaded", { detail: dataForResultsForm }));
      // this.$root.$emit('searchHomeLoaded', dataForResultsForm);
    },
    resetPagination(){
      document.dispatchEvent(new CustomEvent("resetPagination"));
    },
    checkInitialsViews(multimediaCount){
      if (this.congressid && multimediaCount == 0) {
        $('#congress-content-section').css('display', 'none');
        $('#content').css('display', 'none');
        $('#content_menu').css('display', 'none');
      }
    },
    setFormSelects(selector) {
      this.filters[selector.key] = selector.value;
    },
    updateKeyword(keyword_id) {
      this.filters.keywords = [keyword_id];
      $('#keywords option').removeAttr('selected');
      $('#keywords option[value="' + keyword_id.toString() + '"]').attr('selected', 'selected');
      $('.selectpicker').selectpicker('refresh');
      this.getContentsAndFilters();
    },
    addSelectorsListeners() {
      $('body').on('hidden.bs.select', '.selectpicker', ev => {
        //Only if changed
        if (this.hasSelectedValue) {
          setTimeout(() => {
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
            this.getContentsAndFilters();
            this.hasSelectedValue = false;
          }, 75);
        }
      });
      //Set selected value to false when opening selector
      $('body').on('shown.bs.select', '.selectpicker', ev => {
        this.hasSelectedValue = false;
      });
      //Set selected value to false when selected value
      $('body').on('changed.bs.select', '.selectpicker', ev => {
        this.hasSelectedValue = true;
      });
    }
  }
};
</script>
<style scoped>
.opacity-0 {
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .search {
    height: 120px;
    display: grid !important;
  }
}
</style>
