import LanguageSelect from '../../objects/v4/modules/select-language/LanguageSelect.vue';
import ProfileDropdown from '../../objects/v4/modules/profile-dropdown/ProfileDropdown.vue';
import CustomStaticHeader from '../../objects/v4/modules/events/event-internal/CustomStaticHeader.vue';
import StickyHeader from '../../objects/v4/modules/header/StickyHeader.vue';

const vueComponents = [
    ['LanguageSelect', LanguageSelect],
    ['ProfileDropdown', ProfileDropdown],
    ['CustomStaticHeader', CustomStaticHeader],
    ['StickyHeader', StickyHeader],
]

export default vueComponents