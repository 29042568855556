import RoutesHelper from '../v4/routesHelper';

const ResetPasswordController = {
  route: "{!! route('password.update') !!}",
  form: document.querySelector('#reset-password-form'),
  amgenConnectTries: 0,
  errorType: new Set(),
  errors: {
    empty: '.empty-form-error',
    match: '.password-form-error',
    security: '.password-check-error',
    userNotAllowed: '.user-not-allowed',
    notEmail: '.error-login-email'
  },

  register: {
    leftSideselector: '#left-side-page',
    selector: '#pre-home-register',
    content: '#register-form',
    form: '.pre-home-register-form',
    title: '.register-title',
    moreinfoTitle: '.register-moreinfo-title',
    continueButton: '.continue-register-button',
    previousButton: '.previous-register-button',
    signUpButton: '.signup-register-button',
    email: '#register-email',
    pCreate: '#password-create',
    pVerificate: '#password-verificate',
    steps: {
      first: '.first-step-register',
      second: '.second-step-register',
      newsletter: '.newsletter-step-register'
    },
    inputs: {
      internal: {
        therapeutic: '.therapeutic-group',
        department: '.department-group'
      },
      manual: {
        speciality: '.speciality-group',
        institution: '.institution-group',
        textArea: '.textArea-group',
        file: '.upload-file-group',
        amgenContact: '.amgen-contact-group'
      }
    },
    loginLinkSelector: '.login-link',
    selectorButton: '#signup-account',
    changeSignIn: '.change-sign-in',
    successMessage: '.registered-message',
    successDoccheckMessage: '.registered-doccheck-message',
    errorMessage: '.error-register-message',
    appendedError: '.error-register-message-response',
    newsletter: '#newsletter-check',
    submitNewsletter: '.submit-newsletter-button',
    passwordValidations: {
      minChar: '.password-val-min-char',
      uppercase: '.password-val-uppercase',
      lowercase: '.password-val-lowercase',
      number: '.password-val-number',
      match: '.password-match'
    },
    type: {
      internal: 'internal',
      owa: 'owa',
      doccheck: 'doccheck',
      manual: 'manual',
      healthcare: 'healthcare_list'
    }
  },

  registerListeners() {
    $('body').on('keyup', '#password-reset', ev => {
      this.checkPasswordValidation(ev.target.value);
    });
    $('body').on('keyup', '#password-confirm', ev => {
      this.secondValidationListener();
    });
  },

  checkPasswordValidation(value) {
    this.resetFormError();
    this.checkPasswordRules(value);
    this.checkSecondPwValidation();
    return this.showFormError();
  },

  resetFormError() {
    $(this.register.errorMessage).hide();
    this.errorType.forEach(f => {
      $(f).hide();
    });
  },

  checkPasswordRules(password) {
    const { minChar, uppercase, lowercase, number } = this.register.passwordValidations;

    const hasUppercase = /^(?=.*[A-Z]).*$/.test(password);
    const hasLowercase = /^(?=.*[a-z]).*$/.test(password);
    const hasNumber = /^.*[0-9].*$/.test(password);
    const hasLength = password.length > 7;

    hasLength ? this.validPwInput(minChar) : this.invalidPwInput(minChar);
    hasUppercase ? this.validPwInput(uppercase) : this.invalidPwInput(uppercase);
    hasLowercase ? this.validPwInput(lowercase) : this.invalidPwInput(lowercase);
    hasNumber ? this.validPwInput(number) : this.invalidPwInput(number);

    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const isCorrect = re.exec(password);
    if (isCorrect === null) this.errorType.add(this.errors.security);
  },

  checkSecondPwValidation() {
    const { match } = this.register.passwordValidations;
    const data = this.retrieveFormData(this.register.form);
    const isSamePw = $('#password-reset').val() === $('#password-confirm').val();
    isSamePw ? this.validPwInput(match) : this.invalidPwInput(match);
    if (!isSamePw) {
      this.errorType.add(this.errors.match);
    }
  },

  showFormError() {
    this.errorType.forEach(f => {
      $(f).show();
    });
    this.shouldAbleButton();
  },

  shouldAbleButton() {
    let disabled;
    this.errorType.size ? (disabled = true) : (disabled = false);

    if (disabled) {
      $('.btn-reset-pw').addClass('disabled');
    } else {
      $('.btn-reset-pw').removeClass('disabled');
    }
  },

  validPwInput(selector) {
    $(`${selector} img`).attr('src', '/images/v4/assets/check-circle-fill.svg');
  },

  invalidPwInput(selector) {
    $(`${selector} img`).attr('src', '/images/v4/assets/x-circle-fill.svg');
  },

  retrieveFormData(selector) {
    const arrays = ['therapeuticAreas', 'speciality', 'newsletterPathologies'];
    let data = {};
    const formData = $(selector).serializeArray();
    formData.forEach(input => {
      const { name, value } = input;
      const isArray = arrays.includes(name);
      if (data[name]) {
        data[name] = [...data[name], value];
      } else {
        if (isArray) data[name] = [value];
        else {
          data[name] = value;
        }
      }
    });
    return data;
  },

  secondValidationListener() {
    this.resetFormError();
    this.errorType.delete(this.errors.match);
    this.errorType.delete(this.errors.security);
    this.checkSecondPwValidation();
    this.showFormError();
    this.shouldAbleButton();
  },

  getSlugUrl(route, slug = '') {
    let routesHelper = new RoutesHelper();
    return routesHelper.getRoute(route, slug, '%slug%');
  },

  init() {
    this.registerListeners();
  }
};

export default ResetPasswordController;
