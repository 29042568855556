<template>
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="24" 
      height="24" 
      fill="none"
      :style="{ fill: color }"
    >
      <mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" style="mask-type:alpha">
        <path fill="#D9D9D9" d="M0 0h24v24H0z" />
      </mask>
      <g mask="url(#a)">
        <path 
          :fill="color"
          d="M15.25 5c-.35 0-.646-.12-.887-.362A1.207 1.207 0 0 1 14 3.75c0-.35.12-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888s-.12.646-.363.888A1.207 1.207 0 0 1 15.25 5Zm0 16.5c-.35 0-.646-.12-.887-.363A1.207 1.207 0 0 1 14 20.25c0-.35.12-.646.363-.887.241-.242.537-.363.887-.363s.646.12.887.363c.242.241.363.537.363.887s-.12.646-.363.887a1.207 1.207 0 0 1-.887.363Zm4-13c-.35 0-.646-.12-.887-.363A1.207 1.207 0 0 1 18 7.25c0-.35.12-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888s-.12.646-.363.887a1.207 1.207 0 0 1-.887.363Zm0 9.5c-.35 0-.646-.12-.887-.363A1.207 1.207 0 0 1 18 16.75c0-.35.12-.646.363-.887.241-.242.537-.363.887-.363s.646.12.887.363c.242.241.363.537.363.887s-.12.646-.363.887a1.207 1.207 0 0 1-.887.363Zm1.5-4.75c-.35 0-.646-.12-.887-.363A1.207 1.207 0 0 1 19.5 12c0-.35.12-.646.363-.887.241-.242.537-.363.887-.363s.646.12.887.363c.242.241.363.537.363.887s-.12.646-.363.887a1.207 1.207 0 0 1-.887.363ZM12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2v2c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20v2Zm0-8c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 10 12c0-.083.004-.17.012-.262a.929.929 0 0 1 .063-.263L8 9.4 9.4 8l2.075 2.075c.067-.017.242-.042.525-.075.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0 1 12 14Z" 
        />
      </g>
    </svg>
  </template>
  
  <script>
  export default {
    props: {
      color: {
        type: String,
        default: "#27D28B", 
      },
    },
  };
  </script>