import { defineStore } from "pinia"

export const useCreatePwd = defineStore('createPwdStore', {
    state: () => ({
        currentSlug: null,
        currentPwdRecovery: 0,
        currentEmail: null,
        showCreatePwd: false,
        sendRecoverySubmit: false,
    }),
    actions: {
        toggleCreatePwd(slug, pwdRecovery) {
            this.currentSlug = slug;
            this.currentPwdRecovery = pwdRecovery;
            this.showCreatePwd = !this.showCreatePwd
        },
        toggleRecoverySubmit(slug, email) {
            this.currentSlug = slug;
            this.currentEmail = email;
            this.sendRecoverySubmit = !this.sendRecoverySubmit;
        }
    },
})

export const useTermModal = defineStore('createTermModal', {
    state: () => ({
        title: '',
        text: '',

    }),
    actions: {
        setTermModal(title, text) {
            this.title = title;
            this.text = text;
        },

    },
})

export const useLeaveMMPModal = defineStore('leaveMMPModal', {
    state: () => ({
        showLeaveModal: false,
        leaveUrl: '',
    }),
    actions: {
        toggleLeaveModal(url) {
            this.leaveUrl = url;
            this.showLeaveModal = !this.showLeaveModal;
        }
    },
})