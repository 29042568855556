<template>
  <section>
    <div class="register-form-step-container" v-if="!displayManualForm">
      <div>
        <div
          v-if="
            selectedCountryData.show_doc_check === 1 ||
            selectedCountryData.show_owa === 1
          "
        >
          <span
            v-if="registerType === 'manual'"
            class="register-form-step-container__chooose-way"
          >
            {{ t("pre-home.choose_way") }}
          </span>
          <div
            v-if="selectedCountryData.show_owa === 1"
            class="register-form-step-container__owa"
          >
            <button
              v-if="registerType === 'manual'"
              class="v4-btn-primary mb-2 mt-2 register-form-step-container__button"
              @click="onOwa()"
            >
              {{ t("pre-home.register.owa") }}
            </button>
            <div
              class="owa-login-section pre-home-register-form register-event-form__form my-4 p-4"
              v-if="registerType === 'owa'"
            >
              <div class="register-form-step-container__back">
                <a @click="undoOwaDockCheck()" class="text-center">{{
                  t("satellital.step1.go-back-to-register-type-selection")
                }}</a>
              </div>
              <div class="email-group register-form-step-container__owa__email">
                <label
                  for="email"
                  class="text-uppercase register-form-step-container__owa__label"
                  >{{ t("general.user-label") }}</label
                >
                <input
                  id="user-email"
                  class="register-form-step-container__owa__input"
                  v-model="owaForm.email"
                  type="email"
                  name="email"
                  :placeholder="t('pre-home.form.email')"
                  required
                  data-testid="email-owa"
                />
                <div
                  class="error-login-email form-error mt-2"
                  style="display: none"
                >
                  {{ t("pre-home.errors.not_email") }}
                </div>
              </div>
              <div
                class="password-group mt-3 register-form-step-container__owa__password"
              >
                <label
                  for="password"
                  class="text-uppercase register-form-step-container__owa__label"
                  >{{ t("form.p-label") }}</label
                >
                <input
                  v-model="owaForm.password"
                  id="password-user"
                  :type="userPasswordFieldType"
                  class="register-form-step-container__owa__input"
                  name="password"
                  :placeholder="t('pre-home.form.create-pwd-label')"
                  autocomplete="off"
                  data-testid="password-owa"
                />
                <i
                  id="owa-show-password"
                  @click="toggleUserPasswordFieldType"
                  class="bi bi-eye-fill register-form-step-container__owa__psw-icon"
                  data-testid="showOwaPassword"
                >
                </i>
              </div>
              <div class="register-form-step-container__back text-right">
                <a
                  href="https://www.owa-secure.com/sso/login"
                  target="_blank"
                  >{{ t("pre-home.login.hook") }}</a
                >
              </div>
              <div
                class="register-form-step-container__owa__error"
                v-if="!!owaPasswordMessage && owaPasswordMessage.length > 0"
              >
                {{ owaPasswordMessage }}
              </div>
              <div class="text-right mt-5 register-submit-section">
                <button
                  type="submit"
                  class="v4-btn-primary register-form-step-container__button"
                  @click="doOwaLogin"
                  data-testid="submitButton"
                >
                  {{ t("pre-home.register.owa_button") }}
                </button>
              </div>
            </div>
            <div class="satellital-separator mb-2"></div>
          </div>
          <div v-if="selectedCountryData.show_doc_check === 1">
            <button
              v-if="registerType === 'manual'"
              class="v4-btn-primary register-form-step-container__button"
              @click="onDocCheck()"
            >
              {{ t("pre-home.register.doccheck") }}
            </button>
            <div class="doccheck-iframe" v-if="registerType === 'doccheck'">
              <div class="register-form-step-container__back">
                <a @click="undoOwaDockCheck()" class="text-center">{{
                  t("satellital.step1.go-back-to-register-type-selection")
                }}</a>
              </div>
              <iframe
                class="mx-auto d-block mt-4"
                frameborder="0"
                scrolling="no"
                width="311"
                height="188"
                name="dc_login_iframe"
                id="dc_login_iframe"
                :src="docCheckUrl"
                title=""
              >
                <a :href="docCheckUrl" target="_blank">{{
                  t("general.u_login")
                }}</a>
              </iframe>
            </div>
          </div>
          <div v-if="registerType === 'manual'" class="mb-2 mt-2 register-form-step-container__or">
            {{ t("pre-home.or") }}
          </div>
        </div>
        <div v-if="registerType === 'manual'">
          <button
            class="v4-btn-secondary register-form-step-container__manual-button"
            @click="onEmail()"
          >
            {{ t("pre-home.register.manual") }}&nbsp;<span
              class="sat-button-icon"
              ><em class="bi bi-arrow-right"></em
            ></span>
          </button>
        </div>
        <div
          class="register-form-step-container__back"
          v-if="registerType === 'manual'"
        >
          <a @click="backStep()" class="text-center">{{
            t("satellital.back")
          }}</a>
        </div>
      </div>
    </div>

    <div v-if="displayManualForm">
      <RegisterEventForm
        :email="email"
        :slug="slug"
        :congress="congress"
        :countryId="countryId"
        :registerType="registerType"
        :owaForm="owaForm"
        :isOnBehalf="isOnBehalf"
        @back-step="backStep"
        @next-step="nextStep"
      >
      </RegisterEventForm>
    </div>
  </section>
</template>

<script>
import TranslateService from "../../core/services/translate.service.js";
import PageStayController from "../../page-stay/PageStayController.js";
import RegisterEventForm from "./components/RegisterEventForm.vue";
import ActivityService from "../../core/services/activity.service";
import axios from 'axios';
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
  components: {
    RegisterEventForm,
  },
  props: {
    slug: String,
    congress: {
      type: Object,
      default: null,
    },
    email: String,
    countryId: Number,
    selectedCountryData: Object,
    isOnBehalf: Boolean,
  },
  data() {
    return {
      translator: TranslateService.create(),
      activitySvc: ActivityService.create(),
      displayManualForm: false,
      docCheckUrl: window.docCheckUri,
      userPasswordFieldType: "password",
      registerType: "manual",
      owaForm: {
        email: "",
        password: "",
      },
      owaPasswordMessage: "",
      CONSTANTS: {
        DOCCHECK: {
          NO_DOCCHECK: "0",
          CORRECT: "1",
          USER_ERROR: "2",
          G_ERROR: "3",
        },
        OWA: {
          NO_EXISTS: 0,
          EXISTS: 1,
          ALREADY_LOGGED: 2,
        },
      },
    };
  },
  mounted() {
    document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
    this.checkIfShowForm();
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    saveActivity(activityType) {
      this.activitySvc.saveActivity(activityType, {
        model_type: "country",
        model_id: this.countryId,
        congress_id: this.congress ? this.congress.id : null,
      });
    },
    onDocCheck() {
      this.saveActivity("go-to-doccheck");
      GoogleAnalytics.sendRegisterEvent();
      this.registerType = "doccheck";
    },
    onOwa() {
      this.saveActivity("go-to-owa");
      this.registerType = "owa";
    },
    undoOwaDockCheck() {
      this.registerType = "manual";
    },
    togglePasswordItem(item) {
      if (this[item] === "password") {
        this[item] = "text";
      } else {
        this[item] = "password";
      }
    },
    toggleUserPasswordFieldType() {
      this.togglePasswordItem("userPasswordFieldType");
    },
    doOwaLogin(e) {
      e.preventDefault();
      e.stopPropagation();
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      const data = this.owaForm;
      axios
        .post("/check-owa", data)
        .then((response) => {
          const { NO_EXISTS, EXISTS, ALREADY_LOGGED } = this.CONSTANTS.OWA;
          document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
          switch (response.data.owa) {
            case NO_EXISTS:
              this.owaPasswordMessage = this.t(
                "pre-home.modal.owa_error_title"
              );
              console.info("NO_EXISTS");
              break;
            case EXISTS:
              console.info("EXISTS");
              GoogleAnalytics.sendRegisterEvent();
              this.displayManualForm = true;
              break;
            case ALREADY_LOGGED:
              this.owaPasswordMessage = this.t(
                "pre-home.modal.owa_repeated_title"
              );
              console.info("ALREADY_LOGGED");
              break;
          }
        })
        .catch((err) => {
          document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
          this.owaPasswordMessage = this.t("pre-home.modal.owa_error_title");
          console.warn(err);
        });
    },
    checkIfShowForm() {
      if (this.email || (this.slug == null && this.countryId)) {
        this.saveActivity("go-to-manual");
        this.displayManualForm = true;
      }
    },
    onEmail() {
      this.saveActivity("go-to-manual");
      this.displayManualForm = true;
    },
    backStep() {
      window.location.href.includes('country') ? window.location.href = '/' : this.$emit('back-step');
    },
    nextStep() {
      this.$emit("next-step", 3);
    },
  },
};
</script>
