<template>
<div>
    <div class="v4-pagination custom-congress-pagination">
        <ul>
            <svg v-if="currentPage > 1" @click="pageClicked(currentPage - 1)" style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" width="45" height="45" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
            </svg>
            <!-- <li v-if="currentPage > 1" @click="pageClicked(currentPage - 1)">
                &lt;
            </li> -->

            <template v-if="totalPages < (2*maxVisiblePages)">
              <li v-for="index in totalPages" :class="{'active': index === currentPage}" :data-page="index" :key="index" @click="pageClicked(index)">
                  {{ index }}
              </li>
            </template>
            <template v-if="totalPages >= (2*maxVisiblePages)">
              <template v-for="index in totalPages">
                <li :class="{'active': index === currentPage}" :data-page="index" :key="index" @click="pageClicked(index)" v-if="getConditionsForPage(index)">
                  {{ index }}
                </li>
              </template>
            </template>

            <li v-for="page in visiblePages" v-bind:key="page" @click="pageClicked(page)" :class="{active: page === currentPage}">
                {{ page }}
            </li>

            <!-- <li v-if="currentPage < totalPages" @click="pageClicked(currentPage + 1)">
                &gt;
            </li> -->
            <svg v-if="currentPage < totalPages" @click="pageClicked(currentPage + 1)" xmlns="http://www.w3.org/2000/svg" width="45" height="45" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
            </svg>
        </ul>
    </div>
</div>
</template>

<script>
export default {
    props: {
        current: Number,
        totalItems: Number,
        maxResultsPerPage: Number,
    },
    mounted() {
      // this.$root.$on('resetPagination', () => {
      //     this.currentPage = 1;
      // });
      
      document.addEventListener("resetPagination", () => {
        this.currentPage = 1;
      });
    },
    data() {
        return {
            currentPage: this.current ?? 1,
            maxVisiblePages: 2,
            totalPages: Math.ceil(this.totalItems / this.maxResultsPerPage),
            visiblePages: []
        }
    },

    watch: {
      "totalItems": function(val, oldVal) {
        this.totalPages = Math.ceil(val / this.maxResultsPerPage);
      }
    },

    methods: {
        getConditionsForPage(index) {
          let max = this.maxVisiblePages;
          const startDistance = this.currentPage - 1;
          const endDistance = this.totalPages - this.currentPage;
          const diff = this.currentPage - index;
          if (startDistance < this.maxVisiblePages) {
            max = (2*max) - Math.abs(startDistance);
          }
          if(endDistance < this.maxVisiblePages) {
            max = (2*max) - Math.abs(endDistance);
          }
          return (Math.abs(diff) <= max);
        },
        pageClicked(num) {
            this.currentPage = num;
            this.$emit('ChangePageWasClicked', {
                currentPage: this.currentPage - 1
            });
        }
    }
}
</script>
<style scoped>
.v4-pagination ul {
    display: flex;
    justify-content: center;
    padding: 0;
}
.v4-pagination li {
    cursor: pointer;
    list-style: none;
    border-radius: 50%;
    border: 2px solid #2DBCB6;
    color: #2DBCB6;
    width: 45px;
    height: 45px;
    text-align: center;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.v4-pagination svg {
  cursor: pointer;
    fill: #2DBCB6;
}
.v4-pagination li.active {
    background-color: #2DBCB6;
    color: white;
}
</style>
