<template>
    <li class="tabs-component-tab-a" :class="symposia.id == activeSymposiaId ? 'is-active' : 'is-inactive'">
        <a :key="symposia.id" :href="`#${symposia.id}`" :data-id="symposia.id" @click="changeTab({ tab: { computedId: symposia.id } })">
            {{ symposia.title }}
        </a>
    </li>
</template>

<script>
import TranslateService from '../../../core/services/translate.service';
export default {
  name: 'SymposiaTabItem',
  components: {
  },
  props: {
    symposia: Object,
    activeSymposiaId: String,
  },
  setup(props) {
    const translator = TranslateService.create();
    const t = (tag) => translator.translate(tag);
    const symposia = props.symposia;
    
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const changeTab = (tab) => {
      document.dispatchEvent(new CustomEvent("changeMenuTab", { detail: tab }));
    }
    
    return {
      t,
      isMobile,
      symposia,
      changeTab,
    };
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>