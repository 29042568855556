<template>
<div>

    <div class="mb-5">
        <h1 class="section-subtitle text-center mb-2">{{t('new-home.events.find-event')}}</h1>

        <EventForm :therapeutics="therapeutics" :content-type="contentType" :page="'events'"></EventForm>
    </div>

    <div>
        <SearchFormResults :size="pageSize"></SearchFormResults>
    </div>

</div>
</template>

<script>
import EventRepository from '../../home-form/repositories/Content.repository';
import TranslateService from '../../../core/services/translate.service';
import PageStayController from '../../../page-stay/PageStayController';
import EventForm from '../event-form/EventForm.vue';
import SearchFormResults from '../../search-form-results/SearchFormResults.vue';

export default {
    components: {
        EventForm,
        SearchFormResults,
    },
    props: {
        therapeutics: Object,
        contentType: Array,
    },
    data() {
        return {
            isLoading: true,
            list: [],
            error: '',
            pageSize: 12,
            defaultForm: {
                events: null,
                content: null,
                all: null,
                'knowledge-journey': null,
                bookmarks: false,
                q: '',
                qTherapeuticAreas: [],
                qPathologies: [],
                format: [],
                content_type: [],
                qKeywords: [],
                date: [],
                page: 0,
                size: this.pageSize
            },
            form: {},
            translator : TranslateService.create()
        }
    },

    mounted() {
        this.initStayPage();
    },

    methods: {
        async get() {
            try {
                this.isLoading = true;
                this.error = '';
                this.form.content_type = this.contentType;

                this.list = await EventRepository.search(this.form).then((promise) => {
                    document.dispatchEvent(new CustomEvent("searchHomeLoaded", { detail: promise }));
                    // this.$root.$emit('searchHomeLoaded', promise)
                });

            } catch (error) {
                this.list = [];
                this.error = "something happened";
            }
        },
        t(tag){
            return this.translator.translate(tag)
        },
        initStayPage() {
            PageStayController.startStayPageTime('event-page', 0);
        },
    }
}
</script>
