<template>
    <section id="people-choice-podium-section" class="py-3">
        <div class="people-choice-podium-section-top">
            <article @click.stop="goToMultimedia(topThreeMultimedias[1])" class="people-choice-podium-section-top__silver" v-if="topThreeMultimedias[1]">
                <img :src="parseImage(topThreeMultimedias[1].image)" @error="imageUrlAlt" alt="">
                <h2>{{ topThreeMultimedias[1].title }}</h2>
                <div class="people-choice-podium-section-top-speakers">
                    <div class="people-choice-podium-section-top-speakers__images">
                        <img
                            v-for="(author, index) in topThreeMultimedias[1].authorsData"
                            :key="index"
                            :src="parseImage(author.photo)"
                            @error="imageUrlAlt"
                        />
                    </div>
                    <span>
                        {{ topThreeMultimedias[1].authorsData ? topThreeMultimedias[1].authorsData.map(author => author.full_name).join(", "): '' }}
                    </span>
                </div>
                <div class="people-choice-podium-section-top-score">
                    <div class="people-choice-podium-section-top-score__trophy" style="background-color: #efefef;">
                        <trophyIconSvg :fill="'#9A9A9A'" :width="30" :height="30" />
                        <span>2</span>
                    </div>
                    <div class="people-choice-podium-section-top-score__result">
                        <span class="people-choice-podium-section-top-score__result__percentage">{{ Math.trunc(topThreeMultimedias[1].people_choice_score)+'%' }}</span>
                        <span class="people-choice-podium-section-top-score__result__text">Voter Share</span>
                    </div>
                </div>
            </article>
            <article @click.stop="goToMultimedia(topThreeMultimedias[0])" class="people-choice-podium-section-top__gold" v-if="topThreeMultimedias[0]">
                <img :src="parseImage(topThreeMultimedias[0].image)" @error="imageUrlAlt" alt="">
                <h2>{{ topThreeMultimedias[0].title }}</h2>
                <div class="people-choice-podium-section-top-speakers">
                    <div class="people-choice-podium-section-top-speakers__images">
                        <img
                            v-for="(author, index) in topThreeMultimedias[0].authorsData"
                            :key="index"
                            :src="parseImage(author.photo)"
                            @error="imageUrlAlt"
                        />
                    </div>
                    <span class="people-choice-podium-section-top-speakers__names">
                        {{ topThreeMultimedias[0].authorsData ? topThreeMultimedias[0].authorsData.map(author => author.full_name).join(", ") : '' }}
                    </span>
                </div>
                <div class="people-choice-podium-section-top-score">
                    <div class="people-choice-podium-section-top-score__trophy" style="background-color: #FFEFA9;">
                        <trophyIconSvg :fill="'#D2AE2B'" :width="30" :height="30" />
                        <span>1</span>
                    </div>
                    <div class="people-choice-podium-section-top-score__result">
                        <span class="people-choice-podium-section-top-score__result__percentage">{{ Math.trunc(topThreeMultimedias[0].people_choice_score)+'%' }}</span>
                        <span class="people-choice-podium-section-top-score__result__text">Voter Share</span>
                    </div>
                </div>
            </article>
            <article @click.stop="goToMultimedia(topThreeMultimedias[2])" class="people-choice-podium-section-top__bronze" v-if="topThreeMultimedias[2]">
                <img :src="parseImage(topThreeMultimedias[2].image)" @error="imageUrlAlt" alt="">
                <h2>{{ topThreeMultimedias[2].title }}</h2>
                <div class="people-choice-podium-section-top-speakers">
                    <div class="people-choice-podium-section-top-speakers__images">
                        <img
                            v-for="(author, index) in topThreeMultimedias[2].authorsData"
                            :key="index"
                            :src="parseImage(author.photo)"
                            @error="imageUrlAlt"
                        />
                    </div>
                    <span>
                        {{ topThreeMultimedias[2].authorsData ? topThreeMultimedias[2].authorsData.map(author => author.full_name).join(", ") : '' }}
                    </span>
                </div>
                <div class="people-choice-podium-section-top-score">
                    <div class="people-choice-podium-section-top-score__trophy" style="background-color: #E2C4A9;">
                        <trophyIconSvg :fill="'#B05D39'" :width="30" :height="30" />
                        <span>3</span>
                    </div>
                    <div class="people-choice-podium-section-top-score__result">
                        <span class="people-choice-podium-section-top-score__result__percentage">{{ Math.trunc(topThreeMultimedias[2].people_choice_score)+'%' }}</span>
                        <span class="people-choice-podium-section-top-score__result__text">Voter Share</span>
                    </div>
                </div>
            </article>
        </div>
        <div class="people-choice-podium-section-table" v-if="restMultimedias.length > 0">
            <div class="table-container">
                <table>
                    <colgroup>
                        <col style="width: 42.5%;">
                        <col style="width: 42.5%;">
                        <col style="width: 15%;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{{ 'Name' }}</th>
                            <th>{{ 'Author/s' }}</th>
                            <th>{{ 'Voter Share' }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(multimedia, index) in restMultimedias" :key="multimedia.id">
                            <td class="title" @click.stop="goToMultimedia(multimedia)">
                                <span style="margin-left: 10px; margin-right: 20px;">{{ index + 4 }}</span>
                                <span>{{ multimedia.title }}</span>
                            </td>
                            <td class="authors">
                                <div>
                                    <img
                                        v-for="(author, index) in multimedia.authorsData"
                                        :key="index"
                                        :src="parseImage(author.photo)"
                                        @error="imageUrlAlt"
                                    />
                                </div>
                                <span>{{ multimedia.authorsData ? multimedia.authorsData.map(author => author.full_name).join(", ") : '' }}</span>
                            </td>
                            <td class="score">{{ Number(multimedia.people_choice_score.toFixed(1)) + '%'  }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import { ref, computed } from "vue";
import TranslateService from "../../../core/services/translate.service";
import trophyIconSvg from "./people-choice-content-partials/trophyIconSvg.vue";
import GoogleAnalytics from "../../../GoogleAnalytics";

export default {
    name: "PeopleChoicePodiumSection",
    components: { trophyIconSvg },
    props: {
        configuration: Object,
        multimedias: Array,
    },
    setup(props) {
        const translator = TranslateService.create();
        const t = (tag) => translator.translate(tag);

        const isLoading = ref(true);
        const configuration = props.configuration;

        const sortedMultimedias = computed(() => {
            return [...props.multimedias].sort((a, b) => b.people_choice_score - a.people_choice_score);
        });

        const topThreeMultimedias = computed(() => sortedMultimedias.value.slice(0, 3));
        const restMultimedias = computed(() => sortedMultimedias.value.slice(3));

        const isMobile = () => {
            try {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                );
            } catch (error) {
                console.error("Error determining if the device is mobile:", error);
                return false;
            }
        };

        const parseImage = (img) => {
            if (img && img.charAt(0) != '/') {
                img = '/' + img;
            }
            
            return img;
        };

        const imageUrlAlt = (event) => {
            event.target.src = "/images/v4/assets/avatar.svg";
        };

        const goToMultimedia = (multimedia) => {
            GoogleAnalytics.sendOpenContentEvent(multimedia.title, multimedia.type);
            if (multimedia.type !== 'agenda_session' && multimedia.type !== 'agenda') {
                if (multimedia.external_redirect) {
                    document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: multimedia.external_url }));       
                } else {
                    return window.open(multimedia.url, '_blank').focus();
                }
            } else {
                if (multimedia.cards_behaviour == 'external link') {
                    document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: multimedia.external_link }));   
                } else if (multimedia.cards_behaviour == 'open modal') {
                    let agendaURL = '';
                if ((multimedia.is_live && multimedia.is_accessible && !multimedia.external_congress)
                    || (multimedia.is_on_demand  && multimedia.is_accessible && !multimedia.external_congress && multimedia.external_link != '')) {
                    if(multimedia.meeting_platform == 'ZOOM' && window.app.env.ENABLE_LIVE){
                        agendaURL = window.app.env.LIVE_URL+'/zoom/'+multimedia.slug+'/'+multimedia.id+'?encryptsKey=live-encrypts'+window.user.id;
                    }else if(multimedia.meeting_platform == 'WEBEX' && window.app.env.ENABLE_LIVE){
                            agendaURL = window.app.env.LIVE_URL+'/webex/'+multimedia.slug+'/'+multimedia.id+'?encryptsKey=live-encrypts'+window.user.id;
                    }else{
                        agendaURL = TranslationService.transRoute('event-detail-live', {
                            slug: multimedia.slug,
                            agendaSessionId: multimedia.id
                        });
                    }
                } else {
                    agendaURL = TranslationService.transRoute('event-agenda-detail', {
                        slug: multimedia.slug,
                        agendaSessionId: multimedia.id
                    });
                }
                    return window.open(agendaURL, '_blank').focus();
                }
            }
        }

        return {
            t,
            isMobile,
            isLoading,
            parseImage,
            imageUrlAlt,
            configuration,
            topThreeMultimedias,
            restMultimedias,
            goToMultimedia,
        };
    },
};
</script>