<template>
  <ModalComponent v-show="showModal" @close="close" id="recovery-password-modal">
    <template v-slot:header>&nbsp;</template>
    <template v-slot:body>
      <div class="v-modal" id="recover-password-modal" style="top: 30%;">
        <div class="v-modal-message">
          <span class="v-close-modal" @click="close()"><i class="bi bi-x-lg"></i></span>
          <div class="v-modal-header text-center mt-3">
            <p class="v-modal-headerttitle" style="font-size: 2rem; font-weight: bold;">
              {{ t('pre-home.recovery-pwd.recover') }}
            </p>
            <p v-if="createPwd" class="v-modal-headerttitle" style="font-size: 2rem; font-weight: bold;">
              {{ t('pre-home.create-pwd.create') }}
            </p>
            <p v-if="!createPwd" class="v-modal-headertsubtitle text-bold">{{ t('pre-home.recovery-pwd.fill-email')
              }}</p>
            <p v-if="createPwd" class="v-modal-headertsubtitle text-bold">{{ t('pre-home.create-pwd-label.fill-email')
              }}</p>
          </div>
          <div class="v-modal-body">
            <div class="content-modal">
              <div id="forgot-password-page">
                <div id="forgot-password-form" v-if="showForm">
                  <div class="auth-container">
                    <div class="auth-form mt-3">
                      <div class="auth-alert-success" v-if="authAlertSuccess" role="alert"><br /><br /></div>
                      <form class="form" id="password-email-form" @submit.prevent="recoverySubmit()">
                        <div class="row justify-content-center">
                          <div class="col-6">
                            <div class="input-group form-input">
                              <label for="email" class="text-uppercase text-bold">{{ t('form.email_input') }}</label>
                              <input id="recoveryEmail" v-model="email" type="email"
                                class="pre-home-form-input @error('email') is-invalid @enderror" name="email" required
                                autocomplete="off" autofocus :placeholder="t('pre-home.fields.email')" />
                              <div class="error-login-email form-error mt-2" style="display: none;">
                                {{ t('pre-home.errors.not_email') }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-12">
                            <span class="validate-error" style="display:none;" role="alert">
                              <strong>{{ t('pre-home.recovery-pwd.not-found') }}</strong>
                            </span>
                          </div>
                        </div>
                        <div class="form-group mb-0 mt-5 text-right">
                          <button type="submit" @submit.prevent="resetPassword()"
                            class="v4-btn-primary recovery-button-submit">
                            {{ t('pre-home.recovery-pwd.recover') }}
                          </button>
                          <button v-if="createPwd" type="submit" @submit.prevent="resetPassword()"
                            class="v4-btn-primary recovery-button-submit">
                            {{ t('pre-home.create-pwd-label.create') }}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div id="auth-message-container" class="px-3 mt-3" v-html="message"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="error-recovery-message" v-if="errorMessage != ''">
          <p class="error-recovery-message-response validate-error" style="color:red;" v-html="errorMessage"></p>
        </div>
      </div>
    </template>
    <template v-slot:footer>&nbsp;</template>
  </ModalComponent>
</template>
<script>
import TranslateService from '../../core/services/translate.service';
import ModalComponent from '../internal-congress/ModalComponent.vue';
import ChatbotController from '../../../v4/ChatBotController';
import TranslationService from '../../core/services/translation.service';
import ActivityService from '../../core/services/activity.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import Utils from '../../../v4/Utils';
import { ref, watch, getCurrentInstance, reactive } from 'vue';
import { useCreatePwd } from '../../store/modal.store.js';
import axios from 'axios';

export default {
  setup(props) {
    const createPwdStore = useCreatePwd();

    const slug = ref('');
    const congressRecover = ref(false);
    const createPwd = ref(0);
    const showModal = ref(props.show ?? false);
    const email = ref('');

    const instance = getCurrentInstance();

    const recoveryPassword = reactive({
      selector: '#forgot-password',
      form: '#forgot-password-form',
      formData: '#password-email-form',
      container: 'div.auth-container #auth-message-container',
      errorMessage: '.error-recovery-message',
      appendedError: '.error-recovery-message-response',
      formContainer: '#forgot-password-form .auth-form',
      submitButton: '.recovery-button-submit',
      hide: '#recovery-go-login',
      messageContainer: '.recovery-password-message',
      emailInput: '#recoveryEmail',
    });

    const handleRecovery = (slugValue, createPwdValue) => {
      if (slugValue && slugValue !== '') {
        slug.value = slugValue;
        congressRecover.value = true;
      }
      createPwd.value = createPwdValue;
    };

    watch(
      () => createPwdStore.showCreatePwd,
      (newShowCreatePwd) => {
        if (newShowCreatePwd) {
          handleRecovery(createPwdStore.currentSlug, createPwdStore.currentPwdRecovery);
          showModal.value = newShowCreatePwd;
        }
      }
    );

    watch(
      () => createPwdStore.sendRecoverySubmit,
      (newSendRecoverySubmit) => {
        if (newSendRecoverySubmit) {
          handleRecovery(createPwdStore.currentSlug, createPwdStore.currentPwdRecovery);
          if (createPwdStore.currentEmail && createPwdStore.currentEmail !== '') {
            email.value = createPwdStore.currentEmail;
            document.querySelector(recoveryPassword.emailInput).value = email.value;
            instance.proxy.recoverySubmit();
          } else {
            showModal.value = true;
          }
          GoogleAnalytics.sendOpenContentEvent(this.t('pre-home.recovery-pwd.title'), 'link');
        }
      }
    );
    return {
      createPwdStore,
      slug,
      congressRecover,
      createPwd,
      showModal,
      email,
      recoveryPassword,
    };
  },
  props: {
    show: Boolean,
    isSatellital: Boolean,
  },
  components: {
    ModalComponent
  },
  data() {
    return {
      showForm: true,
      authAlertSuccess: false,
      message: '',
      errorMessage: '',
      translator: TranslateService.create(),
      activitySvc: ActivityService.create(),
    };
  },
  methods: {
    recoverySubmit() {
      let data = {};
      const formElement = document.querySelector(this.recoveryPassword.formData);
      const formData = new FormData(formElement);
      const formProps = Object.fromEntries(formData.entries());
      data = { ...data, ...formProps };
      let url = '/password/email';

      const errorElement = document.querySelector(this.recoveryPassword.error);
      if (errorElement) {
        errorElement.style.display = 'none';
      }
      const appendedErrorElement = document.querySelector(this.recoveryPassword.appendedError);
      if (appendedErrorElement) {
        appendedErrorElement.innerHTML = '';
      }
      if (this.isSatellital || this.congressRecover) {
        const loc = document.location;
        data.redirect_url = `${loc.protocol}//${loc.host}${TranslationService.transRoute('event-detail', {
          slug: this.slug
        })}/register`;
      }

      data.create_password = this.createPwd;
      if (this.slug && this.slug != '') {
        data.slug = this.slug;
      }
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      axios
        .post(url, data)
        .then(res => {
          this.redirectOkta(res.data);
          this.savePasswordActivity();
          this.showForm = false;
          this.message = res.data.message;
          this.authAlertSuccess = true;
          document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
          this.showModal = true;
        })
        .catch(error => {
          const { data } = error.response;
          this.catchError(error);
          if (data.status === 400) {
            this.showForm = false;
            Object.keys(data).forEach(e => data[e]);
            this.message = data.message;
            this.authAlertSuccess = true;
            document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
            this.showModal = true;
          } else {
            if (data.errors) {
              let errors = '';
              Object.keys(data.errors).forEach(e => {
                errors += `<p>${data.errors[e][0]}</p>`;
              });
              this.errorMessage = errors;
            }
          }
          document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
        });
    },
    savePasswordActivity() {
      let activityType = 'reset-password';
      GoogleAnalytics.sendForgotPasswordEvent();
      this.activitySvc.saveActivity(activityType, {
        model_type: 'user',
        email: this.email,
      });
    },
    redirectOkta(data) {
      if (data.oktaRedirect) {
        document.location.href = Utils.isValidUrl(Utils.escapeHtml(data.oktaRedirect));
      }
    },
    catchError(error) {
      console.debug('ERR', error);
      ChatbotController.triggerOpening('error');
    },
    close() {
      this.showModal = false;
      GoogleAnalytics.sendCloseContentEvent(this.t('pre-home.recovery-pwd.title'), 'modal');
      this.createPwdStore.showCreatePwd = false;
    },

    t(tag) {
      return this.translator.translate(tag);
    }
  }
};
</script>
