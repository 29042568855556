<template>
  <div class="v4-detailed-card--progress" :class="panel ? 'v4-detailed-card--progress--white' : ''">
    <p class="v4-detailed-card--progress--title">{{ t("clinical-case.card.your_progress") }}</p>

    <div class="v4-detailed-card--progress--bar">
      <span class="v4-detailed-card--progress--completed" :style="{ width: progress + '%' }"></span>
    </div>

    <p class="v4-detailed-card--progress--percent-info">{{ progress }}%</p>
  </div>
</template>

<script>
import TranslateService from "../../core/services/translate.service";

export default {
  name: "ClinicalCasesProgress",
  props: {
    progress: Number,
    panel: Boolean,
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
  },
  data() {
    return {
      translator: TranslateService.create(),
    };
  },
};
</script>
