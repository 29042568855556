<template>
  <div class="mmp-posts-tec">
    <h2>{{ t('live.moderator_message_title') }}</h2>
    <p v-if="moderatorPosts.length === 0">{{ t('live.moderator_no_messages') }}</p>
    <ul class="scroll-list" id="post_listings" v-else>
      <li v-for="(item, i) in orderedMessages" :key="i">
        <label>{{ item.created_time }}</label>
        <p v-html="item.message"></p>
      </li>
    </ul>
  </div>
</template>
<script>
import moment from 'moment';
// import eventCallerService from '../../core/services/eventCaller.service';
import TranslateService from '../../core/services/translate.service';
import $ from 'jquery';
import _ from 'lodash';

export default {
  props: {
    moderatormessages: Array
  },
  computed: {
    orderedMessages() {
      return _.orderBy(this.moderatorPosts, 'id', 'desc');
    }
  },
  watch: {
    moderatormessages: function() {
      if (this.moderatormessages && this.moderatormessages.length > 0) {
        this.moderatormessages.forEach(m => {
          this.moderatorPosts.push(m);
        });
      }
    }
  },
  data() {
    return {
      translator: TranslateService.create(),
      moderatorPosts: []
    };
  },
  mounted() {
    eventCallerService.$on('appendModeratorPost', data => {
      if (!this.moderatorPosts.some(mp => mp.id === data?.id)) {
        this.moderatorPosts.push({
          id: data.id,
          message: data.linkmessage,
          created_time: moment().format('HH:mm')
        });
      }
    });
  },
  mounted() {
    document.addEventListener('appendModeratorPost', event => {
      const data = event.detail;
      if (!this.moderatorPosts.some(mp => mp.id === data?.id)) {
        this.moderatorPosts.push({
          id: data.id,
          message: data.linkmessage,
          created_time: moment().format('HH:mm')
        });
      }
    });

  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    }
  }
};
</script>
