<template>
<div>
    <em :class="localLike ? 'bi-hand-thumbs-up-fill' : 'bi bi-hand-thumbs-up ' + 'like-'+ this.id" @click="onLikeBtn($event, 'like')" style="cursor:pointer;"></em>
    <em :class="localDislike ? 'bi-hand-thumbs-down-fill' : 'bi bi-hand-thumbs-down ' + 'dislike-'+this.id" @click="onLikeBtn($event, 'dislike')" style="cursor:pointer;"></em>
    <em :class="localSave ? 'bi-bookmark-fill ':'bi bi-bookmark ' + 'save-'+this.id" @click="onSaveBtn($event)" style="cursor:pointer;"></em>
    <div :class="'share-icons share-icons-'+ this.id" style="display: none;">
        <em href="https://api.whatsapp.com/send?text=" :data-id="this.id" target="_blank" class="bi bi-whatsapp"  @click="shareAction($event)"></em>
        <em :href="'mailto:?subject=Shared content from the Medical Meeting Point&body='" :data-id="this.id" class="bi bi-envelope" @click="shareAction($event)"></em>
    </div>
    <em class="bi bi-share-fill" @click="showShareOptions()" style="cursor:pointer;"></em>
</div>
</template>

<script>
import Translate from '../../../v4/Translations';
import axios from 'axios';
import $ from 'jquery';

export default {
    name: 'ShareActions',
    props: {
        id: Number,
        title: String,
        slug: String,
        type: String,
        isPast: Boolean,
        liked: Boolean,
        disliked: Boolean,
        saved: Boolean
    },
    data() {
        return {
            localLike: this.liked,
            localDislike: this.disliked,
            localSave: this.saved,
        }
    },

    mounted() {
        this.setLike();
        this.isBookMarked();
    },

    methods: {

        onLikeBtn(ev, action) {
            ev.preventDefault();
            ev.stopPropagation();

            const url = '/profile/save-like';
            const fd = new FormData();
            fd.append("model_id", this.$props.id);
            fd.append("model_type", this.$props.type);
            fd.append("like", this.$props.title);
            fd.append("source", '');
            fd.append("congress_id", this.$props.congress_id);

            if (action == 'like') {
                fd.append("liked", true);
            } else {
                fd.append("liked", false);
            }

            // Call save item
            axios.post(url, fd).then((response) => {
                //Grid
                this.checkLike(response.data.liked);
                this.checkDislike(response.data.disliked);
            }).catch((error) => {
                console.error('Error on like method', error);
            });
            GoogleAnalytics.sendContentReactionEvent(this.title, action, this.type);
        },

        checkLike(liked) {
            if (liked) {
                this.localLike = true;
                this.localDislike = false;
            } else {
                this.localLike = false;
            }
        },

        checkDislike(disliked) {
            if (disliked) {
                this.localLike = false;
                this.localDislike = true;
            } else {
                this.localDislike = false;
            }
        },

        setLike() {
            if (this.liked) {
                this.localDislike = false;
                this.localLike = true;

            } else if (this.disliked) {
                this.localLike = false;
                this.localDislike = true;
            }
        },

        onSaveBtn(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            const url = '/profile/save-bookmark';
            const fd = new FormData();
            fd.append("model_id", this.id);
            fd.append("model_type", this.type);
            fd.append("like", this.title);
            fd.append("source", '');
            fd.append("congress_id", this.congress_id);

            // Call save item
            axios.post(url, fd).then((response) => {
                this.checkBookmarks(response.data.bookmarked);
            }).catch((error) => {
                console.error('Error on bookmark method', error);
            });
        },

        checkBookmarks(bookmarked) {
            if (bookmarked) {
                this.localSave = true;
            } else {
                this.localSave = false;
            }
        },

        isBookMarked() {
            if (this.saved) {
                this.localSave = true;
            } else {
                this.localSave = false;
            }
        },

        showShareOptions() {
            let icons = $(`.share-icons-${this.id}`);

            if (icons.is(":visible")) {
                $(icons).hide(200);
                $(icons).fadeOut(200);
            } else {
                $(icons).show(200);
                $(icons).fadeIn(200);
            }
        },

        shareAction(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            const prepareLink = (type, itemId, url) => {
                let link = '';
                if (type == 'multimedia') {
                    const loc = document.location;
                    link = escape(`${loc.protocol}//${loc.host}/${type}/${itemId}`);
                } else if (type == 'congress') {
                    link = url;
                }
                return link;
            };
            const itemId = this.id;
            const type = this.type;
            const title = this.title;
            const congressUrl = this.link;
            const link = prepareLink(type, itemId, congressUrl);
            const Translation = new Translate();
            const checkoutThis = Translation.translate('general', 'check-out');
            let message = `${checkoutThis} ${title}: ${link}`;

            const target = $(ev.currentTarget);
            const href = target.attr('href');
            const url = href + message;
            if (target.attr('target') === '_blank') {
                window.open(url);
            } else {
                window.location.replace(url);
            }
        }
    }
}
</script>
