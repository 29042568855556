<template>
  <div class="v4-detailed-card clinical-case-card custom-congress-details" @mouseleave="toggleExpand(true)">
    <div class="v4-detailed-card--title">
      <p>{{ shortTitle(title) }}</p>
    </div>
    <div class="v4-detailed-card--info">
      <ul class="v4-detailed-card--info--list">
        <li>
          <span><i class="bi bi-clock"></i></span> {{ info.duration_min }} {{ t("clinical-case.presentation.minutes") }}
        </li>

        <li>
          <span><i class="bi bi-globe"></i></span> {{ info.translated_language }}
        </li>
      </ul>
    </div>

    <ClinicalCasesProgress :progress="info.progress" :panel="false"></ClinicalCasesProgress>

    <a @click="openCard(); handlePromoClick(title)" href="javascript:void(0)" class="v4-detailed-card--action">{{
      t("clinical-case.card.view-case")
      }}</a>
  </div>
</template>

<script>
import ActivityService from "../../core/services/activity.service";
import TranslateService from "../../core/services/translate.service";
import ClinicalCasesProgress from "./ClinicalCasesProgress.vue";
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
  props: {
    id: Number,
    title: String,
    info: Object,
    therapeuticAreas: Array,
    keywords: Array,
    progress: Number,
    link: String,
    language: String,
    duration: Number,
  },

  data() {
    return {
      translator: TranslateService.create(),
      activitySvc: ActivityService.create(),
      expanded: false,
    };
  },

  mounted() {},
  computed: {
    shownKeywords() {
      if (this.expanded) {
        return this.keywords;
      } else {
        return this.keywords.slice(0, 4);
      }
    },
  },

  methods: {
    t(tag, params = null) {
      let translation = this.translator.translate(tag);
      if (params) {
        translation = stringInject(translation, params);
      }
      return translation;
    },
    toggleExpand(force = false) {
      if (force) {
        this.expanded = false;
        return;
      }
      this.expanded = !this.expanded;
    },
    openCard() {
      let params = {
        title: this.title,
        model_type: "clinical",
        model_id: this.id,
        congress_id: window.event ? window.event.id : null,
      };
      this.activitySvc.saveActivity("enter-cc", params);
      return window.open(this.link, "_blank").focus();
    },
    shortTitle(item) {
      if (item && item.length > 60) {
        return item.substring(0, 60) + "...";
      } else {
        return item;
      }
    },
    handlePromoClick(title) {
      if (typeof GoogleAnalytics !== 'undefined' && typeof GoogleAnalytics.sendPromoClickEvent === 'function') {
        GoogleAnalytics.sendPromoClickEvent(title);
      } else {
        console.error('GoogleAnalytics.sendPromoClickEvent is not defined');
      }
    },
  },
  components: {
    ClinicalCasesProgress
  }
};
</script>
