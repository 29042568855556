<template>
  <section class="custom-event-welcome" :id="$parent.$parent.formatHrefAnchorText(configuration.section_wording)">
    <div v-if="dataReady" class="container">
      <div class="row">
        <div class="col-12 col-md custom-event-welcome__content d-flex" :class="logoPosition === 'bottom' ? 'flex-column' : 'flex-column-reverse'">
            <div :class="sectionAlignment === 'left' ? 'd-flex flex-row-reverse' : (sectionAlignment === 'right' ? 'd-flex flex-row' : 'center')">
              <div class="col-md-6">
                <img :src="checkImageConditions() ? parseImage(configuration.configuration.custom_welcome_image) : '' " alt="" class="img-fluid">
              </div>
              <div :class="sectionAlignment === 'center' ? 'col-12' : 'col-md-6'">
                <div>
                  <div class="welcome-title-section" :class="'text-' + sectionAlignment" v-if="content.welcome">{{content.welcome}}</div>
                  <div class="welcome-title" :class="'text-' + sectionAlignment" :data-title="content.title" v-if="content.title && !content.hideEventTitle" v-html="content.title"></div>
                  <div id="welcomeDescription" class="welcome-description" :class="(showReadMore && !readmore) ? 'limit-lines' : ''"  v-html="content.description"></div>
                  <div v-if="showReadMore" class="text-center" >
                      <a v-if="!readmore" href="javascript:void(0);" class="welcome-readmore-link" v-on:click="hideShortdesc">{{t("congress.read-more")}}</a>
                      <a v-if="readmore" href="javascript:void(0);" class="welcome-readmore-link" v-on:click="showShortdesc">{{t("congress.read-less")}}</a>
                  </div>

                  <div v-if="configuration.configuration.custom_welcome_button == 'external_link' && checkWelcomeExternalLinkValue()" class="d-flex justify-content-center mt-3">
                    <a class="welcome-button" v-on:click="redirectToExternalUrl()" target="_blank" >
                        <span>{{ configuration.configuration.custom_welcome_button_wording }}</span>
                    </a>
                  </div>
                  <div v-if="configuration.configuration.custom_welcome_button == 'pdf' && checkWelcomeFileValue()" class="d-flex justify-content-center mt-3">
                    <a class="welcome-button" :href="parseLink()"
                        :download="`${configuration.section_wording}.pdf`" @click="trackDownload()">
                        <span class="list-text">{{ configuration.configuration.custom_welcome_button_wording }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div :class="logoPosition === 'bottom' ? 'mb-3 mt-4' : 'my-3'">
              <img v-if="checkHeaderLogo() && configuration.configuration.custom_welcome_logo_size == 'small'" :src="parseImage(this.content.image)" class="text-center welcome__logo small" alt="event-logo"/>
              <img v-if="checkHeaderLogo() && configuration.configuration.custom_welcome_logo_size == 'medium'" :src="parseImage(this.content.image)" class="text-center welcome__logo medium" alt="event-logo"/>
              <img v-if="checkHeaderLogo() && configuration.configuration.custom_welcome_logo_size == 'large'" :src="parseImage(this.content.image)" class="text-center welcome__logo large" alt="event-logo"/>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import PageStayController from  '../../page-stay/PageStayController';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import axios from 'axios';

export default {
  name: 'WelcomeSection',
  data() {
    return {
      content: {},
      dataReady: false,
      translator: TranslateService.create(),
      readmore: false,
      showReadMore: false,
      sectionAlignment: this.getAlignment(), 
      logoPosition: this.getLogoPosition(),
    }
  },
  components: { },
  props: {
    configuration: Object,
  },
  mounted() {
    this.getWelcomeSectionData();
    this.initStayPage();
  },
  updated(){
    this.$nextTick(function () {
      this.logicForReadMore();
    })
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    getNumberLines(){
      const welcomeDescriptionEl = document.getElementById("welcomeDescription");
      const divHeight = welcomeDescriptionEl.offsetHeight;
      const lineHeight = parseInt(getComputedStyle(welcomeDescriptionEl).lineHeight);
      return Math.ceil(divHeight / lineHeight);
    },
    logicForReadMore(){
      const numberLines = this.getNumberLines();
      const read_more_active = this.configuration.configuration.custom_welcome_read_more ?? 0;
      if (numberLines > 6 && read_more_active == 1) {
        this.showReadMore = true;
      }
    },
    translateField(field){
      return this.translator.translateField(field);
    },
    hideShortdesc: function (event) {
      this.readmore = true;
    },
    showShortdesc: function (event) {
      this.readmore = false;
    },
    checkHeaderLogo() {
      return this.content.image && this.content.image != 'unset' && this.content.image != '';
    },
    parseImage(img) {
      if (img && img.substr(0,1) != '/') {
          img = '/' + img;
      }
      return img;
    },
    checkImageConditions(){
      return this.sectionAlignment !== 'center' && this.configuration.configuration.custom_welcome_image;
    },
    checkWelcomeExternalLinkValue(){
      return this.configuration.configuration.custom_welcome_button_external_link;
    },
    redirectToExternalUrl(){
      const externalLink = this.configuration.configuration.custom_welcome_button_external_link;
      document.dispatchEvent(new CustomEvent("leavingMMPModalURL", { detail: externalLink }));   
    },
    checkWelcomeFileValue(){
      let hasValue = true;
      const welcomeFileValue = this.configuration.configuration.custom_welcome_button_file;
      if(!welcomeFileValue){
        hasValue = false;
      }else {
        const translatedValue = this.translateField(welcomeFileValue);
        if(!translatedValue){
          hasValue = false;
        }
      }
      return hasValue;
    },
    parseLink() {
      const welcomeFileValue = this.configuration.configuration.custom_welcome_button_file;
      const translatedLink = this.translateField(welcomeFileValue);
      
      let link = null;
      if (translatedLink && translatedLink.substring(0,1) != '/') {
        link = '/' + translatedLink;
      }
      return link;
    },
    initStayPage() {
      PageStayController.startStayPageTime('congress', this.configuration.congress_id);
    },
    async getWelcomeSectionData() {
      await axios.get(
        '/congresses/welcome/get-data',
        { params:
          {
            congressId: this.configuration.congress_id,
          }
        }
      ).then(response => {
          this.content = response.data;
          this.dataReady = true;
        }
      );
    },
    getAlignment() {
      return this.configuration.configuration.custom_welcome_alignment === 'right' ? 'right' : (this.configuration.configuration.custom_welcome_alignment === 'left' ? 'left' : 'center');
    },
    getLogoPosition() {
      return this.configuration.configuration.custom_welcome_logo_position;
    },
    trackDownload() {
      GoogleAnalytics.sendDownloadContentEvent(this.configuration.section_wording, 'pdf');
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>