import { defineStore } from 'pinia'

export const useWebexStore = defineStore('webex', {
  state: () => ({
    showParticipants: false
  }),
  actions: {
    toggleShowParticipants () {
      this.showParticipants = !this.showParticipants
    }
  }
})
