<template>
<div class="box-padd-new">
    <div class="text-answer">
        <form action="" method="post" @submit="showSubmit" class="modal-questions-form" :data-poll-id="id" :data-poll-type="type" :data-poll-expiration="expiration_time" :id="'quesform_' + id">
            <div class="single-radio-boxes">
                <input type="hidden" name="pollId" class="questiontextid" ref="answer" :value="id">
                <div class="radio" v-for="(optionval,optionans) in options" :key="optionans">
                    <input :id="`radio-${optionval.option}`" :value="optionval.option" name="answer" type="radio"> <label :for="`radio-${optionval.option}`" class="radio-label">{{optionval.option}}</label>
                </div>
            </div>
        </form>
    </div>
</div>
</template>
<script>
import LivePollsController from "../../../v4/modules/live/LivePollsController"
import $ from 'jquery';

export default {
    props: {
        id : Number,
        type: Number,
        question : String,
        expiration_time : Number,
        options : Array,
        expiration_timeout:Number
    },
    data:function(){
        return{
            answer: "",
        }
    },
    mounted() {
        let id = '#quesform_' + this.id;
        $(document).on('submit', id.toString() , ev => this.showSubmit(ev));
    },
    methods: {
        async showSubmit(event){
            event.preventDefault();
            event.stopPropagation();
            if ( this.answer == '' ) {
                LivePollsController.displayToastifyMessage(2 , "Error" , "Answer is required")
            } else {
                var response = await LivePollsController.setAnswerPollFunc(event);
                if ( response.data.status == 201 ) {
                    LivePollsController.displayToastifyMessage(2 , "Error" , response.data.message)
                } else if ( response.data.status == 200 ) {
                    this.popupQuesType()
                }
            }
        },
        popupQuesType(){
            this.$parent.$parent.closePopup(this.id);
        }
    }
}
</script>
