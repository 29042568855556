import { defineStore } from "pinia"

export const useAppStore = defineStore('app', {
  state: () => ({
    showLoader: false,
  }),
  actions: {
    toggleShowParticipants() {
      this.showLoader = !this.showLoader
    }
  }
})
