<template>
  <div class="speakers-satellital four-speakers">
    <div v-for="(item, i) in speakerInfo" :key="i">
      <div v-if="!isMobile()">
        <div class="speaker_hidden" :id="'speaker_common_' + i">
          <V4Carousel :slidesToShow="4" :slidesToScroll="4" :name="'speakers' + i" ref="carousel">
            <template v-slot:items>
              <div v-for="(itemInfo, j) in speakerInfo[i]" :key="j" @click="openModal(itemInfo)">
                <div class="col-sm-12">
                  <div class="speak-user text-center">
                    <div class="speaker-img">
                      <img :src="parseImage(itemInfo.photo)" @error="imageUrlAlt" alt="" />
                    </div>
                    <div class="user-name-speaker">
                      <h4 class="limit-2-rows">{{ itemInfo.name + ' ' + itemInfo.surname }}</h4>
                      <p class="limit-3-rows">{{ getSpeakerItemInfo(itemInfo) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </V4Carousel>
        </div>
      </div>
      <div v-else>
        <div class="speaker_hidden" :id="'speaker_common_' + i">
          <V4Carousel :slidesToShow="1" :slidesToScroll="1" :name="'speakers' + i" ref="carousel">
            <template v-slot:items>
              <div v-for="(itemInfo, j) in speakerInfo[i]" :key="j" @click="openModal(itemInfo)">
                <div class="col-sm-12">
                  <div class="speak-user text-center">
                    <div class="speaker-img">
                      <img :src="parseImage(itemInfo.photo)" @error="imageUrlAlt" alt="" />
                    </div>
                    <div class="user-name-speaker">
                      <h4>{{ itemInfo.name + ' ' + itemInfo.surname }}</h4>
                      <p>{{ itemInfo.position }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </V4Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import V4Carousel from '../../carousel/V4Carousel.vue';
export default {
  name: 'SpeakerCarousel',
  components: { V4Carousel },
  props: {
    tabInfo: Array,
    speakerInfo: Array,
    parseImage: Function,
    imageUrlAlt: Function,
    getSpeakerItemInfo: Function,
    openModal: Function,
    isMobile: Function,
  }
};
</script>