<template>
    <div class="swiper virtual-booth-swiper virtual-slider">
        <div v-if="isLoading">
            {{ t("congresses.loading") }}
        </div>
        <template v-if="!isLoading && list.length > 0">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in list" v-bind:key="item">
                    <div class="image-cube">
                        <div class="virtual-slider-section">
                            <a href="javascript:void(0)" @click="cardRedirect(item)"><img :src="parseImage(item.image)" @error="showDefaultImage($event)" /></a>
                            <div class="virtual-content">
                            <span class="virtual-tag" :style="'background-color:'+item.card_color+';'"><em :class="getIcon(item.multimedia_type_code)"></em> {{item.card_type}}</span>
                            <span class="virtual-title-text" v-html="item.title"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-arrow-prev">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
                </svg>
            </div>
            <div class="swiper-arrow-next">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                </svg>
            </div>
        </template>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import TranslateService from '../../../core/services/translate.service.js';

export default {
    name: 'VirtualBoothMultipleContent',
    components: {},
    props: {
        configuration: Object,
        list: Array,
    },
    setup(props) {
        const translator = TranslateService.create();
        const t = (tag) => translator.translate(tag);

        const isLoading = ref(false);
        const list = props.list;
        const configuration = props.configuration;

        onMounted(() => {
            loadslider();
        });

        const isMobile = () => {
            try {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            } catch (error) {
                console.error('Error determining if the device is mobile:', error);
                return false;
            }
        };

        const loadslider = () => {
            if (list.length > 0) {
                let automatic_loop = false;
                let seconds_for_rotation = 0;
                if (configuration && configuration.configuration) {
                    automatic_loop = configuration.configuration.custom_automatic_rotation;
                    seconds_for_rotation = configuration.configuration.custom_seconds_for_rotation;
                }
                let loop = list.length >= 4;
                var swiper = new Swiper(".virtual-booth-swiper", {
                    effect: 'coverflow',
                    centeredSlides: true,
                    slidesPerView: 2,
                    autoplay: automatic_loop == 1 ? {
                        delay: seconds_for_rotation * 1000,
                        reverseDirection: false,
                    } : false,
                    loop: loop,
                    loopAdditionalSlides: list.length < 4 ? 2 : 0,
                    speed: 600,
                    grabCursor: true,
                    watchOverflow: true,
                    coverflowEffect: {
                        rotate: 40,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                        slideShadows: false,
                        transformEl:null,
                    },
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                        },
                        990: {
                            slidesPerView: 4,
                        }
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        renderBullet: function (index, className) {
                            if (index < 5) {
                                return '<span class="' + className + '"></span>';
                            } 
                            return '';
                        },
                    },
                    navigation: {
                        nextEl: '.swiper-arrow-next',
                        prevEl: '.swiper-arrow-prev',
                    },
                });
                swiper.init();
            }
        };

        const parseImage = (img) => {
            if (img && img.substr(0,1) != '/') {
                img = '/' + img;
            }
            return img;
        };

        const getIcon = (type) => {
            type = parseTitle(type);
            switch (type) {
                case 'video':
                    return 'bi bi-play-btn'
                case 'Document':
                    return 'bi bi-card-text'
                case 'podcast':
                    return 'bi bi-broadcast-pin'
                default:
                    return 'bi bi-card-text'
            }
        };

        const showDefaultImage = (event) => {
            event.target.src = "/images/v4/picture.png"
        };

        const parseTitle = (tag) => {
            switch(tag){
                case 'pdf document':
                    return 'Document'
                case 'html':
                    return 'Web'
                case 'video':
                    return 'Video'
                default:
                    return tag;
            }
        };

        const cardRedirect = (item) => {
            //EAHP KJ redirects
            if([1534, 1535, 1536, 1537, 1538].includes(item.id) && item.slug) {
                let url = new URL(item.external_link);
                url.searchParams.set('origin', btoa(item.slug));
                window.open(url, '_blank');
            } else {
                window.open(item.url, '_blank');
            }
        };

        return {
            t,
            isMobile,
            parseImage,
            getIcon,
            showDefaultImage,
            parseTitle,
            cardRedirect,
            isLoading,
            list,
        };
    },
}
</script>