import type from 'marzipano/src/util/type';
import TranslationService from '../../../core/services/translation.service';
import EventFactory from '../../../modules/events/factories/Event.factory';

export default {
    get(event) {
        const formatted_dates = EventFactory.formatDates(event);
        return {
            id: event?.id ?? '',
            title: event?.title ?? '',
            description: event?.description ?? '',
            slug: event?.slug ?? '',
            hasLiveSessions: event?.hasLiveSessions ?? false,
            image: event?.image ?? '',
            link: TranslationService.transRoute('event-detail', {slug: event?.slug ?? ''}),
            date_start: event?.date_start ?? '',
            date_end: event?.date_end,
            ...formatted_dates,
            keywords: event?.keywords ?? [],
            shortened_keywords: event.keywords === undefined ? [] : event?.keywords.splice(0, 5),
            is_new: event?.isNew ?? false,
            is_live: event?.hasLiveSessions ?? event?.is_live ?? false,
            is_on_demand: event?.keep_opened_session == 1 ? true : false,
            is_multimedia: event?.multimediaType ?? false,
            multimedia_type: event?.card_type?.toLowerCase() ?? "",
            multimedia_type_str: event?.card_type?.toLowerCase() ?? "",
            type: event?.type ?? ""
        };
    },
};