import $ from 'jquery';

const FormUtils = {
  survey: [],
  questionName: '',

  /**
   * Find the index of an element
   * 
   * @param {<Object>Array} arr - Array to search in
   * @param {string} el - Property name
   * @param {string} value - Searched value
   * @returns <Integer> index
   */
  findElement (arr, el, value) {
    return arr.findIndex((a) => a[el] === value);
  },

  /**
   * Search the match elements, prepare the data and set it 
   * to the survey element
   * 
   * @param {string<image|text>} type 
   */
  findAndRetrieveMatchAnswers (type = 'image') {
    const selector = `[id^="${type}-answers-"]`;
    const self = this;
    const matchAnswers = $(selector);
    if (matchAnswers) {
      matchAnswers.each(function () {
        const answer = $(this).data();
        const { answerid, matchid, questionid } = answer;
        if (!matchid) return;
        const matchAnswer = { answer_option: answerid, answer_match: matchid };
        const result = { [self.questionName]: questionid, answers: [matchAnswer] };
        const i = self.findElement(self.survey, self.questionName, questionid);
        if (i >= 0) return self.survey[i].answers.push(matchAnswer);
        self.survey.push(result);
      });
    };
  },

  /**
   * To retrieve the data properly, we need to set the Question Id in the name property of the form
   * and the Answer Id in the value property
   * 
   * @param {<DOMElement> | id} form - the data element to find the form
   * @param {string} groupName - name of the final array of data 
   * @param {string} questionName - name of the property where we storage the question id 
   * @param {string} answerName - name of the property where we storage the answers id 
   * 
   * @returns formData attribute
   */
  getFormData (form, groupName = 'survey', questionName = 'question_id', answerName = 'answers') {
    const formData = new FormData();
    const data = $(form).serializeArray();
    this.questionName = questionName;

    data.forEach((input) => {
      const { name, value } = input;
      const i = this.findElement(this.survey, questionName, name);

      if (i >= 0) return this.survey[i][answerName].push(value);
      this.survey.push({ [questionName]: name, [answerName]: [value], });
    });
    this.findAndRetrieveMatchAnswers();
    this.findAndRetrieveMatchAnswers('text');

    formData.append(groupName, JSON.stringify(this.survey));
    this.resetGlobalValues();
    return formData;
  },

  resetGlobalValues () {
    this.survey = [];
    this.questionName = '';
  },

////////////////////////////////////////////////////

  setFormListeners (questionClass = 'test-question') {
    $(".selectImageLeft, .textSelectMatchLeft").click((e) => this.selectTestImage(e, questionClass, "left"));
    $(".selectImageRight, .textSelectMatchRight").click((e) => this.selectTestImage(e, questionClass, "right"));
  },

  selectTestImage({ currentTarget }, questionClass, direction) {
    this.checkAndRemoveActive(direction);
    
    if(currentTarget.hasAttribute('data-bind-image-active') && currentTarget.hasAttribute('data-linked')){
      return this.unlinkElements(currentTarget, direction);
    };

    currentTarget.dataset.bindImageActive ="";
    this.linkElements(questionClass);
  },

  checkAndRemoveActive (direction) {
    $(`[image-${direction}]`).each(function () {
      const isActiveNotLinked = this.hasAttribute('data-bind-image-active') && !this.hasAttribute('data-linked');
      if(isActiveNotLinked) {
        delete this.dataset.bindImageActive
      };
    });
  },

  linkElements (questionClass) {
    const l = $(`.${questionClass}:not(.sr-only)`).find('[image-left][data-bind-image-active]:not([data-linked])')[0];
    const r = $(`.${questionClass}:not(.sr-only)`).find('[image-right][data-bind-image-active]:not([data-linked])')[0];
    if  (!l || !r) return;
    
    const x1 = $(l).position().left + ($(l).width());
    const y1 = $(l).position().top + ($(l).height() / 2);
    const x2 = $(r).position().left;
    const y2 = $(r).position().top + ($(r).height() / 2);
  
    $(`#line-image${l.dataset.answerid}`).attr('x1', x1).attr('y1', y1).attr('x2', x2).attr('y2', y2);

    l.dataset.linked = "";
    l.dataset.matchid = r.dataset.answerid;
    $(l).data('matchid', r.dataset.answerid)
    $(r).data('matchid', l.dataset.answerid)
    r.dataset.linked = "";
    r.dataset.matchid = l.dataset.answerid;
  },

  unlinkElements (currentTarget, side) {
    const { dataset } = currentTarget;
    let lineId = dataset.answerid;
    if (side === 'right') lineId = dataset.matchid;
    //Source dataset
    delete dataset.bindImageActive;
    delete dataset.linked;
    delete dataset.matchid;
    $(currentTarget).removeData('matchid');
    currentTarget.removeAttribute('data-matchid');
    //Remove line
    $(`#line-image${lineId}`).removeAttr('x1 y1 x2 y2');
    //Target dataset
    const linkedElement = $(`[data-matchid=${dataset.answerid}]`)[0];
    if (!linkedElement) return;

    delete linkedElement.dataset.linked;
    delete linkedElement.dataset.bindImageActive;
    delete linkedElement.dataset.leftId;
    $(linkedElement).removeData('matchid');
    linkedElement.removeAttribute('data-matchid');
  },

};

export default FormUtils;
