<template>
    <section id="people-choice-content-section" class="py-3">
        <V4Carousel :slidesToShow="3" :slidesToScroll="3" :rowsToShow="multimedias.length > 4 ? 2 : 1" :name="'voting-multimedia'">
            <template v-slot:items>
                <div class="people-choice-content-section-card" v-for="multimedia in multimedias" v-bind:key="multimedia.id">
                    <PeopleChoiceContentCard :multimedia="multimedia" />
                </div>
            </template>
        </V4Carousel>
        <button 
            v-if="displaySubmitVotesButton && votingState != 'countdown'" 
            @click="submitVotes(configuration.congress_id)" 
            :disabled="submittingVotes" 
            class="people-choice-content-section-submit-votes" 
            :class="submittingVotes ? 'disabled' : ''"
        >
            Submit votes
        </button>
    </section>
</template>

<script>
import { ref, computed} from "vue";
import TranslateService from "../../../core/services/translate.service";
import V4Card from "../../card/V4Card.vue";
import V4Carousel from "../../carousel/V4Carousel.vue";
import PeopleChoiceContentCard from "./people-choice-content-partials/PeopleChoiceContentCard.vue";
import { useVotingStore } from "../../../store/voting.store";
import GoogleAnalytics from "../../../GoogleAnalytics";

export default {
    name: "PeopleChoiceContentSection",
    components: { V4Card, V4Carousel, PeopleChoiceContentCard},
    props: {
        configuration: Object,
        multimedias: Array,
    },
    setup(props) {
        const translator = TranslateService.create();
        const t = (tag) => translator.translate(tag);

        const isLoading = ref(true);
        const configuration = props.configuration;
        const multimedias = props.multimedias;

        const votingStore = useVotingStore();
        const votingState = computed(() => votingStore.votingState);

        const displaySubmitVotesButton = computed(() => {
            return votingStore.votes.length == 3;
        });

        const submittingVotes = ref(false);

        const submitVotes = async (congressId) => {
            submittingVotes.value = true;
            await votingStore.submitVotes(congressId);
            submittingVotes.value = false;
            GoogleAnalytics.sendPromoClickEvent('Submit votes');
        };

        const isMobile = () => {
            try {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                );
            } catch (error) {
                console.error("Error determining if the device is mobile:", error);
                return false;
            }
        };

        return {
            t,
            isMobile,
            isLoading,
            configuration,
            multimedias,
            votingStore,
            votingState,
            displaySubmitVotesButton,
            submitVotes,
            submittingVotes,
        };
    },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>