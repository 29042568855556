<template>
  <div class="checkbox-form">
    <div class="answers">
      <label class="item">
        <span>{{ label }}</span>
        <input :checked="checked" type="checkbox" :id="id" :disabled="disabled" :value="value" @change="onChange" />
        <span class="checkmark" :class="{ disabled: disabled }"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    defaultLabel: String,
    value: {
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: String,
  },
  data() {
    return {
      elementId: this.id ?? this.defaultLabel,
      label: this.defaultLabel ?? "",
    };
  },
  methods: {
    onChange(e) {
      // this.$root.$emit('changecheckbox', {
      //   key: this.elementId,
      //   value: this.checked
      // });
      document.dispatchEvent(new CustomEvent("changecheckbox", { detail: {
        key: this.elementId,
        value: this.checked
      } }));
      this.$emit('input', {
        value: this.checked,
        key: this.elementId
      });
    }
  },
};
</script>
