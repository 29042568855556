<template>
    <div v-if="showSticky" class="v4-event-subscription" :class="{'v4-event-subscription--sticky': sticky}" :style="styles" @click="openCard()">
        <span class="v4-event-subscription--close" @click.stop="close" v-if="sticky">
            <i class="bi bi-x"></i>
        </span>
        <div class="d-flex">
            <div class="v4-event-subscription--image">
                <img style="width: 100%; height: 100%;" alt="" :src="parseImage(this.image)" @error="imageUrlAlt" />
            </div>

            <div class="v4-event-subscription--info">
                <span class="v4-event-subscription--live" v-if="is_live" >Live now!</span>
                <h3 class="v4-event-subscription--title">
                    <a href="javascript:void(0)" v-html="defaultTitle"></a>
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
import ActivityService from '../../../core/services/activity.service';
import TranslationService from '../../../core/services/translation.service';
    export default {
        props: {
            title: String,
            image: String,
            slug: String,
            sticky: Boolean,
            styles: String,
            link: String,
            is_live: Boolean,
        },
        data() {
            return {
               defaultTitle: this.title,
               activitySvc: ActivityService.create(),
               showSticky: true
            }
        },
        methods: {
            close() {
                this.showSticky = false;
            },
            imageUrlAlt(event) {
                event.target.src = "/images/default-images/congress/Depositphotos_7451136_xl-2015.jpg";
            },
            parseImage(img) {
                if (img && img.substr(0,1) != '/') {
                    img = '/' + img;
                }
                return img;
            },
            openCard() {
                if(this.$attrs.type == 'agenda_session') {
                    if (this.$attrs.cards_behaviour == 'external link') {
                        document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: this.$attrs.external_link }));
                        // this.$root.$emit('leavingMMPModal', this.$attrs.external_link);
                    } else if (this.$attrs.cards_behaviour == 'open modal') {
                        let agendaURL = '';
                        if ((this.is_live || this.$attrs.is_on_demand) && this.$attrs.is_accessible && !this.$attrs.external_congress) {
                            if (this.$attrs.meeting_platform == 'WEBEX' && window.app.env.ENABLE_LIVE) {
                                agendaURL = window.app.env.LIVE_URL+ '/webex/' + this.slug + '/' + this.$attrs.id + '?encryptsKey=live-encrypts'+window.user.id;
                            }else if(this.$attrs.meeting_platform == 'ZOOM' && window.app.env.ENABLE_LIVE){
                                agendaURL = window.app.env.LIVE_URL+ '/zoom/' + this.slug + '/' + this.$attrs.id + '?encryptsKey=live-encrypts'+window.user.id;
                            }else{
                                agendaURL = TranslationService.transRoute('event-detail-live', { slug: this.slug, agendaSessionId: this.$attrs.id });
                            }
                            this.saveActivity('live');
                        } else {
                            agendaURL = TranslationService.transRoute('event-agenda-detail', { slug: this.slug, agendaSessionId: this.$attrs.id });
                            this.saveActivity('agenda');
                        }
                        return window.open(agendaURL, '_blank').focus();
                    }
                } else {
                    this.saveActivity('enter-congress');
                    return window.open(this.link, '_blank').focus();
                }
            },

            saveActivity(event) {
                let params = {
                    title: this.title,
                    model_type: this.sticky ? 'agenda_session' : 'congress',
                    model_id: this.id,
                    source: this.sticky ? null : 'carousel-congresses',
                    congress_id: window.app.event ? window.app.event.id : null
                }
                this.activitySvc.saveActivity(event, params);
            }
        }
    }
</script>
