import KrasContentSearch from '../objects/v4/modules/kras/KrasContentSearch.vue';
import CalendarSection from '../objects/v4/modules/kras/CalendarSection.vue';
import PastEvents from '../objects/v4/modules/kras/PastEvents.vue';
import PodcastSection from '../objects/v4/modules/kras/PodcastSection.vue';
import SteeringComitee from '../objects/v4/modules/kras/SteeringComitee.vue';

const vueComponents = [
    ['KrasContentSearch', KrasContentSearch],
    ['CalendarSection', CalendarSection],
    ['PastEvents', PastEvents],
    ['PodcastSection', PodcastSection],
    ['SteeringComitee', SteeringComitee]
]

export default vueComponents