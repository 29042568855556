<template>
  <section class="custom-event-banner" :id="$parent.$parent.formatHrefAnchorText(configuration.section_wording)">
    <div class="container">
      <div class="custom-event-banner__content" :class="sectionAlignment === 'left' ? 'd-flex flex-row' : (sectionAlignment === 'right' ? 'd-flex flex-row-reverse' : 'd-flex justify-content-center')">
        <div class="custom-event-banner__content-text" :class="checkImageConditions() ? '' : (sectionAlignment === 'right' ? 'd-flex flex-row-reverse flex-nowrap' : (sectionAlignment === 'left' ? 'd-flex flex-row flex-nowrap' : 'd-flex justify-content-center'))">
          <div class="custom-event-banner__content-text__text" v-html="configuration.configuration.custom_banner_text"></div>
          <div v-if="configuration.configuration.custom_banner_button == 'external_link' && checkBannerExternalLinkValue()" class="custom-event-banner__content-text__container-button">
            <a class="custom-event-banner__content-text__button" v-on:click="redirectToExternalUrl()" target="_blank">
              <span>{{ configuration.configuration.custom_banner_button_wording }}</span>
            </a>
          </div>
          <div v-if="configuration.configuration.custom_banner_button == 'internal_link' && checkBannerInternalLinkValue()" class="custom-event-banner__content-text__container-button">
            <a class="custom-event-banner__content-text__button" v-on:click="redirectToInternalUrl()" target="_blank">
              <span>{{ configuration.configuration.custom_banner_button_wording }}</span>
            </a>
          </div>
          <div v-if="configuration.configuration.custom_banner_button == 'pdf' && checkBannerFileValue()" class="custom-event-banner__content-text__container-button">
            <a class="custom-event-banner__content-text__button" :href="parseLink()"
              :download="`${configuration.section_wording}.pdf`" @click="trackDownload()">
              <span class="list-text">{{ configuration.configuration.custom_banner_button_wording }}</span>
            </a>
          </div>
        </div>
        <div v-if="configuration.configuration.custom_banner_image" class="custom-event-banner__content__logo">
          <img v-if="checkImageConditions()" :src="parseImage(configuration.configuration.custom_banner_image)" alt="banner-logo"/>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';

export default {
  name: 'BannerSection',
  data() {
    return {
      translator: TranslateService.create(),
      sectionAlignment: this.getAlignment(),
      logoPosition: this.getLogoPosition(),

    };
  },
  props: {
    configuration: Object
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    translateField(field){
      return this.translator.translateField(field);
    },
    getAlignment() {
      let logoPosition;
      if (this.configuration.configuration.custom_banner_alignment === 'right') {
        logoPosition = 'right';
      } else if (this.configuration.configuration.custom_banner_alignment === 'left') {
        logoPosition = 'left';
      } else {
        logoPosition = 'center';
      }
      return logoPosition;
    },
    parseLink() {
      const bannerFileValue = this.configuration.configuration.custom_banner_button_file;
      const translatedLink = this.translateField(bannerFileValue);
      
      let link = null;
      if (translatedLink && translatedLink.substring(0,1) != '/') {
        link = '/' + translatedLink;
      }
      return link;
    },
    checkBannerExternalLinkValue(){
      return this.configuration.configuration.custom_banner_button_external_link;
    },
    redirectToExternalUrl(){
      const externalLink = this.configuration.configuration.custom_banner_button_external_link;
      GoogleAnalytics.sendPromoClickEvent(this.configuration.section_wording);
      document.dispatchEvent(new CustomEvent("leavingMMPModal", { detail: externalLink }));
      // this.$root.$emit('leavingMMPModal', externalLink);
    },
    checkBannerInternalLinkValue(){
      return this.configuration.configuration.custom_banner_button_internal_link;
    },
    redirectToInternalUrl(){
      return window.open(this.configuration.configuration.custom_banner_button_internal_link, '_blank').focus();
    },
    checkBannerFileValue(){
      let hasValue = true;
      const bannerFileValue = this.configuration.configuration.custom_banner_button_file;
      if(!bannerFileValue){
        hasValue = false;
      }else {
        const translatedValue = this.translateField(bannerFileValue);
        if(!translatedValue){
          hasValue = false;
        }
      }
      return hasValue;
    },
    getLogoPosition() {
      return this.configuration.configuration.custom_banner_alignment === 'right' ? 'left' : (this.configuration.configuration.custom_banner_alignment === 'left' ? 'right' : 'center');
    },
    checkImageConditions() {
      return this.sectionAlignment !== 'center' && this.configuration.configuration.custom_banner_image;
    },
    parseImage(img) {
      if (img && img.substr(0,1) != '/') {
          img = '/' + img;
      }
      return img;
    },
    trackDownload() {
      GoogleAnalytics.sendDownloadContentEvent(this.configuration.section_wording, 'pdf');
    },
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
