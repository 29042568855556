<template>
  <div class="connectmi-event__speakers" :key="componentKey">
    <div class="connectmi-event__speakers__helper">
      <h2 class="connectmi-event__speakers__title title title--xl">Speakers</h2>
      <div class="connectmi-event__speakers__day connectmi-event__agenda__day__container">
        <AgendaDay
          v-for="(tab, key, loop) in speakers"
          :key="key"
          :tabNumber="loop"
          :day="key"
          :selected="componentKey"
          :desc="topics[loop]"
          @click.native="forceRerender(loop)"
        ></AgendaDay>
      </div>
      <div v-for="(day, i, loop) in speakers" :key="i">
        <div v-if="componentKey === loop" class="connectmi-event__speakers__wrapper">
          <EventSpeaker
            v-for="(speaker, key) in speakers[i]"
            :key="key"
            :image="speaker.photo"
            :imagePath="imagePath"
            :fullname="speaker.name + ' ' + speaker.surname"
            :job="speaker.position"
            variant="speaker"
            :variantimage="'' + ((key % 3) + 1)"
            :bio="speaker.bio"
            :isSteeringCommittee="speaker.steering_committee"
            :institution="speaker.institution"
          ></EventSpeaker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TranslateService from '../../core/services/translate.service';
import EventSpeaker from './components/EventSpeaker.vue';
import AgendaDay from './components/AgendaDay.vue';
import axios from 'axios';

export default {
  props: {
    slug: String,
    imagePath: String
  },

  components: {
    EventSpeaker,
    AgendaDay
  },

  data() {
    return {
      speakers: [],
      translator: TranslateService.create(),
      componentKey: 0,
      topics: [
        'Redefining MI: from MINOCA to cardiogenic shock',
        'Acute atheromatous myocardial infarction',
        'Treating residual risk in patients with myocardial infarction',
        'Role of intracoronary imaging and coronary physiology',
        'New "drugs" and "tools" for myocardial infarction  management'
      ]
    };
  },

  mounted() {
    this.getSpeakers();
  },

  methods: {
    createImagePath(image) {
      return this.imagePath + image;
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    async getSpeakers() {
      const result = await axios.get(`/congresses/grouped-speakers?slug=${this.slug}`);
      this.speakers = result.data.data;
    },
    forceRerender(tab) {
      this.componentKey = tab;
    }
  }
};
</script>
