import moment from 'moment';

export default {
    get(agendaSession) {
      
        let startDateUtc = agendaSession?.startDateUtc ??  agendaSession?.date_start;
        let liveDate = this.getLiveDateCountdown(startDateUtc)
        return {
            id: agendaSession?.id ?? 0 ,
            author: agendaSession?.author ?? "",
            congress_id: agendaSession?.congress_id ?? "",
            created_at: agendaSession?.formattedDate,
            description: agendaSession?.description ?? "",
            disliked: agendaSession?.disliked ?? false,
            duration: agendaSession?.duration ?? false,
            startDate:agendaSession?.startDate ?? "",
            endDate: agendaSession?.endDate ?? "",
            agendaTimezone: agendaSession?.agendaTimezone ?? "UTC",
            startDateUtc: agendaSession?.startDateUtc ?? "",
            external_link: agendaSession?.stream_url ?? "",
            file: agendaSession?.file ?? "",
            image: agendaSession?.image ?? "",
            isNew: agendaSession?.isNew ?? "",
            is_european: agendaSession?.is_european ?? "",
            keywords: agendaSession?.keywords ?? [],
            liked: agendaSession?.liked ?? [],
            multimediaType: agendaSession?.multimediaType ?? "",
            preferedTimestamp: agendaSession?.preferedTimestamp ?? "",
            private: agendaSession?.private ?? "0",
            relatedContent: agendaSession?.relatedContent ?? [],
            relevance: agendaSession?.relevance ?? "",
            saved: agendaSession?.saved,
            slug: agendaSession?.slug ?? "",
            title: agendaSession?.title ?? "",
            slug: agendaSession?.slug ?? "",
            showPresentationsModalBtn: agendaSession?.showPresentationsModalBtn ?? "",
            type: agendaSession?.type ?? "",
            voltLink: agendaSession?.voltLink ?? "",
            voltTitle: agendaSession?.voltTitle ?? "",
            wordTimestamps: agendaSession?.wordTimestamps ?? {},
            liveDate : liveDate,
            date_formatted: agendaSession?.formattedDate ?? '',
            formattedHoursForCard: agendaSession?.formattedHoursForCard ?? '',
            is_live: agendaSession?.is_live ?? false,
            is_on_demand: agendaSession?.keep_opened_session == 1 ? true : false,
            isPast: agendaSession?.isPast ?? false,
            model_type: 'agenda_session',
            cards_behaviour: agendaSession?.cards_behaviour ?? "",
            speakers: agendaSession?.speakers ?? [],
            speakersList: agendaSession?.speakersList ?? "",
            is_accessible: agendaSession?.is_accessible ?? false,
            external_congress: agendaSession?.external_congress ?? false,
            agenda_category_name: agendaSession?.agenda_category_name ?? "",
            agenda_category_color: agendaSession?.agenda_category_color ?? "",
            multimedias: agendaSession?.multimedias ?? "",
            private: agendaSession?.private ?? "",
            isRegistered: agendaSession?.isRegistered ?? "",
            isBookASeat: agendaSession?.isBookASeat ?? "",
            current: agendaSession?.current ?? false,
            meeting_platform: agendaSession?.meeting_platform ?? "",
        };
    },

    getFullCalendar(agendaSession) {
        let formattedEvents = this.get(agendaSession);
        
        return {
            eventId: formattedEvents?.id ?? 0,
            id: formattedEvents?.id ?? 0,
            category: formattedEvents?.agenda_category_name ?? "",
            speakersList: formattedEvents?.speakersList ?? "",
            speakers: formattedEvents?.speakers ?? "",
            eventTitle: formattedEvents?.title ?? "",
            title: formattedEvents?.title ?? "",
            eventStart:formattedEvents?.startDate ?? "",
            start:formattedEvents?.startDate ?? "",
            eventEnd: formattedEvents?.endDate ?? "",
            end: formattedEvents?.endDate ?? "",
            dateFormatted: formattedEvents?.date_formatted,
            eventDescription: formattedEvents?.description,
            isLive: formattedEvents?.is_live,
            isOndemand: formattedEvents?.is_on_demand,
            image: formattedEvents?.image ?? "",
            categoryColor: formattedEvents?.agenda_category_color ?? "",
            onDemandContent: formattedEvents?.multimedias ?? "",
            private: formattedEvents?.private ?? "",
            isRegistered: formattedEvents?.isRegistered ?? "",
            isBookASeat: formattedEvents?.isBookASeat ?? "",
            slug: formattedEvents?.slug ?? "",
            type: formattedEvents?.type ?? "",
            cards_behaviour: formattedEvents?.cards_behaviour ?? "",
            external_redirect: formattedEvents?.external_redirect ?? "",
            external_url: formattedEvents?.external_url ?? "",
            is_accessible: formattedEvents?.is_accessible ?? "",
            external_congress: formattedEvents?.external_congress ?? "",
            meeting_platform: formattedEvents?.meeting_platform ?? "",
        };
    },

    getLiveDateCountdown(startDate) {

        if(!startDate ) {
            return '';
        }
        const startTime = moment.utc(startDate);
        const diffTime = startTime.diff(moment.utc(), 'seconds');
        if (diffTime <= 0) {
            return 'stop';
        }
        const [, slugLocale] = location.pathname.split('/');
        const locale = window.user && window.user.locale ? window.user.locale : slugLocale;
        startTime.locale(locale);
        return startTime.fromNow();

    },

    zeroPad(num) {
        return num < 10 ? '0' + num : '' + num;
    },

};
