<template>
  <div class="content-send-scroll">
    <div v-if="isLoading">
      {{t('general.loading')}}...
    </div>

    <draggable v-if="!isLoading" v-model="audienceData" item-key="id" group="ponent" @start="drag = true" @end="endDrag"
      handle=".question-box">
      <template #item="{ element: item }">
        <div class="question-box speakers-row-count" :qtnid="item.id" :id="'main_ponent_block_' + item.id">
          <div :id="'answered_heading_' + item.id" class="question-title"
            :class="(item.answered) ? 'content-grey-text' : ''">
            <h4 class="text-left">{{ item.user.name + ' ' + item.user.surname }}</h4>
          </div>
          <div class="question-desc">
            <div class="content-des-box text-left" :class="(item.answered) ? 'content-grey-text' : ''"
              :id="'content_block_' + item.id" v-html="item.question"></div>
            <div class="cancel-save clearfix" v-if="!isspeaker">
              <div class="pull-left">
                <div class="ask-tick">
                  <div class="checkbox-form">
                    <div class="answers">
                      <label class="item">
                        <span>Question was answered by the speaker</span>
                        <input type="checkbox" id="checkbox_id_1"
                          @change="checkBox($event, item.agenda_session_id, item.id, item.question)"
                          class="checkbox_cls dynamic_cls" :value="item.id" :checked="(item.answered) ? true : false">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="pull-right" :id="'content_clock_block_' + item.id" v-if="item.status != 'discarded'">
                  <div class="send-btn">
                    <button @click="showModal(item.agenda_session_id, item.id, item.question, 'discarded');"><i
                        class="bi bi-arrow-counterclockwise"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </draggable>

    <div v-if="!isLoading && audienceData.length==0">No records found.</div>
  </div>
</template>
<script>
import AlertModel from "../internal-congress/ModalComponent.vue";
import AudienceFactory from './factories/Audience.factory';
import draggable from 'vuedraggable';
//import eventCallerService from '../../core/services/eventCaller.service';
import axios from 'axios';
import $ from 'jquery';
import TranslateService from '../../core/services/translate.service';
import { WSHelper } from '../../../../libs/ws/ws-helper';
import Utils from '../../Utils';

export default {
  name: 'moderatorPonent',
  props: ['slug', 'itemid', 'isspeaker'],
  components: {
    AlertModel,
    draggable
  },
  data() {
    return {
      isLoading: true,
      allData: [],
      audienceData: [],
      isAudience: true,
      isPonent: false,
      isModalVisible: false ,
      sortBy: 'order',
      sortDirection: 'asc',
      translator : TranslateService.create()
    }
  },
  mounted() {
    try {
      this.liveData();
      let that = this;
      this.ws = new WSHelper(true);
      this.ws.registerCallback((evt) => {
        switch (evt.operation) {
          case 'conn_retry_failure':
            // do something like displaying a loading circle?
            break;
          case 'conn_down':
            break;
          case 'msg_received':
            // A message was received:
            this.onWSMessage(evt);
            break;
          case 'conn_started':
          case 'conn_restarted':
            // Ask current status:
            const agendaSessionId = Utils.getAgendaSessionId();
            that.ws.sendObject({ "agenda_id": agendaSessionId, operation: "register_ws", "uid": window.user.uid });
            break;
        }
      });
      this.ws.start().then(res => {
        console.debug(res);
      }).catch(err => {
        console.debug("ERR", err);
      });
    } catch (error) {
      console.log('An error occurred:', error);
    }

    /* eventCallerService.$on('refreshSpeakerOrderedQuestions', () => {
        this.liveData();
    });
    eventCallerService.$on('receiveSpeakerQuestion', (data) => {
        this.receiveQuestion(data);
    });
    eventCallerService.$on('discardSpeakerQuestion', (data) => {
        this.discardQuestion(data);
    });
    eventCallerService.$on('answerSpeakerQuestion', (data) => {
        this.answerQuestion(data);
    }); */
    document.addEventListener('refreshSpeakerOrderedQuestions', () => {
      this.liveData();
    });
    document.addEventListener('receiveSpeakerQuestion', event => {
      this.receiveQuestion(event.detail);
    });
    document.addEventListener('discardSpeakerQuestion', event => {
      this.discardQuestion(event.detail);
    });
    document.addEventListener('answerSpeakerQuestion', event => {
      this.answerQuestion(event.detail);
    });
  },
  methods: {
    onWSMessage(evt) {
      let that = this;
      if(evt.json && evt.json.data) {
        const data = evt.json.data;
        switch (data.action) {
          case "speaker_question_answered":
          break;
        }
      }
    },
    checkBox(event, sessionId, qtnId, qtn) {
      this.$nextTick(() => {
        this.questionAction(sessionId, qtnId, qtn, 'answered');
      });
    },
    endDrag(event) {
      var id = [];
      $(".question-box").each(function() {
        id.push($(this).attr('qtnid'));
      });
      this.updateQtnOrder(id.toString());
    },
    async updateQtnOrder(id) {
      const response = await this.updateLiveQtnOrder(id);
      if(response.status==200){

      }
    },
    updateLiveQtnOrder(id) {
      let data = new FormData();
      data.append('agendaSessionId', this.itemid);
      data.append('orderedQuestions', id);
      return axios.post('/webapi/live/save-questions-order', data);
    },
    async liveCount() {
      const response = await this.getLiveQtnData();
      var audienceArray = [];
      var ponentArray = [];
      if(response.data.data.length>0) {
        this.allData = response.data.data.reverse().map(k => AudienceFactory.get(k));
        this.allData.forEach(data => {
          if((data.status=='created') || (data.status=='discarded') || (data.status=='updated')) {
            audienceArray.push(data);
          }
          if(data.status=='validated'){
            ponentArray.push(data);
          }
        });
        $('#audience_count').text('('+audienceArray.length+')');
        $('#ponent_count').text('('+ponentArray.length+')');
      }
    },
    async liveData() {
      const results = await this.getLiveQtnData();
      if(typeof results.data.data !== "undefined" && results.data.data.length>0) {
        this.allData = results.data.data.reverse().map(k => AudienceFactory.get(k));
        this.audienceData = [];
        this.allData.forEach(data => {
          if(data.status=='validated') {
            this.audienceData.push(data);
          }
        });
        this.audienceData = this.sortArrays();
        $('#ponent_count').text('('+this.audienceData.length+')');
      }
      this.isLoading = false;
    },
    receiveQuestion(data) {
      this.audienceData.push(data);
      $('#ponent_count').text('('+this.audienceData.length+')');
    },
    discardQuestion(data) {
      this.audienceData = this.audienceData.filter(function( q ) {
        return q.id !== data.id;
      });
      $('#ponent_count').text('('+this.audienceData.length+')');
    },
    answerQuestion(data) {
      if(data.answered) {
        $('#content_block_'+data.id).addClass('content-grey-text');
        $('#answered_heading_'+data.id).addClass('content-grey-text');
      } else {
        $('#content_block_'+data.id).removeClass('content-grey-text');
        $('#answered_heading_'+data.id).removeClass('content-grey-text');
      }
    },
    getLiveQtnData() {
      return axios.post('/webapi/events/'+this.slug+'/get-live-questions', {
        agendaSessionId: this.itemid,shownIds: []
      });
    },
    showModal(sessionId, qtnId, qtn, status){
      this.questionAction(sessionId, qtnId, qtn, status);
    },
    async questionAction(sessionId, qtnId, qtn, status) {
      const response = await this.getQtnValidation(sessionId, qtnId, qtn, status);
      if(response.status==200){
        if(status=='discarded') {
          this.liveCount();
          $('#main_ponent_block_'+qtnId).hide();
          $('#content_clock_block_'+qtnId).hide();
          // $('#content_block_'+qtnId).css('text-decoration','line-through');
        }
        if(response.data.data.answered) {
          $('#content_block_'+qtnId).addClass('content-grey-text');
          $('#answered_heading_'+qtnId).addClass('content-grey-text');
        } else {
          $('#content_block_'+qtnId).removeClass('content-grey-text');
          $('#answered_heading_'+qtnId).removeClass('content-grey-text');
        }
      }
    },
    getQtnValidation(sessionId, qtnId, qtn, status) {
      return axios.post('/webapi/events/'+this.slug+'/validate-live-questions', {
        agendaSessionId: sessionId,
        live_question: qtnId,
        question: qtn,
        status: status
      });
    },
    sortArrays() {
      // return _.orderBy(this.audienceData, 'order', 'asc');
      return this.audienceData.sort((a, b) => a.order - b.order);
    },
    isMobile(){
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    t(tag){
      return this.translator.translate(tag)
    },
  }
}
</script>
