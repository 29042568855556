<template>
    <PopupModal ref="popup">
    <div class="confirmpopup">
      <!-- TITLE -->
      <h2 v-if="title != ''" v-html="title"></h2>
      <!-- MESSAGE -->
      <p v-if="message" v-html="message"></p>
      <!-- COPY CODE FORM -->
      <div v-if="copyForm" class="copy-form">
        <button class="mt-3" @click="copyText">
          <span class="mr-0 mr-lg-3 mr-xl-3">
            {{ copyCode }}
          </span>
          <em ref="copyIcon" class="bi bi-clipboard"></em>
        </button>
      </div>
      <!-- BUTTONS -->
      <div class="justify-center my-4">
        <!-- CANCEL BUTTON -->
        <button v-if="cancelButton" class="cancel-btn mx-3" @click="_cancel">{{ cancelButton }}</button>
        <!-- OK BUTTON -->
        <span v-if="okButton" class="ok-btn mx-3" @click="_confirm">{{ okButton }}</span>
        <!-- DOWNLOAD CERTIFICATE BUTTON -->
        <a v-if="downloadButton" class="ok-btn mx-3" href="javascript:void(0);" @click="downloadDiploma()">{{
        t('knowledge-journey.buttons.get-certificate')
      }}</a>
      </div>
    </div>
  </PopupModal>
</template>

<script>
import PopupModal from './PopupModal.vue';
import TranslateService from '../../core/services/translate.service';
import ActivityService from '../../core/services/activity.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import axios from 'axios';


export default {
  name: 'ConfirmDialogue',
  components: { PopupModal },
  mounted() {
    document.addEventListener("leavingMMPModal", (event) => {
      this.showLeavingModal(event.detail);
    });
    document.addEventListener("showTermModal", (event) => {
      this.showTermsModal(event.detail.title, event.detail.text);
    });
    document.addEventListener("leavingMMPModalURL", (event) => {
      this.showLeavingModalURL(event.detail);
    });
    document.addEventListener("accessCodeModal", (event) => {
      this.showAccessCodeModal(event.detail);
    });
    /*     this.$root.$on('leavingMMPModal', url => {
          this.showLeavingModal(url);
        });
    */
    /*
        this.$root.$on('leavingMMPModalURL', url => {
          this.showLeavingModalURL(url);
        });
    */
   /*
        this.$root.$on('accessCodeModal', params => {
          this.showAccessCodeModal(params);
        });
        this.$root.$on('closeConfirmDialogue', () => {
          this.$refs.popup.close();
        });
    */
      document.addEventListener("requestedMultimediaDialogue", () => {
        this.showRequestedMultimediaDialogue();
      });
   /*
        this.$root.$on('requestedMultimediaDialogue', () => {
          this.showRequestedMultimediaDialogue();
        }); */
  },
  data: () => ({
    kjid: 0,
    title: '',
    message: '',
    okButton: '',
    cancelButton: '',
    downloadButton: '',
    resolvePromise: '',
    rejectPromise: '',
    translator: TranslateService.create(),
    activitySvc: ActivityService.create(),
    copyForm: '',
    copyCode: ''
  }),
  methods: {
    show(opts = {}) {
      this.kjid = opts.kjid;
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      if (opts.downloadButton) {
        this.downloadButton = opts.downloadButton;
      }
      if (opts.copyForm && opts.copyCode) {
        this.copyForm = opts.copyForm;
        this.copyCode = opts.copyCode;
      }
      if (this.$refs.popup) this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _confirm() {
      this.$refs.popup.close();
      /* this.handleOutboundClick(window.location.href); */
      this.handleOutboundClick(window.location.href);
      this.resolvePromise(true);
      GoogleAnalytics.sendCloseContentEvent(this.title, 'modal');
    },
    _cancel() {
      this.$refs.popup.close();
      /* this.$root.$emit('closeConfirmDialogue'); */
      this.resolvePromise(false);
      GoogleAnalytics.sendCloseContentEvent(this.title, 'modal');
    },
    t(tag) {
      return this.translator.translate(tag);
    },
    async showLeavingModal(url) {
      let title = this.t('general.leave-modal-title');
      let message = this.t('general.leaving');
      let okButton = this.t('general.accept');
      let cancelButton = this.t('general.cancel');

      const ok = await this.show({
        title: title,
        message: message,
        okButton: okButton,
        cancelButton: cancelButton
      });
      if (ok) {
        window.open(url, '_blank').focus();
      }
    },
    async showLeavingModalURL(url) {
      let title = this.t('general.leave-modal-title');
      let message = this.t('general.leaving');
      let okButton = this.t('general.accept');
      let cancelButton = this.t('general.cancel');

      const ok = await this.show({
        title: title,
        message: message,
        okButton: okButton,
        cancelButton: cancelButton
      });
      if (ok) {
        window.open(url, '_blank').focus();
      }
    },
    async showRequestedMultimediaDialogue(url) {
      let title = this.t('email.multimedia_title');
      let message = this.t('email.multimedia_time');
      let okButton = this.t('general.accept');
      let cancelButton = null;

      await this.show({
        title: title,
        message: message,
        okButton: okButton,
        cancelButton: cancelButton
      });
    },
    async showAccessCodeModal(params) {
      let title = params.title;
      let message = `${this.t('congress.code_access_virtual')}<br>${this.t('congress.copy_personal_code')} ${params.title
        } ${this.t('congress.form')}`;
      let okButton = this.t('general.access_congress');
      let cancelButton = this.t('general.cancel');

      const ok = await this.show({
        title: title,
        message: message,
        okButton: okButton,
        cancelButton: cancelButton,
        copyForm: true,
        copyCode: params.code
      });
      if (ok) {
        window.open(params.url, '_blank').focus();
      }
    },
    async showTermsModal(title, text) {      
      GoogleAnalytics.sendOpenContentEvent(title, 'T&C');
      await this.show({
        title,
        message: text,
        okButton: this.t('general.close'),
        cancelButton: null
      });
    },
    onCopy() {
      this.$refs.copyIcon.classList.remove('bi-clipboard');
      this.$refs.copyIcon.classList.add('bi-clipboard-check');
    },
    downloadDiploma() {
      const download = '/kj/diploma';
      const fd = new FormData();
      fd.append('kjId', this.kjid);

      axios({
        url: download,
        method: 'POST',
        responseType: 'blob',
        data: fd
      }).then(response => {
        const url = URL.createObjectURL(response.data);
        this.saveActivity('diploma-download');
        window.open(url);
      });
    },
    saveActivity(event) {
      let params = {
        title: this.title,
        model_type: 'knowledge',
        model_id: this.kjid,
        congress_id: window.event ? window.event.id : null
      };
      this.activitySvc.saveActivity(event, params);
    },
    copyText() {
      navigator.clipboard.writeText(this.copyCode);
    },
    handleOutboundClick(url) {
      const parts = url.split('/');
      const urlDomain = parts[2];
      const linkText = parts.slice(3).join(' ').replace(/-/g, ' ');
      GoogleAnalytics.sendOutboundClickEvent(linkText, url, urlDomain);
    },
  }
};
</script>
