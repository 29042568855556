
import CustomFloatingMenu from '../objects/v4/modules/events/event-internal/CustomFloatingMenu.vue';
import EventSubscriptionSticky from '../objects/v4/modules/events/event-subscription-sticky/EventSubscriptionSticky.vue';
import EventInternalHeader from '../objects/v4/modules/events/event-internal/EventInternalHeader.vue';
import InternalCongressUpcoming from '../objects/v4/modules/internal-congress/InternalCongressUpcoming.vue';
import InternalCongressAgenda from '../objects/v4/modules/internal-congress/InternalCongressAgenda.vue';
import InternalCongressSpeakers from '../objects/v4/modules/internal-congress/InternalCongressSpeakers.vue';
import InternalCongressContent from '../objects/v4/modules/internal-congress/InternalCongressContent.vue';
import SpeakerModal from '../objects/v4/modules/modals/SpeakerModal.vue';
import TcModal from '../objects/v4/modules/modals/TcModal.vue';
import InternalCongressHalloffame from '../objects/v4/modules/internal-congress/InternalCongressHalloffame.vue';
import InternalCongressGratitudeboard from '../objects/v4/modules/internal-congress/InternalCongressGratitudeboard.vue';
import InternalCongressTripplecard from '../objects/v4/modules/internal-congress/InternalCongressTripplecard.vue';


const vueComponents = [

    ['CustomFloatingMenu', CustomFloatingMenu],
    ['EventSubscriptionSticky', EventSubscriptionSticky],
    ['EventInternalHeader', EventInternalHeader],
    ['InternalCongressUpcoming', InternalCongressUpcoming],
    ['InternalCongressAgenda', InternalCongressAgenda],
    ['InternalCongressSpeakers', InternalCongressSpeakers],
    ['InternalCongressContent', InternalCongressContent],
    ['SpeakerModal', SpeakerModal],
    ['TcModal', TcModal],
    ['InternalCongressHalloffame', InternalCongressHalloffame],
    ['InternalCongressGratitudeboard', InternalCongressGratitudeboard],
    ['InternalCongressTripplecard', InternalCongressTripplecard],

    
]

export default vueComponents