<template>
    <div>
        <div class="agenda-card-footer">
            <div class="agenda-card-footer__calendar" v-if="!itemInfo.isLive && !itemInfo.isOndemand">
                <!-- Button code -->
                <div title="Add to Calendar" class="addeventatc" @click="registerLogAddToCalendar">
                    <span>{{ t('congress.calendar') }}</span>
                    <i class="bi bi-chevron-down"></i>
                    <span class="start">{{ itemInfo.startDate }}</span>
                    <span class="end">{{ itemInfo.endDate }}</span>
                    <span class="timezone">{{itemInfo.agendaTimezone}}</span>
                    <span class="title">{{itemInfo.title}}</span>
                    <span class="description">{{plainTextDescription}}</span>
                    <span class="location">{{ this.getLocation(itemInfo.slug, itemInfo.id) }}</span>
                </div>
            </div>
            <button class="agenda-card-footer__register" v-if="itemInfo.isBookASeat && !isRegisteredData"
                @click="bookASeat($event, itemInfo.id)">
                <span>{{ t('congress.register') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import ActivityService from '../../../core/services/activity.service';
import TranslationService from '../../../core/services/translation.service';
import TranslateService from '../../../core/services/translate.service';


export default {
    name: 'AgendaCardFooter',
    props: {
        itemInfo: Object,
    },
    data() {
        return {
            activitySvc: ActivityService.create(),
            isRegisteredData: this.itemInfo.isRegistered,
            translator: TranslateService.create(),
            isInRegisterTime: false,
            plainTextDescription: this.getPlainTextDescription(this.itemInfo.description),
        };
    },
    methods: {
        t(tag) {
            return this.translator.translate(tag);
        },
        registerLogAddToCalendar() {
            this.saveActivity('agenda-add-to-calendar');
        },
        async bookASeat(e, agendaId) {
            this.saveActivity('agenda-book-seat');
            const response = await axios.post(
                '/agenda-session/attendee-registration',
                {
                    userId: window.user.id,
                    agendaSessionId: agendaId,
                }
            );

            if (response.status == 200) {
                this.isRegisteredData = true;
                this.openModal();
            };
        },
        saveActivity(event) {
            let params = {
                title: this.itemInfo.title,
                model_type: this.itemInfo.type,
                model_id: this.itemInfo.id,
                congress_id: this.itemInfo.congress_id,
            };
            this.activitySvc.saveActivity(event, params);
        },
        getLocation(slug, id) {
            let agendaURL = '';
            agendaURL = TranslationService.transRoute('event-detail-live', {
                slug: slug,
                agendaSessionId: id
            });
            return window.app.env.APP_URL+agendaURL;
        },
        getPlainTextDescription(description) {
            let plainTextDescription = '';
            if (description) {
                plainTextDescription = description.replace(/<[^>]+>/g, '')
                    .replace(/&nbsp;/g, ' ')
                    .replace(/&amp;/g, '&');
            }
            return plainTextDescription;
        },
        openModal() {
            document.dispatchEvent(new CustomEvent("showEventRegisterModal", { detail: this.itemInfo.title }));                    
            // this.$root.$emit('showEventRegisterModal', this.itemInfo.title);
        },
    }
}
</script>