import AgendaHeader from '../objects/v4/modules/agenda/AgendaHeader.vue';
import AgendaMediaContent from '../objects/v4/modules/agenda/AgendaMediaContent.vue';
import AgendaSpeakers from '../objects/v4/modules/agenda/AgendaSpeakers.vue';

const vueComponents = [

    ['AgendaHeader', AgendaHeader],
    ['AgendaMediaContent', AgendaMediaContent],
    ['AgendaSpeakers', AgendaSpeakers],
]

export default vueComponents
