export default {
  get(related) {
    return {
      id: related?.id ?? '',
      title: related?.title ?? '',
      description: related?.description ?? '',
      slug: related?.slug ?? '',
      hasLiveSessions: false,
      image: related?.image ?? '',
      link: '/' + window.app.env.locale + '/multimedia/' + related?.id ?? '',
      date_start: '',
      date_end: '',
      formatted_dates: '',
      keywords: related?.keywords ?? [],
      shortened_keywords: related?.shortened_keywords ? related?.shortened_keywords.splice(0, 5) : [],
      is_new: false,
      is_live: false,
      is_on_demand: false,
      is_multimedia: related?.is_multimedia,
      multimedia_type: this.parseTitle(related?.multimedia_type) ?? '',
      multimedia_type_str: this.parseTitle(related?.multimedia_type) ?? '',
      card_color: related?.card_color ?? '',
      card_type: related?.card_type ?? '',
      card_icon: related?.card_icon ?? '',
      type: related?.type ?? ''
    };
  },
  parseTitle(tag) {
    switch (tag) {
      case 'pdf document':
        return 'Document';
      case 'html':
        return 'Web';
      default:
        return tag;
    }
  }
};
