<template>
  <div
    @click="showModal($event)"
    :class="'connectmi-event__speaker-card committee connectmi-event__speaker-card--' + this.variant"
  >
    <div
      :class="
        'connectmi-event__speaker-card__image__container connectmi-event__speaker-card__image--variant-' +
          this.variantimage
      "
    >
      <img class="connectmi-event__speaker-card__image" :src="this.getSpeakerImage(this.image)" alt="speaker" />
    </div>
    <p class="connectmi-event__speaker-card__name">{{ this.fullname }}</p>
    <p class="connectmi-event__speaker-card__job">{{ this.job }}</p>
    <p class="connectmi-event__speaker-card__bio">{{ this.institution }}</p>
    <ModalSpeaker
      ref="modal"
      class="d-none"
      :imagePath="this.imagePath"
      :image="this.image"
      :name="this.fullname"
      :job="this.job"
      :bio="this.bio.replace(/<\/?[^>]+(>|$)/g, '')"
      :isSteeringCommittee="this.isSteeringCommittee"
    ></ModalSpeaker>
  </div>
</template>

<script>
import ModalSpeaker from '../modals/ModalSpeaker.vue';

export default {
  props: {
    imagePath: String,
    image: String,
    fullname: String,
    job: String,
    bio: String,
    variant: String,
    variantimage: String,
    isSteeringCommittee: Boolean,
    institution: String
  },

  components: {
    ModalSpeaker
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {
    getSpeakerImage() {
      return this.image ? '/' + this.image : this.imagePath + 'empty-avatar.jpg';
    },

    truncate(input) {
      if (this.variant === 'committee') {
        return input.length > 100 ? input.substring(0, 100).trim() + '...' : input;
      } else {
        return input.length > 60 ? input.substring(0, 60).trim() + '...' : input;
      }
    },

    showModal(e) {
      this.$refs.modal.show();
    }
  }
};
</script>
