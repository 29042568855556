<template>
<div>
    
    <div>
        <ContentForm 
        :congressid="congressid"
        :therapeutics="therapeutics" 
        :content-type="contentType"
        :user-therapeutic-areas="userTherapeuticAreas"
        :keywords="keywords"
        :categories="categories"
        :hide-date-filter="true"></ContentForm>
    </div>

    <div>
        <ContentCategorized :congressid="congressid"></ContentCategorized>
    </div>
    
</div>
</template>

<script>
import ContentRepository from '../home-form/repositories/Content.repository';
import ContentFactory from '../content/factories/Content.factory';
import ContentForm from './ContentForm.vue';
import ContentCategorized from './ContentCategorized.vue';

export default {
    name: 'KrasContentSearch',
    components: {
        ContentForm,
        ContentCategorized
    },
    props: {
        therapeutics: Object,
        contentType: Array,
        userTherapeuticAreas: Array,
        keywords:Array,
        categories: Array,
        congressid: Number,
        slug: String
    },
    data() {
        return {
            isLoading: true,
            list: [],
            error: '',

        }
    },

    mounted() {
        //this.get();
    },

    methods: {
        async get() {
            try {
                this.isLoading = true;
                this.error = '';
                this.list = await ContentRepository.search({
                     content_type: ['content'],
                     format: [1],
                     qKeywords: this.keywords,
                     congress: [this.congressid.toString()]
                 });

                setTimeout(() => {
                    this.list = this.list.forEach(item => ContentFactory.get(item));

                    this.isLoading = false;
                }, 1000)                
            } catch (error) {
                this.list = [];
                this.error = "something happened";
            }
        },
    }
}
</script>
