
<template>
    <div v-if="useButton" id="ask-question-section">
        <div class="title-content">
            <p>
                <span class="h3 font-weight-bold">Ask a question</span>
                <span class="h1">to include in future content</span>
            </p>
        </div>

        <button @click="showModal()" class="open-ask-question v4-btn-primary" >
            <span>Click to send your questions</span>
        </button>
    </div>

    <span v-if="!useButton">Would you like to ask a question for future content? <i @click="showModal()">Click here</i></span>

    <div v-if="isModalVisible" id="modal-ask-question">
        <button type="button" class="btn-close" @click="close" aria-label="Close modal">
            x
        </button>
        <section class="modal-body">
            <h2 class="section-subtitle">Q&A</h2>
            <h3 class="section-title">Ask a question</h3>
            <section class="ask-question">
                <div class="ask-question-content ask-question-content-js">
                    <form @submit.prevent="onFormSubmit" method="post" id="form-ask-to-the-speaker">    
                        <p class="ask-question-content-message contmessage" style="display:none;">
                            <span>{{ t('live.sent_success') }}</span>
                        </p>
                        <textarea name="qpuestion" rows="5" class="modal-light-textarea floating-ask-question-textarea speakerquestion" id="form-ask-question-textarea" maxlength="255" required="" placeholder="Submit your questions to be answered in future content"></textarea>
                        <div class="ask-question-content-btn-container">
                            <div class="d-flex position-relative justify-content-center ask-question-footer-row">
                                <button class="send-ask-question v4-btn-primary">
                                    <span>Submit</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
    </section>
    </div>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import axios from "axios";
import $ from 'jquery';

export default {
    props:{
        modelId: [Number, String],
        modelType: String,
        useButton: Boolean,
    },
    data() {
        return {
            translator : TranslateService.create(),
            isModalVisible: false,
        }
    },
    methods: {
        t(tag){
            return this.translator.translate(tag)
        },
        showModal() {
            this.isModalVisible = true
            GoogleAnalytics.sendOpenContentEvent('Ask a question', 'modal');
        },
        onFormSubmit(ev) {
            var link = '/webapi/events/send-ask-question';
            var question = document.getElementById("form-ask-question-textarea").value;
            axios.post(link,{
                userId: window.user.id,
                modelType: "App\\Models\\"+this.modelType,
                modelId: this.modelId,
                question: question,
            })
            .then(res => {
                $('.form-ask-question-textarea').val(' ');
                $('.contmessage').css('display','block');
                $('.speakerquestion').addClass('textarea-success-msg');
                setTimeout(function(){ $('.contmessage').css('display','none');$('.speakerquestion').removeClass('textarea-success-msg'); }, 5000);
            })
            .catch(err => {
                console.warn(err);
            });
        },
        close() {
            this.$emit('close');
            this.isModalVisible = false;
            GoogleAnalytics.sendCloseContentEvent('Ask a question', 'modal');
        },
    },
}
</script>
