import DateService from "../../../core/services/date.service";
import TranslationService from '../../../core/services/translation.service';
import moment from 'moment';

export default {
    getKeywords(theEvent) {
      if(!!theEvent && Array.isArray(theEvent.keywords)) {
        return theEvent.keywords;
      }
      return [];
    },

    get(theEvent) {
        const event = JSON.parse(JSON.stringify(theEvent));
        const formatted_dates = this.formatDates(event);
        const isMultimedia = this.isMultimedia(event);
        return {
            id: event?.id ?? 0,
            title: event?.name ?? event?.title ?? '',
            slug: event?.slug ?? '',
            date_start: event?.date_start ?? event?.startDate ?? '',
            date_end: event?.date_end ?? event?.endDate ?? '',
            ...formatted_dates,
            keywords: this.getKeywords(theEvent),
            shortened_keywords: event.keywords === undefined ? [] : event?.keywords.splice(0, 5),
            is_new: event?.isNew ?? false,
            is_live: event?.hasLiveSessions ?? false,
            is_on_demand: event?.keep_opened_session == 1 ? true : false,
            is_multimedia: isMultimedia,
            multimedia_type: event?.multimedia_type_code ?? "",
            multimedia_type_str: event?.multimedia_type_code ?? "",
            type: event?.type ?? "congress",
            link: this.getEventLink(event),
            saved: event?.saved ?? false,
            liked: event?.liked ?? false,
            disliked: event?.disliked ?? false,
            congress_id: event?.congress_id ?? '',
            isPast: event?.is_past ?? false,
            pre_register: event?.pre_register ?? false,
            card_color: event?.card_color ?? '',
            card_type: event?.card_type ?? '',
            card_icon: event?.card_icon ?? '',
            availableTags: event?.availableTags ?? [],
            missingTags: event?.missingTags ?? []
        };
    },

    formatDates(event) {
        const date_start = event?.date_start ?? event?.startDate ?? null;
        const date_end = event?.date_end ?? event?.endDate ?? null;

        if(!date_start) {
            return { date_start_formatted: '', date_end_formatted: '', date_formatted: ''};
        }

        const date_start_formatted = this.formatDateStart(date_start);
        const date_end_formatted = this.formatDateEnd(event?.date_end ?? event?.endDate);
        const date_formatted = DateService.cardDate(date_start, event?.date_end ?? event?.endDate);
        let formattedDateStart = moment(date_start).format("DD.MM.YYYY")
        let formattedDateEnd = moment(date_end).format("DD.MM.YYYY")
        let formattedBothDates = formattedDateStart + ' - ' + formattedDateEnd;

        return {
            date_start_formatted, date_end_formatted, date_formatted, formattedBothDates
        };
    },

    getFullDate(start, end) {
        return `${start} - ${end}`
    },

    formatDateStart(date) {
        const parsed = new Date(date);
        const day = parsed.getDate();
        let suffix = 'th';
        if (day % 100 < 11 || day % 100 > 13) {
            switch(day % 10) {
                case 1: suffix = 'st'; break;
                case 2: suffix = 'nd'; break;
                case 3: suffix = 'rd'; break;
            }
        }
        return `${day}${suffix}`;
    },

    formatDateEnd(date) {
        return DateService.fullDate(date);
    },

    getEventLink(event){
        if(event?.card_type == 'Knowledge'){
            return '/kj/'+event.id;
        }else if(event?.type == 'multimedia'){
            return '/'+window.app.env.locale+'/multimedia/'+event.id;
        }else if(event?.type == 'congress'){
            return TranslationService.transRoute('event-detail', {slug: event?.slug ?? ''});
        }else{
            return '';
        }
    },

    isMultimedia(event){
        if(event?.card_type == 'Knowledge' || event?.slug){
            return false;
        }
        return true;
    }
};
