export default class Translations {
    constructor() {
        // TODO: CAMBIAR FUENTE
        this.translations = window.translations;
        this.baseTranslations = window.baseTranslations;
    }

    translate(file = null, name, subname = null) {
        if( ! name ) {
          throw new Error('Name could not be empty.');
        }

        return this.getKeyIfExistsOrThrowException(file, name, subname);
    }

    translateField(field) {
      const fieldWithTranslations = JSON.parse(JSON.stringify(field));
      var translatedField = null;
      if(window.app.env.locale in fieldWithTranslations){
        translatedField = fieldWithTranslations[window.app.env.locale];
      } else if ('en' in fieldWithTranslations) {
        translatedField = fieldWithTranslations.en;
      }
      return translatedField;
    }

    setAttribute(str, attribute) {
      if( ! str )
        throw new Error('String could not be empty.');

      if ( attribute )
         return str.replace(':attribute', attribute);
    }

    setSize(str, size) {
      if( ! str )
        throw new Error('String could not be empty.');

      if ( size )
        return str.replace(':size', size);
    }

    getBaseKeyIfExistsOrThrowException(file, name, subname) {
      let tl = this.baseTranslations[name];
      if(file) {
        tl = this.baseTranslations[file];
        tl = tl[name];
        name = file + "." + name;
      }
      if(subname) {
        name = name + "." + subname;
        tl = tl[subname];
      }
      if( 'undefined' == typeof tl)
          throw new Error('This translation does not exists: ' + name);

      return tl;
    }


    getKeyIfExistsOrThrowException(file, name, subname) {
      let originalFile = file;
      let originalName = name;
      let originalSubname = subname;
      
      let tl = this.translations[name];
      if(file) {
        tl = this.translations[file];
        tl = tl[name];
        name = file + "." + name;
      }
      if(subname) {
        name = name + "." + subname;
        tl = tl[subname];
      }
      if( 'undefined' == typeof tl)
        return this.getBaseKeyIfExistsOrThrowException(originalFile, originalName, originalSubname);

      return tl;
    }

}
