<template>
    <div>
        <h3 class="congress-live-section-title">Q&A</h3>
        <section class="congress-live-qa">
            <div class="congress-live-qa-content congress-live-qa-content-js">
                <form @submit.prevent="onFormSubmit" method="post" id="form-ask-to-the-speaker">    <p class="congress-live-notes-content-message contmessage" style="display:none;">
                        <span>{{ t('live.sent_success') }}</span>
                    </p>
                    <textarea name="qpuestion" rows="5" class="modal-light-textarea floating-qa-textarea speakerquestion" id="form-ask-to-the-speaker-textarea" maxlength="255" required=""></textarea>
                    <div class="congress-live-qa-content-btn-container">
                        <div class="d-flex position-relative justify-content-end qa-footer-row">
                            <button class="live-question-ans">
                                <span class="qa-icon-footer"><img src="/images/v4/icons/send.svg" alt="Mail"></span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import axios from "axios";
import $ from 'jquery';

export default {
    props:{
        slug: String,
        agendaid: [Number, String]
    },
    data() {
        return {
            translator : TranslateService.create()
        }
    },
    methods: {
        t(tag){
            return this.translator.translate(tag)
        },
        onFormSubmit(ev) {
            var link = '/webapi/events/'+this.slug+'/live-questions';
            var question = $('.speakerquestion').val();
            axios.post(link,{
                email: '',
                question: question,
                slug: this.slug,
                agendaSessionId: this.agendaid
            })
            .then(res => {
                console.info(res);
                $('.speakerquestion').val(' ');
                $('.contmessage').css('display','block');
                $('.speakerquestion').addClass('textarea-success-msg');
                setTimeout(function(){ $('.contmessage').css('display','none');$('.speakerquestion').removeClass('textarea-success-msg'); }, 1000);
            })
            .catch(err => {
                console.warn(err);
            });
        }
    },
}
</script>
