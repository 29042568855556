// import VueClipboard from 'vue-clipboard2'
import ResetPasswordController from './objects/v4/ResetPasswordController';
import { createPinia } from 'pinia';
import { useLocaleStore } from './objects/v4/store/locale.store';
import ChatbotController from './objects/v4/ChatBotController';
import CompleteUserFieldsModal from './objects/v4/modules/complete-fields/CompleteUserFieldsModal'
import DisclaimerModal from './objects/v4/modules/disclaimer-modal/DisclaimerModal';
import LiveBackController from './objects/v4/modules/live/LiveBackController';
import LiveWebsocketsAttendee from './objects/v4/LiveWebsocketsAttendee';
import { VJSInitializer } from './libs/videojs-helpers/vjs-initializer';
import { createApp } from 'vue';
import { registerScrollSpy } from 'vue3-scroll-spy'
import { setupCalendar } from 'v-calendar';
import GoogleAnalytics from './objects/v4/GoogleAnalytics.js';

import 'bootstrap';
import 'bootstrap-select';
import 'slick-carousel';
import './libs/amgen-connect/index';
import 'pdfjs-dist';
import './hospitalitySuite.js'
import 'swiper/css';
import { vueComponents } from './pages/pages.js';
import './GoogleAnalyticsTagManager.js';

window.GoogleAnalytics = GoogleAnalytics
// Vue.use(ReadMore) TODO: Library removed
const pinia = createPinia()

if (window.app.env.USERSNAP_ENABLE && (window.environment === 'production' || window.environment === 'preproduction' || window.environment === 'local')) {
  const keyProject = window.environment === 'production' ? '02dcd92e-b4f4-4a56-af36-44a4d517bd02' : 'a8c4f29f-3d8d-4120-8876-f6070c401f3a'
  window.onUsersnapCXLoad = function (api) {
    if (window.user) {
      const domain = window.user.email.split('@').pop()
      api.init({
        user: {
          userId: window.user.email,
          email: window.user.email,
          domain: domain
        },
        custom: {
          country: window.user.countryName,
          isHealthcareVerified: window.user.is_healthcare_verified,
          roles: window.user.roles
        }
      })
      if (window.user.hasRole === '1') {
        api.show(keyProject)
      }
    } else {
      api.init()
    }
    window.Usersnap = api
  }
  const script = document.createElement('script')
  script.defer = 1
  script.src = 'https://widget.usersnap.com/global/load/bf592922-6347-43c3-adb3-d98b860b47ba?onload=onUsersnapCXLoad'
  document.getElementsByTagName('head')[0].appendChild(script)
}

if (window.source === 'satellital-start') {
  ResetPasswordController.init()
}

/**
 * @typedef ModulePlugins 
 * @type {Array<{plugin: object, options: object}>}
 */

/**
 * @typedef ModuleComponents
 * @type {Array<{name: string, component: object}>}
 */

/**
 * Modules with Vue plugins and global components.
 * 
 * @var {Array<{plugins: ModulePlugins, options: ModuleComponents}>} modules
 */

export const app = createApp({
  setup () {
    const localeStore = useLocaleStore()

    return { localeStore }
  },
  mounted () {
    if (window.user) {
      this.localeStore.fetchUserLocales()
    }
    
    this.localeStore.fetchCurrentUserLocale()
  }
})

if (process.env.NODE_ENV === 'development') {
  app.config.devtools = true;
}

app.use(pinia)
app.use(setupCalendar, {})

registerScrollSpy(app)

vueComponents.forEach(([name, component]) => app.component(name, component))

app.mount('#app')

ChatbotController.init()
if (!window.is_old_live) {
  LiveBackController.init()
  LiveWebsocketsAttendee.init()
}
const vjs = new VJSInitializer()
vjs.initializeAll()

window.onload = function () {
  CompleteUserFieldsModal.init()
  DisclaimerModal.init()
}
