import vjsMarkers from '../../../../../libs/videojs-helpers/vjs-markers';
import { WebVTTCreator } from '../../../../../libs/videojs-helpers/webvtt';

export default class VJSTrackHelper {
  vjs = null;
  mId = 0;
  domId = null;
  tracks = null;

  constructor(vjs, domId, mId = 0) {
    this.vjs = vjs;
    this.mId = mId;
    this.domId = domId;
    let dataTracks = localStorage.getItem(`datatracks-m-${this.mId}`);
    try {
      dataTracks = dataTracks ? JSON.parse(dataTracks) : [];
    } catch(e) {
      dataTracks = [];
    }
    this.tracks = dataTracks;
  }

  static create(vjs, domId, mId = 0) {
    return new VJSTrackHelper(vjs, domId, mId);
  }

  generateCaptions() {
    const document = new WebVTTCreator("captions");
    if(this.tracks) {
      this.tracks.forEach(track => {
        document.addCaption(track.start, track.end, track.text);
      });
    }
    return document;
  }
  generateChapters() {
    const document = new WebVTTCreator("chapters");
    if(this.tracks) {
      this.tracks.forEach(track => {
        document.addChapter(track.start, track.end, track.text);
      });
    }
    return document;
  }
  addPlayerStartEventForTracks() {
    this.vjs.on('play', () => {
      let duration = this.vjs.duration();
      if (!Number.isNaN(duration)) {
        vjsMarkers.removeMarkers(this.domId);
        vjsMarkers.addMarkers(this.domId, this.tracks, duration, this.tracksAreRanged);
      }
    });
  }

  initialize(tech) {
    if(this.tracks && this.tracks.length > 0) {
      const captionsVTT = this.generateCaptions();
      const chaptersVTT = this.generateChapters();
      const baseObj = {tech, mode: "showing", language: "en", srclang: "en", default: true};
      this.vjs.addRemoteTextTrack({...baseObj, src: chaptersVTT.toDataURL(), kind: 'chapters'}, true);
      this.vjs.addRemoteTextTrack({...baseObj, src: captionsVTT.toDataURL(), kind: 'captions'}, true);
      this.addPlayerStartEventForTracks();
    }
  }
}
