<template>
  <div>
    <div class="col-12 p-0">
      <span class="favourites-title__text">
        {{t('profile.my-subscriptions')}} ({{this.list.length}})
      </span>
    </div>
    <div v-if="isLoading">
      {{t('general.loading')}}...
    </div>
    <template v-if="!isLoading">
      <ProfileSearch @searchFullTitle="searchFullTitle" ref="profilesearch" :from_type="'profsub'"></ProfileSearch>
      <div>
        <div v-if="!isMobile()">
          <V4Carousel  :slidesToShow="3" :slidesToScroll="3" :name="'profile-subscriptions'" :rowsToShow=this.numRows :isLoading="isLoading" :key="refreshSub" v-if="!isLoading">
            <template v-slot:items>
              <V4Card v-for="(item,i) in list" v-bind="item" :key="i" @click="sendResults(item,i)" @kwchange="searchKW"></V4Card>
            </template>
          </V4Carousel>
        </div>
        <div v-else>
          <V4Carousel v-bind="{slidesToShow:1, slidesToScroll:1, name: 'profile-subscriptions', isLoading: this.isLoading , rowsToShow:3 , key:refreshSub}" v-if="!isLoading">
            <template v-slot:items>
              <V4Card v-for="(item,i) in list" v-bind="item" :key="i" @click="sendResults(item,i)" @kwchange="searchKW"></V4Card>
            </template>
          </V4Carousel>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SubscriptionFactory from './factories/Subscription.factory';
import TranslateService from '../../core/services/translate.service.js';
import ProfileSearch from './ProfileSearch.vue';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import V4Carousel from '../carousel/V4Carousel.vue';
import V4Card from '../card/V4Card.vue';
import axios from 'axios';
import $ from 'jquery';
import moment from "moment";

export default {
  name: 'Subscriptions',
  data() {
    return {
      isLoading: true,
      list: [],
      fulllist : [],
      translator : TranslateService.create(),
      search_title: '',
      placeholder_text : '',
      refreshSub: 0,
      numRows : 3,
      // bus: new Vue(),
    }
  },
  components: {
      ProfileSearch,
      V4Carousel,
      V4Card
  },
  mounted(){
      this.fetch();
      this.placeholder_text = this.t('general.write-in');
  },
  methods: {
    async fetch() {
      const response = await this.getData();
      const results = response.data.data.map(k => SubscriptionFactory.get(k));
      this.fulllist = results;
      this.list = this.fulllist;
      GoogleAnalytics.sendSubscriptionCount(this.list.length.toString());

      if(this.list.length==0) {
        $("#my_subscriptions").hide();
        $('#my_subscriptions_menu').css('display', 'none');
      }else{
        this.setRows(this.list.length);
      }

      this.isLoading = false;
    },
    getData() {
      return axios.get('/profile/subscriptions');
    },
    setRows(len){
      this.numRows = len <= 3 ? 1 : len<= 6 ? 2 : 3;
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    t(tag){
      return this.translator.translate(tag)
    },

    searchFullTitle(filter_data) {
      this.isLoading = true;
      this.list = this.fulllist;

      if ( filter_data.q != '' ) {
        this.list =  this.list.filter(function(obj) {
          return obj.title.toLowerCase().indexOf(filter_data.q.toLowerCase()) !== -1;
        })
        this.refreshSub++;
      }
      if ( filter_data.keywords.length > 0 ) {
        this.list = this.list.filter(function(obj) {
          if ( obj.keywords.length > 0 ) {
            let keywords_list = obj.keywords.map(elementsub => elementsub.id.toString())
            return keywords_list.length > 0 && filter_data.keywords.every(function(element) {
              return keywords_list.indexOf(element) !== -1;
            })
          } else {
            if ( obj.shortened_keywords.length > 0 ) {
              let keywords_list = obj.shortened_keywords.map(elementsub => elementsub.id.toString());
              return keywords_list.length > 0 && filter_data.keywords.every(function(element) {
                return keywords_list.indexOf(element) !== -1;
              })
            }
          }
        })
        this.refreshSub++;
      }
      if ( filter_data.therea.length > 0 ) {
        this.list = this.list.filter(function(obj) {
          let therea_list = [];
          therea_list = obj.therapeuticAreas.map(function(key_obj){
            return key_obj.id.toString();
          });
          let checkThereaExist = (element) => therea_list.includes(element);
          return obj.therapeuticAreas.length > 0 && filter_data.therea.every(checkThereaExist);
        })
        this.refreshSub++
      }
      if ( filter_data.diseases.length > 0 ) {
        this.list = this.list.filter(function(obj){
          let diseases_list = [];
          diseases_list = obj.pathologies.map(function(key_obj) {
            return key_obj.id.toString();
          });
          let checkDiseasesExist = (element) => diseases_list.includes(element);
          return obj.pathologies.length > 0 && filter_data.diseases.every(checkDiseasesExist);
        })
        this.refreshSub++;
      }
      if ( filter_data.event_type != ''  ) {
        if ( filter_data.event_type != 'both' ) {
          this.list = this.list.filter(function(obj) {
            return obj.congress_type == filter_data.event_type;
          })
          this.refreshSub++;
        } else {
          this.refreshSub++;
        }
      }
      if ( filter_data.from_date != '' && filter_data.to_date != '' && filter_data.from_date!='Invalid date' && filter_data.to_date!='Invalid date') {
        this.list = this.list.filter(function(obj){
          let startDate = moment(obj.date_start).format("YYYY-MM-DD").toString();
          let endDate = moment(obj.date_end).format("YYYY-MM-DD").toString();
          let startExist = moment(startDate).isBetween(filter_data.from_date.toString(),filter_data.to_date.toString()) || moment(startDate).isSame(filter_data.from_date) || moment(startDate).isSame(filter_data.to_date);
          let endExist = moment(endDate).isBetween(filter_data.from_date.toString(),filter_data.to_date.toString()) || moment(endDate).isSame(filter_data.from_date) || moment(endDate).isSame(filter_data.to_date)
          console.log(startDate , endDate,startExist,endExist);
          return (startExist == true)  && (endExist == true);
        })                                   
      }   
      document.dispatchEvent(new CustomEvent("SearchCount", { detail: this.list.length }))
      // this.$root.$emit('SearchCount', this.list.length);
      this.refreshSub++;
      this.isLoading = false;
    },
    searchKW(id){
      this.$refs.profilesearch.updatekeyword(id);    
    },
    sendResults(content, index) {
      let searchTerm = '';
      document.addEventListener("searchTerm", (event) => {
        searchTerm = event.detail;
      });
      // this.$root.$on('searchTerm', (data) => {
      //   searchTerm = data;
      // });
      GoogleAnalytics.sendSearchResultsEvent(content.title, searchTerm, index.toString(), 'MySubscriptions');
    }
  }
}
</script>
