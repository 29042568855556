<template>
<div class="v4-calendar-event-modal">
    <div class="event-modal-header">
        <div class="oval" @click="onCloseModalClicked">x</div>
    </div>
    <div class="event-modal-body" v-if="eventselected.length == 1 && eventselected.length < 2" v-for="event in eventselected">
        <h2 class="event-date">{{ formatDate(event.date) }}</h2>
        <h2 class="event-title">
            <a :href="getLink(event)" target="_blank"><span v-html="event.title"></span></a>
        </h2>
        <div class="events-modal-keywords">
            <a v-for="(kw, index) in event.keywords" :key="index" class="v4-card--hash" href="">#{{ kw.name }}</a>
        </div>
        <button v-if="event.pre_register" class="event-register-button" @click="showPreRegisterPage(event, t('new-home.events.register-now'))">{{t('new-home.events.register-now')}}</button>
        <CardFooter :id="event.id" :title="event.title" :slug="event.slug" :type="'congress'" :isPast="event.isPast" :liked="event.liked" :disliked="event.disliked" :saved="event.saved" :link="event.link">
        </CardFooter>
    </div>
</div>
</template>

<script>
import TranslationService from '../../core/services/translation.service';
import TranslateService from '../../core/services/translate.service';
import DateService from '../../core/services/date.service';
import GoogleAnalytics from '../../GoogleAnalytics.js';
import CardFooter from '../card/CardFooter.vue';

export default {
    name: 'VueCalendarEventModal',
    props: {
        eventselected: Array,
    },
    data() {
        return {
            keywordList: this.keywords,
            translator : TranslateService.create()
        }
    },
    methods: {
        onCloseModalClicked() {
            this.$emit('closeModal');
        },
        getLink(event) {
            return TranslationService.transRoute('event-detail', {slug: event.slug});
        },
        showPreRegisterPage(event,textButton) {
            GoogleAnalytics.sendPromoClickEvent(textButton);
            window.open(
                window.location.origin + TranslationService.transRoute('event-detail', {slug: event.slug ?? ''})+ '/register',
                '_blank'
            );
        },
        formatDate(date) {
            return DateService.fullDate(date);
        },
        t(tag){
            return this.translator.translate(tag);
        },
        getEventLink(ev) {
            return TranslationService.transRoute('event-detail', {slug: ev.slug});
        },
        getDayNumber(date) {
            let d = new Date(date);
            return d ? d.getDate() : null;
        },
        getDayWeekShort(date) {
            let d = new Date(date);
            return d ? d.toLocaleString(window.app.env.locale, {weekday: 'short'}) : null;
        }
    },
    components: {
        CardFooter
    }
}
</script>
