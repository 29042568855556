<template>
  <section class="agenda custom-congress-agenda-satellital" id="satellital-agenda">
    <SatellitalAgendaTabs
      v-if="!hideTabs"
      :tabInfo="tabInfo"
      :agendaInfo="agendaInfo"
      :isMobile="isMobile"
      :openModal="openModal"
      :openTab="openTab"
      :selected_tab_id="selected_tab_id"
    />
    <SatellitalAgendaCarousel
      v-else
      :tabInfo="tabInfo"
      :agendaInfo="agendaInfo"
      :isMobile="isMobile"
      :openModal="openModal"
    />
  </section>
</template>


<script>
import V4Carousel from '../../carousel/V4Carousel.vue';
import V4Card from '../../card/V4Card.vue';
import SatellitalAgendaTabs from './SatellitalAgendaTabs.vue';
import SatellitalAgendaCarousel from './SatellitalAgendaCarousel.vue';
import AgendaFactory from '../../agenda/factories/Agenda.factory';
import PageStayController from '../../../page-stay/PageStayController.js';
import SatellitalStorage from '../utils/storage';
import ActivityService from '../../../core/services/activity.service';
import GoogleAnalytics from '../../../GoogleAnalytics.js';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'SatellitalCongressAgenda',
  components: {
    V4Carousel,
    V4Card,
    SatellitalAgendaTabs,
    SatellitalAgendaCarousel,
  },
  props: {
    slug: String,
    tabid: Number,
    active: Number,
  },
  data() {
    return {
      tabInfo: [],
      agendaInfo: [],
      selected_tab_id: 0,
      congressId: '',
      hideTabs: false,
      activitySvc: ActivityService.create(),
    };
  },
  mounted() {
    $('.hidden').hide();
    if (this.tabid == this.active) {
      this.agenda(this.slug, this.tabid);
    }
    this.congressId = SatellitalStorage.getKey(this.slug, 'congress_id');
  },
  methods: {
    openTab(tabId) {
      setTimeout(() => {
        $('.hidden').hide();
        $('#agenda_common_' + tabId).css({ display: 'inline-block', width: '70vw' });
        $('.slick-slider').slick('refresh');
      }, 100);
      this.selected_tab_id = tabId;
    },
    async agenda(slug, tabId) {
      document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: true }));
      const response = await axios.get(`/satellital/grouped-agenda`, {
        params: {
          slug: slug,
          tabId: tabId
        }
      }).then(resp => {
        var agenda = [];
        var tabs = [];
        this.hideTabs = false;
        if (Array.isArray(resp.data.data)) {
          // No grouping case
          agenda = { 0: resp.data.data.map(k => AgendaFactory.get(k)) };
          agenda = Object.values(agenda);
          this.hideTabs = true;
        } else {
          // Grouping case
          $.each(resp.data.data, function (key, value) {
            const results = value.map(k => AgendaFactory.get(k, slug));
            agenda.push(results);
            tabs.push(key);
          });
        }
        this.agendaInfo = agenda;
        this.tabInfo = tabs;
        this.openTab(0);
        document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
      })
        .catch(error => {
          console.error(error);
          document.dispatchEvent(new CustomEvent("showCustomLoader", { detail: false }));
        });
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    openModal(agenda) {
      document.dispatchEvent(new CustomEvent("showSatellitalAgendaModal", {
        detail: {
          title: agenda.title,
          description: agenda.description,
          date_formatted: agenda.date_formatted,
          speakers: agenda.speakers,
          hasSpeakers: agenda.speakers.length > 0 ? true : false
        }
      }));
      PageStayController.startStayPageTime('congress-register-agenda-modal', this.congressId ?? '0', false);

      this.activitySvc.saveActivity('open-satellital-agenda-modal', {
          title: agenda.title,
          model_type: 'agenda_session',
          model_id: agenda.id,
          source: null,
          congress_id: this.congressId
      });
      GoogleAnalytics.sendOpenContentEvent('Agenda ' + agenda.title, 'card');
    }
  }
};
</script>
