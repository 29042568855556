export default {
    get(content) {
        return {
            id: content?.id ?? '',
            name: content?.name ?? '',
            surname: content?.surname ?? '',
            bio: content?.bio.replace(/<\/?[^>]+>/ig, " ") ?? '',
            photo: content?.photo ?? ''
        };
    } 
};
