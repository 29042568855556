import axios from 'axios';
import $ from 'jquery';

const MultiEventHeaderController = {
  init: function() {
    $(".header-inner-actions > img, .header-inner-actions > span").on("click", (ev) => {
      $(".header-caret").toggleClass("fa-caret-down");
      $(".header-caret").toggleClass("fa-caret-up");
      $(".header-menu-actions-overlay").toggleClass("sr-only");
      $(".header-menu-actions").toggleClass("sr-only");
    });

    $(".header-menu-actions-overlay").on("click", (ev) => {
      $(".header-caret").addClass("fa-caret-down");
      $(".header-caret").removeClass("fa-caret-up");
      $(".header-menu-actions-overlay").addClass("sr-only");
      $(".header-menu-actions").addClass("sr-only");
    });

    $(".logout-action").on("click", (ev) => {
      const fn = () => {
        axios.post("/logout").then(res => {
          console.debug(res);
          document.location.replace = "/";
          window.location.reload();
        }).catch(err => {
          console.debug("ERR", err);
        })
      };

      if (!!window.amgenConnect) {
        const tt = setTimeout(() => {
          fn();
        }, 5000);
        window.amgenConnect
          .logout()
          .then(() => {
            fn();
            clearTimeout(tt);
          })
          .catch(e => {
            fn();
            clearTimeout(tt);
          });
      } else {
        fn();
      }
    })

  },
}

export default MultiEventHeaderController;
