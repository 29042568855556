<template>
  <div class="vote-panel" v-if="!isLoading">
    <div class="vote-title"><h3 class="text-left">Live Voting Panel</h3></div>
    <div class="slide-table">
      <div class="table-responsive">
       <div>
        <div>
         <ul class="title-tables clearfix">
          <li class="text-left">Title</li>
          <li class="text-left">Type</li>
          <li class="text-left">Status</li>
          <li class="text-left"></li>
          <li class="text-left"></li>
        </ul>
      </div>
      <div>
        <div v-for="(item, i) in paginated" v-bind:key="i">
          <div colspan="5" class="new-sec-fives">
            <div>
              <ul class="green-lift-pics">
              <li class="text-left">
                <div :class="{'title-green-left': item.type_str == 'multiple'}">
                  <span v-if="item.type_str == 'multiple' && item.polls">
                    {{item.polls.length}}
                  </span>{{item.question}}
                </div>
              </li>
              <li class="text-left"><span class="type-wings">{{item.type_str}}</span></li>
              <li class="text-left"><span class="type-wings">{{item.status_str}}</span></li>
              <li class="text-left"></li>
              <li class="text-right" v-if="item.status==0"><a href="javascript:void(0);" class="last-green send" @click="updateStatus(0, item.id, item.type_str);"><img src="/images/v4/send.jpeg"></a></li>
              <li class="text-right" v-if="item.status==1"><a href="javascript:void(0);" class="last-green eye-cross" @click="updateStatus(1, item.id, item.type_str);"><span class="dots-li"></span></a></li>
              <li class="text-right" v-if="item.status==2"><a href="javascript:void(0);" class="last-green upload" @click="updateStatus(2, item.id, item.type_str);"><i class="bi bi-upload"></i></a></li>
              <li class="text-right" v-if="item.status==3"><a href="javascript:void(0);" class="last-green eye-cross" @click="updateStatus(3, item.id, item.type_str);"><i class="bi bi-eye-slash-fill"></i></a></li>
              <li class="text-right" v-if="item.status==4"><a href="javascript:void(0);" class="last-green eye-cross" @click="updateStatus(4, item.id, item.type_str);"><i class="bi bi-eye-fill"></i></a></li>
            </ul>

            <div class="voting-nones" v-if="item.type_str=='multiple'">
            <ul class="qustion-block">
              <li colspan="5" class="full-wds">
                <div>
                  <ul v-for="(groupPoll, k) in item.polls" v-bind:key="'result'+k">
                    <li>{{groupPoll.question}}</li>
                  </ul>
                </div>
              </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="v4-carousel-controls d-flex v4-loading" v-if="totalPages > 1">
  <a href="javascript:void()" class="v4-carousel-controls--item prev-arrow slick-arrow" @click="prev"><i class="bi bi-arrow-left"></i></a>
  <div class="slick_dots">
    <ul class="slick-dots" role="tablist">
      <li role="presentation" v-for="(n, i) in totalPages" v-bind:key="i" :class="{'slick-active': current == n}">
        <button type="button" role="tab" @click="nav(n)">{{n}}</button>
      </li>
    </ul>
  </div>
  <a href="javascript:void()" class="v4-carousel-controls--item next-arrow ml-auto slick-arrow" @click="next()"><i class="bi bi-arrow-right"></i></a>
</div>
</div>
</template>
<script>
import TranslateService from '../../core/services/translate.service.js';
import VoteFactory from './factories/Vote.factory';
import axios from 'axios';
import $ from 'jquery';

export default {
   name: 'moderatorVote',
   props: ['slug','itemid'],
   data:function(){
    return{
      /* bus: new Vue(), */
      isLoading: true,
      defaultKey:0,
      rawPollInfo: [],
      voteInfo: [],
      current: 1,
      pageSize: 7,
      totalPages: 0,
      voteStatus: ["Created", "Published", "Finished", "Answers Published", "Hidden"],
      translator : TranslateService.create()
    }
  },
   mounted() {
      this.votingData();
   },
   computed: {
      indexStart() {
        return (this.current - 1) * this.pageSize;
      },
      indexEnd() {
        return this.indexStart + this.pageSize;
      },
      paginated() {
        this.totalPages = Math.ceil(this.voteInfo.length/this.pageSize);
        return this.voteInfo.slice(this.indexStart, this.indexEnd);
      }
   },
   methods:{
    async votingData() {
      const response = await this.getvotingData();
      this.rawPollInfo = response.data.data.polls;
      this.voteInfo = response.data.data.polls.map(k => VoteFactory.get(k));
      this.addGroupsToVotingPanel(response.data.data.groups);
      this.isLoading = false;
    },
    getvotingData() {
      return axios.get('/agenda-session/voting-panel?id='+this.itemid);
    },isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    },
    updateStatus(status, poolid, typeStr){
      var customStatus=0;
      let isUnpublish = false;
      if(status==0){
        customStatus = 1;
      }else if(status==1){
        customStatus = 2;
      }else if(status==2){
        customStatus = 3;
      }else if(status==3){
        customStatus = 2;
        isUnpublish = true;
      }
      if(typeStr == 'multiple') {
        this.changeSurvey(customStatus, poolid, isUnpublish);
      } else {
        this.changePoll(customStatus, poolid, isUnpublish);
      }
    },
    async changePoll(status, poolid, isUnpublish) {
      var voteIndex = this.rawPollInfo.findIndex((obj => obj.id == poolid));
      const response = await this.updatePollstatus(status, poolid);
      if(response.data.status==200){
        this.voteInfo[voteIndex].status = response.data.data.status;
        this.voteInfo[voteIndex].status_str = response.data.data.status_str;
        if(!isUnpublish && (status==1 || status==2)){
          await this.publishOrCloseSurvey(status, poolid);
        }
      }
    },
    async changeSurvey(status, poolid, isUnpublish) {
      var voteIndex = this.rawPollInfo.findIndex((obj => obj.id == poolid));
      const response = await this.updateSurveyStatus(status, poolid);
      if(response.data.status==200){
        this.voteInfo[voteIndex].status = response.data.data.status;
        this.voteInfo[voteIndex].status_str = response.data.data.status_str;
      }
    },
    updatePollstatus(status,poolid) {
      return axios.post('/webapi/events/'+this.slug+'/change-live-poll-status',{
          status:status,
          agendaSessionId:this.itemid,
          pollId:poolid
      });
    },
    updateSurveyStatus(status,surveyId) {
      return axios.post('/webapi/events/'+this.slug+'/change-survey-status',{
          status: status,
          agendaSessionId: this.itemid,
          id: surveyId
      });
    },
    async publishOrCloseSurvey(status,poolid, isSurvey = false) {
      var url;
      if(status==1){
        url = '/webapi/live/publish-survey';
        return axios.post(url,{ id:poolid, agendaSessionId:this.itemid, isSurvey: isSurvey });
      }else{
        url = '/webapi/live/close-survey';
        return axios.post(url,{ pollId:poolid, isSurvey: isSurvey });
      }
    },
    prev() {
      if (this.current != 1){
        this.current--
      }
      else{
        this.current = this.totalPages;
      }
    },
    next() {
      if (this.current != this.totalPages){
        this.current++
      }
      else{
        this.current = 1;
      }
    },nav(n){
      this.current = n;
    },
    t(tag){
      return this.translator.translate(tag)
    },
    addGroupsToVotingPanel(groups) {
      groups.forEach(item => {
        this.rawPollInfo.push(item);
        this.voteInfo.push(VoteFactory.getGroup(item));
      });
    }
   }
}
</script>
