/**
 * Simple object to add and remove markers from a video.js player
 */
const vjsMarkers = {
  /** removeMarkers(): Iterates through every marker and removes it from the DOM tree:*/
  removeMarkers(baseId) {
    const items = document.querySelectorAll(`#${baseId} .vjs-marker`);
    items.forEach(item => {
      item.remove();
    })
  },
  /** addMarkers(): Iterates through every vtt item and adds it to the DOM tree: */
  addMarkers(baseId, vttItems, videoDuration, ranged) {
    console.debug(`#${baseId} .vjs-progress-control.vjs-control`, videoDuration, vttItems);
    if(vttItems) {
      let iMax = vttItems.length, playheadWell = document.querySelector(`#${baseId} .vjs-progress-control.vjs-control`);
      for (let i = 0; i < iMax; i++) {
        let elem = document.createElement('div');
        elem.setAttribute("class",'vjs-marker');
        elem.id = 'cp' + i;
        elem.setAttribute("data-title", vttItems[i].text);
        const startProgress = (vttItems[i].start / videoDuration);
        const startP = (playheadWell.clientWidth*startProgress)+10+(10*startProgress);
        let midP = 0;
        if (ranged) {
          const endProgress = (vttItems[i].end / videoDuration);
          const endP = (playheadWell.clientWidth*endProgress)+10+(10*endProgress);
          elem.style.width = `${endP-startP}px`;
          elem.style.height = "40%";
          midP = (endP - startP) / 2;
        }
        elem.style.left = `${startP}px`;
        let tooltip = document.createElement('div');
        tooltip.setAttribute("class",'vjs-tooltip');
        tooltip.innerHTML = vttItems[i].text;
        tooltip.style.left = `${midP}px`;
        elem.appendChild(tooltip);
        console.debug('elem.style.left', elem.style.left);
        playheadWell.appendChild(elem);
      }
    }
  }
}
export default vjsMarkers;
