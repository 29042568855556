'use strict';

import {AmgenConnect} from "./amgen-connect";

const options = {
  urlSpeciality: true,
  reload: false,
  src: window.app.env.amgenConnect,
  // src: 'http://medconnect.local'
  /* Test */
  // src: 'https://id.laredmed.bymotto.com',

  /* Prod */
  // src: 'http://id.laredmed.es'
};

window.amgenConnect = window.amgenConnect || new AmgenConnect(options);

window.amgenConnect.init();

export default window.amgenConnect;
