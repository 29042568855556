import TranslationService from "../../../core/services/translation.service";
export default {
    get(cc) {
      const currentLang =  window.app.env.locale;
        return {
            id: cc?.id ?? '',
            title: cc?.title ?? '',
            description: cc?.description ?? '',
            info: cc?.infocard ?? {},
            keywords: cc?.keywords ?? [],
            therapeuticAreas: cc?.therapeuticAreas ?? [],
            slug: cc?.slug ?? '',
            image: cc?.banner_image ?? '',
            link: TranslationService.transRoute('clinical-case-detail', {id: cc?.id ?? ''}),
            duration: cc?.totalMinutes ?? 0,
            language: cc?.language ?? 'English',
            progress: cc?.progress ?? 0
        };
    },


};
