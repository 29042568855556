<template>
  <div>
    <div class="mb-4">
     <DateRangePicker v-model="dateRange" :dateRange="dateRange" is-range :min-date="this.minDate" :max-date="this.maxDate">
        <template v-slot="{ inputValue, inputEvents, isDragging }">
          <div class="flex flex-col sm:flex-row justify-start items-center">
            <div class="relative flex-grow">
              <input
                style="font-size: 0.9rem; width: 100%; font-weight:bold; text-align: center; padding: 0.5rem 0 0.3rem 0px; 
                      background-color: rgb(0, 16, 32); border-radius: 0.25rem; color: white; border: 0px; cursor:pointer; height: 40px;"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.start && inputValue.end ? `${inputValue.start} - ${inputValue.end}` : t('form.date')"
                v-on="inputEvents.start"
                readonly="readonly"
                id="date-selector"
              />
            </div>
          </div>
        </template>
      </DateRangePicker>
    </div>
  </div>
</template>

<script>
import VCalendar from 'v-calendar';
import DateRangePicker from 'vue3-daterange-picker'
import TranslateService from '../../../core/services/translate.service';

export default {
  components: {
    VCalendar,
    DateRangePicker
  },
  props:[
    'setday',
    'preselectedStartDate',
    'preselectedEndDate',
    'minDate',
    'maxDate'
  ],
  data() {
    var startDate, endDate;
    if(typeof this.setday !== "undefined") {
      startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-this.setday);
      endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    } else if(this.preselectedStartDate && this.preselectedEndDate) {
      let splittedStartDate = this.preselectedStartDate.split('/');
      let splittedEndDate = this.preselectedEndDate.split('/');
      startDate = new Date(splittedStartDate[2], splittedStartDate[1]-1, splittedStartDate[0]);
      endDate = new Date(splittedEndDate[2], splittedEndDate[1]-1, splittedEndDate[0]);
    } else {
      startDate = null;
      endDate = null;
    }
    return {
      dateRange: {
        startDate: startDate,
        endDate: endDate,
      },
      masks: {
      input: 'YYYY-MM-DD',
      },
      modelConfig: {
        start: {
          timeAdjust: '00:00:00',
        },
        end: {
          timeAdjust: '23:59:59',
        },
      },
      translator : TranslateService.create()
    }
  },
  watch: {
    'dateRange': function(newVal) {
      this.$emit('datechange', newVal);
    }
  },
  methods: {
    t(tag) {
        return this.translator.translate(tag);
    },
  }
};
</script>