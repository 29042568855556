import EventFactory from '../../../modules/events/factories/Event.factory';
import TranslationService from '../../../core/services/translation.service';

export default {
    get(content) {
        const formatted_dates = EventFactory.formatDates(content.mixed_object);
        return {
            id: content?.mixed_object.id ?? '',
            title: content?.mixed_object.title ?? '',
            description: content?.mixed_object.description ?? '',
            slug: content?.mixed_object.slug ?? '',
            hasLiveSessions: content?.mixed_object.hasLiveSessions ?? false,
            image: content?.mixed_object.image ?? '',
            link: this.getEventLink(content.mixed_object),
            date_start: content?.mixed_object.startDate ?? '',
            date_end: content?.mixed_object.endDate,
            ...formatted_dates,
            keywords: content?.mixed_object.keywords ?? [],
            shortened_keywords: content.mixed_object.keywords === undefined ? [] : content?.mixed_object.keywords.splice(0, 5),
            is_new: content?.mixed_object.isNew ?? false,
            is_live: content?.mixed_object.hasLiveSessions ?? false,
            is_on_demand: content?.mixed_object.keep_opened_session == 1 ? true : false,
            is_multimedia: true, 
            multimedia_type: content?.mixed_object.card_type.toLowerCase() ?? "",
            multimedia_type_str: content?.mixed_object.card_type.toLowerCase() ?? "",
            saved :  content?.mixed_object.saved ?? false,
            liked :  content?.mixed_object.liked ?? false,
            disliked : content?.mixed_object.disliked ?? false,
            congress_id: content?.mixed_object.id ?? '',
            type : content?.mixed_object.type ?? "",
            card_type: content?.mixed_object.card_type ?? "",    
            card_color: content?.mixed_object.card_color ?? "", 
            card_icon: content?.card_icon ?? '',
            congress_type: content?.mixed_object.congress_type ?? "",
            object: content?.object ?? "",
            from_type: 'bookmark',
            therapeuticAreas: content?.object.therapeutic_areas ?? "",
            pathologies: content?.object.pathologies ?? "",
        };
    },
    getEventLink(event){
        if(event?.card_type == 'Knowledge'){
            return '/kj/'+event.id;
        }else if(event?.type == 'multimedia'){
            return '/'+window.app.env.locale+'/multimedia/'+event.id;
        }else if(event?.type == 'congress'){
            return TranslationService.transRoute('event-detail', {slug: event?.slug ?? ''});
        }else{
            return '';
        }
    },
    
};
