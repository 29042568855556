<template>
    <section class="my_profile" id="my_profile">
     <div class="profile-max-container">
       <div class="profile-title">
           <h3 class="v4-section-title">{{t("profile.my_profile")}}</h3></div>
       <div class="profile-block clearfix">
        <div class="row">
            <div class="col-sm-7">
             <div class="profile-left pull-left">
                <div class="user-profile">
                    <div v-if="userImage" class="user-profile-image" :style="{'background-image': `url(${parseImage(userImage)})`}"></div>
                    <i v-else class="bi bi-person-circle"></i>
                    <div class="edit-user">
                        <i class="bi bi-pencil-square v4-action-link">
                            <input id="profile-image-input" accept="image/x-png,image/gif,image/jpg,image/svg" type="file" title=" " @change="uploadImage">
                        </i>
                    </div>
              </div>
          </div>
          <div class="profile-left pull-left">
              <div class="user-details">
               <h4>Dr. {{userdata.name}} {{userdata.surname}}</h4>
               <h5><template v-if="userdata.position">{{userdata.position}} (Job Position)</template></h5>
               <label><span :class="`flag-icon flag-icon-${userdata.country.code.toLowerCase()}`"></span><span v-if="userdata.country.name">{{userdata.country.name}}</span> <template v-if="userdata.locale">({{userdata.locale.name}})</template></label>
           </div>
       </div>
   </div>
   <div class="col-sm-5">
     <div class="social-links">
      <ul class="sociacl-icons text-right">
        <template v-if="userdata.instagram_url">
           <li><a :href="userdata.instagram_url" class="v4-action-link" target="_blank"><i class="bi bi-instagram"></i></a></li>
       </template>
       <template v-if="userdata.twitter_url">
           <li><a :href="userdata.twitter_url" class="v4-action-link" target="_blank"><i class="bi bi-twitter"></i></a></li>
       </template>
       <template v-if="userdata.facebook_url">
           <li><a :href="userdata.facebook_url" class="v4-action-link" target="_blank"><i class="bi bi-facebook"></i></a></li>
       </template>
       <template v-if="userdata.linkedin_url">
           <li><a :href="userdata.linkedin_url" class="v4-action-link" target="_blank"><i class="bi bi-linkedin"></i></a></li>
       </template>
   </ul>
</div>
<div class="edit-profile text-right">
    <button class="v4-btn-primary" v-on:click="editProfile">
        <i class="bi bi-pencil-square"></i>
        <p>{{t("profile.edit_profile")}}</p>
    </button>
</div>
</div>
</div>
</div>
<div class="profile-desc clearfix">
    <div class="row">
        <div v-if="isAmgenEmail(userdata.email) || userdata.is_healthcare_verified != 2" class="col-sm-4">
            <div class="head-title"><h5>{{isAmgenEmail(userdata.email) ? t("form.department") : t("form.speciality") }}</h5></div>
            <div class="content-small">
                <div v-if="isAmgenEmail(userdata.email)">
                    {{ userdata.department.name }}
                </div>
                <div v-if="!isAmgenEmail(userdata.email)">
                    <p v-for="(area_item, i) in userdata.specialities" v-bind:key="i">{{area_item.name}}<template v-if="i<(userdata.specialities.length-1)"><span class="therapeuticspace">,</span></template> </p>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="head-title"><h5>{{ t("form.institution") }}</h5></div>
            <div class="content-small">
                <p>{{ isAmgenEmail(userdata.email) ? t("form.amgen") : userdata.institution }}</p>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="head-title"><h5>{{t("profile.email")}}</h5></div>
            <div class="content-small email-small">
                <p>{{userdata.email}}</p>
            </div>
        </div>
    </div>
</div>

<div class="row slim-resposnive">
  <div class="col-sm-4">

    <div class="myactivity">
        <div class="note-boxers clearfix">
            <div class="activity-box">
                <h4>{{t("profile.my_activitiy")}}</h4>
            <div class="my-date-system">
            <div class="v4-select-container" @click="actions();">
              <div class="form-control reportrange-text"><i class="bi bi-calendar3"></i> <i class="bi bi-chevron-down"></i></div>
            </div>
            <div class="v4-select-container">
              <vSelect :options="activity_type"  :value="selected_type" v-model="selected_acttype"></vSelect>
             </div>
            </div>
            </div>
        </div>
    </div>
    <template v-if="calendar">
    <DatePicker @datechange="dateChange" :setday="7" ref="profile_date"></DatePicker>
    </template>
   <div class="box-first clearfix" v-if="list.length > 0">
    <div class="top-space-bt clearfix">
     <ul>
        <li v-for="(activity_item, k) in list" v-bind:key="k">
            <a class="profile-link-row" :href="getActivityLink(activity_item)" target="_blank" style="text-decoration: none; color: white;">
                <div class="pull-left text-right start-gray"><p>{{ momentTime(activity_item.date) }}</p><p>{{ momentDate(activity_item.date) }}</p></div><div class="pull-left end-white"><p>{{activity_item.event}}</p></div>
            </a>
        </li>
     </ul>
 </div>
</div>
</div>


<div class="col-sm-8">

    <div class="myactivity">
        <div class="note-boxers clearfix">
            <div class="notes-box">
                <h4>{{t("profile.my_notes")}}</h4>
            </div>
        </div>
    </div>

   <div class="box-even-cls clearfix">
       <div class="col-sm-6 pull-left pl-0">
           <div class="fit-to-box">
            <div class="fill-scroll">
              <div class="confrence-box" v-for="(notes_item, j) in notelist" v-bind:key="j" @click="showNoteText(notes_item.id,notes_item.note_text)" :class="{ 'confrence-box-active' : notelist[j].id == selected_id }">
                 <div class="text-left-box pull-left">
                  <p>{{notes_item.agenda_session_title}}</p>
                  <label>{{ momentDate(notes_item.date) }}</label>
              </div>
              <div class="right-icon-box pull-right">
                  <div class="download-icon">
                  <a href="javascript:void(0);" @click="download(notes_item.agenda_session_title,notes_item.note_text)" class="v4-action-link"><i class="bi bi-download"></i></a>
                  </div>
                  <div class="three-dots">
                   <ul>
                    <li>
                     <a href="javascript:void(0);" class="v4-action-link"><i class="bi bi-three-dots"></i></a>
                     <ul class="sub-dots">
                      <li><a :href="'https://api.whatsapp.com/send?text='" :note-id="notes_item.id" :title="notes_item.agenda_session_title" :notes="notes_item.note_text" target="_blank" @click="shareAction($event)"><i class="bi bi-whatsapp"></i></a></li>
                      <li><a :href="'mailto:?subject=Shared content from the Medical Meeting Point&body='" :note-id="notes_item.id" :title="notes_item.agenda_session_title" :notes="notes_item.note_text" target="_blank" @click="shareAction($event)"><i class="bi bi-envelope"></i></a></li>
                      <li><a href="javascript:void(0);" :note-id="notes_item.id" @click="deletePost(notes_item.id)"><i class="bi bi-trash"></i></a></li>
                  </ul>
              </li>
          </ul>
      </div>
  </div>
</div>

</div>
</div>
</div>
<div class="col-sm-6 pull-left">
    <div class="content-even">
     <div class="content-scroll">
         <p id="note_text_content">{{this.selected_note}}</p>
     </div>
 </div>
</div>
</div>
</div>
</div>
</div>
</section>
</template>

<script>
import moment from 'moment'
import ProfileFactory from './factories/Profile.factory';
import TranslateService from '../../core/services/translate.service';
import UtilsService from '../../core/services/utils.service';
import FormSelect from '../form/select/FormSelect.vue';
import DatePicker from '../form/datepicker/DatePicker.vue';
import TranslationService from '../../core/services/translation.service';
import vSelect from 'vue-select'
import $ from 'jquery';
import axios from 'axios';
import { ref, watch, getCurrentInstance } from 'vue';


export default {
    name: 'ProfileHeader',
    props: ['userdata'],
    data() {
       let startDate = moment().format();
       let endDate = moment().add(7,'days').format();

        return {
            calendar: false,
            list: [],
            notelist: [],
            translator: TranslateService.create(),
            selected_note : '',
            selected_id : '',
            activity_type : [],
            selected_type: {"label" : "All" , "code":"all"},
            dateRange: {startDate , endDate },
            from_date : moment().subtract(7,'d').format('YYYY-MM-DD'),
            to_date: moment().format('YYYY-MM-DD'),
            maxDate: new Date(),
            userImage: this.userdata.image,
        }
    },
    setup() {
        const momentDate = (date) => {
            return moment(date).format("DD/MM/YYYY");
        }
        const momentTime = (date) => {
            return moment(date).format("HH:mm");
        }
        const selected_acttype = ref({ label: "All", code: "all" });

        const instance = getCurrentInstance();

        watch(selected_acttype, (newVal, oldVal) => {
            instance.proxy.updateActivityList();
        });

        return { momentDate, momentTime, selected_acttype, }
    },
    components: {
        DatePicker,
        FormSelect,
        vSelect,
    },

    mounted() {
        this.fetch();
    },
    methods: {
        editProfile: function (event) {
            window.location.href = '/profile/edit';
        },
        datecell:function (value) {
            let dt = new Date(value)
            return dt.getDate()
        },
        moment: function () {
            return moment();
        },
        async fetch() {
            const response = await this.getData();
            const results = response.data.data.map(k => ProfileFactory.get(k));
            this.list = results;
            let activity_type = [
              {code: 'like', label: "Liked Items", name: "Liked Items", id: '0'},
              {code: 'dislike' , label: "Disliked Items", name: "Disliked Items", id: '1'},
              {code: 'enter-congress',  label: "Congress Access", name: "Congress Access", id: '2'},
              {code: 'open-multimedia', label: "Multimedias seen", name: "Multimedias seen", id: '3'},
              {code: 'bookmark',  label: "Bookmarked Items", name: "Bookmarked Items", id: '4'},
              {code: 'all', label: 'All', name: 'All', id: '5'}, ,
            ]
            this.activity_type = UtilsService.mapObjectToArray(activity_type)
            const notesResponse = JSON.parse(JSON.stringify(await this.getNotes()))
            const notesResults = notesResponse.data.data.map(obj => ProfileFactory.getNotes(obj));
            this.notelist = notesResults;
            if ( this.notelist.length > 0 ) {
                this.selected_note = this.notelist[0].note_text
                this.selected_id = this.notelist[0].id
            }
        },
        async deletePost(id){
            let deleteResponse = await this.deleteNotes(id);
            deleteResponse = JSON.parse(JSON.stringify(deleteResponse));
            if ( deleteResponse.data.status == 200 ) {
                var index = this.notelist.findIndex(function(obj){
                    return obj.id === id;
                })
                if (index !== -1) this.notelist.splice(index, 1);
                this.$nextTick()
                if ( this.selected_id == id ) {
                  if ( this.notelist.length > 0  ) {
                    this.selected_note = this.notelist[0].note_text
                    this.selected_id = this.notelist[0].id
                  } else {
                    this.selected_note = ""
                    this.selected_id = ""
                  }
                }
            }
        },
        getData() {
            let params = {};
            if ( this.selected_acttype.code != "all" ) {
                params["type"] = this.selected_acttype.code;
            }
            if ( this.from_date != null ) {
                params["date_start"] = this.from_date.toString();
            }
            if ( this.to_date != null ) {
                params["date_end"] = this.to_date.toString();
            }
            return axios.get('/profile/user-activities' , {
                params : params
            });
        },
        dateChange(t){
          this.from_date = moment(t.startDate).format("YYYY-MM-DD");
          this.to_date = moment(t.endDate).format("YYYY-MM-DD");
          this.updateActivityList();
        },
        actions(){
          if(this.calendar){
            this.calendar = false;
          }else{
            this.calendar = true;
          }
        },
        getNotes() {
            return axios.get('/profile/user-notes');
        },
        deleteNotes(id) {
            return axios.post('/profile/delete-note' ,{
                "note_id" : id ,
            });
        },
        isMobile() {
            return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        },
        t(tag) {
          return this.translator.translate(tag);
        },
        showNoteText(id,notes) {
            this.selected_note = notes;
            this.selected_id = id
        },
        shareAction(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            const target = $(ev.currentTarget);
            let id = target.attr('note-id');
            let title = target.attr('title');
            let note = target.attr('notes');
            const checkoutThis = this.t("general.check-out");
            let message = `${title}: ${note}`;
            const href = target.attr('href');
            const url = href + message;
            if (target.attr('target') === '_blank') {
                window.open(url);
            } else {
                window.location.replace(url);
            }
        },
        download(title,notes) {
            if(title == ''){title = "note";}
            let text = JSON.stringify(notes);
            let filename = title+'.txt';
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        async updateActivityList () {
            const response = await this.getData();
            const results = response.data.data.map(k => ProfileFactory.get(k));
            this.list = results;
        },
        async resetActivityList ( event , func ) {
            if ( this.from_date != null && this.to_date != null ) {
                this.$refs.picker.clickCancel();
                this.from_date = null;
                this.to_date = null;
                const response = await this.getData();
                const results = response.data.data.map(k => ProfileFactory.get(k));
                this.list = results;
            } else {
                this.$refs.picker.clickCancel();
            }
        },
        getActivityLink(activityItem) {
            if(activityItem.type == 'congress') {
                return TranslationService.transRoute('event-detail', {slug: activityItem.url});
            } else {
                return TranslationService.transRoute('detail-multimedia', {multimediaId: activityItem.model_id});
            }
        },
        isAmgenEmail(email) {
            return email.includes('@amgen.com');
        },
        parseImage(img) {
            if (img && img.substr(0,1) != '/') {
                img = '/' + img;
            }
            return img;
        },
        uploadImage(event) {
            let url = '/profile/image';
            let data = new FormData();
            data.append('_token', $('meta[name="csrf-token"]').attr('content'));
            data.append('image', event.target.files[0]);

            axios
            .post(url, data)
            .then(res => {
                if (res.status === 200) {
                    let timestamp = new Date().getTime();
                    this.userImage = res.data.image + '?t=' + timestamp;
                }
            })
            .catch(error => {
                console.debug("ERR", error);
            });
        },
    },
}
</script>
