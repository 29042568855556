<template>
  <section class="custom-template-agenda" :id="$parent.$parent.formatHrefAnchorText(configuration.section_wording)"
    v-if="this.congress.showSection">
    <div class="container">
      <div>
        <h2 class="text-center custom-template-agenda__head">{{ configuration.section_wording }}</h2>
      </div>

      <div class="custom-template-agenda__form">
        <div class="custom-template-agenda__timezones" v-if="this.congress.agendaFiltersView!==0">
          <div class="mb-4"
            :class="{'custom-template-agenda__timezones__wrapper': this.congress.agendaFiltersView!==1}">
            <div class="custom-template-agenda__form__timezone">
              <select class="select selectpicker bootstrap-select--bordered" @change="onDropDownTimezoneChange"
                v-model="selectedTimezone" data-live-search="true" :title="t('satellital.nothing-selected')"
                :data-live-search-placeholder="t('general.search')">
                <option :key="index" v-for="(zone, index) of this.congress.allTimezones" :value="zone">{{ zone }}
                </option>
              </select>
            </div>

            <div class="custom-template-agenda__form__slider" :class="{'mt-4': this.congress.agendaFiltersView===1}">
              <div class="custom-template-agenda__form__advice">
                <span v-if="!!this.congress.timezoneText" class="text-center"
                  v-html="this.congress.timezoneText"></span>
                <i class="bi bi-arrow-repeat" v-on:click="refreshSlider" v-if="this.congress.agendaFiltersView!==1"></i>
              </div>

              <div class="w-100">
                <Slider v-model="selectedSlider" v-bind="sliderOptions" @change="onSliderChange"
                  :class="{'slider-bordered': this.congress.agendaFiltersView === 1 }" />
              </div>
            </div>

            <div class="custom-template-agenda__form__category" v-if="this.congress.agendaFiltersView!==1">
              <select class="select selectpicker bootstrap-select--bordered" @change="onDropDownCategoriesChange"
                v-model="selectedCategory" data-live-search="true" :title="t('congress.all-categories')"
                :data-live-search-placeholder="t('general.search')" multiple="multiple">
                <option v-for="(category, index) of this.congress.allCategories" :key="index" :value="index"
                  :data-content="`<i class='bi bi-circle-fill' style='color: ` + category.color + `'></i> <span>` + category.name + `</span>`">
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div v-if="dataReady">
        <div v-if="this.congress.fullCalendar">
          <AgendaCalendar :key="loopKey" :agendaSessions="events" :timezone="selectedTimezone"
            :activeViewType="activeViewType" :congress="this.congress" />
        </div>

        <div v-else>
          <div v-if="this.congress.agenda_grouping_method && this.congress.agenda_grouping_method != 'No Grouping'">
            <AgendaTabs :key="loopKey" :agendaSessions="events"
              :agenda_grouping_method="this.congress.agenda_grouping_method ?? 'Group by Date'" />
          </div>
          <div v-else>
            <AgendaCarrousel :key="loopKey" :agendaSessions="events" />
          </div>
        </div>
      </div>

      <div v-if="this.configuration.configuration.show_timezone_info" class="d-flex justify-content-rigth mt-3">
        <div>
            <span class="title-text text-bold">
              <span class="list-text"> All times are shown in your local timezone: {{ this.formatTimezone }}. It is currently {{ currentTime }}. If you want to change your timezone, you can do so in your <a :href="'/en/profile'" class='v4-action-link'>profile settings</a></span>
            </span>
        </div>
      </div>
    </div>
    
    <div v-if="!!this.congress.agendaFile" class="d-flex justify-content-center mt-3">
      <div>
        <a :href="parseLink(this.congress.agendaFile)" :download="`${this.congress.slug}.pdf`"
          class="v4-btn-primary satellital-country-next" @click="onAgendaFileClick">
          <span class="title-text text-bold">
            <span class="list-text"> {{ t('congresses.download_full_agenda') }}</span>
          </span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import TranslateService from '../../../core/services/translate.service';
import ActivityService from '../../../core/services/activity.service';
import AgendaCalendar from './AgendaCalendar.vue';
import AgendaTabs from './AgendaTabs.vue';
import AgendaCarrousel from './AgendaCarrousel.vue';
import Slider from '@vueform/slider';
import $ from 'jquery';
import axios from 'axios';

export default {
  name: 'AgendaSection',
  data() {
    return {
      activitySvc: ActivityService.create(),
      activeViewType: '',
      isLoading: true,
      hidden: false,
      first: true,
      translator: TranslateService.create(),
      selectedTimezone: window.user.time_zone ?? 'UTC',
      loopKey: 0,
      selectedSlider: [],
      sliderOptions: {
        max: 24,
        min: 0,
        step: 1,
        merge: 1,
        tooltipPosition: 'bottom',
        format: function(value) {
          if (value === 24) {
            return `23:59`;
          }
          return `${value}:00`;
        }
      },
      selectedCategory: [],
      events: [],
      dataReady: false,
      congress: [],
      currentTime: ''
    };
  },
  components: { AgendaTabs, AgendaCarrousel, AgendaCalendar, Slider },
  props: {
    configuration: Object,
  },
  mounted() {
    this.congressData();
    this.updateCurrentTime();
    setInterval(this.updateCurrentTime, 1000 * 30);
    this.formatTimezone = this.getFormatTimezone(this.selectedTimezone)
    setTimeout(function(){
      addeventatc.settings({
          license: window.app.env.USER_CLIENT_ID,
      });
      addeventatc.refresh();
    }, 600);
  },
  methods: {
    t(tag) {
      return this.translator.translate(tag);
    },
    onDropDownTimezoneChange() {
      this.saveActivity('select-timezone-agenda-section', this.selectedTimezone);
      this.getEvents();
    },
    onDropDownCategoriesChange() {
      this.saveActivity('select-categories-agenda-section', this.selectedCategory);
      this.getEvents();
    },
    onSliderChange() {
      setTimeout(() => {
        this.saveActivity('select-slider-agenda-section', this.selectedSlider);
        this.getEvents();
      }, 200);
    },
    onAgendaFileClick(){
      this.saveActivity('agenda-download-full-calendar', this.agendaFile);
    },
    parseLink(link) {
      if (link && link.substr(0, 1) != '/') {
        link = '/' + link;
      }
      return link;
    },
    refreshSlider() {
      this.selectedSlider = [this.congress.timezoneStartHour,this.congress.timezoneEndHour];
       this.getEvents();
    },
    async congressData() {
      await axios
        .get('/congresses/agenda/get-congress-data',
        { params:
            {
              congressId: this.configuration.congress_id
            }
        })
        .then(response => {
          if(response.data && response.data.data) {
            this.congress = response.data.data;
            this.activeViewType = this.congress.fullCalendarDefaultView;
            this.selectedSlider = [this.congress.timezoneStartHour,this.congress.timezoneEndHour];

            this.getEvents();

            if (!this.congress.showSection) {
              $('#agenda_menu').hide();
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getEvents() {
      const response = await this.getAgendaData();
      this.events = response;
      this.dataReady = true;
      // this.$root.$emit('giveMeType');
      document.dispatchEvent(new CustomEvent("giveMeType"));
      if (localStorage.getItem('fullCalendarActiveViewType')) {
        this.activeViewType = localStorage.getItem('fullCalendarActiveViewType');
        localStorage.removeItem('fullCalendarActiveViewType');
      }
      this.loopKey += 1;
    },
    getAgendaData() {
      return axios.get(
        '/congresses/agenda/get-grouped-agenda',
        { params:
          {
            congressId: this.configuration.congress_id,
            countryId: this.congress.countryId,
            timezone: this.selectedTimezone,
            startTime: this.selectedSlider[0],
            endTime: this.selectedSlider[1],
            categories: this.selectedCategory,
            agendaFiltersView: this.congress.agendaFiltersView
          }
        }
      );
    },
    saveActivity(event, value) {
      let params = {
          title: value,
          model_type: 'congress',
          model_id: window.app.event ? window.app.event.id : null,
          congress_id: window.app.event ? window.app.event.id : null
      };
      this.activitySvc.saveActivity(event, params);
    },
    getCurrentTimeInTimezone(timezone) {
      const date = new Date();
      return new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: timezone
      }).format(date);
    },
    updateCurrentTime() {
      this.currentTime = this.getCurrentTimeInTimezone(this.selectedTimezone);
    },
    getFormatTimezone(timezone) {
      const date = new Date();
      const parts = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        timeZoneName: 'short'
      }).formatToParts(date);
      const timeZoneName = parts.find(part => part.type === 'timeZoneName').value;
      return `${timezone} (${timeZoneName})`;
    },
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
